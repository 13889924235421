<form class="collapsible-form dense">
  <div>
    <div style="padding: 10px 0; overflow-x: auto; overflow-y: hidden">
      <table class="terms-table dense-layout">
        <thead>
          <tr>
            <td class="field-label sticky-label pt-2" style="font-size: 1.15rem; padding-bottom: 0.5rem"></td>
            <td
              *ngFor="let formGroup of control.controls; let i = index"
              class="option-header"
              style="padding-top: 0.5rem"
            >
              Option {{ i + 1 }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="sticky-label grid">
              <label class="terms-label-text">
                {{ amountDataDef.label }}
              </label>
            </td>
            <td *ngFor="let formGroup of control.controls">
              <app-currency-field
                [control]="formGroup.controls['amount']"
                [dataDef]="amountDataDef"
                [inlineNoLabel]="true"
                [readOnly]="readOnly"
                class="terms-input-field"
              >
              </app-currency-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
