import { LabelValue } from '@lib-resource/label-value.model';

export function toLabelValue(o: object): LabelValue[] {
  return Object.keys(o).map((key) => ({
    label: key,
    value: o[key]
  }));
}

export function toArray(o: object): LabelValue[] {
  return Object.keys(o).map((key) => ({
    label: key,
    value: o[key]
  }));
}

export function isObject(obj) {
  return obj === Object(obj);
}
