import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { FileDocType, FileModel } from '@file-upload-lib/file.model';
import { DocTypeMappingPipe } from '@shared/pipes/doctype-mapping.pipe';
import { forkJoin, Observable, of } from 'rxjs';
import { SERVER_API_URL } from '@app/app.constants';
import { ActionDef } from '@data-table-lib/models/data-table.model';
import { faCircleDown, faPenCircle, faTrashCircle } from '@fortawesome/pro-solid-svg-icons';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { EditSupportFileDialogComponent } from '@common/dialogs/edit-support-file-dialog/edit-support-file-dialog.component';
import { TableSource } from '@data-table-lib/data-table-config';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { QuoteResponseManifestDialogComponent } from '@common/dialogs/manifest-dialogs/quote-response-manifest-dialog.component';
import { ManifestEntryModel } from '@common/models/manifest-entry.model';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { Store } from '@ngxs/store';
import { OrgProduct, OrgProductType } from '@main/store/product/product.model';
import { UserModel } from '@auth/models/user.model';

@Component({
  templateUrl: './quote-supporting-file-dialog.component.html'
})
export class QuoteSupportingFileDialogComponent {
  quoteResponseId: number;
  readOnly: boolean = false;
  fileColumns: DataDefModel[];
  displayedFileColumns: string[];
  supportFiles$: Observable<FileModel[]>;
  docTypes: FileDocType[];
  dataTableSource: TableSource;
  includeDelete: boolean = false;
  includeDownloadQuoteLink: boolean = false;
  dialogTitle: string = 'Add Supporting Files to Quote';
  orgName: string;

  product: OrgProductType;

  fileActions: ActionDef[] = [
    {
      label: 'Edit File',
      icon: faPenCircle,
      hideIf: () => this.readOnly,
      callback: (row) => {
        this.editFile(row);
      }
    },
    {
      label: 'Download File',
      icon: faCircleDown,
      hideIf: () => this.readOnly,
      callback: (row) => {
        this.downloadFile(row);
      }
    },
    {
      label: 'Delete File',
      icon: faTrashCircle,
      hideIf: () => this.readOnly || !this.includeDelete,
      disableIf: (row) => !!row.versionId,
      disabledMessage: 'Delete File',
      callback: (row) => {
        this.deleteSupportFile(row);
      }
    }
  ];

  docTypeDef: DataDefModel = new DataDefModel({
    key: 'docType',
    label: 'Type',
    type: DATA_TYPES.select
  });

  fileNameDef: DataDefModel = new DataDefModel({
    label: 'Name',
    key: 'name',
    type: DATA_TYPES.text
  });

  fileVersionDef: DataDefModel = new DataDefModel({
    label: 'Version',
    key: 'versionId',
    type: DATA_TYPES.text,
    calculatedValueFn: (row) => {
      if (row.versionId !== null && row.versionId !== undefined) {
        return 'v' + row.versionId;
      }
      return '';
    }
  });

  fileCreatedDateDef: DataDefModel = new DataDefModel({
    label: 'Created',
    key: 'createdDate',
    type: DATA_TYPES.date
  });

  fileCreatedByDef: DataDefModel = new DataDefModel({
    label: 'Created By',
    key: 'createdBy',
    type: DATA_TYPES.text,
    calculatedValueFn: (row: FileModel) => this.formatCreatedBy(row.createdBy)
  });

  fileDescriptionDef: DataDefModel = new DataDefModel({
    label: 'Description',
    key: 'description',
    type: DATA_TYPES.text
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData,
    private store: Store,
    private dialogRef: MatDialogRef<QuoteSupportingFileDialogComponent>,
    private dialog: MatDialog,
    private docTypePipe: DocTypeMappingPipe,
    private quoteResponseService: QuoteResponseService,
    private dialogsService: DialogsService
  ) {
    this.quoteResponseId = dialogData.id;
    this.readOnly = dialogData.readOnly;
    this.docTypeDef = {
      ...this.docTypeDef,
      options: dialogData.docTypes.map((docType: FileDocType) => ({
        label: this.docTypePipe.transform(docType),
        value: docType
      }))
    };
    this.product = this.store.selectSnapshot(ProductSelectors.selectedProduct);
    this.fileColumns = this.isRx
      ? [this.fileNameDef, this.fileDescriptionDef, this.docTypeDef, this.fileCreatedDateDef, this.fileCreatedByDef]
      : [
          this.fileNameDef,
          this.fileDescriptionDef,
          this.fileVersionDef,
          this.docTypeDef,
          this.fileCreatedDateDef,
          this.fileCreatedByDef
        ];
    this.displayedFileColumns = this.fileColumns.map((def) => def.key);
    this.docTypes = dialogData.docTypes;
    this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.quoteResponseId);
    this.includeDelete = dialogData.includeDelete || this.includeDelete;
    this.dialogTitle = dialogData.dialogTitle || this.dialogTitle;
    this.orgName = dialogData.orgName || '';
    this.includeDownloadQuoteLink = dialogData.includeDownloadQuoteLink || this.includeDownloadQuoteLink;
  }

  get getSupportFileUploadUrl() {
    return `${SERVER_API_URL}/quote-response/${this.quoteResponseId}/supportfile`;
  }

  downloadFile(file: FileModel) {
    this.quoteResponseService.downloadSupportFile(this.quoteResponseId, file.id, file.name);
  }

  deleteSupportFile(rmtFile: FileModel): void {
    this.dialogsService
      .confirmationDialog({
        label: 'Delete File',
        warnMessage: 'The support file will be permanently deleted',
        actionText: 'Delete'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.quoteResponseService.deleteSupportFile(this.quoteResponseId, rmtFile.id).subscribe(() => {
            this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.quoteResponseId);
          });
        }
      });
  }

  editFile(file: FileModel) {
    this.dialog
      .open(EditSupportFileDialogComponent, {
        data: {
          activeSupportFileId: file.id,
          fileName: file.name,
          docType: file.docType,
          description: file.description,
          docTypes: this.docTypes,
          restrictDocTypeUpdate: !!file.versionId
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.quoteResponseService
            .updateSupportFileMetaData(
              this.quoteResponseId,
              file.id,
              result.fileName,
              !!file.versionId ? null : result.docType,
              result.description ? result.description : null
            )
            .subscribe(() => {
              this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.quoteResponseId);
            });
        }
      });
  }

  supportFileAdded() {
    this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.quoteResponseId);
  }

  get isRx(): boolean {
    return this.product === OrgProduct.QUOTE_RX;
  }

  closeAndOpenManifestDialog() {
    // Close the current dialog
    this.dialogRef.close();

    // Open a new dialog
    this.dialog.open(QuoteResponseManifestDialogComponent, {
      width: '90%',
      autoFocus: false,
      panelClass: 'full-width-dialog',
      data: {
        zipFileName: this.orgName,
        ids: {
          quoteResponseId: this.quoteResponseId
        },
        loadFunction: (): Observable<[boolean, ManifestEntryModel[]]> =>
          forkJoin([of(true), this.quoteResponseService.getManifestOptions(this.quoteResponseId)]),
        dialogTitle: `Download Quote for ${this.orgName}`
      }
    });
  }

  formatCreatedBy(createdBy: UserModel): string {
    return !!createdBy ? `${createdBy.firstName}${!!createdBy.lastName ? ` ${createdBy.lastName}` : ''}` : '';
  }
}
