import { Direction, Directionality } from '@angular/cdk/bidi';
import {
  ComponentType,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector, NgZone, Optional, TemplateRef } from '@angular/core';
import { InputDialogData, MessageDialogData } from '@common/dialogs/dialogs.model';
import { InputDialogComponent } from '@common/dialogs/input-dialog/input-dialog.component';
import { MessageDialogComponent } from '@common/dialogs/message-dialog/message-dialog.component';
import { PopoverComponent } from '@shared/components/popover/popover.component';
import { PopoverRef } from './popover-ref';
import { POPOVER_CONFIG, POPOVER_DATA, PopoverConfig } from './popover.model';

const defaultConfig: PopoverConfig = {
  backdropClass: '',
  disableClose: false,
  panelClass: ''
};

/**
 * Service to open modal and manage popovers.
 */
@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  get dir(): Direction {
    return this._dir && this._dir.value === 'rtl' ? 'rtl' : 'ltr';
  }

  constructor(
    private _overlay: Overlay,
    private injector: Injector,
    public zone: NgZone,
    @Optional() private _dir: Directionality
  ) {}

  open<D = any>(
    component: ComponentType<any> | any,
    target: ElementRef,
    config: Partial<PopoverConfig> = {}
  ): PopoverRef<D> {
    const popoverConfig: PopoverConfig = Object.assign({}, defaultConfig, config);
    const positionStrategy = this._getPosition(target, popoverConfig);
    const overlayRef = this._createOverlay(positionStrategy);

    const popoverRef = new PopoverRef(overlayRef, positionStrategy, popoverConfig);

    const portalInjector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: PopoverRef, useValue: popoverRef },
        { provide: POPOVER_CONFIG, useValue: popoverConfig },
        { provide: POPOVER_DATA, useValue: popoverConfig.data }
      ]
    });

    const portal = new ComponentPortal(PopoverComponent, null, portalInjector);
    const popoverObj = overlayRef.attach(portal);

    const popover = popoverObj.instance;

    if (component instanceof TemplateRef) {
      popover.attachTemplatePortal(
        new TemplatePortal<any>(component, null, {
          $implicit: popoverRef
        })
      );
    } else {
      const popoverInjector = Injector.create({
        parent: this.injector,
        providers: [
          { provide: POPOVER_DATA, useValue: config.data },
          { provide: PopoverRef, useValue: popoverRef }
        ]
      });
      popover.attachComponentPortal(new ComponentPortal(component, null, popoverInjector));
    }
    return popoverRef;
  }

  /**
   *  This method creates the overlay from the provided popover's template and saves its
   *  OverlayRef so that it can be attached to the DOM when openPopover is called.
   */
  private _createOverlay(positionStrategy: PositionStrategy): OverlayRef {
    const overlayConfig = new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      direction: this.dir,
      scrollStrategy: this._overlay.scrollStrategies.reposition()
    });
    // this._subscribeToPositions(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);

    return this._overlay.create(overlayConfig);
  }

  /**
   * This method builds the position strategy for the overlay, so the popover is properly connected
   * to the trigger.
   * @returns ConnectedPositionStrategy
   */
  private _getPosition(target: ElementRef, config: PopoverConfig): FlexibleConnectedPositionStrategy {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      config.positionX === 'before' ? ['end', 'start'] : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      config.positionY === 'above' ? ['bottom', 'top'] : ['top', 'bottom'];

    const originY = overlayY;
    const originFallbackY = overlayFallbackY;

    const overlayX = originX;
    const overlayFallbackX = originFallbackX;

    let offsetX = -8;
    let offsetY = 32;
    if (config.targetOffsetX !== undefined && !isNaN(Number(config.targetOffsetX))) {
      offsetX = Number(config.targetOffsetX);
    }

    if (config.targetOffsetY && !isNaN(Number(config.targetOffsetY))) {
      offsetY = Number(config.targetOffsetY);
    }

    return this._overlay
      .position()
      .flexibleConnectedTo(target)
      .withLockedPosition(true)
      .withPositions([
        {
          originX,
          originY,
          overlayX,
          overlayY,
          offsetY,
          offsetX
        },
        {
          originX: originFallbackX,
          originY,
          overlayX: overlayFallbackX,
          overlayY,
          offsetY
        },
        {
          originX,
          originY: originFallbackY,
          overlayX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY
        },
        {
          originX: originFallbackX,
          originY: originFallbackY,
          overlayX: overlayFallbackX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY
        }
      ])
      .withDefaultOffsetX(offsetX)
      .withDefaultOffsetY(offsetY);
  }

  openInput(target: ElementRef, data: InputDialogData): PopoverRef<InputDialogComponent, { value: string }> {
    return this.open<InputDialogComponent>(InputDialogComponent, target, { data });
  }

  openMessage(
    target: ElementRef,
    data: MessageDialogData,
    popoverConfig?: PopoverConfig
  ): PopoverRef<MessageDialogComponent> {
    return this.open<MessageDialogComponent>(MessageDialogComponent, target, { ...popoverConfig, data: data });
  }

  openDialogComponent(component: any, target: ElementRef, data: any, popoverConfig?: PopoverConfig): PopoverRef {
    return this.open(component, target, { ...popoverConfig, data: data });
  }
}
