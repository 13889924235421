import { ResourceStateModel } from '@app/store-utils/resource-state.model';
import { UserModel, UserRole } from '@auth/models/user.model';
import { AuthStateModel } from '@auth/store/auth.actions';
import { AuthState } from '@auth/store/auth.state';
import { UserState, UserStoreModel } from '@main/store/user/user.state';
import { Selector } from '@ngxs/store';

export class UserSelectors {
  @Selector([UserState])
  static user(state: UserStoreModel): UserModel {
    return state?.userResource?.payload;
  }

  @Selector([UserState])
  static userResource(state: UserStoreModel): ResourceStateModel<UserModel> {
    return state?.userResource;
  }

  @Selector([UserState, AuthState])
  static isLoggingIn(state: UserStoreModel, authState: AuthStateModel): boolean {
    const userResource = UserSelectors.userResource(state);
    if (authState?.tokenResource?.loading) return true;
    if (userResource?.loading) return true;

    // There is a brief moment between success of get token and the start of get user
    // that neither is loading. This checks for that.
    return authState?.tokenResource?.payload && !userResource?.payload;
  }

  @Selector([UserState])
  static isSysAdmin(state: UserStoreModel): boolean {
    return state?.userResource?.payload?.authorizations?.indexOf(UserRole.ROLE_ADMIN) > -1;
  }
}
