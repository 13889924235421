import { ProductRole } from '@auth/models/user.model';
import { DateRange } from '@common/models/common.model';
import { OrgMemberModel } from '@common/models/org-mem.model';
import { isRequestingOrg, OrgFunction } from '@common/models/org-type.model';
import { OrgMembershipSelectors } from '@main/store/org-membership/org-membership.selectors';
import { OrgMembershipState, OrgMembershipStateModel } from '@main/store/org-membership/org-membership.state';
import { OrgProduct } from '@main/store/product/product.model';
import { ProductSelectors } from '@main/store/product/product.selectors';
import {
  HideSiteFilterModel,
  SiteFilterOptions,
  SiteFilterStateModel
} from '@main/store/site-filter/site-filter.models';
import { SiteFilterState } from '@main/store/site-filter/site-filter.state';
import { UserSelectors } from '@main/store/user/user.selectors';
import { createSelector, Selector } from '@ngxs/store';
import { PolicyModel } from '@smart/component/policy/model/policy.model';
import { OrgModel } from '@main/org/models/org.model';

export class SiteFilterSelectors {
  @Selector([SiteFilterState])
  static siteFilters(state: SiteFilterStateModel): SiteFilterStateModel {
    return state ? state : {};
  }

  @Selector([SiteFilterSelectors.siteFilters])
  static selectedAccounts(state: SiteFilterStateModel): number[] {
    return state[SiteFilterOptions.ACCOUNT];
  }

  @Selector([SiteFilterSelectors.siteFilters])
  static selectedPolicies(state: SiteFilterStateModel): PolicyModel[] {
    return state[SiteFilterOptions.POLICY];
  }

  @Selector([SiteFilterSelectors.siteFilters])
  static selectedEffectiveDateRange(state: SiteFilterStateModel): DateRange {
    return state[SiteFilterOptions.EFFECTIVE_DATE_RANGE];
  }

  @Selector([SiteFilterSelectors.siteFilters])
  static selectedPbms(state: SiteFilterStateModel): number[] {
    return state[SiteFilterOptions.PBM];
  }

  // This is a single org
  @Selector([SiteFilterState, OrgMembershipSelectors.orgMemberships])
  static singleSelectedOrgMembership(state: SiteFilterStateModel, orgMemberships: OrgMemberModel[]): OrgMemberModel {
    const orgId = SiteFilterSelectors.singleSelectedOrgId(state);
    if (!orgId) return;
    return orgMemberships?.find((orgMem) => orgMem.orgId === orgId);
  }

  @Selector([SiteFilterState, OrgMembershipSelectors.orgMemberships])
  static selectedOrgMemberships(state: SiteFilterStateModel, orgMemberships: OrgMemberModel[]): OrgMemberModel[] {
    const orgIds = state[SiteFilterOptions.ORG_MEMBERSHIP];
    if (!orgIds?.length) return [];
    return orgIds.map((orgId) => orgMemberships?.find((orgMem) => orgMem.orgId === orgId)).filter((val) => !!val);
  }

  @Selector([SiteFilterState])
  static singleSelectedOrgId(siteFilters: SiteFilterStateModel): number {
    if (!siteFilters) return null;
    const selectedOrgs = siteFilters[SiteFilterOptions.ORG_MEMBERSHIP];
    return selectedOrgs ? selectedOrgs[0] : null;
  }

  @Selector([SiteFilterState])
  static hideSiteFilter(siteFilters: SiteFilterStateModel): HideSiteFilterModel {
    const val = !!siteFilters && siteFilters[SiteFilterOptions.HIDE_SITE_FILTER];
    return !!val ? val : { hide: false }; // default to showing the sitewide filter
  }

  @Selector([SiteFilterState])
  static showEffectiveDateFilter(siteFilters: SiteFilterStateModel): HideSiteFilterModel {
    const val = !!siteFilters && siteFilters[SiteFilterOptions.SHOW_EFFECTIVE_DATE_FILTER];
    return !!val ? val : { hide: true }; // default to not showing effective date filter
  }

  @Selector([SiteFilterState])
  static showUnderwriterFilter(siteFilters: SiteFilterStateModel): HideSiteFilterModel {
    const val = !!siteFilters && siteFilters[SiteFilterOptions.SHOW_UNDERWRITER_FILTER];
    return !!val ? val : { hide: true }; // default to not showing underwriter filter
  }

  @Selector([SiteFilterState])
  static showPbmFilter(siteFilters: SiteFilterStateModel): HideSiteFilterModel {
    const val = !!siteFilters && siteFilters[SiteFilterOptions.SHOW_PBM_FILTER];
    return !!val ? val : { hide: true }; // default to not showing pbm filter
  }

  @Selector([SiteFilterSelectors.siteFilters])
  static selectedUnderwriters(state: SiteFilterStateModel): OrgModel[] {
    return state[SiteFilterOptions.UNDERWRITER];
  }

  @Selector([OrgMembershipState, SiteFilterState])
  static selectedOrgIds(state: OrgMembershipStateModel, siteFilters?: SiteFilterStateModel): number[] {
    return siteFilters[SiteFilterOptions.ORG_MEMBERSHIP];
  }

  static isOrgMember(
    orgId: number
  ): (state: SiteFilterStateModel, orgMembershipState: OrgMembershipStateModel, isSysAdmin?: boolean) => boolean {
    return createSelector(
      [SiteFilterState, OrgMembershipState, UserSelectors.isSysAdmin],
      (state: SiteFilterStateModel, orgMembershipState: OrgMembershipStateModel, isSysAdmin?: boolean) => {
        if (isSysAdmin) return true;
        return !!OrgMembershipSelectors.orgMembershipById(orgId)(orgMembershipState);
      }
    );
  }

  static isOrgAdmin(orgId?: number) {
    return createSelector(
      [SiteFilterState, OrgMembershipSelectors.orgMemberships, UserSelectors.isSysAdmin],
      (state: SiteFilterStateModel, orgMemberships: OrgMemberModel[], isSysAdmin?: boolean) => {
        if (isSysAdmin) return true;
        // If no orgId is provided, use currently selected org
        if (orgId) {
          const orgMembership = OrgMembershipSelectors.orgMembershipById(orgId)({ orgMemberships });
          return orgMembership?.admin;
        }

        const selectedOrgMems = SiteFilterSelectors.selectedOrgMemberships(state, orgMemberships);
        if (selectedOrgMems?.length) return selectedOrgMems.some((orgMem) => orgMem.admin);
        return orgMemberships.some((orgMem) => orgMem.admin);
      }
    );
  }

  @Selector([SiteFilterState])
  static orgFunction(state: SiteFilterStateModel): OrgFunction {
    if (!state) return null;
    return state[SiteFilterOptions.QUOTE_FUNCTION];
  }

  @Selector([SiteFilterState])
  static isRequestingOrg(state: SiteFilterStateModel): boolean {
    return isRequestingOrg(SiteFilterSelectors.orgFunction(state));
  }

  @Selector([SiteFilterState, OrgMembershipSelectors.orgMemberships])
  static isRespondingOrg(state: SiteFilterStateModel): boolean {
    return (
      SiteFilterSelectors.orgFunction(state) === OrgFunction.RESPOND ||
      SiteFilterSelectors.orgFunction(state) === OrgFunction.PBM
    );
  }

  @Selector([SiteFilterState, OrgMembershipSelectors.orgMemberships])
  static isRxRespondingOrg(state: SiteFilterStateModel): boolean {
    return SiteFilterSelectors.orgFunction(state) === OrgFunction.PBM;
  }

  @Selector([SiteFilterState, OrgMembershipSelectors.orgMemberships])
  static isOfflineOrg(state: SiteFilterStateModel): boolean {
    return SiteFilterSelectors.orgFunction(state) === OrgFunction.OFFLINE_RESPOND;
  }

  static singleSelectedOrgMembershipProductRole(
    product?: OrgProduct
  ): (
    state: SiteFilterStateModel,
    orgMemberships: OrgMemberModel[],
    selectedProduct?: OrgProduct,
    isSysAdmin?: boolean
  ) => ProductRole {
    return createSelector(
      [SiteFilterState, OrgMembershipSelectors.orgMemberships, ProductSelectors.selectedProduct],
      (state: SiteFilterStateModel, orgMemberships, selectedProduct?) => {
        if (!product) {
          product = selectedProduct;
        }

        const selectedProductOrgMemberships = SiteFilterSelectors.selectedOrgMemberships(state, orgMemberships);

        const managerRoles = selectedProductOrgMemberships.some((orgMem) => orgMem[product] === ProductRole.MANAGER);
        if (managerRoles) return ProductRole.MANAGER;

        const memberRoles = selectedProductOrgMemberships.some((orgMem) => orgMem[product] === ProductRole.MEMBER);
        if (memberRoles) return ProductRole.MEMBER;

        return ProductRole.NONE;
      }
    );
  }
}
