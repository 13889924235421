import { Injectable } from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { EndpointFlow, EndpointModel } from '@main/org-endpoint/model/org-endpoint.model';
import { EndpointService } from '@main/org-endpoint/service/org-endpoint.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EndpointProducerOptionService extends AsyncOptions<any> {
  key = 'endpointproducers';

  constructor(private endpointService: EndpointService) {
    super();
  }

  filter = () =>
    this.endpointService.getEndpoints().pipe(
      map((result) => result.filter((endpoint) => endpoint.flow === EndpointFlow.PRODUCER)),
      map((result) => ({
        content: this.generateLabelValues(result),
        total: result.length
      }))
    );

  valuesFromKeys = (values: number[]) => {
    if (!values?.length) return of(null);
    return this.endpointService.getEndpoints().pipe(
      map((result) => result.filter((endpoint) => endpoint.flow === EndpointFlow.PRODUCER)),
      map((result) => this.generateLabelValues(result))
    );
  };
}
