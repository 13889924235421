<div class="mapping-group pb-3">
  <div class="mapping-label">{{ name }}</div>
  <ng-container *ngFor="let item of field.mapping">
    <div class="label-text text-no-overflow">{{ item }}</div>
    <div class="dense-layout">
      <mat-form-field class="dense mapping-select-field" [style.padding-bottom]="'0.45rem'">
        <mat-select [value]="valueMapTo[item]" (selectionChange)="onSelect(item, $event.value)" required>
          <mat-option *ngFor="let enum of field.values | keyvalue; let key = index" [value]="enum.value"
            >{{ enum.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div></div>
  </ng-container>
</div>
