import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BadgeDef } from '@info-lib/info-badge/badge-def.model';

@Component({
  selector: 'app-info-badge',
  template: `
    @if (display) {
      <div class="info-badge bg-{{ color }}">
        <fa-icon [matTooltip]="hover" class="badge-icon" [icon]="icon"></fa-icon>
        <span class="badge-content">{{ display }}</span>
      </div>
    }
  `,
  styleUrls: ['./info-badge.component.scss']
})
export class InfoBadgeComponent {
  @Input() badgeDef: BadgeDef;
  @Input() data: any;

  get color(): string {
    return this.badgeDef.colorFn ? this.badgeDef.colorFn(this.data) : this.badgeDef.color;
  }

  get icon(): IconDefinition {
    return this.badgeDef.iconFn ? this.badgeDef.iconFn(this.data) : this.badgeDef.icon;
  }

  get display(): any {
    return this.badgeDef.displayFn ? this.badgeDef.displayFn(this.data) : this.badgeDef.display;
  }

  get hover(): any {
    return this.badgeDef.hoverFn ? this.badgeDef.hoverFn(this.data) : this.badgeDef.hover;
  }
}
