import { NgModule } from '@angular/core';
import { ScrollIndicatorComponent } from '@common/components/scroll-indicator/scroll-indicator.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [SharedModule],
  exports: [ScrollIndicatorComponent],
  declarations: [ScrollIndicatorComponent]
})
export class ScrollIndicatorModule {}
