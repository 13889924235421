import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { alertClassMap, AlertLevelType } from '@common/models/alert-level.model';
import { NotificationComponent } from './notification.component';
import { NotificationConfig } from './notification.models';

const defaultDuration = 3000;
const persistentErrorKey = 'persistentError';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  get persistentError() {
    const error = sessionStorage.getItem(persistentErrorKey);

    // Persistent messages are intended to be one-time pieces of
    // information, so they should be removed after first retrieval
    sessionStorage.removeItem(persistentErrorKey);

    return error === 'null' ? null : error;
  }

  set persistentError(message: string) {
    sessionStorage.setItem(persistentErrorKey, message);
  }

  openNotification({ duration, message, type, items, action, actionText }: NotificationConfig) {
    if (!duration) {
      duration = defaultDuration;
    }
    return this.snackBar.openFromComponent(NotificationComponent, {
      duration,
      announcementMessage: message,
      verticalPosition: 'top',
      panelClass: alertClassMap(type),
      data: {
        message,
        type,
        items,
        action,
        actionText
      }
    });
  }

  successfulNotification(message = 'Success') {
    return this.openNotification({ message, type: AlertLevelType.SUCCESS });
  }

  warningNotification(message: string) {
    return this.openNotification({ message, type: AlertLevelType.ATTENTION });
  }

  failedNotification(message: string, items?, duration = 5000) {
    return this.openNotification({ message, type: AlertLevelType.WARN, duration, items });
  }

  actionNotification({ message, action, actionText, duration = defaultDuration }: NotificationConfig) {
    return this.openNotification({ message, action, actionText, duration, type: AlertLevelType.INFO });
  }
}
