import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { GlobalTpaModel } from '@common/models/global-tpa.model';
import { Observable } from 'rxjs';
import { Page } from '@common/models/page.model';
import { OrgModel } from '@main/org/models/org.model';
import { UrlUtilService } from '@common/services/url-util.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalTpaService {
  get baseUrl() {
    return `${SERVER_API_URL}/global-tpa`;
  }

  constructor(private http: HttpClient, private urlUtil: UrlUtilService) {}

  find(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<GlobalTpaModel>> {
    return this.http.get(`${this.baseUrl}`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  getById(globalTpaId: number): Observable<GlobalTpaModel> {
    return this.http.get<GlobalTpaModel>(`${this.baseUrl}/${globalTpaId}`);
  }

  nameExists(id: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );

    if (id) {
      params = params.set('id', `${id}`);
    }

    return this.http.get<boolean>(`${this.baseUrl}/exists`, {
      params: params
    });
  }

  create(globalTpa: GlobalTpaModel): Observable<GlobalTpaModel> {
    return this.http.post<GlobalTpaModel>(this.baseUrl, globalTpa);
  }

  update(globalTpa: GlobalTpaModel): Observable<GlobalTpaModel> {
    return this.http.put<GlobalTpaModel>(`${this.baseUrl}/${globalTpa.id}`, globalTpa);
  }

  delete(globalTpa: GlobalTpaModel): Observable<GlobalTpaModel> {
    return this.http.delete(`${this.baseUrl}/${globalTpa.id}`);
  }
}
