import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getFormattedDateString } from '@app/tools/date';

@Component({
  selector: 'app-error-renderer',
  template: `
    <div *ngIf="errors" truncateText>{{ errorMessage }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldErrorComponent {
  @Input() errors;

  get errorMessage() {
    if (!this.errors) return null;

    const keys = Object.keys(this.errors);
    if (!keys.length) return null;

    switch (keys[0]) {
      case 'min':
        return `Must be greater than or equal to ${this.errors.min.min}.`;
      case 'max':
        return `Must be less than or equal to ${this.errors.max.max}.`;
      case 'minlength':
        return `Must be at least ${this.errors.minlength.requiredLength} characters long.`;
      case 'maxlength':
        return `Must be at most ${this.errors.maxlength.requiredLength} characters long.`;
      case 'url':
        return `Valid URL required.`;
      case 'zip':
        return `Valid zip code required.`;
      case 'required':
        return `This field is required.`;
      case 'fedId':
        return `Valid federal ID required, xx-xxxxxxx`;
      case 'email':
        return 'Valid email required.';
      case 'matStartDateInvalid':
        return 'Start date must be before end date';
      case 'matEndDateInvalid':
        return 'Start date must be before end date';
      case 'matDatepickerParse':
        return 'Date must be formatted mm/dd/yyyy or mmddyyyy';
      case 'matDatepickerMin':
        return `Date must be on or after ${getFormattedDateString(this.errors.matDatepickerMin.min)}`;
      case 'matDatepickerMax':
        return `Date must be on or before ${getFormattedDateString(this.errors.matDatepickerMax.max)}`;
      case 'mustBeInteger':
        return `Must be a whole number`;
      default:
        return this.errors[keys[0]];
    }
  }
}
