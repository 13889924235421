import { NgModule } from '@angular/core';
import { DisplayCardKpiGroupComponent } from '@app/libraries/workspace-lib/components/display-card-kpi/display-card-kpi-group.component';
import { DisplayCardKpiComponent } from '@app/libraries/workspace-lib/components/display-card-kpi/display-card-kpi.component';
import { DisplayCardTagComponent } from '@app/libraries/workspace-lib/components/display-card-tag/display-card-tag.component';
import { WorkspaceHeaderLabelDirective } from '@app/libraries/workspace-lib/components/header/workspace-header-label.directive';
import { WorkspaceHeaderRowDirective } from '@app/libraries/workspace-lib/components/header/workspace-header-row.directive';
import { WorkspaceHeaderStatusLinkComponent } from '@app/libraries/workspace-lib/components/header/workspace-header-status-link.component';
import { WorkspaceHeaderStatusComponent } from '@app/libraries/workspace-lib/components/header/workspace-header-status.component';
import { WorkspaceHeaderComponent } from '@app/libraries/workspace-lib/components/header/workspace-header.component';
import { KpiComponent } from '@app/libraries/workspace-lib/components/kpi/kpi.component';
import { TagsDisplayComponent } from '@app/libraries/workspace-lib/components/tags/tags-display.component';
import { WorkspaceItemDisplayComponent } from '@app/libraries/workspace-lib/components/workspace-item-display/workspace-item-display.component';
import { RmtChipModule } from '@common/components/rmt-chip/rmt-chip.module';
import { LibResourceModule } from '@lib-resource/lib-resource.module';
import { SharedModule } from '@shared/shared.module';
import { FilterBrickRowsComponent } from './components/workspace-filter-bricks/filter-brick-rows/filter-brick-rows.component';

const exportItems = [
  DisplayCardKpiComponent,
  DisplayCardKpiGroupComponent,
  DisplayCardTagComponent,
  FilterBrickRowsComponent,
  KpiComponent,
  WorkspaceHeaderComponent,
  TagsDisplayComponent,
  WorkspaceHeaderLabelDirective,
  WorkspaceHeaderRowDirective,
  WorkspaceHeaderStatusComponent,
  WorkspaceHeaderStatusLinkComponent,
  WorkspaceItemDisplayComponent
];

@NgModule({
  declarations: [...exportItems],
  exports: [...exportItems],
  imports: [SharedModule, LibResourceModule, RmtChipModule]
})
export class WorkspaceLibModule {}
