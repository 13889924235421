import { UserModel } from '@auth/models/user.model';

export enum FileDocType {
  ACCIDENT = 'ACCIDENT',
  AGG_REPORT = 'AGG_REPORT',
  APPEAL = 'APPEAL',
  CLAIM_COPY = 'CLAIM_COPY',
  CLAIM_HISTORY = 'CLAIM_HISTORY',
  COBRA_MEMBER_LIST = 'COBRA_MEMBER_LIST',
  CONTRACT = 'CONTRACT',
  CURRENT_RENEWAL_RATES = 'CURRENT_RENEWAL_RATES',
  DISCLOSURE = 'DISCLOSURE',
  DISCLOSURE_FORM = 'DISCLOSURE_FORM',
  EOR = 'EOR',
  FLAGGED_MEMBERS = 'FLAGGED_MEMBERS',
  FULL_BENEFITS_GRIDS = 'FULL_BENEFITS_GRIDS',
  IMAGE = 'IMAGE',
  IN_FORCE_RATE = 'IN_FORCE_RATE',
  ITEMIZED_BILL = 'ITEMIZED_BILL',
  LARGE_CLAIM_EXPERIENCE = 'LARGE_CLAIM_EXPERIENCE',
  LASER = 'LASER',
  LDD_LIST = 'LDD_LIST',
  MEQ = 'MEQ',
  OTHER = 'OTHER',
  PARTICIPANT = 'PARTICIPANT',
  PENDED_CLAIM_REPORT = 'PENDED_CLAIM_REPORT',
  PLAN_DESIGN = 'PLAN_DESIGN',
  PRE_CERT = 'PRE_CERT',
  PROOF_OF_INS = 'PROOF_OF_INS',
  PROPOSAL = 'PROPOSAL',
  PROPOSAL_TEMPLATE = 'PROPOSAL_TEMPLATE',
  SDL_LIST = 'SDL_LIST',
  TRIGGER_DIAG_REPORT = 'TRIGGER_DIAG_REPORT',
  WORKSTATUS = 'WORKSTATUS'
}

export class FileModel {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public base64MD5?: string,
    public base64Sha256?: string,
    public fileSize?: number,
    public mimeType?: string,
    public createdDate?: any,
    public lastModifiedDate?: any,
    public docType?: FileDocType,
    public versionId?: number,
    public createdBy?: UserModel
  ) {}
}

// extended file model that assists in encapsulating the UI raw File with FileModel (RmtFile data like name, description, and docType)
// so the information moves together
export class FileExtModel {
  fileModel?: FileModel;
  file?: File;
}

export function isPdf(fileModel: FileModel): boolean {
  return fileModel?.mimeType?.toLowerCase() === 'application/pdf';
}

export function isExcel(fileModel: FileModel): boolean {
  return fileModel?.mimeType?.toLowerCase() === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
}
