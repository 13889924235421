import { CurrencyPipe } from '@angular/common';
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { setAndPreserveErrors } from '@form-lib/validators/form.validators';

/**
 * Formats input field value into a currency format for display purposes
 */
@Directive({ selector: '[appCurrencyFormatter]' })
export class CurrencyFormatterDirective implements OnInit {
  @Input() dollarsOnly: boolean = false;
  @Input() useParenthesis: boolean = false;
  ngOnInit() {
    if (this.isValidCurrency) {
      this.applyCurrencyPipeToValue();
    }
  }

  constructor(
    private ngControl: NgControl,
    private currencyPipe: CurrencyPipe
  ) {}

  @HostListener('focus')
  onFocus() {
    this.valueAccessor.writeValue(this.ctrl.value);
  }

  @HostListener('blur')
  formatToCurrency() {
    let inError = false;
    if (!(this.ctrl.value === undefined || this.ctrl.value === null || this.ctrl.value === '')) {
      if (this.isValidCurrency) {
        this.applyCurrencyPipeToValue();
      } else {
        this.valueAccessor.writeValue(this.ctrl.value);
        inError = true;
      }
    }
    setAndPreserveErrors(this.ctrl, inError, 'currency', this.errorMessage);
  }

  get ctrl() {
    return this.ngControl.control;
  }

  get valueAccessor() {
    return this.ngControl.valueAccessor;
  }

  get isValidCurrency(): boolean {
    return this.dollarsOnly
      ? !!this.ctrl?.value?.toString().match(/(^-?\d+$)/)
      : !!this.ctrl?.value?.toString().match(/(^(-[0-9])?\d*(\.\d{1,2})?$)/);
  }

  get errorMessage(): string {
    return this.dollarsOnly ? 'Valid dollar (no decimals) amount required.' : 'Valid currency amount required.';
  }

  applyCurrencyPipeToValue() {
    const strVal = this.currencyPipe.transform(+this.ctrl.value, 'USD', 'symbol', this.dollarsOnly ? '1.0-0' : '1.2-2');
    this.valueAccessor.writeValue(this.useParenthesis ? `( ${strVal} )` : strVal);
  }
}
