import { LaserModel, LaserRelationship } from '@common/models/quote-response.model';

export class LaserUiModel {
  name?: string;
  relationship?: LaserRelationship;
  conditional?: boolean;
  condition?: string;
  uiId?: number; // necessary to track models when the name doesn't exist (new ones)
  options?: LaserModel[];

  constructor(name?: string, relationship?: LaserRelationship) {
    this.name = name;
    this.relationship = relationship;
    this.options = [];
  }
}
