import { AsyncValidatorFn } from '@angular/forms';
import { AsyncFormValidatorDef } from '@form-lib/models/validators.model';
import { DataDefModel } from '@lib-resource/data-def.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export class FormSectionInfo {
  icon?: IconDefinition;
  iconClass?: string;
  label?: string;
  labelClass?: string;
}

export class FormSection {
  label?: string;
  key?: any;
  sections?: FormSection[];
  displayItems?: string[];
  icon?: string;
  fullWidth?: boolean;
  info?: FormSectionInfo;
}

export class FormModel {
  fields: DataDefModel[];
  sections?: FormSection[];
  validators?: {
    [prop: string]: any;
  };

  asyncValidators?: Array<AsyncFormValidatorDef | AsyncValidatorFn>;
}

export enum LabelOnlyClasses {
  heading = 'section-label-text mat-h1 mb-0',
  subHeading = 'sub-section-label-text mb-0'
}
