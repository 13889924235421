import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RapidQuoteRxProfileModel } from '@common/models/rapid-quote-rx-profile.model';
import { SERVER_API_URL } from '@app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class RapidQuoteRxProfileService {
  constructor(private http: HttpClient) {}

  updateRapidQuoteRxProfile(
    orgId: number,
    rapidQuoteRxProfile: RapidQuoteRxProfileModel
  ): Observable<RapidQuoteRxProfileModel> {
    return this.http.put(`${SERVER_API_URL}/org/${orgId}/rapid-quote-rx-profile`, rapidQuoteRxProfile);
  }

  findRapidQuoteRxProfiles(orgId: number): Observable<RapidQuoteRxProfileModel[]> {
    return this.http.get<RapidQuoteRxProfileModel[]>(`${SERVER_API_URL}/org/${orgId}/rapid-quote-rx-profile`);
  }

  deleteRapidQuoteRxProfile(orgId: number, profileId: number): Observable<void> {
    return this.http.delete<void>(`${SERVER_API_URL}/org/${orgId}/rapid-quote-rx-profile/${profileId}`);
  }
}
