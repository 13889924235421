import { Injectable } from '@angular/core';
import { OrgFunction } from '@common/models/org-type.model';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgHttpService } from '@main/org/org-http.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UnderwriterOrgOptionService extends AsyncOptions<string> {
  key = 'underwriterOrg';
  defaultSort = ['name'];

  constructor(private orgHttpService: OrgHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) => {
    requiredQuery = requiredQuery
      ? `(${requiredQuery}) and (orgType.orgFunction = '${OrgFunction.RESPOND}' or orgType.orgFunction = '${OrgFunction.OFFLINE_RESPOND}')`
      : `(orgType.orgFunction = '${OrgFunction.RESPOND}' or orgType.orgFunction = '${OrgFunction.OFFLINE_RESPOND}')`;
    return this.orgHttpService
      .searchAllOrgs(this.generateFilterString(value, requiredQuery), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));
  };

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.orgHttpService
      .searchAllOrgs(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  private generateFilterString = (value, requiredQuery) =>
    this.combineFilters(value ? `name ~ '${value}'` : '', requiredQuery);
}
