<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label>Edit File Properties</app-dialog-label>
<app-dialog-content>
  <p>Id: {{ data.activeSupportFileId }}</p>
  <mat-form-field>
    <mat-label>File name</mat-label>
    <input matInput [(ngModel)]="data.fileName" required />
  </mat-form-field>
  <mat-form-field>
    <mat-label>File Type</mat-label>
    <mat-select [(ngModel)]="data.docType" [disabled]="data.restrictDocTypeUpdate">
      <mat-option *ngFor="let type of data.docTypes" [value]="type"> {{ type | docTypeMapping }} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="data.description" />
  </mat-form-field>
</app-dialog-content>
<app-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="data"
    [disabled]="!(data.fileName && data.fileName.length > 0)"
  >
    Save
  </button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</app-dialog-actions>
