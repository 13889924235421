import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopoverService } from '@shared/components/popover/popover.service';
import { QueryDisplayComponent } from './query-display.component';
import { QueryDisplayModel } from './query-display.model';

@Injectable({
  providedIn: 'root'
})
export class QueryDisplayService {
  constructor(private dialog: MatDialog, private popover: PopoverService) {}

  openDialog(queries: QueryDisplayModel[]) {
    this.dialog.open(QueryDisplayComponent, {
      data: queries,
      width: '800px'
    });
  }

  openPopover(target, queries: QueryDisplayModel[]) {
    this.popover.open(QueryDisplayComponent, target, {
      data: queries
    });
  }
}
