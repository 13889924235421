import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { BaseStatusIndicatorDirective } from '@shared/directives/status-indicator/base-status-indicator.directive';

enum StatusColor {
  success = 'success',
  primary = 'primary',
  attention = 'attention',
  warn = 'warn',
  accent = 'accent',
  neutral = 'neutral'
}

@Directive({
  selector: 'button[statusIndicator]'
})
export class StatusIndicatorButtonDirective extends BaseStatusIndicatorDirective {
  protected _errorClass = `bg-${StatusColor.warn}`;
  protected _successClass = `bg-${StatusColor.success}`;
  constructor(_el: ElementRef, _renderer: Renderer2) {
    super(_el, _renderer);
  }
}
