import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { DisplayCardKpiComponent } from '@app/libraries/workspace-lib/components/display-card-kpi/display-card-kpi.component';
import { KpiDefModel } from '@app/libraries/workspace-lib/components/kpi/kpi-def.model';

@Component({
  selector: 'app-display-card-kpi-group',
  templateUrl: './display-card-kpi-group.component.html',
  styleUrls: ['./display-card-kpi-group.component.scss']
})
export class DisplayCardKpiGroupComponent {
  @Input() kpiDefs: KpiDefModel[];
  @Input() selectedKpi: string;
  @Output() kpiSelected = new EventEmitter<string>();
  @ViewChildren('displayCardKpi') kpiComponents: QueryList<DisplayCardKpiComponent>;

  constructor() {}

  onKpiSelect(kpi: string) {
    this.selectedKpi = kpi;
    this.kpiSelected.emit(kpi);
  }
}
