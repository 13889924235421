<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label>
  {{ data.title }}
</app-dialog-label>
<app-dialog-content>
  <div #viewer class="excel-viewer"></div>
</app-dialog-content>
<app-dialog-actions>
  <button mat-raised-button mat-dialog-close>Close</button>
</app-dialog-actions>
