import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-data-table-cell',
  template: `
    <ng-container *ngIf="columnDef.actions; else noAction">
      <div class="grid-content">
        <ng-container *ngFor="let action of columnDef.actions">
          <button
            mat-icon-button
            (click)="action.callback(row, null, null, $event)"
            [matTooltip]="action.label | derivedActionLabel: action:row"
            [class]="action.classes"
            [disabled]="action.disableIf && action.disableIf(row)"
            style="white-space: nowrap"
          >
            <ng-container *ngIf="action.derivedIcon && action.derivedIcon(row) as icons; else icon">
              <fa-stack class="pt-1" *ngIf="icons.length > 1; else oneClass">
                <fa-icon
                  *ngFor="let derivedIcon of icons"
                  [icon]="derivedIcon.icon"
                  [inverse]="derivedIcon.inverse"
                  [classes]="derivedIcon.classes"
                  [stackItemSize]="derivedIcon.stackItemSize"
                >
                </fa-icon>
              </fa-stack>
              <ng-template #oneClass>
                <fa-icon [icon]="icons[0].icon" [inverse]="icons[0].inverse" [classes]="icons[0].classes"></fa-icon>
              </ng-template>
            </ng-container>
            <ng-template #icon>
              <fa-icon [icon]="action.icon"></fa-icon>
            </ng-template>
            <span
              class="action-text"
              [class]="{ 'action-text-enabled': !(action.disableIf && action.disableIf(row)) }"
              >{{ row | dataFormatter: columnDef }}</span
            >
          </button>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noAction>
      <ng-container *ngIf="columnDef.derivedIcon && columnDef.derivedIcon(row, columnDef) as derivedIcons">
        <fa-stack class="pt-1" *ngIf="derivedIcons.length > 1; else oneClass">
          <fa-icon
            *ngFor="let derivedIcon of derivedIcons"
            [icon]="derivedIcon.icon"
            [inverse]="derivedIcon.inverse"
            [classes]="derivedIcon.classes"
            [stackItemSize]="derivedIcon.stackItemSize"
          >
          </fa-icon>
        </fa-stack>
        <ng-template #oneClass>
          <app-in-progress-indicator
            *ngIf="derivedIcons[0]?.classes[0]?.startsWith('spinner'); else nonSpinner"
            style="display: inline-block; position: relative; vertical-align: middle;"
            diameter="1rem"
          ></app-in-progress-indicator>
        </ng-template>
        <ng-template #nonSpinner>
          <fa-icon
            [icon]="derivedIcons[0].icon"
            [classes]="derivedIcons[0].classes"
            [inverse]="derivedIcons[0].inverse"
          ></fa-icon>
        </ng-template>
      </ng-container>
      {{ row | dataFormatter: columnDef }}
    </ng-template>
  `,
  styleUrls: ['data-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableCellComponent {
  @Input()
  row;

  @Input()
  columnDef: DataDefModel;

  constructor() {}

  @Memoize()
  isArray(classes: any) {
    return Array.isArray(classes);
  }
}
