import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { RxContractService } from '@common/services/rx-contract.service';
import { RxContractModel } from '@common/models/rx-contract.model';
import { getFormattedDateString } from '@app/tools/date';

@Injectable()
export class RxContractOptionService extends AsyncOptions<RxContractModel | number> {
  key = 'rxContractModels';
  defaultSort = ['contractName'];

  constructor(private rxContractService: RxContractService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.rxContractService
      .search(
        this.combineFilters(
          this.generateFilterString(control, asyncOptionDeps, value, requiredFilter),
          asyncExtras?.filter
        ),
        pageIndex,
        pageSize,
        this.defaultSort,
        asyncExtras?.ignoreSiteFilter === true ? [] : null
      )
      .pipe(
        map(({ content, total }) => ({ content: this.genLabelVals(content, asyncExtras?.useModelAsValue), total }))
      );

  valuesFromKeys = (value: RxContractModel | RxContractModel[], asyncExtras?) => {
    // can either be an array or an individual item
    if (value && value['length']) {
      return of(this.genLabelVals(value as RxContractModel[], asyncExtras?.useModelAsValue));
    }
    if (value && value['id']) {
      return of(this.genLabelVals([value as RxContractModel], asyncExtras?.useModelAsValue));
    }
    return of(null);
  };

  private generateFilterString = (control, asyncOptionDeps, value, requiredQuery?) => {
    const filterString = [
      this.buildContainsQuery(value, 'name'),
      asyncOptionDeps?.length >= 1
        ? this.buildContainsQuery(control.parent.get(asyncOptionDeps[0])?.value, 'orgid')
        : undefined,
      asyncOptionDeps?.length >= 2
        ? this.buildContainsQuery(control.parent.get(asyncOptionDeps[1])?.value, 'accountid')
        : undefined
    ]
      .filter((filter) => filter !== undefined)
      .join(' AND ');

    return this.combineFilters(filterString, requiredQuery);
  };

  private buildContainsQuery = (asyncOptionDepValue, queryKey) => {
    const itemsFromValue = asyncOptionDepValue || [];
    const items = Array.isArray(itemsFromValue) ? itemsFromValue : [itemsFromValue];
    return items.length ? `${queryKey} ~ (${items.join(',')})` : undefined;
  };

  genLabelVals(
    contracts: RxContractModel[],
    useModelAsValue: boolean
  ): { label: string; value: RxContractModel | number }[] {
    return contracts.map((contract) => ({
      label: `${contract.contractName} (${getFormattedDateString(contract.effectiveDate)})`,
      value: useModelAsValue ? contract : contract.id
    }));
  }
}
