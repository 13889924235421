import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appDate'
})
export class AppDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    return super.transform(value, 'M/d/yyyy', timezone, locale);
  }
}
