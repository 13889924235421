import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FieldValidators } from '@form-lib/validators/field.validators';
import { Subject, Subscription } from 'rxjs';
import { RangeModel } from './range.model';

@Component({
  selector: 'app-range-input',
  template: `
    <div [formGroup]="parts">
      <input type="number" class="input-element" formControlName="min" [placeholder]="disabled ? '' : 'min'" />
      <span class="input-spacer">&ndash;</span>
      <input
        type="number"
        class="input-element"
        formControlName="max"
        [readonly]="readonly"
        [placeholder]="disabled ? '' : 'max'"
        (blur)="onTouched()"
      />
    </div>
  `,
  styleUrls: ['../form-lib.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: RangeInputComponent }]
})
export class RangeInputComponent implements ControlValueAccessor, MatFormFieldControl<any>, OnDestroy, OnInit {
  static nextId = 0;
  sub: Subscription;
  parts: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  @Input() readonly;

  get errorState() {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  controlType = 'range-input';

  get empty() {
    const {
      value: { min, max }
    } = this.parts;

    return !min && !max;
  }

  @HostBinding('id') id = `range-input-${RangeInputComponent.nextId++}`;
  @HostBinding('class.label-floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  // Property not needed since label always floats, but is required to be declared
  placeholder;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.disabled) {
      this.parts.disable();
    } else {
      this.parts.enable();
    }

    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value(): RangeModel | null {
    const {
      value: { min, max }
    } = this.parts;
    return { min, max };
  }

  set value(range: RangeModel) {
    const { min = null, max = null } = range || {};
    this.parts.setValue({ min, max });
    this.stateChanges.next();
  }

  _onTouched;
  private _onChange = (_: any) => {};

  onTouched() {
    this._onTouched();
    this.stateChanges.next();
  }

  constructor(
    fb: UntypedFormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = fb.group({
      min: null,
      max: null
    });

    this.sub = this.parts.valueChanges.subscribe((value) => {
      let minMax;
      const { min, max } = value;
      if (min || max) {
        minMax = {};
      }
      if (min) {
        minMax.min = min;
      }
      if (max) {
        minMax.max = max;
      }
      this._onChange(minMax);
    });

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.ngControl.control.setValidators([
        this.ngControl.control.validator,
        FieldValidators.customValidator(() => {
          const { min, max } = this.parts.value;
          const isMaxEmpty = !!min && !max && max !== 0;
          const isMinEmpty = !!max && !min && min !== 0;
          return !(isMaxEmpty || isMinEmpty);
        }, 'Both or neither must have a value')
      ]);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.sub.unsubscribe();
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => any) {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const inputRef = this.elRef.nativeElement.querySelector('input');
      if (inputRef) {
        inputRef.focus();
      }
    }
  }
}
