<div #scrollFrame class="table-form-wrapper" [style.height]="componentHeight">
  <table
    mat-table
    [dataSource]="dataSource"
    class="dense-layout"
    [ngClass]="control.disabled ? 'form-array-disabled' : 'form-array-enabled'"
  >
    <ng-content></ng-content>
    <ng-container matColumnDef="tableArrayLabels" sticky>
      <th class="table-labels" mat-header-cell *matHeaderCellDef></th>
      <td class="table-labels" truncateText mat-cell *matCellDef="let def">
        <ng-container [ngSwitch]="def.type">
          <div *ngSwitchCase="'spacer'" class="no-label" style="height: 100%">&nbsp;</div>
          <div
            *ngSwitchDefault
            class="{{ def.labelClasses }}"
            [ngClass]="{
              'label-text': def.type !== 'labelOnly',
              readonly: def.readOnly,
              required: def.validators?.required
            }"
          >
            {{ def.label }}
          </div>
        </ng-container>
      </td>
    </ng-container>
    <ng-container
      *ngFor="let option of optionsColumnDefs; let i = index"
      arrayItemFormGroup
      [matColumnDef]="option.value"
    >
      <th class="column-header p-2" #scrollItem mat-header-cell *matHeaderCellDef>
        <div class="column-actions" *ngIf="control.enabled">
          <div>
            <button *ngIf="itemsAddable" mat-icon-button matTooltip="Clone" (click)="cloneItem(i)" type="button">
              <fa-icon [icon]="copyIcon"></fa-icon>
            </button>
            <button *ngIf="itemsRemovable" mat-icon-button matTooltip="Remove" (click)="removeItem(i)" type="button">
              <fa-icon [icon]="trashIcon"></fa-icon>
            </button>
          </div>
        </div>
        {{ option.label }}
      </th>
      <td
        mat-cell
        *matCellDef="let def; let idx = index; let row"
        [ngStyle]="{ 'border-bottom': def.type === 'labelOnly' ? 'none' : '' }"
        class="field-renderer-cell"
      >
        <div
          *ngIf="def.type !== 'labelOnly' && def.type !== 'spacer'"
          [ngClass]="def.fieldRenderClass"
          class="ocr-overlay-wrapper"
        >
          <app-field-renderer
            class="table-field"
            [inline]="true"
            [definition]="def"
            [control]="control.controls[i].controls[def.formKey]"
          >
          </app-field-renderer>
        </div>
        <div
          *ngIf="def.derivedIcon && def.derivedIcon(control.getRawValue()[i]) as derivedIcons"
          class="pl-2 icon-font-medium text-center"
          [ngClass]="def.type !== 'spacer' ? 'dib' : ''"
        >
          <fa-stack class="pl-1" *ngIf="derivedIcons.length > 1; else nonStackedIcon">
            <fa-icon
              *ngFor="let derivedIcon of derivedIcons"
              [icon]="derivedIcon.icon"
              [inverse]="derivedIcon.inverse"
              [stackItemSize]="derivedIcon.stackItemSize"
              [classes]="derivedIcon.classes"
              style="line-height: 30px"
            >
            </fa-icon>
          </fa-stack>
          <ng-template #nonStackedIcon>
            <fa-icon [icon]="derivedIcons[0]?.icon" [classes]="derivedIcons[0]?.classes"></fa-icon>
          </ng-template>
        </div>
        <div *ngIf="def.icon">
          <fa-icon [icon]="def.icon"></fa-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="filler">
      <th class="table-filler" mat-header-cell *matHeaderCellDef></th>
      <td
        class="table-filler"
        mat-cell
        *matCellDef="let def"
        [ngStyle]="{ 'border-bottom': def.type === 'labelOnly' ? 'none' : '' }"
      ></td>
    </ng-container>

    <tr
      mat-row
      *matRowDef="let def; columns: columnsToDisplay$ | async"
      [hidden]="!!def.hiddenRow"
      [ngClass]="{ 'sub-section': !!def.formGroupArraySubSectionName }"
      class="table-array-row"
    ></tr>
  </table>
</div>
<tr class="table-header-row" mat-header-row *matHeaderRowDef="columnsToDisplay$ | async"></tr>
