import { Component, OnInit } from '@angular/core';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { DataDefOption } from '@lib-resource/data-def.model';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-select-field',
  templateUrl: 'select-field.component.html',
  styleUrls: ['../../form-lib.scss']
})
export class SelectFieldComponent extends BaseFieldComponent implements OnInit {
  displayValue;

  ngOnInit() {
    super.ngOnInit();
    if (this.readOnly) {
      if (this.options && this.options.length) {
        this.displayValue = this.options.find((opt) => this.control.value === opt.value);
      } else {
        this.displayValue = this.control.value;
      }
    }
  }

  @Memoize()
  shouldDisable(value: any, option: DataDefOption) {
    if (option.disable) return option.disable;
    return option.disableIf && option.disableIf(value, option);
  }
}
