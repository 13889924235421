import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostBinding,
  Input,
  isDevMode,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DataDefModel } from '@lib-resource/data-def.model';
import { BaseFieldComponent } from '../fields/base-field/base-field.component';
import { FieldComponentMapService } from './field-component-map.service';

@Component({
  selector: 'app-field-renderer',
  template: `
    <div #fieldOutlet></div>
  `,
  providers: [FieldComponentMapService] // inject to make testing easier
})
export class FieldRendererComponent implements OnChanges {
  @Input() definition: DataDefModel;
  @Input() inline: boolean;
  @Input() inlineNoLabel: boolean;
  @Input() control: UntypedFormControl;
  @Input() controls: UntypedFormControl[];
  @Input() hasJoinedField: boolean;
  @Input() isJoinedField: boolean;
  required: boolean;
  @HostBinding('attr.id') componentId;
  @HostBinding('class.field-disable') get isDisabled() {
    return this.control?.disabled || this.controls?.every((c) => c.disabled);
  }

  @ViewChild('fieldOutlet', { static: true, read: ViewContainerRef }) fieldOutlet: ViewContainerRef;
  private componentRef: ComponentRef<BaseFieldComponent>;

  constructor(
    private compiler: ComponentFactoryResolver,
    private fieldComponentMap: FieldComponentMapService,
    public el: ElementRef,
    public viewRef: ViewContainerRef
  ) {}

  renderComponent() {
    this.componentId = this.definition.key;
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    const childComponent = this.fieldComponentMap.getComponentFor(
      this.definition.derivedValue ? 'derivedValue' : this.definition.type,
      !!this.definition.asyncOptions,
      this.definition
    );
    const componentFactory = this.compiler.resolveComponentFactory<BaseFieldComponent>(childComponent);
    this.componentRef = this.fieldOutlet.createComponent(componentFactory);
    this.componentRef.instance.control = this.control;
    this.componentRef.instance.controls = this.controls;
    this.componentRef.instance.dataDef = this.definition;
    this.componentRef.instance.inline = this.inline;
    this.componentRef.instance.inlineNoLabel = this.inlineNoLabel;
    this.componentRef.instance.asyncExtras = this.definition.asyncExtras;
    this.componentRef.instance.isJoinedField = this.isJoinedField;
    this.componentRef.instance.hasJoinedField = this.hasJoinedField;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.definition || changes.control) {
      if (!this.definition) {
        if (isDevMode()) {
          console.warn(`No definition provided for field`);
        }
        return;
      }
      if (!this.control && !this.controls) {
        if (isDevMode()) {
          console.warn(`No control provided for: ${this.definition.label}`);
        }
        return;
      }
      this.renderComponent();
    }
  }
}
