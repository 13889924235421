<div class="top-bar grid dense-layout align-center">
  <div class="main-actions data-table-flex-container align-center">
    <div class="grid-content">
      <ng-content></ng-content>
    </div>
    <div *ngIf="headerActions?.length > 0 || bulkActions?.length > 0" class="grid-content">
      <button
        mat-raised-button
        color="primary"
        [appInProgressIndicator]="actionInProgress"
        *ngIf="bulkActions?.length > 0"
        [matMenuTriggerFor]="bulkActionMenu"
        [disabled]="bulkActionsDisabled"
      >
        Action
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button
        mat-raised-button
        *ngFor="let action of headerActions"
        [appInProgressIndicator]="actionInProgress"
        [disabled]="action.disableIf && action.disableIf(null)"
        (click)="runCallback(action, $event)"
      >
        <fa-icon *ngIf="action.icon" [icon]="action.icon"></fa-icon>
        {{ action.label }}
      </button>
    </div>
    <app-data-table-filter
      *ngIf="showQuickSearch"
      [searchValue]="searchValue"
      class="table-search"
      (search)="onQuickSearch($event)"
    ></app-data-table-filter>
    <div *ngIf="showSearchBarActions" class="grid-content align-center pr-2">
      <button
        mat-icon-button
        class="raised"
        *ngIf="quickFilterDefs"
        matTooltip="Quick Filters"
        [ngClass]="{ 'rmt-primary': hasSelectedQuickFilter }"
        (popoverTrigger)="openQuickFilters($event, quickFiltersRef)"
      >
        <fa-icon [icon]="boltIcon"></fa-icon>
      </button>
      {{ selectedQuickFilters?.length }}
      <button
        mat-icon-button
        class="raised"
        matTooltip="Apply Filter"
        (popoverTrigger)="openFilterMenu($event)"
        *ngIf="showFilter"
      >
        <fa-icon [icon]="filterIcon"></fa-icon>
      </button>
      <button
        mat-icon-button
        class="raised"
        (popoverTrigger)="openDisplayColumns($event)"
        matTooltip="Display Columns"
        *ngIf="showColumnChooser"
      >
        <fa-icon [icon]="columnsIcon"></fa-icon>
      </button>
      <button
        *ngIf="showSavedQueries"
        mat-icon-button
        class="raised"
        (popoverTrigger)="openSavedFilters($event, savedFiltersMenu)"
        matTooltip="Saved Filters"
      >
        <fa-icon [icon]="starIcon"></fa-icon>
      </button>
      <button
        mat-icon-button
        class="raised"
        (popoverTrigger)="exportAsCsv($event)"
        matTooltip="Download CSV File"
        *ngIf="showCsvDownload"
      >
        <fa-icon [icon]="downloadIcon"></fa-icon>
      </button>
      <button
        *ngIf="showAdvancedOptions"
        class="raised"
        mat-icon-button
        (click)="toggleAdvanced()"
        matTooltip="Advanced Query"
      >
        <fa-icon [icon]="cogsIcon"></fa-icon>
      </button>
      <button
        mat-icon-button
        class="raised"
        matTooltip="View table glossary"
        *ngIf="_glossary"
        (click)="viewGlossary()"
      >
        <fa-icon [icon]="infoIcon"></fa-icon>
      </button>
    </div>
    <ng-container *ngIf="!advanced">
      <div class="grid align-center" *ngFor="let query of queries" style="height: 2rem">
        <app-query-brick
          [query]="query"
          (remove)="removeQuery($event)"
          (popoverTrigger)="openFilterMenu($event, query)"
        ></app-query-brick>
      </div>
    </ng-container>
    <app-data-table-filter
      class="data-table-header-advanced-search"
      *ngIf="advanced"
      label="Enter advanced query"
      [searchValue]="advancedQuery"
      (enterEvent)="submitAdvancedQuery($event)"
    ></app-data-table-filter>
    <div class="grid-content align-center" *ngIf="advanced || queries?.length">
      <button
        *ngIf="!advanced"
        class="color-primary"
        mat-icon-button
        matTooltip="View Query"
        (popoverTrigger)="showQuery($event)"
      >
        <fa-icon [icon]="infoIcon"></fa-icon>
      </button>
      <button
        *ngIf="showSavedQueries"
        class="btn-reset btn-anchor"
        mat-button
        (popoverTrigger)="openSaveFilter($event, saveFilterInput)"
      >
        Save Filter
      </button>
    </div>
  </div>
</div>

<ng-content select="app-select-all-toolbar"></ng-content>

<ng-template #savedFiltersMenu let-ref>
  <app-dialog-close icon (click)="ref.close()"></app-dialog-close>
  <app-dialog-label>Saved Filters</app-dialog-label>

  <app-dialog-content class="p-0" style="min-width: 300px; min-height: 150px">
    <ng-container *ngIf="savedQueries | propertyFilter: 'favorite':true as favoriteFilters">
      <div
        *ngFor="let filter of favoriteFilters"
        class="saved-filters-item"
        [ngClass]="{ 'selected-saved-filter': filter.id === selectedSavedFilter?.id }"
      >
        <button class="color-accent" mat-icon-button (click)="favoriteFilter(filter)">
          <fa-icon *ngIf="filter.favorite" [icon]="starIcon"></fa-icon>
          <fa-icon *ngIf="!filter.favorite" [icon]="regularStarIcon"></fa-icon>
        </button>
        <a truncateText class="saved-filter" (click)="selectFilter(filter); ref.close()">
          {{ filter.name }}
        </a>
        <button mat-icon-button (click)="removeFilter(filter); ref.close()">
          <fa-icon [icon]="trashIcon"></fa-icon>
        </button>
      </div>
      <div *ngIf="favoriteFilters.length && savedQueries.length > favoriteFilters.length" class="pv-1">
        <mat-divider></mat-divider>
      </div>
    </ng-container>
    <div
      *ngFor="let filter of savedQueries | propertyFilter: 'favorite':false"
      class="saved-filters-item"
      [ngClass]="{ 'selected-saved-filter': filter.id === selectedSavedFilter?.id }"
    >
      <button class="saved-filter-favorite-icon" mat-icon-button (click)="favoriteFilter(filter)">
        <fa-icon *ngIf="filter.favorite" [icon]="starIcon"></fa-icon>
        <fa-icon *ngIf="!filter.favorite" [icon]="regularStarIcon"></fa-icon>
      </button>
      <a truncateText (click)="selectFilter(filter); ref.close()" class="saved-filter">
        {{ filter.name }}
      </a>
      <button mat-icon-button (click)="removeFilter(filter); ref.close()">
        <fa-icon [icon]="trashIcon"></fa-icon>
      </button>
    </div>
    <div *ngIf="!savedQueries || !savedQueries.length" style=" padding: 1rem">
      There are currently no saved filters.
    </div>
  </app-dialog-content>

  <app-dialog-actions>
    <button mat-raised-button (click)="ref.close()">Close</button>
  </app-dialog-actions>
</ng-template>

<ng-template #saveFilterInput let-ref>
  <app-dialog-close icon (click)="ref.close()"></app-dialog-close>
  <app-dialog-label>Save Filter</app-dialog-label>

  <app-dialog-content class="dense-layout">
    <form [formGroup]="saveFilterForm" class="saved-filter-form">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Name</mat-label>
        <input formControlName="name" type="text" matInput />
        <mat-error *ngIf="saveFilterForm?.get('name')?.invalid && saveFilterForm?.get('name')?.errors?.required">
          This field is required.
        </mat-error>
        <mat-error *ngIf="saveFilterForm?.get('name')?.invalid && saveFilterForm?.get('name')?.errors?.uniqueName">
          {{ saveFilterForm.get('name').errors.uniqueName }}
        </mat-error>
      </mat-form-field>

      <label class="container">
        <input #favoriteInput type="checkbox" formControlName="favorite" class="sr-only" />
        <fa-icon
          [icon]="favoriteInput.checked ? starIcon : regularStarIcon"
          class="saved-filter-favorite-icon"
          [ngClass]="favoriteInput.checked ? 'color-accent' : ''"
        >
        </fa-icon>
        Save as a Favorite
      </label>
    </form>
  </app-dialog-content>

  <app-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="submitSaveFilter(ref, saveFilterForm)"
      [disabled]="saveFilterForm?.invalid"
    >
      Save
    </button>
    <button mat-raised-button (click)="ref.close()">Cancel</button>
  </app-dialog-actions>
</ng-template>

<ng-template #quickFiltersRef let-ref>
  <app-dialog-close icon (click)="ref.close()"></app-dialog-close>
  <app-dialog-label>Quick Filters</app-dialog-label>

  <app-dialog-content class="p-3">
    <app-filter-brick-rows
      [filterBrickRows]="quickFilterDefs"
      [selectedFilters]="selectedQuickFilters"
      (filterBrickClicked)="quickFilterSelected.emit($event)"
    >
    </app-filter-brick-rows>
  </app-dialog-content>

  <app-dialog-actions>
    <button
      *ngIf="hasSelectedQuickFilter"
      secondaryActions
      mat-raised-button
      class="color-rmt-dark-tint-1"
      (click)="clearQuickFilters.emit()"
    >
      <fa-icon [icon]="timesIcon"></fa-icon> Clear All
    </button>
    <button mat-raised-button (click)="ref.close()">Close</button>
  </app-dialog-actions>
</ng-template>

<mat-menu #bulkActionMenu="matMenu" class="user-menu">
  <ng-template matMenuContent>
    <ng-container *ngIf="bulkAction$ | async as bulkActions">
      <div
        *ngFor="let action of bulkActions"
        [matTooltip]="action.disabledMessage"
        [matTooltipDisabled]="!action.disabled"
      >
        <div class="disabled-bulk-action" *ngIf="action.disabled; else showBulkAction">
          <span>{{ action.bulkActionDef.label }}</span>
          <fa-icon [icon]="warningIcon" class="color-intermediary disable-bulk-action-icon"></fa-icon>
        </div>
        <ng-template #showBulkAction>
          <button mat-menu-item (click)="runCallback(action.bulkActionDef, $event)" [disabled]="action.disabled">
            <span>{{ action.bulkActionDef.label }}</span>
          </button>
        </ng-template>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
