import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { SectionConfig } from '@form-lib/forms/section-config.model';
import { FormSection } from '@form-lib/models/form.model';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { unflattenData } from '@lib-resource/data.utils';

@Component({
  selector: 'app-section-form',
  exportAs: 'appSectionForm',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.scss'],
  providers: [
    {
      provide: BaseFormComponent,
      useExisting: forwardRef(() => SectionFormComponent)
    }
  ]
})
export class SectionFormComponent extends BaseFormComponent implements OnChanges {
  sectionConfigs: SectionConfig[];
  @Input() sections!: FormSection[];
  @Input() formGroupArrayItemRemovable: boolean = true;
  @Input() formGroupArrayItemAddable: boolean = true;
  definitionsBySection: {
    [prop: string]: DataDefModel[];
  };

  plusIcon = faPlus;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if ((changes.sections || changes.definitions) && this.definitions && this.sections) {
      this.setSectionAndDisplayItems();
      this.setDisplayedDefinitions();
      // Preserves user's changes to the form by passing in current form value
      const rawValue = this.form?.getRawValue();
      if (rawValue) {
        this.setForm(unflattenData(rawValue, '/'));
      } else {
        this.setForm();
      }
    }
  }

  setSectionAndDisplayItems() {
    this.definitionsBySection = {};
    this.sectionConfigs = [...this.sections];
    this.sectionConfigs.forEach((section) => {
      this.definitionsBySection[section.key] = [];
    });

    if (this.sectionConfigs.length && this.sectionConfigs[0].displayItems) {
      // For definitions defined based on section def
      this.displayItems = this.sectionConfigs.reduce((acc, section) => [...acc, ...section.displayItems], []);

      this.sectionConfigs.forEach((section, idx) => {
        section.displayItems.forEach((key) => {
          this.definitionsBySection[section.key].push(this.definitionsMap[key]);
          if (this.definitionsMap[key].type === DATA_TYPES.formGroupArray) {
            this.sectionConfigs[idx] = { ...section, formArrayDef: this.definitionsMap[key] };
          }
        });
      });
    } else {
      // For definitions defined with sectionKey defined on them
      const sectionKeys = this.sectionConfigs.map((def) => def.key);
      this.displayItems = this.definitions
        .filter((field) => field.sectionKey && sectionKeys.includes(field.sectionKey))
        .map((field) => field.key);
      this.definitions.forEach((def) => {
        if (this.definitionsBySection[def.sectionKey]) {
          this.definitionsBySection[def.sectionKey].push(def);
          if (def.type === DATA_TYPES.formGroupArray) {
            this.sectionConfigs = this.sectionConfigs.map((obj) =>
              obj.key === def.sectionKey ? { ...obj, formArrayDef: def } : obj
            );
          }
        }
      });
    }
  }
}
