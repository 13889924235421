import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-simple-form',
  exportAs: 'simpleForm',
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseFormComponent,
      useExisting: forwardRef(() => SimpleFormComponent)
    }
  ]
})
export class SimpleFormComponent extends BaseFormComponent implements OnChanges {
  @Input() grid: boolean;
  @Input() denseLayout: boolean;
  @Input() inlineLabels: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.definitions) {
      this.setForm();
    }
  }
}
