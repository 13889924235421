<ng-container>
  <app-dialog-close icon mat-dialog-close></app-dialog-close>
  <app-dialog-label>
    Import Proposal
    @if (data.version) {
      | Version {{ data.version.versionId }}
    }
  </app-dialog-label>
  <app-dialog-content>
    <mat-tab-group *ngIf="multipleOptions; else oneOption" [(selectedIndex)]="selectedTabIndex">
      <mat-tab label="1. Select Import Type *">
        <div class="rmt-card">
          <div>
            <mat-radio-group
              class="grid"
              [(ngModel)]="selectedExtractType"
              required
              (change)="selectedExtractTypeChanged()"
            >
              <mat-radio-button value="DY" color="primary" *ngIf="data.dyXmlEnabled"
                >Import from ESL XML</mat-radio-button
              >
              <mat-radio-button value="RMT_XML" color="primary" *ngIf="data.rmtXmlEnabled"
                >Import from RMT XML</mat-radio-button
              >
              <mat-radio-button value="OCR" color="primary" *ngIf="data.ocrTemplates?.length > 0"
                >Import by OCR Scan</mat-radio-button
              >
              <mat-radio-button value="XLSX" color="primary" *ngIf="data.excelTemplates?.length > 0"
                >Import by Excel Scan</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="2. Select Files" [disabled]="!selectedExtractType">
        <ng-container *ngTemplateOutlet="selectFile"></ng-container>
      </mat-tab>
    </mat-tab-group>
    <ng-template #oneOption>
      <ng-container *ngTemplateOutlet="selectFile"></ng-container>
    </ng-template>
  </app-dialog-content>
  <app-dialog-actions>
    <div secondaryActions class="grid-content" *ngIf="multipleOptions">
      <button mat-raised-button [disabled]="backDisabled()" (click)="previousTab()">Back</button>
    </div>

    <div class="grid-content">
      <button
        mat-raised-button
        color="primary"
        (click)="nextTab()"
        *ngIf="multipleOptions && selectedTabIndex <= 0"
        [disabled]="nextDisabled()"
      >
        Next
      </button>
      <button
        mat-raised-button
        color="primary"
        [appInProgressIndicator]="processing()"
        [disabled]="!valid()"
        (click)="requestExtract()"
        *ngIf="selectedTabIndex > 0 || !multipleOptions"
      >
        Import
      </button>
      <button mat-raised-button mat-dialog-close>Cancel</button>
    </div>
  </app-dialog-actions>
</ng-container>

<ng-template #selectFile>
  <app-ocr-extract
    *ngIf="selectedExtractType === 'OCR'"
    [responseId]="data.responseId"
    [templates]="data.ocrTemplates"
    (valid)="ocrValid = $event"
    (processing)="ocrProcessing = $event"
    (extractedResponse)="ocrExtract($event)"
  >
  </app-ocr-extract>
  <app-dy-extract
    *ngIf="selectedExtractType === 'DY'"
    [responseId]="data.responseId"
    (valid)="dyValid = $event"
    (processing)="dyProcessing = $event"
    (extractedResponse)="dyExtract($event)"
  >
  </app-dy-extract>
  <app-rmt-xml-extract
    *ngIf="selectedExtractType === 'RMT_XML'"
    [responseId]="data.responseId"
    (valid)="rmtXmlValid = $event"
    (processing)="rmtXmlProcessing = $event"
    (extractedResponse)="rmtXmlExtract($event)"
  >
  </app-rmt-xml-extract>
  <app-excel-extract
    *ngIf="selectedExtractType === 'XLSX'"
    [responseId]="data.responseId"
    [templates]="data.excelTemplates"
    (valid)="excelValid = $event"
    (processing)="excelProcessing = $event"
    (extractedResponse)="excelExtract($event)"
  >
  </app-excel-extract>
</ng-template>
