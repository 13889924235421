import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-actions',
  template: `
    <div class="dialog-action-wrapper dense-layout">
      <div class="dialog-actions-secondary">
        <ng-content select="[secondaryActions]"></ng-content>
      </div>
      <div class="dialog-actions-primary grid-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['dialog-actions.component.scss']
})
export class DialogActionsComponent {}
