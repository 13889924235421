export enum AlertLevelType {
  ATTENTION = 'attention',
  SUCCESS = 'success',
  WARN = 'warn',
  INFO = 'info',
  PRIMARY = 'primary'
}

const colorClasses = {
  [AlertLevelType.WARN]: 'bg-warn',
  [AlertLevelType.SUCCESS]: 'bg-success',
  [AlertLevelType.ATTENTION]: 'bg-intermediary',
  [AlertLevelType.INFO]: 'bg-info',
  [AlertLevelType.PRIMARY]: 'bg-primary'
};

export function alertClassMap(alertLevel: string) {
  return colorClasses[alertLevel];
}
