<div class="rmt-card">
  <div
    class="workspace-header-layout"
    [ngClass]="{ 'workspace-header': !isRecordDetailsHeader, 'record-details-header': isRecordDetailsHeader }"
  >
    <div class="status">
      <ng-content select=".header-icon"></ng-content>
      <ng-content select="app-workspace-header-status"></ng-content>
    </div>
    <div class="workspace-header-info pl-4 pt-2">
      <ng-content select="[appWorkspaceHeaderLabel]"></ng-content>
      <ng-content select="[appWorkspaceHeaderRow]"></ng-content>
    </div>
    <div class="tags-display">
      <ng-content select="app-tags-display"></ng-content>
      <div class="workspace-header-actions">
        <ng-content select="button"></ng-content>
      </div>
    </div>
  </div>
</div>
