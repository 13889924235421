<app-dialog-close icon mat-dialog-close></app-dialog-close>

<app-dialog-label>Send Message</app-dialog-label>

<app-dialog-content>
  <app-simple-form
    #simpleForm="simpleForm"
    [data]="message"
    [editing]="!readOnly"
    [definitions]="messageFormDefinitions"
    (doSubmit)="onSubmit($event)"
  ></app-simple-form>
  <div class="p2">
    <app-file-upload
      [disabled]="!simpleForm.editing"
      [labelText]="'Add Supporting Files to Quote'"
      (selectFile)="addFile(message, $event)"
    ></app-file-upload>
  </div>
  <app-data-table
    *ngIf="attachedSupportFiles.length"
    [inlineActions]="true"
    [paginated]="false"
    [displayCols]="displayedFileColumns"
    [columns]="fileColumns"
    [data]="attachedSupportFiles"
    [actions]="fileActions"
  >
  </app-data-table>
</app-dialog-content>

<app-dialog-actions>
  <button mat-stroked-button color="primary" (click)="simpleForm.submit()" [appInProgressIndicator]="creating">
    Send
  </button>
  <button mat-raised-button mat-dialog-close [appInProgressIndicator]="creating">Cancel</button>
</app-dialog-actions>
