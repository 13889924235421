import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { QuoteTypeProduct } from '@common/models/quote-type.model';

@Injectable({
  providedIn: 'root'
})
export class FilterUtilService {
  constructor(private store: Store) {}

  filterByQuoteProduct(filter: string, key = 'quotetype.product'): string {
    let newFilter = filter || '';
    if (newFilter.indexOf(key) < 0) {
      if (this.store.selectSnapshot(ProductSelectors.isQuoteLinq)) {
        const quoteLinqProduct = `${key} = '${QuoteTypeProduct.STOP_LOSS}'`;
        newFilter = this.combineFilters(filter, quoteLinqProduct);
      } else if (this.store.selectSnapshot(ProductSelectors.isQuoteLinqRx)) {
        const quoteLinqRxProduct = `${key} = '${QuoteTypeProduct.RX}'`;
        newFilter = this.combineFilters(filter, quoteLinqRxProduct);
      }
    }

    return newFilter;
  }

  private combineFilters(origFilter: string, productFilter: string) {
    return !!origFilter ? `( ${origFilter} ) and ${productFilter}` : productFilter;
  }
}
