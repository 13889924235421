import { KpiDefModel } from '@app/libraries/workspace-lib/components/kpi/kpi-def.model';
import { FilterBrickRowModel } from '@app/libraries/workspace-lib/model/filter-brick-row.model';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { NavItem } from '@main/nav.models';
import { QuoteResponseStatus } from '@common/models/quote-response.model';
import { fundingTypeDef } from '@common/components/quote-response-list/columns.definitions';

export const createdDateDef: DataDefModel = new DataDefModel({
  key: 'quoteRequest.createdDate',
  label: 'Create Date',
  type: DATA_TYPES.date
});

export const dueDateDef: DataDefModel = new DataDefModel({
  key: 'quoteRequest.dueDate',
  label: 'Quote Due Date',
  type: DATA_TYPES.date
});

export const effectiveDateDef: DataDefModel = new DataDefModel({
  key: 'quoteRequest.effectiveDate',
  label: 'Effective Date',
  type: DATA_TYPES.date
});

export const porDef: DataDefModel = new DataDefModel({
  key: 'por',
  label: 'Producer of Record',
  type: DATA_TYPES.text
});

export const proposalInfoDefs: DataDefModel[] = [fundingTypeDef, createdDateDef, dueDateDef, effectiveDateDef, porDef];

export const censusNavItems: NavItem[] = [
  {
    label: 'Census',
    link: 'census'
  }
];

export const verikaiNavItems: NavItem[] = [
  {
    label: 'Verikai',
    link: 'verikai',
    class: 'verikai-logo'
  }
];

export const cardNavItemsRestricted: NavItem[] = [
  {
    label: 'Claims History',
    link: 'claims-history'
  },
  {
    label: 'Flagged Members',
    link: 'flagged-members'
  }
];

export const cardNavItemsHead: NavItem[] = [
  {
    label: 'Proposal',
    link: 'proposal-response'
  },
  {
    label: 'Cover Sheet',
    link: 'cover-sheet'
  },
  {
    label: 'Supporting Files',
    link: 'supporting-files'
  }
];

export const cardNavItemsTail: NavItem[] = [
  {
    label: 'Audit',
    link: 'audit'
  },
  {
    label: 'Messages',
    link: 'messages'
  }
];

export const proposalWorkspaceTableDefaultCols = [
  'id',
  'quoteRequest.quoteType.orgName',
  'quoteRequest.account.name',
  'matchIndex',
  'quoteRequest.renewal',
  'status',
  'quoteRequest.effectiveDate',
  'quoteRequest.dueDate',
  'incumbent'
];

export const proposalWorkspaceQuerySnippet = {
  stageNew: "(status = 'NEW')",
  stageUnderwriting: "(status = 'UNDERWRITING')",
  stageSubmitted: "(status = 'SUBMITTED')",
  stageShortlist: "(status = 'SHORTLIST')",
  stageWon: "(status = 'WON')",
  stageLost: "(status = 'LOST')",
  stageDeclined: "(status = 'DECLINED')",
  stageClosed: "((status = 'CLOSED') OR (status = 'LOST') OR (status = 'DECLINED'))",
  stageBound: "(status = 'BOUND')"
};

export const kpiDefs: KpiDefModel[] = [
  {
    label: 'New',
    value: null,
    filters: {
      Stage: [proposalWorkspaceQuerySnippet.stageNew]
    }
  },
  {
    label: 'Underwriting',
    value: null,
    filters: {
      Stage: [proposalWorkspaceQuerySnippet.stageUnderwriting]
    }
  },
  {
    label: 'Submitted',
    value: null,
    filters: {
      Stage: [proposalWorkspaceQuerySnippet.stageSubmitted]
    }
  },
  {
    label: 'Shortlisted',
    value: null,
    filters: {
      Stage: [proposalWorkspaceQuerySnippet.stageShortlist]
    }
  },
  {
    label: 'Won',
    value: null,
    filters: {
      Stage: [proposalWorkspaceQuerySnippet.stageWon]
    }
  },
  {
    label: 'Quotes with New Messages',
    value: null,
    filters: {
      'New Messages': ['(unreadmsg = true)']
    }
  }
];

export const proposalWorkspaceFilterBrickDefs: FilterBrickRowModel[] = [
  {
    rowName: 'Stage',
    filterOptions: [
      {
        rowName: 'Stage',
        displayName: 'New',
        filterString: proposalWorkspaceQuerySnippet.stageNew
      },
      {
        rowName: 'Stage',
        displayName: 'Underwriting',
        filterString: proposalWorkspaceQuerySnippet.stageUnderwriting
      },
      {
        rowName: 'Stage',
        displayName: 'Submitted',
        filterString: proposalWorkspaceQuerySnippet.stageSubmitted
      },
      {
        rowName: 'Stage',
        displayName: 'Shortlist',
        filterString: proposalWorkspaceQuerySnippet.stageShortlist
      },
      {
        rowName: 'Stage',
        displayName: 'Closed',
        filterString: proposalWorkspaceQuerySnippet.stageClosed
      },
      {
        rowName: 'Stage',
        displayName: 'Bound',
        filterString: proposalWorkspaceQuerySnippet.stageBound
      }
    ]
  },
  {
    rowName: 'Due in (days)',
    filterOptions: [
      {
        rowName: 'Due in (days)',
        displayName: '< 8 days',
        filterString: `quoterequest.duedate < date('today + 8 days')`
      },
      {
        rowName: 'Due in (days)',
        displayName: '8 - 14 days',
        filterString: `quoterequest.duedate > date('today + 8 days') AND quoterequest.duedate <= date('today + 14 days')`
      },
      {
        rowName: 'Due in (days)',
        displayName: '15 - 30 days',
        filterString: `quoterequest.duedate > date('today + 15 days') AND quoterequest.duedate <= date('today + 30 days')`
      },
      {
        rowName: 'Due in (days)',
        displayName: '> 30 days',
        filterString: `quoterequest.duedate > date('today + 30 days')`
      }
    ]
  },
  {
    rowName: 'Renewals',
    filterOptions: [
      {
        rowName: 'Renewals',
        displayName: 'Yes',
        filterString: 'quoterequest.renewal = true'
      },
      {
        rowName: 'Renewals',
        displayName: 'No',
        filterString: 'quoterequest.renewal = false'
      }
    ]
  },
  {
    rowName: 'New Messages',
    filterOptions: [
      {
        rowName: 'New Messages',
        displayName: 'Yes',
        filterString: '(unreadmsg = true)'
      },
      {
        rowName: 'New Messages',
        displayName: 'No',
        filterString: '(unreadmsg = false)'
      }
    ]
  }
];

export const READ_ONLY_STATUSES = [
  QuoteResponseStatus.BOUND,
  QuoteResponseStatus.CLOSED,
  QuoteResponseStatus.LOST,
  QuoteResponseStatus.DECLINED
];
