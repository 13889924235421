import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DisableChildIf {
  /**
   * Disable or enable field on form if sibling field has a particular value.
   *
   * @param field - The key of the field to disable or enable.
   * @param siblingField - The key of the field to evaluate the value of.
   * @param value - The value to check for.
   * @param isNot - Optional flag to enable field if value matches and disable otherwise. Defaults to false, which will disable field if values match.
   */
  static siblingHasValueOf(field: string, siblingField: string, value: any, isNot = false) {
    return (AC: AbstractControl): ValidationErrors | null => {
      if (AC.disabled) return;
      const siblingFieldValue = AC.get(siblingField).value;
      const control = AC.get(field);
      let valueMatches;
      if (Array.isArray(value)) {
        valueMatches = value.some((val) => val === siblingFieldValue);
      } else {
        valueMatches = siblingFieldValue === value;
      }

      if (valueMatches === isNot) {
        if (control.disabled) {
          control.enable();
        }
      } else {
        if (control.enabled) {
          control.disable();
          control.setValue(null);
        }
      }
    };
  }

  static customDisabler(validationFn: (_?: AbstractControl) => boolean | void, errorMessage?: string) {
    return (AC: AbstractControl): ValidationErrors | null => {
      const error = !validationFn(AC);
      return error ? { customValidation: errorMessage } : null;
    };
  }
}

export class DisableChildrenIf {
  /**
   * Disable or enable field on form if sibling field has a particular value.
   *
   * @param fields - The key of the field to disable or enable.
   * @param siblingField - The key of the field to evaluate the value of.
   * @param value - The value to check for.
   * @param isNot - Optional flag to enable field if value matches and disable otherwise. Defaults to false, which will disable field if values match.
   * @param defaultWhenDisabled
   */
  static siblingHasValueOf(
    fields: string[],
    siblingField: string,
    value: any,
    isNot = false,
    defaultWhenDisabled: { [key: string]: any } = null
  ): ValidatorFn {
    return (AC: AbstractControl) => {
      if (AC.disabled) return null;
      const siblingFieldValue = AC.get(siblingField).value;
      const controls = fields.map((key) => AC.get(key));

      if ((siblingFieldValue === value || !isNot) && !(siblingFieldValue === value && !isNot)) {
        controls.forEach((control) => {
          if (control.disabled) control.enable();
        });
      } else {
        controls.forEach((control) => {
          if (control.enabled) {
            control.disable();
            const formGroup = control.parent.controls;
            const key = Object.keys(formGroup).find((name) => control === formGroup[name]);
            const controlValue = defaultWhenDisabled && key ? defaultWhenDisabled[key] || null : null;
            control.setValue(controlValue);
          }
        });
      }
    };
  }
}
