import { Component, forwardRef, Input } from '@angular/core';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { DataDefModel } from '@lib-resource/data-def.model';
import { quoteResponseAdditionalFeesDef } from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';

@Component({
  selector: 'app-additional-fees-array',
  templateUrl: './additional-fees-array.component.html',
  styleUrls: ['./additional-fees-array.component.scss'],
  providers: [
    {
      provide: BaseArrayComponent,
      useExisting: forwardRef(() => AdditionalFeesArrayComponent)
    }
  ]
})
export class AdditionalFeesArrayComponent extends BaseArrayComponent {
  @Input() readOnly: boolean;

  amountDataDef: DataDefModel = this.getDataDef('amount');

  defs = quoteResponseAdditionalFeesDef;

  constructor() {
    super();
  }

  private getDataDef(key: string): DataDefModel {
    return quoteResponseAdditionalFeesDef.find((def) => def.key === key);
  }
}
