<ng-container *ngIf="show; else selectItem">
  <div>
    <ng-content select="[workspaceHeader]"></ng-content>
  </div>
  <div class="display-card-details rmt-section">
    <div class="grid">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        @for (item of navItems; track item) {
          <div [matTooltip]="item.tooltip">
            <a
              mat-tab-link
              [routerLink]="item.link"
              [queryParams]="item.queryParams"
              [disabled]="item.disabled"
              queryParamsHandling="merge"
              routerLinkActive
              #rla="routerLinkActive"
              [active]="rla.isActive"
            >
              <div *ngIf="item.icon; else noIcon" class="nav-icon-container">
                <fa-icon [icon]="item.icon" [ngClass]="!!item.iconStyle ? item.iconStyle : 'color-accent'"></fa-icon>
                <div>{{ item.label }}</div>
              </div>
              <ng-template #noIcon>
                <div>{{ item.label }}</div>
              </ng-template>
            </a>
          </div>
        }
      </nav>

      <mat-tab-nav-panel #tabPanel>
        <ng-content></ng-content>
      </mat-tab-nav-panel>
    </div>
  </div>
</ng-container>

<ng-template #selectItem>
  <div class="empty-display-card-layout">
    <img src="../../../../../assets/icons/display-card-icon.svg" width="220px" height="220px" alt="Display Card Logo" />
    <div class="mat-headline-5">Select an item from the Queue</div>
  </div>
</ng-template>
