import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProposalConfirmationDialogComponent } from '@common/dialogs/proposal-confirmation-dialog/proposal-confirmation-dialog.component';
import { WorkspaceConfirmationDialogComponent } from '@common/dialogs/workspace-confirmation-dialog/workspace-confirmation-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {
  DialogData,
  InputDialogData,
  MessageDialogData,
  MonospaceDialogData,
  WorkspaceConfirmationDialogData,
  WorkspaceDialogData
} from './dialogs.model';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MonospaceMessageDialogComponent } from './monospace-message-dialog/monospace-message-dialog.component';

// default values
const defaultWidth = '600px';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {
  constructor(private dialog: MatDialog) {}

  openDialog<T>(component, data, config: MatDialogConfig, width = defaultWidth): MatDialogRef<T> {
    return this.dialog.open(component, { data, ...config, width: width });
  }

  confirmationDialog(
    data: DialogData = {},
    width: string = defaultWidth
  ): MatDialogRef<ConfirmationDialogComponent, boolean> {
    return this.openDialog<ConfirmationDialogComponent>(ConfirmationDialogComponent, data, {
      width,
      disableClose: false
    });
  }

  discardChangesConfirmationDialog(
    data: DialogData = {},
    width: string = defaultWidth
  ): MatDialogRef<ConfirmationDialogComponent, boolean> {
    data.label = data.label || 'Discard Changes';
    data.actionText = data.actionText || 'Discard';
    data.cancelText = data.cancelText || 'Cancel';
    data.message = data.message || 'Are you sure you want to discard your changes?';
    return this.openDialog<ConfirmationDialogComponent>(ConfirmationDialogComponent, data, {
      width,
      disableClose: true
    });
  }

  inputDialog<T>(
    data: InputDialogData,
    width: string = defaultWidth
  ): MatDialogRef<InputDialogComponent, { value: T }> {
    return this.openDialog<InputDialogComponent>(InputDialogComponent, data, { width });
  }

  messageDialog(data: MessageDialogData, width: string = defaultWidth): MatDialogRef<MessageDialogComponent, void> {
    return this.openDialog<MessageDialogComponent>(MessageDialogComponent, data, { width });
  }

  // Display code like content
  monospaceMessageDialog(
    data: MonospaceDialogData,
    width: string = defaultWidth
  ): MatDialogRef<MonospaceMessageDialogComponent, void> {
    return this.openDialog<MonospaceMessageDialogComponent>(MonospaceMessageDialogComponent, data, { width });
  }

  // Acts like an alert
  alertDialog(data: DialogData, width: string = defaultWidth, disableClose: boolean = false) {
    return this.openDialog<AlertDialogComponent>(AlertDialogComponent, data, { width, disableClose });
  }

  playAudio() {
    // const audio = new Audio();
    // audio.src = '../../../assets/audio/ding.wav';
    // audio.load();
    // audio.play();
  }

  /**
   * Confirmation Dialog used in Workspace.
   * This confirmation dialog will provide the workspace proposal header information on the top.
   */
  proposalConfirmationDialog(
    data: WorkspaceDialogData = {},
    width: string = defaultWidth
  ): MatDialogRef<ProposalConfirmationDialogComponent> {
    return this.openDialog<ProposalConfirmationDialogComponent>(ProposalConfirmationDialogComponent, data, { width });
  }

  workspaceConfirmationDialog(
    data: WorkspaceConfirmationDialogData = {},
    width: string = defaultWidth
  ): MatDialogRef<WorkspaceConfirmationDialogComponent> {
    return this.openDialog<WorkspaceConfirmationDialogComponent>(
      WorkspaceConfirmationDialogComponent,
      data,
      { width },
      width
    );
  }
}
