import { OperatorType } from '@data-table-lib/models/data-table.model';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

class QueryInputMenuOperatorDef {
  label: string;
  value: {
    multi: boolean;
    operator: OperatorType;
    empty?: boolean; // use with operator 'is' to add an is empty filter
  };
}

export const enumTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'contains', value: { operator: '~', multi: true } },
  { label: 'does not contain', value: { operator: '!~', multi: true } },
  { label: 'is empty', value: { operator: 'is', multi: false, empty: true } },
];

export const textTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'contains', value: { operator: '~', multi: false } },
  { label: 'does not contain', value: { operator: '!~', multi: false } },
  { label: 'is equal to', value: { operator: '=', multi: false } },
  { label: 'is not equal to', value: { operator: '!=', multi: false } },
  { label: 'is empty', value: { operator: 'is', multi: false, empty: true } },
  { label: 'is one of', value: { operator: '~', multi: true } },
  { label: 'is not one of', value: { operator: '!~', multi: true } }
];

export const dateTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'is equal to', value: { operator: '=', multi: false } },
  { label: 'is not equal to', value: { operator: '!=', multi: false } },
  { label: 'is empty', value: { operator: 'is', multi: false, empty: true } },
  { label: 'is greater than', value: { operator: '>', multi: false } },
  {
    label: 'is greater than or equal to',
    value: { operator: '>=', multi: false }
  },
  { label: 'is less than', value: { operator: '<', multi: false } },
  { label: 'is less than or equal to', value: { operator: '<=', multi: false } }
];

export const numericTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'contains', value: { operator: '~', multi: true } },
  { label: 'does not contain', value: { operator: '!~', multi: true } },
  { label: 'is empty', value: { operator: 'is', multi: false, empty: true } },
  { label: 'is equal to', value: { operator: '=', multi: false } },
  { label: 'is not equal to', value: { operator: '!=', multi: false } },
  { label: 'is greater than', value: { operator: '>', multi: false } },
  {
    label: 'is greater than or equal to',
    value: { operator: '>=', multi: false }
  },
  { label: 'is less than', value: { operator: '<', multi: false } },
  { label: 'is less than or equal to', value: { operator: '<=', multi: false } }
];

export const booleanTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'is equal to', value: { operator: '=', multi: false } },
  { label: 'is empty', value: { operator: 'is', multi: false, empty: true } }
];

export const fileSizeTypeOperators: QueryInputMenuOperatorDef[] = [
  { label: 'is greater than', value: { operator: '>', multi: false } },
  { label: 'is less than', value: { operator: '<', multi: false } }
];

export const dateForm: DataDefModel[] = [
  new DataDefModel({
    label: 'Date',
    key: 'date',
    type: DATA_TYPES.date,
    layout: {
      base: 12
    },
    validators: { onlyOneValueOf: ['days'] }
  }),
  new DataDefModel({
    label: 'Plus or Minus Days from today',
    key: 'days',
    type: DATA_TYPES.number,
    layout: {
      base: 12
    },
    validators: { onlyOneValueOf: ['date'] }
  })
];

export function getOperators(columnType) {
  switch (columnType) {
    case DATA_TYPES.select:
    case DATA_TYPES.multiSelect:
    case DATA_TYPES.enum:
      return enumTypeOperators;
    case DATA_TYPES.text:
      return textTypeOperators;
    case DATA_TYPES.dateTime:
    case DATA_TYPES.date:
      return dateTypeOperators;
    case DATA_TYPES.number:
    case DATA_TYPES.currency:
    case DATA_TYPES.percentage:
      return numericTypeOperators;
    case DATA_TYPES.boolean:
      return booleanTypeOperators;
    case DATA_TYPES.fileSize:
      return fileSizeTypeOperators;
    default:
      return textTypeOperators;
  }
}

export const generateFieldDef = (field, operator): DataDefModel[] => {
  const fieldDef = new DataDefModel({
    label: 'Query Value(s)',
    key: 'value',
    validators: { required: true }
  });

  if (operator.empty) {
    fieldDef.options = [
      { label: 'Yes', value: 'empty' },
      { label: 'No', value: 'not empty' }
    ];
    fieldDef.type = 'select';
    return [fieldDef];
  }

  switch (field.type) {
    case DATA_TYPES.date:
    case DATA_TYPES.dateTime:
      return dateForm;
    case 'boolean':
      fieldDef.options = field.options || [
        { label: 'True', value: true },
        { label: 'False', value: false }
      ];
      fieldDef.type = 'select';
      break;
    case 'number':
    case 'percentage':
    case 'fileSize':
      fieldDef.type = operator.multi ? 'multiInput' : 'number';
      break;
    case 'currency':
      fieldDef.type = operator.multi ? 'multiCurrency' : 'currency';
      break;
    case 'enum':
    case 'select':
      fieldDef.options = field.options;
      fieldDef.asyncOptions = field.asyncOptions;
      if (!operator.multi) {
        fieldDef.type = DATA_TYPES.select;
      } else {
        fieldDef.type = fieldDef.asyncOptions ? DATA_TYPES.multiSelectSet : DATA_TYPES.multiSelect;
      }
      break;
    default:
      fieldDef.type = operator.multi ? 'multiInput' : field.type;
  }
  return [fieldDef];
};
