import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkspaceConfirmationDialogData } from '@common/dialogs/dialogs.model';
import { CollapsibleFormComponent } from '@form-lib/forms/collapsible-form/collapsible-form.component';

/**
 * Confirmation Dialog used in Workspace.
 * This confirmation dialog will provide the standard rfp header information on the top of the dialog.
 */
@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>{{ data.dialogData.label }}</app-dialog-label>
    <app-dialog-content>
      <app-workspace-header>
        <app-workspace-header-status [data]="data.item" [statusDef]="data.headerStatusDef" [allowUpdateStatus]="false">
        </app-workspace-header-status>
        <h1 appWorkspaceHeaderLabel>{{ data.label }}</h1>
        <div appWorkspaceHeaderRow class="rmt-subheader">{{ data.subLabel }}</div>
        <app-info-display
          appWorkspaceHeaderRow
          [dataDefs]="data.headerInfoDefs"
          [data]="data.item"
          [cols]="data.headerInfoDefs.length"
        >
        </app-info-display>
      </app-workspace-header>
      <div>
        <p *ngIf="data.dialogData.message">{{ data.dialogData.message }}</p>
        <app-collapsible-form
          *ngIf="data.dialogData.collapsibleForm"
          #workspaceConfirmationDialogForm
          [data]="data.item"
          [sections]="data.dialogData?.collapsibleForm?.sections"
          [definitions]="data.dialogData?.collapsibleForm?.definitions"
          [editing]="true"
          (doSubmit)="saveChanges($event)"
        >
        </app-collapsible-form>
      </div>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button color="primary" (click)="submitForm()" cdkFocusInitial>
        {{ data.dialogData.actionText ? data.dialogData.actionText : 'Confirm' }}
      </button>
      <button mat-raised-button mat-dialog-close>
        {{ data.dialogData.cancelText ? data.dialogData.cancelText : 'Close' }}
      </button>
    </app-dialog-actions>
  `
})
export class WorkspaceConfirmationDialogComponent {
  label: string;
  @ViewChild('workspaceConfirmationDialogForm')
  collpasibleForm: CollapsibleFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkspaceConfirmationDialogData,
    private dialogRef: MatDialogRef<WorkspaceConfirmationDialogComponent>
  ) {
    this.label = data.dialogData.label ? data.dialogData.label : 'Are you sure?';
  }

  submitForm() {
    if (!!this.data.dialogData?.collapsibleForm) {
      this.collpasibleForm.submit();
    } else {
      this.dialogRef.close(true);
    }
  }

  saveChanges(event) {
    if (!this.collpasibleForm.invalid) {
      this.dialogRef.close(event.value);
    }
  }
}
