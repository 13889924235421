import { Component, Inject } from '@angular/core';
import { BaseManifestDialogComponent } from '@common/dialogs/manifest-dialogs/base-manifest-dialog/base-manifest-dialog.component';
import { ManifestEntryModel, SourceType } from '@common/models/manifest-entry.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { QuoteResponseRxService } from '@common/services/quote-response-rx.service';
import { RxDrugService } from '@common/services/rx-drug.service';
import { RxDrugListType } from '@common/models/rx-drug.model';
import { isPdf } from '@file-upload-lib/file.model';
import { RxClaimService } from '@common/services/rx-claim.service';
import { QuoteRequestService } from '@common/services/quote-request.service';
import { QuoteResponseService } from '@common/services/quote-response.service';

export class RxResponseManifestEntry extends ManifestEntryModel {
  optionId: number;
  optionIndex: number;
}

@Component({
  templateUrl: './base-manifest-dialog/base-manifest-dialog.component.html',
  styleUrls: ['./base-manifest-dialog/base-manifest-dialog.component.scss']
})
export class QuoteResponseRxManifestDialogComponent extends BaseManifestDialogComponent<RxResponseManifestEntry> {
  isRequestingSide: boolean;
  quoteResponseRxId: number;
  quoteRequestRxId: number;

  fileNameMap: Map<SourceType, (item: RxResponseManifestEntry) => string> = new Map([
    [SourceType.RMT_FILE, (_) => 'RMT File'],
    [SourceType.COVER_SHEET, (item) => `Option ${item?.optionIndex + 1}`],
    [SourceType.PARSED_DISCLOSURE, (_) => 'Claims History'],
    [SourceType.PARSED_LDD, (_) => 'LDD List'],
    [SourceType.PARSED_SDL, (_) => 'Specialty Drug List']
  ]);

  docTypeMap = new Map<SourceType, string>([
    [SourceType.RMT_FILE, 'Supporting File'],
    [SourceType.COVER_SHEET, 'Quote Sheet'],
    [SourceType.PARSED_DISCLOSURE, 'Claims History'],
    [SourceType.PARSED_LDD, 'LDD List'],
    [SourceType.PARSED_SDL, 'Specialty Drug List']
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isRequestingSide: boolean;
      zipFileName: string;
      ids: any;
      loadFunction: () => Observable<[boolean, RxResponseManifestEntry[]]>;
    },
    private quoteResponseRxService: QuoteResponseRxService,
    private quoteResponseService: QuoteResponseService,
    private quoteRequestService: QuoteRequestService,
    private rxDrugService: RxDrugService,
    private rxClaimService: RxClaimService
  ) {
    super(data);
    this.isRequestingSide = data.isRequestingSide;
    this.dialogLabel = `Download Quote - ${data.zipFileName}`;
    this.quoteResponseRxId = data.ids.quoteResponseRxId;
    this.quoteRequestRxId = data.ids.quoteRequestRxId;
  }

  downloadItem(item: RxResponseManifestEntry) {
    switch (item.source) {
      case SourceType.COVER_SHEET:
        this.downloadRxProposalDoc(item);
        break;
      case SourceType.PARSED_DISCLOSURE:
        this.downloadParsedDisclosure();
        break;
      case SourceType.PARSED_LDD:
        this.downloadParsedLDD();
        break;
      case SourceType.PARSED_SDL:
        this.downloadParsedSDL();
        break;
      case SourceType.RMT_FILE:
        this.downloadRmtFile(item);
        break;
      default:
        this.downloadRmtFile(item);
        break;
    }
  }

  downloadRxProposalDoc(item: RxResponseManifestEntry): void {
    this.quoteResponseRxService.downloadRxProposalDoc(
      this.quoteResponseRxId,
      item.optionId,
      `${this.data.zipFileName}_quote_sheet_option_${item.optionIndex + 1}.xlsx`
    );
  }

  downloadManifest(): Observable<Blob> {
    return this.quoteResponseRxService.downloadQuoteResponseRxManifest(
      this.quoteResponseRxId,
      this.manifestList.selectedOptions.selected.map((item) => item.value),
      `${this.data.zipFileName}.zip`
    );
  }

  downloadParsedDisclosure(): void {
    this.rxClaimService.downloadRxClaimCsvForResponse(this.quoteResponseRxId, 'claim_history.csv');
  }

  downloadParsedLDD(): void {
    this.rxDrugService.downloadRxDrugCsvForResponse(
      this.quoteResponseRxId,
      `listtype = '${RxDrugListType.LDD}'`,
      'ldd_list.csv'
    );
  }

  downloadParsedSDL(): void {
    this.rxDrugService.downloadRxDrugCsvForResponse(
      this.quoteResponseRxId,
      `listtype = '${RxDrugListType.SDL}'`,
      'specialty_drug_list.csv'
    );
  }

  downloadRmtFile(item: ManifestEntryModel): void {
    if (this.isRequestingSide) {
      if (isPdf(item?.rmtFile)) {
        this.quoteResponseService
          .downloadSupportFile(this.quoteResponseRxId, item.rmtFile.id, null)
          .subscribe((blob) => (this.viewItemStream = blob));
      } else {
        this.quoteResponseService.downloadSupportFile(this.quoteResponseRxId, item.rmtFile.id, item.rmtFile.name);
      }
    } else {
      if (isPdf(item?.rmtFile)) {
        this.quoteRequestService
          .downloadSupportFile(this.quoteRequestRxId, item.rmtFile.id, null)
          .subscribe((blob) => (this.viewItemStream = blob));
      } else {
        this.quoteRequestService.downloadSupportFile(this.quoteRequestRxId, item.rmtFile.id, item.rmtFile.name);
      }
    }
  }
}
