import { Directive, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormContainerService } from '../services/form-container.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FormActionBase implements OnInit, OnDestroy {
  // id prop is for testing purposes
  id = 'app-form-button-';
  @Input() for: string;
  sub: Subscription;
  @Input() confirmOnCancel = true;
  @Input() disabled: boolean;

  constructor(
    private formContainerService: FormContainerService,
    @Optional() public formComponent: BaseFormComponent,
    private dialogService: DialogsService
  ) {}

  ngOnInit(): void {
    if (!this.formComponent) {
      this.sub = this.formContainerService.getForm(this.for).subscribe((form) => {
        this.formComponent = form;
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  edit() {
    this.formCheck();
    this.formComponent.toggleEdit(true);
  }

  cancel() {
    this.formCheck();
    if (this.confirmOnCancel && this.formComponent?.form?.dirty) {
      this.dialogService
        .discardChangesConfirmationDialog()
        .afterClosed()
        .subscribe((res) => {
          if (!res) return;
          this.formComponent.cancel();
        });
    } else {
      this.formComponent.cancel();
    }
  }

  submit() {
    this.formCheck();
    this.formComponent.submit();
  }

  formCheck() {
    if (!this.formComponent) {
      throw Error(`No formContainer was found with id '${this.for}'.`);
    }
  }

  getSubmitColor() {
    if (this.formComponent?.invalid && this.formComponent?.submitted) return 'warn';
    return 'primary';
  }
}
