import { Injectable } from '@angular/core';
import { Color } from '@common/components/color-picker/models/color.model';
import { ColorInputFormat } from '@common/components/color-picker/models/color-input-format';
import { stringInputToObject } from '@common/components/color-picker/helpers/color-helpers';

@Injectable()
export class ColorAdapter {
  constructor() {}

  sameColor(a: Color | string, b: Color | string) {
    const aStr = a instanceof Color ? a.hex : a;
    const bStr = b instanceof Color ? b.hex : b;
    if (aStr == null && bStr == null) return true;
    if (aStr != null && bStr != null) {
      return aStr === bStr;
    }

    return false;
  }

  format(c: Color | string, format: ColorInputFormat): string {
    if (c instanceof Color) {
      return c.toString(format);
    }
    return c;
  }

  parse(value: string): Color | string | null {
    const obj = stringInputToObject(value);
    if (obj) {
      return new Color(obj.r, obj.g, obj.b, obj.a).hex;
    }
    return null;
  }
}
