import { QueryModel } from '@data-table-lib/data-table-config';
import { DataTableKey } from '@main/store/data-table/data-table.state-model';
import { QueryParamModel } from '@app/tools/url';

export namespace DataTable {
  export class Reset {
    static readonly type = '[DataTable] Reset';
  }

  export class SetQueryModel {
    static readonly type = '[DataTable] Set QueryModel ';

    constructor(
      public tableKey: string,
      public queryModel: QueryModel,
      public advancedQuery: string
    ) {}
  }

  export class SetQueryParams {
    static readonly type = '[DataTable] Set SetQueryParams ';

    constructor(
      public tableKey: string,
      public workspaceQueryParam: QueryParamModel
    ) {}
  }

  export class SetPagingModel {
    static readonly type = '[DataTable] Set PagingModel ';

    constructor(
      public tableKey: string,
      public pagingModel: QueryModel
    ) {}
  }

  export class SetDisplayColumns {
    static readonly type = '[DataTable] Set Display Columns ';

    constructor(
      public tableKey: string,
      public columns: string[]
    ) {}
  }
}
