import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import { FileExtModel, FileModel } from '@file-upload-lib/file.model';

export enum ExtractType {
  OCR = 'OCR',
  DY = 'DY',
  RMT_XML = 'RMT_XML',
  EXCEL = 'XLSX'
}

export class ExtractTextModel {
  fields: OcrModel[];
  extractedObj: QuoteResponseModel;
}

export class ExtractFileModel {
  extractText: ExtractTextModel;
  processedFiles: FileExtModel[];
  extractType: ExtractType;
  supportFileSelected: FileModel;

  constructor(
    extractText: ExtractTextModel,
    processedFiles: FileExtModel[],
    extractType: ExtractType,
    supportFileSelected: FileModel = null
  ) {
    this.extractText = extractText;
    this.processedFiles = processedFiles;
    this.extractType = extractType;
    this.supportFileSelected = supportFileSelected;
  }
}

export enum ExtractAuditStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  IN_PROCESS = 'IN_PROCESS'
}

export class ExtractAuditModel {
  id: number;
  optionCount: number;
  status: ExtractAuditStatus;
  type: ExtractType;
}
