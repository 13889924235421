import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisclosureService } from '@common/services/disclosure.service';
import { ParticipantService } from '@common/services/participant.service';
import { QuoteRequestService } from '@common/services/quote-request.service';
import { Observable } from 'rxjs';
import { isPdf } from '@file-upload-lib/file.model';
import { BaseManifestDialogComponent } from '@common/dialogs/manifest-dialogs/base-manifest-dialog/base-manifest-dialog.component';
import { ManifestEntryModel, SourceType } from '@common/models/manifest-entry.model';

@Component({
  templateUrl: './base-manifest-dialog/base-manifest-dialog.component.html',
  styleUrls: ['./base-manifest-dialog/base-manifest-dialog.component.scss']
})
export class QuoteRequestManifestDialogComponent extends BaseManifestDialogComponent<ManifestEntryModel> {
  quoteRequestId: number;
  fileNameMap: Map<SourceType, (item: ManifestEntryModel) => string> = new Map([
    [SourceType.RMT_FILE, (_) => 'RMT File'],
    [SourceType.COVER_SHEET, (_) => 'Cover Sheet'],
    [SourceType.DISCLOSURE, (_) => 'Disclosure'],
    [SourceType.PARSED_PARTICIPANT, (_) => 'Members Parsed'],
    [SourceType.PARSED_DISCLOSURE, (_) => 'Claims History Parsed']
  ]);

  docTypeMap = new Map<SourceType, string>([
    [SourceType.RMT_FILE, 'Supporting File'],
    [SourceType.COVER_SHEET, 'Cover Sheet'],
    [SourceType.PARTICIPANT, 'Member'],
    [SourceType.DISCLOSURE, 'Disclosure'],
    [SourceType.PARSED_PARTICIPANT, 'Member'],
    [SourceType.PARSED_DISCLOSURE, 'Disclosure']
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      zipFileName: string;
      ids: any;
      loadFunction: () => Observable<[boolean, ManifestEntryModel[]]>;
    },
    private participantService: ParticipantService,
    private disclosureService: DisclosureService,
    private quoteRequestService: QuoteRequestService
  ) {
    super(data);
    this.dialogLabel = `Download Request - ${data.zipFileName}`;
    this.quoteRequestId = data.ids.quoteRequestId;
  }

  getViewIcon(item: ManifestEntryModel) {
    if (isPdf(item?.rmtFile) || item.source === SourceType.COVER_SHEET) {
      return 'remove_red_eye';
    }
    return 'cloud_download';
  }

  downloadItem(item: ManifestEntryModel) {
    switch (item.source) {
      case SourceType.COVER_SHEET:
        this.downloadCoverSheet();
        break;
      case SourceType.DISCLOSURE:
        this.downloadDisclosure(item);
        break;
      case SourceType.PARTICIPANT:
        this.downloadParticipant(item);
        break;
      case SourceType.PARSED_DISCLOSURE:
        this.downloadParsedDisclosure();
        break;
      case SourceType.PARSED_PARTICIPANT:
        this.downloadParsedParticipant();
        break;
      case SourceType.RMT_FILE:
        this.downloadRmtFile(item);
        break;
      default:
        this.downloadRmtFile(item);
        break;
    }
  }

  downloadCoverSheet(): void {
    this.quoteRequestService
      .downloadCoverSheet(this.quoteRequestId, this.data?.ids?.quoteResponseId)
      .subscribe((blob) => {
        this.viewItemStream = blob;
      });
  }

  downloadDisclosure(item: ManifestEntryModel): void {
    this.quoteRequestService.downloadSupportFile(this.quoteRequestId, item.rmtFile.id, item.rmtFile.name + '.csv');
  }

  downloadManifest(): Observable<Blob> {
    return this.quoteRequestService.downloadQuoteRequestPackage(
      this.quoteRequestId,
      this.data?.ids?.quoteResponseId,
      `${this.data.zipFileName}.zip`,
      this.manifestList.selectedOptions.selected.map((item) => item.value)
    );
  }

  downloadParsedDisclosure(): void {
    this.disclosureService.downloadDisclosuresCsv(this.quoteRequestId, 'claim_history_parsed.csv');
  }

  downloadParsedParticipant(): void {
    this.participantService.downloadParticipantsCsv(this.quoteRequestId, 'census_parsed.csv');
  }

  downloadParticipant(item: ManifestEntryModel): void {
    this.quoteRequestService.downloadSupportFile(this.quoteRequestId, item.rmtFile.id, item.rmtFile.name + '.csv');
  }

  downloadRmtFile(item: ManifestEntryModel): void {
    if (isPdf(item?.rmtFile)) {
      this.quoteRequestService.downloadSupportFile(this.quoteRequestId, item.rmtFile.id, null).subscribe((blob) => {
        this.viewItemStream = blob;
      });
    } else {
      this.quoteRequestService.downloadSupportFile(this.quoteRequestId, item.rmtFile.id, item.rmtFile.name);
    }
  }
}
