import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseFieldComponent } from '@form-lib/fields/base-field/base-field.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerivedValueFieldService } from './derived-value-field.service';

@Component({
  selector: 'app-derived-value-field',
  providers: [DerivedValueFieldService],
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span class="label-text" truncateText *ngIf="inline"> {{ label }}</span>
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)"> {{ label }} </mat-label>
        <input matInput [value]="value$ | async" type="text" disabled />
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class DerivedValueFieldComponent extends BaseFieldComponent implements OnInit {
  value$: Observable<string>;

  constructor(private service: DerivedValueFieldService, private currencyPipe: CurrencyPipe) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.dataDef.type === 'currency') {
      this.value$ = this.service
        .getDerivedValue(this.derivedValue, this.control)
        .pipe(map((v) => this.currencyPipe.transform(+v, 'USD', 'symbol', '1.2-2')));
    } else {
      this.value$ = this.service.getDerivedValue(this.derivedValue, this.control);
    }
  }
}
