import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { DataTableStateModel, StoredTableMetaData } from '@main/store/data-table/data-table.state-model';
import { DataTable } from '@main/store/data-table/data-table.actions';
import { patch } from '@ngxs/store/operators';

const dataTableStateModelDefault: DataTableStateModel = {
  tables: {}
};
@State<DataTableStateModel>({
  name: 'datatable',
  defaults: dataTableStateModelDefault
})
@Injectable()
export class DataTableState {
  constructor(private store: Store) {}

  @Action(DataTable.Reset)
  resetWorkspace(ctx: StateContext<DataTableStateModel>) {
    ctx.setState(dataTableStateModelDefault);
  }

  @Action(DataTable.SetQueryModel)
  setFilters(ctx: StateContext<DataTableStateModel>, action: DataTable.SetQueryModel) {
    const storedMetaData = ctx.getState().tables[action.tableKey];
    const newStoredMetaData: StoredTableMetaData = {
      queryModel: { ...storedMetaData?.queryModel, ...action.queryModel },
      advancedQuery: action.advancedQuery === null ? storedMetaData?.advancedQuery : action.advancedQuery,
      columns: storedMetaData?.columns,
      workspaceQueryParams: storedMetaData?.workspaceQueryParams
    };

    ctx.setState(
      patch({
        tables: patch({
          [action.tableKey]: newStoredMetaData
        })
      })
    );
  }

  @Action(DataTable.SetQueryParams)
  setQueryParams(ctx: StateContext<DataTableStateModel>, action: DataTable.SetQueryParams) {
    const storedMetaData = ctx.getState().tables[action.tableKey];
    const newStoredMetaData: StoredTableMetaData = {
      queryModel: { ...storedMetaData?.queryModel },
      advancedQuery: storedMetaData?.advancedQuery,
      columns: storedMetaData?.columns,
      workspaceQueryParams: { ...action.workspaceQueryParam }
    };

    ctx.setState(
      patch({
        tables: patch({
          [action.tableKey]: newStoredMetaData
        })
      })
    );
  }

  @Action(DataTable.SetPagingModel)
  setPaging(ctx: StateContext<DataTableStateModel>, action: DataTable.SetPagingModel) {
    const storedMetaData = ctx.getState().tables[action.tableKey];
    const newStoredMetaData: StoredTableMetaData = {
      queryModel: { ...storedMetaData?.queryModel, ...action.pagingModel },
      advancedQuery: storedMetaData?.advancedQuery,
      columns: storedMetaData?.columns,
      workspaceQueryParams: storedMetaData?.workspaceQueryParams
    };

    ctx.setState(
      patch({
        tables: patch({
          [action.tableKey]: newStoredMetaData
        })
      })
    );
  }

  @Action(DataTable.SetDisplayColumns)
  setDisplayColumns(ctx: StateContext<DataTableStateModel>, action: DataTable.SetDisplayColumns) {
    const storedMetaData = ctx.getState().tables[action.tableKey];
    const newStoredMetaData: StoredTableMetaData = {
      queryModel: { ...storedMetaData?.queryModel },
      advancedQuery: storedMetaData?.advancedQuery,
      columns: action.columns,
      workspaceQueryParams: storedMetaData?.workspaceQueryParams
    };

    ctx.setState(
      patch({
        tables: patch({
          [action.tableKey]: newStoredMetaData
        })
      })
    );
  }
}
