import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import {
  AsyncFormValidatorDef,
  CustomAsyncValidatorDef,
  CustomValidatorDef,
  DisablerConfig,
  FORM_VALIDATOR_KEYS,
  FormValidatorDef,
  ValidatorsMap
} from '@form-lib/models/validators.model';
import { DisableFieldIf } from '@form-lib/validators/field.disablers';
import { DisableFormArrayFieldIf } from '@form-lib/validators/form-array.disablers';
import { FormArrayValidators } from '@form-lib/validators/form-array.validators';
import { DataDefModel } from '@lib-resource/data-def.model';
import { replaceInvalidKeyChars } from '@lib-resource/data.utils';
import { AsyncFieldValidators, FieldValidators } from './field.validators';
import { AsyncFormValidators, FormValidators } from './form.validators';

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const mapAsyncValidators = (field: DataDefModel, model: any, asyncValidatorFns): AsyncValidatorFn[] => {
  let asyncValidators = [];
  if (Array.isArray(field.asyncValidators)) {
    asyncValidators = field.asyncValidators;
  } else if (!!field.asyncValidators) {
    asyncValidators = Object.keys(field.asyncValidators);
  }

  return asyncValidators.map((validator) => {
    if (typeof validator === 'string') {
      const validationService = asyncValidatorFns.find((validatorFn) => !!validatorFn[validator]);
      return validationService[validator](model);
    }
    if (validator.key === FORM_VALIDATOR_KEYS.customValidator) {
      // this should be a custom async validator def, which means that the second argument is the function, the third is the message.
      const custom: CustomAsyncValidatorDef = validator as CustomAsyncValidatorDef;
      return AsyncFieldValidators.customValidator(custom.args[1], custom.args[2]);
    }
    return validator;
  });
};

export function mapFieldValidators(validators: ValidatorsMap | FormValidatorDef[]): ValidatorFn[] {
  if (!validators) {
    return [];
  }
  if (Array.isArray(validators)) {
    validators = validators.reduce((acc, validator) => {
      acc[validator.key] = validator.args;
      return acc;
    }, {} as ValidatorsMap);
  }
  return Object.keys(validators).map((validationType) => {
    const validation = validators[validationType];
    switch (validationType) {
      case 'required':
        return Validators.required;
      case 'requiredTrue':
        return Validators.requiredTrue;
      case 'min':
        return Validators.min(validation);
      case 'max':
        return Validators.max(validation);
      case 'usZip':
        return FieldValidators.usZip;
      case 'url':
        return FieldValidators.urlValidator;
      case 'usFedId':
        return FieldValidators.usFedId;
      case 'email':
        return Validators.email;
      case 'eitherOr':
        return FieldValidators.eitherOr(validation[0], validation[1]);
      case 'onlyOneValueOf':
        return FieldValidators.onlyOneValueOf(validation[0]);
      case 'rangeValidator':
        return FieldValidators.rangeValidator(validation[0], validation[1]);
      case 'noDuplicates':
        return FieldValidators.noDuplicates;
      case 'maxLength':
        return Validators.maxLength(validation);
      case 'minLength':
        return Validators.minLength(validation);
      case 'mustBeInteger':
        return FieldValidators.mustBeInteger;
      case 'valueIsTruthy':
        return DisableFieldIf.valueIsTruthy(validation);
      case 'valueContains':
        return DisableFieldIf.valueContains(validation);
      // FormArrayValidators
      case 'thereCanBeOnlyOne':
        return FormArrayValidators.thereCanBeOnlyOne(validation);
      case 'maxSize':
        return FieldValidators.maxSize(validation);
      default:
        return Validators.max(validation);
    }
  });
}

export const mapFormValidators = (validators: FormValidatorDef[]): ValidatorFn[] => {
  if (!validators) return [];

  if (!Array.isArray(validators)) {
    validators = Object.keys(validators).map(
      (key) =>
        ({
          key,
          args: validators[key]
        } as FormValidatorDef)
    );
  }

  return validators
    .map((validator) => {
      if (isFunction(validator)) return validator as any;

      // be sure and switch all these over to the formkey, essentially the key with the dot replaced with a slash
      // this is because angular forms do not like dots
      switch (validator.key) {
        case 'oneOrTheOther':
          return FormValidators.oneOrTheOther(
            replaceInvalidKeyChars(validator.args[0]),
            replaceInvalidKeyChars(validator.args[1])
          );
        case 'eitherOr':
          return FormValidators.eitherOr(
            replaceInvalidKeyChars(validator.args[0]),
            replaceInvalidKeyChars(validator.args[1])
          );
        case 'validDateRange':
          return FormValidators.validDateRange(
            replaceInvalidKeyChars(validator.args[0]),
            replaceInvalidKeyChars(validator.args[1]),
            validator.args[2]
          );
        case 'mustBeGreaterThan':
          return FormValidators.mustBeGreaterThan(
            replaceInvalidKeyChars(validator.args[0]),
            replaceInvalidKeyChars(validator.args[1])
          );
        case 'mustBeLessThanOrEqualTo':
          return FormValidators.mustBeLessThanOrEqualTo(
            replaceInvalidKeyChars(validator.args[0]),
            replaceInvalidKeyChars(validator.args[1])
          );
        case 'allOrNone':
          return FormValidators.allOrNone(validator.args.map((k) => replaceInvalidKeyChars(k)));
        case 'atLeastOneOf':
          return FormValidators.atLeastOneOf(validator.args.map((k) => replaceInvalidKeyChars(k)));
        case FORM_VALIDATOR_KEYS.customValidator:
          validator = validator as CustomValidatorDef; // fix for typing issue
          return FormValidators.customValidator(
            validator.args[0].map((k) => replaceInvalidKeyChars(k)),
            validator.args[1],
            validator.args[2]
          );
        default:
          return null;
      }
    })
    .filter((val) => !!val);
};

export const mapAsyncFormValidators = (validators: AsyncFormValidatorDef[]): AsyncValidatorFn[] => {
  if (!validators) return [];

  return validators.map((validator) => {
    if (isFunction(validator)) return validator as any;

    switch (validator.key) {
      case FORM_VALIDATOR_KEYS.customValidator:
        validator = validator as CustomAsyncValidatorDef; // fix for typing issue
        return AsyncFormValidators.customValidator(validator.args[0], validator.args[1], validator.args[2]);
      default:
        return null;
    }
  });
};

export function mapFormArrayDisablers(disablers: DisablerConfig[]): ValidatorFn[] {
  if (!disablers) return [];

  return disablers.map((disabler) => {
    switch (disabler.key) {
      case 'thereCanBeOnlyOne':
        return DisableFormArrayFieldIf.thereCanBeOnlyOne(disabler);
      case 'controlHasValueOf':
        return DisableFormArrayFieldIf.controlHasValueOf(disabler);
      default:
        throw new Error('Unknown Disabler function: ' + disabler.key);
    }
  });
}
