import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { OrgMinModel } from '@main/org/models/org.model';
import { OrgHttpService } from '@main/org/org-http.service';
import { OrgFunction } from '@common/models/org-type.model';

@Injectable()
export class CredPbmOrgModelOptionService extends AsyncOptions<OrgMinModel> {
  key = 'credPbmOrgModels';
  defaultSort = ['credentialedOrg.name'];

  constructor(private orgHttpService: OrgHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) => {
    if (asyncOptionDeps?.length > 0 || asyncExtras?.orgId) {
      // precedence on selection of the org id: extras, a field that is an account model field, a field with just the org id
      const orgId =
        asyncExtras?.orgId ||
        control.parent.controls[asyncOptionDeps[0]]?.value?.orgId ||
        control.parent.controls[asyncOptionDeps[0]]?.value;
      if (orgId) {
        return this.orgHttpService.getCredentialedOrgs(orgId).pipe(
          map((creds) =>
            creds.map((cred) => cred.credentialedOrg).filter((cred) => cred.orgFunction === OrgFunction.PBM)
          ),
          map((orgMins) => ({ content: this.genLabelVals(orgMins), total: orgMins.length }))
        );
      }
    }
    return of({ content: [], total: 0 });
  };

  valuesFromKeys = (value: OrgMinModel) => {
    if (!value?.id) {
      return of(null);
    }
    return of(this.genLabelVals([value]));
  };

  genLabelVals(orgs: OrgMinModel[]): { label: string; value: OrgMinModel }[] {
    return orgs.map((o) => ({
      label: `${o.name} (ID: ${o.id})`,
      value: o
    }));
  }
}
