import { UserModel } from '@auth/models/user.model';

export enum EndpointConnectionType {
  HOSTED = 'HOSTED',
  REMOTE = 'REMOTE'
}

export enum EndpointFlow {
  CONSUMER = 'CONSUMER',
  PRODUCER = 'PRODUCER'
}

export enum EndpointPackaging {
  ENROLLMENT = 'ENROLLMENT',
  FILING = 'FILING',
  LUMINXC = 'LUMINXC',
  LUMINXD = 'LUMINXD',
  NOTICE = 'NOTICE',
  POWERSTEP = 'POWERSTEP',
  RMT_CONTROL_FILE = 'RMT_CONTROL_FILE',
  RMT_IMPORT = 'RMT_IMPORT',
  RMT_IMPORT_DUP_CLAIM = 'RMT_IMPORT_DUP_CLAIM',
  RMT_RX_AUDIT_CLAIM = 'RMT_RX_AUDIT_CLAIM',
  WLT_IMPORT = 'WLT_IMPORT'
}

export class EndpointConnectionModel {
  constructor(
    public id?: number,
    public type?: EndpointConnectionType,
    public userName?: string,
    public key?: string,
    public url?: string
  ) {}
}

export class EndpointModel {
  constructor(
    public id?: number,
    public endpointConnection?: EndpointConnectionModel,
    public name?: string,
    public directory?: string,
    public flow?: EndpointFlow,
    public packaging?: EndpointPackaging,
    public enabled: boolean = true,
    public controlResultEndpointId?: number
  ) {}
}

export class EndpointEventModel {
  constructor(
    public id?: number,
    public endpoint?: EndpointModel,
    public user?: UserModel,
    public createdDate?: string,
    public success?: boolean,
    public message?: string
  ) {}
}

export class EndpointConnectionTestModel {
  constructor(public messages?: Array<string>) {}
}
