import { OrgFunction } from '@common/models/org-type.model';
import { FormModel } from '@form-lib/models/form.model';
import { FeatureType } from '@main/org/models/org.model';
import { FormFieldDefinitionModel } from '@common/models/form-field-definition.model';

export enum QuoteTypeProduct {
  RX = 'RX',
  STOP_LOSS = 'STOP_LOSS'
}

export class QuoteTypeModel {
  id?: number;
  name?: string;
  icon?: string;
  eslOfficeEnabled?: boolean;
  active?: boolean;
  reqFormLayout?: FormModel;
  respFormLayout?: FormModel;
  responseFields?: Array<string>;
  orgId?: number;
  orgName?: string;
  orgFeatures?: FeatureType[];
  orgFunction?: OrgFunction;
  proposalTemplateRmtFile?: any;
  product?: QuoteTypeProduct;
  quoteTypeFields?: QuoteTypeFieldModel[];
}

export class QuoteTypeMinModel {
  id?: number;
  name?: string;
  icon?: string;
  orgId?: number;
  orgName?: string;
  orgFeatures?: FeatureType[];
  orgFunction?: OrgFunction;
  product?: QuoteTypeProduct;
  quoteTypeFields?: QuoteTypeFieldModel[];
}

export class QuoteTypeFieldModel {
  id?: number;
  quoteTypeId?: number;
  location?: QuoteTypeFieldLocation;
  formFieldDefinition?: FormFieldDefinitionModel;

  constructor(
    id: number,
    quoteTypeId: number,
    fieldDefId: number,
    name: string,
    type: FormFieldType,
    location: QuoteTypeFieldLocation,
    groupName: string,
    inputSize: number,
    maxInputSize: number,
    minInputSize: number,
    required: boolean,
    defaultValues: string[],
    dollarsOnly: boolean
  ) {
    this.id = id;
    this.quoteTypeId = quoteTypeId;
    this.location = location;
    this.formFieldDefinition = new FormFieldDefinitionModel(
      fieldDefId,
      name,
      type,
      groupName,
      inputSize,
      maxInputSize,
      minInputSize,
      required,
      defaultValues,
      dollarsOnly
    );
  }
}

export class QuoteTypeFieldDataModel {
  id?: number;
  quoteTypeField?: QuoteTypeFieldModel;
  quoteRequestId?: number;
  quoteResponseOptionId?: number;
  rxContractId?: number;
  values?: string[];
}

export class QuoteTypeFieldDataUIModel extends QuoteTypeFieldDataModel {
  value?: any;
  comment?: string;
}

export enum FormFieldType {
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  YES_NO_COMMENTS = 'YES_NO_COMMENTS'
}

export enum QuoteTypeFieldLocation {
  QUOTE_REQUEST = 'QUOTE_REQUEST',
  QUOTE_RESPONSE_OPTION = 'QUOTE_RESPONSE_OPTION',
  // UI Only option
  QUOTE_REQUEST_AND_QUOTE_RESPONSE_OPTION = 'QUOTE_REQUEST_AND_QUOTE_RESPONSE_OPTION'
}
