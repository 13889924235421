import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getIdValue } from '@form-lib/utils/control.utils';
import { map } from 'rxjs/operators';
import { OrgExcelConfigService } from '@main/org-excel-config/org-excel-config.service';

@Injectable()
export class OrgExcelConfigValidators {
  constructor(private excelConfigService: OrgExcelConfigService) {}

  excelTemplateNameUnique() {
    return (control: AbstractControl) => {
      const id = getIdValue(control);
      return this.excelConfigService
        .excelTemplateNameExists(id, control.value)
        .pipe(
          map((val) =>
            !!val ? { uniqueExcelTemplateName: 'Excel template name is already in use, choose another' } : null
          )
        );
    };
  }
}
