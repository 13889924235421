import { AbstractControl } from '@angular/forms';

export const markFormGroupTouched = (formGroup) => {
  (<any>Object).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
};

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

export function compareFn(val, val2): boolean {
  if (val && val.id && val2 && val2.id) {
    return val.id === val2.id;
  }
  // For non-object values
  return val === val2;
}

export function getIdValue(control: AbstractControl) {
  return control?.parent?.controls['id']?.value;
}
