import { getFormattedDateString } from '@app/tools/date';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { mapValueToLabel } from '@lib-resource/data.utils';
import { AppCurrencyPipe } from '@shared/pipes/app-currency.pipe';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { AppPercentPipe } from '@shared/pipes/percent.pipe';

const locale = 'en-US';
export class DataFormatter {
  private static currencyPipe = new AppCurrencyPipe(locale);
  private static fileSizePipe = new FileSizePipe();
  private static percentPipe = new AppPercentPipe(locale);

  static transform(value, dataDef: DataDefModel, nullValueReplacement?: string) {
    if (value === undefined || value === null) {
      return DataFormatter.nullValue(value, nullValueReplacement);
    }

    switch (dataDef.type) {
      case DATA_TYPES.date:
      case DATA_TYPES.dateTime:
        if (!!value.toISOString || isNaN(value)) {
          // it is a date thing and can get the formatted string
          return dataDef.type === DATA_TYPES.dateTime
            ? getFormattedDateString(value, 'M/d/yyyy, h:mm a')
            : getFormattedDateString(value);
        }
        // it is just the value and have to construct the words
        return value !== 0 ? (value > 0 ? `today +${value} days` : `today ${value} days`) : 'today';
      case DATA_TYPES.currency:
        return dataDef.dollarsOnly
          ? this.currencyPipe.transform(value, 'USD', '1.0-0', locale, 'symbol')
          : this.currencyPipe.transform(value);
      case DATA_TYPES.percentage:
        return this.percentPipe.transform(value);
      case DATA_TYPES.enum:
      case DATA_TYPES.select:
      case DATA_TYPES.multiSelect:
        if (value instanceof Array && !value?.length) return nullValueReplacement;
        return mapValueToLabel(dataDef, value);
      case DATA_TYPES.boolean:
        if (!!dataDef.options) {
          return mapValueToLabel(dataDef, value);
        }
        return value ? 'Yes' : 'No';
      case DATA_TYPES.fileSize:
        return this.fileSizePipe.transform(value);
      default:
        return DataFormatter.nullValue(value, nullValueReplacement);
    }
  }

  private static nullValue(value, nullValueReplacement: string) {
    // In some cases, display a specific value (i.e. 'empty') instead of null.
    return (value === undefined || value === null || value === '') && !!nullValueReplacement
      ? nullValueReplacement
      : value;
  }
}
