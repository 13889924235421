import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OcrTemplateModel } from '@main/org-ocr-config/model/org-ocr-config.model';
import { DyXmlExtractComponent } from '@common/components/quote/extract-text-dialog/dy-extract/dy-xml-extract.component';
import { ExtractFileModel, ExtractType } from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { OcrExtractComponent } from '@common/components/quote/extract-text-dialog/ocr-extract/ocr-extract.component';
import { RmtXmlExtractComponent } from '@common/components/quote/extract-text-dialog/rmt-xml-extract/rmt-xml-extract.component';
import { QuoteLinqVersionModel } from '@common/models/quote-linq-version.model';
import { ExcelExtractComponent } from '@common/components/quote/extract-text-dialog/excel-extract/excel-extract.component';
import { ExcelTemplateModel } from '@main/org-excel-config/model/org-excel-config.model';

@Component({
  templateUrl: 'extract-text-dialog.component.html'
})
export class ExtractTextDialogComponent {
  selectedTabIndex = 0;
  selectedExtractType: ExtractType;
  ocrProcessing = false;
  ocrValid = false;
  excelValid = false;
  excelProcessing = false;
  dyProcessing = false;
  dyValid = false;
  rmtXmlProcessing = false;
  rmtXmlValid = false;
  multipleOptions = true;

  @ViewChild(OcrExtractComponent) ocrExtractComponent: OcrExtractComponent;
  @ViewChild(DyXmlExtractComponent) dyXmlExtractComponent: DyXmlExtractComponent;
  @ViewChild(RmtXmlExtractComponent) rmtXmlExtractComponent: RmtXmlExtractComponent;
  @ViewChild(ExcelExtractComponent) excelExtractComponent: ExcelExtractComponent;

  constructor(
    private dialogRef: MatDialogRef<ExtractTextDialogComponent, ExtractFileModel>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      ocrTemplates: OcrTemplateModel[];
      excelTemplates: ExcelTemplateModel[];
      dyXmlEnabled: boolean;
      rmtXmlEnabled: boolean;
      responseId: number;
      version: QuoteLinqVersionModel;
    }
  ) {
    if (data.ocrTemplates?.length > 0 && !data.dyXmlEnabled && !data.rmtXmlEnabled && data.excelTemplates?.length < 1) {
      this.selectedExtractType = ExtractType.OCR;
      this.multipleOptions = false;
    } else if (
      data.ocrTemplates?.length < 1 &&
      data.dyXmlEnabled &&
      !data.rmtXmlEnabled &&
      data.excelTemplates?.length < 1
    ) {
      this.selectedExtractType = ExtractType.DY;
      this.multipleOptions = false;
    } else if (
      data.ocrTemplates?.length < 1 &&
      !data.dyXmlEnabled &&
      data.rmtXmlEnabled &&
      data.excelTemplates?.length < 1
    ) {
      this.selectedExtractType = ExtractType.RMT_XML;
      this.multipleOptions = false;
    } else if (
      data.ocrTemplates?.length < 1 &&
      !data.dyXmlEnabled &&
      !data.rmtXmlEnabled &&
      data.excelTemplates?.length > 0
    ) {
      this.selectedExtractType = ExtractType.EXCEL;
      this.multipleOptions = false;
    }
  }

  ocrExtract($event: ExtractFileModel) {
    if ($event) {
      this.dialogRef.close($event);
    }
  }

  excelExtract($event: ExtractFileModel) {
    if ($event) {
      this.dialogRef.close($event);
    }
  }

  dyExtract($event: ExtractFileModel) {
    if ($event) {
      this.dialogRef.close($event);
    }
  }

  rmtXmlExtract($event: ExtractFileModel) {
    if ($event) {
      this.dialogRef.close($event);
    }
  }

  requestExtract() {
    if (this.valid()) {
      if (this.selectedExtractType === ExtractType.OCR) {
        this.ocrExtractComponent.requestOcr();
      } else if (this.selectedExtractType === ExtractType.DY) {
        this.dyXmlExtractComponent.requestXmlExtract();
      } else if (this.selectedExtractType === ExtractType.RMT_XML) {
        this.rmtXmlExtractComponent.requestXmlExtract();
      } else if (this.selectedExtractType === ExtractType.EXCEL) {
        this.excelExtractComponent.requestExcel();
      }
    }
  }

  backDisabled() {
    return this.selectedTabIndex === 0 || this.processing();
  }

  previousTab() {
    if (this.selectedTabIndex === 0) {
      return;
    }
    this.selectedTabIndex--;
  }

  nextTab() {
    this.selectedTabIndex++;
  }

  nextDisabled() {
    return !this.selectedExtractType || this.selectedTabIndex > 0 || this.processing();
  }

  processing() {
    return (
      (this.selectedExtractType === ExtractType.OCR && this.ocrProcessing) ||
      (this.selectedExtractType === ExtractType.DY && this.dyProcessing) ||
      (this.selectedExtractType === ExtractType.RMT_XML && this.rmtXmlProcessing) ||
      (this.selectedExtractType === ExtractType.EXCEL && this.excelProcessing)
    );
  }

  valid() {
    return (
      (this.selectedExtractType === ExtractType.OCR && this.ocrValid) ||
      (this.selectedExtractType === ExtractType.DY && this.dyValid) ||
      (this.selectedExtractType === ExtractType.RMT_XML && this.rmtXmlValid) ||
      (this.selectedExtractType === ExtractType.EXCEL && this.excelValid)
    );
  }

  selectedExtractTypeChanged() {
    // reset all the flags on change
    this.ocrProcessing = false;
    this.ocrValid = false;
    this.dyProcessing = false;
    this.dyValid = false;
    this.rmtXmlProcessing = false;
    this.rmtXmlValid = false;
    this.excelProcessing = false;
    this.excelValid = false;
  }
}
