import { Pipe, PipeTransform } from '@angular/core';
import { ActionDef } from '@data-table-lib/models/data-table.model';

@Pipe({
  name: 'hideIf'
})
export class HideIfPipe implements PipeTransform {
  transform(value: ActionDef[], row?: any): any {
    return value.filter((def) => (def.hideIf ? !def.hideIf(row) : true)); // only remove if there is a hideIf and returns true
  }
}
