import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  laserUiFormGroupDef,
  laserUiOptionsDef,
  quoteResponseLaserDef
} from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import { DataDefModel } from '@lib-resource/data-def.model';
import { FormGeneratorService } from '@form-lib/services/form-generator.service';
import { LaserUiModel } from '@quote/quote-response/quote-response-stepper/laser/laserUi.model';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quote-response-laser',
  templateUrl: './laser.component.html',
  styleUrls: ['../quote-response-stepper.component.scss', './laser.component.scss']
})
export class LaserComponent implements OnChanges, OnDestroy {
  @Input() uiLasers: LaserUiModel[];
  @Input() touched: boolean;
  @Input() readOnly: boolean;
  @Output() update = new EventEmitter<{
    uiLasers: LaserUiModel[];
    valid: boolean;
  }>();

  form: UntypedFormGroup;
  defs: DataDefModel[] = [laserUiFormGroupDef];
  laserUiOptionDataDef: DataDefModel = laserUiOptionsDef;

  nameDataDef = quoteResponseLaserDef.find((def) => def.key === 'name');
  relationshipDataDef = quoteResponseLaserDef.find((def) => def.key === 'relationship');
  conditionDataDef = quoteResponseLaserDef.find((def) => def.key === 'condition');
  conditionalDataDef = quoteResponseLaserDef.find((def) => def.key === 'conditional');
  laserFormGroups;

  prev: LaserUiModel[];

  removeIcon = faXmark;

  localUpdatedAlready: boolean = false;

  formValueChanges: Subscription;

  constructor(private formService: FormGeneratorService) {}

  ngOnChanges({ uiLasers, importData }: SimpleChanges): void {
    if (!this.localUpdatedAlready && uiLasers?.currentValue) {
      this.removeSub();
      this.form = this.formService.generateFormGroup(this.defs, null, null, { lasers: uiLasers.currentValue });
      this.formValueChanges = this.form.valueChanges
        .pipe(debounceTime(500))
        .subscribe((formData) => this.formChanges(formData));
      const laserFormControls = this.form.controls['lasers'];
      this.laserFormGroups = (<FormArray>laserFormControls).controls;
      this.form.markAllAsTouched();

      this.prev = JSON.parse(JSON.stringify(this.uiLasers));
    }
    this.localUpdatedAlready = false;
  }

  ngOnDestroy() {
    this.removeSub();
  }

  formChanges(formData: { lasers: LaserUiModel[] }) {
    if (this.prev && JSON.stringify(this.prev) !== JSON.stringify(formData.lasers)) {
      this.emitUpdate(formData.lasers);
      this.localUpdatedAlready = true;
    }
    this.prev = JSON.parse(JSON.stringify(formData.lasers));
  }

  getFormControl(key: string): any {
    return this.form?.controls[key];
  }

  remove(uiLaserIndex: number) {
    const laserFormControls = this.form.controls['lasers'];
    (<FormArray>laserFormControls).removeAt(uiLaserIndex);
  }

  private emitUpdate(uiLasers: LaserUiModel[]) {
    this.update.emit({
      uiLasers: uiLasers,
      valid: !this.form.invalid
    });
  }

  private removeSub() {
    if (this.formValueChanges) {
      this.formValueChanges.unsubscribe();
    }
  }
}
