import { Component, HostBinding, Input, OnInit } from '@angular/core';

/**
 * This component is a sibling to the [appInProgressIndicator] directive. Use this component when
 * the loading indicator needs to be displayed as a stand-alone component with more granular input parameters.
 * This component relies on the use of *ngIf="loading" in order to hide the component when not in a loading state.
 * Functionality this component provides:
 *   diameter - allows the control of the size of the circle of the indicator. Usage: diameter="45px" or diameter="2.25rem"
 *   indicatorStyle - thick | thin, this controls the thickness of the circle of the indicator. Usage: indicatorStyle="this" (thick is the default)
 *   loadingMessage - controls what text is displayed above the loading indicator. Usage: loadingMessage="Loading..." (defaults to no message)
 */
@Component({
  selector: 'app-in-progress-indicator',
  templateUrl: 'in-progress-indicator.component.html',
  styleUrls: ['in-progress-indicator.component.scss']
})
export class InProgressIndicatorComponent implements OnInit {
  @Input()
  @HostBinding('style.--indicator-diameter')
  diameter: string;

  @Input()
  indicatorStyle: 'thin' | 'thick' = 'thick';

  @Input()
  loadingMessage: string = '';

  @HostBinding('style.--indicator-border-width')
  indicatorBorderWidth: string;

  ngOnInit(): void {
    switch (this.indicatorStyle) {
      case 'thin':
        this.indicatorBorderWidth = '1px';
        break;
      case 'thick':
        this.indicatorBorderWidth = '5px';
        break;
      default:
        this.indicatorBorderWidth = '5px';
        break;
    }
  }
}
