import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkspaceDialogData } from '@common/dialogs/dialogs.model';
import { CollapsibleFormComponent } from '@form-lib/forms/collapsible-form/collapsible-form.component';
import { FormModel } from '@form-lib/models/form.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faShareSquare } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-solid-svg-icons';
import { ConfirmationDialogAction, ConfirmationDialogType } from '@common/models/quote-response.model';

/**
 * Confirmation Dialog used in Workspace.
 * It is used when updating the Proposal Status in a workspace. This confirmation dialog
 * will provide the standard proposal header information on the top of the dialog.
 */
@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>{{ label }}</app-dialog-label>
    <app-dialog-content>
      <div class="grid justify-center">
        <div><fa-icon *ngIf="!!icon" [icon]="icon" class="color-rmt-dark-tint-1" size="2x"></fa-icon></div>
        <div class="message mw">{{ message }}</div>
        <div>
          <i class="mw">{{ subMessage }}</i>
        </div>
      </div>
      <app-collapsible-form
        *ngIf="!!formModel"
        #dialogForm
        [sections]="formModel.sections"
        [data]="data.quoteResponse"
        [definitions]="formModel.fields"
        [editing]="true"
        (doSubmit)="onFormSubmit($event)"
      >
      </app-collapsible-form>
    </app-dialog-content>
    <app-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!!dialogForm && dialogForm?.form.invalid"
        (click)="onPrimaryActionClick()"
      >
        {{ actionText }}
      </button>
      <button
        *ngIf="secondaryActionText"
        [disabled]="!!dialogForm && dialogForm?.form.invalid"
        (click)="onSecondaryActionClick()"
        mat-raised-button
      >
        {{ secondaryActionText }}
      </button>
      <button mat-raised-button mat-dialog-close>
        {{ cancelText }}
      </button>
    </app-dialog-actions>
  `,
  styles: [
    `
      .message {
        font-size: 1.5rem;
      }

      .mw {
        font-weight: 300;
      }
    `
  ]
})
export class ProposalConfirmationDialogComponent {
  label: string = 'Are you sure?';
  actionText: string = 'Confirm';
  secondaryActionText: string;
  cancelText: string = 'Cancel';
  icon: IconDefinition;
  message: string;
  subMessage: string;
  formModel: FormModel;
  action: ConfirmationDialogAction;

  @ViewChild('dialogForm') dialogForm: CollapsibleFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkspaceDialogData,
    private dialogRef: MatDialogRef<ProposalConfirmationDialogComponent>
  ) {
    switch (data.dialogType) {
      case ConfirmationDialogType.ACCEPT_INVITATION:
        this.label = 'Accept Request';
        this.icon = faCheckSquare;
        this.message = 'Accept request and notify the issuer.';
        this.actionText = data.customActionText || 'Accept';
        this.secondaryActionText = data.customSecondaryActionText;
        break;
      case ConfirmationDialogType.DTQ:
        this.label = 'Confirm Decline Reason';
        this.icon = faMessageXmark;
        this.message = 'Select a reason to continue.';
        this.actionText = 'Confirm';
        this.formModel = data.formModel;
        break;
      case ConfirmationDialogType.LOST:
        this.label = 'Confirm Lost Reason';
        this.icon = faMessageXmark;
        this.message = 'Select a reason to continue.';
        this.actionText = 'Confirm';
        this.formModel = data.formModel;
        break;
      case ConfirmationDialogType.SUBMIT_QUOTE:
        this.label = 'Submit Quote';
        this.icon = faShareSquare;
        this.message = 'Submit quote and notify the issuer.';
        this.actionText = 'Submit';
        this.formModel = data.formModel;
        break;
      default:
        throw new Error('Unknown Confirmation Dialog Type');
    }
  }

  onPrimaryActionClick() {
    this.action = ConfirmationDialogAction.PRIMARY;
    this.onClick();
  }

  onSecondaryActionClick() {
    this.action = ConfirmationDialogAction.SECONDARY;
    this.onClick();
  }

  onClick(): void {
    if (!!this.dialogForm) {
      this.dialogForm.submit();
    } else {
      this.dialogRef.close({ action: this.action });
    }
  }

  onFormSubmit(event) {
    if (
      this.data.dialogType === ConfirmationDialogType.DTQ ||
      this.data.dialogType === ConfirmationDialogType.LOST ||
      this.data.dialogType === ConfirmationDialogType.SUBMIT_QUOTE
    ) {
      event.value.action = this.action;
      this.dialogRef.close(event.value); // return the form values
    } else {
      this.dialogRef.close(this.action);
    }
  }
}
