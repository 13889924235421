import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { filterTableData } from '@data-table-lib/data-table.utils';
import { BaseSelectFieldComponent } from '@form-lib/fields/base-select-field/base-select-field.component';
import { OptionsService } from '@form-lib/options/options.service';
import { LabelValue } from '@lib-resource/label-value.model';
import { combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-async-select-field',
  templateUrl: './async-select-field.component.html',
  styleUrls: ['../../form-lib.scss', './async-select-field.component.scss']
})
export class AsyncSelectFieldComponent extends BaseSelectFieldComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSelect) select: MatSelect;
  pageSize = 1000;
  displayValue$;
  currentOptions$ = this.currentOptionsSource.asObservable().pipe(debounceTime(0));
  searchTextBoxControl = new UntypedFormControl('');
  allOptions: LabelValue[];

  constructor(optionsService: OptionsService) {
    super(optionsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.displayValue$ = combineLatest([
      this.control.valueChanges.pipe(startWith(this.control.value)),
      this.currentOptions$
    ]).pipe(map(([controlVal, currentOptions]) => this.getDisplayValue(currentOptions, controlVal)));
    this.subs.add(this.displayValue$);

    this.searchTextBoxControl.valueChanges.subscribe((val) => this._filter(val));
    // Initial retrieval of options data
    this.getAsyncOptions();

    this.asyncDependentSelectFieldSetup();
  }

  // This forces select trigger to float
  ngAfterViewInit(): void {
    setTimeout(() => {
      Object.defineProperty(this.select, 'empty', {
        get: function() {
          return false;
        }
      });
    }, 0);
  }

  /**
   * Used to filter data based on search input
   */
  _filter(filterValue: string): void {
    const results = filterTableData(null, filterValue, ['label', 'value'], this.allOptions);
    this.currentOptionsSource.next(results);
  }

  updateOptions(value) {
    // Should only run on init
    this.allOptions = value;
    this.currentOptionsSource.next(value);
  }

  protected resetToNoSelectedOptions() {
    this.control.setValue(null);
  }
}
