import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { UrlUtilService } from '@common/services/url-util.service';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ExcelTemplateModel } from '@main/org-excel-config/model/org-excel-config.model';

@Injectable({
  providedIn: 'root'
})
export class OrgExcelConfigService {
  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  constructor(
    private store: Store,
    private http: HttpClient,
    private urlUtil: UrlUtilService
  ) {}

  excelTemplateNameExists(id: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );
    if (id) {
      params = params.set('id', `${id}`);
    }
    return this.http.get<boolean>(`${this.baseUrl}/excel-template/exists`, { params: params });
  }

  getExcelTemplates(): Observable<ExcelTemplateModel[]> {
    return this.store
      .select(SiteFilterSelectors.singleSelectedOrgId)
      .pipe(switchMap((orgId) => this.http.get<ExcelTemplateModel[]>(`${SERVER_API_URL}/org/${orgId}/excel-template`)));
  }

  createExcelTemplate(excelTemplate: ExcelTemplateModel): Observable<ExcelTemplateModel> {
    return this.http.post<ExcelTemplateModel>(`${this.baseUrl}/excel-template`, excelTemplate);
  }

  updateExcelTemplate(id: number, excelTemplate: ExcelTemplateModel): Observable<ExcelTemplateModel> {
    return this.http.put<ExcelTemplateModel>(`${this.baseUrl}/excel-template/${id}`, excelTemplate);
  }

  getExcelTemplate(id: number): Observable<ExcelTemplateModel> {
    return this.http.get<ExcelTemplateModel>(`${this.baseUrl}/excel-template/${id}`);
  }

  deleteExcelTemplate(id: number): Observable<any> {
    return this.http.delete<ExcelTemplateModel>(`${this.baseUrl}/excel-template/${id}`);
  }
}
