import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheckCircle, faClose, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { ExtractFileModel, ExtractType } from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { ExtractTextService } from '@common/components/quote/extract-text-dialog/service/extract-text.service';
import { finalize } from 'rxjs/operators';
import { FileDocType, FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-dy-extract',
  template: `
    <div class="rmt-card" [appInProgressIndicator]="processing | async">
      <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
      A PDF and XML file of your proposal are required to continue.
      <div class="pt-3">
        <div class="file-upload-success" *ngIf="pdfFile; else noPdf">
          <fa-icon [icon]="fileSuccessIcon" class="color-success file-success pl-2 pr-2"></fa-icon>
          <span class="text-no-overflow">
            {{
              pdfFile.name.lastIndexOf('.') > 0
                ? pdfFile.name.substring(0, pdfFile.name.lastIndexOf('.'))
                : pdfFile.name
            }}
          </span>
          <span>
            {{ pdfFile.name.lastIndexOf('.') > 0 ? pdfFile.name.substring(pdfFile.name.lastIndexOf('.')) : '' }}
          </span>
          <fa-icon [icon]="closeIcon" class="cursor-click pl-2 pr-2 pt-1" (click)="clearPdfFile()"></fa-icon>
        </div>
      </div>
      <ng-template #noPdf>
        <app-file-upload
          labelText="Add PDF File (required)"
          [multi]="false"
          (selectFile)="onSelectPdfFile($event)"
        ></app-file-upload>
      </ng-template>
      <div class="pt-3">
        <div class="file-upload-success" *ngIf="xmlFile; else noXml">
          <fa-icon [icon]="fileSuccessIcon" class="color-success file-success pl-2 pr-2"></fa-icon>
          <span class="text-no-overflow">
            {{
              xmlFile.name.lastIndexOf('.') > 0
                ? xmlFile.name.substring(0, xmlFile.name.lastIndexOf('.'))
                : xmlFile.name
            }}
          </span>
          <span>
            {{ xmlFile.name.lastIndexOf('.') > 0 ? xmlFile.name.substring(xmlFile.name.lastIndexOf('.')) : '' }}
          </span>
          <fa-icon [icon]="closeIcon" class="cursor-click pl-2 pr-2 pt-1" (click)="clearXmlFile()"></fa-icon>
        </div>
      </div>
      <ng-template #noXml>
        <app-file-upload
          labelText="Add XML File (required)"
          [multi]="false"
          (selectFile)="onSelectXmlFile($event)"
        ></app-file-upload>
      </ng-template>
    </div>
  `
})
export class DyXmlExtractComponent implements OnInit {
  pdfFile: File;
  xmlFile: File;

  infoType = 'primary';
  fileSuccessIcon = faCheckCircle;
  closeIcon = faClose;
  infoIcon: IconDefinition = faInfoCircle;

  @Input() responseId: number;
  @Output() valid = new EventEmitter<boolean>();
  @Output() processing = new EventEmitter<boolean>();
  @Output() extractedResponse = new EventEmitter<ExtractFileModel>();

  constructor(private extractTextService: ExtractTextService) {}

  ngOnInit() {
    this.valid.emit(false);
  }

  onSelectPdfFile(event: File) {
    this.checkValid();
    if (event && event.name.substring(event.name.lastIndexOf('.'))?.toLowerCase() !== '.pdf') {
      this.infoType = 'warn';
      this.pdfFile = null;
    } else {
      this.pdfFile = event;
      this.checkValid();
    }
  }

  clearPdfFile() {
    this.pdfFile = null;
    this.checkValid();
  }

  onSelectXmlFile(event) {
    if (event && event.name.substring(event.name.lastIndexOf('.'))?.toLowerCase() !== '.xml') {
      this.infoType = 'warn';
      this.xmlFile = null;
    } else {
      this.xmlFile = event;
      this.checkValid();
    }
  }

  clearXmlFile() {
    this.xmlFile = null;
    this.checkValid();
  }

  checkValid() {
    if (!this.xmlFile || !this.pdfFile) {
      this.infoType = 'primary';
      this.valid.emit(false);
    }
    if (!!this.xmlFile && !!this.pdfFile) {
      this.infoType = 'success';
      this.valid.emit(true);
    }
  }

  requestXmlExtract() {
    this.processing.emit(true);
    this.extractTextService
      .extractDyXmlText(this.responseId, this.pdfFile, this.xmlFile, false)
      .pipe(finalize(() => this.processing.emit(false)))
      .subscribe((res) => {
        const xmlFileModel = new FileExtModel();
        xmlFileModel.file = this.xmlFile;
        xmlFileModel.fileModel = new FileModel();
        xmlFileModel.fileModel.docType = FileDocType.OTHER;
        const pdfFileModel = new FileExtModel();
        pdfFileModel.file = this.pdfFile;
        pdfFileModel.fileModel = new FileModel();
        pdfFileModel.fileModel.docType = FileDocType.PROPOSAL;
        this.extractedResponse.emit(new ExtractFileModel(res, [xmlFileModel, pdfFileModel], ExtractType.DY));
      });
  }
}
