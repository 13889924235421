<button
  #button
  mat-icon-button
  type="button"
  [attr.aria-haspopup]="picker ? 'dialog' : null"
  [attr.tabindex]="disabled ? -1 : tabIndex"
  [disabled]="disabled"
  (click)="open($event)"
>
  <span class="dot" [style.background-color]="'#' + picker?._selected"></span>
</button>
