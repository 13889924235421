import { Injectable } from '@angular/core';

import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { IntervalValues, monthlyOptions, weeklyOptions } from '@main/org/org-view/org-view.model';
import { of } from 'rxjs';

/**
 * This Async Option is dependent on a parent Async Option for 'recurrenceInterval'. Whenever the 'recurrenceInterval' select field is modified, that will
 * trigger this Async Option to fetch the options for the selected recurrenceInterval.
 */
@Injectable()
export class ReportTypeRecurrenceAsyncOptionGroupService extends AsyncOptions<string> {
  key = 'reportTypeRecurrenceAsyncOptionGroup';

  constructor() {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?) => {
    const interval = control.parent.get(asyncOptionDeps[0])?.value;
    const options = this.mapInterval(interval, value);
    return of({ content: options, total: options?.length });
  };

  private mapInterval(interval, value) {
    let options: LabelValue[] = [];
    if (IntervalValues.WEEKLY === interval) {
      options = !value
        ? weeklyOptions
        : weeklyOptions.filter((o) => o.label.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    } else if (IntervalValues.MONTHLY === interval) {
      options = !value
        ? monthlyOptions
        : monthlyOptions.filter((o) => o.label.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    }
    return options;
  }

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values) {
      return of([]);
    }

    const lower = values.map((v) => v.toLowerCase());
    return of(weeklyOptions.concat(monthlyOptions).filter((o) => lower.indexOf(o.value.toLowerCase()) >= 0));
  };

  generateLabelValues(items: any[]): LabelValue<string, string>[] {
    if (!items?.length) return [];
    return items.map((orgMember) => ({
      label: orgMember.orgName,
      value: orgMember.orgId
    }));
  }
}
