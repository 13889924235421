import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import { responseOptionsAggregateDef } from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import { DataDefModel } from '@lib-resource/data-def.model';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';

@Component({
  selector: 'app-quote-response-aggregate',
  templateUrl: './aggregate.component.html',
  styleUrls: ['../quote-response-stepper.component.scss']
})
export class AggregateComponent implements OnChanges, AfterViewInit {
  @Input() quoteResponse: QuoteResponseModel;
  @Input() ocrData: OcrModel[];
  @Input() importData;
  @Input() touched: boolean;
  @Input() readOnly: boolean;
  @Output() update = new EventEmitter<{
    quoteResponse: QuoteResponseModel;
    valid: boolean;
  }>();

  @Output() cloneOption = new EventEmitter<number>();

  @Output() removeOption = new EventEmitter<number>();

  defs: DataDefModel[] = [responseOptionsAggregateDef];
  optionsDataDef: DataDefModel = responseOptionsAggregateDef;

  @ViewChild('aggregateForm') aggregateForm: BaseFormComponent;

  localFormData: QuoteResponseModel;
  prevOptionCount: number;
  localUpdateOnly: boolean = false;
  stepContainer: HTMLElement;
  formGroupArrayHeight = '100%';

  constructor() {}

  ngAfterViewInit(): void {
    // look up the step container in the DOM to get its size, used to set the height of the table array component
    this.stepContainer = document.getElementById('step-container');
    this.setContainerHeight();
  }

  @HostListener('window:resize')
  onResize() {
    this.setContainerHeight();
  }

  ngOnChanges({ quoteResponse, importData, ocrData }: SimpleChanges): void {
    if (quoteResponse?.currentValue) {
      const currentOptionCount = quoteResponse.currentValue.quoteResponseOptions?.length || 0;

      if (!this.localFormData) {
        // first time in needs to update local form data which doesn't fire formChanges
        // so don't set local only flag that would cause this form to skip emitting a real update
        this.updateLocalForm(quoteResponse.currentValue, false);
      } else if (currentOptionCount !== this.prevOptionCount) {
        // the only thing this form cares about on the quote response from the outside is if the number of options changes (the add/clone/remove buttons)
        // if so, then we'll overwrite this local form data as the actual clone/remove logic has already been taken care of by the main stepper component
        // but this does cause a formChanges therefore mark it local only so it doesn't emit back out
        this.updateLocalForm(quoteResponse.currentValue, true);
      }
      this.prevOptionCount = currentOptionCount;
    } else if (importData || ocrData) {
      // if importData/ocrData comes in, it isn't setting new local form data (the import data sits separately in the app-form)
      // but will cause a formChanges event when applied so mark it local only to not emit it back out
      this.localUpdateOnly = true;
    }
  }

  formChanges(formData: QuoteResponseModel) {
    this.emitUpdate(formData);
  }

  getFormControl(key: string): any {
    return this.aggregateForm.form?.controls[key];
  }

  clone(def: DataDefModel, index: number) {
    this.cloneOption.emit(index);
  }

  remove(def: DataDefModel, index: number) {
    this.removeOption.emit(index);
  }

  private updateLocalForm(qr: QuoteResponseModel, localOnly: boolean) {
    this.localUpdateOnly = localOnly;
    this.localFormData = JSON.parse(JSON.stringify(qr));
  }

  private emitUpdate(quoteResponse: QuoteResponseModel) {
    if (!this.localUpdateOnly) {
      this.update.emit({
        quoteResponse: quoteResponse,
        valid: !this.aggregateForm.invalid
      });
    }
    this.localUpdateOnly = false;
  }

  private setContainerHeight(): void {
    if (this.stepContainer?.offsetHeight > 0) {
      this.formGroupArrayHeight = `${this.stepContainer.offsetHeight - 125}px`;
    }
  }
}
