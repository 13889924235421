import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonospaceDialogData } from '../dialogs.model';

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label *ngIf="data.label">{{ data.label }}</app-dialog-label>
    <app-dialog-content class="code-container">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-subtitle *ngIf="data.subtitle">{{ data.subtitle }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="pre" (click)="selectText($event)">{{ data.message | json }}</div>
        </mat-card-content>
      </mat-card>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Close</button>
    </app-dialog-actions>
  `,
  styleUrls: ['../dialogs.scss']
})
export class MonospaceMessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MonospaceDialogData) {}

  selectText(event: any): void {
    window.getSelection().selectAllChildren(event.target);
  }
}
