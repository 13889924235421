import { Pipe, PipeTransform } from '@angular/core';
import { SectionConfig } from '@form-lib/forms/section-config.model';

@Pipe({
  name: 'arraysToBottom'
})
export class ArrayToBottomPipe implements PipeTransform {
  transform(value: SectionConfig[]): SectionConfig[] {
    const arraySections = [];
    const nonArraySections = [];
    value.forEach((def) => {
      if (def.formArrayDef) {
        arraySections.push(def);
      } else {
        nonArraySections.push(def);
      }
    });
    return [...nonArraySections, ...arraySections];
  }
}
