import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileModel } from '@file-upload-lib/file.model';
import { faCircleDown, faTrashCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-file-list',
  template: `
    <mat-list>
      <h3 mat-subheader>{{ listTitle }}</h3>
      <mat-list-item *ngFor="let file of files">
        <h4 matListItemTitle>{{ file.name }}</h4>
        <p matListItemLine>{{ file.createdDate | date }}</p>
        <button matListItemMeta mat-icon-button *ngIf="withRemove" (click)="removeFile.emit(file)">
          <fa-icon [icon]="trashIcon" class="color-primary" size="lg"></fa-icon>
        </button>
        <button matListItemMeta mat-icon-button *ngIf="withDownload" (click)="downloadFile.emit(file)">
          <fa-icon [icon]="downloadIcon" class="color-primary" size="lg"></fa-icon>
        </button>
      </mat-list-item>

      <mat-list-item *ngIf="!files || !files.length">
        <mat-icon matListItemIcon>folder</mat-icon>
        <h4 matListItemTitle>No files to display.</h4>
      </mat-list-item>
    </mat-list>
  `
})
export class FileListComponent {
  @Input() files: FileModel[] = [];
  @Input() withRemove: boolean;
  @Input() withDownload: boolean;
  @Input() listTitle: string = 'Files';

  @Output() removeFile = new EventEmitter<FileModel>();
  @Output() downloadFile = new EventEmitter<FileModel>();

  downloadIcon = faCircleDown;
  trashIcon = faTrashCircle;
}
