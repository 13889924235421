import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { PreferredAccountModel } from '@common/models/preferred-accounts.model';
import { PreferredAccountsService } from '@quote/org-preferred-accounts/preferred-accounts.service';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';

@Injectable()
export class PreferredAccountOptionService extends AsyncOptions<PreferredAccountModel> {
  key = 'preferredAccounts';

  constructor(private preferredAccountsService: PreferredAccountsService, private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, filter, page, pagesize, sort, requiredQuery, asyncExtras) => {
    // if a different orgid needs to be used to lookup the preferred accounts (sys admin in administration quote-linq tab)
    // then get it will come through async extras.  If it's not there then use the selected org from the site filter
    const orgId = !!asyncExtras
      ? asyncExtras.credentialedOrgId
      : this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId);

    if (!!orgId) {
      return this.preferredAccountsService.getPreferredAccountsForOrg(orgId).pipe(
        map((data) => ({
          content: this.generateLabelValues(data),
          total: data.length
        }))
      );
    }

    // it is possible that the asyncExtras didn't have a value - when a new group array is added and an org hadn't been selected just
    // return an empty result
    return of({ content: [], total: 0 });
  };

  valuesFromKeys = (values: PreferredAccountModel[]) => {
    if (!values?.length) return of(null);
    return this.preferredAccountsService.getPreferredAccountsForOrg(values[0].orgId);
  };

  generateLabelValues(items: PreferredAccountModel[]): LabelValue<string, PreferredAccountModel>[] {
    if (!items?.length) return [];

    return items.map((preferredAccount) => ({
      label: preferredAccount.name,
      value: preferredAccount
    }));
  }
}
