<div matRipple class="wrapper mat-elevation-z2">
  <a
    [ngClass]="{ 'bg-selected': on, 'bg-white': !on, 'kpi-count-link-click': def.filter }"
    class="kpi-count-link grid-gap-2"
    (click)="toggle()"
  >
    <div [ngClass]="on ? 'bg-selected' : 'bg-white'" class="kpi-count-data-container">
      <div class="kpi-count-value text-end pb-3 text-no-overflow">{{ value | number }}</div>
      <div class="kpi-count-label text-end text-no-overflow">{{ def?.label1 }}</div>
      <div class="kpi-count-label text-end text-no-overflow">{{ def?.label2 }}</div>
    </div>
  </a>
</div>
