import { DELIMITER_MATCHER, getFormattedDateString, MONTH_DAY_YEAR_MATCHER, USER_TIMEZONE } from '@app/tools/date';
import { ActionDef, FilterModel, OperatorType } from '@data-table-lib/models/data-table.model';
import { DATA_TYPES, DataDefModel, DataTypes } from '@lib-resource/data-def.model';
import { getValue } from '@lib-resource/data.utils';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { escapeRmtQl } from '@app/tools/string';

export function parseToQuery(query: FilterModel): string {
  // a leaf
  if (query.type === DATA_TYPES.boolean) {
    return `${query.key} ${query.operator} ${query.value}`;
  }

  if (query.type === DATA_TYPES.date || query.type === DATA_TYPES.dateTime) {
    if (!!query.value?.toISOString || isNaN(query.value)) {
      return `${query.key} ${query.operator} date('${getFormattedDateString(
        query.value,
        'yyyy-MM-dd'
      )}', '${USER_TIMEZONE}')`;
    }
    const numberOfDays = query.value !== 0 ? (query.value > 0 ? ` +${query.value} days` : ` ${query.value} days`) : '';
    return `${query.key} ${query.operator} date('today${numberOfDays}', '${USER_TIMEZONE}')`;
  }

  if (
    query.type === DATA_TYPES.number ||
    query.type === DATA_TYPES.currency ||
    query.type === DATA_TYPES.percentage ||
    query.type === DATA_TYPES.fileSize
  ) {
    if (Array.isArray(query.value)) {
      return `${query.key} ${query.operator} (${query.value.join(',')})`;
    }
    return `${query.key} ${query.operator} '${query.value}'`;
  }

  if (
    query.type === DATA_TYPES.text ||
    query.type === DATA_TYPES.multiInput ||
    query.type === DATA_TYPES.enum ||
    query.type === DATA_TYPES.select
  ) {
    if (Array.isArray(query.value)) {
      return `${query.key} ${query.operator} (${query.value.map((v) => `'${escapeRmtQl(`${v}`)}'`).join(',')})`;
    }
    return `${query.key} ${query.operator} '${escapeRmtQl(query.value)}'`;
  }

  return `(unknown field type ${query.type})`;
}

// filters are bricks
export function buildFilterString(filters: FilterModel[]): string {
  return filters.map(parseToQuery).join(' and ');
}

export function filterTableData(filters: any[], searchText: string, searchTextFields: string[], data: any[]): any[] {
  function numericCheck(num: number, filter: any) {
    if (filter.value instanceof Array) {
      // make a copy and make sure they are all numbers
      // to handle any floating point oddness, round to two decimals
      const arrayVals = [...(filter.value as Array<any>)].map((val) => round(+val));
      // contains, not contains
      const roundNum = round(num);
      switch (filter.operator as string) {
        case '~':
          return arrayVals.filter((val) => val === roundNum).length > 0;
        case '!~':
          return arrayVals.filter((val) => val === roundNum).length === 0;
        default:
      }
    } else {
      // to handle any floating point oddness, round to two decimals
      const filterVal = round(+filter.value);
      const roundNum = round(num);
      switch (filter.operator as string) {
        case '=':
          return filterVal === roundNum;
        case '!=':
          return filterVal !== roundNum;
        case '>':
          return roundNum > filterVal;
        case '>=':
          return roundNum >= filterVal;
        case '<':
          return roundNum < filterVal;
        case '<=':
          return roundNum <= filterVal;
        default:
          return false;
      }
    }
  }

  function textCheck(text: string, filter: Partial<FilterModel>) {
    if (filter.value instanceof Array) {
      const arrayVals = filter.value;
      switch (filter.operator) {
        case '~':
          return arrayVals.filter((val) => val === text).length > 0;
        case '!~':
          return arrayVals.filter((val) => val === text).length === 0;
        default:
      }
    } else {
      const filterVal = `${filter.value}`;
      switch (filter.operator) {
        case '=':
          return filterVal === text;
        case '!=':
          return filterVal !== text;
        case '~':
          return text.toLowerCase().indexOf(filterVal.toLowerCase()) > -1;
        case '!~':
          return text.toLowerCase().indexOf(filterVal.toLowerCase()) === -1;
        default:
          return false;
      }
    }
  }

  function round(value: number): number {
    return Math.floor(value * 100) / 100;
  }

  // first filter bricks, then search text
  const filteredData =
    filters && filters.length > 0
      ? data.filter((row) => {
          const pred = filters.find((filter) => {
            let match: boolean = false;
            const val = getValue(row, filter.key);
            switch (filter.type as DataTypes) {
              case DATA_TYPES.number:
              case DATA_TYPES.currency:
              case DATA_TYPES.percentage:
                match = numericCheck(+val, filter);
                break;
              default:
                match = textCheck(`${val}`, filter);
                break;
            }
            return !match;
          });
          return pred === undefined; // all the filters matched, so retain
        })
      : data;
  // further filter based on the search text
  return searchText && searchTextFields && searchTextFields.length > 0
    ? filteredData.filter((row) =>
        // if any of them match the text, then include
        searchTextFields.some((searchField) => {
          const val = getValue(row, searchField);
          const filter = { value: searchText, operator: '~' as OperatorType };
          return val === undefined ? false : textCheck(`${val}`, filter);
        })
      )
    : filteredData;
}

const searchableTypes = {
  [DATA_TYPES.text]: true,
  [DATA_TYPES.number]: true,
  [DATA_TYPES.percentage]: true,
  [DATA_TYPES.currency]: true,
  [DATA_TYPES.date]: true,
  [DATA_TYPES.dateTime]: true,
  [DATA_TYPES.enum]: true,
  [DATA_TYPES.select]: true
};

export function buildDateFilterFromSearchText(searchText, field) {
  const formattedDate = getFormattedDateString(searchText, 'yyyy-MM-dd');
  return formattedDate ? `${field} = date('${formattedDate}', '${USER_TIMEZONE}')` : null;
}

export function buildEnumFilterFromSearchText(searchText: string, key: string, def: DataDefModel): string {
  const upperCaseSearchText = searchText.toLocaleUpperCase();
  const matchedOptions = def.options
    ?.filter(
      (option) =>
        option.label.toLocaleUpperCase() === upperCaseSearchText ||
        option.value.toLocaleUpperCase() === upperCaseSearchText
    )
    .map((option) => option.value);
  return matchedOptions?.length > 0 ? `${key} ~ ('${matchedOptions.join("','")}')` : null;
}

export function buildFilterFromSearchText(
  searchText: string,
  definitions: DataDefModel[],
  defaultSearchTextColumns?: Set<string>
): string {
  searchText = `${searchText}`; // Ensure numbers are converted to strings
  const dateMatch = searchText.match(MONTH_DAY_YEAR_MATCHER);
  let isNumber = !isNaN(+searchText);
  if (isNumber) {
    // also check to confirm the number is not too large.  In general, skip searching numbers that are > 2^31 or < -2^31, as they could fail in the server
    //   if they really need to search for a large number, the direct query will allow it.
    isNumber = +searchText >= -2147483647 && +searchText <= 2147483647; // this is max integer
  }
  const searchableDefinitions = definitions
    .filter((def) => !def.noQuery && (def.visible || defaultSearchTextColumns?.has(def.key)))
    .filter((def) => searchableTypes[def.type]);
  return searchableDefinitions
    .map((def) => {
      const key = def.queryKey ? def.queryKey : def.key;
      switch (def.type) {
        case DATA_TYPES.text:
          return `${key} ~ '${escapeRmtQl(searchText)}'`;

        case DATA_TYPES.enum:
        case DATA_TYPES.select:
          return buildEnumFilterFromSearchText(searchText, key, def);

        case DATA_TYPES.dateTime:
        case DATA_TYPES.date:
          if (!dateMatch?.length) return null;

          return buildDateFilterFromSearchText(searchText.replace(DELIMITER_MATCHER, '/'), key);

        case DATA_TYPES.currency:
          if (!isNumber) return null;
          return `${key} = ${+searchText}`;
        case DATA_TYPES.number:
        case DATA_TYPES.percentage:
          if (!isNumber) return null;
          // if a number/percent type field, there is currently no way to distinguish between floating point and whole number (no DATA_TYPES.integer/float),
          // just avoid sending floating point for number/percent because the query will fail at the server
          return Number.isInteger(+searchText) ? `${key} = ${+searchText}` : null;
        default:
          return null;
      }
    })
    .filter((stanza) => stanza !== null)
    .join(' or ');
}

export function buildQuery(
  filters: FilterModel[],
  searchText: string,
  requiredFilter: string,
  definitions: DataDefModel[],
  defaultColumnsSearch?: Set<string>
) {
  let filterString = '';
  if (filters && filters.length) {
    if (!Array.isArray(filters)) {
      filterString = filters;
    } else if (filters && filters.length > 0) {
      filterString = filters.map(parseToQuery).join(' and ');
    }
  }
  if (requiredFilter) {
    filterString = !!filterString ? `(${requiredFilter}) and (${filterString})` : requiredFilter;
  }
  if (searchText) {
    const searchQuery = buildFilterFromSearchText(searchText, definitions, defaultColumnsSearch);
    const shortCircuitClause = buildShortCircuitClause(definitions);
    if (filterString) {
      filterString = !!searchQuery
        ? `(${searchQuery}) and (${filterString})`
        : `${shortCircuitClause} and (${filterString})`;
    } else {
      filterString = !!searchQuery ? `${searchQuery}` : `${shortCircuitClause}`;
    }
  }
  return filterString;
}

function buildShortCircuitClause(definitions: DataDefModel[]) {
  const numericField = definitions.find((def) => def.type === 'number');
  // Default to 'id' field if no numeric fields
  return !!numericField ? `(${numericField.key} < 0 and ${numericField.key} > 0)` : `(id < 0 and id > 0)`;
}

export function buildCsvFromDefinitions<T>(defs: DataDefModel[], results: Array<T>[]): Observable<Blob> {
  // header record, skipping derived values
  const validDefs = defs.filter((def) => !def.calculatedValueFn);
  const headerRecord = validDefs.map((def) => escapeCsv(def.label)).join(',') + '\n';
  return fromPromise(
    convertCsv(headerRecord + results.map((res) => defs.map((def) => escapeCsv(res[def.key])).join(',')).join('\n'))
  );
}

function escapeCsv(val: any): string {
  const res = val === null || val === undefined ? '' : `${val}`;
  return res.length === 0 ? res : `"${res.replace('"', '""')}"`;
}

async function convertCsv(csvData: string): Promise<Blob> {
  const base64Response = await fetch(`data:text/plain;base64,${btoa(csvData)}`);
  const blob = await base64Response.blob();
  return blob;
}

export function deriveActionLabel(action: ActionDef, row: any, value: string): any {
  return action.derivedLabel ? action.derivedLabel(row) : value;
}
