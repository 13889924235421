import { Injectable } from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgModel } from '@main/org/models/org.model';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { LabelValue } from '@lib-resource/label-value.model';
import { RapidQuoteRxProfileModel } from '@common/models/rapid-quote-rx-profile.model';
import { RapidQuoteRxProfileService } from '@common/services/rapid-quote-rx-profile.service';

@Injectable({
  providedIn: 'root'
})
export class RapidQuoteRxProfileOptionService extends AsyncOptions<RapidQuoteRxProfileModel> {
  key = 'rapidQuoteRxProfiles';

  constructor(private rapidQuoteRxProfileService: RapidQuoteRxProfileService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) => {
    const orgId = asyncExtras.credentialedOrgId;
    if (!!orgId) {
      return this.rapidQuoteRxProfileService
        .findRapidQuoteRxProfiles(orgId)
        .pipe(map((profiles) => ({ content: this.generateLabelValues(profiles), total: profiles.length })));
    }

    return of({ content: [], total: 0 });
  };

  valuesFromKeys = (values: any[], multi?, asyncExtras?) => {
    const orgId = asyncExtras.credentialedOrgId;
    if (!orgId) return of(null);
    if (!values?.length) return of(null);
    return this.rapidQuoteRxProfileService
      .findRapidQuoteRxProfiles(orgId)
      .pipe(map((profiles) => this.generateLabelValues(profiles)));
  };

  generateLabelValues(content: RapidQuoteRxProfileModel[]): LabelValue<string, OrgModel>[] {
    if (!content?.length) return [];
    return content
      .sort((a, b) => a.id - b.id)
      .map((val) => ({
        label: val.profileName,
        value: val
      }));
  }
}
