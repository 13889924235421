import { AccountContactModel } from '@common/models/account-contact.model';
import { BenefitPlanModel } from '@common/models/benefit-plan.model';
import { LabelValue } from '@lib-resource/label-value.model';

export enum PolicyCoverageType {
  DENTAL = 'DENTAL',
  MEDICAL = 'MEDICAL',
  PRESCRIPTION = 'PRESCRIPTION',
  SAAO = 'SAAO',
  SHORT_TERM_DISABILITY = 'SHORT_TERM_DISABILITY',
  VISION = 'VISION'
}

export const PolicyCoverageTypeOptions: LabelValue[] = [
  {
    label: 'Medical',
    value: PolicyCoverageType.MEDICAL
  },
  {
    label: 'Prescription',
    value: PolicyCoverageType.PRESCRIPTION
  },
  {
    label: 'Dental',
    value: PolicyCoverageType.DENTAL
  },
  {
    label: 'Vision',
    value: PolicyCoverageType.VISION
  },
  {
    label: 'SAAO',
    value: PolicyCoverageType.SAAO
  },
  {
    label: 'Short Term Disability',
    value: PolicyCoverageType.SHORT_TERM_DISABILITY
  }
];

export class PolicyModel {
  orgId: number;
  orgName: string;
  id?: number;
  name?: string;
  effectiveDate?: string;
  accountId?: number;
  accountName?: string;
  primaryGroupNumber?: string;
  totalEEs?: number;
  underWriterId?: number;
  underWriterName?: string;
  underWriterOrgId?: number;
  underWriterOrgName?: string;
  eligibilityContact?: AccountContactModel;
  carrier?: string;
  specCoverages?: Array<PolicyCoverageType>;
  specIncurredMonths?: number;
  specPaidMonths?: number;
  specDeductible?: number;
  aggCoverages?: Array<PolicyCoverageType>;
  aggIncurredMonths?: number;
  aggPaidMonths?: number;
  aggAttachmentMonths?: number;
  aggDeductible?: number;
  minFilingAmount?: number;
  firstNotificationOfLossThreshold?: number;
  largeClaimThresholdAmount?: number;
  workStatusValidDays?: number;
  autoSendNotices?: boolean;
  paidAmount?: number;
  pendingAmount?: number;
  recoverableAmount?: number;
  receivableAmount?: number;
  ineligibleAmount?: number;
  active?: boolean;
  importGroupIds?: string[];
  aggSpecDeductible?: number;
  maxLifetimeReimbursement?: number;
  maxLifetimeReimbursementUnlimited?: boolean;
  specMaxAnnualReimbursement?: number;
  specMaxAnnualReimbursementUnlimited?: boolean;
  minAggDeductible?: number;
  aggMaxAnnualReimbursement?: number;
  advancedFundingContract?: boolean;
  aggAccommodationContract?: boolean;
  itemizedBillThreshold?: number;
  claimCopyThreshold?: number;
  domesticPercentReduction?: number;
  domesticProfessional?: boolean;
  domesticInstitution?: boolean;
  gaplessRenewal?: boolean;
  additionalNotes?: string;
  includesSpecific?: boolean;
  proofInsuranceRequired?: boolean;
  filingEndpointId?: number;
  noticeEndpointId?: number;
  includesAggregate?: boolean;
  aggSpecPercentMet?: number;
  aggEligibleDayOfMonth?: number;
  specExpirationDate?: string;
  specMaxFileDate?: string;
  aggExpirationDate?: string;
  aggMaxFileDate?: string;
  aggAttachPercentMet?: number;
  aggAttach?: number;
  totalAggClaimAmount?: number;
  totalAggAdjustments?: number;
  minAggAttachEnforce?: number;
  minAggAttach?: number;
  aggSurplusDeficit?: number;
  // Calculated to be displayed on the form
  specFinalFilingPeriod?: number;
  aggFinalFilingPeriod?: number;
}

export class PolicyStatsByMonthModel {
  yearMonth?: number;
  benefitPackage?: BenefitPlanModel;
  attachmentPoint?: number;
  attachmentPointToDate?: number;
  projectedAttachmentPoint?: number;
  tier1Count?: number;
  tier2Count?: number;
  tier3Count?: number;
  tier4Count?: number;
  tier5Count?: number;
  tier6Count?: number;
  tier7Count?: number;
  tier8Count?: number;
  employeeCount?: number;
  participantCount?: number;
  reimbursedAmount?: number;
  receivableAmount?: number;
  minAttachmentPoint?: number;
}

export enum HistEnrollmentDialogAction {
  ADD = 'ADD',
  COPY = 'COPY',
  EDIT = 'EDIT'
}
