import { FileModel } from '@file-upload-lib/file.model';

export enum SourceType {
  COVER_SHEET = 'COVER_SHEET',
  DISCLOSURE = 'DISCLOSURE',
  PARSED_DISCLOSURE = 'PARSED_DISCLOSURE',
  PARSED_LDD = 'PARSED_LDD',
  PARSED_PARTICIPANT = 'PARSED_PARTICIPANT',
  PARSED_SDL = 'PARSED_SDL',
  PARTICIPANT = 'PARTICIPANT',
  RMT_FILE = 'RMT_FILE'
}

export class ManifestEntryModel {
  constructor(public source: SourceType, public rmtFile: FileModel) {}
}
