import { Observable } from 'rxjs';
import { Page } from '@common/models/page.model';
import { RxDrugListType, RxDrugModel } from '@common/models/rx-drug.model';
import { SERVER_API_URL } from '@app/app.constants';
import { CsvImportModel } from '@common/models/csv-import.model';
import { ImportResultModel } from '@common/models/import-result.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlUtilService } from '@common/services/url-util.service';
import { SearchModel } from '@common/models/search.model';
import { map } from 'rxjs/operators';
import { DataFormatter } from '@lib-resource/data.formatter';
import { getValueByDef } from '@lib-resource/data.utils';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { rxDrugDefs } from '@common/components/rx-drug/rx-drug-list/rx-drug-list.definition';
import { BulkActionResultModel } from '@common/models/bulk-action.model';
import { HttpResourceService } from '@main/http-resource.service';

@Injectable({
  providedIn: 'root'
})
export class RxDrugService {
  constructor(
    protected http: HttpClient,
    private httpResource: HttpResourceService,
    private urlUtil: UrlUtilService,
    private fileDownloadService: FileDownloadService
  ) {}

  searchRxDrugForOrg(orgId: number, searchModel: SearchModel): Observable<Page<RxDrugModel>> {
    return this.searchRxDrug(`${SERVER_API_URL}/org/${orgId}/rx-drug`, searchModel);
  }

  uploadRxDrugForOrg(
    orgId: number,
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxDrug(`${SERVER_API_URL}/org/${orgId}/rx-drug/import`, listType, overwrite, file, csvModel);
  }

  searchRxDrugForRequest(quoteRequestId: number, searchModel: SearchModel): Observable<Page<RxDrugModel>> {
    return this.searchRxDrug(`${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-drug`, searchModel);
  }

  downloadRxDrugCsvForResponse(quoteResponseRxId: number, filterString: string, fileName: string): void {
    this.fileDownloadService.csvDownloadProgress(
      fileName,
      rxDrugDefs.map((def) => def.label),
      (page) =>
        this.searchRxDrugForResponse(quoteResponseRxId, new SearchModel(null, filterString, ['ndc'], 1000, page)).pipe(
          map((page) => ({
            total: page.total,
            page: page.page,
            pageSize: page.pageSize,
            content: page.content.map((c) =>
              rxDrugDefs.map((def) => DataFormatter.transform(getValueByDef(c, def), def))
            )
          }))
        )
    );
  }

  uploadRxDrugForRequest(
    quoteRequestId: number,
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxDrug(
      `${SERVER_API_URL}/quote-request-rx/${quoteRequestId}/rx-drug/import`,
      listType,
      overwrite,
      file,
      csvModel
    );
  }

  searchRxDrugForResponse(quoteResponseId: number, searchModel: SearchModel): Observable<Page<RxDrugModel>> {
    return this.searchRxDrug(`${SERVER_API_URL}/quote-response-rx/${quoteResponseId}/rx-drug`, searchModel);
  }

  uploadRxDrugForResponse(
    quoteResponseId: number,
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxDrug(
      `${SERVER_API_URL}/quote-response-rx/${quoteResponseId}/rx-drug/import`,
      listType,
      overwrite,
      file,
      csvModel
    );
  }

  searchRxDrugForContract(
    orgId: number,
    rxContractId: number,
    searchModel: SearchModel
  ): Observable<Page<RxDrugModel>> {
    return this.searchRxDrug(`${SERVER_API_URL}/org/${orgId}/rx-contract/${rxContractId}/rx-drug`, searchModel);
  }

  searchRxDrugForGlobalBiosimilar(searchModel: SearchModel): Observable<Page<RxDrugModel>> {
    return this.searchRxDrug(`${SERVER_API_URL}/biosimilar-rx-drug`, searchModel);
  }

  uploadRxDrugForGlobalBiosimilar(
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxDrug(`${SERVER_API_URL}/biosimilar-rx-drug/import`, listType, overwrite, file, csvModel);
  }

  uploadRxDrugForContract(
    orgId: number,
    rxContractId: number,
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    return this.uploadRxDrug(
      `${SERVER_API_URL}/org/${orgId}/rx-contract/${rxContractId}/rx-drug/import`,
      listType,
      overwrite,
      file,
      csvModel
    );
  }

  copyRxDrugForResponse(
    quoteResponseId: number,
    listType: RxDrugListType,
    overwrite: boolean
  ): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('overwrite', `${overwrite}`);
    formData.append('listType', `${listType}`);
    return this.http.post<ImportResultModel>(
      `${SERVER_API_URL}/quote-response-rx/${quoteResponseId}/rx-drug/org-copy`,
      formData
    );
  }

  importRxDrugForRxContract(
    quoteRequestRxId: number,
    rxContractId: number,
    listType: RxDrugListType
  ): Observable<ImportResultModel> {
    return this.http.put<ImportResultModel>(
      `${SERVER_API_URL}/quote-request-rx/${quoteRequestRxId}/rx-contract/${rxContractId}/import-drug?listType=${listType}`,
      null
    );
  }

  searchRxDrug(url: string, searchModel: SearchModel): Observable<Page<RxDrugModel>> {
    return this.http.get<Page<RxDrugModel>>(url, {
      params: this.urlUtil.buildSearchParams(
        searchModel.buildQueryString(),
        searchModel.pageIndex,
        searchModel.pageSize,
        searchModel.sort
      )
    });
  }

  uploadRxDrug(
    url: string,
    listType: RxDrugListType,
    overwrite: boolean,
    file: File,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('overwrite', `${overwrite}`);
    formData.append('listType', `${listType}`);

    if (csvModel != null) {
      formData.append('fieldmap', new Blob([JSON.stringify(csvModel)], { type: 'application/json' }));
    }
    return this.http.post<ImportResultModel>(url, formData);
  }

  bulkActionBiosimilar(action: string, ids: number[], query: string): Observable<BulkActionResultModel> {
    return this.bulkAction('biosimilar-rx-drug/bulk-action', action, ids, query);
  }

  bulkActionOrg(orgId: number, action: string, ids: number[], query: string): Observable<BulkActionResultModel> {
    return this.bulkAction(`org/${orgId}/rx-drug/bulk-action`, action, ids, query);
  }

  bulkActionQuoteRequestRx(
    quoteRequestRxId: number,
    action: string,
    ids: number[],
    query: string
  ): Observable<BulkActionResultModel> {
    return this.bulkAction(`quote-request-rx/${quoteRequestRxId}/rx-drug/bulk-action`, action, ids, query);
  }

  bulkActionQuoteResponseRx(
    quoteResponseRxId: number,
    action: string,
    ids: number[],
    query: string
  ): Observable<BulkActionResultModel> {
    return this.bulkAction(`quote-response-rx/${quoteResponseRxId}/rx-drug/bulk-action`, action, ids, query);
  }

  bulkActionRxContract(
    orgId: number,
    rxContractId: number,
    action: string,
    ids: number[],
    query: string
  ): Observable<BulkActionResultModel> {
    return this.bulkAction(`org/${orgId}/rx-contract/${rxContractId}/rx-drug/bulk-action`, action, ids, query);
  }

  bulkAction(uri, action: string, ids: number[], query: string): Observable<BulkActionResultModel> {
    return this.httpResource.putBulkActionQueryWithoutOrg({
      path: uri,
      query: query,
      ids: ids,
      action: action
    });
  }
}
