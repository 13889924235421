import { DataDefModel } from '@lib-resource/data-def.model';
import { AggResultModel } from '@common/models/agg.model';

class DataDefMin {
  key: string;
  calculatedValueFn?: (data, dataDef?: DataDefMin) => any;
}

export function getValue(data: any, key: string): any {
  return key.split('.').reduce((obj, field) => (obj ? obj[field] : null), data);
}

export function getValueByDef(data: any, dataDef: DataDefMin): any {
  if (dataDef.calculatedValueFn) {
    return dataDef.calculatedValueFn(data, dataDef);
  }
  return getValue(data, dataDef.key);
}

export function setValue(dataObj: any, key: string, value: any, sep = '.') {
  const keyParts = key.split(sep);
  keyParts.reduce((prev, current, currentIndex) => {
    if (currentIndex === keyParts.length - 1) {
      // the last key, apply the value
      prev[current] = value;
    } else {
      // else the key is an object, if undefined/null then create a literal object
      if (prev[current] === undefined || prev[current] === null) {
        prev[current] = {};
      }
    }
    return prev[current];
  }, dataObj);
}

export function mapValueToLabel(dataDef: DataDefModel, value: any): any {
  if (!dataDef.options || !dataDef.options.length) return value;
  if (value instanceof Array) {
    return value
      .map((dataItem) => {
        const mapped = dataDef.options.find((option) => option.value === dataItem);
        return !mapped ? dataItem : mapped.label;
      })
      .join(', ');
  }

  const match = dataDef.options.find((option) => option.value === value);
  return !match ? value : match.label;
}

export function unflattenData(data: any, sep = '.'): any {
  let dataObj = {};

  const dataKeys = Object.keys(data);
  dataKeys.forEach((key) => {
    setValue(dataObj, key, data[key], sep);
  });

  return dataObj;
}

export function replaceInvalidKeyChars(key: string): string {
  // replace any dots with forward slashes, Angular Forms doesn't like periods
  return key ? key.replace(/\./g, '/') : key;
}

export const QUOTEREQUEST_QUOTERESPONSE_LOCAL_DATE_FIELDS: string[] = [
  'effectiveDate',
  'expirationDate',
  'proposalDate',
  'validThroughDate'
];

export function cleanLocalDates(
  data: any,
  localDateKeys: string[] = QUOTEREQUEST_QUOTERESPONSE_LOCAL_DATE_FIELDS
): void {
  localDateKeys.forEach((localDateKey) => {
    const value = getValue(data, localDateKey);
    if (value instanceof Date) {
      setValue(
        data,
        localDateKey,
        `${value.getFullYear()}-${('0' + (value.getMonth() + 1)).slice(-2)}-${('0' + value.getDate()).slice(-2)}`
      );
    }
  });
}

export function findFirstDuplicateItem(items: Array<any>): any {
  if (!items) {
    return null;
  }
  // check for duplicate records, using an associative array to do one pass of the data
  const found = [];
  for (let idx = 0; idx < items.length; idx++) {
    const val = JSON.stringify(items[idx]); // for object equality use the string form, it will not match properties out of order
    if (!found[val]) {
      found[val] = true;
    } else {
      return items[idx];
    }
  }
  return null; // duplicates not found
}

export function getAggValue(aggs: AggResultModel[], name: string): number {
  const val = aggs.find((value) => value.name === name);
  return val ? val.value : 0;
}
