import { Directive, ElementRef, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { StatusType } from '@shared/directives/status-indicator/status-indicator.model';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseStatusIndicatorDirective implements OnChanges, OnDestroy {
  @Input() hasError: boolean;
  @Input() hasSuccess: boolean;
  @Input() control: UntypedFormControl;
  @Input() status: StatusType;
  protected abstract _errorClass: string;
  protected abstract _successClass: string;
  private _sub: Subscription;
  private _previousClass: string;

  constructor(protected _el: ElementRef, protected _renderer: Renderer2) {}

  ngOnChanges({ status, control, hasError, hasSuccess }: SimpleChanges) {
    if (control && this.control) {
      this._sub?.unsubscribe(); // destroy sub if one exists
      this._sub = this.control.statusChanges.subscribe((ctrlStatus) => {
        if (ctrlStatus === 'INVALID') {
          this._setStatus(StatusType.error);
        } else if (ctrlStatus === 'VALID') {
          this._setStatus(StatusType.success);
        }
      });
    } else if (hasError) {
      this._setStatus(this.hasError === true ? StatusType.error : null);
    } else if (hasSuccess) {
      this._setStatus(this.hasSuccess === true ? StatusType.success : null);
    } else if (status) {
      this._applyStatus();
    }
  }

  ngOnDestroy() {
    this._sub?.unsubscribe();
  }

  private _getStatusClasses(): string {
    if (this.status === StatusType.error) {
      return this._errorClass;
    }
    if (this.status === StatusType.success) {
      return this._successClass;
    }
  }

  private _applyStatus() {
    if (this._previousClass) {
      this._renderer.removeClass(this._el.nativeElement, this._previousClass);
    }
    this._previousClass = this._getStatusClasses();
    if (this._previousClass) {
      this._renderer.addClass(this._el.nativeElement, this._previousClass);
    }
  }

  private _setStatus(status: StatusType) {
    if (status === this.status) return;
    this.status = status;
    this._applyStatus();
  }
}
