import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { TagService } from '@configuration/admin/tag-list/tag.service';
import { FormDialogService } from '@form-lib/services/form-dialog.service';
import { faTags } from '@fortawesome/pro-solid-svg-icons';
import { isEqual } from 'lodash-es';
import { switchMap, take } from 'rxjs/operators';
import { DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-display-card-tag',
  templateUrl: './display-card-tag.component.html',
  styleUrls: ['./display-card-tag.component.scss']
})
export class DisplayCardTagComponent {
  @Input() tags: TagModel[];
  @Input() orgId: number;
  @Input() filterString: string;
  @Input() disableEdit: boolean;
  @Input() displayTagIcon: boolean = false;
  @Output() updateTags = new EventEmitter<TagModel[]>();

  tagsIcon = faTags;

  constructor(private formDialogService: FormDialogService, private tagService: TagService) {}

  openTagSelectorDialog() {
    if (!!this.orgId) {
      this.tagService
        .searchWithOrg(this.orgId, this.filterString, 0, 1000, [])
        .pipe(
          take(1),
          switchMap((page) =>
            this.formDialogService
              .open({
                label: 'Apply Tags',
                actionText: 'Apply',
                data: {
                  tags: this.tags
                },
                definitions: [
                  new DataDefModel({
                    key: 'tags',
                    label: 'Tags',
                    type: 'multiSelect',
                    options: page.content.map((tag) => ({ label: tag.name, value: tag }))
                  })
                ]
              })
              .afterClosed()
          )
        )
        .subscribe((results) => {
          if (!results) return;
          if (isEqual(this.tags, results.tags)) return;
          this.updateTags.emit(results.tags);
        });
    }
  }
}
