import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { UnderWriterModel } from '@smart/component/policy/model/under-writer.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnderWriterDataService {
  constructor(protected http: HttpClient, private urlUtil: UrlUtilService) {}

  getBaseUrl(): string {
    return `${SERVER_API_URL}`;
  }

  search(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<UnderWriterModel>> {
    return this.http.get(`${this.getBaseUrl()}/underwriter`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  create(underWriter: UnderWriterModel): Observable<UnderWriterModel> {
    return this.http.post<UnderWriterModel>(`${this.getBaseUrl()}/underwriter`, underWriter);
  }

  update(underWriter: UnderWriterModel): Observable<UnderWriterModel> {
    return this.http.put<UnderWriterModel>(`${this.getBaseUrl()}/underwriter/${underWriter.id}`, underWriter);
  }

  delete(underWriter: UnderWriterModel): Observable<UnderWriterModel> {
    return this.http.delete<UnderWriterModel>(`${this.getBaseUrl()}/underwriter/${underWriter.id}`);
  }
}
