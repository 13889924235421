import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { DerivedIcon } from '@data-table-lib/models/data-table.model';
import { FormSection, LabelOnlyClasses } from '@form-lib/models/form.model';
import { YES_NO_OPTIONS } from '@form-lib/predefined/generic.options';
import { faAward } from '@fortawesome/pro-solid-svg-icons';
import {
  AdditionalFeeModel,
  FeeRecurrence,
  LaserModel,
  LaserRelationship,
  quoteOptionStatusOptions,
  QuoteResponseLaserConfirmationType,
  QuoteResponseModel,
  QuoteResponseOption,
  QuoteResponseOptionTermType,
  QuoteResponseStatus,
  QuoteResponseType,
  RATE_TYPE_OPTIONS,
  RateType
} from '@common/models/quote-response.model';
import { PolicyCoverageType } from '@smart/component/policy/model/policy.model';
import { CurrencyPipe } from '@angular/common';
import { DisableChildIf } from '@form-lib/validators/form.disablers';
import { FundingTypeLabel } from '@common/models/quote-request.model';
import { FundingTypeModel } from '@common/models/funding-type.model';
import { nullOrUndefinedToZero } from '@app/tools/number';

export const quoteResponseSections: FormSection[] = [
  {
    key: 'general',
    label: 'General',
    fullWidth: true
  },
  {
    key: 'requestedEnrollment',
    label: 'Requested Enrollment'
  },
  {
    key: 'quotedEnrollment',
    label: 'Quoted Enrollment'
  },
  {
    key: 'options',
    label: 'Options'
  }
];

export const optionStatusDef: DataDefModel = new DataDefModel({
  key: 'status',
  type: DATA_TYPES.select,
  options: quoteOptionStatusOptions,
  readOnly: true,
  hiddenRow: true
});

export const QuoteResponseTypeSelectOptions: DataDefOption[] = [
  {
    label: 'Final',
    value: QuoteResponseType.FINAL
  },
  {
    label: 'Firm',
    value: QuoteResponseType.FIRM
  },
  {
    label: 'Illustrative',
    value: QuoteResponseType.ILLUSTRATIVE
  }
];

export const QuoteResponseLaserConfirmationOptions: DataDefOption[] = [
  {
    label: 'Yes (Lasers included)',
    value: QuoteResponseLaserConfirmationType.CONFIRMED_LASERS_INCLUDED
  },
  {
    label: 'No (Lasers not included)',
    value: QuoteResponseLaserConfirmationType.CONFIRMED_LASERS_NOT_INCLUDED
  },
  {
    label: 'Not confirmed',
    value: QuoteResponseLaserConfirmationType.UNCONFIRMED
  }
];

export const specLabelClasses = 'bg-rmt-dark-tint-1 color-white';
export const aggLabelClasses = 'bg-rmt-violet color-white';
export const costSummaryLabelClasses = 'bg-rmt-dark color-white';
const awardedDerivedIcon: DerivedIcon[] = [
  new DerivedIcon(faAward, ['font-size-4', 'pt-1', 'color-status-green-dark'])
];

const responseOptionsBaseDef: DataDefModel = new DataDefModel({
  key: 'quoteResponseOptions',
  type: DATA_TYPES.formGroupArray,
  label: 'Options',
  sectionKey: 'options',
  notOrderable: true,
  itemPrefix: 'Option',
  formGroupArrayInitModel: () => ({ status: QuoteResponseStatus.NEW }),
  definitions: [
    new DataDefModel({
      key: 'id',
      type: DATA_TYPES.text,
      label: 'Id',
      readOnly: true,
      hiddenRow: true
    }),
    new DataDefModel({
      key: 'createdDate',
      type: DATA_TYPES.date,
      label: 'Created Date',
      readOnly: true,
      hiddenRow: true
    }),
    optionStatusDef,
    new DataDefModel({
      key: 'icon',
      type: DATA_TYPES.spacer,
      derivedIcon: (row) => {
        if (row.status === QuoteResponseStatus.WON || row.status === QuoteResponseStatus.BOUND) {
          return awardedDerivedIcon;
        }
        return null;
      }
    })
  ]
});

export const fundingTypeDef: DataDefModel = new DataDefModel({
  key: 'fundingType',
  type: DATA_TYPES.select,
  label: 'Funding Type',
  options: [
    {
      label: FundingTypeLabel[FundingTypeModel.SELF_FUNDED],
      value: FundingTypeModel.SELF_FUNDED
    },
    {
      label: FundingTypeLabel[FundingTypeModel.LEVEL_FUNDED],
      value: FundingTypeModel.LEVEL_FUNDED
    }
  ],
  readOnly: true
});

export const benefitPlanDef: DataDefModel = new DataDefModel({
  key: 'policyBenefitPlans',
  label: 'Benefit Plan(s)',
  type: DATA_TYPES.multiInput
});

export const policyAdministratorDef: DataDefModel = new DataDefModel({
  key: 'policyAdministrator',
  label: 'Administrator',
  type: DATA_TYPES.text,
  validators: {
    maxLength: 100
  }
});

export const policyPbmDef: DataDefModel = new DataDefModel({
  key: 'policyPBM',
  label: 'PBM',
  type: DATA_TYPES.text
});

export const policyNetworkDef: DataDefModel = new DataDefModel({
  key: 'policyNetwork',
  label: 'Network',
  type: DATA_TYPES.text
});

export const policyTransplantExclusionDef: DataDefModel = new DataDefModel({
  key: 'specTransplantExclusion',
  label: 'Transplant Exclusion',
  type: DATA_TYPES.select,
  options: YES_NO_OPTIONS
});

export const policyTransplantVendorDef: DataDefModel = new DataDefModel({
  key: 'policyTransplantVendor',
  label: 'Transplant Vendor',
  type: DATA_TYPES.text
});

export const policyGaplessRenewalDef: DataDefModel = new DataDefModel({
  key: 'specGaplessOption',
  label: 'Gapless Renewal',
  type: DATA_TYPES.select,
  options: YES_NO_OPTIONS
});

export const policyPlanMirroringDef: DataDefModel = new DataDefModel({
  key: 'specPlanMirroringOption',
  label: 'Plan Mirroring',
  type: DATA_TYPES.select,
  options: YES_NO_OPTIONS
});

export const policyActivelyAtWorkWaivedDef: DataDefModel = new DataDefModel({
  key: 'specActiveAtWorkWaived',
  label: 'Actively-at-Work Waived',
  type: DATA_TYPES.select,
  options: YES_NO_OPTIONS
});

export const policyRetireesCoveredDef: DataDefModel = new DataDefModel({
  key: 'specRetireesCovered',
  label: 'Retirees Covered',
  type: DATA_TYPES.select,
  options: YES_NO_OPTIONS
});

export const policyDef: DataDefModel[] = [
  new DataDefModel({
    key: 'policySectionHeader',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.heading + ' header-smaller-font',
    label: 'Policy Administration'
  }),
  fundingTypeDef,
  benefitPlanDef,
  policyAdministratorDef,
  policyPbmDef,
  policyNetworkDef,
  policyTransplantExclusionDef,
  policyTransplantVendorDef,
  new DataDefModel({
    key: 'formGroupSectionPolicyOther',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Other Policy Terms'
  }),
  policyGaplessRenewalDef,
  policyPlanMirroringDef,
  policyActivelyAtWorkWaivedDef,
  policyRetireesCoveredDef
];

export const specCoverageDef: DataDefModel = new DataDefModel({
  key: 'specCoverage',
  type: DATA_TYPES.multiSelect,
  label: 'Benefits Covered',
  options: [
    {
      label: 'Medical',
      value: PolicyCoverageType.MEDICAL
    },
    {
      label: 'Rx Card',
      value: PolicyCoverageType.PRESCRIPTION
    },
    {
      label: 'Rx SAAO',
      value: PolicyCoverageType.SAAO
    }
  ],
  labelClasses: specLabelClasses
});

export const specContractTypeDef: DataDefModel = new DataDefModel({
  key: 'specContractType',
  type: DATA_TYPES.text,
  label: 'Contract Type',
  labelClasses: specLabelClasses
});

export const specDeductibleDef: DataDefModel = new DataDefModel({
  key: 'specDeductible',
  type: DATA_TYPES.currency,
  label: 'Spec Deductible',
  labelClasses: specLabelClasses
});

export const specFamilyDeductibleDef: DataDefModel = new DataDefModel({
  key: 'specFamilyDeductible',
  type: DATA_TYPES.select,
  label: 'Deductible Accumulation',
  options: [
    {
      label: 'Per Individual',
      value: false
    },
    {
      label: 'Per Family',
      value: true
    }
  ],
  labelClasses: specLabelClasses
});

export const specMonthlyPremiumAmountDef = new DataDefModel({
  key: 'specMonthlyPremiumAmount',
  type: DATA_TYPES.currency,
  label: 'Est. Monthly Spec Premium',
  labelClasses: specLabelClasses
});

export const specPremiumAmountDef: DataDefModel = new DataDefModel({
  key: 'premiumAmount',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Spec Premium',
  labelClasses: specLabelClasses
});

export const specAggDeductibleDef: DataDefModel = new DataDefModel({
  key: 'aggSpec',
  type: DATA_TYPES.currency,
  label: 'Agg Spec Deductible',
  labelClasses: specLabelClasses
});

export const specAnnualMaximumUnlimitedDef: DataDefModel = new DataDefModel({
  key: 'specAnnualMaximumUnlimited',
  type: DATA_TYPES.select,
  label: 'Annual Maximum Unlimited',
  options: [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ],
  labelClasses: specLabelClasses
});

export const specAnnualMaximumDef: DataDefModel = new DataDefModel({
  key: 'specAnnualMaximum',
  type: DATA_TYPES.currency,
  label: 'Annual Maximum Amount',
  labelClasses: specLabelClasses
});

export const specLifetimeMaximumUnlimitedDef: DataDefModel = new DataDefModel({
  key: 'specLifetimeMaximumUnlimited',
  type: DATA_TYPES.select,
  label: 'Lifetime Maximum Unlimited',
  options: [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ],
  labelClasses: specLabelClasses
});

export const specLifetimeMaximumDef: DataDefModel = new DataDefModel({
  key: 'specLifetimeMaximum',
  type: DATA_TYPES.currency,
  label: 'Lifetime Maximum Amount',
  labelClasses: specLabelClasses
});

export const specReimbPercentDef: DataDefModel = new DataDefModel({
  key: 'specReimbPercent',
  type: DATA_TYPES.percentage,
  label: 'Reimbursement (%)',
  labelClasses: specLabelClasses
});

export const specDomReimbPercentDef: DataDefModel = new DataDefModel({
  key: 'specDomReimbPercent',
  type: DATA_TYPES.percentage,
  label: 'Domestic Reimbursement (%)',
  labelClasses: specLabelClasses
});

export const specRunInLimitDef: DataDefModel = new DataDefModel({
  key: 'specRunInLimit',
  type: DATA_TYPES.currency,
  label: 'Spec Run In Limit',
  labelClasses: specLabelClasses
});

export const specAdvancedReimbursementDef: DataDefModel = new DataDefModel({
  key: 'specAdvancedReimbursement',
  type: DATA_TYPES.select,
  label: 'Advanced Funding Option',
  options: YES_NO_OPTIONS,
  labelClasses: specLabelClasses
});

export const specNoNewLaserOptionDef: DataDefModel = new DataDefModel({
  key: 'specNoNewLaserOption',
  type: DATA_TYPES.select,
  label: 'No New Lasers Option',
  options: YES_NO_OPTIONS,
  labelClasses: specLabelClasses
});

export const specRenewalRateCapOptionDef: DataDefModel = new DataDefModel({
  key: 'specRenewalRateCapOption',
  type: DATA_TYPES.select,
  label: 'Rate Cap Option',
  options: YES_NO_OPTIONS,
  labelClasses: specLabelClasses
});

export const specRenewalRateCapDef: DataDefModel = new DataDefModel({
  key: 'specRenewalRateCap',
  type: DATA_TYPES.percentage,
  label: 'Rate Cap (%)',
  labelClasses: specLabelClasses
});

export const specTermLiabilityCoverageDef: DataDefModel = new DataDefModel({
  key: 'specTermLiabilityCoverage',
  type: DATA_TYPES.select,
  label: 'Term Liability Option (TLO)',
  options: YES_NO_OPTIONS,
  labelClasses: specLabelClasses
});

export const specTermLiabilityTermDef: DataDefModel = new DataDefModel({
  key: 'specTermLiabilityTerm',
  type: DATA_TYPES.select,
  label: 'TLO Timeframe',
  options: [
    { label: '3 Month', value: QuoteResponseOptionTermType.MONTH_3 },
    { label: '6 Month', value: QuoteResponseOptionTermType.MONTH_6 },
    { label: 'None', value: QuoteResponseOptionTermType.NONE }
  ],
  labelClasses: specLabelClasses
});

export const specCommissionIncludedDef: DataDefModel = new DataDefModel({
  key: 'specCommission',
  type: DATA_TYPES.percentage,
  label: 'Commission Included (%)',
  labelClasses: specLabelClasses
});

export const specRateScheduleDef: DataDefModel = new DataDefModel({
  key: 'specRateSchedule',
  type: DATA_TYPES.select,
  label: 'Rate Schedule',
  options: RATE_TYPE_OPTIONS,
  labelClasses: specLabelClasses
});

export const empOnlyRateDef: DataDefModel = new DataDefModel({
  key: 'empOnlyRate',
  type: DATA_TYPES.currency,
  label: 'EE Only (Single)',
  labelClasses: specLabelClasses
});

export const empSpouseRateDef: DataDefModel = new DataDefModel({
  key: 'empSpouseRate',
  type: DATA_TYPES.currency,
  label: 'EE + Spouse',
  labelClasses: specLabelClasses
});

export const empChildRateDef: DataDefModel = new DataDefModel({
  key: 'empChildRate',
  type: DATA_TYPES.currency,
  label: 'EE + Child',
  labelClasses: specLabelClasses
});

export const empDepRateDef: DataDefModel = new DataDefModel({
  key: 'empDepRate',
  type: DATA_TYPES.currency,
  label: 'EE + Dependent',
  labelClasses: specLabelClasses
});

export const familyRateDef: DataDefModel = new DataDefModel({
  key: 'familyRate',
  type: DATA_TYPES.currency,
  label: 'Family',
  labelClasses: specLabelClasses
});

export const compositeRateDef: DataDefModel = new DataDefModel({
  key: 'compositeRate',
  type: DATA_TYPES.currency,
  label: 'Composite',
  labelClasses: specLabelClasses
});

export const specTerminalLiabilityRateDef: DataDefModel = new DataDefModel({
  key: 'specTerminalLiabilityRate',
  type: DATA_TYPES.currency,
  label: 'TLO Rate',
  labelClasses: specLabelClasses
});

export const specificDef: DataDefModel[] = [
  new DataDefModel({
    key: 'specSectionHeader',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.heading,
    label: 'Specific Terms'
  }),
  specCoverageDef,
  specContractTypeDef,
  specDeductibleDef,
  specFamilyDeductibleDef,
  specAggDeductibleDef,
  specAnnualMaximumUnlimitedDef,
  specAnnualMaximumDef,
  specLifetimeMaximumUnlimitedDef,
  specLifetimeMaximumDef,
  specReimbPercentDef,
  specDomReimbPercentDef,
  specRunInLimitDef,
  new DataDefModel({
    key: 'formGroupSectionSpecOther',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Specific Options'
  }),
  specAdvancedReimbursementDef,
  specNoNewLaserOptionDef,
  specRenewalRateCapOptionDef,
  specRenewalRateCapDef,
  specTermLiabilityCoverageDef,
  specTermLiabilityTermDef,
  new DataDefModel({
    key: 'specSubSectionHeader',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Specific Rates (PEPM)'
  }),
  specCommissionIncludedDef,
  specRateScheduleDef,
  empOnlyRateDef,
  empSpouseRateDef,
  empChildRateDef,
  empDepRateDef,
  familyRateDef,
  compositeRateDef,
  specTerminalLiabilityRateDef,
  specMonthlyPremiumAmountDef,
  specPremiumAmountDef
];

export const aggCoverageDef: DataDefModel = new DataDefModel({
  key: 'aggCoverage',
  type: DATA_TYPES.multiSelect,
  label: 'Benefits Covered',
  options: [
    {
      label: 'Medical',
      value: PolicyCoverageType.MEDICAL
    },
    {
      label: 'Rx Card',
      value: PolicyCoverageType.PRESCRIPTION
    },
    {
      label: 'Dental',
      value: PolicyCoverageType.DENTAL
    },
    {
      label: 'Vision',
      value: PolicyCoverageType.VISION
    },
    {
      label: 'Rx SAAO',
      value: PolicyCoverageType.SAAO
    },
    {
      label: 'Short Term Disability',
      value: PolicyCoverageType.SHORT_TERM_DISABILITY
    }
  ],
  labelClasses: aggLabelClasses
});

export const aggContractTypeDef: DataDefModel = new DataDefModel({
  key: 'aggContractType',
  type: DATA_TYPES.text,
  label: 'Contract Type',
  labelClasses: aggLabelClasses
});

export const aggLossLimitPerIndividualDef: DataDefModel = new DataDefModel({
  key: 'aggLossLimitPerIndividual',
  type: DATA_TYPES.currency,
  label: 'Loss Limit Per Individual',
  labelClasses: aggLabelClasses
});

export const aggMaxReimbursementDef: DataDefModel = new DataDefModel({
  key: 'aggMaximum',
  type: DATA_TYPES.currency,
  label: 'Agg Maximum',
  labelClasses: aggLabelClasses
});

export const aggReimbPercentDef: DataDefModel = new DataDefModel({
  key: 'aggReimbPercent',
  type: DATA_TYPES.percentage,
  label: 'Reimbursement (%)',
  labelClasses: aggLabelClasses
});

export const runInRunOutLimitDef: DataDefModel = new DataDefModel({
  key: 'runInRunOutLimit',
  type: DATA_TYPES.currency,
  label: 'Agg Run In Limit',
  labelClasses: aggLabelClasses
});

export const riskCorridorDef: DataDefModel = new DataDefModel({
  key: 'riskCorridor',
  type: DATA_TYPES.number,
  label: 'Agg Corridor (%)',
  labelClasses: aggLabelClasses
});

export const aggDeductibleDef: DataDefModel = new DataDefModel({
  key: 'aggDeductible',
  type: DATA_TYPES.currency,
  label: 'Agg Deductible',
  labelClasses: aggLabelClasses
});

export const minAggDeductibleDef: DataDefModel = new DataDefModel({
  key: 'minAggDeductible',
  type: DATA_TYPES.currency,
  label: 'Min Agg Deductible',
  labelClasses: aggLabelClasses
});

export const aggAccommodationDef: DataDefModel = new DataDefModel({
  key: 'aggAccommodation',
  type: DATA_TYPES.select,
  label: 'Agg Accommodation Option',
  options: YES_NO_OPTIONS,
  labelClasses: aggLabelClasses
});

export const aggTermLiabilityCoverageDef: DataDefModel = new DataDefModel({
  key: 'aggTermLiabilityCoverage',
  type: DATA_TYPES.select,
  label: 'Term Liability Option (TLO)',
  options: YES_NO_OPTIONS,
  labelClasses: aggLabelClasses
});

export const aggTermLiabilityTermDef: DataDefModel = new DataDefModel({
  key: 'aggTermLiabilityTerm',
  type: DATA_TYPES.select,
  label: 'TLO Timeframe',
  options: [
    { label: '3 Month', value: QuoteResponseOptionTermType.MONTH_3 },
    { label: '6 Month', value: QuoteResponseOptionTermType.MONTH_6 },
    { label: 'None', value: QuoteResponseOptionTermType.NONE }
  ],
  labelClasses: aggLabelClasses
});

export const aggEstAnnualPremiumDef: DataDefModel = new DataDefModel({
  key: 'aggEstAnnualPremium',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Agg Premium',
  labelClasses: aggLabelClasses
});

export const aggCommissionDef: DataDefModel = new DataDefModel({
  key: 'aggCommission',
  type: DATA_TYPES.percentage,
  label: 'Commission Included (%)',
  labelClasses: aggLabelClasses
});

export const aggRateScheduleDef: DataDefModel = new DataDefModel({
  key: 'aggRateSchedule',
  type: DATA_TYPES.select,
  label: 'Rate Schedule',
  options: RATE_TYPE_OPTIONS,
  labelClasses: aggLabelClasses
});

export const aggEmpOnlyRateDef: DataDefModel = new DataDefModel({
  key: 'aggEmpOnlyRate',
  type: DATA_TYPES.currency,
  label: 'EE Only (Single)',
  labelClasses: aggLabelClasses
});

export const aggEmpSpouseRateDef: DataDefModel = new DataDefModel({
  key: 'aggEmpSpouseRate',
  type: DATA_TYPES.currency,
  label: 'EE + Spouse',
  labelClasses: aggLabelClasses
});

export const aggEmpChildRateDef: DataDefModel = new DataDefModel({
  key: 'aggEmpChildRate',
  type: DATA_TYPES.currency,
  label: 'EE + Child',
  labelClasses: aggLabelClasses
});

export const aggEmpDepRateDef: DataDefModel = new DataDefModel({
  key: 'aggEmpDepRate',
  type: DATA_TYPES.currency,
  label: 'EE + Dependent',
  labelClasses: aggLabelClasses
});

export const aggFamilyRateDef: DataDefModel = new DataDefModel({
  key: 'aggFamilyRate',
  type: DATA_TYPES.currency,
  label: 'Family',
  labelClasses: aggLabelClasses
});

export const aggCompositeRateDef: DataDefModel = new DataDefModel({
  key: 'aggCompositeRate',
  type: DATA_TYPES.currency,
  label: 'Composite',
  labelClasses: aggLabelClasses
});

export const monthlyAccRateDef: DataDefModel = new DataDefModel({
  key: 'monthlyAccRate',
  type: DATA_TYPES.currency,
  label: 'Agg Accommodation Rate',
  labelClasses: aggLabelClasses
});

export const terminalLiabilityRateDef: DataDefModel = new DataDefModel({
  key: 'terminalLiabilityRate',
  type: DATA_TYPES.currency,
  label: 'TLO Rate',
  labelClasses: aggLabelClasses
});

export const aggMonthlyPremiumDef: DataDefModel = new DataDefModel({
  key: 'aggMonthlyPremium',
  type: DATA_TYPES.currency,
  label: 'Est. Monthly Agg Premium',
  labelClasses: aggLabelClasses
});

export const aggFactorScheduleDef: DataDefModel = new DataDefModel({
  key: 'aggFactorSchedule',
  type: DATA_TYPES.select,
  label: 'Factor Schedule',
  options: RATE_TYPE_OPTIONS,
  labelClasses: aggLabelClasses
});

export const empOnlyMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'empOnlyMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'EE Only (Single)',
  labelClasses: aggLabelClasses
});

export const empSpouseMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'empSpouseMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'EE + Spouse',
  labelClasses: aggLabelClasses
});

export const empChildMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'empChildMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'EE + Child',
  labelClasses: aggLabelClasses
});

export const empDepMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'empDepMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'EE + Dependent',
  labelClasses: aggLabelClasses
});

export const familyMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'familyMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'Family',
  labelClasses: aggLabelClasses
});

export const compositeMonthlyClaimFactorDef: DataDefModel = new DataDefModel({
  key: 'compositeMonthlyClaimFactor',
  type: DATA_TYPES.currency,
  label: 'Composite',
  labelClasses: aggLabelClasses
});

export const aggDef: DataDefModel[] = [
  new DataDefModel({
    key: 'aggSectionHeader',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.heading,
    label: 'Aggregate Terms'
  }),
  aggCoverageDef,
  aggContractTypeDef,
  aggLossLimitPerIndividualDef,
  aggMaxReimbursementDef,
  aggReimbPercentDef,
  runInRunOutLimitDef,
  riskCorridorDef,
  aggDeductibleDef,
  minAggDeductibleDef,
  new DataDefModel({
    key: 'aggSubSectionHeader1',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Aggregate Options'
  }),
  aggAccommodationDef,
  aggTermLiabilityCoverageDef,
  aggTermLiabilityTermDef,
  new DataDefModel({
    key: 'aggSubSectionHeader2',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Aggregate Rates (PEPM)'
  }),
  aggCommissionDef,
  aggRateScheduleDef,
  aggEmpOnlyRateDef,
  aggEmpSpouseRateDef,
  aggEmpChildRateDef,
  aggEmpDepRateDef,
  aggFamilyRateDef,
  aggCompositeRateDef,
  monthlyAccRateDef,
  terminalLiabilityRateDef,
  aggMonthlyPremiumDef,
  aggEstAnnualPremiumDef,
  new DataDefModel({
    key: 'aggSubSectionHeader3',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.subHeading,
    label: 'Aggregate Factors (PEPM)'
  }),
  aggFactorScheduleDef,
  empOnlyMonthlyClaimFactorDef,
  empSpouseMonthlyClaimFactorDef,
  empChildMonthlyClaimFactorDef,
  empDepMonthlyClaimFactorDef,
  familyMonthlyClaimFactorDef,
  compositeMonthlyClaimFactorDef
];

export const costSummaryAnnualFixedDef: DataDefModel = new DataDefModel({
  key: 'annualFixedCosts',
  sectionKey: 'costSummary',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Fixed Costs',
  labelClasses: costSummaryLabelClasses
});

export const costSummarySpecAnnualVariableDef: DataDefModel = new DataDefModel({
  key: 'specAnnualVarCosts',
  sectionKey: 'costSummary',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Spec Variable Costs',
  labelClasses: costSummaryLabelClasses
});

export const costSummaryAggAnnualVariableDef: DataDefModel = new DataDefModel({
  key: 'aggAnnualVarCosts',
  sectionKey: 'costSummary',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Agg Variable Costs',
  labelClasses: costSummaryLabelClasses
});

export const costSummaryMaxAnnualDef: DataDefModel = new DataDefModel({
  key: 'maxAnnualLiability',
  sectionKey: 'costSummary',
  type: DATA_TYPES.currency,
  label: 'Est. Annual Maximum Liability',
  labelClasses: costSummaryLabelClasses
});

const costSummaryDef: DataDefModel[] = [
  new DataDefModel({
    key: 'costSummarySectionHeader',
    sectionKey: 'costSummary',
    type: DATA_TYPES.labelOnly,
    labelClasses: LabelOnlyClasses.heading,
    label: 'Cost Summary'
  }),
  costSummaryAnnualFixedDef,
  costSummarySpecAnnualVariableDef,
  costSummaryAggAnnualVariableDef,
  costSummaryMaxAnnualDef
];

export const responseOptionsPolicyDef: DataDefModel = new DataDefModel({
  ...responseOptionsBaseDef,
  definitions: [...responseOptionsBaseDef.definitions, ...policyDef]
});

export const responseOptionsSpecificDef: DataDefModel = new DataDefModel({
  ...responseOptionsBaseDef,
  definitions: [...responseOptionsBaseDef.definitions, ...specificDef]
});

export const responseOptionsAggregateDef: DataDefModel = new DataDefModel({
  ...responseOptionsBaseDef,
  definitions: [...responseOptionsBaseDef.definitions, ...aggDef]
});

export const responseOptionsCostSummaryDef: DataDefModel = new DataDefModel({
  ...responseOptionsBaseDef,
  definitions: [...responseOptionsBaseDef.definitions, ...costSummaryDef]
});

export const responseOptionsDef: DataDefModel = new DataDefModel({
  ...responseOptionsBaseDef,
  definitions: [...responseOptionsBaseDef.definitions, ...policyDef, ...specificDef, ...aggDef, ...costSummaryDef]
});

export const quoteResponseTypeDef: DataDefModel = new DataDefModel({
  key: 'type',
  type: DATA_TYPES.select,
  label: 'Proposal Status',
  options: QuoteResponseTypeSelectOptions,
  sectionKey: 'general'
});

export const quoteResponseTypeQuestionDef: DataDefModel = new DataDefModel({
  key: 'type',
  type: DATA_TYPES.select,
  label: 'Is this version firm?',
  options: [
    {
      label: 'Yes (Firm)',
      value: QuoteResponseType.FIRM
    },
    {
      label: 'No (Illustrative)',
      value: QuoteResponseType.ILLUSTRATIVE
    }
  ],
  sectionKey: 'general',
  validators: { required: true }
});

export const quoteResponseTypeRequiredDef: DataDefModel = new DataDefModel({
  ...quoteResponseTypeDef,
  validators: { required: true }
});

export const quoteResponseLaserConfirmationDef: DataDefModel = new DataDefModel({
  key: 'laserConfirmation',
  type: DATA_TYPES.select,
  label: 'Does this version include lasers?',
  options: QuoteResponseLaserConfirmationOptions,
  validators: { required: true }
});

export const quoteResponseLaserDef: DataDefModel[] = [
  new DataDefModel({
    key: 'id',
    type: DATA_TYPES.text,
    label: 'Id',
    readOnly: true,
    hiddenRow: true
  }),
  new DataDefModel({
    key: 'name',
    type: DATA_TYPES.text,
    label: 'Name/ID'
  }),
  new DataDefModel({
    key: 'relationship',
    type: DATA_TYPES.select,
    label: 'Relationship',
    options: [
      {
        label: 'EE',
        value: LaserRelationship.EE
      },
      {
        label: 'SP',
        value: LaserRelationship.SP
      },
      {
        label: 'CH',
        value: LaserRelationship.CH
      }
    ]
  }),
  new DataDefModel({
    key: 'specDeductible',
    type: DATA_TYPES.currency,
    label: 'Individual Spec Deductible'
  }),
  new DataDefModel({
    key: 'contractBasis',
    type: DATA_TYPES.text,
    label: 'Contract Type'
  }),
  new DataDefModel({
    key: 'maxReimbursement',
    type: DATA_TYPES.currency,
    label: 'Maximum Reimbursement'
  }),
  new DataDefModel({
    key: 'excluded',
    type: DATA_TYPES.select,
    label: 'Excluded Laser',
    options: YES_NO_OPTIONS
  }),
  new DataDefModel({
    key: 'conditional',
    type: DATA_TYPES.boolean,
    label: 'Conditional Laser',
    layout: {
      toggle: true
    }
  }),
  new DataDefModel({
    key: 'condition',
    type: DATA_TYPES.text,
    label: 'Comments'
  })
];

export const laserUiOptionsDef: DataDefModel = new DataDefModel({
  key: 'options',
  type: DATA_TYPES.formGroupArray,
  definitions: quoteResponseLaserDef
});
export const laserUiListDef: DataDefModel[] = [
  new DataDefModel({
    key: 'name',
    type: DATA_TYPES.text,
    validators: {
      required: true
    }
  }),
  new DataDefModel({
    key: 'relationship',
    type: DATA_TYPES.select,
    label: 'Relationship',
    options: [
      {
        label: 'EE',
        value: LaserRelationship.EE
      },
      {
        label: 'SP',
        value: LaserRelationship.SP
      },
      {
        label: 'CH',
        value: LaserRelationship.CH
      }
    ]
  }),
  new DataDefModel({
    key: 'conditional',
    type: DATA_TYPES.boolean,
    label: 'Conditional Laser',
    layout: {
      toggle: true
    }
  }),
  new DataDefModel({
    key: 'condition',
    type: DATA_TYPES.text,
    label: 'Comments'
  }),
  laserUiOptionsDef
];
export const laserUiFormGroupDef: DataDefModel = new DataDefModel({
  key: 'lasers',
  type: DATA_TYPES.formGroupArray,
  definitions: laserUiListDef
});

export const quoteResponseAdditionalFeesDef: DataDefModel[] = [
  new DataDefModel({
    key: 'id',
    type: DATA_TYPES.text,
    label: 'Id',
    readOnly: true,
    hiddenRow: true
  }),
  new DataDefModel({
    key: 'name',
    type: DATA_TYPES.text,
    label: 'Fee Name'
  }),
  new DataDefModel({
    key: 'type',
    type: DATA_TYPES.text,
    label: 'Fee Type'
  }),
  new DataDefModel({
    key: 'recurrence',
    type: DATA_TYPES.select,
    label: 'Recurrence',
    options: [
      {
        label: 'PEPM',
        value: FeeRecurrence.PEPM
      },
      {
        label: 'Per Employee',
        value: FeeRecurrence.PE
      },
      {
        label: 'Monthly',
        value: FeeRecurrence.MONTHLY
      },
      {
        label: 'Once',
        value: FeeRecurrence.ONCE
      }
    ]
  }),
  new DataDefModel({
    key: 'amount',
    type: DATA_TYPES.currency,
    label: 'Amount',
    validators: {
      required: true
    }
  })
];

export const additionalFeesUiOptionsDef: DataDefModel = new DataDefModel({
  key: 'options',
  type: DATA_TYPES.formGroupArray,
  definitions: quoteResponseAdditionalFeesDef
});

export const additionalFeesUiListDef: DataDefModel[] = [
  new DataDefModel({
    key: 'name',
    type: DATA_TYPES.text,
    validators: {
      required: true
    }
  }),
  new DataDefModel({
    key: 'type',
    type: DATA_TYPES.text
  }),
  new DataDefModel({
    key: 'recurrence',
    type: DATA_TYPES.select,
    options: [
      {
        label: 'PEPM',
        value: FeeRecurrence.PEPM
      },
      {
        label: 'Per Employee',
        value: FeeRecurrence.PE
      },
      {
        label: 'Monthly',
        value: FeeRecurrence.MONTHLY
      },
      {
        label: 'Once',
        value: FeeRecurrence.ONCE
      }
    ],
    validators: {
      required: true
    }
  }),
  additionalFeesUiOptionsDef
];

export const additionalFeesUiFormGroupDef: DataDefModel = new DataDefModel({
  key: 'additionalFees',
  type: DATA_TYPES.formGroupArray,
  definitions: additionalFeesUiListDef
});

export const effectiveDateDataDef: DataDefModel = new DataDefModel({
  key: 'effectiveDate',
  type: DATA_TYPES.datepicker,
  label: 'Contract Effective Date',
  sectionKey: 'general'
});

export const enrollmentTierDataDef = new DataDefModel({
  key: 'enrollmentTier',
  type: DATA_TYPES.select,
  label: 'Enrollment Schedule',
  options: RATE_TYPE_OPTIONS,
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true
  }
});

export const specEnrollmentEEDataDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentEE',
  type: DATA_TYPES.number,
  label: 'EE Only (Single)',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});

export const specEnrollmentESDataDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentES',
  type: DATA_TYPES.number,
  label: 'EE + Spouse',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});

export const specEnrollmentECDataDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentEC',
  type: DATA_TYPES.number,
  label: 'EE + Child',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});
export const specEnrollmentEDDataDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentED',
  type: DATA_TYPES.number,
  label: 'EE + Dependent',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});

export const specEnrollmentEFDataDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentEF',
  type: DATA_TYPES.number,
  label: 'Family',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});

export const specEnrollmentCEDef: DataDefModel = new DataDefModel({
  key: 'specEnrollmentCE',
  type: DATA_TYPES.number,
  label: 'Composite',
  sectionKey: 'quotedEnrollment',
  validators: {
    required: true,
    min: 0
  }
});

export const requestedTotalEnrollmentNoComposite = new DataDefModel({
  key: 'requestedTotalEnrollmentNoComposite',
  type: DATA_TYPES.number,
  label: 'Total Enrollment',
  readOnly: true,
  sectionKey: 'requestedEnrollment',
  derivedValue: {
    fields: [
      'quoteRequest/enrollmentEmployeeOnly',
      'quoteRequest/enrollmentEmployeeSpouse',
      'quoteRequest/enrollmentEmployeeChildren',
      'quoteRequest/enrollmentEmployeeDependent',
      'quoteRequest/enrollmentFamily'
    ],
    operation: 'sum'
  }
});

export const requestedTotalEnrollmentComposite = new DataDefModel({
  key: 'requestedTotalEnrollmentComposite',
  type: DATA_TYPES.number,
  label: 'Total Enrollment',
  readOnly: true,
  sectionKey: 'requestedEnrollment',
  derivedValue: {
    fields: ['quoteRequest/enrollmentComposite'],
    operation: 'sum'
  }
});

export const quotedTotalEnrollmentNoComposite = new DataDefModel({
  key: 'quotedTotalEnrollmentNoComposite',
  type: DATA_TYPES.number,
  label: 'Total Enrollment',
  readOnly: true,
  sectionKey: 'quotedEnrollment',
  derivedValue: {
    fields: ['specEnrollmentEE', 'specEnrollmentES', 'specEnrollmentEC', 'specEnrollmentED', 'specEnrollmentEF'],
    operation: 'sum'
  }
});

export const quotedTotalEnrollmentComposite = new DataDefModel({
  key: 'quotedTotalEnrollmentComposite',
  type: DATA_TYPES.number,
  label: 'Total Enrollment',
  readOnly: true,
  sectionKey: 'quotedEnrollment',
  derivedValue: {
    fields: ['specEnrollmentCE'],
    operation: 'sum'
  }
});

export const quoteResponseFields: DataDefModel[] = [
  quoteResponseTypeDef,
  effectiveDateDataDef,
  new DataDefModel({
    key: 'expirationDate',
    type: DATA_TYPES.datepicker,
    label: 'Contract Expiration Date',
    sectionKey: 'general'
  }),
  new DataDefModel({
    key: 'proposalDate',
    type: DATA_TYPES.datepicker,
    label: 'Proposal Date',
    sectionKey: 'general'
  }),
  new DataDefModel({
    key: 'validThroughDate',
    type: DATA_TYPES.datepicker,
    label: 'Valid Through Date',
    sectionKey: 'general'
  }),
  new DataDefModel({
    key: 'lastModifiedDate',
    type: DATA_TYPES.datepicker,
    label: 'Last Updated Date',
    readOnly: true,
    sectionKey: 'general'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentTier',
    type: DATA_TYPES.select,
    label: 'Enrollment Schedule',
    options: RATE_TYPE_OPTIONS,
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentEmployeeOnly',
    type: DATA_TYPES.number,
    label: 'EE Only (Single)',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentEmployeeSpouse',
    type: DATA_TYPES.number,
    label: 'EE + Spouse',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentEmployeeChildren',
    type: DATA_TYPES.number,
    label: 'EE + Child',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentEmployeeDependent',
    type: DATA_TYPES.number,
    label: 'EE + Dependent',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentFamily',
    type: DATA_TYPES.number,
    label: 'Family',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'quoteRequest.enrollmentComposite',
    type: DATA_TYPES.number,
    label: 'Composite',
    readOnly: true,
    sectionKey: 'requestedEnrollment'
  }),
  new DataDefModel({
    key: 'requestedTotalEnrollment',
    type: DATA_TYPES.number,
    label: 'Total Enrollment',
    sectionKey: 'requestedEnrollment',
    calculatedValueFn: (row: QuoteResponseModel) =>
      !!row.quoteRequest.enrollmentComposite
        ? nullOrUndefinedToZero(row.quoteRequest.enrollmentComposite)
        : nullOrUndefinedToZero(row.quoteRequest.enrollmentEmployeeOnly) +
          nullOrUndefinedToZero(row.quoteRequest.enrollmentEmployeeSpouse) +
          nullOrUndefinedToZero(row.quoteRequest.enrollmentEmployeeChildren) +
          nullOrUndefinedToZero(row.quoteRequest.enrollmentEmployeeDependent) +
          nullOrUndefinedToZero(row.quoteRequest.enrollmentFamily)
  }),
  enrollmentTierDataDef,
  specEnrollmentEEDataDef,
  specEnrollmentESDataDef,
  specEnrollmentECDataDef,
  specEnrollmentEDDataDef,
  specEnrollmentEFDataDef,
  new DataDefModel({
    key: 'proposalEnrollmentComposite',
    type: DATA_TYPES.number,
    label: 'Composite',
    sectionKey: 'quotedEnrollment',
    calculatedValueFn: (row: QuoteResponseModel) =>
      !row.enrollmentTier || row.enrollmentTier === RateType.TS01 ? row.specEnrollmentCE : undefined
  }),
  new DataDefModel({
    key: 'totalEnrollment',
    type: DATA_TYPES.number,
    label: 'Total Enrollment',
    sectionKey: 'quotedEnrollment',
    calculatedValueFn: (row: QuoteResponseModel) =>
      !!row.specEnrollmentCE
        ? nullOrUndefinedToZero(row.specEnrollmentCE)
        : nullOrUndefinedToZero(row.specEnrollmentEE) +
          nullOrUndefinedToZero(row.specEnrollmentES) +
          nullOrUndefinedToZero(row.specEnrollmentEC) +
          nullOrUndefinedToZero(row.specEnrollmentED) +
          nullOrUndefinedToZero(row.specEnrollmentEF)
  }),
  responseOptionsDef
];

const currencyPipe: CurrencyPipe = new CurrencyPipe('en_US');

export const laserSummaryDefs: DataDefModel[] = [
  new DataDefModel({
    key: 'specDeductible',
    label: 'Contract Spec Deductible',
    type: DATA_TYPES.currency
  }),
  new DataDefModel({
    key: 'totalLaserSpecDeductible',
    label: 'Total Laser Spec Deductible(s)',
    type: DATA_TYPES.currency,
    calculatedValueFn: (row: QuoteResponseOption) =>
      currencyPipe.transform(calculateTotalLaserSpecDeductible(row), 'USD', 'symbol', '1.2-2')
  }),
  new DataDefModel({
    key: 'additionalLaserLiability',
    label: 'Additional Laser Liability',
    type: DATA_TYPES.currency,
    calculatedValueFn: (row: QuoteResponseOption) =>
      currencyPipe.transform(
        calculateTotalLaserSpecDeductible(row) -
          row.specDeductible * row.lasers?.filter((laser) => !!laser.specDeductible && !laser.excluded).length,
        'USD',
        'symbol',
        '1.2-2'
      )
  })
];

export const laserSection: FormSection = { label: '', key: 'lasers' };

export const laserLabelDef: DataDefModel = new DataDefModel({
  key: '',
  label: 'Lasers',
  sectionKey: 'lasers',
  type: DATA_TYPES.labelOnly,
  labelClasses: LabelOnlyClasses.heading
});

export const laserDef: DataDefModel = new DataDefModel({
  key: 'lasers',
  sectionKey: 'lasers',
  type: DATA_TYPES.formGroupArray,
  definitions: quoteResponseLaserDef.map((def) => {
    if (def.key === 'conditional') {
      def.type = DATA_TYPES.select;
      def.options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
        { label: 'No', value: null }
      ];
    }
    return def;
  })
});

export const additionalFeeSection: FormSection = { label: '', key: 'additionalFees' };

export const additionalFeeLabelDef: DataDefModel = new DataDefModel({
  key: '',
  label: 'Additional Fees',
  sectionKey: 'additionalFees',
  type: DATA_TYPES.labelOnly,
  labelClasses: LabelOnlyClasses.heading
});

export const additionalFeeDef: DataDefModel = new DataDefModel({
  key: 'additionalFees',
  sectionKey: 'additionalFees',
  type: DATA_TYPES.formGroupArray,
  definitions: quoteResponseAdditionalFeesDef.map((def) => {
    if (def.key === 'conditional') {
      def.type = DATA_TYPES.select;
      def.options = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
        { label: 'No', value: null }
      ];
    }
    return def;
  })
});

function calculateTotalLaserSpecDeductible(option: QuoteResponseOption) {
  let totalSpecDeductible = 0;
  option.lasers.forEach((laser) => {
    totalSpecDeductible = !laser.excluded ? totalSpecDeductible + laser.specDeductible : totalSpecDeductible;
  });
  return totalSpecDeductible;
}

/**
 * In order for the item preview to make sense for lasers we need to reformat them into a set of lasers for each option.
 * Not all the options have to have the same amount of lasers so group them by name so that the preview will display them
 * in the same row. This way empty lasers will still get displayed as placeholders.
 *
 * This creates an array of objects that have a 'lasers' key to an array of lasers i.e
 * [
 *   {
 *     lasers: [
 *       {
 *         name: 'laserName',
 *         ...
 *       },
 *       ...
 *     ],
 *   },
 *   ...
 * ]
 * The 'lasers' key is defined in the laserDef above.
 * @param options
 */
export function getLaserArrays(options: QuoteResponseOption[]): LaserModel[] {
  const laserArrays = [];
  const laserNames = new Set();
  options.forEach((option) => {
    option.lasers?.forEach((laser) => laserNames.add(laser.name));
  });
  for (let name of Array.from(laserNames).sort()) {
    const laserArray = { lasers: [] };
    options.forEach((option) => {
      if (option?.lasers) {
        laserArray.lasers.push(option.lasers.find((laser) => laser.name === name));
      }
    });
    laserArrays.push(laserArray);
  }
  return laserArrays;
}

export function getAdditionalFeeArrays(options: QuoteResponseOption[]): AdditionalFeeModel[] {
  const additionalFeeArrays = [];
  const feeName = new Set<string>();
  options.forEach((option) => {
    option.additionalFees?.forEach((addFee) => feeName.add(addFee.name));
  });
  for (let name of Array.from(feeName).sort()) {
    const feeArray = { additionalFees: [] };
    options.forEach((option) => {
      if (option?.additionalFees) {
        feeArray.additionalFees.push(option.additionalFees.find((addFee) => addFee.name === name));
      }
    });
    additionalFeeArrays.push(feeArray);
  }
  return additionalFeeArrays;
}

type FieldEnableDisable = { enabled: string[]; disabled: string[] };

export const enrollmentTierToEnabledFields = new Map<RateType, FieldEnableDisable>([
  [
    RateType.TS01,
    {
      enabled: [specEnrollmentCEDef.formKey],
      disabled: [
        specEnrollmentEEDataDef.formKey,
        specEnrollmentESDataDef.formKey,
        specEnrollmentECDataDef.formKey,
        specEnrollmentEDDataDef.formKey,
        specEnrollmentEFDataDef.formKey
      ]
    }
  ],
  [
    RateType.TS02,
    {
      enabled: [specEnrollmentEEDataDef.formKey, specEnrollmentEFDataDef.formKey],
      disabled: [
        specEnrollmentCEDef.formKey,
        specEnrollmentESDataDef.formKey,
        specEnrollmentECDataDef.formKey,
        specEnrollmentEDDataDef.formKey
      ]
    }
  ],
  [
    RateType.TS03,
    {
      enabled: [specEnrollmentEEDataDef.formKey, specEnrollmentEDDataDef.formKey, specEnrollmentEFDataDef.formKey],
      disabled: [specEnrollmentCEDef.formKey, specEnrollmentESDataDef.formKey, specEnrollmentECDataDef.formKey]
    }
  ],
  [
    RateType.TS04,
    {
      enabled: [
        specEnrollmentEEDataDef.formKey,
        specEnrollmentESDataDef.formKey,
        specEnrollmentECDataDef.formKey,
        specEnrollmentEFDataDef.formKey
      ],
      disabled: [specEnrollmentCEDef.formKey, specEnrollmentEDDataDef.formKey]
    }
  ],
  [
    null,
    {
      enabled: [
        specEnrollmentCEDef.formKey,
        specEnrollmentEEDataDef.formKey,
        specEnrollmentESDataDef.formKey,
        specEnrollmentECDataDef.formKey,
        specEnrollmentEDDataDef.formKey,
        specEnrollmentEFDataDef.formKey
      ],
      disabled: []
    }
  ],
  [
    undefined,
    {
      enabled: [
        specEnrollmentCEDef.formKey,
        specEnrollmentEEDataDef.formKey,
        specEnrollmentESDataDef.formKey,
        specEnrollmentECDataDef.formKey,
        specEnrollmentEDDataDef.formKey,
        specEnrollmentEFDataDef.formKey
      ],
      disabled: []
    }
  ]
]);

export const enrollmentDisabler = DisableChildIf.customDisabler((control) => {
  if (control.disabled) return null;
  const selectedTier = control?.get(enrollmentTierDataDef.formKey)?.value;
  const fieldEnableDisable: FieldEnableDisable = enrollmentTierToEnabledFields.get(selectedTier);
  fieldEnableDisable.disabled.forEach((key) => {
    if (control?.get(key)?.enabled) {
      control?.get(key)?.disable();
      control?.get(key)?.setValue(null);
    }
  });
  fieldEnableDisable.enabled.forEach((key) => {
    if (control?.get(key)?.disabled) {
      control?.get(key)?.enable();
    }
  });

  // No errors
  return true;
});
