import { Injectable } from '@angular/core';
import { UnderWriterDataService } from '@common/services/under-writer-data.service';
import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UnderwriterOptionService extends AsyncOptions<string> {
  key = 'underwriter';
  defaultSort = ['name'];

  constructor(private underWriterService: UnderWriterDataService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.underWriterService
      .search(this.generateFilterString(value, requiredQuery), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.underWriterService
      .search(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  private generateFilterString = (value, requiredQuery) =>
    this.combineFilters(value ? `name ~ '${value}'` : '', requiredQuery);
}
