import { Directive, Host, Input, OnChanges, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { encodeQueryParam, segmentUrl } from '@app/tools/url';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dropCrumbs]'
})
export class DropCrumbsDirective implements OnChanges, OnDestroy {
  sub: Subscription;
  @Input('dropCrumbs') crumbLabel: string;

  constructor(private router: Router, @Host() private routerLinkWithHref: RouterLink) {
    this.sub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setQueryParams(event.url);
      }
    });
  }

  ngOnChanges(changes): void {
    if (changes.crumbLabel) {
      this.setQueryParams(this.router.url);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  setQueryParams(url) {
    this.routerLinkWithHref.queryParams = this.crumbLabel
      ? encodeQueryParam({
          breadCrumb: {
            label: this.crumbLabel,
            ...segmentUrl(this.router.parseUrl(url)) // get/store current url and queryParams
          }
        })
      : {};
  }
}
