import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SERVER_API_URL } from '@app/app.constants';
import { BulkActionResultModel } from '@common/models/bulk-action.model';
import { OrgMemberModel } from '@common/models/org-mem.model';
import { Page } from '@common/models/page.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductRole } from '@auth/models/user.model';
import { UrlUtilService } from './url-util.service';

@Injectable({
  providedIn: 'root'
})
export class OrgMemberService {
  constructor(private http: HttpClient, private urlUtil: UrlUtilService) {}

  getOrgMembershipByUserId(userId: number): Observable<OrgMemberModel[]> {
    return this.searchOrgMembers(`user.id = ${userId}`, 0, 1000, ['orgName']).pipe(map((page) => page.content));
  }

  getOrgMembershipById(id: number): Observable<OrgMemberModel> {
    return this.searchOrgMembers(`id = ${id}`, 0, 1000, []).pipe(
      map((page) => page.content),
      map((orgMembers) => (orgMembers.length > 0 ? orgMembers[0] : null))
    );
  }

  searchOrgMembers(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<OrgMemberModel>> {
    return this.http.get(`${SERVER_API_URL}/org/member`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  updateOrgMember(orgMember: OrgMemberModel): Observable<OrgMemberModel> {
    return this.http.put(`${SERVER_API_URL}/org/${orgMember.orgId}/member/${orgMember.id}`, orgMember);
  }

  updateOrgMemberPreference(orgMember: OrgMemberModel): Observable<OrgMemberModel> {
    return this.http.put(`${SERVER_API_URL}/org/${orgMember.orgId}/member/preference`, orgMember);
  }

  deleteOrgMember(orgMember: OrgMemberModel): Observable<OrgMemberModel> {
    return this.http.delete(`${SERVER_API_URL}/org/${orgMember.orgId}/member/${orgMember.id}`);
  }

  addOrgMember(orgId: number, membership: any): Observable<OrgMemberModel> {
    const body = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: membership
      })
    );
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post(`${SERVER_API_URL}/org/${orgId}/member/email`, body, httpOptions);
  }

  updateOrgMemberTags(orgMember: OrgMemberModel, tagIds: number[], action: string): Observable<BulkActionResultModel> {
    return this.http.put(`${SERVER_API_URL}/org/${orgMember.orgId}/member/${orgMember.id}/bulk-action/tag`, {
      action: action,
      ids: tagIds
    });
  }

  isIntakeOrgMember(orgMembers: OrgMemberModel[]): boolean {
    return orgMembers.some((orgMember) => orgMember.quoteLinqRole === ProductRole.INTAKE || orgMember.smartLinqRole === ProductRole.INTAKE ||
    orgMember.quoteLinqRxRole === ProductRole.INTAKE);
  }
}
