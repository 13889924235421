import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-workspace-header-status',
  template: `
    <div class="status-pill" [ngClass]="classes" (click)="emitStatusClick()">
      {{ data | dataFormatter: statusDef }}
    </div>
  `,
  styleUrls: ['./workspace-header.component.scss']
})
export class WorkspaceHeaderStatusComponent implements OnChanges {
  @Input() data;
  @Input() statusDef: DataDefModel = new DataDefModel({
    label: 'Status',
    key: 'status',
    type: DATA_TYPES.select
  });

  @Input() allowUpdateStatus: boolean = true;
  @Output() statusClick = new EventEmitter();

  classes = [];

  ngOnChanges(changes: SimpleChanges) {
    this.setClasses();
  }

  emitStatusClick() {
    this.statusClick.emit();
  }

  private setClasses() {
    this.classes.length = 0;
    if (this.allowUpdateStatus) {
      this.classes.push('clickable');
    }

    this.classes.push(this.data.status);
  }
}
