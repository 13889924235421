import { Injectable } from '@angular/core';
import { Page } from '@common/models/page.model';
import { HttpResourceService } from '@main/http-resource.service';
import {
  EndpointConnectionModel,
  EndpointConnectionTestModel,
  EndpointEventModel,
  EndpointModel
} from '@main/org-endpoint/model/org-endpoint.model';
import { Observable } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {
  constructor(private httpResourceService: HttpResourceService) {}

  createEndpointConnection(endpointConnection: EndpointConnectionModel): Observable<EndpointConnectionModel> {
    return this.httpResourceService.post(`endpoint-connection`, endpointConnection);
  }

  updateEndpointConnection(endpointConnection: EndpointConnectionModel): Observable<EndpointConnectionModel> {
    return this.httpResourceService.put(`endpoint-connection/${endpointConnection.id}`, endpointConnection);
  }

  deleteEndpointConnection(endpointConnectionId: number): Observable<any> {
    return this.httpResourceService.delete(`endpoint-connection/${endpointConnectionId}`);
  }

  getEndpointConnections(): Observable<EndpointConnectionModel[]> {
    return this.httpResourceService.orgMembership$.pipe(
      switchMapTo(this.httpResourceService.get('endpoint-connection'))
    );
  }

  testEndpointConnection(endpointConnection: EndpointConnectionModel): Observable<EndpointConnectionTestModel> {
    return this.httpResourceService.put<EndpointConnectionTestModel>(
      `endpoint-connection/${endpointConnection.id}/test`,
      null
    );
  }

  createEndpoint(endpoint: EndpointModel): Observable<EndpointModel> {
    return this.httpResourceService.post<EndpointModel>('endpoint', endpoint);
  }

  updateEndpoint(endpoint: EndpointModel): Observable<EndpointModel> {
    return this.httpResourceService.put<EndpointModel>(`endpoint/${endpoint.id}`, endpoint);
  }

  deleteEndpoint(endpointId: number): Observable<any> {
    return this.httpResourceService.delete(`endpoint/${endpointId}`);
  }

  getEndpoints(): Observable<EndpointModel[]> {
    return this.httpResourceService.get('endpoint');
  }

  searchEndpointEvents(
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<EndpointEventModel>> {
    return this.httpResourceService.query({
      path: 'endpoint-event',
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  executeEndpoint(endpoint: EndpointModel): Observable<any> {
    return this.httpResourceService.put(`endpoint/${endpoint.id}/execute`, null);
  }
}
