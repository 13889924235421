import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-workspace-header',
  templateUrl: './workspace-header.component.html',
  styleUrls: ['./workspace-header.component.scss']
})
export class WorkspaceHeaderComponent {
  @Input() isRecordDetailsHeader: boolean = false;
}
