<label [class]="{ 'inline-label': inline, required: required }">
  <span
    class="label-text {{ hasJoinedField ? 'has-other-label' : '' }} {{
      !control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''
    }}"
    truncateText
    *ngIf="inline"
    aria-hidden="true"
  >
    {{ label }}
  </span>
  <mat-form-field [appearance]="appearance" floatLabel="always">
    <mat-select
      [compareWith]="compareFn"
      [required]="required"
      [formControl]="control"
      [appIsReadOnly]="readOnly"
      (selectionChange)="_valueChanged($event.value)"
      [placeholder]="noSelectionLabel"
    >
      <mat-select-trigger truncateText>{{ getDisplayValue(options, control.value) }}</mat-select-trigger>
      <mat-option *ngIf="!required" [value]="null">--</mat-option>
      <mat-option
        [disabled]="shouldDisable(control.value, option)"
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
    <mat-hint>
      <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
    </mat-hint>
    <mat-error>
      <app-error-renderer [errors]="control.errors"></app-error-renderer>
    </mat-error>
  </mat-form-field>
</label>
