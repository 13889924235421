import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { of } from 'rxjs';
import { SicCodeService } from './sic-code.service';

function sicToLabelValue(sicCodes: any[]): LabelValue<string, string>[] {
  return sicCodes.map((sic) => ({
    label: `${sic.code} - ${sic.description}`,
    value: sic.code
  }));
}

@Injectable()
export class SicCodeOptionService extends AsyncOptions<string> {
  key = 'sicCodes';

  constructor(private sicService: SicCodeService) {
    super();
  }

  generateLabelValues = sicToLabelValue;

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.sicService
      .search(
        this.combineFilters(`code ~ '${value}' or description ~ '${value}'`, requiredQuery),
        pageIndex,
        pageSize,
        ['code']
      )
      .pipe(
        map((result) => ({
          content: this.generateLabelValues(result.content),
          total: result.total
        }))
      );

  valuesFromKeys = (values: string[], multi) => {
    if (!values?.length) return of(null);
    const filter = multi ? `code ~ ('${values.join("','")}')` : `code ~ '${values}'`;
    return this.sicService.search(filter, 0, 1000, ['code']).pipe(map((result) => sicToLabelValue(result.content)));
  };
}
