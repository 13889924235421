import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-support-file-dialog.component.html',
  selector: 'app-edit-support-file-dialog'
})
export class EditSupportFileDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditSupportFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
