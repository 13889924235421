import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavItems } from '@main/nav.models';
import { OrgProduct } from '@main/store/product/product.model';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { Store } from '@ngxs/store';
import { SiteFilters } from '@main/store/site-filter/site-filter.actions';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import HideSiteFilter = SiteFilters.HideSiteFilter;

@Component({
  template: `
    <h1 class="mat-h1 title">Reports</h1>

    <app-card *ngIf="navItems$ | async as navItems">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a
          mat-tab-link
          *ngFor="let item of navItems"
          [routerLink]="item.link"
          queryParamsHandling="merge"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="rla.isActive"
        >
          {{ item.label }}
        </a>
      </nav>
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </app-card>
  `,
  selector: 'app-report-library'
})
export class ReportLibraryComponent implements OnInit, OnDestroy {
  navItems$: Observable<NavItems> = this.store.select(ProductSelectors.selectedProduct).pipe(
    map((p) =>
      p === OrgProduct.SMART
        ? [
            {
              label: 'Report Library',
              link: 'library'
            },
            {
              label: 'My Schedules',
              link: 'my-schedules'
            },
            {
              label: 'System Schedules',
              link: 'system'
            },
            {
              label: 'Report History',
              link: 'history'
            }
          ]
        : [
            {
              label: 'Report Library',
              link: 'library'
            },
            {
              label: 'My Schedules',
              link: 'my-schedules'
            },
            {
              label: 'Report History',
              link: 'history'
            }
          ]
    )
  );

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new HideSiteFilter(true));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new HideSiteFilter(false));
  }
}
