import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './import-help-dialog.component.html',
  selector: 'app-import-help-dialog',
  styleUrls: ['./import-help-dialog.component.scss']
})
export class ImportHelpDialogComponent {
  date: Date;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.date = new Date();
  }
}
