import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkspaceLibModule } from '@app/libraries/workspace-lib/workspace-lib.module';
import { DisplayColumnsMenuComponent } from '@data-table-lib/data-table-header/display-columns-menu/display-columns-menu.component';
import { DataTableCellComponent } from '@data-table-lib/data-table/data-table-cell.component';
import { FormLibModule } from '@form-lib/form-lib.module';
import { LibResourceModule } from '@lib-resource/lib-resource.module';
import { SharedModule } from '@shared/shared.module';
import { DataTableFilterComponent } from './data-table-filter/data-table-filter.component';
import { DataTableHeaderComponent } from './data-table-header/data-table-header.component';
import { GlossaryDialogComponent } from './data-table-header/glossary-dialog/glossary-dialog.component';
import { QueryBrickComponent } from './data-table-header/query-brick/query-brick.component';
import { QueryInputMenuComponent } from './data-table-header/query-input-menu/query-input-menu.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DerivedActionLabelPipe } from './pipes/derived-action-label.pipe';
import { HideIfPipe } from './pipes/hide-if.pipe';
import { SelectAllToolbarComponent } from './select-all-toolbar/select-all-toolbar.component';

const declarationsOnly = [
  QueryBrickComponent,
  QueryInputMenuComponent,
  HideIfPipe,
  DerivedActionLabelPipe,
  DisplayColumnsMenuComponent
];

const entryComponents = [GlossaryDialogComponent];

const exportItems = [DataTableComponent, DataTableHeaderComponent, DataTableFilterComponent, SelectAllToolbarComponent];

@NgModule({
  imports: [SharedModule, FormLibModule, RouterModule, LibResourceModule, WorkspaceLibModule],
  declarations: [...exportItems, ...declarationsOnly, ...entryComponents, DataTableCellComponent],
  exports: [...exportItems]
})
export class DataTableLibModule {}
