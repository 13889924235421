import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { HttpResourceService } from '@main/http-resource.service';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import {
  QuoteTypeFieldDataModel,
  QuoteTypeFieldDataUIModel,
  QuoteTypeFieldLocation,
  QuoteTypeFieldModel,
  FormFieldType,
  QuoteTypeModel
} from '@common/models/quote-type.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { FormModel } from '@form-lib/models/form.model';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { YES_NO_OPTIONS } from '@form-lib/predefined/generic.options';

@Injectable({
  providedIn: 'root'
})
export class QuoteTypeService {
  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  constructor(
    protected http: HttpClient,
    protected urlUtil: UrlUtilService,
    private fileDownloadService: FileDownloadService,
    private store: Store,
    private httpResourceService: HttpResourceService
  ) {}

  quoteTypeNameExists(id: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );
    if (id) {
      params = params.set('id', `${id}`);
    }
    return this.http.get<boolean>(`${this.baseUrl}/quote-type/exists`, { params: params });
  }

  // Used to retrieve data outside of active org
  getQuoteType(id: number, orgId?: number): Observable<QuoteTypeModel> {
    if (!orgId) {
      this.store
        .select(SiteFilterSelectors.singleSelectedOrgId)
        .pipe(switchMap((oid) => this.http.get(`${SERVER_API_URL}/org/${oid}/quote-type/${id}`)));
    }
    return this.http.get<QuoteTypeModel>(`${SERVER_API_URL}/org/${orgId}/quote-type/${id}`).pipe(
      map((qt) =>
        // set the form keys by rebuilding the data def models
        ({ ...qt, reqFormLayout: this.setReqFormLayoutFormKeys(qt?.reqFormLayout) })
      )
    );
  }

  setReqFormLayoutFormKeys(reqFormLayout: FormModel): FormModel {
    return {
      ...reqFormLayout,
      fields: reqFormLayout?.fields.map(
        (def) =>
          new DataDefModel({
            ...def,
            definitions: def.definitions?.map((subDef) => new DataDefModel(subDef)),
            fields: def.fields?.map((subField) => new DataDefModel(subField))
          })
      )
    };
  }

  getQuoteTypes(orgId: number): Observable<Page<QuoteTypeModel>> {
    const params = new HttpParams({
      fromObject: {
        sort: 'name'
      }
    });
    return this.http.get(`${SERVER_API_URL}/org/${orgId}/quote-type`, { params: params });
  }

  getQuoteTypeTemplates(): Observable<Array<QuoteTypeModel>> {
    return this.http.get<Array<QuoteTypeModel>>(`${SERVER_API_URL}/quote-type/template`);
  }

  createFromTemplate(quoteTypeId: number, name: string, active: boolean, orgId?: number): Observable<QuoteTypeModel> {
    const body = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name,
          active: active
        }
      })
    );

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    if (!orgId) {
      orgId = this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId);
    }

    return this.http.post<QuoteTypeModel>(
      `${SERVER_API_URL}/org/${orgId}/quote-type/template/${quoteTypeId}`,
      body,
      httpOptions
    );
  }

  create(quoteTypeModel: QuoteTypeModel): Observable<QuoteTypeModel> {
    return this.http.post<QuoteTypeModel>(`${this.baseUrl}/quote-type`, quoteTypeModel);
  }

  update(quoteTypeModel: QuoteTypeModel): Observable<QuoteTypeModel> {
    return this.http.put<QuoteTypeModel>(`${this.baseUrl}/quote-type/${quoteTypeModel.id}`, quoteTypeModel);
  }

  search(filterString: string, pageIndex: number, pageSize: number, sort: string[]): Observable<Page<QuoteTypeModel>> {
    return this.httpResourceService.query({
      path: 'quote-type',
      query: {
        filterString,
        pageIndex,
        pageSize,
        sort
      }
    });
  }

  searchWithoutOrg(
    orgId: number,
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<QuoteTypeModel>> {
    return this.httpResourceService.queryWithoutOrg({
      path: `org/${orgId}/quote-type`,
      query: {
        filterString,
        pageIndex,
        pageSize,
        sort
      }
    });
  }

  uploadProposalTemplate(quoteType: QuoteTypeModel, file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `${SERVER_API_URL}/org/${quoteType.orgId}/quote-type/${quoteType.id}/proposaltemplate`,
      formData
    );
  }

  downloadProposalTemplate(quoteType: QuoteTypeModel): void {
    this.fileDownloadService.downloadFile(
      `${SERVER_API_URL}/org/${quoteType.orgId}/quote-type/${quoteType.id}/proposaltemplate/stream`,
      { fileName: quoteType.proposalTemplateRmtFile.name }
    );
  }

  deleteProposalTemplate(quoteType: QuoteTypeModel) {
    return this.http.delete(`${SERVER_API_URL}/org/${quoteType.orgId}/quote-type/${quoteType.id}/proposaltemplate`);
  }

  buildQuoteTypeFieldDefs(
    quoteTypeFieldData: QuoteTypeFieldDataUIModel[],
    sectionKey: string,
    transformBoolean: boolean = true
  ): DataDefModel[] {
    if (!quoteTypeFieldData?.length) {
      return [];
    }
    return quoteTypeFieldData
      .map((uiFieldData: QuoteTypeFieldDataUIModel, index: number) => {
        switch (uiFieldData.quoteTypeField.formFieldDefinition.type) {
          case FormFieldType.BOOLEAN:
            return new DataDefModel({
              label: uiFieldData.quoteTypeField.formFieldDefinition.name,
              key: `quoteTypeFieldData[${index}].value`,
              sectionKey: sectionKey,
              type: transformBoolean ? DATA_TYPES.select : DATA_TYPES.boolean,
              options: transformBoolean ? YES_NO_OPTIONS : undefined
            });
          case FormFieldType.TEXT:
            if (uiFieldData.quoteTypeField.formFieldDefinition.inputSize > 1) {
              return new DataDefModel({
                label: uiFieldData.quoteTypeField.formFieldDefinition.name,
                key: `quoteTypeFieldData[${index}].value`,
                sectionKey: sectionKey,
                type: DATA_TYPES.textarea
              });
            }
            return new DataDefModel({
              label: uiFieldData.quoteTypeField.formFieldDefinition.name,
              key: `quoteTypeFieldData[${index}].value`,
              sectionKey: sectionKey,
              type: DATA_TYPES.text
            });
          case FormFieldType.YES_NO_COMMENTS:
            return new DataDefModel({
              label: uiFieldData.quoteTypeField.formFieldDefinition.name,
              key: `quoteTypeFieldData[${index}].value`,
              sectionKey: sectionKey,
              type: DATA_TYPES.boolean,
              icon: faComment,
              joinedField: new DataDefModel({
                key: `quoteTypeFieldData[${index}].comment`,
                type: DATA_TYPES.textarea
              })
            });
          default:
            return undefined;
        }
      })
      .filter((uiFieldData) => uiFieldData !== undefined);
  }

  mapQuoteTypeFieldDataToUIData(quoteTypeFieldData: QuoteTypeFieldDataModel[]): QuoteTypeFieldDataUIModel[] {
    return quoteTypeFieldData
      .sort((a, b) => a.quoteTypeField.formFieldDefinition.orderIndex - b.quoteTypeField.formFieldDefinition.orderIndex)
      .map((fieldData) => ({
        ...fieldData,
        value:
          fieldData.quoteTypeField.formFieldDefinition.type !== FormFieldType.BOOLEAN &&
          fieldData.quoteTypeField.formFieldDefinition.type !== FormFieldType.YES_NO_COMMENTS
            ? fieldData.values[0]
            : fieldData.values[0] === 'true'
              ? true
              : fieldData.values[0] === 'false'
                ? false
                : undefined,
        comment:
          fieldData.quoteTypeField.formFieldDefinition.type === FormFieldType.YES_NO_COMMENTS &&
          fieldData.values.length > 1
            ? fieldData.values[1]
            : undefined
      }));
  }

  mapQuoteTypeFieldToData(
    quoteTypeFields: QuoteTypeFieldModel[],
    requestId: number,
    responseOptionId: number,
    contractId: number,
    locations: QuoteTypeFieldLocation[]
  ): QuoteTypeFieldDataUIModel[] {
    return quoteTypeFields
      .filter((field) => locations.includes(field.location))
      .sort((a, b) => a.formFieldDefinition.orderIndex - b.formFieldDefinition.orderIndex)
      .map((field) => ({
        quoteTypeField: field,
        quoteRequestId: requestId,
        quoteResponseOptionId: responseOptionId,
        rxContractId: contractId,
        value:
          field.formFieldDefinition.type === FormFieldType.BOOLEAN ||
          field.formFieldDefinition.type === FormFieldType.YES_NO_COMMENTS
            ? field.formFieldDefinition.defaultValues[0] === 'true'
            : undefined
      }));
  }

  convertUiFieldData(quoteTypeFieldUiData: QuoteTypeFieldDataUIModel[]): QuoteTypeFieldDataModel[] {
    return quoteTypeFieldUiData?.map((fieldData) => ({
      id: fieldData.id,
      quoteTypeField: fieldData.quoteTypeField,
      quoteResponseOptionId: fieldData.quoteResponseOptionId,
      values: [
        fieldData.quoteTypeField.formFieldDefinition.type !== FormFieldType.BOOLEAN &&
        fieldData.quoteTypeField.formFieldDefinition.type !== FormFieldType.YES_NO_COMMENTS
          ? fieldData?.value
          : fieldData?.value
            ? 'true'
            : 'false',
        fieldData?.comment
      ].filter((o) => o !== null)
    }));
  }
}
