import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '@app/app.constants';
import { ImportResultModel } from '@common/models/import-result.model';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { replaceNewLine } from './sic-code.helpers';
import { SicCodeModel } from './sic-code.model';

@Injectable({
  providedIn: 'root'
})
export class SicCodeService {
  constructor(private http: HttpClient, private urlUtil: UrlUtilService) {}

  search(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<SicCodeModel>> {
    return this.http
      .get<Page<SicCodeModel>>(`${SERVER_API_URL}/sic`, {
        params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
      })
      .pipe(
        map((result) => {
          if (result.content && result.content.length) {
            result.content = result.content.map((value) => ({
              ...value,
              description: replaceNewLine(value.description)
            }));
          }
          return result;
        })
      );
  }

  create(file: File): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ImportResultModel>(`${SERVER_API_URL}/sic/import`, formData);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${SERVER_API_URL}/sic/${id}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${SERVER_API_URL}/sic/${id}`);
  }
}
