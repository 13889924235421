import { Injectable } from '@angular/core';
import { PorModel } from '@common/models/por.model';
import { PorService } from '@common/services/por.service';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PorOptionsService extends AsyncOptions<PorModel> {
  key = 'pors';
  defaultSort = ['id'];

  constructor(private porService: PorService) {
    super();
  }

  filter = () =>
    this.porService
      .get()
      .pipe(map((content) => ({ content: this.generateLabelValues(content), total: content.length })));

  valuesFromKeys = (values: Array<PorModel>, multi = true) => {
    if (!values?.length) return of(null);
    return this.porService.get();
  };

  generateLabelValues(items: PorModel[]): LabelValue<string, PorModel>[] {
    if (!items?.length) return [];

    const newitems = items.map((por) => ({
      label: por.name + ' | ' + por.displayName,
      value: por
    }));
    newitems.push({ label: 'TPA', value: null });
    return newitems;
  }
}
