import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This wraps the Angular currency pipe in order to catch errors and instead return the value provided
 * */

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode?: string,
    digitsInfo?: string,
    locale?: string,
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol'
  ): any {
    try {
      return super.transform(value, currencyCode, display, digitsInfo, locale);
    } catch (error) {
      return value;
    }
  }
}
