<form class="collapsible-form dense">
  <div>
    <div style="overflow: auto; padding: 10px 0;">
      <table class="terms-table dense-layout">
        <thead>
          <tr>
            <td class="field-label sticky-label pt-2" style="font-size: 1.15rem; padding-bottom: 0.5rem;">
              <span>Individual Terms</span>
            </td>
            <td
              *ngFor="let formGroup of control.controls; let i = index"
              class="option-header"
              style="padding-top: 0.5rem;"
            >
              Option {{ i + 1 }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="sticky-label grid">
              <label class="field-label label-text terms-label-text">
                {{ specDeductibleDataDef.label }}
              </label>
            </td>
            <td *ngFor="let formGroup of control.controls">
              <app-currency-field
                [control]="formGroup.controls['specDeductible']"
                [dataDef]="specDeductibleDataDef"
                [inlineNoLabel]="true"
                [readOnly]="readOnly || formGroup.controls['excluded'].value"
                class="terms-input-field"
              >
              </app-currency-field>
            </td>
          </tr>
          <tr>
            <td class="sticky-label grid">
              <label class="field-label inline-label label-text terms-label-text">
                {{ contractBasisDataDef.label }}
              </label>
            </td>
            <td *ngFor="let formGroup of control.controls">
              <app-text-field
                [control]="formGroup.controls['contractBasis']"
                [dataDef]="contractBasisDataDef"
                [inlineNoLabel]="true"
                [readOnly]="readOnly || formGroup.controls['excluded'].value"
                class="terms-input-field"
              >
              </app-text-field>
            </td>
          </tr>
          <tr>
            <td class="sticky-label grid">
              <label class="field-label label-text terms-label-text">
                {{ maxReimbursementDataDef.label }}
              </label>
            </td>
            <td *ngFor="let formGroup of control.controls">
              <app-currency-field
                [control]="formGroup.controls['maxReimbursement']"
                [dataDef]="maxReimbursementDataDef"
                [inlineNoLabel]="true"
                [readOnly]="readOnly || formGroup.controls['excluded'].value"
                class="terms-input-field"
              >
              </app-currency-field>
            </td>
          </tr>
          <tr>
            <td class="sticky-label grid">
              <label class="field-label label-text terms-label-text">
                {{ excludedDataDef.label }}
              </label>
            </td>
            <td *ngFor="let formGroup of control.controls">
              <app-select-field
                [control]="formGroup.controls['excluded']"
                [dataDef]="excludedDataDef"
                [inlineNoLabel]="true"
                [readOnly]="readOnly"
                [onChangeFn]="excludeLaser"
                class="terms-input-field"
              >
              </app-select-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
