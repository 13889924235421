import * as memoizee from 'memoizee';

// Allows devs to use functions in templates w/o them
// been called every-time there's change detection.
export function Memoize() {
  return function(target, key, descriptor) {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function() {
      return newFunction.apply(this, arguments);
    };
  };
}
