<ng-container *ngIf="{ downloadProgress: itemStream$ | async } as download">
  <app-dialog-label>Downloading CSV File</app-dialog-label>
  <app-dialog-content>
    <div *ngIf="download?.downloadProgress; else notStarted">
      {{ download.downloadProgress.downloaded | number }} of {{ download.downloadProgress.total | number }} rows
      {{ download.downloadProgress.total === download.downloadProgress.downloaded ? '- Complete' : '' }}
    </div>
    <ng-template #notStarted>
      <div>
        Downloading
      </div>
    </ng-template>
    <mat-progress-bar
      [mode]="download?.downloadProgress ? 'determinate' : 'buffer'"
      [value]="download?.downloadProgress ? download.downloadProgress.progress : 0"
    >
    </mat-progress-bar>
  </app-dialog-content>
  <app-dialog-actions>
    <button
      mat-raised-button
      (click)="cancel = true"
      *ngIf="!download?.downloadProgress || download.downloadProgress.total !== download.downloadProgress.downloaded"
    >
      Cancel
    </button>
  </app-dialog-actions>
</ng-container>
