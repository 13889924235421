import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '@app/app.constants';
import { UserJobModel } from '@common/models/user-job.model';
import { HttpResourceService } from '@main/http-resource.service';
import { Page } from '@common/models/page.model';

@Injectable()
export class UserJobService {
  constructor(
    private http: HttpClient,
    private httpResource: HttpResourceService
  ) {}

  search(filterString: string, pageIndex = 0, pageSize = 0, sort: string[] = []): Observable<Page<UserJobModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'user-job',
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  delete(id: number): Observable<UserJobModel> {
    return this.http.delete(`${SERVER_API_URL}/user-job/${id}`);
  }

  downloadUserJobFile(id: number, fileName: string): Observable<Blob> {
    const path = `user-job/${id}/stream`;
    return this.httpResource.downloadFileWithoutOrg({ path }, { fileName });
  }
}
