import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { buildFilterString } from '@data-table-lib/data-table.utils';
import { KpiModel } from '@shared/components/kpis/kpi.model';
import { faAnglesRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { PopoverService } from '@shared/components/popover/popover.service';

@Component({
  selector: 'app-splash-kpi',
  templateUrl: './splash-kpi.component.html',
  styleUrls: ['./splash-kpi.component.scss']
})
export class SplashKpiComponent implements OnChanges {
  @Input() kpiDef: KpiModel;
  @Input() data;
  filter;
  queryData;
  loading = true;
  active: boolean;
  showInfo: boolean;
  labelColorClass: string;
  valueColorClass: string;
  bgColorClass: string;
  faAnglesRight = faAnglesRight;
  infoIcon = faInfoCircle;

  constructor(private popoverService: PopoverService) {}

  ngOnChanges({ kpiDef, data }: SimpleChanges): void {
    if (kpiDef && kpiDef.currentValue) {
      this.filter = this.kpiDef.query ? buildFilterString(this.kpiDef.query.bricks) : undefined;
      this.queryData = {
        query: this.kpiDef.query ? JSON.stringify(this.kpiDef.query.bricks) : ''
      };
      this.showInfo = !!kpiDef.currentValue.infoDialogContent;
      this.labelColorClass = kpiDef.currentValue.labelColorClass || 'color-dark-text';
      this.valueColorClass = kpiDef.currentValue.valueColorClass || 'color-dark-text';
      this.bgColorClass = kpiDef.currentValue.bgColorClass || 'bg-extra-light-gray';
    }

    if (data?.currentValue !== null) {
      this.loading = false;
    }
  }

  labelFn(def, data) {
    return def.calculatedLabelDisplayFn ? def.calculatedLabelDisplayFn(data) : def.label;
  }

  valueFn(def, data) {
    const value = def.calculatedValueDisplayFn ? def.calculatedValueDisplayFn(data) : data;
    return value === null || value === undefined ? 0 : value;
  }

  openInfoPopover(target: ElementRef) {
    this.popoverService.openMessage(
      target,
      { label: this.kpiDef.infoDialogLabel, message: this.kpiDef.infoDialogContent },
      { panelClass: 'kpi-popover-dialog' }
    );
  }
}
