import { NgModule } from '@angular/core';
import { FileListComponent } from '@file-upload-lib/components/file-list/file-list.component';
import { SharedModule } from '@shared/shared.module';
import { ImportCsvDialogComponent } from '@file-upload-lib/components/import-csv-dialog/import-csv-dialog.component';
import { CsvColumnMapperComponent } from '@file-upload-lib/components/import-csv-dialog/csv-column-mapper/csv-column-mapper.component';
import { CsvErrorComponent } from '@file-upload-lib/components/import-csv-dialog/csv-error/csv-error.component';
import { CsvImportMappingComponent } from '@file-upload-lib/components/import-csv-dialog/csv-import-mapping/csv-import-mapping.component';
import { ScrollIndicatorModule } from '@common/components/scroll-indicator/scroll-indicator.module';
import { FileLevelFundedInfoComponent } from '@file-upload-lib/components/file-level-funded-info/file-level-funded-info';
import { FileListConciseComponent } from './components/file-list-concise/file-list-concise.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { FileUploadWithOptionsDialogComponent } from './components/file-upload-with-options-dialog/file-upload-with-options-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { FileSelectDirective } from './directives/file-select.directive';

const exportItems = [
  FileLevelFundedInfoComponent,
  FileUploadComponent,
  FileDropDirective,
  FileSelectDirective,
  FileListConciseComponent,
  FileListComponent,
  ImportCsvDialogComponent,
  CsvColumnMapperComponent,
  CsvErrorComponent,
  CsvImportMappingComponent
];
const entryItems = [FileUploadDialogComponent, FileUploadWithOptionsDialogComponent];

@NgModule({
  imports: [SharedModule, ScrollIndicatorModule],
  declarations: [...exportItems, ...entryItems],
  exports: [...exportItems]
})
export class FileUploadLibModule {}
