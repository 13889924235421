import { InjectionToken } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { LabelValue } from '@lib-resource/label-value.model';

export abstract class AsyncOptions<T = any> {
  // This is how the form builder finds appropriate service
  abstract key: string;
  labelKey = 'name';
  valueKey = 'id';
  // Get values from server; ?? First call
  // search: (query?, pageIndex?, pageSize?, sort?) => Observable<LabelValue<string, v>[]>;
  // Filter values, maybe from server, maybe not. Can be same as search for some cases
  abstract filter: (
    control: AbstractControl,
    asyncOptionDeps: string[],
    query?,
    pageIndex?: number,
    pageSize?: number,
    sort?: string[],
    requiredQuery?: string,
    asyncExtras?: any
  ) => Observable<{
    content: LabelValue<string, T>[];
    total: number;
  }>;

  // Get LabelValue objects for given array of keys
  abstract valuesFromKeys(values: any, multi?: boolean, asyncExtras?: any): Observable<LabelValue<string, T>[]>;

  generateLabelValues(items: any[], useModelAsValue: boolean = false) {
    if (!items?.length) return [];
    return items.map((val) => ({
      label: val[this.labelKey],
      value: useModelAsValue ? val : val[this.valueKey]
    }));
  }

  combineFilters(filterValue: string, requiredFilter?: string) {
    let filterString = filterValue;
    if (requiredFilter && filterValue) {
      filterString = `(${requiredFilter}) and (${filterValue})`;
    } else if (requiredFilter) {
      filterString = requiredFilter;
    } else if (filterValue) {
      filterString = filterValue;
    }
    return filterString;
  }
}

export const ASYNC_OPTIONS = new InjectionToken<AsyncOptions<any>>('ASYNC_OPTIONS');
