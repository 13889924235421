import { Injectable } from '@angular/core';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { TagService } from '@configuration/admin/tag-list/tag.service';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { of } from 'rxjs';

function tagToLabelValue(tagCodes: TagModel[]): LabelValue<string, TagModel>[] {
  return tagCodes.map((t) => ({
    label: `${t.name}`,
    value: t
  }));
}

@Injectable()
export class TagOptionService extends AsyncOptions<TagModel> {
  key = 'tags';

  constructor(private tagService: TagService) {
    super();
  }

  generateLabelValues = tagToLabelValue;

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.tagService.search(this.combineFilters(`name ~ '${value}'`, requiredQuery), pageIndex, pageSize, ['name']).pipe(
      map((pagedTags) => ({
        content: this.generateLabelValues(pagedTags.content),
        total: pagedTags.total
      }))
    );

  valuesFromKeys = (values: TagModel[], multi) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ (${values.map((v) => `'${v.id}'`).join(',')})` : `id ~ ${values}`;
    return this.tagService.search(filter, 0, 1000, ['name']).pipe(map((result) => tagToLabelValue(result.content)));
  };
}
