import { Pipe, PipeTransform } from '@angular/core';
import { FileDocType } from '@file-upload-lib/file.model';

@Pipe({
  name: 'docTypeMapping'
})
export class DocTypeMappingPipe implements PipeTransform {
  private typeMap = new Map<FileDocType, string>();

  constructor() {
    this.typeMap.set(FileDocType.ACCIDENT, 'Accident');
    this.typeMap.set(FileDocType.AGG_REPORT, 'Aggregate Report');
    this.typeMap.set(FileDocType.APPEAL, 'Appeal');
    this.typeMap.set(FileDocType.CLAIM_COPY, 'Claim Copy');
    this.typeMap.set(FileDocType.CLAIM_HISTORY, 'Claim History');
    this.typeMap.set(FileDocType.COBRA_MEMBER_LIST, 'COBRA Member List');
    this.typeMap.set(FileDocType.CONTRACT, 'Contract');
    this.typeMap.set(FileDocType.CURRENT_RENEWAL_RATES, 'Current/Renewal Rates');
    this.typeMap.set(FileDocType.DISCLOSURE, 'Claims History');
    this.typeMap.set(FileDocType.DISCLOSURE_FORM, 'Disclosure Info/Form');
    this.typeMap.set(FileDocType.EOR, 'EOR');
    this.typeMap.set(FileDocType.FLAGGED_MEMBERS, 'Flagged Members');
    this.typeMap.set(FileDocType.FULL_BENEFITS_GRIDS, 'Full Benefits Grids');
    this.typeMap.set(FileDocType.IMAGE, 'Image');
    this.typeMap.set(FileDocType.IN_FORCE_RATE, 'In-force Rate');
    this.typeMap.set(FileDocType.ITEMIZED_BILL, 'Itemized Bill');
    this.typeMap.set(FileDocType.LARGE_CLAIM_EXPERIENCE, 'Large Claim Experience');
    this.typeMap.set(FileDocType.LASER, 'Case Management');
    this.typeMap.set(FileDocType.LDD_LIST, 'LDD List');
    this.typeMap.set(FileDocType.MEQ, 'Multiple Employer Questionnaire (MEQ)');
    this.typeMap.set(FileDocType.OTHER, 'Other');
    this.typeMap.set(FileDocType.PARTICIPANT, 'Census');
    this.typeMap.set(FileDocType.PENDED_CLAIM_REPORT, 'Pended Claim Report');
    this.typeMap.set(FileDocType.PLAN_DESIGN, 'Plan Design');
    this.typeMap.set(FileDocType.PRE_CERT, 'Pre-Certification');
    this.typeMap.set(FileDocType.PROOF_OF_INS, 'Proof of Insurance');
    this.typeMap.set(FileDocType.PROPOSAL, 'Proposal');
    this.typeMap.set(FileDocType.PROPOSAL_TEMPLATE, 'Financial Exhibit Template');
    this.typeMap.set(FileDocType.SDL_LIST, 'Specialty Drug List');
    this.typeMap.set(FileDocType.TRIGGER_DIAG_REPORT, 'Trigger Diagnosis Report');
    this.typeMap.set(FileDocType.WORKSTATUS, 'Work Status');
  }

  transform(docType: any): any {
    return this.typeMap.has(docType) ? this.typeMap.get(docType) : docType;
  }
}
