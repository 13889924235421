import { UserModel } from '@auth/models/user.model';

export namespace User {
  export class Get {
    static readonly type = '[App] Get User';
  }

  export class GetFailure {
    static readonly type = '[App] Get User Failure';
    constructor(public error: any) {}
  }

  export class GetSuccess {
    static readonly type = '[App] Get User Success';
    constructor(public user: UserModel) {}
  }

  export class Reset {
    static readonly type = '[App] Reset User Success';
  }
}
