import { AdditionalFeeModel, FeeRecurrence } from '@common/models/quote-response.model';

export class AdditionalFeeUiModel {
  name?: string;
  type?: string;
  recurrence?: FeeRecurrence;
  options?: AdditionalFeeModel[];

  constructor(name?: string, recurrence: FeeRecurrence = FeeRecurrence.PEPM) {
    this.name = name;
    this.recurrence = recurrence;
    this.options = [];
  }
}
