import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SERVER_API_URL } from '@app/app.constants';
import { CsvImportModel, ImportTemplateModel, ImportType } from '@common/models/csv-import.model';
import { ImportResultModel } from '@common/models/import-result.model';
import { Page } from '@common/models/page.model';
import { HttpResourceService } from '@main/http-resource.service';
import { DisclosureModel } from '@common/models/disclosure.model';
import { getValueByDef } from '@lib-resource/data.utils';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { columnDefs } from '@common/components/quote/disclosure-list/disclosures-list.definitions';
import { DataFormatter } from '@lib-resource/data.formatter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlUtilService } from './url-util.service';

@Injectable({
  providedIn: 'root'
})
export class DisclosureService {
  get baseUrl() {
    return `${SERVER_API_URL}/quote-request`;
  }

  constructor(
    protected http: HttpClient,
    private urlUtil: UrlUtilService,
    private httpResource: HttpResourceService,
    private fileDownloadService: FileDownloadService
  ) {}

  downloadDisclosuresCsv(quoteRequestId: number, fileName: string): void {
    this.fileDownloadService.csvDownloadProgress(
      fileName,
      columnDefs.map((def) => def.label),
      (page) =>
        this.searchQuoteRequestDisclosures(quoteRequestId, '', page, 1000, ['id']).pipe(
          map((page) => ({
            total: page.total,
            page: page.page,
            pageSize: page.pageSize,
            content: page.content.map((c) =>
              columnDefs.map((def) => DataFormatter.transform(getValueByDef(c, def), def))
            )
          }))
        )
    );
  }

  searchQuoteRequestDisclosures(
    quoteRequestId: number,
    query: string,
    page: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<DisclosureModel>> {
    return this.http.get(`${this.baseUrl}/${quoteRequestId}/disclosure`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  searchDisclosures(quoteRequestId: number, filterString: string, pageIndex: number, pageSize: number, sort: string[]) {
    return this.httpResource.queryWithoutOrg<Page<DisclosureModel>>({
      path: `quote-request/${quoteRequestId}/disclosure`,
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  uploadCsv(
    quoteRequestId: number,
    file: File,
    overwrite: boolean,
    csvModel: CsvImportModel
  ): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    formData.append('fieldmap', new Blob([JSON.stringify(csvModel)], { type: 'application/json' }));
    formData.append('overwrite', overwrite ? 'true' : 'false');

    return this.http.post<ImportResultModel>(`${this.baseUrl}/${quoteRequestId}/disclosure/import`, formData);
  }

  saveImportMappingTemplate(template: ImportTemplateModel) {
    return this.http.post<ImportTemplateModel>(`${SERVER_API_URL}/import-mapping-template`, {
      ...template,
      importType: ImportType.DISCLOSURE
    });
  }

  getImportMappingTemplates() {
    let params = new HttpParams();
    params = params.append('importType', ImportType.DISCLOSURE);
    return this.http.get<ImportTemplateModel[]>(`${SERVER_API_URL}/import-mapping-template`, {
      params
    });
  }
}
