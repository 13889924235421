import { Injectable } from '@angular/core';
import { RouterSelectors } from '@app/store-utils/router.selectors';
import { encodeQueryParam } from '@app/tools/url';
import { Workspace } from '@main/store/workspace/workspace.actions';
import { WorkspaceStateModel } from '@main/store/workspace/workspace.model';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext, Store } from '@ngxs/store';

const workspaceStateModelDefault: WorkspaceStateModel = {
  filters: {}
};
@State<WorkspaceStateModel>({
  name: 'workspace',
  defaults: workspaceStateModelDefault
})
@Injectable()
export class WorkspaceState {
  constructor(private store: Store) {}

  @Action(Workspace.Reset)
  resetWorkspace(ctx: StateContext<WorkspaceStateModel>) {
    ctx.setState(workspaceStateModelDefault);
  }

  @Action(Workspace.ToggleFilter)
  toggleFilter(ctx: StateContext<WorkspaceStateModel>, action: Workspace.ToggleFilter) {
    const queryParamsState = this.store.selectSnapshot(RouterSelectors.queryParams);
    const serializedWorkspace = queryParamsState?.workspace;
    const workspaceState = serializedWorkspace ? JSON.parse(serializedWorkspace) : {};
    const newFilterSet = workspaceState.filters ? { ...workspaceState.filters } : {};

    if (!newFilterSet[action.payload.rowName]) {
      newFilterSet[action.payload.rowName] = [];
    }

    const filters = {
      ...newFilterSet,
      [action.payload.rowName]: addOrRemove(newFilterSet[action.payload.rowName], action.payload.filterString)
    };
    const queryParams = encodeQueryParam({
      workspace: {
        filters: filters,
        searchValue: workspaceState.searchValue
      }
    });
    this.store.dispatch(new Navigate([], queryParams, { replaceUrl: true }));
  }

  @Action(Workspace.ResetFilters)
  resetFilters(ctx: StateContext<WorkspaceStateModel>) {
    const queryParams = encodeQueryParam({
      workspace: {}
    });
    this.store.dispatch(new Navigate([], queryParams, { replaceUrl: true }));
  }

  @Action(Workspace.SetSearchValue)
  setSearchValue(ctx: StateContext<WorkspaceStateModel>, action: Workspace.SetSearchValue) {
    if (ctx.getState().searchValue !== action.value) {
      const queryParamsState = this.store.selectSnapshot(RouterSelectors.queryParams);
      const serializedWorkspace = queryParamsState?.workspace;
      const state = serializedWorkspace ? JSON.parse(serializedWorkspace) : {};

      const queryParams = encodeQueryParam({
        workspace: {
          filters: state.filters,
          searchValue: action.value
        }
      });

      this.store.dispatch(new Navigate([], queryParams, { replaceUrl: true }));
    }
  }

  @Action(Workspace.SetSelectedItem)
  setSelectedItem(ctx: StateContext<WorkspaceStateModel>, action: Workspace.SetSelectedItem) {
    ctx.patchState({ selectedItem: action.item });
  }
}

function addOrRemove(array, value) {
  const index = array.indexOf(value);

  if (index === -1) {
    return [...array, value];
  }
  return array.filter((val) => val !== value);
}
