<ng-container *ngIf="file$ | async as download">
  <app-dialog-label>{{ this.data.filename ? 'Downloading' : 'Opening' }} File</app-dialog-label>
  <app-dialog-content>
    <div>
      {{ download.loadedBytes | fileSize }} <ng-container *ngIf="download.total">of</ng-container>
      {{ download.total | fileSize }} {{ download.state === 'DONE' ? '- Complete' : '' }}
    </div>
    <mat-progress-bar
      [mode]="download.state == 'PENDING' || !download.total ? 'buffer' : 'determinate'"
      [value]="download.progress"
    >
    </mat-progress-bar>
  </app-dialog-content>
  <app-dialog-actions>
    <button mat-raised-button (click)="cancel = true" *ngIf="download.state !== 'DONE'">Cancel</button>
  </app-dialog-actions>
</ng-container>
