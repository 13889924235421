export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_ADMIN = 'ROLE_ADMIN'
}

export enum ProductRole {
  INTAKE = 'INTAKE',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
  NONE = 'NONE'
}

export class UserModel {
  constructor(
    public id?: number,
    public login?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public langKey?: string,
    public imageUrl?: string,
    public enabled?: boolean,
    public activated?: boolean,
    public authorizations?: UserRole[],
    public createdDate?: string,
    public lastModifiedDate?: string,
    public lockedDate?: string,
    public title?: string,
    public phone?: string,
    public idpUserName?: string,
    public identityProviderId?: number,
    public lastApiKeyChangeDate?: string
  ) {}
}

export class ActivateUserModel {
  constructor(
    public key?: string,
    public linkId?: string,
    public password?: string,
    public firstName?: string,
    public lastName?: string,
    public title?: string,
    public phone?: string
  ) {}
}

export class UserMfaConfigModel {
  constructor(public key: string, public base64QrCode: string) {}
}
