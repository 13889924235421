import { FileModel } from '@file-upload-lib/file.model';
import { QuoteResponseEventType } from '@common/models/quote-response-event.model';
import { ContingencyModel } from '@quote/quote-response/quote-response-stepper/contingencies/contingency.model';

export enum QuoteLinqVersionStatusType {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED'
}
export class QuoteLinqVersionModel {
  id?: number;
  versionId?: number;
  quoteResponseId?: number;
  contingencyVersions?: ContingencyModel[];
  rmtFile?: FileModel;
  createdDate?: string;
  lastModifiedDate?: string;
  versionStatus?: QuoteLinqVersionStatusType;
  versionData: any;
  createdEvent: QuoteResponseEventType;
}
