import { Injectable } from '@angular/core';

import { AccountModel } from '@common/models/account.model';
import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable()
export class AccountOptionService extends AsyncOptions<number> {
  key = 'accounts';
  defaultSort = ['name'];

  constructor(private accountService: AccountService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.accountService
      .searchUnfiltered(this.generateFilterString(value, requiredFilter), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.genLabelVals(content), total })));

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.accountService
      .searchUnfiltered(filter, 0, 100, this.defaultSort)
      .pipe(map((result) => this.genLabelVals(result.content)));
  };

  private generateFilterString = (value, requiredQuery?) => {
    let filterString = value ? `name ~ '${value}'` : '';
    return this.combineFilters(filterString, requiredQuery);
  };

  genLabelVals(accounts: AccountModel[]): { label: any; value: any }[] {
    return accounts.map((a) => ({
      label: `${a.name} (ID: ${a.id})`,
      value: `${a.id}`
    }));
  }
}
