<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label>Importing Help</app-dialog-label>
<app-dialog-content>
  <a
    download="sample-upload-participants.csv"
    class="download"
    mat-button
    color="primary"
    target="_blank"
    href="/assets/sample-files/sample-participants.csv"
  >
    <mat-icon fontSet="fas" fontIcon="fa-file-download" color="primary"></mat-icon>
    Download Sample File
  </a>
  <p>
    Ringmaster products have the capability to upload member and claim history information from your local hard drive.
    Claims History can be uploaded via *.CSV files and Census information can be uploaded via *.CSV and *.x12 files.
  </p>
  <p>
    Once a file has been chosen, you are able to assign columns to specific fields in the system. Any columns that you
    want to ignore, either leave the dropdown blank or choose "-- IGNORE --".
  </p>
  <p>
    "First Name", "Last Name", "Date of Birth", and "Gender" are all required fields for Participant imports. You must
    have those fields selected in order to successfully import a CSV file.
  </p>
  <p>
    Certain fields do require information to be set up in specific formats. If you set a column to be one of these
    required fields and the data is not in the correct format, you will be presented with a page to make changes.
  </p>
  <p>Examples of these fields include:</p>
  <ul>
    <li>
      Fields for Relationship need to contain only the fields "SELF", "SPOUSE", "CHILD", "EX_SPOUSE", "LIFE_PARTNER".
    </li>
    <li>Fields for Employment Status need to contain only the fields "ACTIVE", "LEAVE", "RETIRED", "TERMINATED".</li>
    <li>
      Fields for Coverage Types need to contain only the fields "EMPLOYEE", "EMPLOYEE_SPOUSE", "FAMILY",
      "EMPLOYEE_CHILDREN", "EMPLOYEE_DEP", "EMPLOYEE_CHILD", "WAIVED".
    </li>
    <li>Fields for Gender need to contain only the fields "MALE", "FEMALE", "OTHER".</li>
    <li>
      Date fields (Date of Birth, Date of Hire, etc.) need to be in the format "{{ date | date: 'MM/dd/yyyy' }}" or "{{
        date | date: 'MM-dd-yyyy'
      }}".
    </li>
  </ul>
</app-dialog-content>
<app-dialog-actions>
  <button mat-raised-button mat-dialog-close>Close</button>
</app-dialog-actions>
