import { Injectable } from '@angular/core';
import { OrgFunction } from '@common/models/org-type.model';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgHttpService } from '@main/org/org-http.service';
import { OrgModel } from '@main/org/models/org.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PbmOrgOptionService extends AsyncOptions<number> {
  key = 'pbmOrg';
  defaultSort = ['name'];

  pbmOrgTypeQuery = `orgtype.orgfunction = '${OrgFunction.PBM}'`;

  constructor(private orgHttpService: OrgHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) => {
    requiredQuery = requiredQuery ? `(${requiredQuery}) and ${this.pbmOrgTypeQuery}` : this.pbmOrgTypeQuery;
    return this.orgHttpService
      .searchAllOrgs(this.generateFilterString(value, requiredQuery), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.genLabelVals(content), total })));
  };

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.orgHttpService
      .searchAllOrgs(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.genLabelVals(result.content)));
  };

  private generateFilterString = (value, requiredQuery) =>
    this.combineFilters(value ? `name ~ '${value}'` : '', requiredQuery);

  private genLabelVals(pbmOrgs: OrgModel[]): { label: any; value: number }[] {
    return pbmOrgs.map((a) => ({
      label: `${a.name} (ID: ${a.id})`,
      value: a.id
    }));
  }
}
