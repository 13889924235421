import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { FindRequestModel, StatsRequestModel } from '@common/models/find-request.model';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { QueryModel } from '@data-table-lib/data-table-config-source';
import { selector } from '@lib-resource/operators/selector';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { SiteFilterOptions, SiteFilterStateModel } from '@main/store/site-filter/site-filter.models';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { DateRange } from '@common/models/common.model';
import { getFormattedDateString } from '@app/tools/date';
import { BulkActionModel, BulkActionResultModel } from '@common/models/bulk-action.model';
import { OrgModel } from '@main/org/models/org.model';
import { DownloadRequest, FileDownloadService } from '@shared/components/file-download-progress/file-download.service';

interface ObservedChanges {
  product?;
  [SiteFilterOptions.POLICY]?: number[];
  [SiteFilterOptions.ACCOUNT]?: number[];
  [SiteFilterOptions.PBM]?: number[];
  [SiteFilterOptions.ORG_MEMBERSHIP]?: number[];
  [SiteFilterOptions.EFFECTIVE_DATE_RANGE]?: DateRange;
}

export interface FilterConfig {
  dataKey: string;
  siteFilterKey: SiteFilterOptions;
}

type SiteFilterCallback = (_: ObservedChanges) => string;

interface RequestConfig {
  path: string;
  siteFilterConfig?: FilterConfig[];
  siteFilterCb?: SiteFilterCallback;
}

interface QueryConfig extends RequestConfig {
  query?: QueryModel;
}

interface QueryAggConfig extends RequestConfig {
  findReq?: FindRequestModel;
}

interface QueryStatsConfig extends RequestConfig {
  statsReq?: StatsRequestModel;
}

interface QueryTrendDriverConfig extends RequestConfig {
  period1Query: string;
  period2Query: string;
  period1TotalMembers: number;
  period2TotalMembers: number;
  bottom: boolean;
}

interface BulkActionQueryConfig extends RequestConfig {
  action?: string;
  query?: string;
  ids?: any[];
}

function generateSiteFilter(filterConfigs: FilterConfig[], changes: any): string {
  if (!filterConfigs?.length) return '';
  return filterConfigs
    .filter((config) => {
      if (!changes[config.siteFilterKey]) return false;
      if (config.siteFilterKey === SiteFilterOptions.EFFECTIVE_DATE_RANGE) {
        const dateRange: DateRange = changes[config.siteFilterKey];
        return !!dateRange.startDate && !!dateRange.endDate;
      }
      if (Array.isArray(changes[config.siteFilterKey])) {
        return !!changes[config.siteFilterKey].length;
      }
      return true;
    })
    .map((config) => {
      if (config.siteFilterKey === SiteFilterOptions.EFFECTIVE_DATE_RANGE) {
        const dateRange: DateRange = changes[config.siteFilterKey];
        const startDateStr = !!dateRange.startDate
          ? `${config.dataKey} >= date('${getFormattedDateString(
              dateRange.startDate,
              'yyyy-MM-dd'
            )}', 'America/Chicago')`
          : '';
        const endDateStr = !!dateRange.endDate
          ? `${config.dataKey} <= date('${getFormattedDateString(dateRange.endDate, 'yyyy-MM-dd')}', 'America/Chicago')`
          : '';
        return `${startDateStr} ${startDateStr && endDateStr ? 'and' : ''} ${endDateStr}`;
      }
      if (Array.isArray(changes[config.siteFilterKey])) {
        return `${config.dataKey} ~ (${changes[config.siteFilterKey].map((acc) => acc).join(',')})`;
      }
      return `${config.dataKey} = ${changes[config.siteFilterKey]}`;
    })
    .join(' and ');
}

function buildFilterString(changes, siteFilterConfig, siteFilterCb, filterString) {
  let filter = '';
  if (siteFilterConfig) {
    filter = generateSiteFilter(siteFilterConfig, changes);
  } else if (siteFilterCb) {
    filter = siteFilterCb(changes) || '';
  }
  if (filter && filterString) {
    filter = `( ${filterString} ) and ( ${filter} )`;
  } else if (filterString) {
    filter = filterString;
  }
  return filter;
}

function generateSiteStats(req: StatsRequestModel, filterConfigs: FilterConfig[], changes: any): StatsRequestModel {
  if (!filterConfigs && !filterConfigs.length) return req;
  const reqCopy = { ...req };
  return filterConfigs
    .filter((config) => !!changes[config.siteFilterKey] && !!changes[config.siteFilterKey].length)
    .reduce((acc, config) => {
      const currentChange = changes[config.siteFilterKey] ? changes[config.siteFilterKey] : [];
      if (config.siteFilterKey === SiteFilterOptions.POLICY) {
        const policyIds = acc.policyIds ? acc.policyIds : [];
        acc.policyIds = [...policyIds, ...currentChange];
      } else if (config.siteFilterKey === SiteFilterOptions.ACCOUNT) {
        const accIds = acc.accountIds ? acc.accountIds : [];
        acc.accountIds = [...accIds, ...currentChange];
      }
      return acc;
    }, reqCopy);
}

@Injectable({
  providedIn: 'root'
})
export class HttpResourceService {
  private urlUtil = new UrlUtilService();
  private _siteFilters$: Observable<SiteFilterStateModel> = this.store.select(SiteFilterSelectors.siteFilters);
  product$ = this.store.select(ProductSelectors.selectedProduct);
  orgMembership$ = this._siteFilters$.pipe(selector(SiteFilterOptions.ORG_MEMBERSHIP));
  account$ = this._siteFilters$.pipe(selector(SiteFilterOptions.ACCOUNT));
  policy$ = this._siteFilters$.pipe(selector(SiteFilterOptions.POLICY));
  effectiveDate$ = this._siteFilters$.pipe(selector(SiteFilterOptions.EFFECTIVE_DATE_RANGE));
  underwriter$ = this._siteFilters$.pipe(selector(SiteFilterOptions.UNDERWRITER));
  pbm$ = this._siteFilters$.pipe(selector(SiteFilterOptions.PBM));

  constructor(
    private store: Store,
    protected http: HttpClient,
    private fileDownloadService: FileDownloadService
  ) {}

  // Only get the one request and complete observable; COLD
  get<T = any>(path: string, orgId?: number): Observable<T> {
    return this.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.get<T>(`${SERVER_API_URL}/org/${!!orgId ? orgId : orgIds[0]}/${path}`))
    );
  }

  // Only get the one request and complete observable, a cold observable is returned.  Allow the passing of http params.
  getWithOptions<T = any>(
    path: string,
    options: {
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
    },
    orgId?: number
  ): Observable<T> {
    return this.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.get<T>(`${SERVER_API_URL}/org/${!!orgId ? orgId : orgIds[0]}/${path}`, options))
    );
  }

  getWithoutOrg<T = any>(path: string): Observable<T> {
    return this.http.get<T>(`${SERVER_API_URL}/${path}`);
  }

  post<T = any>(
    path: string,
    data,
    orgId?: number,
    options?: {
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
    }
  ): Observable<T> {
    return this.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) =>
        this.http.post<T>(`${SERVER_API_URL}/org/${!!orgId ? orgId : orgIds[0]}/${path}`, data, options)
      )
    );
  }

  postWithoutOrg<T = any>(path: string, data): Observable<T> {
    return this.http.post<T>(`${SERVER_API_URL}/${path}`, data);
  }

  put<T = any>(path: string, data?, orgId?: number): Observable<T> {
    return this.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.put<T>(`${SERVER_API_URL}/org/${!!orgId ? orgId : orgIds[0]}/${path}`, data))
    );
  }

  putWithoutOrg<T = any>(path: string, data?): Observable<T> {
    return this.http.put<T>(`${SERVER_API_URL}/${path}`, data);
  }

  putBulkActionQuery<T = BulkActionResultModel>({
    path,
    query,
    action,
    siteFilterConfig,
    siteFilterCb
  }: BulkActionQueryConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes: ObservedChanges) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, query);
        const orgIds = changes[SiteFilterOptions.ORG_MEMBERSHIP];
        return this.http.put<T>(
          `${SERVER_API_URL}/org/${orgIds[0]}/${path}`,
          new BulkActionModel(action, null, filterString)
        );
      }),
      take(1)
    );
  }

  putBulkActionQueryWithoutOrg<T = BulkActionResultModel>({
    path,
    query,
    ids,
    action,
    siteFilterConfig,
    siteFilterCb
  }: BulkActionQueryConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes: ObservedChanges) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, query);
        return this.http.put<T>(`${SERVER_API_URL}/${path}`, new BulkActionModel(action, ids, filterString));
      }),
      take(1)
    );
  }

  delete<T = any>(path: string, orgId?: number): Observable<T> {
    return this.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.delete<T>(`${SERVER_API_URL}/org/${!!orgId ? orgId : orgIds[0]}/${path}`))
    );
  }

  deleteWithoutOrg<T = any>(path: string): Observable<T> {
    return this.http.delete<T>(`${SERVER_API_URL}/${path}`);
  }

  query<T = Page<any>>({ path, query, siteFilterConfig, siteFilterCb }: QueryConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes: ObservedChanges) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, query.filterString);
        const orgIds = changes[SiteFilterOptions.ORG_MEMBERSHIP];
        return this.http.get<T>(`${SERVER_API_URL}/org/${orgIds[0]}/${path}`, {
          params: this.urlUtil.buildSearchParams(filterString, query.pageIndex, query.pageSize, query.sort, query.sid)
        });
      })
    );
  }

  queryWithoutOrg<T = Page<any>>({ path, query, siteFilterConfig, siteFilterCb }: QueryConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, query.filterString);
        return this.http.get<T>(`${SERVER_API_URL}/${path}`, {
          params: this.urlUtil.buildSearchParams(filterString, query.pageIndex, query.pageSize, query.sort, query.sid)
        });
      })
    );
  }

  queryAgg<T = Page<any>>({ path, findReq, siteFilterConfig, siteFilterCb }: QueryAggConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, findReq.query);
        const orgIds = changes[SiteFilterOptions.ORG_MEMBERSHIP];
        return this.http.put<T>(`${SERVER_API_URL}/org/${orgIds[0]}/${path}`, {
          ...findReq,
          query: filterString
        });
      })
    );
  }

  queryAggWithoutOrg<T = Page<any>>({ path, findReq, siteFilterConfig, siteFilterCb }: QueryAggConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes) => {
        const filterString = buildFilterString(changes, siteFilterConfig, siteFilterCb, findReq.query);
        return this.http.put<T>(`${SERVER_API_URL}/${path}`, {
          ...findReq,
          query: filterString
        });
      })
    );
  }

  queryStats<T = any>({ path, statsReq, siteFilterConfig, siteFilterCb }: QueryStatsConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes) => {
        const orgIds = changes[SiteFilterOptions.ORG_MEMBERSHIP];
        const siteStats = generateSiteStats(statsReq, siteFilterConfig, changes);
        const params = this.urlUtil.buildStatsRequest(siteStats);
        return this.http.get<T>(`${SERVER_API_URL}/org/${orgIds[0]}/${path}`, { params });
      })
    );
  }

  queryTrendDrivers<T = any>({
    path,
    period1Query,
    period2Query,
    period1TotalMembers,
    period2TotalMembers,
    bottom,
    siteFilterConfig,
    siteFilterCb
  }: QueryTrendDriverConfig): Observable<T> {
    const changes$ = this._changesToTrack(siteFilterConfig, siteFilterCb);
    return changes$.pipe(
      mergeMap((changes) => {
        const period1Filter = buildFilterString(changes, siteFilterConfig, siteFilterCb, period1Query);
        const period2Filter = buildFilterString(changes, siteFilterConfig, siteFilterCb, period2Query);
        const params = this.urlUtil.buildParamsFromObject({
          period1Query: period1Filter,
          period2Query: period2Filter,
          period1TotalMembers: period1TotalMembers,
          period2TotalMembers: period2TotalMembers,
          bottom: bottom
        });
        return this.http.get<T>(`${SERVER_API_URL}/${path}`, { params });
      })
    );
  }

  downloadFile({ path }: QueryConfig, downloadRequest: DownloadRequest) {
    const changes$ = this._changesToTrack();
    return changes$.pipe(
      take(1),
      mergeMap((changes) => {
        const orgIds = changes[SiteFilterOptions.ORG_MEMBERSHIP];
        return this.fileDownloadService.downloadFile(`${SERVER_API_URL}/org/${orgIds[0]}/${path}`, downloadRequest);
      })
    );
  }

  downloadFileWithoutOrg({ path }: QueryConfig, downloadRequest: DownloadRequest) {
    return this.fileDownloadService.downloadFile(`${SERVER_API_URL}/${path}`, downloadRequest);
  }

  // Private methods
  private _mapStream(key: SiteFilterOptions): Observable<number[]> | Observable<DateRange> | Observable<OrgModel[]> {
    switch (key) {
      case SiteFilterOptions.ACCOUNT:
        return this.account$;
      case SiteFilterOptions.POLICY:
        // Because certain code expects entire policy object
        return this.policy$.pipe(map((policies) => (policies ? policies.map((policy) => policy.id) : undefined)));
      case SiteFilterOptions.EFFECTIVE_DATE_RANGE:
        return this.effectiveDate$;
      case SiteFilterOptions.UNDERWRITER:
        return this.underwriter$;
      case SiteFilterOptions.PBM:
        return this.pbm$;
      default:
        return null;
    }
  }

  private _changesToTrack(
    siteFilterConfig?: FilterConfig[],
    siteFilterCb?: SiteFilterCallback
  ): Observable<ObservedChanges> {
    let changesStream: Observable<ObservedChanges> = combineLatest([this.orgMembership$, this.product$]).pipe(
      map(([orgIds, product]) => ({ [SiteFilterOptions.ORG_MEMBERSHIP]: orgIds, product }))
    );
    if (siteFilterCb) {
      changesStream = combineLatest([
        changesStream,
        this.account$,
        this.policy$.pipe(map((items) => items.map((item) => item.id)))
      ]).pipe(map(([changes, account, policy]) => ({ ...changes, account, policy })));
    } else if (siteFilterConfig) {
      siteFilterConfig.forEach((config) => {
        const newStream = this._mapStream(config.siteFilterKey);
        if (!newStream) return;
        changesStream = combineLatest([changesStream, newStream]).pipe(
          map(([changes, filter]) => ({ ...changes, [config.siteFilterKey]: filter }))
        );
      });
    }
    // Since the site filters use different streams to represent the changes coming in
    //  for different resources, combine latest fires multiple times for a single change. This
    //  code should be refactored to represent the filters as a single filter object with a memoization
    //  strategy applied to the various slices that queries can then watch out for changes on.
    //  This debounce is a temp work around.
    //  The debounce dueTime adds a little time for extra site filters to be set before firing changes, especially important on initial load
    //  the value is based on scientific anecdotal localized testing
    return changesStream.pipe(debounceTime(300));
  }
}
