function layoutCalc(value, basis) {
  const layoutValue = (value / basis) * 100;
  return layoutValue > 100 ? 100 : layoutValue;
}

export function layoutGenerator(field) {
  if (!field.layout) {
    field.layout = {};
  }
  if (!field.layout.base) {
    field.layout.base = 3;
  }
  field.calculatedLayout = {
    gtSm: `calc(${layoutCalc(field.layout.base, 12)}% - 10px)`,
    sm: `calc(${layoutCalc(field.layout.base, 8)}% - 10px)`,
    xs: `calc(${layoutCalc(field.layout.base, 4)}% - 10px)`
  };
  return field;
}

export class LayoutConfig {
  base?: number;
}

export class GeneratedLayout {
  gtSm: number;
  sm: number;
  xs: number;
}

const NUMBER_OF_COLUMNS = 12;

function spanCalc(value: number, basis: number) {
  const calculatedValue = Math.round((value / basis) * NUMBER_OF_COLUMNS);
  if (calculatedValue > NUMBER_OF_COLUMNS) {
    return NUMBER_OF_COLUMNS;
  }

  if (calculatedValue < 1) {
    return 1;
  }

  return calculatedValue;
}

export function generateLayout(layout?: LayoutConfig): GeneratedLayout {
  if (!layout || !layout.base) {
    layout = {
      base: 3
    };
  }

  return {
    gtSm: layout.base,
    sm: spanCalc(layout.base, 8),
    xs: spanCalc(layout.base, 4)
  };
}
