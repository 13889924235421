import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { toObject } from '@app/tools/array';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { NotificationService } from '@shared/notifications/notification.service';

class FormErrorModel {
  group: string;
  errors: FormErrors;
}

type FormErrors = Array<FormErrorModel | string>;

@Injectable({
  providedIn: 'root'
})
export class FormErrorService {
  constructor(private notificationService: NotificationService) {}

  reportErrors(form: UntypedFormGroup, definitions: DataDefModel[]) {
    const controlKeys = this.getControlsWithErrors(form);
    const controlLabels = this.getLabelsForErrorKeys(definitions, controlKeys);
    this.notificationService.failedNotification(`Missing or invalid information`, controlLabels);
  }

  getControlsWithErrors(form: UntypedFormGroup): FormErrors {
    const controlsWithErrors = [];

    Object.keys(form.controls).forEach((controlKey) => {
      if ((form.controls[controlKey] as UntypedFormGroup).controls) {
        // For FormGroups and FormArrays
        if (!Array.isArray((form.controls[controlKey] as UntypedFormGroup).controls)) {
          // FormGroup
          controlsWithErrors.push({
            group: controlKey,
            errors: this.getControlsWithErrors(form.controls[controlKey] as UntypedFormGroup)
          });
        } else if ((form.controls[controlKey] as UntypedFormArray).controls.length) {
          let errorPresent = false;
          (form.controls[controlKey] as UntypedFormArray).controls.forEach((formGroup) => {
            const arrayErrors = this.getControlsWithErrors(formGroup as UntypedFormGroup);
            if (arrayErrors && arrayErrors.length) {
              errorPresent = true;
            }
          });
          if (errorPresent) {
            controlsWithErrors.push(controlKey);
          }
        }
      } else if (form.controls[controlKey].errors) {
        controlsWithErrors.push(controlKey);
      }
    });

    return controlsWithErrors;
  }

  getLabelsForErrorKeys(definitions: DataDefModel[], errors): string[] {
    const errorDefs = this.getDefsForErrorKeys(definitions, errors);
    return !!errorDefs?.length ? errorDefs.map((def) => def.label) : null;
  }

  getDefsForErrorKeys(definitions: DataDefModel[], errorKeys): DataDefModel[] {
    const errorDefs: DataDefModel[] = [];
    const defsAsObject: { [prop: string]: DataDefModel } = toObject(definitions, 'formKey');
    // let formGroupFieldsAsObject;
    errorKeys.forEach((errorKey) => {
      if (typeof errorKey === 'string') {
        const potentialDateRangeKey =
          errorKey.endsWith('/startDate') || errorKey.endsWith('/endDate')
            ? errorKey.substring(0, errorKey.lastIndexOf('/'))
            : null;

        // if this is a potential date range key then check for a date range type that matches the substring
        // if no daterange field matches then it's a non date range so use the original key
        if (!!potentialDateRangeKey && defsAsObject[potentialDateRangeKey]?.type === DATA_TYPES.dateRange) {
          // check if it's already in the error defs - the daterange fields will show up with the same key twice
          if (!errorDefs.some((def) => def.key === potentialDateRangeKey)) {
            errorDefs.push(defsAsObject[potentialDateRangeKey]);
          }
        } else {
          errorDefs.push(defsAsObject[errorKey]);
        }
      }
    });
    return errorDefs;
  }
}
