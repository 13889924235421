import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'versionAddV'
})
export class VersionAddVPipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    if (value !== null && value !== undefined) {
      return 'v' + value;
    }

    return '';
  }
}
