import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { getDateFromString } from '@app/tools/date';
import { isEmpty } from '@app/tools/string';

/**
 * A directive to allow the input format of mmddyyyy and it will reformat to a date object mm/dd/yyyy on blur of the input field
 */
@Directive({ selector: '[appDateFormatter]' })
export class DateFormatterDirective {
  private dateRegex: RegExp = /^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])([0-9]{4})$/;
  constructor(private ngControl: NgControl) {}

  @HostListener('blur', ['$event'])
  formatToDate(event) {
    // attempt to parse and set the date
    const regexRes = event?.target?.value?.toString()?.match(this.dateRegex);
    if (!this.ngControl.control.value && regexRes?.length > 0) {
      this.ngControl.control.patchValue(getDateFromString(`${regexRes[1]}/${regexRes[2]}/${regexRes[3]}`));
    } else if (!this.ngControl.control.value && !isEmpty(event?.target?.value?.toString())) {
      // there is a value in the field, but it was unable to parse, error
      this.ngControl.control.setErrors({
        matDatepickerParse: 'this.errorMessage'
      });
    }
  }
}
