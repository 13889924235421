<div class="mt-2 collapsible-form dense">
  <form *ngIf="!!form" [formGroup]="form">
    <div *ngIf="!uiAdditionalFees?.length" class="pl-2">No Additional Fees.</div>
    <div formArrayName="additionalFees" class="pb-2">
      <div *ngFor="let feeFormGroup of feeFormGroups; let i = index" class="fee-card mb-4">
        <div class="fee-card__header">
          <div class="fee-card__header-name">Fee Name*</div>
          <app-text-field
            [control]="feeFormGroup.controls['name']"
            [dataDef]="nameDataDef"
            [inlineNoLabel]="true"
            [readOnly]="readOnly"
            [ngClass]="{ 'field-disable': readOnly }"
            style="padding-top: 1.5rem"
          ></app-text-field>
          <div class="fee-card__header-close">
            <button *ngIf="!readOnly" (click)="remove(i)" type="button" mat-icon-button matTooltip="Remove">
              <fa-icon [icon]="removeIcon"></fa-icon>
            </button>
          </div>
          <div class="fee-card__header-name">
            Fee Type
            <fa-icon
              [icon]="infoIcon"
              [matTooltip]="'Use this to group fees'"
              class="info-icon color-rmt-dark-tint-1"
            ></fa-icon>
          </div>
          <app-text-field
            [control]="feeFormGroup.controls['type']"
            [dataDef]="typeDataDef"
            [inlineNoLabel]="true"
            [readOnly]="readOnly"
            [ngClass]="{ 'field-disable': readOnly }"
            style="padding-top: 1.5rem"
          ></app-text-field>
          <div></div>
          <div class="fee-card__header-name">Recurrence*</div>
          <app-select-field
            [control]="feeFormGroup.controls['recurrence']"
            [dataDef]="recurrenceDataDef"
            [inlineNoLabel]="true"
            [readOnly]="readOnly"
            [ngClass]="{ 'field-disable': readOnly }"
            style="padding-top: 1.5rem"
          ></app-select-field>
        </div>
        <div class="p-3">
          <app-additional-fees-array
            [control]="feeFormGroup.controls['options']"
            [definition]="additionalFeesUiOptionDataDef"
            [readOnly]="readOnly"
          ></app-additional-fees-array>
        </div>
      </div>
    </div>
  </form>
</div>
