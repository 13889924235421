import { Injectable } from '@angular/core';
import { ServerInfoService } from '@common/services/server-info.service';
import { NotificationService } from '@shared/notifications/notification.service';
import { parse, ParseResult } from 'papaparse';
import { Observable, of, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';

export enum FileTypes {
  CSV = 'CSV',
  EDI834 = 'EDI834'
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private serverInfoService: ServerInfoService, private notificationService: NotificationService) {}

  toMbs(bytes: number): number {
    return +(bytes / 1024 / 1024).toFixed(2);
  }

  isCsv(file: File): boolean {
    return file && file.name.endsWith('csv');
  }

  getMaxUploadBytes(): number {
    return this.serverInfoService.serverInfoSnapshot.app.maxUploadBytes;
  }

  getMaxUploadMbs(): number {
    return this.toMbs(this.getMaxUploadBytes());
  }

  getMaxDownloadMbs(): number {
    return this.getMaxUploadMbs() * 5;
  }

  getMaxDownloadBytes(): number {
    return this.getMaxUploadBytes() * 5;
  }

  uploadSizeExceeded(bytes: number): boolean {
    return this.getMaxUploadBytes() < bytes;
  }

  downloadSizeExceeded(bytes: number): boolean {
    if (!bytes) return false;
    return this.getMaxDownloadBytes() < bytes;
  }

  showTooLargeError(attemptedBytes: number): void {
    this.notificationService.failedNotification(`
      A file size of ${this.toMbs(attemptedBytes)} MB is too large.
      The server accepts a maximum file size of ${this.getMaxUploadMbs()} MB.
      `);
  }

  zeroByteFileError(): void {
    this.notificationService.failedNotification(`
      A file size of zero bytes is not allowed, check the file and try again.
      `);
  }

  showInvalidTypeError(validFileTypes: FileTypes[]): void {
    const fileTypes = [];
    validFileTypes.forEach((type) => {
      if (type === FileTypes.CSV) {
        fileTypes.push('.csv');
      }
      if (type === FileTypes.EDI834) {
        fileTypes.push('834 x12');
      }
    });
    const result = fileTypes.length > 1 ? fileTypes.join(' or ') : fileTypes[0];
    this.notificationService.failedNotification(`
      File is not a valid type. Try again with a ${result} formatted file.
      `);
  }

  isFileType(file: File, fileType: FileTypes): Observable<boolean> {
    if (!file) return of(false);

    switch (fileType) {
      case FileTypes.CSV:
        return of(this.isCsv(file)).pipe(share());
      case FileTypes.EDI834:
        return this._blobToText(file.slice(0, 1024, 'utf-8')).pipe(
          map((fileHead) => fileHead && fileHead.startsWith('ISA*'), share())
        );
      default:
        return of(false);
    }
  }

  private _blobToText(fileBlob: Blob): Observable<string> {
    const subject = new Subject<string>();
    const reader = new FileReader();
    reader.onload = (e) => {
      const responseText = (<any>e.target).result;
      subject.next(responseText);
      subject.complete();
    };
    reader.readAsText(fileBlob, 'utf-8');
    return subject.asObservable();
  }

  parseCsv(file: File): Observable<string[][]> {
    const fileSource = new Subject<string[][]>();
    parse(file, {
      header: false,
      complete(results: ParseResult<any>): void {
        fileSource.next(results.data);
        fileSource.complete();
      }
    });
    return fileSource.asObservable().pipe(share());
  }

  /**
   * Stream the specified CSV file to fetch the top n rows from the file.
   * Once the top n rows have been read in, the streaming stops and the
   * rows are retuned as an observable.
   * This will typically be used when the UI wants to display the top 5
   * rows of a csv file to the user, so that the columns can be mapped
   * appropriately.
   * The parser will skip empty lines.
   */
  parseTopRowsFromCsv(file: File, numberOfRows: number): Observable<string[][]> {
    let lineNumber: number = 0;
    const csvData: string[][] = [];
    if (!!file) {
      return new Observable((observable) => {
        parse(file, {
          header: false,
          skipEmptyLines: true,
          step(results: ParseResult<any>, parser) {
            if (results.data.length > 0) {
              csvData[lineNumber] = [];
              results.data.forEach((c) => csvData[lineNumber].push(c));
            }
            lineNumber++;
            if (lineNumber >= numberOfRows) {
              parser.abort();
            }
          },
          complete(_: ParseResult<any>): void {
            observable.next(csvData);
            observable.complete();
          }
        });
      });
    }
    return of(null);
  }

  /**
   * Stream the specified csv file and process each row of the file as it is being read in.
   *
   * The parser will skip empty lines
   */
  streamCsv(file: File, processRow: (row: string[], lineNumber: number) => void): Observable<boolean> {
    let lineNumber: number = 0;
    if (!!file) {
      return new Observable((observable) => {
        parse(file, {
          header: false,
          skipEmptyLines: true,
          step(results: ParseResult<any>, parser) {
            processRow(results.data, lineNumber);
            lineNumber++;
          },
          complete(results: ParseResult<any>): void {
            observable.next(true);
            observable.complete();
          }
        });
      });
    }
    return of(false);
  }
}
