import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-all-toolbar',
  template: `
    <ng-container *ngIf="!selectedByQuery && selectedItems?.length > 0">
      <div class="p-2 selected-banner">
        {{ selectedItems?.length }} item{{ selectedItems?.length > 1 ? 's' : '' }} from the page
        {{ selectedItems?.length > 1 ? 'are' : 'is' }} selected.
        <a
          class="color-accent"
          *ngIf="selectedItems?.length !== total && enableSelectByQuery"
          (click)="onSelectByQuery()"
          >Click here to select all {{ total }} items.</a
        >
      </div>
    </ng-container>
    <ng-container *ngIf="selectedByQuery">
      <div class="p-2 selected-banner">
        All {{ total }} item{{ total > 1 ? 's' : '' }} are selected.
        <a class="color-accent" (click)="onClearSelection()">Click here to clear the selection.</a>
      </div>
    </ng-container>
  `,
  styleUrls: ['select-all-toolbar.component.scss']
})
export class SelectAllToolbarComponent {
  @Input() currentPageSize: number;
  @Input() total: number;
  @Input() enableSelectByQuery: boolean = true;
  @Input() selectedByQuery: boolean;
  @Input() selectedItems: any[];
  @Input() actions: any[];
  @Input() fileSize: number;

  @Output() selectByQuery = new EventEmitter();
  @Output() clearSelection = new EventEmitter();

  constructor() {}

  onSelectByQuery() {
    this.selectByQuery.emit();
  }

  onClearSelection() {
    this.clearSelection.emit();
  }
}
