import { Component, DoCheck, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CardModel } from './card.model';

@Component({
  selector: 'app-card',
  template: `
    <section class="rmt-card card-wrapper {{ backgroundColorClass }}" [class.card-wrapper-full]="cardWrapperFull">
      <div class="card-toolbar dense-layout" [style.display]="hasHeaderElements ? 'block' : 'none'">
        <div class="card-header-row">
          <ng-content select="[cardHeaderInfo]"></ng-content>
          <app-card-label *ngIf="label" [icon]="icon" [label]="label"></app-card-label>
          <div #headerRef>
            <ng-content select="[cardHeader]"></ng-content>
          </div>
          <div class="card-spacer"></div>
          <div #headerAdditionsRef class="card-header-addition">
            <ng-content select="[cardHeaderAddition]"></ng-content>
          </div>
        </div>
      </div>
      <div class="card-content">
        <ng-content></ng-content>
      </div>
    </section>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnChanges, DoCheck {
  @Input() section?: CardModel;
  @Input() label?: string;
  @Input() icon?: IconDefinition;
  @Input() iconClasses: string[];
  @Input() cardWrapperFull: boolean = false;
  @Input() backgroundColorClass: string = '';
  @Output() iconClicked = new EventEmitter<void>();
  @ViewChild('headerRef') headerRef;
  @ViewChild('headerAdditionsRef') headerAdditionsRef;
  hasHeaderElements: boolean;

  ngOnChanges() {
    if (this.section) {
      this.label = this.section.label;
      this.icon = this.section.icon;
      this.iconClasses = this.section.iconClasses;
    }
  }

  ngDoCheck() {
    this.hasHeaderElements =
      !!this.label ||
      !!this.headerRef?.nativeElement?.childNodes.length ||
      !!this.headerRef?.nativeElement?.childNodes.length;
  }
}
