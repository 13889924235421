import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getIdValue } from '@form-lib/utils/control.utils';
import { OrgHttpService } from '@main/org/org-http.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrgValidators {
  constructor(private orgHttpService: OrgHttpService) {}

  orgNameUnique() {
    return (control: AbstractControl) => {
      const id = getIdValue(control);
      if (!id) return of(null);
      return this.orgHttpService
        .orgNameExists(id, control.value)
        .pipe(map((val) => (!!val ? { uniqueOrgName: 'Org name is already in use, choose another' } : null)));
    };
  }
}
