import { Directive, HostBinding, Input } from '@angular/core';

enum DisabledProperties {
  pointerEvents = 'none',
  textDecoration = 'none',
  cursor = 'none' // Has no effect
}

enum EnabledProperties {
  pointerEvents = 'auto',
  textDecoration = 'inherit',
  cursor = 'pointer'
}

@Directive({
  selector: '[appDisableAnchorLink]'
})
export class DisableAnchorLinkDirective {
  @HostBinding('style.pointer-events')
  pointerEvents;

  @HostBinding('style.text-decoration')
  textDecoration;

  @HostBinding('style.cursor')
  cursor;

  @HostBinding('class.is-disabled')
  @HostBinding('attr.disabled')
  @HostBinding('attr.aria-disabled')
  _isDisabled;

  @Input('appDisableAnchorLink')
  set isDisabled(val: boolean) {
    // when false, setting to null so that attr.disabled doesn't end up present on the element as disabled="false"
    // due to disabled attribute presence and not whatever value is assigned to it being significant to html
    this._isDisabled = val ? val : null;
    this.pointerEvents = val ? DisabledProperties.pointerEvents : EnabledProperties.pointerEvents;
    this.textDecoration = val ? DisabledProperties.textDecoration : EnabledProperties.textDecoration;
    this.cursor = val ? DisabledProperties.cursor : EnabledProperties.cursor;
  }
}
