import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisplayItemsPipe } from '@lib-resource/pipes/display-items.pipe';
import { AccountingCurrencyPipe } from '@lib-resource/pipes/accounting-currency.pipe';
import { DataFormatterPipe } from './pipes/data-formatter.pipe';
import { GetValuePipe } from './pipes/get-value.pipe';
import { MapValueToLabelPipe } from './pipes/map-value-to-label.pipe';

const exportItems = [DataFormatterPipe, GetValuePipe, MapValueToLabelPipe, DisplayItemsPipe, AccountingCurrencyPipe];

@NgModule({
  declarations: [...exportItems],
  exports: [...exportItems],
  imports: [CommonModule]
})
export class LibResourceModule {}
