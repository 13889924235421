import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoDisplayDialogComponent } from './info-display-dialog/info-display-dialog.component';

@Injectable()
export class InfoDisplayService {
  constructor(private matDialog: MatDialog) {}

  openDisplay({ displayData, definitions, label }) {
    this.matDialog.open(InfoDisplayDialogComponent, {
      data: {
        displayData,
        definitions,
        label
      }
    });
  }
}
