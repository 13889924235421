import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() multi: boolean;
  @Input() extensions: string;
  @Input() disabled: boolean;
  @Input() labelText = 'Add File';
  @Output() selectFile = new EventEmitter<File | File[]>();
  active = false;

  fileSelected(files: FileList) {
    if (files && files.length > 0) {
      if (this.multi) {
        this.selectFile.emit(Array.from(files));
      } else {
        this.selectFile.emit(files[0]);
      }
    }
  }

  toggleActive(event) {
    this.active = event;
  }
}
