import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from '@common/models/page.model';
import { Observable, Subject } from 'rxjs';
import { QuoteLinqVersionModel, QuoteLinqVersionStatusType } from '@common/models/quote-linq-version.model';
import { SERVER_API_URL } from '@app/app.constants';
import { UrlUtilService } from '@common/services/url-util.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '@common/components/pdf-view-dialog/pdf-viewer-dialog.component';
import { QuoteResponseModel, QuoteResponseOption } from '@common/models/quote-response.model';
import { QuoteResponseEventType } from '@common/models/quote-response-event.model';
import { switchMap, tap } from 'rxjs/operators';
import {
  QuoteSupportingFilePdfDialogComponent,
  QuoteSupportingFilePdfDialogReturn
} from '@common/dialogs/quote-supporting-file-pdf-dialog/quote-supporting-file-pdf-dialog.component';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { ContingencyModel } from '@quote/quote-response/quote-response-stepper/contingencies/contingency.model';
import { DocViewerDialogComponent } from '@common/dialogs/doc-view-dialog/doc-viewer-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class QuoteLinqVersionService {
  private quoteLinqVersionUpdated = new Subject<QuoteLinqVersionModel>(); // a subject that fires when the update is called

  constructor(
    protected http: HttpClient,
    private urlUtil: UrlUtilService,
    private matDialog: MatDialog,
    private dialogService: DialogsService
  ) {}

  getLatest(quoteResponseId: number): Observable<QuoteLinqVersionModel> {
    return this.http.get<QuoteLinqVersionModel>(
      `${SERVER_API_URL}/quote-linq-version/quote-response/${quoteResponseId}/version/latest`
    );
  }

  find(
    quoteResponseId: number,
    pageIndex = 0,
    pageSize = 0,
    sort: string[] = []
  ): Observable<Page<QuoteLinqVersionModel>> {
    return this.http.get<Page<QuoteLinqVersionModel>>(
      `${SERVER_API_URL}/quote-linq-version/quote-response/${quoteResponseId}/version`,
      {
        params: this.urlUtil.buildSearchParams('', pageIndex, pageSize, sort)
      }
    );
  }

  create(
    quoteResponse: QuoteResponseModel,
    contingencies: ContingencyModel[],
    proposalRmtFileId: string,
    draft: boolean,
    eventType: QuoteResponseEventType
  ) {
    const formData: FormData = new FormData();
    formData.append('quoteResponseDTO', new Blob([JSON.stringify(quoteResponse)], { type: 'application/json' }));
    formData.append(
      'contingencies',
      new Blob([JSON.stringify(contingencies?.length ? contingencies : [])], { type: 'application/json' })
    );
    if (proposalRmtFileId) {
      formData.append('proposalFileId', proposalRmtFileId);
    }
    if (eventType) {
      formData.append('responseEventType', `${eventType}`);
    }
    formData.append('draft', !!draft ? 'true' : 'false');

    return this.http
      .post<QuoteLinqVersionModel>(
        `${SERVER_API_URL}/quote-linq-version/quote-response/${quoteResponse.id}/version`,
        formData
      )
      .pipe(tap((res) => this.quoteLinqVersionUpdated.next(res)));
  }

  update(
    version: number,
    quoteResponse: QuoteResponseModel,
    contingencies: ContingencyModel[],
    proposalRmtFileId: string,
    draft: boolean,
    eventType: QuoteResponseEventType,
    proposalFile?: File
  ): Observable<QuoteLinqVersionModel> {
    const formData: FormData = new FormData();
    formData.append('quoteResponseDTO', new Blob([JSON.stringify(quoteResponse)], { type: 'application/json' }));
    formData.append(
      'contingencies',
      new Blob([JSON.stringify(contingencies?.length ? contingencies : [])], { type: 'application/json' })
    );
    if (proposalRmtFileId) {
      formData.append('proposalFileId', proposalRmtFileId);
    }
    if (eventType) {
      formData.append('responseEventType', `${eventType}`);
    }
    if (proposalFile) {
      formData.append('proposalFile', proposalFile, proposalFile.name);
    }
    formData.append('draft', !!draft ? 'true' : 'false');

    return this.http
      .put<QuoteLinqVersionModel>(
        `${SERVER_API_URL}/quote-linq-version/quote-response/${quoteResponse.id}/version/${version}`,
        formData
      )
      .pipe(tap((res) => this.quoteLinqVersionUpdated.next(res)));
  }

  delete(quoteResponseId: number, versionId: number): Observable<void> {
    return this.http.delete<void>(
      `${SERVER_API_URL}/quote-linq-version/quote-response/${quoteResponseId}/version/${versionId}`
    );
  }

  quoteLinqVersionUpdateObservable(): Observable<QuoteLinqVersionModel> {
    return this.quoteLinqVersionUpdated.asObservable();
  }

  openVersionFileDialog(blob: Blob, versionNumber: number, mimeType?: string, filename?: string): void {
    if (mimeType && mimeType !== 'application/pdf') {
      this.matDialog
        .open(DocViewerDialogComponent, {
          autoFocus: false,
          panelClass: 'full-width-dialog',
          height: '90vh',
          width: '90vw',
          data: {
            blob: blob,
            title: `View Proposal Excel | Version ${versionNumber}`,
            filename: filename
          }
        })
        .afterClosed();
    } else {
      this.matDialog
        .open(PdfViewerDialogComponent, {
          autoFocus: false,
          panelClass: 'full-width-dialog',
          height: '90vh',
          width: '90vw',
          data: {
            blob: blob,
            title: `View Proposal PDF | Version ${versionNumber}`
          }
        })
        .afterClosed();
    }
  }

  mapVersionDataToQuoteResponse(quoteResponse: QuoteResponseModel, version: QuoteLinqVersionModel): QuoteResponseModel {
    const mappedVersionOptions: QuoteResponseOption[] = this.mapVersionOptionsToQuoteResponseOptions(
      quoteResponse.quoteResponseOptions,
      version.versionData?.quoteResponseOptionVersions
    );

    const fullQuoteResponse = {
      ...quoteResponse,
      ...version.versionData,
      quoteResponseOptions: mappedVersionOptions
    };

    delete fullQuoteResponse.quoteResponseOptionVersions;

    return fullQuoteResponse;
  }

  mapVersionOptionsToQuoteResponseOptions(
    quoteResponseOptions: QuoteResponseOption[],
    versionOptions: any[]
  ): QuoteResponseOption[] {
    const mappedOptions: QuoteResponseOption[] = [];
    if (versionOptions) {
      versionOptions.forEach((versionOption) => {
        const quoteResponseOption = quoteResponseOptions.find((qro) => qro.id === versionOption.id);
        if (quoteResponseOption) {
          mappedOptions.push({
            ...quoteResponseOption,
            ...versionOption
          });
        } else {
          mappedOptions.push(versionOption);
        }
      });
    }

    return mappedOptions;
  }

  findQuoteLinqVersionsByQuoteRequest(quoteRequestId: number): Observable<any> {
    return this.http.get(`${SERVER_API_URL}/quote-linq-version/quote-request/${quoteRequestId}/versions`, {
      params: this.urlUtil.buildSearchParams(undefined, 0, 1000, [])
    });
  }

  // this will open a modal that a user can manually select a pdf to attach to the version
  attachPdf(quoteResponse: QuoteResponseModel, quoteLinqVersion: QuoteLinqVersionModel) {
    return this.dialogService
      .openDialog(
        QuoteSupportingFilePdfDialogComponent,
        {
          quoteResponseId: quoteResponse.id,
          quoteLinqVersion: quoteLinqVersion
        },
        {
          disableClose: true
        },
        '40vw'
      )
      .afterClosed()
      .pipe(
        switchMap((res: QuoteSupportingFilePdfDialogReturn) => {
          if (!!res) {
            return this.update(
              quoteLinqVersion.versionId,
              quoteResponse,
              quoteLinqVersion.contingencyVersions,
              res.supportingFile?.id,
              quoteLinqVersion.versionStatus === QuoteLinqVersionStatusType.DRAFT,
              QuoteResponseEventType.RESPONSE_VERSION_UPDATED_MANUAL,
              res.fileToUpload
            );
          }
        })
      );
  }
}
