import { ResourceStateModel } from '@app/store-utils/resource-state.model';

export interface AuthStateModel {
  tokenResource?: ResourceStateModel<string | null>;
  expireDate?: number; // seconds from epoch
}

export class Login {
  static readonly type = '[Auth] Login';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class NavigateToLogin {
  static readonly type = '[Auth] Navigate To Login';
}

export class SetAuthenticated {
  static readonly type = '[Auth] Set Authentication';
  constructor(public authenticated: string, public expireDate?: number) {}
}
