<label [class]="{ 'inline-label': inline, required: required }" class="multi-select-filter-field">
  <span
    class="label-text"
    [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
    truncateText
    *ngIf="inline"
    aria-hidden="true"
  >
    {{ label }}</span
  >
  <mat-form-field [appearance]="appearance" floatLabel="always">
    <mat-select
      #select
      [compareWith]="compareFn"
      [required]="required"
      multiple
      [formControl]="control"
      [appIsReadOnly]="readOnly"
      (closed)="selectClosed()"
      (opened)="selectOpened()"
      panelClass="multi-select-filter-panel"
    >
      <mat-select-trigger truncateText> {{ selectedDisplay }} </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [formControl]="searchTextBoxControl"
          [searching]="searching"
          noEntriesFoundLabel="'No results found.'"
          placeholderLabel="Search"
        ></ngx-mat-select-search>
      </mat-option>
      <cdk-virtual-scroll-viewport
        appendOnly
        itemSize="32"
        (scrolledIndexChange)="getNextBatch()"
        minBufferPx="240"
        maxBufferPx="320"
      >
        <ng-container *ngIf="!!appliedOptions">
          <mat-option
            (onSelectionChange)="selectionChange($event)"
            *ngFor="let option of appliedOptions"
            [value]="option.value"
            [disabled]="this.control.disabled"
          >
            <span truncateText>{{ option.label }}</span>
          </mat-option>
          <div class="pv-1">
            <mat-divider></mat-divider>
          </div>
        </ng-container>
        <ng-container *ngIf="currentOptions$ | async as currentOptions">
          <mat-option
            (onSelectionChange)="selectionChange($event)"
            *cdkVirtualFor="let option of currentOptions"
            [value]="option.value"
            [disabled]="this.control.disabled"
          >
            <div truncateText>{{ option.label }}</div>
          </mat-option>
          <mat-option *ngIf="!currentOptions.length" disabled>
            No options currently available
          </mat-option>
        </ng-container>
      </cdk-virtual-scroll-viewport>
      <div class="meta-display dense-layout" *ngIf="!!this.control.enabled">
        <span class="result-count"> Displaying {{ total }} results </span>
        <button mat-raised-button class="ternary-button" [disabled]="!appliedOptions?.length" (click)="clearAll()">
          <fa-icon [icon]="timesIcon"></fa-icon>
          Clear All
        </button>
        <button color="primary" mat-raised-button [disabled]="!valueHasChanged" (click)="apply()">
          Apply
        </button>
        <button mat-stroked-button (click)="select.close()">
          Cancel
        </button>
      </div>
    </mat-select>
    <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
    <mat-hint>
      <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
    </mat-hint>
    <mat-error>
      <app-error-renderer [errors]="control.errors"></app-error-renderer>
    </mat-error>
  </mat-form-field>
</label>
