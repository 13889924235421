import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { defaultSort } from '@configuration/admin/icd-code-list/icd-code-list.definitions';
import { of } from 'rxjs';
import { generateFilterString, icdToLabelValue } from './icd-code.helpers';
import { IcdCodeService } from './icd-code.service';

@Injectable({
  providedIn: 'root'
})
export class IcdCodeOptionService extends AsyncOptions<string> {
  key = 'icdCodes';

  constructor(private icdService: IcdCodeService) {
    super();
  }

  generateLabelValues = icdToLabelValue;

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.icdService
      .search(this.combineFilters(generateFilterString(value), requiredQuery), pageIndex, pageSize, defaultSort)
      .pipe(
        map(({ content, total }) => ({
          content: this.generateLabelValues(content),
          total
        }))
      );

  valuesFromKeys = (values: string[], multi) => {
    if (!values?.length) return of(null);
    const filter = multi ? `name ~ ('${values.join("','")}')` : `name ~ '${values}'`;
    return this.icdService.search(filter, 0, 1000, defaultSort).pipe(map((result) => icdToLabelValue(result.content)));
  };
}
