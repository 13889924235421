import { ESCAPE } from '@angular/cdk/keycodes';
import { FlexibleConnectedPositionStrategy, Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { PopoverRef } from '@shared/components/popover/popover-ref';
import {
  PopoverPanel,
  PopoverPositionX,
  PopoverPositionY,
  PopoverScrollStrategy
} from '@shared/components/popover/popover.model';
import { Subscription } from 'rxjs';
import { POPOVER_CONFIG } from './popover.model';

@Component({
  template: `
    <div
      class="popover-panel"
      role="dialog"
      [ngClass]="_classList"
      [ngStyle]="popoverPanelStyles"
      (keydown)="_handleKeydown($event)"
    >
      <div
        class="popover-direction-arrow"
        [ngStyle]="popoverArrowStyles"
        [ngClass]="arrowColor"
        *ngIf="!overlapTrigger"
      ></div>
      <div
        class="popover-content mat-elevation-z1"
        [ngClass]="{ 'popover-is-template': isTemplate }"
        [cdkTrapFocus]="focusTrapEnabled"
        [cdkTrapFocusAutoCapture]="focusTrapAutoCaptureEnabled"
      >
        <ng-container cdkPortalOutlet></ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PopoverComponent implements PopoverPanel, OnDestroy {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  /** Settings for popover, view setters and getters for more detail */
  positionX: PopoverPositionX = 'after';
  positionY: PopoverPositionY = 'above';
  scrollStrategy: PopoverScrollStrategy = 'reposition';
  focusTrapEnabled = true;
  focusTrapAutoCaptureEnabled = true;

  /** Config object to be passed into the popover's ngClass */
  _classList: { [key: string]: boolean } = {};
  containerPositioning = false;
  closeDisabled = false;

  /** Config object to be passed into the popover's ngStyle */
  popoverPanelStyles: {};

  /** Config object to be passed into the popover's content ngStyle */
  popoverContentStyles = {
    'padding-top': '0px',
    'padding-bottom': '0px',
    'margin-top': '0px'
  };

  /** Event emitted when the popover is closed. */
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  overlapTrigger = false;
  private arrowWidth = 16;
  private arrowColor = 'color-medium-light-gray';
  private arrowOffsetX = 24;
  popoverArrowStyles: {
    'border-top': string;
    'border-right': string;
    left: string;
    'border-left': string;
    right: string;
    'border-bottom': string;
  };

  private _positionSubscription: Subscription;
  isTemplate: boolean;

  constructor(
    private _elementRef: ElementRef,
    private popoverRef: PopoverRef,
    @Inject(POPOVER_CONFIG) popoverConfig: any,
    private _overlay: Overlay,
    private _changeDetectorRef: ChangeDetectorRef,
    private _zone: NgZone
  ) {
    if (popoverConfig.panelClass?.length) {
      this._classList = popoverConfig.panelClass.split(' ').reduce((obj: any, className: string) => {
        obj[className] = true;
        return obj;
      }, {});

      this._elementRef.nativeElement.className = '';
    }

    this.setPositionClasses();
    this.setCurrentStyles();

    this._subscribeToPositions(popoverRef.positionStrategy as FlexibleConnectedPositionStrategy);
  }

  ngOnDestroy() {
    this._positionSubscription?.unsubscribe();
  }

  /** Handle a keyboard event from the popover, delegating to the appropriate action. */
  _handleKeydown(event: KeyboardEvent) {
    switch ((event as any).keyCode) {
      case ESCAPE:
        this.popoverRef.close();
        break;
      default:
    }
  }

  /**
   * It's necessary to set position-based classes to ensure the popover panel animation
   * folds out from the correct direction.
   */
  setPositionClasses(posX = this.positionX, posY = this.positionY): void {
    this._classList['popover-before'] = posX === 'before';
    this._classList['popover-after'] = posX === 'after';
    this._classList['popover-above'] = posY === 'above';
    this._classList['popover-below'] = posY === 'below';
  }

  /** Focuses the popover trigger. */
  focus() {
    this._elementRef.nativeElement.focus();
  }

  attachComponentPortal(portal: ComponentPortal<any>) {
    this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal(portal: TemplatePortal) {
    this.isTemplate = true;
    this.portalOutlet.attachTemplatePortal(portal);
  }

  setCurrentStyles() {
    this.popoverArrowStyles = {
      right: this.positionX === 'before' ? this.arrowOffsetX - this.arrowWidth + 'px' : '',
      left: this.positionX === 'after' ? this.arrowOffsetX - this.arrowWidth + 'px' : '',
      'border-top': this.positionY === 'below' ? this.arrowWidth + 'px solid' : '0px solid transparent',
      'border-right': this.arrowWidth + 'px solid transparent',
      'border-bottom':
        this.positionY === 'above' ? this.arrowWidth + 'px solid' : this.arrowWidth + 'px solid transparent',
      'border-left': this.arrowWidth + 'px solid transparent'
    };

    this.popoverContentStyles = {
      'padding-top': this.overlapTrigger === true ? '0px' : this.arrowWidth + 'px',
      'padding-bottom': this.overlapTrigger === true ? '0px' : this.arrowWidth + 'px',
      'margin-top':
        this.overlapTrigger === false && this.positionY === 'below' && this.containerPositioning === false
          ? -(this.arrowWidth * 2) + 'px'
          : '0px'
    };
  }

  /**
   * Listens to changes in the position of the overlay and sets the correct classes
   * on the popover based on the new position. This ensures the animation origin is always
   * correct, even if a fallback position is used for the overlay.
   */
  private _subscribeToPositions(position: FlexibleConnectedPositionStrategy): void {
    this._positionSubscription = position.positionChanges.subscribe((change) => {
      const positionX: PopoverPositionX = change.connectionPair.overlayX === 'start' ? 'after' : 'before';
      let positionY: PopoverPositionY = change.connectionPair.overlayY === 'top' ? 'below' : 'above';

      if (!this.overlapTrigger) {
        positionY = positionY === 'below' ? 'above' : 'below';
      }

      // required for ChangeDetectionStrategy.OnPush
      this._changeDetectorRef.markForCheck();

      this._zone.run(() => {
        this.positionX = positionX;
        this.positionY = positionY;
        this.setCurrentStyles();

        this.setPositionClasses(positionX, positionY);
      });
    });
  }
}
