<div class="filter-brick">
  <button id="filter-brick-{{ query.key }}" class="filter-name bg-primary">
    <span [innerHTML]="query.label"></span>
  </button>
  <div class="filter-operator">{{ query.operator }}</div>
  <div truncateText matTooltipPosition="below" class="filter-desc" [ngSwitch]="query.type">
    <ng-container *ngIf="!query.asyncOptions; else asyncTmp">
      {{ query | dataFormatter: getValueConfig }}
    </ng-container>
    <ng-template #asyncTmp>
      {{ displayValue$ | async }}
    </ng-template>
  </div>
  <button (click)="removeQuery($event)" class="remove-chip">
    <fa-icon [icon]="timesIcon"></fa-icon>
  </button>
</div>
