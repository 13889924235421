<section>
  <header class="stepper-header-nav">
    <button
      class="step-trigger mat-typography"
      *ngFor="let step of steps; let i = index"
      (click)="startNavigation(i)"
      [ngClass]="{ 'active-step': selectedIndex === i }"
    >
      <span class="step-trigger-content">
        <span class="step-trigger-state">
          <ng-container *ngIf="!loading || i !== selectedIndex">
            <fa-icon *ngIf="step.interacted && step.hasError" [icon]="errorIcon" class="color-warn"></fa-icon>
            <fa-icon
              *ngIf="step.interacted && step.completed && !step.hasError"
              [icon]="checkIcon"
              class="color-success"
            ></fa-icon>
            <ng-container *ngIf="!step.interacted">
              {{ i + 1 }}
            </ng-container>
          </ng-container>
          <app-in-progress-indicator *ngIf="loading && i === selectedIndex" diameter="2.25rem" indicatorStyle="thin">
          </app-in-progress-indicator>
        </span>
        <span class="step-trigger-index">Step {{ i + 1 }}</span>
        <span class="step-trigger-label" truncateText>{{ step.label }}</span>
      </span>
    </button>
  </header>
  <div style="position: relative; overflow: hidden">
    <div
      *ngFor="let step of steps; let i = index"
      class="step-container"
      role="tabpanel"
      [ngStyle]="{ position: selectedIndex !== i ? 'absolute' : 'inherit' }"
      [@horizontalStepTransition]="_getAnimationDirection(i)"
      [id]="_getStepContentId(i)"
      [attr.aria-labelledby]="_getStepLabelId(i)"
      [attr.aria-expanded]="selectedIndex === i"
    >
      <ng-container [ngTemplateOutlet]="step.content"></ng-container>
    </div>
  </div>

  <footer class="stepper-nav pt-4 grid-content justify-end">
    <button mat-raised-button cdkStepperPrevious [disabled]="this.steps.first === selected">Back</button>
    <button
      mat-raised-button
      [color]="selected.hasError ? 'warn' : 'primary'"
      (click)="startNavigation(this.selectedIndex + 1)"
      *ngIf="this.selectedIndex < this.steps.length - 1"
      [appInProgressIndicator]="loading"
    >
      <ng-container *ngIf="!loading">
        <fa-icon
          *ngIf="this.steps.last !== selected && selected.hasError"
          [icon]="errorIcon"
          style="font-size: .75rem"
        ></fa-icon>
        Next
      </ng-container>
    </button>
  </footer>
</section>
