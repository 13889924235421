import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ValueMap } from '@common/models/csv-import.model';

@Component({
  selector: 'app-csv-import-mapping',
  templateUrl: './csv-import-mapping.component.html',
  styleUrls: ['./csv-import-mapping.component.scss']
})
export class CsvImportMappingComponent implements OnChanges {
  @Input() field: ValueMap;
  @Input() name: string;
  @Input() valueMapTo;

  @Output() valueMapped = new EventEmitter();

  ngOnChanges({ valueMapTo }: SimpleChanges): void {
    if (valueMapTo && !valueMapTo.currentValue) {
      this.valueMapTo = {}; // Keeps DOM from erroring when no value is provided
    }
  }

  onSelect(value, systemValue) {
    this.valueMapped.emit({
      value,
      systemValue
    });
  }
}
