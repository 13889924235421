import { AuthStateModel } from '@auth/store/auth.actions';
import { AuthState } from '@auth/store/auth.state';
import { Selector } from '@ngxs/store';

export class AuthSelectors {
  @Selector([AuthState])
  static token(state: AuthStateModel): string | null {
    return state?.tokenResource?.payload;
  }

  @Selector([AuthState])
  static authState(state: AuthStateModel): AuthStateModel | null {
    return state;
  }

  @Selector([AuthState])
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state?.tokenResource?.payload;
  }
}
