import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { LabelValue } from '@lib-resource/label-value.model';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { OptionsService } from '../../options/options.service';
import { BaseSelectFieldComponent } from '../base-select-field/base-select-field.component';

@Component({
  selector: 'app-multi-select-filter-field',
  templateUrl: './multi-select-filter-field.component.html',
  styleUrls: ['../../form-lib.scss', './multi-select-filter-field.component.scss']
})
export class MultiSelectFilterFieldComponent extends BaseSelectFieldComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(MatSelect, { static: true }) select: MatSelect;
  searchTextBoxControl = new UntypedFormControl();
  searchSub: Subscription;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;

  isMulti = true;
  subs = new Subscription();
  valueHasChanged: boolean = true;
  selectedOptionsRetainer: LabelValue[] = [];
  appliedOptions: LabelValue[] = [];
  timesIcon = faTimes;

  get selectedDisplay(): string {
    if (!!this.selectedOptionsRetainer && this.selectedOptionsRetainer.length > 0) {
      return this.selectedOptionsRetainer.map(({ label }) => label).join(', ');
    }

    if (!!this.noSelectionLabel) {
      return this.noSelectionLabel;
    }

    return '--';
  }

  constructor(optionsService: OptionsService) {
    super(optionsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.add(
      this.asyncOptionsService.valuesFromKeys(this.control.value, true, this.asyncExtras).subscribe((lv) => {
        if (!lv) {
          lv = [];
        }
        this.appliedOptions = lv;
        this.selectedOptionsRetainer = lv;
      })
    );

    this.searchSub = this.searchTextBoxControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => this._filter(value));

    // Initial retrieval of options data
    this._filter('');

    // watch for changes to the controls values..
    this.subs.add(
      this.control.valueChanges.subscribe((values) => {
        // if the selected options list is out of sync with the control values, we need to update them.  This is typically because the values were
        //   changed outside of this control
        const isMismatched =
          values?.length !== this.selectedOptionsRetainer.length ||
          values.some((ctrlVal) => !this.selectedOptionsRetainer.some((selectedOption) => selectedOption.value === ctrlVal));

        if (this.control.enabled) {
          if (isMismatched) {
            this.asyncOptionsService
              .valuesFromKeys(values, this.isMulti, this.asyncExtras)
              .pipe(take(1))
              .subscribe((result) => {
                // act as if the control was updated through the select field, set the in member retainer, call apply
                this.selectedOptionsRetainer = result || [];
                this.apply(false);
              });
          }
        } else if (isMismatched && this.control.value === null) {
          // also check when disabled if the list is mismatched and the value is set to null, we need to clear the list visually
          // can happen when DisableIfChildren.siblingHasValue disables this control because the sibling is in a particular state
          this.selectedOptionsRetainer = [];
        }
      })
    );

    this.asyncDependentSelectFieldSetup();
  }

  // This forces select trigger to float
  ngAfterContentInit(): void {
    Object.defineProperty(this.select, 'empty', {
      get: function () {
        return false;
      }
    });
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
    this.subs.unsubscribe();
  }

  /**
   * Used to filter data based on search input
   */
  _filter(name: string): void {
    this.pageIndex = 0;
    this.filterValue = name;

    // Set selected values to retain the selected checkbox state
    if (this.asyncOptions) {
      this.getAsyncOptions();
    }
  }

  getNextBatch() {
    const selected = this.appliedOptions.length;
    const total = this.viewport.getDataLength();
    if (total + selected >= this.total) return;
    const end = this.viewport.getRenderedRange().end;
    if (total - end >= this.pageSize) return;
    this.pageIndex++;
    this.getAsyncOptions();
  }

  updateOptions(value: LabelValue[]) {
    if (this.pageIndex === 0) {
      this.currentOptionsSource.next(this.removeAppliedOptionsFromList(value));
      this.viewport.scrollToIndex(0);
    } else {
      const newOptionsArray = this.removeAppliedOptionsFromList([...this.currentOptionsSource.value, ...value]);
      this.currentOptionsSource.next(newOptionsArray);
    }
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event: MatOptionSelectionChange) {
    this.valueHasChanged = true;
    if (event.isUserInput) {
      if (event.source.selected) {
        // This will add the LabelValue objects as user selects so we don't have to get the data from the server
        this.selectedOptionsRetainer = [
          ...this.selectedOptionsRetainer,
          {
            label: event.source.viewValue,
            value: event.source.value
          }
        ];
      } else {
        this.selectedOptionsRetainer = this.selectedOptionsRetainer.filter(
          (item) => !this.compareFn(item.value, event.source.value)
        );
      }
    }
  }

  selectClosed() {
    // Ensure that the previously selected options are restored on close.
    // When applying new values the selected options have already been updated so this is just for cancel and clicking off the select window
    this.selectedOptionsRetainer = this.appliedOptions;
    this.control.setValue(this.selectedOptionsRetainer.map((item) => item.value));
  }

  selectOpened() {
    this.viewport.scrollToOffset(1);
  }

  clearAll() {
    this.resetToNoSelectedOptions();
    this.markControlAsDirtyAndClose();
  }

  apply(close: boolean = true) {
    this.appliedOptions = this.selectedOptionsRetainer;
    this._valueChanged(this.appliedOptions);
    this.markControlAsDirtyAndClose(close);
  }

  markControlAsDirtyAndClose(close: boolean = true) {
    this.getAsyncOptions();
    this.control.markAsDirty();
    if (close) {
      // Reset batching so that we don't request empty pages past the render range
      this.pageIndex = 0;
      this.viewport.setRenderedRange({
        start: 0,
        end: 0
      });
      this.select.close();
    }
  }

  removeAppliedOptionsFromList(inputOptions: LabelValue[]): LabelValue[] {
    if (!this.appliedOptions || this.appliedOptions.length === 0) {
      return inputOptions;
    }
    return inputOptions.filter((io) => !this.appliedOptions.some((ao) => io.value === ao.value));
  }

  protected resetToNoSelectedOptions() {
    this.selectedOptionsRetainer = [];
    this.appliedOptions = this.selectedOptionsRetainer;
    this._valueChanged(this.appliedOptions);
  }
}
