import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { AccountCommentModel } from '@common/models/account-comment.model';
import { AccountModel } from '@common/models/account.model';
import { BulkActionModel } from '@common/models/bulk-action.model';
import { Page } from '@common/models/page.model';
import { FilterConfig, HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomesticProviderModel } from '../models/domestic-provider.model';
import { UrlUtilService } from './url-util.service';

const accountSiteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
    dataKey: 'org.id'
  },
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'id'
  }
];

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  get baseUrlWithoutOrg() {
    return `${SERVER_API_URL}/org`;
  }

  constructor(
    protected http: HttpClient,
    private urlUtil: UrlUtilService,
    private httpResource: HttpResourceService,
    private store: Store
  ) {}

  generateBaseUrl(orgId: number) {
    if (orgId) return `${SERVER_API_URL}/org/${orgId}`;
    return this.baseUrl;
  }

  exists(accountId: number, name: string, orgId?: number): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );

    if (accountId) {
      params = params.set('id', `${accountId}`);
    }
    return this.http.get<boolean>(`${this.generateBaseUrl(orgId)}/account/exists`, { params: params });
  }

  create(accountModel: AccountModel): Observable<AccountModel> {
    return this.http.post<AccountModel>(`${this.generateBaseUrl(accountModel.orgId)}/account`, accountModel);
  }

  save(accountModel: AccountModel): Observable<AccountModel> {
    return this.http.put<AccountModel>(
      `${this.generateBaseUrl(accountModel.orgId)}/account/${accountModel.id}`,
      accountModel
    );
  }

  delete(group: AccountModel): Observable<AccountModel> {
    return this.http.delete<AccountModel>(`${this.generateBaseUrl(group.orgId)}/account/${group.id}`);
  }

  search(
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[],
    siteFilterConfigOverride?: FilterConfig[]
  ): Observable<Page<AccountModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'org/account',
      query: { filterString, pageIndex, pageSize, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : accountSiteFilterConfig
    });
  }

  searchUnfiltered(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<AccountModel>> {
    return this.http.get(`${this.baseUrlWithoutOrg}/account`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  get(id: number, orgId?: number): Observable<AccountModel> {
    return this.http.get<AccountModel>(`${this.generateBaseUrl(orgId)}/account/${id}`);
  }

  getDomesticProviders(id: number, orgId?): Observable<Array<DomesticProviderModel>> {
    return this.http.get<Array<DomesticProviderModel>>(
      `${this.generateBaseUrl(orgId)}/account/${id}/domestic-provider`
    );
  }

  updateDomesticProviders(
    id: number,
    domesticProviders: Array<DomesticProviderModel>,
    orgId?: number
  ): Observable<Array<DomesticProviderModel>> {
    return this.http.put<Array<DomesticProviderModel>>(
      `${this.generateBaseUrl(orgId)}/account/${id}/domestic-provider`,
      domesticProviders
    );
  }

  bulkAction(bulkAction: BulkActionModel): Observable<{}> {
    const params = new HttpParams(this.urlUtil.buildOption({}));
    return this.http.put(`${SERVER_API_URL}/account/bulk-action`, bulkAction, { params: params });
  }

  getComments(id: number, orgId?: number): Observable<AccountCommentModel[]> {
    return this.httpResource.get<AccountCommentModel[]>(`account/${id}/comment`, orgId);
  }

  getCommentsOldestFirst(id: number, orgId?: number): Observable<AccountCommentModel[]> {
    return this.getComments(id, orgId).pipe(map((comments) => comments.reverse()));
  }

  addComment(id: number, comment: AccountCommentModel, orgId?: number) {
    return this.httpResource.post(`account/${id}/comment`, comment, orgId);
  }

  updateComment(id: number, comment: AccountCommentModel, orgId?: number) {
    return this.httpResource.put(`account/${id}/comment/${comment.id}`, comment, orgId);
  }

  deleteComment(id: number, commentId: number, orgId?: number) {
    return this.httpResource.delete(`account/${id}/comment/${commentId}`, orgId);
  }
}
