import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Renderer2, ViewEncapsulation } from '@angular/core';
import { ServerInfoService } from '@common/services/server-info.service';
import { MainService } from '@main/main.service';
import { ProductDefModel } from '@main/nav.models';
import { map, pairwise, startWith } from 'rxjs/operators';

const screenSizesXs = ['xs', 'lt-sm', 'lt-md', 'lt-lg', 'lt-xl'];
const screenSizesSm = ['sm', 'gt-xs', 'lt-md', 'lt-lg', 'lt-xl'];
const screenSizesMd = ['md', 'gt-xs', 'gt-sm', 'lt-lg', 'lt-xl'];
const screenSizesLg = ['lg', 'gt-xs', 'gt-sm', 'gt-md', 'lt-xl'];
const screenSizesXl = ['xl', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg'];

const screenSizes = {
  xs: screenSizesXs,
  sm: screenSizesSm,
  md: screenSizesMd,
  lg: screenSizesLg,
  xl: screenSizesXl
};

function mapBreakpointClass(breakpoints: { [key: string]: boolean }) {
  let sizes = [];
  if (breakpoints[Breakpoints.XSmall]) {
    sizes = screenSizes.xs;
  } else if (breakpoints[Breakpoints.Small]) {
    sizes = screenSizes.sm;
  } else if (breakpoints[Breakpoints.Medium]) {
    sizes = screenSizes.md;
  } else if (breakpoints[Breakpoints.Large]) {
    sizes = screenSizes.lg;
  } else if (breakpoints[Breakpoints.XLarge]) {
    sizes = screenSizes.xl;
  }
  return sizes.map((val) => `ss-${val}`);
}

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      .app-container {
        background-color: white;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  private _body = document.body;

  constructor(
    // while not used directly in this class, inject the ServerInfoService to construct it and start the timer
    serverInfoService: ServerInfoService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    mainService: MainService
  ) {
    mainService.selectedProduct$
      .pipe(
        startWith(null as ProductDefModel),
        map((def) => (!!def?.abbreviation ? `${def.abbreviation.toLowerCase()}-theme` : 'default-theme')),
        pairwise()
      )
      .subscribe(([prev, curr]) => {
        if (prev) this.renderer.removeClass(this._body, prev);
        this.renderer.addClass(this._body, curr);
      });
    // No unsub needed since component is never destroyed
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(
        startWith({ breakpoints: {} }),
        map(({ breakpoints }) => mapBreakpointClass(breakpoints)),
        pairwise()
      )
      .subscribe(([prev, curr]) => {
        prev.forEach((val) => this.renderer.removeClass(this._body, val));
        curr.forEach((val) => this.renderer.addClass(this._body, val));
      });
  }
}
