import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigateToLogin } from '@auth/store/auth.actions';
import { AuthSelectors } from '@auth/store/auth.selectors';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenGuard {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.store.selectSnapshot(AuthSelectors.token)) {
      return true;
    }

    this.store.dispatch(new NavigateToLogin());
    return false;
  }
}
