import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-file-list-concise',
  template: `
    <mat-list>
      <h3 mat-subheader>Files</h3>
      <mat-list-item *ngFor="let file of files">
        <mat-icon matListItemIcon>folder</mat-icon>
        <h4 matListItemTitle>{{ file.name }}</h4>
        <p matListItemLine>{{ file.lastModified | date }}</p>
        <button matListItemMeta mat-icon-button *ngIf="!disabled" (click)="removeFile.emit(file)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-list-item>

      <mat-list-item *ngIf="!files || !files.length">
        <mat-icon matListItemIcon>folder</mat-icon>
        <h4 matListItemTitle>No files to display.</h4>
      </mat-list-item>
    </mat-list>
  `,
  styles: []
})
export class FileListConciseComponent {
  @Input() files: File[] = [];
  @Input() disabled: boolean;
  @Output() removeFile = new EventEmitter<File>();
}
