<app-dialog-close icon (click)="close()" *ngIf="!uploading?.loading"></app-dialog-close>
<app-dialog-label>Import File</app-dialog-label>
<app-dialog-content class="collapsible-form dense">
  <mat-tab-group class="header-less-tabs" [(selectedIndex)]="selectedTabIndex">
    <mat-tab>
      <ng-content select="[import-csv-dialog-info]"></ng-content>
      File requirements:
      <ul>
        <ng-content select="[import-csv-dialog-file-requirements]"></ng-content>
        <li>
          The maximum file size supported is <strong>{{ fileService.getMaxUploadMbs() }} MB</strong>
        </li>
      </ul>
      <div class="file-upload-success" *ngIf="file$ | async as file; else noFile">
        <fa-icon [icon]="fileSuccessIcon" class="color-success file-success pl-2 pr-2"></fa-icon>
        <span class="text-no-overflow">
          {{ file.name.lastIndexOf('.') > 0 ? file.name.substring(0, file.name.lastIndexOf('.')) : file.name }}
        </span>
        <span>
          {{ file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.')) : '' }}
        </span>
        <fa-icon [icon]="closeIcon" class="cursor-click pl-2 pr-2 pt-1" (click)="clearFile()"></fa-icon>
      </div>
      <ng-template #noFile>
        <app-file-upload
          (selectFile)="setFile($event)"
          class="mt-3"
          [multi]="false"
          extensions=".csv"
          labelText="Add CSV File"
        ></app-file-upload>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <div class="mapping-tab m-1">
        <div>{{ instructions }}</div>
        <div class="saved-templates">
          <mat-form-field *ngIf="showSavedTemplates">
            <mat-label>Saved Templates</mat-label>
            <mat-select (selectionChange)="applyMapping($event.value)" [value]="mappings$ | async">
              <mat-option *ngFor="let template of savedTemplates" [value]="template">
                {{ template.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="scanResultHasInvalidProblems(scanResult)">
          <div class="pt-3" *ngIf="scanResult?.invalid2yDateColumns?.length > 0">
            <app-csv-error
              [invalidColumns]="scanResult.invalid2yDateColumns"
              [invalidRows]="scanResult.invalid2yDateRows"
              [message]="
                'Two year date fields require one of the following formats: M/d/yy, yyMMdd, or M-d-yy. Please correct the following fields in your import file and try again.'
              "
            ></app-csv-error>
          </div>
          <div class="pt-3" *ngIf="scanResult?.invalid4yDateColumns?.length > 0">
            <app-csv-error
              [invalidColumns]="scanResult.invalid4yDateColumns"
              [invalidRows]="scanResult.invalid4yDateRows"
              [message]="
                'Four year date fields require one of the following formats: M/d/yyyy, yyyyMMdd, yyyy-M-d, or M-d-yyyy. Please correct the following fields in your import file and try again.'
              "
            ></app-csv-error>
          </div>
          <div class="pt-3" *ngIf="scanResult?.invalidNumberColumns?.length > 0">
            <app-csv-error
              [invalidColumns]="scanResult.invalidNumberColumns"
              [invalidRows]="scanResult.invalidNumberRows"
              [message]="
                'Number fields require numeric data. Please correct the following fields in your import file and try again.'
              "
            ></app-csv-error>
          </div>
          <div class="pt-3" *ngIf="scanResult?.invalidRequiredValueColumns?.length > 0">
            <app-csv-error
              [invalidColumns]="scanResult.invalidRequiredValueColumns"
              [invalidRows]="scanResult.invalidRequiredValueRows"
              [message]="
                'Empty values are not allowed in required value fields. Please correct the following fields in your import file and try again.'
              "
            ></app-csv-error>
          </div>
          <div class="pt-3" *ngIf="scanResult?.invalidEncoding">
            <app-csv-error
              [message]="
                'Invalid file encoding ' +
                scanResult.invalidEncoding +
                ' encountered.  UTF-8 is required, please save your import file as UTF-8 and try again.'
              "
            ></app-csv-error>
          </div>
        </div>
        <div>
          <app-csv-column-mapper
            *ngIf="mappings$ | async as mapping"
            [csvData]="csvDisplayData$ | async"
            [systemFields]="headerEntries"
            [hasHeader]="mapping.ignoreHeader"
            [csvColumnValues]="mapping.columnMappingList"
            (columnMapped)="mapColumn($event)"
          >
          </app-csv-column-mapper>
        </div>
        <div class="grid pt-2 pb-2 checkboxes" *ngIf="mappings$ | async as mapping">
          <mat-checkbox [checked]="mapping.ignoreHeader" (change)="toggleIgnoreHeader($event)" *ngIf="isCsv$ | async">
            First row is header
          </mat-checkbox>
          <mat-checkbox *ngIf="showOverwriteCheckbox" [(ngModel)]="overwrite" [ngModelOptions]="{ standalone: true }">
            {{ overwriteCheckboxLabel }}
          </mat-checkbox>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="mapping-tab" *ngIf="columnsWithMappedValues?.length && (valuesToMap$ | async) as valuesToMap">
        <ng-container *ngIf="mappings$ | async as mappings">
          <div class="csv-import-mapping">
            <div class="pb-3">Map the value(s) from your file to our system.</div>
            <p *ngIf="!valuesToMap.length">No values require mapping.</p>
            <app-csv-import-mapping
              *ngFor="let item of valuesToMap"
              [name]="item?.name"
              [field]="item"
              [valueMapTo]="mappings.valueMapping[item?.key]"
              (valueMapped)="updateValueMap($event, item)"
            >
            </app-csv-import-mapping>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</app-dialog-content>
<app-dialog-actions *ngIf="isValidFile$ | async as validFile">
  <button
    secondaryActions
    [disabled]="selectedTabIndex === 0 || uploading?.loading"
    (click)="clickBack()"
    mat-raised-button
  >
    Back
  </button>
  <button
    *ngIf="showHelp"
    [disabled]="uploading?.loading"
    secondaryActions
    mat-button
    class="need-help-link"
    (click)="openHelpDialog()"
  >
    Need help?
  </button>
  <button mat-raised-button *ngIf="selectedTabIndex === 0" [disabled]="!file" color="primary" (click)="next()">
    Next
  </button>
  <ng-container *ngIf="validFile.valid; else noFile">
    <button
      *ngIf="selectedTabIndex === 1 && { mappingInvalid: mappingInvalid$ | async } as check"
      [disabled]="check.mappingInvalid || scanResultHasInvalidProblems(scanResult)"
      [appInProgressIndicator]="uploading?.loading"
      color="primary"
      (click)="import()"
      mat-raised-button
    >
      Import
    </button>
    <button
      *ngIf="selectedTabIndex === 2 && { valueMappingInvalid: valueMappingInvalid$ | async } as check"
      [disabled]="check.valueMappingInvalid"
      [appInProgressIndicator]="uploading?.loading"
      color="primary"
      (click)="import()"
      mat-raised-button
    >
      Import
    </button>
    <button [disabled]="uploading?.loading" (click)="close()" mat-raised-button>Cancel</button>
  </ng-container>
  <ng-template #noFile>
    <button mat-raised-button (click)="close()">Cancel</button>
  </ng-template>
</app-dialog-actions>
