import { ResourceStateModel } from '@app/store-utils/resource-state.model';

export function resourceGet<T = any>(priorState?: ResourceStateModel<T>): ResourceStateModel<T> {
  return {
    payload: priorState?.payload,
    loading: true
  };
}

export function resourceSuccess<T = any>(resourceData): ResourceStateModel<T> {
  return {
    payload: resourceData,
    loading: false
  };
}

export function resourceFailure<T = any>(error: any, priorState?: ResourceStateModel<T>): ResourceStateModel<T> {
  return {
    payload: priorState?.payload,
    error,
    loading: false
  };
}
