export enum AggType {
  AVG = 'AVG',
  COUNT = 'COUNT',
  SUM = 'SUM',
  HISTOGRAM = 'HISTOGRAM',
  AVG_AGE = 'AVG_AGE'
}
export enum AggInterval {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR'
}

export class AggTypeModel {
  public name?: string;
  public type?: AggType;
  public field?: string;

  // for date_time histogram only
  public interval?: AggInterval;
  public startTime?: string;
  public endTime?: string;
}

export class AggModel {
  public name?: string;
  public groups?: Array<string>;
  public aggregations?: Array<AggTypeModel>;
  public timeSeriesField?: string;
  public interval?: AggInterval;
  public startDate?: string;
  public endDate?: string;
  public sort?: Array<string>;
  public limit?: number;
}

export class AggResultModel {
  public name?: string;
  public value?: number;
}

export class AggResultGroupModel {
  public value?: string;
  public groups?: Array<AggResultGroupModel>;
  public aggs?: Array<AggResultModel>;
}

export function getAggValueByKey(aggs: Array<AggResultGroupModel>, groupId: string, key: number, aggId: string) {
  const aggGroups = aggs.find((agg) => agg.value === groupId);

  if (aggGroups) {
    return aggGroups.groups.find((group) => group.value === key.toString()).aggs.find((agg) => agg.name === aggId)
      .value;
  }
  return false;
}
