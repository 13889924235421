import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogData } from '@common/dialogs/dialogs.model';
import { FormGeneratorService } from '@form-lib/services/form-generator.service';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';

const defaultDef: DataDefModel = new DataDefModel({
  label: '',
  key: '',
  type: DATA_TYPES.text
});

@Component({
  template: `
    <app-dialog-close icon appCloseDialog></app-dialog-close>
    <app-dialog-label>{{ data.label }}</app-dialog-label>
    <app-dialog-content>
      <div class="icon-layout pb-2" *ngIf="data.message">
        <div>
          <fa-icon
            *ngIf="!!data.icon"
            [icon]="data.icon"
            class="{{ !!data.iconClass ? data.iconClass : 'color-status-orange-dark' }}"
          ></fa-icon>
        </div>
        <div>{{ data.message }}</div>
      </div>
      <div class="pb-3"></div>
      <app-field-renderer [control]="control" [definition]="definition"> </app-field-renderer>
    </app-dialog-content>
    <app-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        [disabled]="control.invalid"
        [appCloseDialog]="{ value: control.value }"
      >
        {{ data.actionText ? data.actionText : 'Ok' }}
      </button>
      <button mat-raised-button appCloseDialog>{{ data.cancelText ? data.cancelText : 'Cancel' }}</button>
    </app-dialog-actions>
  `,
  styles: [
    `
      .icon-layout {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 0.5rem;
      }
    `
  ]
})
export class InputDialogComponent {
  data: InputDialogData;
  control: UntypedFormControl;
  definition: DataDefModel;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: InputDialogData,
    @Optional() @Inject(POPOVER_DATA) popoverData: InputDialogData,
    fg: FormGeneratorService
  ) {
    this.data = !!dialogData ? dialogData : popoverData;
    this.definition = this.data.definition ? this.data.definition : defaultDef;
    const formGroup = fg.generateFormGroup([this.definition], undefined, undefined, {
      [this.definition.key]: this.data.value
    });
    this.control = formGroup.get(this.definition.key) as UntypedFormControl;
  }
}
