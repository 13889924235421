import { Injectable } from '@angular/core';
import { sortByProperty } from '@app/tools/array';
import { OrgMemberService } from '@common/services/org-member.service';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This Async Option fetches the org members of a specified org
 */
@Injectable({
  providedIn: 'root'
})
export class OrgMemberOptionService extends AsyncOptions<string> {
  key = 'orgMemberOption';
  valueKey = 'id';

  constructor(private orgMemberService: OrgMemberService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) => {
    let filterString;
    if (asyncOptionDeps?.includes('orgId')) {
      // get the org members for the orgId in the form
      const orgId = control.parent.get(asyncOptionDeps[0])?.value;
      if (!!orgId) {
        filterString = `(orgid = ${orgId})`;
      } else {
        return of({ content: [], total: 0 });
      }
    }
    return this.orgMemberService.searchOrgMembers(filterString, pageIndex, pageSize, ['user.lastname']).pipe(
      map((orgMemPage) => ({
        content: this.generateLabelValues(orgMemPage.content),
        total: !orgMemPage ? 0 : orgMemPage.total
      }))
    );
  };

  valuesFromKeys = (values, multi = true) => {
    if (!values?.length) return of([]);
    return of(multi ? this.generateLabelValues(values.sort(sortByProperty('user.lastname'))) : values.user.firstName);
  };

  generateLabelValues(items: any[]): LabelValue<string, string>[] {
    if (!items?.length) return [];
    return items.map((orgMember) => ({
      label:
        orgMember.user.lastName && orgMember.user.firstName
          ? `${orgMember.user.lastName}, ${orgMember.user.firstName}`
          : orgMember.user.email,
      value: orgMember
    }));
  }
}
