import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DisablerConfig } from '@form-lib/models/validators.model';

// This allows for values that are defined and not false
function isDefined(value: any) {
  return value !== null && value !== undefined;
}

function isTruthy(value: any) {
  return isDefined(value) && value !== false && value !== '';
}

function invertBoolean(value: any, invert: boolean) {
  return invert ? !value : value;
}

function disableControl(control: UntypedFormControl, disable = true, clearValue = false) {
  if (disable && control.enabled) {
    control.disable();
    if (clearValue) control.setValue(null);
  }
  if (!disable && control.disabled) control.enable();
}

function getSibling(control: UntypedFormControl, field: string): UntypedFormControl {
  const siblingControl = control.parent.controls[field];
  if (!siblingControl) {
    console.warn(`No control found for: ${field}`);
  }
  return siblingControl;
}

function getName(control: AbstractControl): string | null {
  const group = <UntypedFormGroup>control.parent;

  if (!group) {
    return null;
  }

  let name = '(control name not found)';

  Object.keys(group.controls).forEach((key) => {
    const childControl = group.get(key);

    if (childControl !== control) {
      return;
    }

    name = key;
  });

  return name;
}

export class DisableFieldIf {
  /**
   * Disable or enable field on form if sibling field has a particular value.
   *
   * @param field - The key of the field to evaluate the value of.
   * @param value - The value to check for.
   * @param isNot - Optional flag to enable field if value matches and disable otherwise. Defaults to false, which will disable field if values match.
   */
  static siblingHasValueOf(field: string, value: any, isNot = false) {
    return (control: AbstractControl) => {
      if (control.disabled) return;
      const siblingFieldValue = control.parent.controls[field].value;

      if ((siblingFieldValue === value || !isNot) && !(siblingFieldValue === value && !isNot)) {
        if (control.disabled) {
          control.enable();
        }
      } else {
        if (control.enabled) {
          control.disable();
          control.setValue(null);
        }
      }
    };
  }

  /**
   * Disables list definitions if current control has value that matches the value defined in the configuration
   *
   * @param disablerConfig - Disabler config used to define what definitions require what value to be disabled or enabled
   * @param isNot - Optional flag that inverts the results
   */
  static valueIsTruthy(
    { value, field, clearValue, validateWhenSiblingIsDisabled }: DisablerConfig,
    isNot = false
  ): ValidatorFn {
    let siblingControlSub;
    let shouldDisable;
    return (control: UntypedFormControl) => {
      if (!control.parent || siblingControlSub) return null;
      // This keeps the desired disabled state when changes to state occur not related to sibling
      control.registerOnDisabledChange((isDisabled) => {
        if (!isDisabled && shouldDisable) {
          control.disable();
        } else if (control.parent.enabled && isDisabled && !shouldDisable) {
          control.enable();
        }
      });

      const validateFn = (siblingValue) => {
        if (!validateWhenSiblingIsDisabled && siblingControl.disabled) return;
        if (value === undefined) {
          shouldDisable = !isTruthy(siblingValue);
        } else if (Array.isArray(value)) {
          shouldDisable = !value.includes(siblingValue);
        } else {
          shouldDisable = value !== siblingValue;
        }
        disableControl(control, invertBoolean(shouldDisable, isNot), clearValue);
      };
      const siblingControl = getSibling(control, field);
      validateFn(siblingControl.value);
      siblingControlSub = siblingControl.valueChanges.subscribe(validateFn);
    };
  }

  static valueContains({
    field,
    value,
    isNot,
    clearValue,
    validateWhenSiblingIsDisabled
  }: DisablerConfig): ValidatorFn {
    let siblingControlSub;
    let shouldDisable;
    return (control: UntypedFormControl) => {
      if (value === undefined) {
        console.error(`valueContains validator requires value to be defined. Field: ${getName(control)}`);
        return null;
      }
      if (!control.parent || siblingControlSub) return null;

      control.registerOnDisabledChange((isDisabled) => {
        if (!isDisabled && shouldDisable) {
          control.disable();
        } else if (control.parent.enabled && isDisabled && !shouldDisable) {
          control.enable();
        }
      });

      const siblingControl = getSibling(control, field);
      siblingControlSub = siblingControl.valueChanges.subscribe((siblingValue) => {
        if (!validateWhenSiblingIsDisabled && siblingControl.disabled) return;
        siblingValue = !siblingValue || !siblingValue.length ? [] : siblingValue;
        shouldDisable = invertBoolean(!siblingValue.includes(value), isNot);
        disableControl(control, shouldDisable, clearValue);
      });
    };
  }
}
