import { Inject, Injectable, isDevMode, Optional } from '@angular/core';
import { LabelValue } from '@lib-resource/label-value.model';
import { AsyncOptions, ASYNC_OPTIONS } from './options.model';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  constructor(@Optional() @Inject(ASYNC_OPTIONS) private asyncOptions: AsyncOptions<any>[]) {
    asyncOptions.forEach((asyncOption: AsyncOptions<any>) => {
      this.asyncOptions[asyncOption.key] = asyncOption;
    });
  }

  getAsyncOptionsService(key: string): AsyncOptions<any> {
    const asyncOptions = this.asyncOptions[key];
    if (!asyncOptions && isDevMode()) {
      console.error(
        `No AsyncOption service was found for ${key}. 
        There may be a mismatch in name or it was not 
        provided in app.module.ts`
      );
    }
    return asyncOptions;
  }

  getOptions(key: string): LabelValue<string, any>[] {
    return [];
  }
}
