<div class="mt-2 collapsible-form dense">
  <app-form
    #aggregateForm="appForm"
    id="aggregateForm"
    [definitions]="defs"
    [data]="localFormData"
    [editing]="!readOnly"
    [alwaysEditing]="!readOnly"
    [importData]="importData"
    [importDataOverwrites]="true"
    [appOcrOverlay]="ocrData"
    (valueChanges)="formChanges($event)"
  >
    <div class="quote-response-base-form-group-layout-outer" *ngIf="aggregateForm.form">
      <app-table-array
        [control]="getFormControl(optionsDataDef.key)"
        [definition]="optionsDataDef"
        [componentHeight]="formGroupArrayHeight"
        (doClone)="clone(optionsDataDef, $event)"
        (doRemove)="remove(optionsDataDef, $event)"
        class="pb-3 ocr-overlay-wrapper"
      ></app-table-array>
    </div>
  </app-form>
</div>
