import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { generateLayout } from '@form-lib/utils/layout.utils';
import { DataDefModel } from '@lib-resource/data-def.model';

function classNameGenerator(activation: string, value: number) {
  return `span-${activation}-${value}`;
}

@Directive({
  selector: '[fieldLayout]'
})
export class FieldLayoutDirective implements OnChanges {
  private _klasses: string[] = [];
  @Input() definition: DataDefModel;

  constructor(private renderer2: Renderer2, private el: ElementRef) {}

  ngOnChanges({ definition }: SimpleChanges) {
    if (!definition) return;
    if (definition.currentValue) {
      // reset classes
      if (this._klasses.length) {
        this._klasses.forEach((klass) => {
          this.renderer2.removeClass(this.el.nativeElement, klass);
        });
        this._klasses = [];
      }

      // Compute layout
      const { sm, xs, gtSm } = generateLayout(definition.currentValue.layout);
      // set new classes
      this._klasses.push(classNameGenerator('xs', xs));
      this._klasses.push(classNameGenerator('sm', sm));
      this._klasses.push(classNameGenerator('gt-sm', gtSm));
      this._klasses.forEach((klass) => {
        this.renderer2.addClass(this.el.nativeElement, klass);
      });
    }
  }
}
