<div matRipple class="wrapper mat-elevation-z2">
  <a
    [ngClass]="isSelected() ? 'bg-primary' : 'bg-white'"
    class="kpi-link grid-gap-2"
    (click)="toggle()"
    [routerLink]="[]"
  >
    <div [ngClass]="!isSelected() ? 'bg-white' : 'bg-primary'" class="kpi-value">{{ value }}</div>
    <div class="kpi-label kpi-label-container">
      <div>
        <fa-icon
          *ngIf="labelIcon"
          class="kpi-label-icon"
          [ngClass]="{ 'color-rmt-dark-tint-1': !isSelected() }"
          [icon]="labelIcon"
        ></fa-icon>
      </div>
      <div>{{ label }}</div>
    </div>
  </a>
</div>
