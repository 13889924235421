import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationConfig } from './notification.models';

@Component({
  selector: 'app-snack-bar-component',
  template: `
    <div class="snack-bar-container">
      <mat-icon color="data.type">notifications</mat-icon>
      <span style="overflow-wrap: break-word;">{{ data.message }}</span>
      <button
        *ngIf="data.action"
        mat-button
        aria-hidden="true"
        class="action"
        aria-label="Close notification"
        style="color: white; text-decoration: underline"
        (click)="data.action()"
      >
        {{ data.actionText }}
      </button>
      <mat-icon class="material-icons close-click" (click)="close()">close</mat-icon>
    </div>
    <div *ngIf="data?.items?.length > 0" class="pt-1">
      <div class="snack-bar-line mb-1"></div>
      <div *ngFor="let item of data?.items" class="snack-bar-item">
        {{ item }}
      </div>
    </div>
  `,
  styles: [
    `
      .snack-bar-container {
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        align-items: center;
        grid-column-gap: 0.5rem;
      }
      .snack-bar-line {
        border-bottom: 1px solid;
      }
      .snack-bar-item {
        padding-left: 2rem;
      }
      .close-click {
        cursor: pointer;
      }
    `
  ]
})
export class NotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationConfig,
    private snackbarRef: MatSnackBarRef<NotificationComponent>
  ) {}

  close() {
    this.snackbarRef.dismiss();
  }
}
