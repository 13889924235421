import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { MessageModel } from '@common/components/message/message.model';
import { Component, Inject, ViewChild } from '@angular/core';
import { OnlyWhenValid, SubmitModel } from '@form-lib/models/submit.model';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SimpleFormComponent } from '@form-lib/forms/simple-form/simple-form.component';
import { FileDocType } from '@file-upload-lib/file.model';
import { FormDialogService } from '@form-lib/services/form-dialog.service';
import { filter, finalize } from 'rxjs/operators';
import { DocTypeMappingPipe } from '@shared/pipes/doctype-mapping.pipe';
import { MessageService } from '@common/components/message/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@shared/notifications/notification.service';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Store } from '@ngxs/store';
import { QuoteRequestModel } from '@common/models/quote-request.model';
import { QuoteRequestService } from '@common/services/quote-request.service';
import { ActionDef } from '@data-table-lib/models/data-table.model';
import { faTrashCircle } from '@fortawesome/pro-solid-svg-icons';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';

@Component({
  templateUrl: './quote-response-message-dialog.component.html'
})
export class QuoteResponseMessageDialogComponent {
  quoteResponses: QuoteResponseModel[];
  readOnly: boolean = false;
  creating: boolean = false;
  quoteRequest: QuoteRequestModel;
  @ViewChild('sendMessageForm') sendMessageForm: SimpleFormComponent;
  attachedSupportFiles: any[] = [];
  message: any = {};

  toMessageDef: DataDefModel = new DataDefModel({
    key: 'toOrgIds',
    label: 'To:',
    type: DATA_TYPES.multiSelect,
    layout: {
      base: 12
    }
  });

  subjectMessageDef: DataDefModel = new DataDefModel({
    key: 'subject',
    label: 'Subject',
    type: DATA_TYPES.text,
    validators: {
      required: true,
      maxLength: 100
    },
    layout: {
      base: 12
    }
  });

  bodyMessageDef: DataDefModel = new DataDefModel({
    key: 'body',
    label: 'Message',
    type: DATA_TYPES.textarea,
    validators: {
      required: true,
      maxLength: 4000
    },
    layout: {
      base: 12
    }
  });

  docTypeDef: DataDefModel = new DataDefModel({
    key: 'docType',
    label: 'Type',
    type: DATA_TYPES.select
  });

  fileNameDef: DataDefModel = new DataDefModel({
    label: 'Name',
    key: 'name',
    type: DATA_TYPES.text
  });

  fileDescriptionDef: DataDefModel = new DataDefModel({
    label: 'Description',
    key: 'description',
    type: DATA_TYPES.text
  });

  messageFormDefinitions: DataDefModel[];
  fileColumns: DataDefModel[];
  displayedFileColumns: string[];

  fileActions: ActionDef[] = [
    {
      label: 'Delete',
      icon: faTrashCircle,
      hideIf: () => this.readOnly,
      callback: (row) => {
        this.removeFile(row);
      }
    }
  ];

  fileIndexCounter: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData,
    private dialogRef: MatDialogRef<QuoteResponseMessageDialogComponent>,
    private formDialogService: FormDialogService,
    private docTypePipe: DocTypeMappingPipe,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private store: Store,
    private quoteRequestService: QuoteRequestService
  ) {
    this.quoteResponses = dialogData.quoteResponses;
    this.readOnly = dialogData.readOnly;
    this.docTypeDef = {
      ...this.docTypeDef,
      options: dialogData.docTypes.map((docType: FileDocType) => ({
        label: this.docTypePipe.transform(docType),
        value: docType
      }))
    };
    this.quoteRequest = dialogData.quoteRequest;
    this.toMessageDef = {
      ...this.toMessageDef,
      options: [
        ...this.quoteResponses.map((quoteResponse) => ({
          label: quoteResponse.org.name,
          value: quoteResponse.org.id
        })),
        {
          label: this.quoteResponses[0]?.quoteRequest.quoteType.orgName,
          value: this.quoteResponses[0]?.quoteRequest.quoteType.orgId
        }
      ],
      validators: !!this.quoteRequest ? { required: true } : undefined,
      readOnly: !this.quoteRequest
    };
    this.messageFormDefinitions = [this.toMessageDef, this.subjectMessageDef, this.bodyMessageDef];
    this.fileColumns = [this.fileNameDef, this.fileDescriptionDef, this.docTypeDef];
    this.displayedFileColumns = this.fileColumns.map((def) => def.key);
    this.message.toOrgIds = dialogData.toOrgIds;
    this.message.subject = dialogData.replySubject;
  }

  addFile(message: MessageModel, file) {
    this.formDialogService
      .open({
        label: `Edit Supporting File Information`,
        actionText: 'Save',
        definitions: [
          new DataDefModel({
            label: 'Description',
            key: 'description',
            type: DATA_TYPES.text
          }),
          this.docTypeDef
        ]
      })
      .afterClosed()
      .pipe(filter((val) => !!val))
      .subscribe((dialogFormResult) => {
        file.description = dialogFormResult.description;
        file.docType = !!dialogFormResult.docType ? dialogFormResult.docType : FileDocType.OTHER;
        file.index = this.fileIndexCounter++;
        this.attachedSupportFiles = [...this.attachedSupportFiles, file];
      });
  }

  removeFile(removeFile) {
    this.attachedSupportFiles = this.attachedSupportFiles.filter((file) => file.index !== removeFile.index);
  }

  @OnlyWhenValid
  onSubmit(event: SubmitModel): void {
    this.creating = true;
    this.message = { ...this.message, ...event.value };
    let formData: FormData = new FormData();
    if (this.quoteRequest) {
      formData.append(
        'bulkMessageDTO',
        new Blob(
          [
            JSON.stringify({
              orgIds: this.message.toOrgIds,
              subject: this.message.subject,
              body: this.message.body,
              attachedSupportFiles: this.attachedSupportFiles.map((file) => ({
                name: `${file.name}_${file.index}`,
                description: file.description,
                docType: file.docType
              }))
            })
          ],
          { type: 'application/json' }
        )
      );
      this.attachedSupportFiles.forEach((file) => {
        // Index is here just in case the user uploaded more than one file with the same name
        formData.append(`${file.name}_${file.index}`, file);
      });
      this.quoteRequestService
        .createResponseMessages(this.quoteRequest.id, formData)
        .pipe(
          finalize(() => {
            this.creating = false;
          })
        )
        .subscribe(
          (_) => {
            event.success();
            this.dialogRef.close();
            this.notificationService.successfulNotification(`Message sent to selected Organizations`);
          },
          (error) => {
            if (error instanceof HttpErrorResponse) return;
            event.failure();
            this.dialogRef.close();
            this.notificationService.failedNotification(`Failed to send message to selected Organizations ${error}`);
          }
        );
    } else {
      formData.append(
        'quoteResponseMessageDTO',
        new Blob(
          [
            JSON.stringify({
              subject: this.message.subject,
              body: this.message.body,
              quoteResponse: this.quoteResponses[0],
              sender: this.store.selectSnapshot(UserSelectors.user),
              senderOrg: {
                id: this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)
              },
              attachedSupportFiles: this.attachedSupportFiles.map((file) => ({
                name: `${file.name}_${file.index}`,
                description: file.description,
                docType: file.docType
              }))
            })
          ],
          { type: 'application/json' }
        )
      );
      this.attachedSupportFiles.forEach((file) => {
        // Index is here just in case the user uploaded more than one file with the same name
        formData.append(`${file.name}_${file.index}`, file);
      });
      this.messageService
        .create(this.quoteResponses[0].id, formData)
        .pipe(
          finalize(() => {
            this.creating = false;
          })
        )
        .subscribe(
          (_) => {
            event.success();
            this.dialogRef.close();
            this.notificationService.successfulNotification('Message sent successfully');
          },
          (error) => {
            if (error instanceof HttpErrorResponse) return;
            event.failure();
            this.dialogRef.close();
            this.notificationService.failedNotification(`Failed to send message ${error}`);
          }
        );
    }
  }
}
