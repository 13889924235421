import { NgModule } from '@angular/core';
import { InfoDisplayItemComponent } from '@info-lib/info-display/info-display-item.component';
import { InfoDisplayVerticalComponent } from '@info-lib/info-display/info-display-vertical.component';
import { LibResourceModule } from '@lib-resource/lib-resource.module';
import { SharedModule } from '@shared/shared.module';
import { InfoBadgeComponent } from '@info-lib/info-badge/info-badge.component';
import { InfoTableComponent } from '@info-lib/info-table/info-table.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { InfoDisplayDialogComponent } from './info-display-dialog/info-display-dialog.component';
import { InfoDisplayComponent } from './info-display/info-display.component';

const exportItems = [
  InfoDisplayItemComponent,
  InfoDisplayComponent,
  InfoDisplayVerticalComponent,
  InfoCardComponent,
  InfoBadgeComponent,
  InfoTableComponent
];
const entryComponents = [InfoDisplayDialogComponent, InfoBadgeComponent, InfoTableComponent];

@NgModule({
  declarations: [...exportItems, ...entryComponents],
  exports: [...exportItems],
  imports: [LibResourceModule, SharedModule]
})
export class InfoLibModule {}
