<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label label="{{ dialogLabel }}"></app-dialog-label>
<app-dialog-content [appInProgressIndicator]="loading" class="file-preview">
  <mat-selection-list [(ngModel)]="selectedOptions" class="manifest card-corners" dense>
    <mat-list-item>
      <div class="list-header">
        <mat-checkbox
          class="select-all"
          [checked]="isSelectAllChecked()"
          [indeterminate]="isSelectAllIndeterminate()"
          (change)="$event ? toggleSelectAllCheckBox($event) : null"
        >
          <span class="file-header">{{ selectAllLabel() }}</span>
        </mat-checkbox>
      </div>
    </mat-list-item>
    <div class="list-item" *ngFor="let item of manifest">
      <mat-list-option class="option" togglePosition="before" [value]="item">
        <div truncateText matListItemTitle>
          {{ getFileName(item) }}
        </div>
      </mat-list-option>
      <div class="list-doctype">
        {{ getDocType(item) }}
        <div class="list-doctype" *ngIf="item.rmtFile">{{ item.rmtFile.createdDate | appDateTime }}</div>
      </div>
      <button mat-button class="view-button" (click)="viewFile(item)">
        <mat-icon>
          {{ getViewIcon(item) }}
        </mat-icon>
      </button>
    </div>
  </mat-selection-list>
  <div class="text-center" *ngIf="!viewItemStream">
    <p>{{ messageText }}</p>
  </div>
  <ng2-pdfjs-viewer class="pdf-viewer" *ngIf="viewItemStream" [pdfSrc]="viewItemStream"></ng2-pdfjs-viewer>
</app-dialog-content>
<app-dialog-actions>
  <div class="grid-content">
    <button
      [appInProgressIndicator]="downloading"
      mat-raised-button
      color="primary"
      [disabled]="isDownloadButtonDisabled()"
      (click)="submitManifest()"
    >
      Download
    </button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </div>
</app-dialog-actions>
