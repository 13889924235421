<app-dialog-close icon matDialogClose></app-dialog-close>
<app-dialog-label>
  {{ data.quoteResponse.status === 'SUBMITTED' && data.version.id > 0 ? 'Update' : 'Submit' }}
  {{ data.version ? 'Version ' + data.version.versionId : 'Proposal' }}
  <span class="dialog-message">
    <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
    Verify these minimum fields for accuracy.
  </span>
</app-dialog-label>
<app-dialog-content>
  <div class="mat-h1" style="font-weight: 100">General Information</div>
  <div class="mt-2">
    <app-form
      #quoteResponseSubmitForm="appForm"
      id="quoteResponseSubmitForm"
      [definitions]="definitions"
      [data]="data.quoteResponse"
      [editing]="!readOnly"
      (doSubmit)="submit($event)"
      class="dense submit-proposal-form"
    >
      <div *ngIf="quoteResponseSubmitForm.form">
        <div class="grid mb-3" style="width: 60%">
          <app-date-field
            [control]="getFormControl(effectiveDateDef.key)"
            [dataDef]="effectiveDateDef"
            [inline]="true"
            class="submit-proposal-datepicker mb-3"
          >
          </app-date-field>
          <app-select-field [control]="getFormControl(typeDef.key)" [dataDef]="typeDef" [inline]="true" class="mb-3">
          </app-select-field>
          <app-select-field
            [control]="getFormControl(laserConfirmationDef.key)"
            [dataDef]="laserConfirmationDef"
            [inline]="true"
            class="mb-3"
          >
          </app-select-field>
          <app-derived-value-field
            [control]="getFormControl(estTotalEEsDef.key)"
            [dataDef]="estTotalEEsDef"
            [inline]="true"
            class="mb-2 field-disable"
          >
          </app-derived-value-field>
        </div>
        <div class="mb-3">
          <app-table-array
            [control]="getFormControl(optionsDef.key)"
            [definition]="optionsDef"
            [itemsAddable]="false"
            [itemsRemovable]="false"
          ></app-table-array>
        </div>
      </div>
    </app-form>
  </div>
</app-dialog-content>
<app-dialog-actions>
  <button
    mat-raised-button
    [color]="quoteResponseSubmitForm?.invalid ? 'warn' : 'primary'"
    [disabled]="saving"
    (click)="quoteResponseSubmitForm.submit()"
    [appInProgressIndicator]="saving"
  >
    <fa-icon [icon]="errorIcon" *ngIf="quoteResponseSubmitForm?.invalid"></fa-icon>
    {{ data.quoteResponse.status === 'SUBMITTED' && data.version.id > 0 ? 'Update' : 'Submit' }}
  </button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</app-dialog-actions>
