import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogData } from '@common/dialogs/dialogs.model';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';

@Component({
  template: `
    <app-dialog-close icon appCloseDialog></app-dialog-close>
    <app-dialog-label><span [innerHTML]="data.label"></span></app-dialog-label>
    <app-dialog-content><p [innerHTML]="data.message"></p></app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button appCloseDialog>Close</button>
    </app-dialog-actions>
  `,
  styleUrls: ['../dialogs.scss']
})
export class MessageDialogComponent {
  data: MessageDialogData;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: MessageDialogData,
    @Optional() @Inject(POPOVER_DATA) popoverData: MessageDialogData
  ) {
    this.data = !!dialogData ? dialogData : popoverData;
  }
}
