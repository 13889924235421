import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface FileUploadDialogData {
  title?: string;
  description?: string;
  multiple?: boolean;
}

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>{{ label }}</app-dialog-label>
    <app-dialog-content>
      <app-file-upload [multi]="multiple" (selectFile)="fileSelected($event)"></app-file-upload>
      <app-file-list-concise [files]="files" (removeFile)="removeFile($event)"></app-file-list-concise>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button color="primary" [mat-dialog-close]="files" [disabled]="!files.length">Upload</button>
      <button mat-raised-button mat-dialog-close>Cancel</button>
    </app-dialog-actions>
  `
})
export class FileUploadDialogComponent {
  label: string;
  files: File[] = [];
  multiple: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data: FileUploadDialogData) {
    this.label = data?.title ? data.title : 'Upload files';
    this.multiple = data?.multiple;
  }

  fileSelected(file) {
    if (!this.multiple) {
      this.files = [file];
    } else {
      this.files.push(...file);
    }
  }

  removeFile(removedFile) {
    this.files = this.files.filter((file) => removedFile !== file);
  }
}
