import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PreferredAccountModel } from '@common/models/preferred-accounts.model';

@Injectable({
  providedIn: 'root'
})
export class PreferredAccountsService {
  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  constructor(private http: HttpClient, private store: Store) {}

  getData(): Observable<PreferredAccountModel[]> {
    return this.http.get<PreferredAccountModel[]>(`${this.baseUrl}/preferredaccount`);
  }

  getPreferredAccountsForOrg(orgId: number): Observable<PreferredAccountModel[]> {
    return this.http.get<PreferredAccountModel[]>(`${SERVER_API_URL}/org/${orgId}/preferredaccount`);
  }

  create(data: PreferredAccountModel) {
    return this.http.post(`${this.baseUrl}/preferredaccount`, {
      ...data
    });
  }

  update(preferredAccountId: number, data: PreferredAccountModel) {
    return this.http.put(`${this.baseUrl}/preferredaccount/${preferredAccountId}`, {
      ...data
    });
  }

  delete(preferredAccountId: number) {
    return this.http.delete<PreferredAccountModel[]>(`${this.baseUrl}/preferredaccount/${preferredAccountId}`);
  }
}
