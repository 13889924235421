import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AsyncValidatorFn, FormGroupDirective, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { toObject } from '@app/tools/array';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { FieldRendererComponent } from '@form-lib/field-renderer/field-renderer.component';
import { SubmitModel } from '@form-lib/models/submit.model';
import { AsyncFormValidatorDef, FormValidatorDef } from '@form-lib/models/validators.model';
import { FormContainerService } from '@form-lib/services/form-container.service';
import { FormErrorService } from '@form-lib/services/form-error.service';
import { FormGeneratorService } from '@form-lib/services/form-generator.service';
import { markFormGroupTouched } from '@form-lib/utils/control.utils';
import { DataDefModel } from '@lib-resource/data-def.model';
import { unflattenData } from '@lib-resource/data.utils';
import { asapScheduler, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, observeOn, shareReplay, startWith } from 'rxjs/operators';

export enum StatusChangeEvent {
  editing = 'EDITING',
  reset = 'RESET',
  cancel = 'CANCEL',
  submit = 'SUBMIT'
}

export class StatusChange {
  event: StatusChangeEvent;
  value?: any;
}

@Component({
  selector: 'app-form',
  exportAs: 'appForm',
  template: `
    <form *ngIf="_form$ | async as formGroup" [formGroup]="formGroup" (ngSubmit)="submit()" class="mat-typography">
      <ng-content></ng-content>
    </form>
  `
})
export class BaseFormComponent implements OnInit, OnChanges, OnDestroy {
  // clone the definitions here and create the formKeys in the process.  Invalid characters cause angular forms to complain.
  _definitions: DataDefModel[];
  definitionsMap: {
    [prop: string]: DataDefModel;
  };

  resetData: any;
  get invalid() {
    return this.form?.invalid;
  }

  get valid() {
    return this.form?.valid;
  }

  form: UntypedFormGroup;
  submitted: boolean;

  // Forces form creation when this component is not used as a base class
  _form$ = of(null).pipe(
    observeOn(asapScheduler),
    map(() => {
      this.setForm();
      return this.form;
    })
  );

  protected sub: Subscription;

  @Input() id: string;
  @Input() definitions: DataDefModel[];

  @Input() data;
  @Input() importData;
  @Input() importDataOverwrites = false;
  @Input() validators: Array<FormValidatorDef | ValidatorFn>;
  @Input() asyncValidators: Array<AsyncFormValidatorDef | AsyncValidatorFn>;
  @Input() alwaysEditing = false;
  @Input() toggleEditOnDataChange = true;
  @Input() valueChangesRawValue: boolean = false;
  private _editing = false;
  @Input()
  set editing(val) {
    this._editing = val;
  }

  get editing() {
    if (this.alwaysEditing) return true;
    return this._editing;
  }

  // Determines what definitions will be displayed in simple forms, and determines
  // what definitions are made into FormControls for all forms
  @Input() displayItems: string[];
  _displayedDefinitions: DataDefModel[];

  @Input() addFormGroupArrayItemToTop: boolean = false;

  @ViewChild(FormGroupDirective, { static: false }) formGroupRef: FormGroupDirective;
  // This is needed for OCR overlay
  @ViewChildren(FieldRendererComponent) fieldComponents: QueryList<FieldRendererComponent>;
  @ContentChildren(FieldRendererComponent, { descendants: true }) contentFieldComponents: QueryList<
    FieldRendererComponent
  >;

  @ViewChildren(BaseArrayComponent) arrayComponent: QueryList<BaseArrayComponent>;
  // array components that come from ng-content of the base form
  @ContentChildren(BaseArrayComponent, { descendants: true }) contentArrayComponent: QueryList<BaseArrayComponent>;

  private valueChangesSource = new Subject();
  @Output() valueChanges: Observable<any> = this.valueChangesSource.asObservable();
  @Output() invalidFieldsDefs = this.valueChanges.pipe(
    startWith({}),
    map((_) => {
      if (!this.form) return null;
      const errorControls = this.formErrorService.getControlsWithErrors(this.form);
      const errorDefs = this.formErrorService.getDefsForErrorKeys(this.definitions, errorControls);
      return errorDefs.length ? errorDefs : null;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  @Output() invalidFieldsLabels = this.invalidFieldsDefs.pipe(
    map((defs) => (!!defs?.length ? defs.map((def) => def.label) : null))
  );

  @Output() statusChanges = new EventEmitter<StatusChange>();
  @Output() doSubmit = new EventEmitter<SubmitModel>();
  @Output() doCancel = new EventEmitter();

  // this flag marks the form as being rendered, typically on the transition from edit to readonly (and back)
  renderingLayout = false;

  constructor(
    protected formService: FormGeneratorService,
    protected formErrorService: FormErrorService,
    protected formContainerService: FormContainerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.resetData = this.data;
    }

    if (changes.importData && this.importData) {
      if (this.form) {
        this.form.markAsDirty();
      }
      if (this.importDataOverwrites) {
        // overwrite everything except readonly fields and fields not derived
        // building an object from the readonly keys which can, and usually do, include dot notation
        const readOnlyData = {};
        this.definitions
          .filter((def) => def.readOnly && !def.derivedValue)
          .forEach((readOnlyDef) => {
            const keys = readOnlyDef.formKey.split('/');
            keys.reduce((prev, current, currentIndex) => {
              if (prev[current] === undefined) {
                if (currentIndex < keys.length - 1) {
                  // if we're not at the leaf then create an object literal
                  prev[current] = {};
                } else {
                  // at the leaf, set the value
                  prev[current] = this.form.controls[readOnlyDef.formKey].value;
                }
              }
              return prev[current];
            }, readOnlyData);
          });
        this.data = { ...this.importData, ...readOnlyData };
      } else {
        this.data = { ...this.data, ...this.importData };
      }
      // cycle through the toggle edit to unset the rendering and also allow the form to validate
      this.toggleEdit(this.editing);
    }

    if ((changes.data || changes.importData) && this.form) {
      this.formService.setFormValue(this._displayedDefinitions, this.form, this.data);
    }

    if (changes.definitions) {
      this.definitionsMap = toObject(this.definitions);
    }
    if (changes.definitions || changes.displayItems) {
      this.setDisplayedDefinitions();
    }
    if (((changes.data && this.toggleEditOnDataChange) || changes.editing) && this.form) {
      this.toggleEdit(changes.editing ? this.editing : false);
    }
  }

  ngOnInit() {
    if (this.id) {
      this.formContainerService.registerForm(this);
    }
  }

  ngOnDestroy() {
    this.destroyFormRefs();
  }

  destroyFormRefs() {
    if (this.sub) this.sub.unsubscribe();
    if (this.id) this.formContainerService.unregisterForm(this.id);
  }

  setDisplayedDefinitions() {
    if (this.displayItems) {
      this._displayedDefinitions = this.displayItems.map((key) => this.definitionsMap[key]);
    } else {
      this._displayedDefinitions = this.definitions;
    }
  }

  // first = true;
  setForm(resetData?) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.form = this.formService.generateFormGroup(
      this._displayedDefinitions,
      this.validators,
      this.asyncValidators,
      resetData ? resetData : this.data
    );

    if (this.editing) {
      this.form.enable();
    } else {
      this.form.disable();
    }

    this.setListener();
  }

  setListener() {
    this.destroyFormRefs();
    this.sub = new Subscription();
    this.sub.add(
      this.form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
        this.valueChangesSource.next(unflattenData(this.valueChangesRawValue ? this.form.getRawValue() : value, '/'));
      })
    );
    if (this.id) {
      this.formContainerService.registerForm(this);
    }
  }

  submit(successCb?: () => void, failureCb?: () => void) {
    this.renderingLayout = true;

    markFormGroupTouched(this.form);
    if (this.form.invalid) {
      this.highlightInvalidFields();
    }
    this.submitted = true;
    this.doSubmit.emit({
      value: unflattenData(this.form.value, '/'),
      rawValue: unflattenData(this.form.getRawValue(), '/'),
      success: (editMode = false) => {
        if (successCb) successCb();
        // don't un-set the rendering layout here because the toggleEdit will handle that after the form update
        this.toggleEdit(editMode);
        this.form.markAsPristine();
      },
      failure: () => {
        // un-set the rendering layout here because all form processing is stopped when an error
        this.renderingLayout = false;
        if (failureCb) failureCb();
      },
      valid: this.form.valid,
      invalid: this.form.invalid,
      errors: this.form.errors
    });
    this.statusChanges.emit({
      event: StatusChangeEvent.submit
    });
  }

  reset() {
    (this.formGroupRef as { submitted: boolean }).submitted = false;
    this.data = this.resetData; // This is here because importData changes the data prop;
    this.formService.setFormValue(this._displayedDefinitions, this.form, this.data);
    this.form.markAsPristine();
    this.statusChanges.emit({
      event: StatusChangeEvent.reset
    });
    this.submitted = false;
  }

  highlightInvalidFields() {
    this.formErrorService.reportErrors(this.form, this._displayedDefinitions);
  }

  toggleEdit(edit?: boolean) {
    // set the rendering flag to true and then in the next UI loop update the editing of the fields.  On larger forms it slows down significantly
    //   when switching to and from edit mode.  This flag allows the caller to provide feedback to the end user.
    //   if the renderingLayout flag was set prior to this call, leave it set as it will be cleared after the next UI loop
    this.renderingLayout = this.renderingLayout || edit !== this.editing;
    setTimeout((_) => {
      this.toggleEditSync(edit);
    });
  }

  /**
   * provided to simplify testing, avoiding mocking timers.  Not intended to be called outside of toggleEdit or tests
   */
  toggleEditSync(edit?: boolean) {
    this.editing = edit === undefined ? !this.editing : edit;
    if (this.editing && !(this.alwaysEditing && this.form.enabled)) {
      this.form.markAsUntouched();
      this.form.markAsPristine();
      this.submitted = false;
      if (!!this.formGroupRef) {
        (this.formGroupRef as { submitted: boolean }).submitted = false;
      }
      this.form.enable();
      // go back and disable all the readonly fields because form.enable doesn't care about keeping read only disabled
      this.definitions
        .filter((def) => def.readOnly && !!this.form.controls[def.formKey])
        .forEach((readOnlyDef) => {
          this.form.controls[readOnlyDef.formKey].disable();
        });
    } else if (!this.editing) {
      this.form.disable();
    }
    this.formContainerService.notifyFormToggleEdit({
      form: this.form,
      editing: this.editing
    });
    this.statusChanges.emit({
      event: StatusChangeEvent.editing,
      value: this.editing
    });

    // turn off rendering flag
    this.renderingLayout = false;
  }

  cancel() {
    // set the rendering flag to true and then in the next UI loop update the editing of the fields.  On larger forms it slows down significantly
    //   when switching to and from edit mode.  This flag allows the caller to provide feedback to the end user.
    this.renderingLayout = true;
    setTimeout((_) => {
      this.cancelSync();
    });
  }

  /**
   * provided to simplify testing, avoiding mocking timers.  Not intended to be called outside of cancel or tests
   */
  cancelSync() {
    this.reset();
    this.toggleEditSync(false);
    this.form.markAsPristine();
    this.statusChanges.emit({
      event: StatusChangeEvent.cancel
    });
    this.doCancel.emit();
  }

  addItem(def: DataDefModel, data?: any) {
    this.renderingLayout = true; // set the rendering flag due to large forms taking many seconds to render
    setTimeout((_) => {
      const defs = (def as any).fields ? (def as any).fields : def.definitions;
      const dataModel = def.formGroupArrayInitModel ? { ...def.formGroupArrayInitModel(), data } : data;
      const formArray = this.form.controls[def.formKey] as UntypedFormArray;
      formArray.insert(
        this.addFormGroupArrayItemToTop ? 0 : formArray.length,
        this.formService.generateFormGroup(defs, def.validators as any, undefined, dataModel)
      );
      setTimeout((_) => {
        this.renderingLayout = false;
      });
    });
  }

  removeItem(def: DataDefModel, index: number) {
    this.renderingLayout = true; // set the rendering flag due to large forms taking many seconds to render
    setTimeout((_) => {
      const formArray = this.form.controls[def.formKey] as UntypedFormArray;
      formArray.markAsTouched();
      formArray.markAsDirty();
      formArray.removeAt(index);
      setTimeout((_) => {
        this.renderingLayout = false;
      });
    });
  }

  cloneItem(def: DataDefModel, index: number) {
    this.renderingLayout = true; // set the rendering flag due to large forms taking many seconds to render
    setTimeout((_) => {
      const formArray = this.form.controls[def.formKey] as UntypedFormArray;
      formArray.markAsTouched();
      formArray.markAsDirty();
      // This must use `value` instead of `getRawValue()` because we do not want to clone system set properties
      const data = def.formGroupArrayInitModel
        ? { ...def.formGroupArrayInitModel(), ...(formArray.at(index) as UntypedFormGroup).value }
        : formArray.at(index).value;
      formArray.push(
        this.formService.generateFormGroup(
          def.definitions ? def.definitions : (def as any).fields,
          def.validators as any,
          undefined,
          data
        )
      );
      setTimeout((_) => {
        this.renderingLayout = false;
      });
    });
  }
}
