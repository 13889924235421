import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserJobModel, UserJobStatus } from '@common/models/user-job.model'; // produces a warning without the ignore due to no default class exposed
import { UserJobService } from '@shared/components/user-job-progress/user-job.service';
import { NotificationService } from '@shared/notifications/notification.service';
import { take } from 'rxjs/operators';
import Timeout = NodeJS.Timeout;

export interface UserJobProgressDialogData {
  userJob: UserJobModel;
  title: string;
  fileName: string;
}

@Component({
  templateUrl: 'user-job-progress-dialog.component.html'
})
export class UserJobProgressDialog implements OnInit, OnDestroy {
  autoRefresh: Timeout;
  userJob: UserJobModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserJobProgressDialogData,
    public dialogRef: MatDialogRef<UserJobProgressDialog>,
    private userJobService: UserJobService,
    private notificationService: NotificationService
  ) {
    this.userJob = data.userJob;
  }

  ngOnInit(): void {
    this.startAutoRefresh(2000);
  }

  ngOnDestroy(): void {
    this.cancelAutoRefresh();
  }

  cancelAutoRefresh() {
    if (!!this.autoRefresh) {
      clearTimeout(this.autoRefresh);
    }
  }

  startAutoRefresh(time: number) {
    this.cancelAutoRefresh();
    this.autoRefresh = setTimeout((_) => this.checkJobStatus(), time) as unknown as Timeout;
  }

  checkJobStatus() {
    this.userJobService
      .search(`id = ${this.userJob.id}`, 0, 1)
      .pipe(take(1))
      .subscribe((job) => {
        if (job.content.length === 0) {
          this.notificationService.failedNotification(`Unknown user job with id ${this.userJob.id}`);
        } else {
          this.userJob = job.content[0];
          if (this.userJob.status === UserJobStatus.IN_PROCESS) {
            this.startAutoRefresh(5000);
          } else if (this.userJob.status === UserJobStatus.ERROR) {
            this.notificationService.failedNotification(this.userJob.message);
            this.dialogRef.close(this.userJob);
          } else {
            // completed, if there is an Rmt File, download it
            if (this.userJob.rmtFile) {
              this.userJobService.downloadUserJobFile(this.userJob.id, this.data.fileName);
            }
            this.dialogRef.close(this.userJob);
          }
        }
      });
  }

  close(userJob: UserJobModel) {
    this.dialogRef.close(userJob);
  }
}
