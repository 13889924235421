import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AlertInfoComponent } from '@shared/components/alert-info.component';
import { DropCrumbsDirective } from '@shared/components/bread-crumbs/drop-crumbs.directive';
import { CardHeaderAdditionDirective } from '@shared/components/card/card-header-addition.directive';
import { CollapsibleItemDirective } from '@shared/components/collapsible-container/collapsible-item.directive';
import { CommentsComponent } from '@shared/components/comments/comments.component';
import { CloseDialogDirective } from '@shared/components/dialog/close-dialog.directive';
import { DialogActionsComponent } from '@shared/components/dialog/dialog-actions.component';
import { DialogSecondaryActionsDirective } from '@shared/components/dialog/dialog-actions.directives';
import { DialogCloseButtonComponent } from '@shared/components/dialog/dialog-close-button.component';
import { DialogContentComponent } from '@shared/components/dialog/dialog-content.component';
import { DialogLabelComponent } from '@shared/components/dialog/dialog-label.component';
import { SplashKpiComponent } from '@shared/components/kpis/splash-kpi/splash-kpi.component';
import { PopoverContentDirective } from '@shared/components/popover/popover-content.directive';
import { PopoverTriggerDirective } from '@shared/components/popover/popover-trigger.directive';
import { PopoverComponent } from '@shared/components/popover/popover.component';
import { ReportLibraryComponent } from '@shared/components/report/report-library/report-library.component';
import { SelectSearchControlComponent } from '@shared/components/select-search-control/select-search-control.component';
import { SkeletonLoaderModule } from '@shared/components/skeleton/skeleton-loader.module';
import { StepperComponent } from '@shared/components/stepper/stepper.component';
import { AddItemNgForWrapperDirective } from '@shared/directives/add-item-ng-for-wrapper.directive';
import { AutoFocusDirective } from '@shared/directives/auto-focus.directive';
import { CurrencyFormatterDirective } from '@shared/directives/currency-formatter/currency-formatter.directive';
import { InProgressIndicatorButtonDirective } from '@shared/directives/in-progress-indicator/in-progress-indicator-button.directive';
import { ScrollIntoViewDirective } from '@shared/directives/scroll-into-view.directive';
import { ScrollListenerDirective } from '@shared/directives/scroll-listener/scroll-listener.directive';
import { StatusIndicatorIconDirective } from '@shared/directives/status-indicator/status-indicator-icon.directive';
import { StatusIndicatorButtonDirective } from '@shared/directives/status-indicator/status-indicator.directive';
import { TruncateTextDirective } from '@shared/directives/truncate-text/truncate-text.directive';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { DialogSidebarComponent } from '@shared/components/dialog/dialog-sidebar.component';
import { CsvDownloadProgressDialog } from '@shared/components/file-download-progress/csv-download-progress/csv-download-progress.component';
import { FileDownloadProgressDialog } from '@shared/components/file-download-progress/file-download-progress/file-download-progress.component';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { KpiCountComponent } from '@shared/components/kpis/kpi-count/kpi-count.component';
import { InProgressIndicatorComponent } from '@shared/components/in-progress-indicator/in-progress-indicator.component';
import { AbsPipe } from '@shared/pipes/abs.pipe';
import { DateFormatterDirective } from '@shared/directives/date-formatter/date-formatter.directive';
import { ChartHeaderDirective } from '@common/components/charts/chart-header.directive';
import { VersionAddVPipe } from '@shared/pipes/version-add-v.pipe';
import { UserJobService } from '@shared/components/user-job-progress/user-job.service';
import { UserJobProgressDialog } from '@shared/components/user-job-progress/user-job-progress-dialog.component';
import { UserJobProgressBanner } from '@shared/components/user-job-progress/user-job-progress-banner.component';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { CardHeaderDirective } from './components/card/card-header.directive';
import { CardLabelComponent } from './components/card/card-label.component';
import { CardComponent } from './components/card/card.component';
import { CollapsibleContainerComponent } from './components/collapsible-container/collapsible-container.component';
import { FlowNavigationControlsComponent } from './components/flow-navigation-controls/flow-navigation-controls.component';
import { FlowNavigationLinksComponent } from './components/flow-navigation-links/flow-navigation-links.component';
import { KpiCollectionComponent } from './components/kpis/kpi-collection/kpi-collection.component';
import { KpiMetricComponent } from './components/kpis/kpi-metric/kpi-metric.component';
import { LinearChecklistComponent } from './components/linear-checklist/linear-checklist.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { DisableAnchorLinkDirective } from './directives/disable-anchor-link/disable-anchor-link.directive';
import { InProgressIndicatorDirective } from './directives/in-progress-indicator/in-progress-indicator.directive';
import { RouterLinkActiveDirective } from './directives/router-link-active.directive';
import { NotificationComponent } from './notifications/notification.component';
import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import { AppDateTimePipe } from './pipes/app-date-time.pipe';
import { AppDatePipe } from './pipes/app-date.pipe';
import { BooleanYesNoPipe } from './pipes/boolean-yes-no.pipe';
import { DateOrDaysPipe } from './pipes/dateOrDays.pipe';
import { DocTypeMappingPipe } from './pipes/doctype-mapping.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { IconClassMappingPipe } from './pipes/icon-class-mapping.pipe';
import { AppPercentPipe } from './pipes/percent.pipe';
import { PropertyFilterPipe } from './pipes/property-filter.pipe';
import { QueryOperatorPipe } from './pipes/query-operator.pipe';
import { SicToObject } from './pipes/sicToObject.pipe';
import { ArraySortPipe } from './pipes/sort.pipe';

const moduleExports = [
  CommonModule,
  FontAwesomeModule,
  FormsModule,
  MaterialModule,
  PortalModule,
  ReactiveFormsModule,
  RouterModule,
  SkeletonLoaderModule
];

const exportItems = [
  AbsPipe,
  AddItemNgForWrapperDirective,
  AlertInfoComponent,
  AppCurrencyPipe,
  AppDatePipe,
  AppDateTimePipe,
  AppPercentPipe,
  ArraySortPipe,
  AutoFocusDirective,
  BooleanYesNoPipe,
  BreadCrumbsComponent,
  CardComponent,
  CardHeaderAdditionDirective,
  CardHeaderDirective,
  CardLabelComponent,
  ChartHeaderDirective,
  ClickOutsideDirective,
  CloseDialogDirective,
  CollapsibleContainerComponent,
  CollapsibleItemDirective,
  CommentsComponent,
  CsvDownloadProgressDialog,
  CurrencyFormatterDirective,
  DateFormatterDirective,
  DateOrDaysPipe,
  DialogActionsComponent,
  DialogCloseButtonComponent,
  DialogContentComponent,
  DialogLabelComponent,
  DialogSecondaryActionsDirective,
  DialogSidebarComponent,
  DisableAnchorLinkDirective,
  DocTypeMappingPipe,
  DropCrumbsDirective,
  FileDownloadProgressDialog,
  FileSizePipe,
  FilterPipe,
  FlowNavigationControlsComponent,
  FlowNavigationLinksComponent,
  IconClassMappingPipe,
  InProgressIndicatorButtonDirective,
  InProgressIndicatorComponent,
  InProgressIndicatorDirective,
  KpiCollectionComponent,
  KpiCountComponent,
  KpiMetricComponent,
  LinearChecklistComponent,
  NotFoundComponent,
  PopoverComponent,
  PopoverContentDirective,
  PopoverTriggerDirective,
  PropertyFilterPipe,
  QueryOperatorPipe,
  ReportLibraryComponent,
  RouterLinkActiveDirective,
  ScrollIntoViewDirective,
  ScrollListenerDirective,
  SelectSearchControlComponent,
  SicToObject,
  SplashKpiComponent,
  StatusIndicatorButtonDirective,
  StatusIndicatorIconDirective,
  StepperComponent,
  TruncateTextDirective,
  UserJobProgressDialog,
  UserJobProgressBanner,
  VersionAddVPipe
];

const entryComponents = [NotificationComponent];

@NgModule({
  imports: [...moduleExports, NgxMatSelectSearchModule, OverlayModule],
  declarations: [...exportItems, ...entryComponents],
  exports: [...exportItems, ...moduleExports],
  providers: [FileDownloadService, DocTypeMappingPipe, UserJobService]
})
export class SharedModule {}
