import { DataTypes } from '@lib-resource/data-def.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export class DerivedIcon {
  icon: IconDefinition;
  classes?: string[];
  inverse?: boolean;
  stackItemSize?: '1x' | '2x';
  iconText?: string;

  constructor(icon: IconDefinition, classes?: string[], stackItemSize?: '1x' | '2x', hoverText?: string) {
    this.icon = icon;
    this.classes = classes || [];
    this.inverse = false;
    this.stackItemSize = stackItemSize;
    this.iconText = hoverText || '';
  }
}

export class ActionDef {
  label: string;
  key?: string;
  icon?: IconDefinition;
  hideIf?: (_?: any) => boolean;
  disableIf?: (_?: any) => boolean;
  disabledMessage?: string;
  callback?: (value: any, index?: number, values?: any[], event?: any) => any;
  link?: (_?: any) => string;
  linkParams?: (_?: any) => { workspace: any } | { breadCrumb: any } | { query: FilterModel[] };
  derivedLabel?: (_?: any) => string;
  derivedIcon?: (value: any) => DerivedIcon[];
  derivedDisabledMessage?: (value: any) => string;
  classes?: string[];
}

export class QueryOperator {
  constructor(
    public label: string,
    public operator: string,
    public multi: boolean
  ) {}
}

export type OperatorType = '~' | '!~' | '=' | '!=' | '>' | '>=' | '<' | '<=' | 'is' | 'not';

export class FilterModel {
  key: string;
  value?: any;
  operator: OperatorType;
  type: DataTypes;
}

export interface FilterUpdate {
  queries?: FilterModel[] | any;
  searchValue?: string;
}

export type TableSelectionType = 'none' | 'multi' | 'single';
