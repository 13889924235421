import { Component, Input } from '@angular/core';
import { KpiModel } from '@shared/components/kpis/kpi.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kpi-collection',
  templateUrl: './kpi-collection.component.html',
  styleUrls: ['./kpi-collection.component.scss']
})
export class KpiCollectionComponent {
  @Input() kpis: { definition: KpiModel; request: Observable<any> }[];
  @Input() direction: string = 'horizontal';
}
