import { formatDate } from '@angular/common';
import { isDevMode } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { DateRange } from '@common/models/common.model';

dayjs.extend(utc);
dayjs.extend(timezone);

const tzGuess = dayjs.tz.guess();
export const USER_TIMEZONE = tzGuess === undefined ? 'America/New_York' : tzGuess;

export const DELIMITER_PATTERN = '[- /.]';
export const MONTH_PATTERN = '(0{0,1}[1-9]|1[012])';
export const YEAR_PATTERN = `(19|20){0,1}\\d\\d`;
export const FOUR_DIGIT_YEAR_PATTERN = `(19|20)\\d\\d`;
export const DAY_PATTERN = `(0{0,1}[1-9]|[12][0-9]|3[01])`;

// All matchers include valid variations
// eg. mm-dd-yyyy ---> 01/21/1980,  9.21.1980, 12-3-12, etc.
export const YEAR_MONTH_DAY_MATCHER = new RegExp(
  `^${YEAR_PATTERN}${DELIMITER_PATTERN}${MONTH_PATTERN}${DELIMITER_PATTERN}${DAY_PATTERN}$`,
  'g'
);
export const DAY_MONTH_YEAR_MATCHER = new RegExp(
  `^${DAY_PATTERN}${DELIMITER_PATTERN}${MONTH_PATTERN}${DELIMITER_PATTERN}${YEAR_PATTERN}$`,
  'g'
);
export const MONTH_DAY_YEAR_MATCHER = new RegExp(
  `^${MONTH_PATTERN}${DELIMITER_PATTERN}${DAY_PATTERN}${DELIMITER_PATTERN}${YEAR_PATTERN}$`,
  'g'
);
export const MONTH_DAY_FOUR_DIGIT_YEAR_MATCHER = new RegExp(
  `^${MONTH_PATTERN}${DELIMITER_PATTERN}${DAY_PATTERN}${DELIMITER_PATTERN}${FOUR_DIGIT_YEAR_PATTERN}$`,
  'g'
);

// CSV parsing oriented formats
// Supported 4 digit years: M/d/yyyy, yyyyMMdd, yyyy-M-d, M-d-yyyy
// Supported 2 digit years: M/d/yy, yyMMdd, M-d-yy
// M/d/yy, M-d-yy
const CSV_2YEAR_SLASH = `${MONTH_PATTERN}/${DAY_PATTERN}/\\d{2}`;
const CSV_2YEAR_DASH = `${MONTH_PATTERN}-${DAY_PATTERN}-\\d{2}`;
export const CSV_MONTH_DAY_TWO_DIGIT_YEAR_MATCHER = new RegExp(`^(${CSV_2YEAR_SLASH}|${CSV_2YEAR_DASH})$`, 'g');
// yyMMdd
const CSV_2YEAR_MONTH_DAY = `\\d\\d${MONTH_PATTERN}${DAY_PATTERN}`;
export const CSV_TWO_DIGIT_YEAR_MONTH_DAY_MATCHER = new RegExp(`^${CSV_2YEAR_MONTH_DAY}$`, 'g');
// all M/d/yy, yyMMdd, M-d-yy
export const CSV_ALL_TWO_DIGIT_YEAR_MATCHER = new RegExp(
  `^(${CSV_2YEAR_SLASH}|${CSV_2YEAR_DASH}|${CSV_2YEAR_MONTH_DAY})$`,
  'g'
);
// M/d/yyyy, M-d-yyyy
const CSV_4YEAR_SLASH = `${MONTH_PATTERN}/${DAY_PATTERN}/${FOUR_DIGIT_YEAR_PATTERN}`;
const CSV_4YEAR_DASH = `${MONTH_PATTERN}-${DAY_PATTERN}-${FOUR_DIGIT_YEAR_PATTERN}`;
export const CSV_MONTH_DAY_FOUR_DIGIT_YEAR_MATCHER = new RegExp(`^(${CSV_4YEAR_SLASH}|${CSV_4YEAR_DASH})$`, 'g');
// yyyyMMdd
const CSV_4YEAR_MONTH_DAY = `${FOUR_DIGIT_YEAR_PATTERN}([01][1-9]|1[012])([01][1-9]|[12][0-9]|3[01])`;
export const CSV_FOUR_DIGIT_YEAR_MONTH_DAY_MATCHER = new RegExp(`^${CSV_4YEAR_MONTH_DAY}$`, 'g');
// yyyy-M-d
const CSV_4YEAR_MONTH_DAY_DASH = `${FOUR_DIGIT_YEAR_PATTERN}-${MONTH_PATTERN}-${DAY_PATTERN}`;
export const CSV_FOUR_DIGIT_YEAR_MONTH_DAY_DASH_MATCHER = new RegExp(`^${CSV_4YEAR_MONTH_DAY_DASH}$`, 'g');
// all M/d/yyyy, yyyyMMdd, yyyy-M-d, M-d-yyyy
export const CSV_ALL_FOUR_DIGIT_YEAR_MATCHER = new RegExp(
  `^(${CSV_4YEAR_SLASH}|${CSV_4YEAR_DASH}|${CSV_4YEAR_MONTH_DAY}|${CSV_4YEAR_MONTH_DAY_DASH})$`,
  'g'
);

// check if the date string matches any of the server supported 2 digit year date strings
export function csv2YearDateStrValid(dateString: string): boolean {
  return dateString.match(CSV_ALL_TWO_DIGIT_YEAR_MATCHER)?.length > 0;
}

// check if the date string matches any of the server supported 4 digit year date strings
export function csv4YearDateStrValid(dateString: string): boolean {
  return dateString.match(CSV_ALL_FOUR_DIGIT_YEAR_MATCHER)?.length > 0;
}

// datetime matcher
export const DATETIME_MATCHER = new RegExp(
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/,
  'g'
);

export const DELIMITER_MATCHER = new RegExp(DELIMITER_PATTERN, 'g');

export function getSplitDateArray(dateString: string): string[] {
  return dateString.replace(DELIMITER_MATCHER, '/').split('/');
}

export function getDateFromString(date: string): Date {
  if (!date) return null;
  if (!!(date as any)?.toISOString) return date as any;
  const newDate = new Date(date);
  // The server returns two formats for date types: 'yyyy-MM-dd' and 'yyyy-MM-ddThh:mm:ss.fffZ'
  // This checks for the 'yyyy-MM-dd' format and provides an offset to adjust for the timezone
  if (!date.match(DATETIME_MATCHER)) {
    newDate.setHours(newDate.getHours() + newDate.getTimezoneOffset() / 60);
  }
  if (isValidDate(newDate)) return newDate;
  if (isDevMode()) console.error(`Date '${date}' is not in a valid date format.`);
  return null;
}

export function getFormattedDateString(date: Date | string, format = 'M/d/yyyy'): string {
  if (!date) return null;
  return formatDate(date, format, 'en-US', USER_TIMEZONE);
}

export function getUTCFormattedDateString(date: Date | string, format = 'M/d/yyyy'): string {
  if (!date) return null;
  return formatDate(date, format, 'en-US', 'UTC');
}

// If the date object is invalid it will return 'NaN' from getTime()
export function isValidDate(date: Date): boolean {
  if (!date?.getTime) return false;
  return date instanceof Date && !isNaN(date.getTime());
}

export function isValidDateAndInRecentCentury(date: Date): boolean {
  if (!date?.getTime) return false;
  // confirm last, current, next century
  const cent = Math.trunc(date.getFullYear() / 100);
  const currCent = Math.trunc(new Date().getFullYear() / 100);
  return date instanceof Date && !isNaN(date.getTime()) && Math.abs(currCent - cent) <= 1;
}

export function getDaysFromTo(fromDate: Date, toDate: Date): number {
  // Floor first date
  fromDate = new Date(fromDate);
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  fromDate.setSeconds(0);
  fromDate.setMilliseconds(0);
  // Floor second date
  toDate = new Date(toDate);
  toDate.setHours(0);
  toDate.setMinutes(0);
  toDate.setSeconds(0);
  toDate.setMilliseconds(0);
  const differenceInTime = toDate.getTime() - fromDate.getTime();
  return Math.trunc(differenceInTime / (1000 * 3600 * 24));
}

export function addSubtractDays(dt: Date, amount: number): Date {
  const copyDate = new Date(dt.getTime());
  return copyDate.setDate(copyDate.getDate() + amount) && copyDate;
}

export function addSubtractMonths(dt: Date, amount: number): Date {
  const copyDate = new Date(dt.getTime());
  return copyDate.setMonth(copyDate.getMonth() + amount) && copyDate;
}

export function addSubtractYears(dt: Date, amount: number): Date {
  const copyDate = new Date(dt.getTime());
  return copyDate.setFullYear(copyDate.getFullYear() + amount) && copyDate;
}

/**
 * In a couple places we store dates as a number representing the format 'yyyymm'. This function formats it as a string 'yyyy-mm'.
 * @param yearMonth
 */
export function formatYearMonth(yearMonth: number): string {
  if (yearMonth >= 100000) {
    // this is just making sure the yearMonth is a format of yyyymm
    const strYearMonth = `${yearMonth}`;
    return `${strYearMonth.slice(0, 4)}-${strYearMonth.slice(4, 6)}`;
  }
  return `${yearMonth}`;
}

export function isDateBetween(date: Date, range: DateRange): boolean {
  return date.valueOf() >= range.startDate.valueOf() && date.valueOf() <= range.endDate.valueOf();
}

/**
 * Compares two Date objects and returns e number value that represents the result:
 * 0 if the two dates are equal.
 * 1 if the first date is greater than second.
 * -1 if the first date is less than second.
 */
export function compareDates(date1: Date, date2: Date): number {
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // Check if the dates are equal
  const same = d1.getTime() === d2.getTime();
  if (same) return 0;

  // Check if the first is greater than second
  if (d1 > d2) return 1;

  // Check if the first is less than second
  if (d1 < d2) return -1;
}
