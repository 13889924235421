import { LabelValue } from '@lib-resource/label-value.model';

export class SlOptionsModel {
  filingPeriodRecurrenceExprList?: string[];
  filingPeriodRecurrenceInterval?: string;
  filingNotificationRecurrenceExprList?: string[];
  filingNotificationRecurrenceInterval?: string;
}

export class RepeatingTimePeriod {
  recurrenceInterval: IntervalValues;
  recurrenceExprList: WeeklyRecurrenceValues[] | number[] | ['LAST_DAY'];
}

export enum IntervalValues {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

enum WeeklyRecurrenceValues {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export const weeklyOptions: LabelValue[] = [
  {
    label: 'Sunday',
    value: WeeklyRecurrenceValues.SUNDAY
  },
  {
    label: 'Monday',
    value: WeeklyRecurrenceValues.MONDAY
  },
  {
    label: 'Tuesday',
    value: WeeklyRecurrenceValues.TUESDAY
  },
  {
    label: 'Wednesday',
    value: WeeklyRecurrenceValues.WEDNESDAY
  },
  {
    label: 'Thursday',
    value: WeeklyRecurrenceValues.THURSDAY
  },
  {
    label: 'Friday',
    value: WeeklyRecurrenceValues.FRIDAY
  },
  {
    label: 'Saturday',
    value: WeeklyRecurrenceValues.SATURDAY
  }
];

export const monthlyOptions: LabelValue[] = [
  ...Array.from(Array(28).keys()).map((val) => ({ label: `Day ${val + 1}`, value: `${val + 1}` })),
  {
    label: 'Last Day of the Month',
    value: 'L'
  }
];
