import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { PorModel } from '@common/models/por.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PorService {
  get baseUrl() {
    return `${SERVER_API_URL}/por`;
  }

  constructor(private http: HttpClient) {}

  get(): Observable<PorModel[]> {
    return this.http.get<PorModel[]>(this.baseUrl);
  }

  getById(id): Observable<PorModel> {
    return this.http.get<PorModel>(`${this.baseUrl}/${id}`);
  }

  getByOrgIdAndCredentialedOrgId(orgId, credentialedOrgId): Observable<PorModel> {
    return this.http.get<PorModel>(`${this.baseUrl}/${orgId}/${credentialedOrgId}`);
  }

  update(por: PorModel): Observable<PorModel> {
    return this.http.put<PorModel>(`${this.baseUrl}/${por.id}`, por);
  }

  create(por: PorModel): Observable<PorModel> {
    return this.http.post<PorModel>(this.baseUrl, por);
  }

  delete(por: PorModel): Observable<PorModel> {
    return this.http.delete(`${this.baseUrl}/${por.id}`);
  }
}
