export namespace FileTools {
  export function bytesToKB(bytes: number): number {
    const kb = bytes / 1024;
    return kb % 1 === 0 ? kb : +kb.toFixed(+2);
  }

  export function kbToBytes(kb: number): number {
    return Math.round(kb * 1024);
  }
}
