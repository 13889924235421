import { Injectable } from '@angular/core';
import { AccountContactModel } from '@common/models/account-contact.model';
import { AccountModel } from '@common/models/account.model';

import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

/**
 * This Async Option is dependent on the parent control 'accountId'. Whenever the 'accountId' select field is modified, that will
 * trigger this Async Option to fetch the contacts for the selected account.
 */
@Injectable()
export class AccountContactAsyncOptionGroupService extends AsyncOptions<AccountContactModel> {
  key = 'accountContactAsyncOptionGroup';
  defaultSort = ['name'];

  constructor(private accountService: AccountService) {
    super();
  }

  filter = (control, asyncOptionDeps) => {
    // precedence of looking for an account object first, then an account id
    const accountId =
      control.parent.controls[asyncOptionDeps[0]]?.value?.id || control.parent.controls[asyncOptionDeps[0]]?.value;
    if (!!accountId) {
      return this.accountService.get(accountId).pipe(
        map((account) => {
          const labelValues: LabelValue[] = this.toLabelValue(account);
          return { content: labelValues, total: labelValues?.length };
        })
      );
    }
    return of({ content: [], total: 0 });
  };

  toLabelValue(acct: AccountModel) {
    if (!!acct) {
      return acct?.accountContacts?.map((ac) => ({
        label: ac.name,
        value: ac
      }));
    }
    return [];
  }

  valuesFromKeys = (_) => null;
}
