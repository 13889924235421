import { Component, Input } from '@angular/core';
import { NavItems } from '@main/nav.models';

@Component({
  selector: 'workspace-item-display',
  templateUrl: 'workspace-item-display.component.html',
  styleUrls: [`workspace-item-display.component.scss`]
})
export class WorkspaceItemDisplayComponent {
  @Input() show = false;
  @Input() navItems: NavItems;

  constructor() {}
}
