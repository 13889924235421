export enum FormularyDisruptionTierType {
  EXCLUDED = 'EXCLUDED',
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
  TIER_4 = 'TIER_4'
}

export class FormularyDisruptionModel {
  id?: number;
  drugName?: string;
  scriptCount?: number;
  tier?: FormularyDisruptionTierType;
}
