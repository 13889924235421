import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

/**
 * This Async Option is dependent on a parent Async Option for 'orgIds'. Whenever the 'orgIds' select field is modified, that will
 * trigger this Async Option to fetch the accounts for the selected orgIds.
 *
 * Default the values of the selection will the account ids
 * If providing the definition with asyncExtras.useModelAsValue set to true will return the account model instead of just the id
 */
@Injectable()
export class AccountAsyncOptionGroupService extends AsyncOptions<string> {
  key = 'accountAsyncOptionGroup';
  defaultSort = ['name'];

  constructor(private accountService: AccountService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.accountService
      .searchUnfiltered(this.generateFilterString(control, asyncOptionDeps, value, requiredFilter), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({content, total}) => ({content: this.generateLabelValues(content, asyncExtras?.useModelAsValue), total})));

  valuesFromKeys = (values: string[], multi = true, asyncExtras: any = {}) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.accountService
      .searchUnfiltered(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content, asyncExtras?.useModelAsValue)));
  };

  /*
   * The filter string should include the criteria for the org ids based on what has been selected in the parent dependent control 'orgIds'
   */
  private generateFilterString = (control: AbstractControl, asyncOptionDeps: string[], value, requiredQuery?) => {
    let filterString = value ? `(name ~ '${value}')` : '';
    const orgIds = control.parent.get(asyncOptionDeps[0])?.value;
    const orgFilter = Array.isArray(orgIds)
      ? orgIds?.map((orgId) => `org.id = ${orgId}`).join(' OR ')
      : !!orgIds
      ? `org.id = ${orgIds}`
      : null;
    if (!!orgFilter) {
      filterString = filterString ? `${filterString} AND (${orgFilter})` : orgFilter;
    }
    return this.combineFilters(filterString, requiredQuery);
  };
}
