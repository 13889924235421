import { Component, forwardRef, Input } from '@angular/core';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { DataDefModel } from '@lib-resource/data-def.model';
import { quoteResponseLaserDef } from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-laser-array',
  templateUrl: './laser-array.component.html',
  styleUrls: ['./laser-array.component.scss'],
  providers: [
    {
      provide: BaseArrayComponent,
      useExisting: forwardRef(() => LaserArrayComponent)
    }
  ]
})
export class LaserArrayComponent extends BaseArrayComponent {
  @Input()
  readOnly: boolean;

  specDeductibleDataDef: DataDefModel = this.getDataDef('specDeductible');
  contractBasisDataDef: DataDefModel = this.getDataDef('contractBasis');
  maxReimbursementDataDef: DataDefModel = this.getDataDef('maxReimbursement');
  excludedDataDef: DataDefModel = this.getDataDef('excluded');

  defs = quoteResponseLaserDef;

  constructor() {
    super();
  }

  excludeLaser = (ctrl: AbstractControl, val: any, label?: any) => {
    if (val) {
      const laserFormGroup = ctrl.parent;
      laserFormGroup.controls[this.specDeductibleDataDef.key].setValue(undefined);
      laserFormGroup.controls[this.contractBasisDataDef.key].setValue(undefined);
      laserFormGroup.controls[this.maxReimbursementDataDef.key].setValue(undefined);
    }
  };

  private getDataDef(key: string): DataDefModel {
    return quoteResponseLaserDef.find((def) => def.key === key);
  }
}
