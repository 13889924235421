import { Injectable } from '@angular/core';
import { SavedFilterDataService } from '@common/services/saved-filter.data-service';
import { SavedFilterModel } from '@data-table-lib/models/saved-filter.model';
import { DataDefModel } from '@lib-resource/data-def.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

const fieldTypeMap = {
  TEXT: 'text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
  CURRENCY: 'currency',
  ENUM: 'enum'
};

function mapFieldTypes(field) {
  return fieldTypeMap[field.fieldType];
}

@Injectable({
  providedIn: 'root'
})
export class SavedFilterService {
  constructor(private dataService: SavedFilterDataService, private store: Store) {}

  get(savedFilterType): Observable<Array<SavedFilterModel>> {
    return this.dataService.getForUser(savedFilterType);
  }

  create(savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    return this.dataService.create(savedFilter);
  }

  update(filterId: number, savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    if (!savedFilter) {
      return of({});
    }

    if (typeof savedFilter.query === 'object') {
      savedFilter.query = JSON.stringify(savedFilter.query);
    }

    return this.dataService.update(savedFilter);
  }

  delete(savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    return this.dataService.delete(savedFilter);
  }

  processFilterQueries(savedFilters: SavedFilterModel[], columns: DataDefModel[]): Array<any> {
    const savedQueries = [];
    savedFilters.forEach((filter: any) => {
      // iterate over queries
      if (filter.orgLevelFilter && !filter.orgId) {
        filter.orgId = this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId);
      }
      if (!filter.advanced) {
        const convertedQueries = [];
        filter.query = JSON.parse(filter.query as string).map((queryNode) => Object.assign(queryNode));
        filter.query.forEach((query) => {
          let options;
          if (query.fieldType) {
            let value;
            if (query.value) {
              value = query.value.value || query.value.value === false ? query.value.value : query.value.plusMinusDays;
            } else if (query.values && Array.isArray(query.values)) {
              value = [];
              query.values.forEach((val) => value.push(val.value));
            }
            const column = columns.find((col) => query.field.value === (col.queryKey ? col.queryKey : col.key));
            options = column ? column.options : null;

            query = {
              type: mapFieldTypes(query),
              label: query.field.display,
              key: query.field.value,
              value,
              operator: query.operator
            };
          } else {
            // This line will ensure fresh options based on columnDef, but the data should be prevented from reaching the DB
            const foundColumn = columns.find(
              (column) => query.key === (column.queryKey ? column.queryKey : column.key)
            );
            if (!foundColumn) {
              console.error(`Column with key of ${query.key} was not found in this table's column definitions.`);
            } else {
              options = foundColumn.options;
            }
          }

          if (options) {
            query.options = options;
          }
          convertedQueries.push(query);
        });
        savedQueries.push({ ...filter, query: convertedQueries });
      } else {
        savedQueries.push(filter);
      }
    });
    return savedQueries;
  }
}
