<app-dialog-label>{{ data.title }}</app-dialog-label>
<app-dialog-content>
  <div>
    {{ userJob.message }}
  </div>
  <mat-progress-bar [mode]="'buffer'" [value]="0"></mat-progress-bar>
</app-dialog-content>
<app-dialog-actions>
  <button mat-raised-button (click)="close(userJob)">Close</button>
</app-dialog-actions>
