import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DataDefModel, DataDefOption, DerivedValueDef } from '@lib-resource/data-def.model';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { replaceInvalidKeyChars } from '@lib-resource/data.utils';
import { compareFn, hasRequiredField } from '../../utils/control.utils';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseFieldComponent implements OnInit, OnChanges {
  _dataDef: DataDefModel;
  @Input() control: AbstractControl;
  @Input() controls: AbstractControl[];
  @Input() inline: boolean;
  @Input() readOnly: boolean;
  @Input() label: string;
  @Input() inlineNoLabel: boolean = false;
  @Input() options: DataDefOption[];
  @Input() toggleOptions: DataDefOption[];
  @Input() asyncOptions: string; // Only applicable to select definitions, putting here for typing reasons
  @Input() asyncExtras: any;
  @Input() derivedAsyncExtras: (controls, column?: DataDefModel) => any;
  @Input() asyncOptionsDeps: string[];
  @Input() calculatedValueFn: (_: any, def: DataDefModel) => any;
  @Input() hint: string;
  @Input() noSelectionLabel: string; // In some multi-select fields, when there is no selection a specific label needs to be displayed. (i.e. 'All')
  @Input() onChangeFn: (ctrl: AbstractControl, val: any, label?: any) => void;
  @Input() derivedValue: DerivedValueDef;
  @Input()
  set dataDef(val: DataDefModel) {
    this._dataDef = val?.formKey ? val : { ...val, formKey: replaceInvalidKeyChars(val.key) };
  }

  get dataDef(): DataDefModel {
    return this._dataDef;
  }

  @Input() isJoinedField: boolean = false;
  @Input() hasJoinedField: boolean = false;
  required: boolean;
  compareFn = compareFn;
  isMulti: boolean = false;

  get appearance(): MatFormFieldAppearance {
    if (this.inline || this.inlineNoLabel) return null;
    return this.control.disabled || this.readOnly ? 'fill' : 'outline';
  }

  ngOnInit() {
    if (this.dataDef) {
      this.readOnly = this.readOnly || this.dataDef.readOnly;
      this.label = this.dataDef.label;
      this.options = this.dataDef.options;
      this.toggleOptions = this.dataDef.toggleOptions;
      this.asyncOptions = this.dataDef.asyncOptions;
      this.asyncExtras = this.dataDef.asyncExtras;
      this.derivedAsyncExtras = this.dataDef.derivedAsyncExtras;
      this.asyncOptionsDeps = this.dataDef.asyncOptionsDeps;
      this.calculatedValueFn = this.dataDef.calculatedValueFn;
      this.hint = this.dataDef.hint;
      this.noSelectionLabel = !!this.dataDef.noSelectionLabel ? this.dataDef.noSelectionLabel : '';
      this.derivedValue = this.dataDef.derivedValue;
      this.onChangeFn = this.dataDef.onChangeFn ? this.dataDef.onChangeFn : this.onChangeFn;
    }

    if (!this.control && !this.controls) {
      throw Error(`A control was not passed in for field: ${this.label}`);
    }

    if (!this.control) {
      this.control = this.controls[0];
    }

    this.required = hasRequiredField(this.control);

    if (!this.calculatedValueFn) {
      this.calculatedValueFn = (value) => value;
    }
  }

  ngOnChanges({ readOnly }: SimpleChanges) {
    if (readOnly) {
      this.readOnly = readOnly?.currentValue;
      if (this.readOnly) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  _valueChanged(event) {
    if (this.onChangeFn) {
      this.onChangeFn(this.control, event, this.label);
    }
  }

  // For select and multi-input fields
  updateCtrlValue(value: any) {
    this.control.setValue(value);
    this.control.markAsDirty();
    this._valueChanged(value);
  }

  // For select fields
  @Memoize()
  getDisplayValue(currentOptions, controlVal) {
    if (currentOptions && currentOptions.length) {
      const option = currentOptions.find((opt) => this.compareFn(controlVal, opt.value));
      if (!!option) {
        return option.label;
      }
      return this.noSelectionLabel;
    }
    return controlVal;
  }
}
