<script src="multi-file-upload.component.ts"></script>
<div *ngIf="uploader">
  <div
    style="margin-top: 1rem"
    ng2FileDrop
    (fileOver)="fileOverBase($event)"
    [uploader]="uploader"
    (onFileDrop)="fileChange()"
  >
    <label class="file-select-drop">
      <i class="material-icons" aria-hidden="true">cloud_upload</i>
      Add Supporting Files
      <input
        class="upload-control"
        type="file"
        ng2FileSelect
        (onFileSelected)="fileChange()"
        [uploader]="uploader"
        multiple
      />
    </label>
  </div>

  <div *ngIf="uploader?.queue?.length" style="padding: 0.25rem">
    <table mat-table [dataSource]="datasource" class="mat-elevation-z2" style="width: 100%">
      <ng-container matColumnDef="file.name">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td
          mat-cell
          *matCellDef="let item"
          [matTooltip]="item.file?.name"
          matTooltipClass="single-line-tool-tip"
          class="file-name"
        >
          <strong>{{ item.file?.name }}</strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="formData.description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let item" class="file-form-column dense">
          <mat-form-field (change)="emitFileQueueChange()">
            <input matInput (keyup)="item.formData.description = $event.target.value" />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="formData.docType">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let item" class="file-form-column dense">
          <form *ngIf="item.formData.fg" [formGroup]="item.formData.fg">
            <mat-form-field>
              <mat-select
                formControlName="docType"
                (selectionChange)="item.formData.docType = $event.value; emitFileQueueChange()"
              >
                <mat-option *ngFor="let type of docTypes" [value]="type">
                  {{ type | docTypeMapping }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="item.formData.fg.get('docType')?.invalid && item.formData.fg.get('docType').errors.required"
                >This field is required.</mat-error
              >
            </mat-form-field>
          </form>
        </td>
      </ng-container>
      <ng-container matColumnDef="file.size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell nowrap *matCellDef="let item">
          {{ item?.file?.size | fileSize }}
          <mat-error *ngIf="tooLarge(item)">Maximum {{ fileService.getMaxUploadMbs() }} MB</mat-error>
          <mat-error *ngIf="zeroByteFile(item)">Zero byte file</mat-error>
        </td>
      </ng-container>
      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef>Progress</th>
        <td mat-cell *matCellDef="let item">
          <mat-progress-bar mode="determinate" [value]="uploader.progress"></mat-progress-bar>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let item">
          <span *ngIf="item.isSuccess">
            <fa-icon [icon]="successIcon" class="color-rmt-dark icon-size"></fa-icon>
          </span>
          <span *ngIf="item.isCancel">
            <fa-icon [icon]="closeIcon" class="color-rmt-dark icon-size"></fa-icon>
          </span>
          <span *ngIf="item.isError" (click)="showFileItemError(item)">
            <fa-icon [icon]="warnIcon" class="color-status-orange-dark icon-size"></fa-icon>
          </span>
          <span *ngIf="tooLarge(item)" (click)="showTooLargeError(item)">
            <fa-icon [icon]="warnIcon" class="color-status-orange-dark icon-size"></fa-icon>
          </span>
          <span *ngIf="zeroByteFile(item)" (click)="showZeroByteFileError()">
            <fa-icon [icon]="warnIcon" class="color-status-orange-dark icon-size"></fa-icon>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell nowrap *matCellDef="let item">
          <button mat-icon-button (click)="removeItem(item)" [disabled]="item?.isUploading">
            <fa-icon [icon]="deleteIcon" class="color-rmt-dark icon-size"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let item; columns: displayedColumns"></tr>
    </table>

    <ng-container *ngIf="showButtons">
      <div style="padding: 1rem 0">
        Queue progress:
        <mat-progress-bar mode="determinate" [value]="uploader.progress"></mat-progress-bar>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="uploader.uploadAll()" [disabled]="disableAllUpload()">
          Upload all</button
        >&nbsp;
        <button mat-raised-button (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">Cancel all</button
        >&nbsp;
        <button mat-raised-button (click)="clearAllItems()" [disabled]="!uploader.queue.length">Remove all</button>
      </div>
    </ng-container>
  </div>
</div>
