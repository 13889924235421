import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-content',
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: block;
        grid-area: cardContent;
        overflow: auto;
        padding: 1rem;
        height: 100%;
      }
    `
  ]
})
export class DialogContentComponent {}
