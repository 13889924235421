import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { Observable } from 'rxjs';
import { isPdf } from '@file-upload-lib/file.model';
import { BaseManifestDialogComponent } from '@common/dialogs/manifest-dialogs/base-manifest-dialog/base-manifest-dialog.component';
import { ManifestEntryModel } from '@common/models/manifest-entry.model';

@Component({
  templateUrl: './base-manifest-dialog/base-manifest-dialog.component.html',
  styleUrls: ['./base-manifest-dialog/base-manifest-dialog.component.scss']
})
export class QuoteResponseManifestDialogComponent extends BaseManifestDialogComponent<ManifestEntryModel> {
  quoteResponseId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      zipFileName: string;
      ids: any;
      loadFunction: () => Observable<[boolean, ManifestEntryModel[]]>;
      dialogTitle?: string;
    },
    private quoteResponseService: QuoteResponseService
  ) {
    super(data);
    this.dialogLabel = data.dialogTitle || `Download Supporting Files - ${data.zipFileName}`;
    this.quoteResponseId = data.ids.quoteResponseId;
  }

  downloadItem(item: ManifestEntryModel) {
    this.downloadRmtFile(item);
  }

  downloadManifest(): Observable<Blob> {
    return this.quoteResponseService.downloadQuoteResponseManifest(
      this.quoteResponseId,
      this.manifestList.selectedOptions.selected.map((item) => item.value.rmtFile),
      `${this.data.zipFileName}.zip`
    );
  }

  downloadRmtFile(item: ManifestEntryModel): void {
    if (isPdf(item?.rmtFile)) {
      this.quoteResponseService
        .downloadSupportFile(this.quoteResponseId, item.rmtFile.id, null)
        .subscribe((blob) => (this.viewItemStream = blob));
    } else {
      this.quoteResponseService.downloadSupportFile(this.quoteResponseId, item.rmtFile.id, item.rmtFile.name);
    }
  }
}
