<div class="side-by-side-container">
  <div class="contingency-column">
    <div class="drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="contingency-container pb-3" *ngFor="let contingency of contingencies" cdkDrag>
        <div class="contingency-handle" cdkDragHandle>
          @if (canEdit()) {
            <span><fa-icon [icon]="faGripDots"></fa-icon></span>
          }
        </div>
        <mat-form-field (change)="doUpdate()" appearance="fill">
          <label class="sr-only" for="contingencyField">Contingencies</label>
          <textarea
            id="contingencyField"
            matInput
            [(ngModel)]="contingency.contingencyText"
            [disabled]="!canEdit()"
            rows="5"
          ></textarea>
        </mat-form-field>
        <div class="contingency-remove" (click)="removeContingency(contingency)">
          @if (canEdit()) {
            <span><fa-icon [icon]="deleteIcon"></fa-icon></span>
          }
        </div>
      </div>
    </div>
  </div>
  <app-doc-viewer
    *ngIf="blob && blob.type !== 'application/pdf' && hasBeenVisited"
    [blob]="blob"
    [filename]="getFileName()"
  ></app-doc-viewer>
  @if (blob && blob.type === 'application/pdf') {
    <div class="pdf-view">
      <ng2-pdfjs-viewer [pdfSrc]="blob"></ng2-pdfjs-viewer>
    </div>
  }
</div>
