import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-label',
  template: `
    <div class="dialog-label-wrapper dense-layout">
      <h3 class="mat-h3 dialog-label m-0 mr-2">
        <i *ngIf="icon" class="dialog-label-icon pr-1" [ngClass]="icon"></i>
        {{ label }}
        <ng-content></ng-content>
      </h3>
    </div>
  `,
  styleUrls: ['dialog-label.component.scss']
})
export class DialogLabelComponent {
  @Input() label: string;
  @Input() icon: string;
}
