import { createSelector } from '@ngxs/store';
import { DataTableState } from '@main/store/data-table/data-table.state';
import { DataTableStateModel, StoredTableMetaData } from '@main/store/data-table/data-table.state-model';

export class DataTableSelectors {
  static storedMetaData(tableKey: string) {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel): StoredTableMetaData => state.tables[tableKey]
    );
  }
}
