import { AfterViewInit, Component, inject, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSelectionList } from '@angular/material/list';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@shared/notifications/notification.service';
import { isPdf } from '@file-upload-lib/file.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BaseApiDialogComponent } from '@common/dialogs/base-api-dialog.component';
import { tap } from 'rxjs/operators';
import { ManifestEntryModel, SourceType } from '@common/models/manifest-entry.model';
import { DocTypeMappingPipe } from '@shared/pipes/doctype-mapping.pipe';

@Component({ template: '' })
export abstract class BaseManifestDialogComponent<T extends ManifestEntryModel> extends BaseApiDialogComponent<
  [boolean, T[]]
> {
  docTypeMappingPipe: DocTypeMappingPipe = inject(DocTypeMappingPipe);
  notificationService: NotificationService = inject(NotificationService);
  dialogLabel: string;
  selectedFile: ManifestEntryModel;
  selectedOptions;
  messageText: string = 'Choose documents to view, download, and/or include in the download package';
  viewItemStream;
  manifest: ManifestEntryModel[];
  fileNameMap: Map<SourceType, (item: T) => string>;
  docTypeMap: Map<SourceType, string>;
  downloading: boolean = false;

  @ViewChild(MatSelectionList, { static: true }) manifestList: MatSelectionList;

  protected constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      zipFileName: string;
      ids: any;
      selectAll?: boolean;
      loadFunction: () => Observable<[boolean, T[]]>;
    }
  ) {
    super(data.loadFunction);
  }

  getFileName(item: T): string {
    if (item.rmtFile) {
      return item.rmtFile.name;
    }
    return this.fileNameMap.get(item.source)(item);
  }

  getDocType(item: T): string {
    if (item.source === SourceType.RMT_FILE) {
      const docTypeDisplay = this.docTypeMappingPipe.transform(item.rmtFile?.docType);
      return !!item.rmtFile?.versionId ? `${docTypeDisplay} - v${item.rmtFile.versionId}` : docTypeDisplay;
    }

    return this.docTypeMap.get(item.source);
  }

  isDownloadButtonDisabled(): boolean {
    return !(this.selectedOptions && this.selectedOptions.length > 0);
  }

  submitManifest() {
    if (this.manifestList.selectedOptions.selected.length > 0) {
      this.downloading = true;
      this.viewItemStream = null;
      this.messageText = 'Downloading zip file ...';
      this.downloadManifest().subscribe(
        (_) => {
          this.messageText = 'Package downloaded';
          this.downloading = false;
        },
        () => {
          this.notificationService.failedNotification('An error occurred.');
          this.downloading = false;
        }
      );
    } else {
      this.notificationService.failedNotification('Please select at least one file to include in the package');
    }
  }

  viewFile(item: T) {
    this.messageText = 'Downloading file: ' + this.getFileName(item);
    this.selectedFile = item;
    this.viewItemStream = null;

    this.downloadItem(item);
  }

  getViewIcon(item: T) {
    if (isPdf(item?.rmtFile)) {
      return 'remove_red_eye';
    }
    return 'cloud_download';
  }

  isSelectAllIndeterminate() {
    if (this.selectedOptions) {
      return this.selectedOptions.length > 0 && !this.isSelectAllChecked();
    }
    return false;
  }

  isSelectAllChecked() {
    if (this.selectedOptions) {
      return this.selectedOptions.length === this.manifestList.options.length;
    }
    return false;
  }

  toggleSelectAllCheckBox(event: MatCheckboxChange) {
    if (event.checked) {
      this.manifestList.selectAll();
    } else {
      this.manifestList.deselectAll();
    }
  }

  selectAllLabel() {
    if (this.isSelectAllChecked()) {
      return 'Deselect All';
    }
    return 'Select All';
  }

  loadData(loadFunction: () => Observable<[boolean, T[]]>): Observable<[boolean, T[]]> {
    return loadFunction().pipe(
      tap(([selectAll, manifestDocModels]) => {
        this.manifest = manifestDocModels;
        this.selectedOptions = selectAll ? manifestDocModels : this.selectedOptions;
      })
    );
  }

  // specific download implementations, implementor will need to set the viewItemStream as needed for pdf downloads
  abstract downloadItem(item: T);
  abstract downloadManifest(): Observable<Blob>;
}
