import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty } from '@app/tools/string';
import { CustomAsyncValidatorFn, CustomValidatorFn } from '@form-lib/models/validators.model';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { compareDates, getDaysFromTo } from '@app/tools/date';

export const FORM_ERRORS = {
  customValidation: {
    key: 'customValidation'
  },
  eitherOr: {
    key: 'eitherOr',
    message: 'Only one of these fields can have a value.'
  },
  oneRequired: {
    key: 'oneRequired',
    message: 'One of these fields must have a value.'
  },
  requiredIfSiblingContainsValue: {
    key: 'requiredIfSiblingContainsValue',
    message: 'This field is required.'
  },
  requiredIfSiblingUnchecked: {
    key: 'requiredIfSiblingUnchecked',
    message: 'This field is required.'
  },
  requiredIfSiblingChecked: {
    key: 'requiredIfSiblingChecked',
    message: 'This field is required.'
  },
  matchPassword: {
    key: 'matchPassword',
    message: 'Passwords do not match'
  },
  mustBeGreaterThan: {
    key: 'mustBeGreaterThan',
    messageFn: (firstField, secondField) => `${firstField} must be greater than ${secondField}.`
  },
  mustBeLessThanOrEqualTo: {
    key: 'mustBeLessThanOrEqualTo',
    messageFn: (firstField, secondField) => `${firstField} must be <= ${secondField}.`
  },
  allOrNone: {
    key: 'allOrNone',
    message: 'This field is required.'
  },
  atLeastOneOf: {
    key: 'atLeastOneOf',
    message: 'This field is required.'
  },
  atLeastOneTrue: {
    key: 'atLeastOneTrue',
    message: 'One of these fields is required to be true'
  },
  endDateMustBeGreater: {
    key: 'endDateMustBeGreater',
    message: 'End date must be greater or equal to Start date.'
  },
  endDateRequired: {
    key: 'endDateRequired',
    message: 'End date is required.'
  },
  startDateMustNotBeFuture: {
    key: 'startDateMustNotBeFuture',
    message: 'Start date must not be in the future.'
  },
  endDateMustNotBeFuture: {
    key: 'endDateMustNotBeFuture',
    message: 'End date must not be in the future.'
  },
  dateRangeMustBeLessThanOrEqualTo: {
    key: 'dateRangeMustBeLessThanOrEqualTo',
    messageFn: (range) => `Date range must be <= ${range} day(s).`
  }
};

export function setAndPreserveErrors(ctrl: AbstractControl, errorExists, errorKey: string, errorMessage: string) {
  const currentErrors = { ...ctrl.errors };
  // If this error does NOT exist on the control and errorExists is false, nothing needs to update
  if ((!currentErrors[errorKey] && !errorExists) || (currentErrors[errorKey] && errorExists)) return;

  if (currentErrors[errorKey]) {
    // If this error DOES exist on the control and errorExists is false, remove error
    delete currentErrors[errorKey];
  } else {
    // If this error DOES NOT exist on the control and errorExists is true, add error
    currentErrors[errorKey] = errorMessage;
  }
  if (Object.keys(currentErrors)?.length === 0) {
    ctrl.setErrors(null);
  } else {
    ctrl.setErrors(currentErrors);
  }
}

export class AsyncFormValidators {
  static customValidator(
    fields: string[],
    validationFn: CustomAsyncValidatorFn,
    errorMessage?: string
  ): AsyncValidatorFn {
    let controlValues;
    let previousErrors;
    return (form: UntypedFormGroup): Observable<ValidationErrors | null> => {
      const formControlArray = [];
      fields.forEach((field) => {
        formControlArray.push(form.controls[field]);
      });
      if (
        controlValues &&
        controlValues.length === formControlArray.length &&
        !controlValues.some((value, index) => value !== formControlArray[index].value)
      ) {
        return of(previousErrors);
      }

      controlValues = formControlArray.map((control) => control.value);
      return timer(300).pipe(
        switchMap((_) => validationFn(controlValues, form)),
        tap((errors) => {
          if (!errors && !formControlArray.some((ctrl) => ctrl.dirty)) return;

          formControlArray.forEach((control) => {
            control.updateValueAndValidity();
            control.markAsTouched();
            setAndPreserveErrors(control, !!errors, FORM_ERRORS.customValidation.key, errorMessage);
          });
        }),
        map((errorExists) => (errorExists ? { customValidation: errorMessage } : null)),
        tap((errors) => (previousErrors = errors))
      );
    };
  }
}

export class FormValidators {
  static eitherOr(firstField: string, secondField: string) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[secondField];
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (previousFirstValue === firstValue && previousSecondValue === secondValue) {
        return;
      }
      previousFirstValue = firstValue;
      previousSecondValue = secondValue;

      setAndPreserveErrors(
        firstControl,
        !isEmpty(firstValue) && !isEmpty(secondValue),
        FORM_ERRORS.eitherOr.key,
        FORM_ERRORS.eitherOr.message
      );
      setAndPreserveErrors(
        secondControl,
        !isEmpty(firstValue) && !isEmpty(secondValue),
        FORM_ERRORS.eitherOr.key,
        FORM_ERRORS.eitherOr.message
      );
    };
  }

  static customValidator(fields: string[], validationFn: CustomValidatorFn, errorMessage?: string): ValidatorFn {
    let controlValues;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const formControlArray = [];
      fields.forEach((field) => {
        formControlArray.push(form.controls[field]);
      });
      if (
        controlValues &&
        controlValues.length === formControlArray.length &&
        !controlValues.some((value, index) => value !== formControlArray[index].value)
      ) {
        return null;
      }

      controlValues = formControlArray.map((control) => control.value);
      const errors = !validationFn(controlValues, form);
      if (!errors && !formControlArray.some((ctrl) => ctrl.dirty)) return null;
      formControlArray.forEach((control) => {
        control.updateValueAndValidity({ emitEvent: false });
        control.markAsTouched({ emitEvent: false });
        setAndPreserveErrors(control, !!errors, FORM_ERRORS.customValidation.key, errorMessage);
      });
      return null;
    };
  }

  /**
   * One and only one field can have a value.
   * @param firstField
   * @param secondField
   */
  static oneOrTheOther(firstField: string, secondField: string) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[secondField];
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (previousFirstValue === firstValue && previousSecondValue === secondValue) {
        return;
      }
      previousFirstValue = firstValue;
      previousSecondValue = secondValue;

      setAndPreserveErrors(
        firstControl,
        !isEmpty(firstValue) && !isEmpty(secondValue),
        FORM_ERRORS.eitherOr.key,
        FORM_ERRORS.eitherOr.message
      );
      setAndPreserveErrors(
        secondControl,
        !isEmpty(firstValue) && !isEmpty(secondValue),
        FORM_ERRORS.eitherOr.key,
        FORM_ERRORS.eitherOr.message
      );
      setAndPreserveErrors(
        firstControl,
        isEmpty(firstValue) && isEmpty(secondValue),
        FORM_ERRORS.oneRequired.key,
        FORM_ERRORS.oneRequired.message
      );
      setAndPreserveErrors(
        secondControl,
        isEmpty(firstValue) && isEmpty(secondValue),
        FORM_ERRORS.oneRequired.key,
        FORM_ERRORS.oneRequired.message
      );
      return null;
    };
  }

  /**
   * Flags a field as required, if a sibling field contains any of the provided values.
   */
  static requiredIfSiblingContainsValue(firstField: string, siblingField: string, siblingValues: Array<any>) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const siblingControl = form.controls[siblingField];
      if (!(siblingControl.touched || siblingControl.dirty)) {
        return;
      }
      const firstValue = firstControl.value;
      const secondValue = siblingControl.value;
      if (previousFirstValue === firstValue && previousSecondValue === secondValue) {
        return;
      }
      previousFirstValue = firstValue;
      previousSecondValue = secondValue;

      setAndPreserveErrors(
        firstControl,
        isEmpty(firstValue) && siblingValues.includes(secondValue),
        `${FORM_ERRORS.requiredIfSiblingContainsValue.key}-${siblingField}`,
        FORM_ERRORS.requiredIfSiblingContainsValue.message
      );
      return null;
    };
  }

  static requiredIfSiblingUnchecked(firstField: string, siblingField: string) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[siblingField];
      if (!firstControl.touched && !secondControl.touched) {
        return;
      }
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (previousFirstValue === firstValue && previousSecondValue === secondValue) {
        return;
      }
      previousFirstValue = firstValue;
      previousSecondValue = secondValue;

      setAndPreserveErrors(
        firstControl,
        isEmpty(firstValue) && !secondValue,
        FORM_ERRORS.requiredIfSiblingUnchecked.key,
        FORM_ERRORS.requiredIfSiblingUnchecked.message
      );
      return null;
    };
  }

  static requiredIfSiblingChecked(firstField: string, siblingField: string) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[siblingField];
      if (!firstControl.touched && !secondControl.touched) {
        return;
      }
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      if (previousFirstValue === firstValue && previousSecondValue === secondValue) {
        return;
      }
      previousFirstValue = firstValue;
      previousSecondValue = secondValue;

      setAndPreserveErrors(
        firstControl,
        isEmpty(firstValue) && !!secondValue,
        FORM_ERRORS.requiredIfSiblingChecked.key,
        FORM_ERRORS.requiredIfSiblingChecked.message
      );
      return null;
    };
  }

  static matchPassword(AC: AbstractControl): ValidationErrors | null {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    setAndPreserveErrors(
      AC.get('confirmPassword'),
      password !== confirmPassword,
      FORM_ERRORS.matchPassword.key,
      FORM_ERRORS.matchPassword.message
    );
    return null;
  }

  static validDateRange(
    startDateField,
    endDateField,
    allowEmptyEndDate: boolean = false,
    allowFutureDates: boolean = true,
    maxDateRange: number = null
  ) {
    let previousFirstValue;
    let previousSecondValue;
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const startDateControl = form.controls[startDateField];
      const endDateControl = form.controls[endDateField];
      const startDateValue = startDateControl.value;
      const endDateValue = endDateControl.value;

      if (previousFirstValue === startDateValue && previousSecondValue === endDateValue) {
        return;
      }
      previousFirstValue = startDateValue;
      previousSecondValue = endDateValue;

      setAndPreserveErrors(
        endDateControl,
        !isEmpty(startDateValue) && !isEmpty(endDateValue) && compareDates(startDateValue, endDateValue) > 0,
        FORM_ERRORS.endDateMustBeGreater.key,
        FORM_ERRORS.endDateMustBeGreater.message
      );
      setAndPreserveErrors(
        endDateControl,
        !allowEmptyEndDate && !isEmpty(startDateValue) && isEmpty(endDateValue),
        FORM_ERRORS.endDateRequired.key,
        FORM_ERRORS.endDateRequired.message
      );
      setAndPreserveErrors(
        startDateControl,
        !allowFutureDates && !isEmpty(startDateValue) && compareDates(startDateValue, new Date()) === 1,
        FORM_ERRORS.startDateMustNotBeFuture.key,
        FORM_ERRORS.startDateMustNotBeFuture.message
      );
      setAndPreserveErrors(
        endDateControl,
        !allowFutureDates && !isEmpty(endDateValue) && compareDates(endDateValue, new Date()) === 1,
        FORM_ERRORS.endDateMustNotBeFuture.key,
        FORM_ERRORS.endDateMustNotBeFuture.message
      );
      setAndPreserveErrors(
        endDateControl,
        maxDateRange != null &&
          !isEmpty(startDateValue) &&
          !isEmpty(endDateValue) &&
          getDaysFromTo(startDateValue, endDateValue) > maxDateRange,
        FORM_ERRORS.dateRangeMustBeLessThanOrEqualTo.key,
        FORM_ERRORS.dateRangeMustBeLessThanOrEqualTo.messageFn(maxDateRange)
      );

      return null;
    };
  }

  static mustBeGreaterThan(firstField, secondField) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[secondField];
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      setAndPreserveErrors(
        secondControl,
        !isEmpty(firstValue) && !isEmpty(secondValue) && firstValue <= secondValue,
        FORM_ERRORS.mustBeGreaterThan.key,
        FORM_ERRORS.mustBeGreaterThan.messageFn(firstField, secondField)
      );
      return null;
    };
  }

  static mustBeLessThanOrEqualTo(firstField, secondField, message?: string) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const firstControl = form.controls[firstField];
      const secondControl = form.controls[secondField];
      const firstValue = firstControl.value;
      const secondValue = secondControl.value;
      setAndPreserveErrors(
        firstControl,
        !isEmpty(firstValue) && !isEmpty(secondValue) && firstValue > secondValue,
        FORM_ERRORS.mustBeLessThanOrEqualTo.key,
        message ? message : FORM_ERRORS.mustBeLessThanOrEqualTo.messageFn(firstField, secondField)
      );
      return null;
    };
  }

  static allOrNone(fields: string[]) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const fieldControls = fields.map((f) => form.controls[f]);
      const anyEmpty = fieldControls.some((c) => this.isEmpty(c));
      const anyPopulated = fieldControls.some((c) => this.hasValue(c));
      fieldControls.forEach((control) => {
        if (anyEmpty && anyPopulated) {
          control.markAsTouched(); // mark all fields involved in the form level validation as touched to show the required message
        }
        // only show the message if it is empty, but the others have a value
        setAndPreserveErrors(
          control,
          anyEmpty && anyPopulated && this.isEmpty(control),
          FORM_ERRORS.allOrNone.key,
          FORM_ERRORS.allOrNone.message
        );
      });
      return null;
    };
  }

  static atLeastOneOf(fields: string[]) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const fieldControls = fields.map((f) => form.controls[f]);
      const anyPopulated = fieldControls.some((c) => this.hasValue(c));
      fieldControls.forEach((control) => {
        if (!anyPopulated) {
          control.markAsTouched(); // mark all fields involved in the form level validation as touched to show the required message
        }
        setAndPreserveErrors(control, !anyPopulated, FORM_ERRORS.atLeastOneOf.key, FORM_ERRORS.atLeastOneOf.message);
      });
      return null;
    };
  }

  static atLeastOneTrue(fields: string[]) {
    return (form: UntypedFormGroup): ValidationErrors | null => {
      const fieldControls = fields.map((f) => form.controls[f]);
      const anyPopulated = fieldControls.some((c) => this.hasValue(c) && c.value === true);
      fieldControls.forEach((control) => {
        if (!anyPopulated) {
          control.markAsTouched(); // mark all fields involved in the form level validation as touched to show the required message
        }
        setAndPreserveErrors(
          control,
          !anyPopulated,
          FORM_ERRORS.atLeastOneOf.key,
          `At least one of [${fields.join(', ')}] must be true`
        );
      });
      return null;
    };
  }

  private static isEmpty(c: AbstractControl) {
    return Array.isArray(c.value) ? c.value.length === 0 || c.value.every((x) => isEmpty(x)) : isEmpty(c.value);
  }

  private static hasValue(c: AbstractControl) {
    return Array.isArray(c.value) ? c.value.length > 0 && c.value.some((x) => !isEmpty(x)) : !isEmpty(c.value);
  }
}
