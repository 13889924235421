import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SERVER_API_URL } from '@app/app.constants';
import { ImportResultModel } from '@common/models/import-result.model';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { Observable } from 'rxjs';

const path = 'icd10';

@Injectable({
  providedIn: 'root'
})
export class IcdCodeService {
  constructor(private http: HttpClient, private urlUtil: UrlUtilService) {}

  search(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<any>> {
    return this.http.get<Page<any>>(`${SERVER_API_URL}/${path}`, {
      params: this.urlUtil.buildSearchParams(query, page, pageSize, sort)
    });
  }

  create(file: File): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ImportResultModel>(`${SERVER_API_URL}/${path}/import`, formData);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${SERVER_API_URL}/${path}/${id}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${SERVER_API_URL}/${path}/${id}`);
  }
}
