import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { CsvImportModel, ImportTemplateModel, ImportType } from '@common/models/csv-import.model';
import { ImportResultModel } from '@common/models/import-result.model';
import { Page } from '@common/models/page.model';
import { HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { ParticipantModel } from '@quote/model/participant.model';
import { Observable } from 'rxjs';
import { FileDownloadService } from '@shared/components/file-download-progress/file-download.service';
import { columnDefs } from '@quote/component/participant-list/participant-list.definitions';
import { map } from 'rxjs/operators';
import { getValueByDef } from '@lib-resource/data.utils';
import { DataFormatter } from '@lib-resource/data.formatter';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  get baseUrl() {
    return `${SERVER_API_URL}/quote-request`;
  }

  constructor(
    protected http: HttpClient,
    private httpResource: HttpResourceService,
    private fileDownloadService: FileDownloadService
  ) {}

  downloadParticipantsCsv(quoteRequestId: number, fileName: string): void {
    this.fileDownloadService.csvDownloadProgress(
      fileName,
      columnDefs.map((def) => def.label),
      (page) =>
        this.searchParticipants(quoteRequestId, '', page, 1000, ['id']).pipe(
          map((page) => ({
            total: page.total,
            page: page.page,
            pageSize: page.pageSize,
            content: page.content.map((c) =>
              columnDefs.map((def) => DataFormatter.transform(getValueByDef(c, def), def))
            )
          }))
        )
    );
  }

  searchParticipants(
    quoteRequestId: number,
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<ParticipantModel>> {
    return this.httpResource.queryWithoutOrg({
      path: `quote-request/${quoteRequestId}/participant`,
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  createParticipants(
    quoteRequestId: number,
    file: File,
    overwrite: boolean,
    csvModel?: CsvImportModel
  ): Observable<ImportResultModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('overwrite', overwrite ? 'true' : 'false');
    formData.append('quoteRequestId', `${quoteRequestId}`);

    if (csvModel != null) {
      formData.append('fieldmap', new Blob([JSON.stringify(csvModel)], { type: 'application/json' }));
    }

    return this.http.post<ImportResultModel>(`${this.baseUrl}/${quoteRequestId}/participant/import`, formData);
  }

  getImportMappingTemplate() {
    const params = new HttpParams({
      fromObject: {
        importType: ImportType.PARTICIPANT
      }
    });

    return this.http.get<ImportTemplateModel[]>(`${SERVER_API_URL}/import-mapping-template`, {
      params
    });
  }

  saveImportMappingTemplate(template: ImportTemplateModel) {
    return this.http.post<ImportTemplateModel>(`${SERVER_API_URL}/import-mapping-template`, {
      ...template,
      importType: ImportType.PARTICIPANT
    });
  }
}
