import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import WebViewer from '@pdftron/webviewer';
import { hasSubdomain, isLocalhost } from '@app/tools/url';
import { environment } from '../../../../environments/environment';

@Component({
  templateUrl: './doc-viewer-dialog.component.html',
  styles: [
    `
      .excel-viewer {
        height: 100%;
      }
    `
  ]
})
export class DocViewerDialogComponent implements AfterViewInit {
  @ViewChild('viewer') viewerRef: ElementRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { blob: Blob; title: string; filename?: string }) {}

  ngAfterViewInit(): void {
    WebViewer(
      {
        path: '/assets/webviewer',
        isReadOnly: true,
        enableAnnotations: false,
        backendType: WebViewer.BackendTypes.WASM,
        licenseKey: this.getApryseLicenseKey()
      },
      this.viewerRef.nativeElement
    ).then((instance) => {
      instance.Core.disableEmbeddedJavaScript();
      let docViewer = instance.Core.documentViewer;
      let LayoutMode = instance.UI.LayoutMode;
      docViewer.addEventListener('documentLoaded', function () {
        docViewer
          .getDocument()
          .getDocumentCompletePromise()
          .then(() => {
            instance.UI.setLayoutMode(LayoutMode.Continuous);
            instance.UI.setZoomLevel(1.25);
            let scrollViewElement = docViewer.getScrollViewElement();
            scrollViewElement.scrollLeft = (scrollViewElement.scrollWidth - scrollViewElement.clientWidth) / 2;
          });
      });

      instance.UI.loadDocument(this.data.blob, { filename: this.data.filename || 'proposal.xlsx' });
    });
  }

  // Check if the URL has a subdomain. If it does, we can assume we aren't in prod since our non-prod environments
  // have subdomains like qa.quote-linq.com or dev1.quote-linq.com. For localhost (local dev) there won't be a subdomain,
  // so check for that case specifically. We want to pass an undefined license key if we aren't in prod to avoid using up
  // our Apryse license allotment. We cannot rely on the environment itself because we use environment.prod.ts for all deployed envs.
  getApryseLicenseKey(): string {
    return hasSubdomain() || isLocalhost() ? undefined : environment.apryseLicenseKey;
  }
}
