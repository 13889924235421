import { OcrFieldModel } from '@main/org-ocr-config/model/org-ocr-config.model';

export class BoundingBoxModel {
  constructor(public width?: number, public height?: number, public left?: number, public top?: number) {}
}

export class OcrExtractModel {
  constructor(
    public key?: string,
    public value?: any,
    public confidence?: number,
    public boundingBoxes?: BoundingBoxModel[],
    public ocrField?: OcrFieldModel
  ) {}
}

export class OcrTableModel {
  constructor(
    public boundingBox?: BoundingBoxModel,
    public rows?: number,
    public columns?: number,
    public cells?: BoundingBoxModel[],
    public fullText?: string
  ) {}
}

export class OcrPageModel {
  constructor(
    public page?: number,
    public imageMimeType?: string,
    public imageEncoded?: string,
    public fullPageText?: string,
    public fields: OcrExtractModel[] = [],
    public tables?: OcrTableModel[]
  ) {}
}

export class OcrTestResultModel {
  constructor(public ocrPages?: OcrPageModel[], public fileName?: string) {}
}
