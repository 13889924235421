<div class="not-found">
  <div>
    <span>
      <fa-icon [icon]="poopIcon"></fa-icon>
    </span>
    <h1>404 Error</h1>
    <div class="heading">
      <p class="heading">Whelp...that stinks. Something went wrong!</p>
    </div>
    <div class="message">
      <p>We couldn't find the page you were looking for.</p>
      <p>
        Get out of here and go back to the
        <a [routerLink]="selectedProductLink$ | async">
          home page.
        </a>
      </p>
    </div>
  </div>
</div>
