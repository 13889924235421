import { Component, Inject, ViewChild } from '@angular/core';
import { FileTools } from '@app/tools/files';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { OnlyWhenValid } from '@form-lib/models/submit.model';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { PopoverRef } from '@shared/components/popover/popover-ref';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';
import { generateFieldDef, getOperators } from './query-input-menu.definition';

class QueryColumn extends DataDefModel {
  operators?: any;
}

class FilterInputData {
  definitions: DataDefModel[];
  queryPrefixes?: string[];
  selectedFilter?;
}

@Component({
  templateUrl: './query-input-menu.component.html',
  styleUrls: ['./query-input-menu.component.scss']
})
export class QueryInputMenuComponent {
  selectedColumn: QueryColumn;
  renderedColumns: QueryColumn[];
  formDef = [];
  queryFormData;
  operatorDef: DataDefModel[];
  operatorFormData;

  definitions: DataDefModel[];
  queryPrefixes;
  selectedFilter;

  @ViewChild('queryForm') queryForm: BaseFormComponent;
  @ViewChild('operatorForm') operatorForm: BaseFormComponent;

  constructor(@Inject(POPOVER_DATA) data: FilterInputData, private popoverRef: PopoverRef) {
    this.definitions = data.definitions;
    this.queryPrefixes = data.queryPrefixes;
    this.selectedFilter = data.selectedFilter;
    this.setColumns(this.definitions);

    if (this.selectedFilter) {
      this.setSelectedQuery(this.selectedFilter);
    } else {
      this.selectColumn(this.renderedColumns[0]);
    }
  }

  setFieldDef(value: { operator }) {
    this.queryFormData = {};
    this.formDef = generateFieldDef(this.selectedColumn, value.operator);
  }

  setOperatorFormData(value) {
    this.operatorFormData = { operator: value };
    this.formDef = generateFieldDef(this.selectedColumn, value);
  }

  setSelectedQuery(selectedQuery) {
    const column = this.renderedColumns.find((col) => selectedQuery.key === (col.queryKey ? col.queryKey : col.key));
    this.setSelectedColumn(column);
    const prevOperator = selectedQuery.operator;
    this.setOperatorFormData(this.findOperator(selectedQuery).value);
    // For "is empty" operator, the operator will be "is" and the value will be either "empty" or "not empty".
    if (this.operatorFormData.operator.empty && prevOperator === 'not' && selectedQuery.value === 'empty') {
      this.setQueryFormData('not empty', this.operatorFormData.operator.empty);
    } else {
      this.setQueryFormData(selectedQuery.value, this.operatorFormData.operator.empty);
    }
  }

  setColumns(columns) {
    this.renderedColumns = [];
    columns.forEach((col) => {
      if (!col.noQuery) {
        const colCopy = { ...col };
        if (col.type === 'boolean' && !col.options) {
          colCopy.options = [
            {
              label: 'True',
              value: true
            },
            {
              label: 'False',
              value: false
            }
          ];
        }
        this.renderedColumns.push(colCopy);
      }
    });
    if (this.queryPrefixes) {
      this.queryPrefixes.forEach((prefix) => {
        this.definitions.forEach((col) => {
          if (!prefix.exclude.includes(col.key)) {
            this.renderedColumns.push({
              ...col,
              label: `${prefix.label} ${col.label}`,
              key: `${prefix.value}.${col.key}`
            });
          }
        });
      });
    }
    this.renderedColumns.sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  selectColumn(column) {
    this.setSelectedColumn(column);
    const firstOperator = this.operatorDef[0].options[0];
    this.setOperatorFormData(firstOperator.value);
  }

  setSelectedColumn(column) {
    // Erase input value
    this.queryFormData = {};
    this.selectedColumn = column;
    const operators = getOperators(column.type);

    this.operatorDef = [
      new DataDefModel({
        label: 'Operator',
        key: 'operator',
        type: DATA_TYPES.select,
        validators: {
          required: true
        },
        layout: {
          base: 12
        },
        readOnly: operators.length === 1,
        options: operators
      })
    ];
  }

  findOperator(query) {
    let isMulti = false;
    if (Array.isArray(query.value)) {
      isMulti = true;
    }
    if (query.value === 'empty') {
      return this.operatorDef[0].options.find(({ value }) => value.empty === true);
    }
    return this.operatorDef[0].options.find(
      ({ value }) => value.operator === query.operator && value.multi === isMulti
    );
  }

  setQueryFormData(value, empty) {
    this.queryFormData = {};
    if (!empty && (this.selectedColumn.type === 'date' || this.selectedColumn.type === DATA_TYPES.dateTime)) {
      if (!!value?.toISOString || isNaN(value)) {
        this.queryFormData.date = value;
      } else {
        this.queryFormData.days = value;
      }
    } else if (this.selectedColumn.type === 'fileSize') {
      FileTools.bytesToKB(value);
    } else {
      this.queryFormData.value = value;
    }
  }

  @OnlyWhenValid
  applyQuery(_) {
    let queryValue;
    const formValue = this.queryForm.form.value;
    const operatorValue = this.operatorForm.form.value;
    if (!operatorValue.operator.empty && (this.selectedColumn.type === 'date' || this.selectedColumn.type === 'dateTime')) {
      if (!!formValue.date?.toISOString || isNaN(formValue.date)) {
        queryValue = formValue.date;
      } else {
        queryValue = formValue.days;
      }
    } else if (this.selectedColumn.type === 'fileSize') {
      queryValue = FileTools.kbToBytes(formValue.value); // convert KB to bytes
    } else {
      queryValue = formValue.value;
    }

    // For "is empty" operator query, the operator needs to change between the "Yes" and "No" options (instead of the value).
    // The definitions are stored as operator: "is" and values "empty" or "not empty".
    // For the query, this is converted into operator "is" or "not" and value is always "empty".
    const query = {
      label: this.selectedColumn.label,
      type: operatorValue.operator.empty ? DATA_TYPES.text : this.selectedColumn.type,
      key: this.selectedColumn.queryKey ? this.selectedColumn.queryKey : this.selectedColumn.key,
      operator: operatorValue.operator.empty && queryValue === 'not empty' ? 'not' : operatorValue.operator.operator,
      value: operatorValue.operator.empty ? 'empty' : queryValue,
      options: this.selectedColumn.options
    };
    this.closePanel(query);
  }

  closePanel(val?) {
    this.popoverRef.close(val);
  }
}
