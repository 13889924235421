import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { getDateFromString, getDaysFromTo } from '@app/tools/date';
import { getValue } from '@lib-resource/data.utils';
import { FundingTypeLabel } from '@common/models/quote-request.model';
import { FundingTypeModel } from '@common/models/funding-type.model';

export const additionalDetailsDef: DataDefModel = new DataDefModel({
  label: 'Additional Details',
  key: 'reasonText',
  type: DATA_TYPES.text
});
export const brokerDueDateDef: DataDefModel = new DataDefModel({
  label: 'Broker Due Date',
  key: 'quoteRequest.brokerDueDate',
  type: DATA_TYPES.date
});
export const contractExpirationDateDef: DataDefModel = new DataDefModel({
  label: 'Contract Expiration Date',
  key: 'quoteRequest.contractExpirationDate',
  type: DATA_TYPES.date
});
export const coverSheetDef: DataDefModel = new DataDefModel({
  label: 'Cover Sheet',
  key: 'quoteRequest.quoteType.name',
  type: DATA_TYPES.text
});
export const createdDateDef: DataDefModel = new DataDefModel({
  label: 'Created Date',
  key: 'createdDate',
  type: DATA_TYPES.date
});
export const daysLeftDef: DataDefModel = new DataDefModel({
  label: 'Days Left',
  key: 'daysLeft',
  queryKey: 'quoteRequest.dueDate',
  type: DATA_TYPES.text,
  readOnly: true,
  noQuery: true,
  calculatedValueFn(row, def) {
    const date = getDateFromString(getValue(row, def.queryKey));
    return date ? getDaysFromTo(new Date(), date) : '';
  }
});
export const effectiveDateDef: DataDefModel = new DataDefModel({
  label: 'Effective Date',
  key: 'quoteRequest.effectiveDate',
  type: DATA_TYPES.date
});
export const specContractTypeDef: DataDefModel = new DataDefModel({
  label: 'Spec Contract Type',
  key: 'quoteRequest.quoteData.specContractType',
  type: DATA_TYPES.text
});
export const firstSubmitDateDef: DataDefModel = new DataDefModel({
  label: 'First Submit Date',
  key: 'firstSubmitDate',
  type: DATA_TYPES.date
});
export const flaggedDrcsDef: DataDefModel = new DataDefModel({
  label: 'Flagged DRCs',
  key: 'drcCount',
  type: DATA_TYPES.number
});
export const fundingTypeDef: DataDefModel = new DataDefModel({
  label: 'Funding Type',
  key: 'quoteRequest.fundingType',
  type: DATA_TYPES.select,
  options: [
    {
      label: FundingTypeLabel[FundingTypeModel.SELF_FUNDED],
      value: FundingTypeModel.SELF_FUNDED
    },
    {
      label: FundingTypeLabel[FundingTypeModel.LEVEL_FUNDED],
      value: FundingTypeModel.LEVEL_FUNDED
    }
  ]
});
export const groupDef: DataDefModel = new DataDefModel({
  label: 'Group',
  key: 'quoteRequest.account.name',
  type: DATA_TYPES.text
});
export const groupIdDef: DataDefModel = new DataDefModel({
  label: 'Group ID',
  key: 'quoteRequest.account.id',
  type: DATA_TYPES.number
});
export const groupOwnerFirstNameDef: DataDefModel = new DataDefModel({
  label: 'Group Owner First Name',
  key: 'quoteRequest.account.contactMember.user.firstName',
  type: DATA_TYPES.text
});
export const groupOwnerLastNameDef: DataDefModel = new DataDefModel({
  label: 'Group Owner Last Name',
  key: 'quoteRequest.account.contactMember.user.lastName',
  type: DATA_TYPES.text
});
export const idDef: DataDefModel = new DataDefModel({ label: 'ID', key: 'id', type: DATA_TYPES.number });
export const isIncumbentDef: DataDefModel = new DataDefModel({
  label: 'Is Incumbent',
  key: 'incumbent',
  type: DATA_TYPES.boolean,
  options: [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ]
});
export const issuerDef: DataDefModel = new DataDefModel({
  label: 'Issuer',
  queryKey: 'quoteRequest.quoteType.org.name',
  key: 'quoteRequest.quoteType.orgName',
  type: DATA_TYPES.text
});
export const issuerIdDef: DataDefModel = new DataDefModel({
  label: 'Issuer ID',
  queryKey: 'quoteRequest.quoteType.org.id',
  key: 'quoteRequest.quoteType.orgId',
  type: DATA_TYPES.number
});
export const matchDexDef: DataDefModel = new DataDefModel({
  label: 'MatchDex Score',
  key: 'matchIndex',
  type: DATA_TYPES.percentage
});
export const modifiedDateDef: DataDefModel = new DataDefModel({
  label: 'Modified Date',
  key: 'lastModifiedDate',
  type: DATA_TYPES.date
});
export const primaryGroupNumberDef: DataDefModel = new DataDefModel({
  label: 'Primary Group Number',
  key: 'quoteRequest.account.groupIdRef',
  type: DATA_TYPES.text
});
export const secondaryGroupIdsDef: DataDefModel = new DataDefModel({
  key: 'quoteRequest.account.secondaryGroupIdRefs',
  type: DATA_TYPES.multiInput,
  label: 'Client Identifier(s)',
  noSort: true
});
export const quoteDueDateDef: DataDefModel = new DataDefModel({
  label: 'Quote Due Date',
  key: 'quoteRequest.dueDate',
  type: DATA_TYPES.date
});
export const requestIdDef: DataDefModel = new DataDefModel({
  label: 'Request ID',
  key: 'quoteRequest.id',
  type: DATA_TYPES.number
});
export const requestNameDef: DataDefModel = new DataDefModel({
  label: 'Request Name',
  key: 'quoteRequest.name',
  type: DATA_TYPES.text
});
export const carrierDef: DataDefModel = new DataDefModel({
  label: 'Carrier',
  key: 'org.name',
  type: DATA_TYPES.text
});
export const responderIdDef: DataDefModel = new DataDefModel({
  label: 'Responder ID',
  key: 'org.id',
  type: DATA_TYPES.number
});
export const receivedDateDef: DataDefModel = new DataDefModel({
  label: 'Received Date',
  key: 'quoteRequest.receivedDate',
  type: DATA_TYPES.date
});
export const stageChangeDate: DataDefModel = new DataDefModel({
  label: 'Stage Change Date',
  key: 'stateChangeDate',
  type: DATA_TYPES.date
});
export const tagsDef: DataDefModel = new DataDefModel({
  label: 'Tags',
  key: 'tag.name',
  type: DATA_TYPES.text,
  noSort: true,
  calculatedValueFn: (row) => (row.tags ? row.tags.map((t) => t.name).join(',') : row.value ? row.value : '')
});
export const unreadMsgsDef: DataDefModel = new DataDefModel({
  label: 'Unread Messages',
  key: 'unreadmsg',
  type: DATA_TYPES.boolean,
  noSort: true,
  noView: true,
  options: [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ]
});
