import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { SetAuthenticated } from '@auth/store/auth.actions';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AUTHENTICATED } from '@auth/store/auth.state';

const RMT_EXPIRATION_DATE = 'RMT-EXPIRATION-DATE';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private whitelist: string[] = [
    `${SERVER_API_URL}/account/register`,
    `${SERVER_API_URL}/account/activate`,
    `${SERVER_API_URL}/account/activate/resend-key`,
    `${SERVER_API_URL}/account/authenticate`,
    `${SERVER_API_URL}/account/reset-password/init`,
    `${SERVER_API_URL}/account/reset-password/finish`,
    `${SERVER_API_URL}/account/exists`,
    `${SERVER_API_URL}/quote-request-proposal`,
    `${SERVER_API_URL}/authentication/login`,
    `${SERVER_API_URL}/server/info`
  ];

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request ||
      !request.url ||
      (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL))) ||
      this.whitelist.find((x) => request.url.startsWith(x))
    ) {
      return next.handle(request);
    }

    const store = this.injector.get(Store);

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const res: HttpResponse<any> = event as HttpResponse<any>;
          const rmtExpireDate = res.headers.get(RMT_EXPIRATION_DATE);
          if (rmtExpireDate && store) {
            // update the expireDate in the store
            store.dispatch(new SetAuthenticated(AUTHENTICATED, +rmtExpireDate));
          }
        }
      })
    );
  }
}
