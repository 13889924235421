import { Pipe, PipeTransform } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';

interface DisplayItems {
  [prop: string]: boolean;
}
class DataDefMin {
  key: string;
}

@Pipe({
  name: 'displayItems'
})
export class DisplayItemsPipe implements PipeTransform {
  transform(dataDefs: DataDefModel[], displayItems: string[] | DisplayItems): DataDefModel[] {
    if (!dataDefs) return;
    if (!displayItems) return dataDefs;
    const keyMap = Array.isArray(displayItems)
      ? displayItems.reduce((acc, key) => ({ ...acc, [key]: true }), {})
      : displayItems;
    return dataDefs.filter((def) => keyMap[def.key]);
  }
}
