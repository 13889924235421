import { Component, Input } from '@angular/core';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { get } from 'lodash-es';

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss']
})
export class InfoTableComponent {
  @Input() headerLabel: string;
  @Input() rowsGroups: DataDefModel[][];
  @Input() totalRowDef: DataDefModel;
  @Input() data: any;
  protected readonly DATA_TYPES = DATA_TYPES;

  getValue(data: any, rowDef: DataDefModel) {
    return rowDef.calculatedValueFn ? rowDef.calculatedValueFn(data) : get(data, rowDef.key);
  }
}
