import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appScrollListener]'
})
export class ScrollListenerDirective {
  @Output() scrollEnd = new EventEmitter();

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
      this.scrollEnd.emit();
    }
  }
}
