import { Pipe, PipeTransform } from '@angular/core';
import { AppCurrencyPipe } from '@shared/pipes/app-currency.pipe';

@Pipe({
  name: 'accountingCurrency'
})
export class AccountingCurrencyPipe implements PipeTransform {
  private locale: string = 'en-US';
  private currencyPipe = new AppCurrencyPipe(this.locale);
  transform(value: any, format: string = '1.2-0'): any {
    value = value === null || value === undefined || isNaN(value) ? 0 : value;
    const currency = this.currencyPipe.transform(value >= 0 ? value : -value, 'USD', format, this.locale, 'symbol');
    return value >= 0 ? currency : `(${currency})`;
  }
}
