<!--
 IMPORTANT: the min-height style is a temporary hack to keep the query-menu in view when the table lacks enough content
-->
<div class="data-table-wrapper" [class]="{ paginated: paginated }">
  <table *ngIf="loading" mat-table [dataSource]="data?.length ? data : [1, 1, 1]">
    <ng-container *ngFor="let column of dataSource?.columns | async" [matColumnDef]="column.key">
      <th mat-header-cell class="header-wrapper" *matHeaderCellDef>
        <app-skeleton-loader
          class="m-auto"
          *ngIf="column.type === 'rowSelect' && showSelectAll"
          Cheight="16"
          Cwidth="16"
        ></app-skeleton-loader>
        <div style="width: 16px; height: 16px" *ngIf="column.type === 'rowSelect' && !showSelectAll"></div>
        <div *ngIf="column.type !== 'rowSelect'">
          <span [innerHTML]="column.label"></span>
        </div>
      </th>
      <td *matCellDef="let row" mat-cell [ngSwitch]="column.type" [class]="column.class">
        <div class="user-selectable loading-skeleton-wrapper">
          <app-skeleton-loader
            class="m-auto"
            *ngSwitchCase="'expandable'"
            Cheight="16"
            Cwidth="16"
          ></app-skeleton-loader>
          <app-skeleton-loader
            class="m-auto"
            *ngSwitchCase="'rowSelect'"
            Cheight="16"
            Cwidth="16"
          ></app-skeleton-loader>
          <app-skeleton-loader class="m-auto" *ngSwitchCase="'icon'" Cheight="16" Cwidth="16"></app-skeleton-loader>
          <ng-container *ngSwitchDefault>
            <app-skeleton-loader Cheight="16" CminWidth="80"></app-skeleton-loader>
          </ng-container>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ actionColumnName }}</th>
      <td mat-cell *matCellDef="let row">
        <button type="button" mat-icon-button disabled>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dataSource?.displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = dataIndex; columns: dataSource?.displayedColumns"></tr>
  </table>

  <table
    [style.display]="loading ? 'none' : ''"
    mat-table
    matSort
    [dataSource]="dataSource"
    [attr.aria-label]="ariaLabel"
    multiTemplateDataRows
  >
    <ng-container *ngFor="let column of dataSource?.columns | async" [matColumnDef]="column.key">
      <th
        mat-header-cell
        class="header-wrapper"
        [class.select-all-wrapper]="column.type === 'rowSelect'"
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!paginated || noSort || column.noSort"
      >
        <span *ngIf="column.type === 'rowSelect'; else normalHeader">
          <mat-checkbox
            matTooltip="Select All"
            *ngIf="showSelectAll"
            class="no-margin-label"
            (click)="$event.stopPropagation()"
            color="primary"
            [checked]="allRowsSelected"
            (change)="onSelectAll($event)"
          >
          </mat-checkbox>
        </span>

        <ng-template #normalHeader>
          <span [innerHTML]="column.label"></span>
          <mat-icon *ngIf="column.hint" dense class="dense hint-icon" [matTooltip]="column.hint">
            info_outline
          </mat-icon>
        </ng-template>
      </th>

      <td
        mat-cell
        *matCellDef="let row; let i = rowIndex"
        [ngSwitch]="column.type"
        [class]="column.class"
        matTooltip="{{ getTooltip(row) }}"
      >
        <div class="user-selectable">
          <ng-container *ngSwitchCase="'expandable'">
            <ng-container *ngIf="expandableWhen(row)">
              <fa-icon *ngIf="isRowExpanded(row)" [icon]="expandedIcon"></fa-icon>
              <fa-icon *ngIf="!isRowExpanded(row)" [icon]="notExpandedIcon"></fa-icon>
            </ng-container>
          </ng-container>

          <mat-checkbox
            *ngSwitchCase="'rowSelect'"
            (click)="$event.stopPropagation()"
            color="primary"
            [checked]="isChecked(row, selectedRows)"
            (change)="onSelectionChange($event, row)"
          ></mat-checkbox>

          <mat-radio-button
            *ngSwitchCase="'rowSingleSelect'"
            (click)="$event.stopPropagation()"
            color="primary"
            [checked]="isSelected(row)"
            (change)="onSelectionChange($event, row)"
          ></mat-radio-button>

          <ng-container *ngSwitchCase="'icon'">
            <ng-container
              *ngIf="!!column.derivedIcon && column.derivedIcon(row, column) as derivedIcons; else justIcon"
            >
              <fa-icon
                *ngIf="derivedIcons.length"
                [icon]="derivedIcons[0].icon"
                [classes]="derivedIcons[0].classes"
              ></fa-icon>
            </ng-container>
            <ng-template #justIcon>
              <fa-icon *ngIf="!!column.icon" [icon]="column.icon"></fa-icon>
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <app-data-table-cell [row]="row" [columnDef]="column"></app-data-table-cell>
          </ng-container>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span *ngIf="column.rollupFn">
          {{ column.rollupFn(dataSource?.data.value, column) | dataFormatter: column }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let parentRow" [attr.colspan]="dataSource?.displayedColumns.length">
        <div class="expansion-area" *ngIf="withExpansion">
          <app-data-table
            *ngIf="isRowExpanded(parentRow)"
            style="width: 100%"
            [data]="expansionData"
            [noSort]="true"
            [columns]="expansionColumns"
            [paginated]="false"
          ></app-data-table>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ actionColumnName }}</th>
      <td mat-cell *matCellDef="let row; let i = rowIndex" (click)="$event.stopPropagation()">
        <ng-container *ngIf="inlineActions; else menuActions">
          <div class="grid-content justify-content-center">
            <ng-container *ngFor="let action of actions | hideIf: row">
              <button
                mat-icon-button
                style="pointer-events: all"
                (click)="action.callback(row, i, data, $event)"
                class="color-action-icon"
                [class]="action.classes"
                [disabled]="action.disableIf && action.disableIf(row)"
                *ngIf="action.callback"
              >
                <fa-icon
                  [icon]="action.icon"
                  class="action-button-icon"
                  [matTooltip]="toolTipText(action, row)"
                ></fa-icon>
              </button>
              <a
                mat-icon-button
                [class]="action.classes"
                [routerLink]="action.link(row)"
                [queryParams]="action.linkParams ? stringifyParams(action.linkParams(row)) : null"
                *ngIf="action.link"
              >
                <fa-icon
                  [icon]="action.icon"
                  class="action-button-icon"
                  [matTooltip]="toolTipText(action, row)"
                ></fa-icon>
              </a>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #menuActions>
          <button
            type="button"
            [attr.aria-label]="'Actions for current row'"
            mat-icon-button
            [matMenuTriggerFor]="actionsMenu"
            class="pt-1"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu">
            <ng-container *ngFor="let action of actions | hideIf: row">
              <ng-container *ngIf="!!action.disableIf && action.disableIf(row); else enabledAction">
                <div
                  [matTooltip]="action.disabledMessage"
                  (click)="$event.stopPropagation()"
                  [matTooltipDisabled]="!action.disabledMessage"
                >
                  <div mat-menu-item disabled>
                    <div class="grid-content">
                      <fa-icon
                        *ngIf="action.icon"
                        class="mat-icon action-menu-icon"
                        [icon]="action.icon"
                        [classes]="action.classes || []"
                      ></fa-icon>
                      <span>{{ action.label | derivedActionLabel: action : row }}</span>
                      <fa-icon [icon]="warningIcon" class="color-status-orange-dark pl-2"></fa-icon>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #enabledAction>
                <button type="button" (click)="action.callback(row, i, data)" mat-menu-item *ngIf="action.callback">
                  <div class="grid-content">
                    <fa-icon
                      *ngIf="action.icon"
                      class="mat-icon action-menu-icon"
                      [icon]="action.icon"
                      [classes]="action.classes || []"
                    ></fa-icon>
                    <span>{{ action.label | derivedActionLabel: action : row }}</span>
                  </div>
                </button>
                <a
                  [routerLink]="action.link(row)"
                  [queryParams]="action.linkParams ? stringifyParams(action.linkParams(row)) : null"
                  mat-menu-item
                  *ngIf="action.link"
                >
                  <div class="grid-content">
                    <fa-icon
                      *ngIf="action.icon"
                      class="mat-icon action-menu-icon"
                      [icon]="action.icon"
                      [classes]="action.classes || []"
                    ></fa-icon>
                    <span>{{ action.label | derivedActionLabel: action : row }}</span>
                  </div>
                </a>
              </ng-template>
            </ng-container>
          </mat-menu>
        </ng-template>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="dataSource?.displayedColumns"></tr>

    <tr
      mat-row
      *matRowDef="let row; let i = dataIndex; columns: dataSource?.displayedColumns; when: whenWithoutSelection"
      [class]="{ odd: i % 2 === 0, highlighted: highlightWhen(row) }"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = dataIndex; columns: dataSource?.displayedColumns; when: whenWithSelection"
      (click)="selectRow(row)"
      class="selectable-row regular"
      [class]="calculateRowClasses(i, row)"
      role="button"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; let i = dataIndex; columns: dataSource?.displayedColumns; when: whenExpandable"
      (click)="selectRow(row)"
      class="selectable-row"
      [class]="calculateRowClasses(i, row)"
      role="button"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']; when: whenExpandable"
      [class]="{ expanded: isSelected(row), highlighted: highlightWhen(row) }"
      class="detail-row"
    ></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="dataSource?.displayedColumns"
      style="font-weight: 900; box-shadow: inset 0 1px rgba(0, 0, 0, 0.25)"
      [class]="{ hide: !(dataSource?.hasRollup$ | async) }"
    ></tr>
  </table>
</div>

<mat-paginator
  *ngIf="paginated"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [length]="total"
  [pageSizeOptions]="pageSizeOptions"
  [hidePageSize]="minimalPagination"
  [style.display]="minimalPagination ? 'none' : 'block'"
  class="dense"
></mat-paginator>

<div *ngIf="minimalPagination">
  <button mat-icon-button [disabled]="!paginator.hasPreviousPage()" (click)="paginator.previousPage()">
    <fa-icon [icon]="lessThanIcon"></fa-icon>
  </button>
  <button mat-icon-button [disabled]="!paginator.hasNextPage()" (click)="paginator.nextPage()">
    <fa-icon [icon]="greaterThanIcon"></fa-icon>
  </button>
</div>
