export class SearchModel {
  constructor(
    public requiredFilter?: string,
    public filterString?: string,
    public sort?: string[],
    public pageSize?: number,
    public pageIndex?: number
  ) {}

  buildQueryString(): string {
    if (this.filterString && this.requiredFilter) {
      return `(${this.filterString}) and (${this.requiredFilter})`;
    }
    return this.filterString || this.requiredFilter || '';
  }
}
