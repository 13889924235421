import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

export const columnDefs: DataDefModel[] = [
  new DataDefModel({ label: 'ID', key: 'id', type: DATA_TYPES.number }),
  new DataDefModel({ label: 'Subscriber Number', key: 'subscriberNumber', type: DATA_TYPES.number }),
  new DataDefModel({ label: 'Employee Id', key: 'employeeId', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Dependents', key: 'dependentCnt', type: DATA_TYPES.number }),
  new DataDefModel({ label: 'First Name', key: 'firstName', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Middle Name', key: 'middle', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Last Name', key: 'lastName', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Plan Assignment', key: 'planAssignment', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Medical Tier', key: 'medicalTier', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Waiver Reason', key: 'waiverReason', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'DOB', key: 'dateOfBirth', type: DATA_TYPES.date }),
  new DataDefModel({ label: 'SSN', key: 'ssn', type: DATA_TYPES.number }),
  new DataDefModel({
    label: 'Gender',
    key: 'gender',
    type: DATA_TYPES.enum,
    options: [
      { label: 'Female', value: 'FEMALE' },
      { label: 'Male', value: 'MALE' },
      { label: 'Other', value: 'OTHER' }
    ]
  }),
  new DataDefModel({
    label: 'Coverage',
    key: 'coverage',
    type: DATA_TYPES.enum,
    options: [
      { label: 'Employee', value: 'EMPLOYEE' },
      { label: 'Employee + Spouse', value: 'EMPLOYEE_SPOUSE' },
      { label: 'Family', value: 'FAMILY' },
      { label: 'Employee + Children', value: 'EMPLOYEE_CHILDREN' },
      { label: 'Employee & 1 Dep', value: 'EMPLOYEE_DEP' },
      { label: 'Employee & 1 Child', value: 'EMPLOYEE_CHILD' },
      { label: 'Waived', value: 'WAIVED' }
    ]
  }),
  new DataDefModel({
    label: 'Emp Status',
    key: 'employmentStatus',
    type: DATA_TYPES.enum,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'COBRA', value: 'COBRA' },
      { label: 'Leave of Absence', value: 'LEAVE' },
      { label: 'Retired', value: 'RETIRED' },
      { label: 'Terminated', value: 'TERMINATED' }
    ]
  }),
  new DataDefModel({ label: 'Address 1', key: 'address1', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Address 2', key: 'address2', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'City', key: 'city', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'State', key: 'state', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Zip', key: 'zip', type: DATA_TYPES.text }),
  new DataDefModel({
    label: 'Relationship',
    key: 'relationship',
    type: DATA_TYPES.enum,
    options: [
      { label: 'Self', value: 'SELF' },
      { label: 'Spouse', value: 'SPOUSE' },
      { label: 'Child', value: 'CHILD' },
      { label: 'Ex-spouse', value: 'EX_SPOUSE' },
      { label: 'Life Partner', value: 'LIFE_PARTNER' },
      { label: 'Adopted Child', value: 'ADOPTED_CHILD' },
      { label: 'Stepchild', value: 'STEPCHILD' },
      { label: 'Grandchild', value: 'GRANDCHILD' },
      { label: 'Other', value: 'OTHER' }
    ]
  }),
  new DataDefModel({ label: 'Hire Date', key: 'dateOfHire', type: DATA_TYPES.date })
].map((def) => ({ ...def, layout: { base: 6 } }));

export const defaultCols = columnDefs.map((def) => def.key);
