import { FilterBrickRowOption } from '@app/libraries/workspace-lib/model/filter-brick-row.model';

export namespace Workspace {
  export class Reset {
    static readonly type = '[Workspace] Reset';
  }

  export class SetSelectedItem {
    static readonly type = '[Workspace] Set Selected Item';

    constructor(public item: any) {}
  }

  export class SetSearchValue {
    static readonly type = '[Workspace] Set search text';

    constructor(public value: string) {}
  }

  export class ToggleFilter {
    static readonly type = '[Workspace] Toggle filter';

    constructor(public payload: FilterBrickRowOption) {}
  }

  export class ResetFilters {
    static readonly type = '[Workspace] Reset filters';
  }

  export class Refresh {
    static readonly type = '[workspace] Refresh';
  }

  export class Close {
    static readonly type = '[workspace] Close';
  }
}
