import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { LabelOnlyClasses } from '@form-lib/models/form.model';
import {
  aggCommissionDef,
  aggContractTypeDef,
  aggCoverageDef,
  aggDeductibleDef,
  aggEstAnnualPremiumDef,
  aggMaxReimbursementDef,
  costSummaryAggAnnualVariableDef,
  costSummaryAnnualFixedDef,
  costSummaryMaxAnnualDef,
  costSummarySpecAnnualVariableDef,
  specAggDeductibleDef,
  specCommissionIncludedDef,
  specContractTypeDef,
  specCoverageDef,
  specDeductibleDef,
  specPremiumAmountDef
} from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';

export const quoteResponseSubmitDataDefs: DataDefModel = new DataDefModel({
  key: 'quoteResponseOptions',
  type: DATA_TYPES.formGroupArray,
  label: 'Options',
  sectionKey: 'options',
  notOrderable: true,
  itemPrefix: 'Option',
  definitions: [
    new DataDefModel({
      key: 'costSummarySectionHeader',
      type: DATA_TYPES.labelOnly,
      labelClasses: LabelOnlyClasses.subHeading,
      label: 'Cost Summary'
    }),
    costSummaryAnnualFixedDef,
    costSummaryMaxAnnualDef,
    new DataDefModel({
      key: 'specSectionHeader',
      type: DATA_TYPES.labelOnly,
      labelClasses: LabelOnlyClasses.subHeading,
      label: 'Specific Summary'
    }),
    specCoverageDef,
    specContractTypeDef,
    specDeductibleDef,
    specAggDeductibleDef,
    specPremiumAmountDef,
    new DataDefModel({
      key: 'aggSectionHeader',
      type: DATA_TYPES.labelOnly,
      labelClasses: LabelOnlyClasses.subHeading,
      label: 'Aggregate Summary'
    }),
    aggCoverageDef,
    aggContractTypeDef,
    aggMaxReimbursementDef,
    aggDeductibleDef,
    aggEstAnnualPremiumDef
  ]
});
