import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toObject } from '@app/tools/array';
import { FieldRendererComponent } from '@form-lib/field-renderer/field-renderer.component';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { OcrDialogComponent } from '@form-lib/ocr-overlay/ocr-dialog/ocr-dialog.component';
import { OcrPopoverTriggerComponent } from '@form-lib/ocr-overlay/ocr-popover-trigger/ocr-popover-trigger.component';
import { OcrFieldModel, OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { getDateFromString } from '@app/tools/date';

interface InstanceAndRef {
  instance: FieldRendererComponent;
  viewRef: ViewContainerRef;
  elRef: ElementRef;
}

interface InstanceAndRefDict {
  [prop: string]: InstanceAndRef;
}

@Directive({
  selector: '[appOcrOverlay]',
  exportAs: 'ocrOverlay'
})
export class OcrOverlayDirective implements OnChanges, OnInit {
  @Input('appOcrOverlay') data: OcrModel[];
  // The component is attached to the class to make testing easier
  triggerComponentClass = OcrPopoverTriggerComponent;
  ocrFieldsByKey: {
    [prop: string]: OcrFieldModel;
  };

  popoverInstances: {
    [prop: string]: ComponentRef<OcrPopoverTriggerComponent>;
  } = {};

  constructor(
    private formComponent: BaseFormComponent,
    private componentFactoryResolver: ComponentFactoryResolver,
    private dialog: MatDialog
  ) {}

  ngOnChanges({ data }: SimpleChanges) {
    if (!data) return;
    if (data.currentValue) {
      this.generateOcrFieldsByKey();
      if (this.formComponent.editing) {
        setTimeout(() => this.renderOverlays());
      }
    } else {
      this.destroyOverlays();
    }
  }

  ngOnInit() {
    this.formComponent.statusChanges.subscribe(() => {
      if (!this.formComponent.editing) {
        this.destroyOverlays();
      }
    });
  }

  generateOcrFieldsByKey() {
    this.ocrFieldsByKey = {};
    this.data.forEach((doc: OcrModel) => {
      const docData = {
        image: `data:${doc.imageMimeType};base64,${doc.imageEncoded}`,
        page: doc.page
      };
      Object.assign(this.ocrFieldsByKey, toObject(doc.fields.map((field) => ({ docData, ...field }))));
    });
  }

  destroyOverlays() {
    const dataKeys = Object.keys(this.popoverInstances);

    if (!dataKeys.length) return;

    const instancesAndViewRefs: InstanceAndRefDict = this.getInstancesAndViewRefsByKey();
    dataKeys.forEach((key) => {
      const container = instancesAndViewRefs[key];
      container.viewRef.remove();
    });
    this.popoverInstances = {};
  }

  renderOverlays(): void {
    this.destroyOverlays();
    const instancesAndViewRefs: InstanceAndRefDict = this.getInstancesAndViewRefsByKey();
    Object.keys(this.ocrFieldsByKey).forEach((key) => {
      const fieldComponent = instancesAndViewRefs[key];
      if (!fieldComponent) {
        return;
      }
      this.assignFieldControlValue(fieldComponent, key);
      this.attachPopover(key, fieldComponent);
    });
  }

  private assignFieldControlValue(fieldComponent, key: string): void {
    // With the recent change to use the material-moment-adapter, the control is
    // expecting the value to be a date object.
    if (fieldComponent.instance.definition.type === 'datepicker') {
      fieldComponent.instance.control.setValue(getDateFromString(this.ocrFieldsByKey[key].value));
    } else {
      fieldComponent.instance.control.setValue(this.ocrFieldsByKey[key].value);
    }
  }

  private attachPopover(key: string, fieldComponent: InstanceAndRef) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.triggerComponentClass);
    const componentRef = fieldComponent.viewRef.createComponent(componentFactory);
    componentRef.instance.ocrData = this.ocrFieldsByKey[key];
    componentRef.instance.field = fieldComponent.instance.definition;
    componentRef.instance.openDialog.subscribe(() => {
      this.dialog
        .open(OcrDialogComponent, {
          data: {
            ocrData: this.ocrFieldsByKey[key],
            definition: fieldComponent.instance.definition
          }
        })
        .afterClosed()
        .subscribe((value) => {
          if (!value) return;
          fieldComponent.instance.control.setValue(value);
        });
    });
    this.popoverInstances[key] = componentRef;
  }

  private getInstancesAndViewRefsByKey(): InstanceAndRefDict {
    const refs = {};
    this.formComponent.fieldComponents.forEach((component) => {
      this.buildRefForFieldComponent(component, refs);
    });
    this.formComponent.contentFieldComponents?.forEach((component) => {
      this.buildRefForFieldComponent(component, refs);
    });
    this.formComponent.arrayComponent.forEach((arrayComponent) => {
      this.buildRefsForArrayComponent(arrayComponent, refs);
    });
    this.formComponent.contentArrayComponent?.forEach((arrayComponent) => {
      this.buildRefsForArrayComponent(arrayComponent, refs);
    });
    return refs;
  }

  buildRefForFieldComponent(component: FieldRendererComponent, refs) {
    refs[component.definition.key] = {
      instance: component,
      viewRef: component.viewRef
    };
  }

  buildRefsForArrayComponent(arrayComponent: BaseArrayComponent, refs) {
    arrayComponent.arrayItemFormGroupDirectives.forEach((directive, index) => {
      if (!directive.fieldComponents || !directive.fieldComponents.length) return;
      directive.fieldComponents.forEach((component) => {
        refs[`${arrayComponent.definition.key}[${index}].${component.definition.key}`] = {
          instance: component,
          viewRef: component.viewRef
        };
      });
    });
  }
}
