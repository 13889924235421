import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matStepperAnimations } from '@angular/material/stepper';
import { faCheck, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

export class StepperNavEvent {
  startIndex: number;
  endIndex: number;
  valid?: boolean;
  start?: CdkStep;
  end?: CdkStep;
  failure?: () => void; // async callback
  success?: () => void; // async callback
}

@Component({
  selector: 'app-stepper',
  exportAs: 'appStepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  animations: [matStepperAnimations.horizontalStepTransition]
})
export class StepperComponent extends CdkStepper {
  loading = false;
  @Input() asyncSteps: boolean;
  @Input() completed: boolean;
  @Output() navigationStart = new EventEmitter<StepperNavEvent>();
  @Output() stepperComplete = new EventEmitter<StepperNavEvent>();

  checkIcon = faCheck;
  errorIcon = faExclamationTriangle;

  startNavigation(endIndex) {
    const endStep = this.steps.find((_, i) => i === endIndex);
    const sharedNavProperties: StepperNavEvent = {
      startIndex: this.selectedIndex,
      start: this.selected,
      endIndex,
      end: endStep
    };
    sharedNavProperties.valid =
      endIndex < this.selectedIndex ||
      !this.selected.hasError ||
      (this.selected.optional && !this.selected.stepControl?.invalid);

    if (this.asyncSteps) {
      this.loading = true;
      this.navigationStart.emit({
        ...sharedNavProperties,
        success: () => {
          this.setStep(endIndex);
          this.loading = false;
        },
        failure: () => (this.loading = false)
      });
    } else {
      if (sharedNavProperties.valid) {
        this.setStep(endIndex);
      }
      this.navigationStart.emit(sharedNavProperties);
    }
  }

  setStep(endIndex: number) {
    if (endIndex > this.selectedIndex) {
      const steps = this.steps.toArray();
      for (let i = this.selectedIndex; i <= endIndex; i++) {
        steps[i].interacted = true;
      }
    }
    this.selectedIndex = endIndex;
    if (this.selected === this.steps.last) {
      this.selected.interacted = true;
    }
  }
}
