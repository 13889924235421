<label [class]="{ 'inline-label': inline, required: required }" class="async-select-field">
  <span class="label-text" truncateText *ngIf="inline" aria-hidden="true"> {{ label }}</span>
  <mat-form-field [appearance]="appearance">
    <mat-select
      panelClass="async-select-field-selector"
      [compareWith]="compareFn"
      [required]="required"
      [formControl]="control"
      [appIsReadOnly]="readOnly"
      (valueChange)="_valueChanged($event)"
    >
      <mat-select-trigger truncateText>{{ displayValue$ | async }}</mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [formControl]="searchTextBoxControl"
          [searching]="searching"
          noEntriesFoundLabel="'No results found.'"
          placeholderLabel="Search"
        ></ngx-mat-select-search>
      </mat-option>
      <div class="option-scroll-container">
        <mat-option *ngIf="!required">--</mat-option>
        <mat-option truncateText *ngFor="let option of currentOptions$ | async" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </div>
    </mat-select>
    <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
    <mat-hint *ngIf="!control.parent?.disabled || !control.disabled">
      <div truncateText>{{ hint }}</div>
    </mat-hint>
    <mat-error>
      <app-error-renderer [errors]="control.errors"></app-error-renderer>
    </mat-error>
  </mat-form-field>
</label>
