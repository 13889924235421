import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SERVER_API_URL } from '@app/app.constants';
import { UrlUtilService } from '@common/services/url-util.service';
import { OcrTemplateModel } from '@main/org-ocr-config/model/org-ocr-config.model';
import { OcrTestResultModel } from '@main/org-ocr-config/model/org-ocr-result.model';
import { OrgOcrTemplateTestDialogComponent } from '@main/org-ocr-config/org-ocr-template-test-dialog/org-ocr-template-test-dialog.component';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrgOcrConfigService {
  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  constructor(
    private store: Store,
    private http: HttpClient,
    private dialog: MatDialog,
    private urlUtil: UrlUtilService
  ) {}

  ocrTemplateNameExists(id: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );
    if (id) {
      params = params.set('id', `${id}`);
    }
    return this.http.get<boolean>(`${this.baseUrl}/ocr-template/exists`, { params: params });
  }

  getOcrTemplates(): Observable<OcrTemplateModel[]> {
    return this.store
      .select(SiteFilterSelectors.singleSelectedOrgId)
      .pipe(switchMap((orgId) => this.http.get<OcrTemplateModel[]>(`${SERVER_API_URL}/org/${orgId}/ocr-template`)));
  }

  createOcrTemplate(ocrTemplate: OcrTemplateModel): Observable<OcrTemplateModel> {
    return this.http.post<OcrTemplateModel>(`${this.baseUrl}/ocr-template`, ocrTemplate);
  }

  updateOcrTemplate(id: number, ocrTemplate: OcrTemplateModel): Observable<OcrTemplateModel> {
    return this.http.put<OcrTemplateModel>(`${this.baseUrl}/ocr-template/${id}`, ocrTemplate);
  }

  getOcrTemplate(id: number): Observable<OcrTemplateModel> {
    return this.http.get<OcrTemplateModel>(`${this.baseUrl}/ocr-template/${id}`);
  }

  deleteOcrTemplate(id: number): Observable<any> {
    return this.http.delete<OcrTemplateModel>(`${this.baseUrl}/ocr-template/${id}`);
  }

  openTestDialog(ocrTemplateId: number): MatDialogRef<OrgOcrTemplateTestDialogComponent, OcrTestResultModel> {
    return this.dialog.open(OrgOcrTemplateTestDialogComponent, {
      disableClose: true,
      width: '600px',
      data: {
        ocrTemplateId: ocrTemplateId
      }
    });
  }
}
