import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FilterBrickRowModel, FilterBrickRowOption } from '@app/libraries/workspace-lib/model/filter-brick-row.model';
import { sortByProperty } from '@app/tools/array';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { SavedFilterService } from '@common/services/saved-filter.service';
import { BulkActionDef, ItemSelection, QueryModel } from '@data-table-lib/data-table-config';
import { DisplayColumnsMenuComponent } from '@data-table-lib/data-table-header/display-columns-menu/display-columns-menu.component';
import { GlossaryDialogComponent } from '@data-table-lib/data-table-header/glossary-dialog/glossary-dialog.component';
import { QueryInputMenuComponent } from '@data-table-lib/data-table-header/query-input-menu/query-input-menu.component';
import { parseToQuery } from '@data-table-lib/data-table.utils';
import { ActionDef, FilterUpdate } from '@data-table-lib/models/data-table.model';
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons';
import {
  faBarsFilter,
  faBoltLightning,
  faColumns,
  faDownload,
  faGear,
  faInfoCircle,
  faStar as fasStar,
  faTimes,
  faTrash,
  faWarning
} from '@fortawesome/pro-solid-svg-icons';
import { DATA_TYPES, DataDefGlossary, DataDefModel, DataDefTerms } from '@lib-resource/data-def.model';
import { FiltersModel } from '@main/store/workspace/workspace.model';
import { PopoverRef } from '@shared/components/popover/popover-ref';
import { PopoverService } from '@shared/components/popover/popover.service';
import { QueryDisplayService } from '@shared/components/query-display/query-display.service';
import { NotificationService } from '@shared/notifications/notification.service';
import { RmtQlNodeModel } from '@common/models/rmt-ql.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { DataTableSelectors } from '@main/store/data-table/data-table.selectors';
import { DataTable } from '@main/store/data-table/data-table.actions';
import { SavedFilterModel, SavedFilterType } from '../models/saved-filter.model';

/* eslint-disable */
export function findMatchingQueries(savedQueries, routeQuery) {
  return savedQueries.find(({ query }) => {
    if (query.length !== routeQuery.length) return;
    return !query.some(
      (q) =>
        !routeQuery.some((rq) => {
          if (rq.key === q.key && rq.operator === q.operator && rq.type === q.type) {
            if (Array.isArray(rq.value) && Array.isArray(q.value)) {
              return JSON.stringify(rq.value.sort()) === JSON.stringify(q.value.sort());
            }
            // eslint-disable-next-line eqeqeq
            if (rq.value == q.value) {
              return true;
            }
          }
          return false;
        })
    );
  });
}
/* eslint-enable */

interface BulkActionMenuItem {
  disabled: boolean;
  disabledMessage: string;
  bulkActionDef: BulkActionDef<any>;
}

@Component({
  selector: 'app-data-table-header',
  templateUrl: './data-table-header.component.html',
  styleUrls: ['./data-table-header.component.scss']
})
export class DataTableHeaderComponent implements OnInit, OnChanges, OnDestroy {
  advanced: boolean = false;
  advancedQuery: string = '';
  operators: any[];
  selectedSavedFilter: SavedFilterModel;

  selectedFilter;
  _glossary: DataDefGlossary;
  routeSub: Subscription;
  routeQuery;
  actionInProgress: boolean;
  saveFilterForm: UntypedFormGroup;
  queries: Array<any> = [];

  @Input() bulkActions: BulkActionDef<any>[];
  @Input() columns: DataDefModel[];
  @Input() defaultColKeys: Set<string>;
  @Input() itemSelection: ItemSelection<any>;
  @Input() glossary: DataDefGlossary;
  @Input() requiredQuery: string;
  @Input() searchValue: string;
  @Input() queryModel: QueryModel;
  @Input() savedQueries: SavedFilterModel[];
  @Input() savedQueryType: string;
  @Input() showSavedQueries: boolean;
  @Input() showAdvancedOptions: boolean = true;
  @Input() queryPrefixes: string[];
  @Input() showActions: boolean;
  @Input() showSearchBarActions: boolean = true;
  @Input() showCsvDownload: boolean = true;
  @Input() showColumnChooser: boolean = true;
  @Input() showFilter: boolean = true;
  @Input() showQuickSearch: boolean = true;
  @Input() headerActions: ActionDef[];
  @Input() quickFilterDefs: FilterBrickRowModel[];
  @Input() selectedQuickFilters: FiltersModel;
  @Input() dataTableKey: string;
  @Input() watchRouteQuery: boolean = false;

  @Output() downloadCsv = new EventEmitter<string>();
  @Output() columnsUpdated = new EventEmitter<string>();
  @Output() updateDisplayItems = new EventEmitter<string[]>();
  @Output() queryUpdated = new EventEmitter<FilterUpdate>();
  @Output() quickFilterSelected = new EventEmitter<FilterBrickRowOption>();
  @Output() searchValueUpdated = new EventEmitter<string>();
  @Output() clearQuickFilters = new EventEmitter();

  boltIcon = faBoltLightning;
  columnsIcon = faColumns;
  starIcon = fasStar;
  regularStarIcon = farStar;
  downloadIcon = faDownload;
  cogsIcon = faGear;
  infoIcon = faInfoCircle;
  trashIcon = faTrash;
  timesIcon = faTimes;
  warningIcon = faWarning;
  filterIcon = faBarsFilter;

  hasSelectedQuickFilter: boolean;

  popoverRef: PopoverRef;

  protected _bulkActionSource = new BehaviorSubject<BulkActionMenuItem[]>([]);
  bulkAction$ = this._bulkActionSource.asObservable();

  get bulkActionsDisabled() {
    return !(this.itemSelection?.selectedItems?.length > 0 || this.itemSelection?.selectedByQuery);
  }

  constructor(
    private savedFilterService: SavedFilterService,
    private notificationService: NotificationService,
    private dialogsService: DialogsService,
    private matDialog: MatDialog,
    private queryDisplayService: QueryDisplayService,
    private route: ActivatedRoute,
    private popover: PopoverService,
    private store: Store
  ) {
    this._setSaveFilterForm();
  }

  ngOnInit(): void {
    if (this.watchRouteQuery) {
      this.routeSub = this.route.queryParams.subscribe((params) => {
        if (params.query) {
          this.routeQuery = JSON.parse(params.query);
          this._applyRouteQuery();
        }
      });
    }

    this.getSavedFilters();

    const storedTableMetaData = this.dataTableKey
      ? this.store.selectSnapshot(DataTableSelectors.storedMetaData(this.dataTableKey))
      : null;

    // on init get any stored query model and search value to apply immediately
    // note: this works in concert with the data-table-config doing a similar check as it builds the query for the first time
    if (storedTableMetaData) {
      this.queries = storedTableMetaData.queryModel?.queries;
      this.searchValue = storedTableMetaData.queryModel?.searchValue || this.searchValue;
      this.advanced = !!storedTableMetaData.advancedQuery;
      this.advancedQuery = storedTableMetaData.advancedQuery;
    }
  }

  ngOnChanges({
    columns,
    selectedQuickFilters,
    itemSelection,
    bulkActions,
    searchValue,
    queryModel
  }: SimpleChanges): void {
    if (queryModel && !queryModel.isFirstChange()) {
      if (Array.isArray(queryModel.currentValue.queries) && queryModel.currentValue.queries.length) {
        this.setAndStoreQueries(
          queryModel.currentValue.queries.map((query) => {
            const returnQuery = this.columns.find((col) => col.key === query.key || col.queryKey === query.key);
            return { ...returnQuery, ...query };
          })
        );
      } else {
        this.setAndStoreQueries(null);
      }
    }
    if (columns) {
      const terms: DataDefTerms[] = [];
      const visibleKeys = new Set<string>();
      this.columns.forEach((col) => {
        if (col.hint) {
          terms.push({
            term: col.label,
            definition: col.hint
          });
        }
        if (col.visible) {
          visibleKeys.add(col.key);
        }
      });
      if (!this.defaultColKeys) {
        this.defaultColKeys = visibleKeys;
      }
      if (terms.length || this.glossary) {
        this._glossary = this.glossary ? { ...this.glossary } : {};
        if (this._glossary.terms && this._glossary.terms.length) {
          this._glossary.terms = [...this._glossary.terms, ...terms];
        } else {
          this._glossary.terms = terms;
        }
      }
    }
    if (selectedQuickFilters) {
      this.hasSelectedQuickFilter = this.selectedQuickFilters
        ? Object.keys(this.selectedQuickFilters).some(
            (key) => this.selectedQuickFilters[key]?.length && this.quickFilterDefs?.some((def) => def.rowName === key)
          )
        : false;
    }
    if (bulkActions && this.bulkActions) {
      this._bulkActionSource.next(
        this.bulkActions.map((bulkActionDef) => ({
          disabled: false,
          disabledMessage: null,
          bulkActionDef: bulkActionDef
        }))
      );
    }
    if (itemSelection && this.bulkActions?.length > 0) {
      this._bulkActionSource.next(
        this.bulkActions.map((bulkActionDef) => {
          const val = bulkActionDef.disableIf
            ? bulkActionDef.disableIf(this.itemSelection)
            : { disabled: false, disabledMessage: null };
          return { disabled: val.disabled, disabledMessage: val.disabledMessage, bulkActionDef: bulkActionDef };
        })
      );
    }
    if (searchValue && !searchValue.isFirstChange() && this.searchValue !== searchValue.currentValue) {
      this.onQuickSearch(searchValue.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.routeSub) this.routeSub.unsubscribe();
    if (this.popoverRef) {
      this.popoverRef.close();
    }
  }

  private _applyRouteQuery() {
    if (!this.columns || !this.savedQueries || !this.routeQuery) return;
    const matchingQuery = findMatchingQueries(this.savedQueries, this.routeQuery);
    if (matchingQuery) {
      this.selectFilter(matchingQuery);
    } else {
      this.queryUpdated.emit({
        queries: this.routeQuery,
        searchValue: this.searchValue
      });
    }
  }

  onQuickSearch(value) {
    // don't fire if going from one version of 'no value' to another (undefined, null, empty string)
    if (
      !(
        (this.searchValue === undefined || this.searchValue === null || this.searchValue === '') &&
        (value === undefined || value === null || value === '')
      )
    ) {
      this.searchValue = value;
      this.searchValueUpdated.emit(value);
      if (this.dataTableKey) {
        this.store.dispatch(new DataTable.SetQueryModel(this.dataTableKey, { searchValue: value }, this.advancedQuery));
      }
      this.buildQuery();
    }
  }

  getSavedFilters() {
    if (!this.savedQueryType) return;
    this.savedFilterService.get(this.savedQueryType).subscribe((filters) => {
      const processFilters = this.savedFilterService.processFilterQueries(filters, this.columns);
      this.savedQueries = processFilters.sort(sortByProperty('name', true));
      this._applyRouteQuery();
    });
  }

  exportAsCsv(target) {
    const dialogConfig = {
      label: 'Download CSV File',
      definition: new DataDefModel({
        key: 'name',
        label: 'name',
        type: DATA_TYPES.text,
        validators: {
          required: true
        }
      }),
      actionText: 'Download',
      value: 'results.csv'
    };
    this.openPopoverAfterCloseObs(this.popover.openInput(target, dialogConfig)).subscribe((result) => {
      if (!!result) {
        this.downloadCsv.emit(result.value);
      }
    });
  }

  closeQueryMenu() {
    this.selectedFilter = null; // resets selected query because it persists after the user is done with the query
  }

  removeQuery(removedQuery): void {
    this.clearSelectedSavedFilter();
    this.setAndStoreQueries(this.queries.filter((query) => query !== removedQuery));
    this.buildQuery();
  }

  setQueries(queries: any[]): void {
    this.setAndStoreQueries(queries);
    this.buildQuery();
  }

  clearQueries(): void {
    this.clearSelectedSavedFilter();
    this.advancedQuery = '';
    this.setAndStoreQueries([]);
    this.buildQuery();
  }

  submitAdvancedQuery(query: string): void {
    this.advancedQuery = query;
    this.setAndStoreQueries(null);
    this.buildQuery();
  }

  toggleAdvanced() {
    if (!this.advanced) {
      this.clearSelectedSavedFilter();
      if (this.advancedQuery) {
        this.queryUpdated.emit({
          queries: this.advancedQuery,
          searchValue: this.searchValue
        });
      }
    } else {
      this.clearQueries();
      this.setAndStoreQueries([]);
    }
    this.advanced = !this.advanced;
  }

  showQuery(target): void {
    if (this.queries && this.queries.length > 0) {
      const value = this.queries.map((b) => parseToQuery(b)).join(' and ');
      this.queryDisplayService.openPopover(target, [
        {
          label: 'Current Query',
          query: value
        }
      ]);
    } else {
      this.queryDisplayService.openPopover(target, [
        {
          label: 'No query present',
          query: ''
        }
      ]);
    }
  }

  clearSelectedSavedFilter(): void {
    this.selectedSavedFilter = null;
  }

  openSaveFilter(target: ElementRef, template: TemplateRef<any>): void {
    if (!this.advanced && (!this.queries || (this.queries && this.queries.length === 0))) {
      this.notificationService.warningNotification('You need to have a query in order to save it');
      return;
    }

    if (this.advanced && this.advancedQuery === '') {
      this.notificationService.warningNotification('You need to have a query in order to save it');
      return;
    }

    const newSavedFilter: SavedFilterModel = {
      favorite: true,
      savedFilterType: this.savedQueryType as SavedFilterType,
      advanced: this.advanced,
      query: this.advanced ? this.advancedQuery : this.queries
    };
    this.openPopoverAfterCloseObs(this.popover.open(template, target)).subscribe((result) => {
      if (!result) return;
      newSavedFilter.name = result.name;
      newSavedFilter.favorite = result.favorite;
      if (!this.savedQueryType) {
        throw Error('Data table header must have a defined savedQueryType.');
      }

      this.savedFilterService.create(newSavedFilter).subscribe((createdFilter) => {
        if (!createdFilter.advanced) {
          createdFilter.query = JSON.parse(createdFilter.query as string);
        }

        this.savedQueries = [...this.savedQueries, createdFilter];
        this.notificationService.successfulNotification(`${createdFilter.name} successfully saved`);
        this.selectedSavedFilter = createdFilter;
        this._setSaveFilterForm();
      });
    });
  }

  favoriteFilter(filter: SavedFilterModel): void {
    const newFilter = {
      ...filter,
      favorite: !filter.favorite
    };
    this.savedFilterService.update(filter.id, newFilter).subscribe((res) => {
      res = { ...res, query: res.advanced ? res.query : JSON.parse(res.query as string) };
      this.savedQueries = this.savedQueries.map((f) => (f === filter ? res : f));
    });
  }

  selectFilter(filter: SavedFilterModel): void {
    if (this.selectedSavedFilter === filter) {
      this.advancedQuery = '';
      this.advanced = false;
      this.clearQueries();
    } else {
      this.selectedSavedFilter = filter;
      this.advanced = filter.advanced;

      if (filter.advanced) {
        this.submitAdvancedQuery(filter.query as string);
      } else {
        this.setQueries(filter.query as RmtQlNodeModel[]);
      }
      this.queryUpdated.emit({
        queries: filter.query,
        searchValue: this.searchValue
      });
    }
  }

  removeFilter(filter: SavedFilterModel): void {
    this.dialogsService
      .confirmationDialog({
        label: 'Delete Saved Filter',
        message: `Are you sure you want to delete <b>${filter.name}</b>?`,
        actionText: 'Delete'
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.savedFilterService.delete(filter).subscribe(() => {
            this.savedQueries = this.savedQueries.filter((f) => f.id !== filter.id);
            this.notificationService.successfulNotification(`${filter.name} successfully deleted`);
          });
        }
      });
  }

  buildQuery() {
    this.queryUpdated.emit({
      queries: this.advanced ? this.advancedQuery : this.queries,
      searchValue: this.searchValue
    });
  }

  applyQuery(query) {
    this.advanced = false;
    this.advancedQuery = '';
    this.clearSelectedSavedFilter();
    const returnQuery = this.columns.find((col) => col.key === query.key || col.queryKey === query.key);
    const combinedQuery = { ...returnQuery, ...query };

    if (this.selectedFilter) {
      this.setAndStoreQueries([...this.queries.map((q) => (q === this.selectedFilter ? combinedQuery : q))]);
      this.selectedFilter = null;
    } else {
      this.setAndStoreQueries(this.queries ? this.queries : []);
      this.setAndStoreQueries([...this.queries, combinedQuery]);
    }
    this.closeQueryMenu();
    this.buildQuery();
  }

  viewGlossary() {
    this.matDialog.open(GlossaryDialogComponent, {
      data: this._glossary,
      width: '650px'
    });
  }

  runCallback(action: ActionDef | BulkActionDef<any>, $event: MouseEvent) {
    const callbackResult = action.callback($event);
    if (!!callbackResult?.subscribe) {
      this.actionInProgress = true;
      callbackResult.pipe(finalize(() => (this.actionInProgress = false))).subscribe();
    }
  }

  openFilterMenu(target: any, selectedFilter?) {
    this.selectedFilter = selectedFilter;
    this.openPopoverAfterCloseObs(
      this.popover.open(QueryInputMenuComponent, target, {
        data: {
          definitions: this.columns,
          queryPrefixes: this.queryPrefixes,
          selectedFilter: this.selectedFilter
        }
      })
    ).subscribe((res) => {
      this.popoverRef = null;
      if (!res) return;
      this.applyQuery(res);
    });
  }

  openDisplayColumns(target) {
    this.openPopoverAfterCloseObs(
      this.popover.open(DisplayColumnsMenuComponent, target, {
        data: {
          definitions: this.columns.filter((column) => !column.noView),
          columnsUpdated: this.columnsUpdated,
          defaultColKeys: this.defaultColKeys
        }
      })
    ).subscribe();
  }

  openSavedFilters(target: ElementRef, savedFiltersMenu: TemplateRef<any>) {
    this.openPopoverAfterCloseObs(this.popover.open(savedFiltersMenu, target)).subscribe();
  }

  openQuickFilters(target: ElementRef, template) {
    this.openPopoverAfterCloseObs(this.popover.open(template, target)).subscribe();
  }

  private _setSaveFilterForm() {
    this.saveFilterForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        (control: AbstractControl) => {
          if (this.savedQueries?.some((sq) => sq.name.toLowerCase() === control?.value.toLowerCase())) {
            control.markAsTouched();
            return { uniqueName: 'Name must be unique.' };
          }
          return null;
        }
      ]),
      favorite: new UntypedFormControl(false)
    });
  }

  private openPopoverAfterCloseObs<T, R>(pref: PopoverRef<T, R>): Observable<R> {
    if (this.popoverRef) {
      this.popoverRef.close();
    }
    this.popoverRef = pref;
    return pref.afterClosed().pipe(tap((_) => (this.popoverRef = null)));
  }

  private setAndStoreQueries(queries: any[]) {
    if (JSON.stringify(this.queries) !== JSON.stringify(queries) || this.advanced) {
      this.queries = queries;
      if (this.dataTableKey) {
        this.store.dispatch(new DataTable.SetQueryModel(this.dataTableKey, { queries: queries }, this.advancedQuery));
      }
    }
  }

  submitSaveFilter(ref: any, saveFilterForm: UntypedFormGroup): void {
    if (saveFilterForm.invalid) {
      saveFilterForm.markAllAsTouched();
      return;
    }
    ref.close(saveFilterForm.value);
  }
}
