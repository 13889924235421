import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import {
  enrollmentDisabler,
  enrollmentTierToEnabledFields,
  quotedTotalEnrollmentComposite,
  quotedTotalEnrollmentNoComposite,
  quoteResponseFields,
  quoteResponseTypeQuestionDef,
  requestedTotalEnrollmentComposite,
  requestedTotalEnrollmentNoComposite,
  specEnrollmentCEDef
} from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import { DataDefModel } from '@lib-resource/data-def.model';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import { FormValidators } from '@form-lib/validators/form.validators';
import { set } from 'lodash-es';

@Component({
  selector: 'app-quote-response-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['../quote-response-stepper.component.scss']
})
export class GeneralInformationComponent implements OnChanges {
  @Input() quoteResponse: QuoteResponseModel;
  @Input() ocrData: OcrModel[];
  @Input() importData;
  @Input() touched: boolean;
  @Input() readOnly: boolean;
  @Output() update = new EventEmitter<{
    quoteResponse: QuoteResponseModel;
    valid: boolean;
  }>();

  @Output() updateValidity = new EventEmitter<boolean>();

  defs = [
    ...quoteResponseFields.map((def) => {
      // replace type field with the type field as a question
      if (def.key === 'type') {
        return { ...quoteResponseTypeQuestionDef };
      }

      return def;
    }),
    specEnrollmentCEDef,
    requestedTotalEnrollmentComposite,
    requestedTotalEnrollmentNoComposite,
    quotedTotalEnrollmentNoComposite,
    quotedTotalEnrollmentComposite
  ];

  typeDataDef = this.getDataDef('type');
  proposalDateDataDef = this.getDataDef('proposalDate');
  effectiveDateDataDef = this.getDataDef('effectiveDate');
  validThroughDateDataDef = this.getDataDef('validThroughDate');
  expirationDate = this.getDataDef('expirationDate');
  lastUpdatedDate = this.getDataDef('lastModifiedDate');
  requestEnrollmentTier = this.getDataDef('quoteRequest.enrollmentTier');
  quoteEnrollmentTier = this.getDataDef('enrollmentTier');
  requestEmpOnly = this.getDataDef('quoteRequest.enrollmentEmployeeOnly');
  quoteEmpOnly = this.getDataDef('specEnrollmentEE');
  requestEmpSpouse = this.getDataDef('quoteRequest.enrollmentEmployeeSpouse');
  quoteEmpSpouse = this.getDataDef('specEnrollmentES');
  requestEmpChild = this.getDataDef('quoteRequest.enrollmentEmployeeChildren');
  quoteEmpChild = this.getDataDef('specEnrollmentEC');
  requestEmpDep = this.getDataDef('quoteRequest.enrollmentEmployeeDependent');
  quoteEmpDep = this.getDataDef('specEnrollmentED');
  requestFamily = this.getDataDef('quoteRequest.enrollmentFamily');
  quoteFamily = this.getDataDef('specEnrollmentEF');
  requestComposite = this.getDataDef('quoteRequest.enrollmentComposite');
  quoteComposite = this.getDataDef('specEnrollmentCE');
  requestedTotalEnrollmentNoComposite = requestedTotalEnrollmentNoComposite;
  requestedTotalEnrollmentComposite = requestedTotalEnrollmentComposite;
  quotedTotalEnrollmentComposite = quotedTotalEnrollmentComposite;
  quotedTotalEnrollmentNoComposite = quotedTotalEnrollmentNoComposite;

  formValidators: FormValidators[] = [enrollmentDisabler];

  @ViewChild('generalInformationForm') generalInformationForm: BaseFormComponent;

  localFormData: QuoteResponseModel;
  localUpdateOnly: boolean = false;
  constructor() {}

  ngOnChanges({ quoteResponse, importData, ocrData, touched }: SimpleChanges): void {
    // this watches for the stepper telling us that the step has been touched
    // especially important when the user starts by clicking a different step without interacting with this form
    // by touching all the fields, it fires validations such that going back to the form shows all the red error messages
    if (!!touched && !touched.isFirstChange() && touched.currentValue) {
      setTimeout(() => {
        this.generalInformationForm.form.markAllAsTouched();
        this.generalInformationForm.form.updateValueAndValidity();
        this.formChanges(this.quoteResponse);
      });
    }

    if (!this.localFormData && quoteResponse?.currentValue) {
      // first time in needs to update local form data which doesn't fire formChanges
      // so don't set local only flag that would cause this form to skip emitting a real update
      this.localFormData = JSON.parse(JSON.stringify(quoteResponse.currentValue));
    } else if (importData || ocrData) {
      // if importData/ocrData comes in, it isn't setting new local form data (the import data sits separately in the app-form)
      // but will cause a formChanges event when applied so mark it local only to not emit it back out
      this.localUpdateOnly = true;
    }
  }

  formChanges(formData: QuoteResponseModel) {
    // We need the read only quoted enrollment fields to show up so that they overwrite what is in the quote response
    enrollmentTierToEnabledFields.get(formData.enrollmentTier)?.disabled?.forEach((key) => {
      set(formData, key, null);
    });
    this.emitUpdate(formData);
  }

  getFormControl(key: string) {
    return this.generalInformationForm.form?.controls[key];
  }

  private emitUpdate(quoteResponse: QuoteResponseModel) {
    if (!this.localUpdateOnly) {
      this.update.emit({
        quoteResponse: quoteResponse,
        valid: !this.generalInformationForm.invalid
      });
    } else {
      this.updateValidity.emit(!this.generalInformationForm.form.invalid);
    }
    this.localUpdateOnly = false;
  }

  private getDataDef(key: string): DataDefModel {
    return this.defs.find((def) => def.key === key);
  }
}
