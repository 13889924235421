import { DataSource } from '@angular/cdk/collections';
import { DataDefModel } from '@lib-resource/data-def.model';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ActionDef } from '../models/data-table.model';

class DataSourceModel {
  columns;
  data;
  actions;
}

/**
 * Data source for the view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class TableDataSource extends DataSource<any> {
  displayedColumns: string[];
  data: BehaviorSubject<any>;
  columns: BehaviorSubject<DataDefModel[]>;
  actionCol: BehaviorSubject<any>;
  private hasRollupSource = new BehaviorSubject<boolean>(false);
  hasRollup$ = this.hasRollupSource.asObservable().pipe(distinctUntilChanged());

  constructor({ data, columns, actions }: DataSourceModel) {
    super();
    this.actionCol = actions;
    this.columns = columns;
    this.data = data;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [this.data.asObservable(), this.columns.asObservable(), this.actionCol.asObservable()];
    return merge(...dataMutations).pipe(
      map(() => {
        const columns = !!this.columns.value ? this.columns.value : [];
        if (columns.length) {
          this.setDisplayColumns(columns, this.actionCol.value);
        }
        return !!this.data.value ? this.data.value : [];
      })
    );
  }

  disconnect() {}

  setDisplayColumns(columns: DataDefModel[], actions: ActionDef[]) {
    this.displayedColumns = [];
    let hasRollups = false;
    columns
      .filter((def) => def.visible)
      .forEach((def) => {
        this.displayedColumns.push(def.key);
        if (def.rollupFn) {
          hasRollups = true;
        }
      });
    if (actions && actions.length) {
      this.displayedColumns.push('actions');
    }
    this.hasRollupSource.next(hasRollups);
  }
}

export class ExpansionDataSource extends DataSource<any> {
  constructor(private data) {
    super();
  }

  connect(): Observable<any[]> {
    return of(this.data);
  }

  disconnect() {}
}
