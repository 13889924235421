<app-dialog-close icon mat-dialog-close></app-dialog-close>

<app-dialog-label>
  Upload PDF
  @if (data.quoteLinqVersion) {
    | Version {{ data.quoteLinqVersion.versionId }}
  }
</app-dialog-label>
<app-dialog-content>
  <div class="rmt-card">
    <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
    A PDF of your proposal is required to continue.
    <app-file-upload labelText="Add PDF File" [multi]="false" (selectFile)="onSelectFile($event)"></app-file-upload>
    <div class="pt-3">
      <form [formGroup]="form" class="collapsible-form dense pdf-form">
        <mat-form-field>
          <mat-label>{{
            form.controls.fileId.value < 0 ? 'Uploading file' : 'Select from supporting files'
          }}</mat-label>
          <mat-select formControlName="fileId">
            <mat-option> -- </mat-option>
            <mat-option *ngFor="let item of supportFiles$ | async" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
</app-dialog-content>
<app-dialog-actions>
  <div class="grid-content">
    <button (click)="submit()" [disabled]="form.invalid" mat-raised-button color="primary">Upload</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </div>
</app-dialog-actions>
