import { Injectable } from '@angular/core';
import { ProductRole } from '@auth/models/user.model';
import { TitleService } from '@common/services/title.service';
import { faCogs } from '@fortawesome/pro-solid-svg-icons';
import { NavConfig, NavItem, NavLocation, NavState, ProductDefModel } from '@main/nav.models';
import { OrgMembershipSelectors } from '@main/store/org-membership/org-membership.selectors';
import { OrgProduct } from '@main/store/product/product.model';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

const quoteLinqProductDef: ProductDefModel = {
  label: 'Quote-LinQ',
  abbreviation: 'QL',
  key: OrgProduct.QUOTE,
  image: '../../../assets/branding/quote-linq-inline-white.svg?v=1',
  colorImage: '../../../assets/branding/quote-linq-inline-colored.svg?v=1',
  link: '/quote',
  showSiteFilter: true,
  showTrademark: true
};

const quoteLinqRxProductDef: ProductDefModel = {
  label: 'Ringmaster Rx',
  abbreviation: 'QL-RX',
  key: OrgProduct.QUOTE_RX,
  image: '../../../assets/branding/quote-linq-rx-inline-white.svg?v=2',
  colorImage: '../../../assets/branding/quote-linq-rx-inline-primary-colors.svg?v=2',
  link: '/quote-rx',
  showSiteFilter: true,
  width: '135px'
};

const smartLinqProductDef: ProductDefModel = {
  label: 'Smart-LinQ',
  abbreviation: 'SL',
  key: OrgProduct.SMART,
  image: '../../../assets/branding/smart-linq-inline-white.svg?v=1',
  colorImage: '../../../assets/branding/smart-linq-inline-colored.svg?v=1',
  link: '/smart',
  showSiteFilter: true,
  showTrademark: true
};

const systemProductDef: ProductDefModel = {
  label: 'Administration',
  abbreviation: 'RM',
  key: OrgProduct.RINGMASTER,
  image: '../../../assets/branding/admin-inline-white.svg?v=1',
  colorImage: '../../../assets/branding/admin-inline-colored.svg?v=1',
  icon: faCogs,
  link: '/configuration',
  showSiteFilter: false,
  width: '155px',
  showTrademark: false
};

const allProducts = [quoteLinqProductDef, quoteLinqRxProductDef, smartLinqProductDef, systemProductDef];

@Injectable({
  providedIn: 'root'
})
export class MainService {
  // This allows combineLatest to fire even when no nav observable exists for a location
  private readonly _dummySubject = new BehaviorSubject([]);

  private _navStreamRegistry: NavConfig[] = [];
  _navStreams: NavState = {
    [NavLocation.SUB_NAV]: new BehaviorSubject(null),
    [NavLocation.NAV]: new BehaviorSubject([]),
    [NavLocation.QUICK_NAV]: new BehaviorSubject([])
  };

  _navSubs: { [key in NavLocation]?: Subscription } = {};

  // PRODUCT
  productPathChanges$ = this.store.select(ProductSelectors.selectedProduct).pipe(distinctUntilChanged());
  selectedProduct$ = this.productPathChanges$.pipe(
    map((productKey) => allProducts.find((product) => product.key === productKey)),
    tap((selectedProduct) => this.titleService.setTitle(selectedProduct ? selectedProduct.label : 'Administration'))
  );

  productsAvailable$ = this.store.select(OrgMembershipSelectors.availableProducts).pipe(
    map((productsAvailable) => {
      const products = [systemProductDef];
      if (productsAvailable.includes(OrgProduct.SMART)) {
        products.unshift(smartLinqProductDef);
      }
      if (productsAvailable.includes(OrgProduct.QUOTE_RX)) {
        products.unshift(quoteLinqRxProductDef);
      }
      if (productsAvailable.includes(OrgProduct.QUOTE)) {
        products.unshift(quoteLinqProductDef);
      }
      return products;
    })
  );

  // NAV
  navItems$ = this._navStreams[NavLocation.NAV].asObservable();

  // QUICK_NAV
  quickNavItems$ = this._navStreams[NavLocation.QUICK_NAV].asObservable();
  // SUB_NAV
  subNavItems$ = this._navStreams[NavLocation.SUB_NAV].asObservable();

  constructor(private store: Store, private titleService: TitleService) {}

  _updateStreams(location?: NavLocation) {
    const items = this._navStreamRegistry.filter((item) => item.location === location).map(({ stream }) => stream);
    if (this._navSubs[location]) {
      this._navSubs[location].unsubscribe();
    }
    this._navSubs[location] = combineLatest([...items, this._dummySubject])
      .pipe(map((values) => [].concat.apply([], values)))
      .subscribe(this._navStreams[location]);
  }

  registerNavStream(key: string, location: NavLocation, stream: Observable<NavItem[]>) {
    this._navStreamRegistry = this._navStreamRegistry.filter((s) => key !== s.key);
    this._navStreamRegistry.push({
      key,
      location,
      stream
    });
    this._updateStreams(location);
  }

  deregisterNavStream(key: string) {
    const registeredStreams = this._navStreamRegistry.filter((stream) => key === stream.key);
    if (registeredStreams?.length) {
      this._navStreamRegistry = this._navStreamRegistry.filter((stream) => key !== stream.key);
      registeredStreams.forEach((stream) => this._updateStreams(stream.location));
    }
  }

  getDefaultPath(): string {
    // order of precedence for default url: sysadmin, quote, quote-rx, smart, configuration/org, configuration/profile
    if (this.store.selectSnapshot(UserSelectors.isSysAdmin)) {
      return '/configuration/admin'; // if a sysadmin, send them to the audit log (the default route in admin)
    }

    const qlRole = this.store.selectSnapshot(
      SiteFilterSelectors.singleSelectedOrgMembershipProductRole(OrgProduct.QUOTE)
    );
    if (qlRole !== ProductRole.NONE) {
      return '/quote';
    }
    const qlRxRole = this.store.selectSnapshot(
      SiteFilterSelectors.singleSelectedOrgMembershipProductRole(OrgProduct.QUOTE_RX)
    );
    if (qlRxRole !== ProductRole.NONE) {
      return '/quote-rx';
    }
    const slRole = this.store.selectSnapshot(
      SiteFilterSelectors.singleSelectedOrgMembershipProductRole(OrgProduct.SMART)
    );
    if (slRole !== ProductRole.NONE) {
      return '/smart';
    }

    const orgMems = this.store.selectSnapshot(OrgMembershipSelectors.orgMemberships);
    if (orgMems?.length) {
      return '/configuration/org'; // if the app state is setup with an org, the drop them into configuration/org
    }

    return '/configuration/profile';
  }
}
