import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { faInfoCircle, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DataDefModel } from '@lib-resource/data-def.model';
import {
  effectiveDateDataDef,
  quotedTotalEnrollmentComposite,
  quotedTotalEnrollmentNoComposite,
  quoteResponseLaserConfirmationDef,
  quoteResponseTypeQuestionDef,
  responseOptionsDef,
  specEnrollmentCEDef,
  specEnrollmentECDataDef,
  specEnrollmentEDDataDef,
  specEnrollmentEEDataDef,
  specEnrollmentEFDataDef,
  specEnrollmentESDataDef
} from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import {
  QuoteResponseLaserConfirmationType,
  QuoteResponseModel,
  QuoteResponseOption,
  QuoteResponseStatus,
  QuoteResponseType
} from '@common/models/quote-response.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { OnlyWhenValid, SubmitModel } from '@form-lib/models/submit.model';
import { quoteResponseSubmitDataDefs } from '@quote/quote-response/quote-response-submit/quote-response-submit.definitions';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { defaultIfEmpty, finalize } from 'rxjs/operators';
import { FileDocType, FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { QuoteLinqVersionModel } from '@common/models/quote-linq-version.model';
import { QuoteLinqVersionService } from '@common/services/quote-linq-version.service';
import { QuoteResponseEventType } from '@common/models/quote-response-event.model';
import { forkJoin } from 'rxjs';
import { ContingencyModel } from '@quote/quote-response/quote-response-stepper/contingencies/contingency.model';

@Component({
  templateUrl: './quote-response-submit.component.html',
  styleUrls: ['./quote-response-submit.component.scss']
})
export class QuoteResponseSubmitComponent implements AfterViewInit {
  infoIcon: IconDefinition = faInfoCircle;
  errorIcon: IconDefinition = faWarning;
  @ViewChild('quoteResponseSubmitForm') quoteResponseSubmitForm: BaseFormComponent;
  definitions: DataDefModel[];

  typeDef: DataDefModel = quoteResponseTypeQuestionDef;
  effectiveDateDef: DataDefModel = effectiveDateDataDef;
  laserConfirmationDef: DataDefModel = quoteResponseLaserConfirmationDef;
  estTotalEEsDef: DataDefModel;
  optionsDef: DataDefModel = quoteResponseSubmitDataDefs;
  readOnly: boolean;
  saving: boolean;

  savedProposalFile: FileModel;

  constructor(
    private quoteResponseService: QuoteResponseService,
    private quoteLinqVersionService: QuoteLinqVersionService,
    private dialogRef: MatDialogRef<QuoteResponseSubmitComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      quoteResponse: QuoteResponseModel;
      contingencies: ContingencyModel[];
      filesToSave: FileExtModel[];
      version: QuoteLinqVersionModel;
      importEvent: QuoteResponseEventType;
    }
  ) {
    this.estTotalEEsDef = !!data.quoteResponse.specEnrollmentCE
      ? {
          ...quotedTotalEnrollmentComposite,
          label: 'Est. Total EEs',
          readOnly: true
        }
      : {
          ...quotedTotalEnrollmentNoComposite,
          label: 'Est. Total EEs',
          readOnly: true
        };

    this.definitions = [
      this.typeDef,
      this.effectiveDateDef,
      this.laserConfirmationDef,
      this.estTotalEEsDef,
      {
        ...specEnrollmentEEDataDef,
        readOnly: true
      },
      {
        ...specEnrollmentESDataDef,
        readOnly: true
      },
      {
        ...specEnrollmentECDataDef,
        readOnly: true
      },
      {
        ...specEnrollmentEDDataDef,
        readOnly: true
      },
      {
        ...specEnrollmentEFDataDef,
        readOnly: true
      },
      {
        ...specEnrollmentCEDef,
        readOnly: true
      },
      responseOptionsDef
    ];
  }

  ngAfterViewInit() {
    const hasLasers = this.data.quoteResponse.quoteResponseOptions?.some((option) => option.lasers?.length);

    if (hasLasers) {
      this.data.quoteResponse.laserConfirmation = QuoteResponseLaserConfirmationType.CONFIRMED_LASERS_INCLUDED;
    } else if (
      this.data.quoteResponse.laserConfirmation === QuoteResponseLaserConfirmationType.CONFIRMED_LASERS_INCLUDED
    ) {
      this.data.quoteResponse.laserConfirmation = null;
    }

    // if the quote response was illustrative, clear the status field so the user has to choose it again to verify
    if (this.data.quoteResponse.type === QuoteResponseType.ILLUSTRATIVE) {
      this.data.quoteResponse.type = null;
    }

    if (!this.data.quoteResponse.quoteResponseOptions || this.data.quoteResponse.quoteResponseOptions?.length === 0) {
      this.data.quoteResponse.quoteResponseOptions = [new QuoteResponseOption()];
    }

    setTimeout(() => {
      // get the required fields to show immediately but not too immediately; wait for the next cycle so the form exists
      this.quoteResponseSubmitForm.form?.markAllAsTouched();
    });
  }

  @OnlyWhenValid
  submit(event: SubmitModel) {
    this.saving = true;
    // overlay the data from the submit modal on top of the original response, need to use the rawValue since
    // the form doesn't display all the fields from the quoteResponse and therefore would set them to null/undefined
    const newQuoteResponseOptions = this.data.quoteResponse.quoteResponseOptions.map((originalOption, index) => ({
      ...originalOption,
      ...event.rawValue.quoteResponseOptions[index]
    }));
    const newQuoteResponse = {
      ...this.data.quoteResponse,
      ...event.rawValue,
      quoteResponseOptions: newQuoteResponseOptions
    };

    // if this response was in NEW, UNDERWRITING, or WON, update the status to SUBMITTED as well
    if (
      this.data.quoteResponse.status === QuoteResponseStatus.NEW ||
      this.data.quoteResponse.status === QuoteResponseStatus.UNDERWRITING ||
      this.data.quoteResponse.status === QuoteResponseStatus.WON
    ) {
      newQuoteResponse.status = QuoteResponseStatus.SUBMITTED;
    }

    const uploadSupportingFileCalls = [];
    this.data.filesToSave?.forEach((fileToSave) => {
      uploadSupportingFileCalls.push(
        this.quoteResponseService.uploadSupportFiles(
          fileToSave.file,
          this.data.quoteResponse.id,
          fileToSave.fileModel.docType,
          false
        )
      );
    });

    forkJoin(uploadSupportingFileCalls)
      .pipe(
        defaultIfEmpty(null) // ensures the forkJoin gets to the subscribe if there weren't any files to upload
      )
      .subscribe((savedFiles: FileModel[]) => {
        this.savedProposalFile =
          savedFiles && !this.savedProposalFile ? savedFiles.find((f) => f.docType === FileDocType.PROPOSAL) : null;
        if (this.data.filesToSave) {
          this.data.filesToSave.length = 0;
        }

        if (this.data.version?.id > 0) {
          // update
          this.quoteLinqVersionService
            .update(
              this.data.version.versionId,
              newQuoteResponse,
              this.data.contingencies,
              this.savedProposalFile?.id || this.data.version?.rmtFile?.id,

              false,
              QuoteResponseEventType.RESPONSE_VERSION_UPDATED_MANUAL
            )
            .subscribe(
              (result) => {
                this.dialogRef.close(result);
              },
              () => {
                this.saving = false;
              }
            );
        } else {
          // create
          this.quoteLinqVersionService
            .create(
              newQuoteResponse,
              this.data.contingencies,
              this.savedProposalFile?.id || this.data.version?.rmtFile?.id,
              false,
              this.data.importEvent
            )
            .pipe(
              finalize(() => {
                this.saving = false;
              })
            )
            .subscribe((result) => {
              this.dialogRef.close(result);
            });
        }
      });
  }

  getFormControl(key: string): any {
    return this.quoteResponseSubmitForm.form?.controls[key];
  }
}
