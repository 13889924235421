import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into bytes, KB, or MB.
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 */
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    if (bytes == null) return null;
    if (bytes < 1024) {
      return `${bytes} B`;
    }
    if (bytes < 1048576) {
      // less than 1 MB
      return this.bytesToKB(bytes) + ' KB';
    }
    return this.bytesToMB(bytes) + ' MB';
  }

  bytesToKB(bytes: number): number {
    const kb = bytes / 1024;
    return kb % 1 === 0 ? kb : +kb.toFixed(+2);
  }

  bytesToMB(bytes: number): number {
    const mb = bytes / 1024 / 1025;
    return mb % 1 === 0 ? mb : +mb.toFixed(+2);
  }
}
