<app-dialog-close icon mat-dialog-close></app-dialog-close>

<app-dialog-label>
  {{ dialogTitle }}
  <span class="dialog-message">
    <button
      (click)="closeAndOpenManifestDialog()"
      *ngIf="includeDownloadQuoteLink"
      class="btn-reset btn-anchor ml-3 download-quote-link"
    >
      Download All
    </button>
  </span>
</app-dialog-label>

<app-dialog-content>
  <ng-container *ngIf="supportFiles$ | async as supportFiles">
    <app-card>
      <app-data-table
        *ngIf="supportFiles.length"
        [inlineActions]="true"
        [paginated]="false"
        [displayCols]="displayedFileColumns"
        [columns]="fileColumns"
        [data]="supportFiles"
        [actions]="fileActions"
      >
      </app-data-table>
    </app-card>
  </ng-container>

  <app-card>
    <app-multi-file-upload
      [requireDocType]="true"
      [uploadUrl]="getSupportFileUploadUrl"
      [docTypes]="docTypes"
      (fileUploaded)="supportFileAdded()"
    ></app-multi-file-upload>
  </app-card>
</app-dialog-content>

<app-dialog-actions>
  <button mat-raised-button mat-dialog-close>Close</button>
</app-dialog-actions>
