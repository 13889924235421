import { Component, Input } from '@angular/core';
import { ColumnHeader } from '@common/models/csv-import.model';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-csv-error',
  template: `
    <div class="alert-info-container">
      <span><fa-icon [ngClass]="'color-warn'" [icon]="infoCircleIcon"></fa-icon><strong> Alert: </strong></span>
      <div>
        {{ message }}
      </div>
      <div></div>
      <ul class="invalid-cols" *ngIf="invalidColumns">
        <li *ngFor="let col of invalidColumns">
          {{ col.displayName }}
        </li>
      </ul>
      <div></div>
      <div *ngIf="invalidRows">
        Row index(s) in error:
        <ng-container *ngFor="let row of invalidRows; let i = index">
          <ng-container *ngIf="i === invalidRows.length - 1 && invalidRows.length > 1"> and</ng-container>
          {{ row }}<ng-container *ngIf="i < invalidRows.length - 1 && invalidRows.length > 2">,</ng-container>
        </ng-container>
        <ng-container *ngIf="invalidRows.length >= 5">
          (A maximum of 5 rows are specified, there might be more)</ng-container
        >
      </div>
    </div>
  `,
  styles: [
    `
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        color: red;
      }

      .alert-info-container {
        display: grid;
        align-items: center;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
        grid-template-columns: auto 1fr;
        font-size: 0.875rem;
      }
    `
  ]
})
export class CsvErrorComponent {
  @Input() invalidColumns: ColumnHeader[];
  @Input() invalidRows: number[];
  @Input() message: string;
  infoCircleIcon = faInfoCircle;
}
