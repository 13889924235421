import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LabelValue } from '@lib-resource/label-value.model';

export interface FileUploadWithOptionsDialogData {
  title?: string;
  description?: string;
  multiple?: boolean;
  options?: LabelValue[];
}

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>
      <ng-container *ngIf="data?.title">{{ data.title }}</ng-container>
      <ng-container *ngIf="!data?.title">Upload files</ng-container>
    </app-dialog-label>
    <app-dialog-content>
      <app-file-upload [multi]="data.multiple" (selectFile)="fileSelected($event)"></app-file-upload>
      <app-file-list-concise [files]="files" (removeFile)="removeFile($event)"></app-file-list-concise>
      <div class="grid-content">
        <mat-form-field appearance="outline">
          <mat-label>Doc Type</mat-label>
          <mat-select [formControl]="docType">
            <mat-option *ngFor="let option of data.options" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput [formControl]="description" type="text" />
        </mat-form-field>
      </div>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button color="primary" (click)="submit()" [disabled]="!files.length || docType.invalid">
        Upload
      </button>
      <button mat-raised-button mat-dialog-close>Cancel</button>
    </app-dialog-actions>
  `
})
export class FileUploadWithOptionsDialogComponent {
  files: File[] = [];
  docType = new UntypedFormControl(null, [Validators.required]);
  description = new UntypedFormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileUploadWithOptionsDialogData,
    private dialogRef: MatDialogRef<FileUploadWithOptionsDialogComponent>
  ) {
    if (!data.options || !data.options.length) {
      console.warn('No options where provided to FileUploadWithOptionsDialogComponent.');
    }
  }

  fileSelected(file) {
    if (!this.data.multiple) {
      this.files = [file];
    } else {
      this.files.push(...file);
    }
  }

  removeFile(removedFile) {
    this.files = this.files.filter((file) => removedFile !== file);
  }

  submit() {
    this.dialogRef.close({ files: this.files, docType: this.docType.value, description: this.description.value });
  }
}
