import { Component, Input } from '@angular/core';
import { DisplayCardKpiDefModel } from '@app/libraries/workspace-lib/components/display-card-kpi/display-card-kpi-def.model';

@Component({
  selector: 'app-display-card-kpi',
  templateUrl: './display-card-kpi.component.html',
  styleUrls: ['./display-card-kpi.component.scss']
})
export class DisplayCardKpiComponent {
  @Input() kpi: DisplayCardKpiDefModel;
  @Input() isSelected: boolean = false;
  colorClass = 'bg-accent';
}
