import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

export const columnDefs: DataDefModel[] = [
  new DataDefModel({ label: 'Id', key: 'id', type: DATA_TYPES.number }),
  new DataDefModel({ label: 'Name', key: 'name', type: DATA_TYPES.text }),
  new DataDefModel({ label: 'Description', key: 'description', type: DATA_TYPES.text })
];

export const defaultCols = ['id', 'name', 'description'];

export const defaultSort = ['name'];
