import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { OrgProduct } from '@main/store/product/product.model';
import { BehaviorSubject, Observable } from 'rxjs';

export class ProductDefModel {
  label: string;
  abbreviation: string;
  key: OrgProduct;
  image: string;
  colorImage: string;
  link: string;
  icon?: IconDefinition;
  showSiteFilter?: boolean;
  width?: string;
  showTrademark?: boolean;
  showProprietaryMark?: boolean;
  trademarkClass?: string;
}

export interface NavItem {
  label: string;
  link?: string;
  linkFn?: (_?) => string;
  disabled?: boolean;
  disabledFn?: (_?) => boolean;
  key?: string;
  icon?: IconDefinition;
  iconStyle?: string;
  class?: string;
  children?: NavItem[];
  badge$?: Observable<number>;
  queryParams?: {};
  component?: any;
  tooltip?: string;
}

export type NavItems = NavItem[];

export type NavState = { [key in NavLocation]?: BehaviorSubject<NavItems> };

export interface NavConfig {
  key?: string;
  location: NavLocation;
  stream: Observable<NavItems>;
}

export enum NavLocation {
  QUICK_NAV = 'QUICK_NAV',
  NAV = 'NAV',
  SUB_NAV = 'SUB_NAV'
}

export interface ActionLink {
  link: string;
  label: string;
}
