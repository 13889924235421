import { Injectable } from '@angular/core';
import { LabelValue } from '@lib-resource/label-value.model';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgModel } from '@main/org/models/org.model';
import { OrgHttpService } from '@main/org/org-http.service';
import { of } from 'rxjs';

export const defaultSort = ['name'];

export function generateFilterString(value: string, asyncExtras?: any) {
  let query = '';
  if (asyncExtras?.onlyStopLossOrgs) {
    query = `orgType.orgFunction ~ ('RESPOND', 'OFFLINE_RESPOND')`;
  } else if (asyncExtras?.onlyRxOrgs) {
    query = `orgType.orgFunction ~ ('PBM')`;
  } else {
    query = `orgType.orgFunction ~ ('RESPOND', 'OFFLINE_RESPOND', 'PBM')`;
  }
  if (!value) {
    return query;
  }
  return query + ` and name ~ '${value}' or description ~ '${value}'`;
}

@Injectable({
  providedIn: 'root'
})
export class SellSideOptionService extends AsyncOptions<OrgModel> {
  key = 'sellSideOrgs';

  constructor(private orgHttpService: OrgHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) =>
    this.orgHttpService
      .searchAllOrgs(
        this.combineFilters(generateFilterString(value, asyncExtras), requiredQuery),
        pageIndex,
        pageSize,
        defaultSort
      )
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values: any[], multi) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.map((co) => co.credentialedOrg.id).join("','")}')` : `id ~ '${values}'`;
    return this.orgHttpService
      .searchAllOrgs(filter, 0, 1000, defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  generateLabelValues(content: OrgModel[]): LabelValue<string, OrgModel>[] {
    if (!content?.length) return [];
    return content.map((val) => ({
      label: val.name,
      value: val
    }));
  }
}
