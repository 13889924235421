<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label> Extract Text from Image</app-dialog-label>
<app-dialog-content class="ocr-dialog-content">
  <div class="img-container mat-elevation-z2">
    <div class="img-wrapper">
      <div
        *ngFor="let x of ocrData.boundingBoxes; let i = index"
        #highlighter
        class="highlighter"
        [style.top]="highlighterTop[i]"
        [style.left]="highlighterLeft[i]"
        [style.height]="highlighterHeight[i]"
        [style.width]="highlighterWidth[i]"
      ></div>
      <img class="ocr-img" [src]="bgImage" alt="Image of extracted text" />
    </div>
  </div>

  <div style="padding: 1rem">
    <app-simple-form
      #formComponent
      [editing]="true"
      (doSubmit)="submit($event)"
      [data]="formData"
      [definitions]="definitions"
    ></app-simple-form>
  </div>
</app-dialog-content>

<app-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="formComponent.form.pristine" (click)="formComponent.submit()">
    Update
  </button>
  <button mat-raised-button mat-dialog-close>Close</button>
</app-dialog-actions>
