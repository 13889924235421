export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function isEmpty(value: any) {
  return value === null || value === undefined || value === '';
}

export function pluralize(count: number, str: string): string {
  return `${str}${count !== 1 ? 's' : ''}`;
}

export function escapeRmtQl(value: string): string {
  // escape any escapes (backslashes), then escape any ticks
  return value.replace(/\\/g, '\\\\').replace(/'/g, "\\'");
}

// returns true if the value is null or the value without $ or , is a number.  If it is a number then it must be abs(num) <= minMaxVal
export function csvNumber(value: string, minMaxVal: number): boolean {
  if (value) {
    const cleaned = value.replace(/[$,\s]/g, '');
    if (isNaN(+cleaned)) {
      return false;
    }
    if (minMaxVal) {
      return Math.abs(+cleaned) <= minMaxVal;
    }
  }
  return true;
}

export function nullToEmpty(str: string): string {
  return str ? str : '';
}

export function isNumber(val: any): boolean {
  return !isNaN(+val);
}
