import { UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DisablerConfig } from '@form-lib/models/validators.model';

export class DisableFormArrayFieldIf {
  static thereCanBeOnlyOne(config: DisablerConfig): ValidatorFn {
    return (formArray: UntypedFormArray) => {
      if (formArray.disabled) return null;
      const ctrlsAtKey = (formArray.controls as UntypedFormGroup[]).map((fg) => fg.controls[config.fieldKey]);
      const ctrlWithValue = ctrlsAtKey.find((ctrl) => ctrl.value === config.value);

      ctrlsAtKey.forEach((ctrl) => {
        if (ctrlWithValue && ctrl.enabled && ctrlWithValue !== ctrl) ctrl.disable();
        if (!ctrlWithValue && ctrl.disabled) ctrl.enable();
      });
    };
  }

  static controlHasValueOf(config: DisablerConfig): ValidatorFn {
    let ctrlsToDisable;
    return (formArray: UntypedFormArray) => {
      if (formArray.disabled || ctrlsToDisable) return null;
      ctrlsToDisable = [];
      const ctrlsAtKey = (formArray.controls as UntypedFormGroup[]).map((fg) => fg.controls[config.fieldKey]);
      ctrlsAtKey.forEach((ctrl) => {
        const isMatch = Array.isArray(config.value)
          ? config.value.some((val) => val === ctrl.value)
          : ctrl.value === config.value;
        if (isMatch && ctrl.enabled) ctrlsToDisable.push(ctrl);
      });

      ctrlsToDisable.forEach((ctrl) => ctrl.disable());
      ctrlsToDisable = null;
    };
  }
}
