import { Component } from '@angular/core';
import { FormActionBase } from '@form-lib/form-action/form-action.base';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-form-action[buttons]',
  template: `
    <div class="grid-content">
      <ng-content></ng-content>
      <ng-container *ngIf="!formComponent?.editing || disabled">
        <button
          [disabled]="disabled"
          [id]="id + 'edit'"
          mat-raised-button
          (click)="edit()"
          [appInProgressIndicator]="formComponent?.renderingLayout"
        >
          Edit
        </button>
        <ng-content select="[viewMode]"></ng-content>
      </ng-container>

      <ng-container *ngIf="formComponent?.editing && !disabled">
        <button
          [id]="id + 'submit'"
          mat-raised-button
          [color]="getSubmitColor()"
          type="button"
          (click)="submit()"
          [matTooltip]="formComponent.invalid ? 'Click to highlight invalid definitions' : 'Save'"
          [appInProgressIndicator]="formComponent?.renderingLayout"
        >
          <fa-icon [icon]="invalidIcon" *ngIf="formComponent?.invalid && formComponent?.submitted"></fa-icon> Save
        </button>
        <button
          [id]="id + 'cancel'"
          mat-raised-button
          (click)="cancel()"
          [appInProgressIndicator]="formComponent?.renderingLayout"
        >
          Cancel
        </button>
        <ng-content select="[editMode]"></ng-content>
      </ng-container>
    </div>
  `
})
export class FormActionButtonsComponent extends FormActionBase {
  invalidIcon = faExclamationTriangle;
}
