export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export const YES_NO_STRING_OPTIONS = [
  {
    label: 'Yes',
    value: 'Yes'
  },
  {
    label: 'No',
    value: 'No'
  }
];

export const NA_YES_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'n/a',
    value: false
  }
];
