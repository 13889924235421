import { Component, Input } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-info-display',
  template: `
    <div class="grid grid-columns-{{ cols }} info-display-wrapper">
      <div class="display-item" *ngFor="let dataDef of dataDefs">
        <div class="label">{{ dataDef.label }}</div>
        <div class="data">{{ data | dataFormatter: dataDef }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      .display-item {
        min-height: 3rem;
        padding: 0.25rem;
      }
      .data {
        font-size: 0.875rem;
      }
      .label {
        opacity: 0.5;
        font-size: 0.75rem;
        font-weight: 600;
        padding-bottom: 0.125rem;
      }
      .info-display-wrapper {
        grid-column-gap: 1rem;
      }
    `
  ]
})
export class InfoDisplayComponent {
  @Input() dataDefs: DataDefModel[];
  @Input() data: any;
  @Input() cols = 2;
}
