import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { SavedFilterModel } from '@data-table-lib/models/saved-filter.model';
import { Observable } from 'rxjs';
import { UrlUtilService } from './url-util.service';

@Injectable({
  providedIn: 'root'
})
export class SavedFilterDataService {
  constructor(protected http: HttpClient, private urlUtil: UrlUtilService) {}

  getForUser(savedFilterType): Observable<Array<SavedFilterModel>> {
    const params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: { savedFilterType }
      })
    );
    return this.http.get<Array<SavedFilterModel>>(`${SERVER_API_URL}/saved-filter`, { params: params });
  }

  create(savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    if (typeof savedFilter.query === 'object') {
      savedFilter.query = JSON.stringify(savedFilter.query);
    }
    return this.http.post<SavedFilterModel>(`${SERVER_API_URL}/saved-filter`, savedFilter);
  }

  update(savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    return this.http.put<SavedFilterModel>(`${SERVER_API_URL}/saved-filter/${savedFilter.id}`, savedFilter);
  }

  delete(savedFilter: SavedFilterModel): Observable<SavedFilterModel> {
    return this.http.delete<SavedFilterModel>(`${SERVER_API_URL}/saved-filter/${savedFilter.id}`);
  }
}
