import { Injectable } from '@angular/core';
import { sortByProperty } from '@app/tools/array';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyHttpService } from './policy-http.service';

@Injectable()
export class PolicyOptionService extends AsyncOptions<string> {
  key = 'policies';
  defaultSort = ['name'];

  constructor(private policyService: PolicyHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, filterValue, pageIndex, pageSize, _?, requiredQuery?) =>
    this.policyService
      .search(this.generateFilterString(filterValue, requiredQuery), pageIndex, pageSize, this.defaultSort, [
        {
          siteFilterKey: SiteFilterOptions.ACCOUNT,
          dataKey: 'accountId'
        }
      ])
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values) =>
    of(!values?.length ? null : this.generateLabelValues(values.slice().sort(sortByProperty(this.defaultSort[0]))));

  private generateFilterString = (value, requiredQuery) =>
    this.combineFilters(value ? `name ~ '${value}'` : '', requiredQuery);

  generateLabelValues(accounts: any[]): LabelValue<string, string>[] {
    if (!accounts?.length) return [];
    return accounts.map((a) => ({
      label: a.name,
      value: a
    }));
  }
}
