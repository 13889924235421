import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-data-table-filter',
  template: `
    <div class="search-container dense">
      <mat-form-field class="search-box" appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          type="text"
          #input
          (ngModelChange)="updateSearch($event)"
          (keyup.enter)="enterHit(input.value)"
          [ngModel]="searchValue"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  `
})
export class DataTableFilterComponent {
  _searchSource = new Subject<string>();
  @Output() search = this._searchSource.asObservable().pipe(debounceTime(500));
  @Input() searchValue = '';
  @Input() label: string = 'Search';

  @Output() enterEvent = new EventEmitter();

  updateSearch(e) {
    this._searchSource.next(e);
  }

  enterHit(e) {
    this.enterEvent.emit(e);
  }
}
