import { Injectable } from '@angular/core';

import { OrgMemberService } from '@common/services/org-member.service';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyOrgMemberOptionService extends AsyncOptions<string> {
  key = 'policyOrgMember';

  defaultSort = ['user.lastName'];

  constructor(private orgUserService: OrgMemberService, private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.orgUserService
      .searchOrgMembers(this.generateFilterString(value, requiredQuery), pageIndex, pageSize, this.defaultSort)
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values, multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.map((acc) => acc.id).join("','")}')` : `id ~ '${values}'`;
    return this.orgUserService
      .searchOrgMembers(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  private generateFilterString = (value, requiredQuery) => {
    let filterString = value ? `(user.firstName ~ '${value}' or user.lastName ~ '${value}')` : '';
    const orgFilter = `orgId = ${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
    filterString = filterString ? `${filterString} AND ${orgFilter}` : orgFilter;
    return this.combineFilters(filterString, requiredQuery);
  };

  generateLabelValues(items: any[]): LabelValue<string, string>[] {
    return items.map((orgMember) => ({
      label: `${orgMember.user.firstName} ${orgMember.user.lastName}`,
      value: orgMember
    }));
  }
}
