import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { OcrPageModel } from '@main/org-ocr-config/model/org-ocr-result.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrgOcrConfigTestService {
  constructor(private store: Store, private http: HttpClient) {}

  get baseUrl() {
    return `${SERVER_API_URL}/org/${this.store.selectSnapshot(SiteFilterSelectors.singleSelectedOrgId)}`;
  }

  testOcrExtraction(file: File, templateId: number): Observable<OcrPageModel[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<OcrPageModel[]>(`${this.baseUrl}/ocr-template/${templateId}/field-extract`, formData);
  }
}
