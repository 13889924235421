<form
  [formGroup]="form"
  class="mat-typography panel-container collapsible-form dense"
  [class]="{ 'single-column': singleColumn }"
  [appInProgressIndicator]="renderingLayout"
>
  <div
    *ngFor="let section of sectionConfigs | arraysToBottom"
    [class]="{ 'span-all': section.formArrayDef || section.fullWidth }"
  >
    <section>
      <div
        class="rmt-subsection-header"
        *ngIf="section.label !== null && section.label !== undefined && section.label.trim() !== ''"
      >
        <div class="label">{{ section.label }}</div>
        <div class="rmt-subsection-header-actions" *ngIf="section.formArrayDef && form.enabled">
          <button mat-button type="button" (click)="addItem(section.formArrayDef)">
            <fa-icon [icon]="plusIcon"></fa-icon>
            Add <span class="sr-only">{{ section.formArrayDef.label }}</span>
          </button>
        </div>
      </div>
      @if (section.info) {
        <div style="padding: 7px 2px">
          <fa-icon [icon]="section.info.icon" class="{{ section.info.iconClass }} mr-1"></fa-icon>
          <span class="{{ section.info.labelClass }}">{{ section.info.label }}</span>
        </div>
      }
      <div class="pt-2" [class]="{ 'full-width-section': section.fullWidth }">
        <ng-container *ngFor="let def of definitionsBySection[section.key]" [ngSwitch]="def.type">
          <app-table-array
            [control]="form.controls[def.formKey]"
            [definition]="def"
            (doRemove)="removeItem(def, $event)"
            (doClone)="cloneItem(def, $event)"
            *ngSwitchCase="'formGroupArray'"
            class="span-12 grid-gap-2"
          ></app-table-array>
          <div *ngSwitchCase="'dateRange'" class="ocr-overlay-wrapper">
            <app-field-renderer
              [class.disabled-field]="
                form.enabled &&
                form.controls[def.formKey + '/startDate'].disabled &&
                form.controls[def.formKey + '/endDate'].disabled &&
                !def.readOnly
              "
              class="form-field-renderer"
              [inline]="true"
              [controls]="[form.controls[def.formKey + '/startDate'], form.controls[def.formKey + '/endDate']]"
              [definition]="def"
            ></app-field-renderer>
          </div>
          <h1
            *ngSwitchCase="'labelOnly'"
            [class]="!!def.labelClasses ? def.labelClasses : 'sub-section-label-text mb-0'"
          >
            {{ def.label }}
          </h1>
          <div *ngSwitchCase="'spacer'" class="ocr-overlay-wrapper spacer">&nbsp;</div>
          <div *ngSwitchDefault class="ocr-overlay-wrapper">
            <app-field-renderer
              [class.disabled-field]="form.enabled && form.controls[def.formKey].disabled && !def.readOnly"
              class="form-field-renderer"
              [inline]="true"
              [control]="form.controls[def.formKey]"
              [definition]="def"
              [hasJoinedField]="!!def.joinedField"
            ></app-field-renderer>
            <ng-container *ngIf="!!def.joinedField" class="ocr-overlay-wrapper">
              <app-field-renderer
                [class.disabled-field]="
                  form.enabled && form.controls[def.joinedField.formKey].disabled && !def.joinedField.readOnly
                "
                class="form-field-renderer"
                [inline]="true"
                [control]="form.controls[def.joinedField.formKey]"
                [definition]="def.joinedField"
                [isJoinedField]="true"
              ></app-field-renderer>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</form>
