import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { BaseFieldComponent } from '@form-lib/fields/base-field/base-field.component';

@Component({
  selector: 'app-color-picker-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        *ngIf="inline"
        >{{ label }}</span
      >
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <input
          matInput
          [ngxMatColorPicker]="picker"
          [formControl]="control"
          [required]="required"
          [readonly]="readOnly"
          (colorInput)="_valueChanged($event)"
        />
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker #picker [color]="themePalette"></ngx-mat-color-picker>
        <mat-hint>
          <div *ngIf="!control.parent?.disabled || !control.disabled" truncateText>{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class ColorPickerFieldComponent extends BaseFieldComponent {
  public themePalette: ThemePalette = 'primary';
}
