import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataDefModel } from '@lib-resource/data-def.model';

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label> Viewing {{ data.label }} </app-dialog-label>
    <app-dialog-content>
      <app-info-display [data]="data.displayData" [dataDefs]="data.definitions"></app-info-display>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button mat-dialog-close>Close</button>
    </app-dialog-actions>
  `
})
export class InfoDisplayDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { label: string; displayData: any; definitions: DataDefModel[] }) {}
}
