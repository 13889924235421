import { ProductRole } from '@auth/models/user.model';
import { OrgMemberModel } from '@common/models/org-mem.model';
import { FeatureType } from '@main/org/models/org.model';
import { OrgMembershipState, OrgMembershipStateModel } from '@main/store/org-membership/org-membership.state';
import { OrgProduct, OrgProductType } from '@main/store/product/product.model';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { createSelector, Selector } from '@ngxs/store';

export class OrgMembershipSelectors {
  // Used for org site filter selector
  static orgMembershipsByProduct(selectedProduct: OrgProductType) {
    return createSelector([OrgMembershipState], (state: OrgMembershipStateModel) =>
      OrgMembershipSelectors.orgMembershipsWithSelectedProduct(state, selectedProduct)
    );
  }

  @Selector([OrgMembershipState, ProductSelectors.selectedProduct])
  static orgMembershipsWithSelectedProduct(state: OrgMembershipStateModel, selectedProduct?: OrgProductType) {
    // TODO: Remove selectedProduct check when ngxs version is updated and router bug is fixed https://github.com/ngxs/store/issues/1605
    if (!selectedProduct) return state?.orgMemberships;
    if (selectedProduct === OrgProduct.RINGMASTER) return state?.orgMemberships;
    const orgMems = state.orgMemberships.filter(
      (orgMem) => orgMem[selectedProduct] && orgMem[selectedProduct] !== ProductRole.NONE
    );
    if (selectedProduct === OrgProduct.SMART) {
      return orgMems.filter((orgMem) => orgMem?.orgFeatures.includes(FeatureType.smartLinqEnabled));
    }
    if (selectedProduct === OrgProduct.QUOTE) {
      return orgMems.filter((orgMem) => orgMem?.orgFeatures.includes(FeatureType.quoteLinqEnabled));
    }
    if (selectedProduct === OrgProduct.QUOTE_RX) {
      return orgMems.filter((orgMem) => orgMem?.orgFeatures.includes(FeatureType.quoteLinqRxEnabled));
    }
  }

  @Selector([OrgMembershipState, ProductSelectors.selectedProduct])
  static defaultOrgMembershipsWithSelectedProduct(
    state: OrgMembershipStateModel,
    selectedProduct: OrgProductType
  ): OrgMemberModel {
    const orgMemberships = OrgMembershipSelectors.orgMembershipsByProduct(selectedProduct)(state);
    // If none currently selected, set to default
    let defaultOrgMembership;
    if (selectedProduct === OrgProduct.QUOTE) {
      defaultOrgMembership = orgMemberships.find((orgMem) => orgMem.quoteLinqPrimary);
      if (!defaultOrgMembership) {
        defaultOrgMembership = orgMemberships.find(
          (orgMem) => orgMem.quoteLinqRole && orgMem.quoteLinqRole !== ProductRole.NONE
        );
      }
    } else if (selectedProduct === OrgProduct.QUOTE_RX) {
      defaultOrgMembership = orgMemberships.find((orgMem) => orgMem.quoteLinqRxPrimary);
      if (!defaultOrgMembership) {
        defaultOrgMembership = orgMemberships.find(
          (orgMem) => orgMem.quoteLinqRxRole && orgMem.quoteLinqRxRole !== ProductRole.NONE
        );
      }
    } else if (selectedProduct === OrgProduct.SMART) {
      defaultOrgMembership = orgMemberships.find((orgMem) => orgMem.smartLinqPrimary);
      if (!defaultOrgMembership) {
        defaultOrgMembership = orgMemberships.find(
          (orgMem) => orgMem.smartLinqRole && orgMem.smartLinqRole !== ProductRole.NONE
        );
      }
    }
    return defaultOrgMembership;
  }

  // App-wide
  @Selector([OrgMembershipState])
  static orgMemberships(state: OrgMembershipStateModel) {
    return state?.orgMemberships;
  }

  static orgMembershipById(orgId: number) {
    return createSelector([OrgMembershipState], (state: OrgMembershipStateModel) =>
      state?.orgMemberships.find((orgMem) => orgMem.orgId === orgId)
    );
  }

  @Selector([OrgMembershipState])
  static availableProducts(state: OrgMembershipStateModel): OrgProductType[] {
    const products = [OrgProduct.RINGMASTER];

    if (state.orgMemberships?.some((orgMem) => orgMem.quoteLinqRole && orgMem.quoteLinqRole !== ProductRole.NONE)) {
      products.push(OrgProduct.QUOTE);
    }
    if (state.orgMemberships?.some((orgMem) => orgMem.quoteLinqRxRole && orgMem.quoteLinqRxRole !== ProductRole.NONE)) {
      products.push(OrgProduct.QUOTE_RX);
    }
    if (state.orgMemberships?.some((orgMem) => orgMem.smartLinqRole && orgMem.smartLinqRole !== ProductRole.NONE)) {
      products.push(OrgProduct.SMART);
    }
    return products;
  }
}
