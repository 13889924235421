import { Injectable } from '@angular/core';
import { OrgMembershipSelectors } from '@main/store/org-membership/org-membership.selectors';
import { SiteFilters } from '@main/store/site-filter/site-filter.actions';
import { SiteFilterOptions, SiteFilterStateModel } from '@main/store/site-filter/site-filter.models';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { ProductSelectors } from '@main/store/product/product.selectors';

const siteFilterStateModelDefault: SiteFilterStateModel = {};
const siteFilterStateOptionsNotReset: SiteFilterOptions[] = [
  SiteFilterOptions.SHOW_EFFECTIVE_DATE_FILTER,
  SiteFilterOptions.EFFECTIVE_DATE_RANGE,
  SiteFilterOptions.SHOW_UNDERWRITER_FILTER,
  SiteFilterOptions.UNDERWRITER,
  SiteFilterOptions.SHOW_PBM_FILTER,
  SiteFilterOptions.PBM
];

@State<SiteFilterStateModel>({
  name: 'siteFilters',
  defaults: siteFilterStateModelDefault
})
@Injectable()
export class SiteFilterState {
  constructor(private store: Store) {}

  // START SiteFilter STATE
  @Action(SiteFilters.Set)
  setSiteFilters(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.Set) {
    ctx.patchState(action.siteFilters);
  }

  @Action(SiteFilters.Reset)
  resetSiteFilters(ctx: StateContext<SiteFilterStateModel>) {
    ctx.setState(siteFilterStateModelDefault);
  }

  @Action(SiteFilters.SetOrgFunction)
  setOrgFunctionSiteFilter(ctx: StateContext<SiteFilterStateModel>, { orgFunction }: SiteFilters.SetOrgFunction) {
    // Reset other site filters on changes
    const siteFiltersState = ctx.getState();

    // If orgFunction is passed in and is same as previous, escape
    if (orgFunction && siteFiltersState[SiteFilterOptions.QUOTE_FUNCTION] === orgFunction) return;

    const orgMemberships = this.store.selectSnapshot(
      OrgMembershipSelectors.orgMembershipsByProduct(this.store.selectSnapshot(ProductSelectors.selectedProduct))
    );

    // If no orgFunction provided, use the one from the first org membership
    if (!orgFunction) {
      orgFunction = orgMemberships[0].orgFunction;
    }

    const isSysAdmin = this.store.selectSnapshot(UserSelectors.isSysAdmin);
    let selectedOrgs;
    if (isSysAdmin) {
      selectedOrgs = [orgMemberships.find((orgMem) => orgMem.orgFunction === orgFunction)?.orgId];
    } else {
      const matchingOrg = orgMemberships.find((orgMem) => orgMem.orgFunction === orgFunction && orgMem.quoteLinqPrimary)
        ?.orgId;
      selectedOrgs = matchingOrg ? [matchingOrg] : [];
    }

    this.setStateWithRememberedOptions(ctx, {
      [SiteFilterOptions.QUOTE_FUNCTION]: orgFunction,
      [SiteFilterOptions.ORG_MEMBERSHIP]: selectedOrgs,
      [SiteFilterOptions.ACCOUNT]: []
    });
  }

  @Action(SiteFilters.HideSiteFilter)
  setHideSiteFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.HideSiteFilter) {
    ctx.patchState({
      [SiteFilterOptions.HIDE_SITE_FILTER]: { hide: action.hideSiteFilter }
    });
  }

  @Action(SiteFilters.SetOrgMemberships)
  setOrgMembershipSiteFilter(ctx: StateContext<SiteFilterStateModel>, { orgIds }: SiteFilters.SetOrgMemberships) {
    // Reset other site filters on changes
    const siteFiltersState = ctx.getState();
    let orgMems = this.store
      .selectSnapshot(OrgMembershipSelectors.orgMemberships)
      .filter((orgMem) => orgIds.some((orgId) => orgId === orgMem.orgId));
    if (
      isEqual(
        siteFiltersState[SiteFilterOptions.ORG_MEMBERSHIP],
        orgMems.map((orgMem) => orgMem.orgId)
      )
    ) {
      return;
    }

    // If clear All is fired for sysAdmin, set site filters to all orgs of selected orgFunction
    if (!orgMems?.length && this.store.selectSnapshot(UserSelectors.isSysAdmin)) {
      const orgMembership = this.store
        .selectSnapshot(OrgMembershipSelectors.orgMembershipsWithSelectedProduct)
        .find((orgMem) => siteFiltersState[SiteFilterOptions.QUOTE_FUNCTION] === orgMem.orgFunction);
      orgMems = [orgMembership];
    }
    this.setStateWithRememberedOptions(ctx, {
      [SiteFilterOptions.QUOTE_FUNCTION]: orgMems.length
        ? orgMems[0]?.orgFunction
        : siteFiltersState[SiteFilterOptions.QUOTE_FUNCTION],
      [SiteFilterOptions.ORG_MEMBERSHIP]: orgMems?.map((orgMem) => orgMem?.orgId),
      [SiteFilterOptions.ACCOUNT]: []
    });
  }

  @Action(SiteFilters.SetAccounts)
  setAccountsSiteFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.SetAccounts) {
    const siteFiltersState = ctx.getState();
    if (isEqual(siteFiltersState[SiteFilterOptions.ACCOUNT], action.accountIds)) return;
    // Reset policy site filters on changes
    this.setStateWithRememberedOptions(ctx, {
      [SiteFilterOptions.QUOTE_FUNCTION]: siteFiltersState[SiteFilterOptions.QUOTE_FUNCTION],
      [SiteFilterOptions.ORG_MEMBERSHIP]: siteFiltersState[SiteFilterOptions.ORG_MEMBERSHIP],
      [SiteFilterOptions.ACCOUNT]: action.accountIds
    });
  }

  @Action(SiteFilters.SetPolicies)
  setPoliciesSiteFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.SetPolicies) {
    const siteFiltersState = ctx.getState();
    if (isEqual(siteFiltersState[SiteFilterOptions.POLICY], action.policies)) return;

    ctx.patchState({
      [SiteFilterOptions.POLICY]: action.policies
    });
  }

  @Action(SiteFilters.SetEffectiveDateRange)
  setEffectiveDateRangeFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.SetEffectiveDateRange) {
    const siteFiltersState = ctx.getState();
    if (isEqual(siteFiltersState[SiteFilterOptions.EFFECTIVE_DATE_RANGE], action.dateRange)) return;

    ctx.patchState({
      [SiteFilterOptions.EFFECTIVE_DATE_RANGE]: action.dateRange
    });
  }

  @Action(SiteFilters.ShowEffectiveDateSiteFilter)
  setShowEffectiveDateRangeFilter(
    ctx: StateContext<SiteFilterStateModel>,
    action: SiteFilters.ShowEffectiveDateSiteFilter
  ) {
    ctx.patchState({
      [SiteFilterOptions.SHOW_EFFECTIVE_DATE_FILTER]: { hide: !action.showEffectiveDateSiteFilter }
    });
  }

  @Action(SiteFilters.SetUnderwriters)
  setUnderwriterFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.SetUnderwriters) {
    const siteFiltersState = ctx.getState();
    if (isEqual(siteFiltersState[SiteFilterOptions.UNDERWRITER], action.underwriters)) return;

    ctx.patchState({
      [SiteFilterOptions.UNDERWRITER]: action.underwriters
    });
  }

  @Action(SiteFilters.ShowUnderwriterFilter)
  setShowUnderwriterFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.ShowUnderwriterFilter) {
    ctx.patchState({
      [SiteFilterOptions.SHOW_UNDERWRITER_FILTER]: { hide: !action.showUnderwriterFilter }
    });
  }

  @Action(SiteFilters.SetPbms)
  setPbms(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.SetPbms) {
    const siteFiltersState = ctx.getState();
    if (isEqual(siteFiltersState[SiteFilterOptions.PBM], action.pbmIds)) return;
    ctx.patchState({
      [SiteFilterOptions.PBM]: action.pbmIds
    });
  }

  @Action(SiteFilters.ShowPbmFilter)
  setShowPbmFilter(ctx: StateContext<SiteFilterStateModel>, action: SiteFilters.ShowPbmFilter) {
    ctx.patchState({
      [SiteFilterOptions.SHOW_PBM_FILTER]: { hide: !action.showPbmFilter }
    });
  }
  // END SiteFilter STATE

  private setStateWithRememberedOptions(
    ctx: StateContext<SiteFilterStateModel>,
    siteFilterStateModel: SiteFilterStateModel
  ) {
    // remember the options that shouldn't get reset
    const remembered = {};
    siteFilterStateOptionsNotReset.forEach((rememberOption) => {
      remembered[rememberOption] = ctx.getState()[rememberOption];
    });

    ctx.setState({ ...remembered, ...siteFilterStateModel });
  }
}
