import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterBrickRowModel, FilterBrickRowOption } from '@app/libraries/workspace-lib/model/filter-brick-row.model';
import { FiltersModel } from '@main/store/workspace/workspace.model';

@Component({
  selector: 'app-filter-brick-rows',
  templateUrl: 'filter-brick-rows.component.html',
  styleUrls: ['filter-brick-rows.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBrickRowsComponent {
  @Input() filterBrickRows: FilterBrickRowModel[];
  @Input() selectedFilters: FiltersModel;
  @Output() filterBrickClicked = new EventEmitter<FilterBrickRowOption>();

  /**
   * When filter brick is clicked, identify the corresponding FilterBrickRowOption object
   * associated with the brick. Toggle the value of the 'isSelected' attribute of that object.
   * Emit the FilterBrickRowOption object so that the parent container will be notified.
   *
   * The parent container will build the query string based on all the selected filter bricks.
   */
  onFilterBrickClick(filterOption) {
    this.filterBrickClicked.emit(filterOption);
  }

  isSelected(filterBrickRow: FilterBrickRowModel, option: FilterBrickRowOption) {
    return (
      this.selectedFilters &&
      this.selectedFilters[filterBrickRow.rowName] &&
      this.selectedFilters[filterBrickRow.rowName].includes(option.filterString)
    );
  }
}
