import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@common/dialogs/dialogs.model';

@Component({
  selector: 'app-alert-dialog',
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>{{ data.label }}</app-dialog-label>
    <app-dialog-content>
      <p *ngIf="data.message">{{ data.message }}</p>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button mat-dialog-close>Close</button>
    </app-dialog-actions>
  `,
  styles: []
})
export class AlertDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
