import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { GitInfoModel, ServerInfoModel } from '@common/models/server-info.model';
import { BehaviorSubject, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerInfoService {
  serverInfoSnapshot;
  private serverInfo$: BehaviorSubject<ServerInfoModel> = new BehaviorSubject(
    new ServerInfoModel(
      { maxUploadBytes: 10485760 },
      {
        commit: {
          id: 'unknown',
          time: 'unknown'
        },
        branch: 'unknown'
      }
    )
  );

  constructor(private http: HttpClient) {
    // refresh the server information every hour min (3600000 ms)
    timer(0, 3600000)
      .pipe(mergeMap(() => this.http.get<ServerInfoModel>(`${SERVER_API_URL}/server/info`)))
      .subscribe((serverInfo) => {
        this.serverInfoSnapshot = serverInfo;
        this.serverInfo$.next(serverInfo);
      });
  }

  getGitInfo(): GitInfoModel {
    return this.serverInfo$.getValue().git;
  }
}
