import { AfterViewInit, Component } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-date-range-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }" class="app-date-range-field">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        *ngIf="inline"
      >
        {{ label }}</span
      >
      <mat-form-field
        [appearance]="appearance"
        [floatLabel]="inline ? 'never' : 'always'"
        [style.transform]="'translateY(-0.25rem)'"
      >
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <mat-date-range-input [rangePicker]="picker" style="font-size: 14px">
          <input matStartDate [required]="required" [readOnly]="readOnly" [formControl]="controls[0]" />
          <input matEndDate [required]="required" [readOnly]="readOnly" [formControl]="controls[1]" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!readOnly"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>
          <div truncateText *ngIf="!controls[0].parent?.disabled || !controls[0].disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="controls[0].errors || controls[1].errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss', './date-range-field.component.scss']
})
export class DateRangeFieldComponent extends BaseFieldComponent implements AfterViewInit {
  constructor() {
    super();
  }

  ngAfterViewInit() {
    // register value change listeners -
    // when one of the fields is in an invalid state it is possible to fix that error by modifying the other field
    // so when one value changes, running the validity check on the other one will clear the error
    this.controls[0].valueChanges.subscribe((_) => {
      this.controls[1].updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
    this.controls[1].valueChanges.subscribe((_) => {
      this.controls[0].updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }
}
