import { Directive, ElementRef, HostBinding, Renderer2 } from '@angular/core';
import { BaseStatusIndicatorDirective } from '@shared/directives/status-indicator/base-status-indicator.directive';

@Directive({
  selector: 'i[statusIndicator]'
})
export class StatusIndicatorIconDirective extends BaseStatusIndicatorDirective {
  @HostBinding('class.fas') fontAwesome = true;
  protected _errorClass = 'fa-exclamation-triangle';
  protected _successClass = 'fa-check';

  constructor(_el: ElementRef, _renderer: Renderer2) {
    super(_el, _renderer);
  }
}
