import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OrgMemberService } from '@common/services/org-member.service';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { UserSelectors } from '@main/store/user/user.selectors';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This Async Option fetches the orgs that are available to the user.
 */
@Injectable({
  providedIn: 'root'
})
export class OrgAsyncOptionGroupService extends AsyncOptions<string> {
  key = 'orgAsyncOptionGroup';
  defaultSort = ['orgname'];

  constructor(private orgMemberService: OrgMemberService, private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) =>
    // Get the orgs this user is a member, ordered by orgName
    this.orgMemberService
      .searchOrgMembers(
        this.combineFilters(
          this.generateFilterString(control, asyncOptionDeps, value, requiredQuery),
          asyncExtras?.filter
        ),
        pageIndex,
        pageSize,
        this.defaultSort
      )
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const userFilter = this.userFilter();
    const filter = multi
      ? `orgid ~ ('${values.join("','")}') and ${userFilter}`
      : `orgid ~ '${values}' and ${userFilter}`;
    return this.orgMemberService
      .searchOrgMembers(filter, 0, 1000, this.defaultSort)
      .pipe(map(({ content, total }) => this.generateLabelValues(content)));
  };

  generateLabelValues(items: any[]): LabelValue<string, string>[] {
    if (!items?.length) return [];
    return items.map((orgMember) => ({
      label: orgMember.orgName,
      value: orgMember.orgId
    }));
  }

  private generateFilterString = (control: AbstractControl, asyncOptionDeps: string[], value, requiredQuery?) => {
    const userFilter = this.userFilter();
    const filterString = value ? `(orgname ~ '${value}' and ${userFilter})` : `(${userFilter})`;
    return this.combineFilters(filterString, requiredQuery);
  };

  private userFilter() {
    const activeUser = this.store.selectSnapshot(UserSelectors.user);
    return `user.id = ${activeUser.id}`;
  }
}
