<button
  class="mat-typography material-icons"
  [ngClass]="{ active: active }"
  appFileSelect
  appFileDrop
  [multi]="multi"
  [extensions]="extensions"
  [disabled]="disabled"
  (selectFile)="fileSelected($event)"
  (filesDropped)="fileSelected($event)"
  (filesHovered)="toggleActive($event)"
>
  <i class="material-icons" aria-hidden="true">cloud_upload</i>
  <span class="pl-4">{{ labelText }}</span>
</button>
