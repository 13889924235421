import { FindRequestModel } from '@common/models/find-request.model';
import { AggType } from '@common/models/agg.model';

export enum RxClaimStatType {
  ACTUAL = 'ACTUAL',
  CONTRACT = 'CONTRACT'
}

export class RxClaimStatModel {
  quoteRequestId?: number;
  processing?: boolean;
  lastModifiedDate?: string;
  reversalCount?: number;
  zeroAmountCount?: number;
  invalidPriceHistoryCount?: number;
  totalClaims?: number;
  statType?: RxClaimStatType;
}

export class RetailMailAgg {
  cnt: number = 0;
  genericCnt: number = 0;
  brandCnt: number = 0;
  genericAwp?: number = 0;
  genericEffDisc?: number = 0;
  genericIngCost?: number = 0;
  brandAwp?: number = 0;
  brandEffDisc?: number = 0;
  brandIngCost?: number = 0;
  dispFee: number = 0;
  total: number = 0;
}

export interface RxClaimStatSummary {
  quality?: RxClaimStatModel;
  retail30?: RetailMailAgg;
  retail90?: RetailMailAgg;
  mail?: RetailMailAgg;
  specialty?: RetailMailAgg;
  limited?: RetailMailAgg;
}

export const typeAgg: FindRequestModel = {
  aggOnly: true,
  query: `(discounted = true or type ~ ('SPECIALTY_BRAND', 'SPECIALTY_GENERIC', 'LIMITED_DIST_BRAND', 'LIMITED_DIST_GENERIC')) and type not null`,
  aggs: [
    {
      name: 'typegroup',
      groups: ['type'],
      aggregations: [
        { name: 'medispanawp', type: AggType.SUM, field: 'medispanawp' },
        { name: 'nadacawp', type: AggType.SUM, field: 'nadacawp' },
        { name: 'ingcost', type: AggType.SUM, field: 'ingredientcost' },
        { name: 'cnt', type: AggType.COUNT, field: 'id' },
        { name: 'retail30dispfee', type: AggType.SUM, field: 'discountretail30dispensefee' },
        { name: 'retail90dispfee', type: AggType.SUM, field: 'discountretail90dispensefee' },
        { name: 'maildispfee', type: AggType.SUM, field: 'discountmailorderdispensefee' },
        { name: 'specdispfee', type: AggType.SUM, field: 'discountspecialtydispensefee' }
      ]
    }
  ]
};
