import { NgModule } from '@angular/core';
import { FileUploadLibModule } from '@file-upload-lib/file-upload-lib.module';
import { ArrayItemFormGroupDirective } from '@form-lib/arrays/array-item-form-group.directive';
import { GroupArrayComponent } from '@form-lib/arrays/group-array/group-array.component';
import { TableArrayComponent } from '@form-lib/arrays/table-array/table-array.component';
import { DisableReadonlySelectDirective } from '@form-lib/disable-control/disable-readonly-select.directive';
import { FieldLayoutDirective } from '@form-lib/field-layout.directive';
import { FormSubmitDirective } from '@form-lib/form-action/form-submit.directive';
import { ArrayToBottomPipe } from '@form-lib/forms/array-to-bottom.pipe';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { CollapsibleFormComponent } from '@form-lib/forms/collapsible-form/collapsible-form.component';
import { FormDialogService } from '@form-lib/services/form-dialog.service';
import { SharedModule } from '@shared/shared.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@common/components/color-picker/services/color-formats';
import { NgxMatColorPickerModule } from '@common/components/color-picker/color-picker.module';
import { ColorPickerFieldComponent } from '@form-lib/fields/color-picker-field/color-picker-field.component';
import { CurrencyPipe } from '@angular/common';
import { FieldRendererComponent } from './field-renderer/field-renderer.component';
import {
  AsyncSelectFieldComponent,
  CheckboxFieldComponent,
  CurrencyFieldComponent,
  DateFieldComponent,
  DateRangeFieldComponent,
  DateTimeFieldComponent,
  DerivedValueFieldComponent,
  FieldErrorComponent,
  MultiCurrencyFieldComponent,
  MultiInputFieldComponent,
  MultiNumberFieldComponent,
  MultiSelectFieldComponent,
  MultiSelectFilterFieldComponent,
  NumberFieldComponent,
  NumberRangeFieldComponent,
  SelectFieldComponent,
  SingleSelectFilterFieldComponent,
  TelephoneFieldComponent,
  TextareaFieldComponent,
  TextFieldComponent,
  ToggleFieldComponent
} from './fields';
import { FormActionButtonsComponent } from './form-action/form-action-buttons.component';
import { FormActionIconsComponent } from './form-action/form-action-icons.component';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { SectionFormComponent } from './forms/section-form/section-form.component';
import { SimpleFormComponent } from './forms/simple-form/simple-form.component';
import { RangeInputComponent } from './inputs/range-input.component';
import { TelephoneInputComponent } from './inputs/telephone-input.component';
import { OcrDialogComponent } from './ocr-overlay/ocr-dialog/ocr-dialog.component';
import { OcrOverlayDirective } from './ocr-overlay/ocr-overlay.directive';
import {
  OcrPopoverTriggerComponent,
  OcrSnippetMessageDialogComponent
} from './ocr-overlay/ocr-popover-trigger/ocr-popover-trigger.component';

const exportComponents = [
  BaseFormComponent,
  CollapsibleFormComponent,
  FieldLayoutDirective,
  FieldRendererComponent,
  FormActionButtonsComponent,
  FormActionIconsComponent,
  FormSubmitDirective,
  GroupArrayComponent,
  OcrOverlayDirective,
  RangeInputComponent,
  SectionFormComponent,
  SimpleFormComponent,
  TableArrayComponent,
  TelephoneInputComponent,
  ToggleFieldComponent
];

export const entryComponents = [
  AsyncSelectFieldComponent,
  CheckboxFieldComponent,
  CurrencyFieldComponent,
  DateFieldComponent,
  DateTimeFieldComponent,
  DateRangeFieldComponent,
  DerivedValueFieldComponent,
  FieldErrorComponent,
  FormDialogComponent,
  MultiCurrencyFieldComponent,
  MultiInputFieldComponent,
  MultiNumberFieldComponent,
  MultiSelectFieldComponent,
  MultiSelectFilterFieldComponent,
  NumberFieldComponent,
  NumberRangeFieldComponent,
  OcrDialogComponent,
  OcrPopoverTriggerComponent,
  OcrSnippetMessageDialogComponent,
  SelectFieldComponent,
  SingleSelectFilterFieldComponent,
  TextareaFieldComponent,
  TextFieldComponent,
  TelephoneFieldComponent,
  ColorPickerFieldComponent
];

@NgModule({
  imports: [SharedModule, NgxMatSelectSearchModule, FileUploadLibModule, NgxMatColorPickerModule],
  providers: [FormDialogService, CurrencyPipe, { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  declarations: [
    ...exportComponents,
    ...entryComponents,
    ArrayToBottomPipe,
    ArrayItemFormGroupDirective,
    DisableReadonlySelectDirective
  ],
  exports: [...exportComponents, ...entryComponents]
})
export class FormLibModule {}
