import { NgModule } from '@angular/core';
import { MultiFileUploadComponent } from '@common/components/multi-file-upload/multi-file-upload.component';
import { SharedModule } from '@shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  imports: [FileUploadModule, SharedModule],
  declarations: [MultiFileUploadComponent],
  exports: [MultiFileUploadComponent]
})
export class MultiFileUploadModule {}
