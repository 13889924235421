import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { AccountModel } from '@common/models/account.model';
import { of } from 'rxjs';
import { AccountService } from './account.service';

@Injectable()
export class AccountModelOptionService extends AsyncOptions<AccountModel> {
  key = 'accountModels';
  defaultSort = ['name'];

  constructor(private accountService: AccountService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.accountService
      .search(this.generateFilterString(value, requiredFilter), pageIndex, pageSize, this.defaultSort)
      .pipe(
        map(({ content, total }) => ({
          content: this.genLabelVals(content, asyncExtras?.includeBusinessType, asyncExtras?.useRxBusinessType),
          total
        }))
      );

  valuesFromKeys = (value: AccountModel, asyncExtras?: any) => {
    if (!value?.id) return of(null);
    const filter = `id = '${value.id}'`;
    return this.accountService
      .searchUnfiltered(filter, 0, 100, this.defaultSort)
      .pipe(
        map((result) =>
          this.genLabelVals(result.content, asyncExtras?.includeBusinessType, asyncExtras?.useRxBusinessType)
        )
      );
  };

  private generateFilterString = (value, requiredQuery?) => {
    let filterString = value ? `name ~ '${value}'` : '';
    return this.combineFilters(filterString, requiredQuery);
  };

  genLabelVals(
    accounts: AccountModel[],
    includeBusinessType: boolean,
    useRxBusinessType: boolean
  ): { label: any; value: AccountModel }[] {
    if (includeBusinessType) {
      return accounts.map((a) => {
        const renewal = useRxBusinessType ? a.rxRenewal : a.stopLossRenewal;
        return {
          label: `${a.name} (ID: ${a.id}) | ${renewal ? 'Existing Business' : 'New Prospect'}`,
          value: a
        };
      });
    }

    return accounts.map((a) => ({
      label: `${a.name} (ID: ${a.id})`,
      value: a
    }));
  }
}
