import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatColorPaletteComponent } from '@common/components/color-picker/components/color-palette/color-palette.component';
import { NgxMatColorCanvasComponent } from '@common/components/color-picker/components/color-canvas/color-canvas.component';
import { NgxMatColorCollectionComponent } from '@common/components/color-picker/components/color-collection/color-collection.component';
import { NgxMatColorSliderComponent } from '@common/components/color-picker/components/color-canvas/color-slider/color-slider.component';
import { NumericColorInputDirective } from '@common/components/color-picker/directives/numeric-color-input.directive';
import {
  NGX_MAT_COLOR_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  NgxMatColorPickerComponent,
  NgxMatColorPickerContentComponent
} from '@common/components/color-picker/components/color-picker/color-picker.component';
import { NgxMatColorToggleComponent } from '@common/components/color-picker/components/color-toggle/color-toggle.component';
import { NgxMatColorPickerInput } from '@common/components/color-picker/components/color-picker/color-input.component';
import { ColorAdapter } from '@common/components/color-picker/services/color-adapter';

@NgModule({
  declarations: [
    NgxMatColorPaletteComponent,
    NgxMatColorCanvasComponent,
    NgxMatColorCollectionComponent,
    NgxMatColorSliderComponent,
    NumericColorInputDirective,
    NgxMatColorPickerContentComponent,
    NgxMatColorPickerComponent,
    NgxMatColorToggleComponent,
    NgxMatColorPickerInput
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    PortalModule,
    MatIconModule
  ],
  exports: [
    NgxMatColorToggleComponent,
    NgxMatColorPickerInput,
    NgxMatColorPickerComponent,
    NgxMatColorPickerContentComponent
  ],
  providers: [ColorAdapter, NGX_MAT_COLOR_PICKER_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class NgxMatColorPickerModule {}

/**
 * The original version of this color-picker was inspired by (read copied from)
 * https://www.npmjs.com/package/@angular-material-components/color-picker?activeTab=readme
 * It did not have the customization that we needed so I forked it into our codebase and made the modifications we needed
 */
