import { Observable, Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseApiDialogComponent<T> implements OnDestroy {
  protected overrideLoading: boolean = false;
  loading: boolean = false;
  subs = new Subscription();

  protected constructor(loadFunction: () => Observable<T>) {
    this.loading = true;
    this.subs.add(
      this.loadData(loadFunction)
        .pipe(finalize(() => (this.loading = this.overrideLoading ? this.loading : false)))
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  abstract loadData(loadFunction: () => Observable<T>): Observable<T>;
}
