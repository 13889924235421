import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DerivedValueDef } from '@lib-resource/data-def.model';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

function getSum(total, num) {
  if (isNaN(total)) {
    return 0;
  }

  if (isNaN(num)) {
    return +total;
  }

  return +total + +num;
}

function getDivision(total, num) {
  if (isNaN(total)) {
    return 0;
  }

  if (num === 0 || isNaN(num)) {
    return +total;
  }

  return +total / +num;
}

@Injectable()
export class DerivedValueFieldService {
  getDerivedValue(derivedValueDef: DerivedValueDef, control: AbstractControl): Observable<string> {
    // Add debounce if performance becomes an issue
    // The empty observable is used to trigger the initial value
    // Only addition, division, percentage supported at this point
    if (derivedValueDef?.operation === 'sum') {
      // sum uses the fields parameter
      if (!derivedValueDef.fields || !derivedValueDef.fields.length) {
        console.error('No definitions from which to derive a value were provided.');
        return of('');
      }
      const valueSourceControls = [];
      derivedValueDef.fields.forEach((field) => {
        valueSourceControls.push(control.parent.controls[field]);
      });
      return merge(...valueSourceControls.map((source) => source.valueChanges), of('')).pipe(
        map(() =>
          valueSourceControls
            .map((source) => source.value)
            .reduce(getSum)
            .toString()
        )
      );
    }

    if (derivedValueDef?.operation === 'subtractSumFromConstant') {
      // sum uses the fields parameter
      if (!derivedValueDef.fields || !derivedValueDef.fields.length) {
        console.error('No definitions from which to derive a value were provided.');
        return of('');
      }
      const valueSourceControls = [];
      derivedValueDef.fields.forEach((field) => {
        valueSourceControls.push(control.parent.controls[field]);
      });
      return merge(...valueSourceControls.map((source) => source.valueChanges), of('')).pipe(
        map(() => {
          let values = valueSourceControls.map((source) => source.value);

          // If all source values are null or undefined, return an empty string
          if (values.every((value) => value === null || value === undefined)) {
            return '';
          }

          let s = values.map((value) => (value === null || value === undefined ? 0 : value)).reduce(getSum);
          return (derivedValueDef.subtractFrom - s).toString();
        })
      );
    }

    if (derivedValueDef?.operation === 'division' || derivedValueDef?.operation === 'percentage') {
      if (
        !derivedValueDef.numeratorFields ||
        !derivedValueDef.numeratorFields.length ||
        !derivedValueDef.denominatorFields ||
        !derivedValueDef.denominatorFields.length
      ) {
        console.error('No numerator or denominator fields from which to derive a value were provided.');
        return of('');
      }
      const allSourceControls = [];
      const numerators = [];
      const denominators = [];
      derivedValueDef.numeratorFields.forEach((field) => {
        allSourceControls.push(control.parent.controls[field]);
        numerators.push(control.parent.controls[field]);
      });
      derivedValueDef.denominatorFields.forEach((field) => {
        allSourceControls.push(control.parent.controls[field]);
        denominators.push(control.parent.controls[field]);
      });

      if (derivedValueDef.operation === 'division') {
        return merge(...allSourceControls.map((source) => source.valueChanges), of('')).pipe(
          map(() => {
            // calc numerator
            const num: number = numerators.map((n) => +n.value).reduce(getSum);
            const den: number = denominators.map((n) => +n.value).reduce(getSum);
            return getDivision(num, den).toString();
          })
        );
      }

      return merge(...allSourceControls.map((source) => source.valueChanges), of('')).pipe(
        map(() => {
          // calc numerator
          const num: number = numerators.map((n) => +n.value).reduce(getSum);
          const den: number = denominators.map((n) => +n.value).reduce(getSum);
          return (getDivision(num, den) * 100).toFixed(2).toString() + ' %';
        })
      );
    }

    console.error(
      `Unknown operation ${derivedValueDef?.operation},no definitions from which to derive a value were provided.`
    );
    return of('');
  }
}
