import { RouterStateModel } from '@app/store-utils/router-state.model';
import { RouterSelectors } from '@app/store-utils/router.selectors';
import { OrgProduct, OrgProductType } from '@main/store/product/product.model';
import { RouterState, RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';

export class ProductSelectors {
  @Selector([RouterState])
  static selectedProduct(state: RouterStateOuterModel<RouterStateModel>): OrgProductType {
    const url = RouterSelectors.url(state);
    if (!url) return;
    let product;
    if (url.startsWith('/quote-rx')) {
      product = OrgProduct.QUOTE_RX;
    } else if (url.startsWith('/quote')) {
      product = OrgProduct.QUOTE;
    } else if (url.startsWith('/smart')) {
      product = OrgProduct.SMART;
    } else if (url.startsWith('/configuration')) {
      product = OrgProduct.RINGMASTER;
    }
    return product;
  }

  @Selector([RouterState])
  static isSmartLinq(state: RouterStateOuterModel<RouterStateModel>): boolean {
    return ProductSelectors.selectedProduct(state) === OrgProduct.SMART;
  }

  @Selector([RouterState])
  static isQuoteLinq(state: RouterStateOuterModel<RouterStateModel>): boolean {
    return ProductSelectors.selectedProduct(state) === OrgProduct.QUOTE;
  }

  @Selector([RouterState])
  static isQuoteLinqRx(state: RouterStateOuterModel<RouterStateModel>): boolean {
    return ProductSelectors.selectedProduct(state) === OrgProduct.QUOTE_RX;
  }

  @Selector([RouterState])
  static isQuoteLinqOrRx(state: RouterStateOuterModel<RouterStateModel>): boolean {
    return this.isQuoteLinq(state) || this.isQuoteLinqRx(state);
  }

  @Selector([RouterState])
  static isAdmin(state: RouterStateOuterModel<RouterStateModel>): boolean {
    return ProductSelectors.selectedProduct(state) === OrgProduct.RINGMASTER;
  }
}
