import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationResponseModel } from '@auth/models/authentication-response.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { SERVER_API_URL } from '@app/app.constants';
import { Store } from '@ngxs/store';
import { Logout } from '@auth/store/auth.actions';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthHttpService {
  private readonly Login = `${SERVER_API_URL}/authentication/login`;
  private readonly Logout = `${SERVER_API_URL}/authentication/logout`;

  constructor(private http: HttpClient, private urlUtil: UrlUtilService, private store: Store) {}

  login(payload) {
    let body;
    if (payload.totp) {
      body = this.urlUtil.buildParamsFromObject({
        username: payload.username,
        password: payload.password,
        totp: payload.totp
      });
    } else if (payload.password) {
      body = this.urlUtil.buildParamsFromObject({
        username: payload.username,
        password: payload.password
      });
    } else {
      body = this.urlUtil.buildParamsFromObject({
        username: payload.username
      });
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return this.http.post<AuthenticationResponseModel>(this.Login, body, httpOptions);
  }

  logout() {
    this.store
      .dispatch(new Logout())
      .pipe(switchMap(() => this.http.post<AuthenticationResponseModel>(this.Logout, null)))
      .subscribe(() => window.location.reload());
  }
}
