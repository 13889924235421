<div
  cdkDropList
  class="grid-gap-2"
  [ngClass]="control.disabled ? 'disabled' : 'enabled'"
  [cdkDropListDisabled]="definition.notOrderable || control.disabled"
  (cdkDropListDropped)="itemOrderChanged($event)"
>
  <p *ngIf="control.controls.length === 0">No values to display.</p>
  <div
    cdkDrag
    class="mat-elevation-z1 p-2 form-array-item"
    arrayItemFormGroup
    *ngFor="let formGroup of control.controls; let i = index"
  >
    <div>
      <button type="button" *ngIf="!definition.notOrderable && control.enabled" mat-icon-button cdkDragHandle>
        <fa-icon [icon]="barsIcon"></fa-icon>
      </button>
    </div>

    <div class="flex-layout mb-3">
      <app-field-renderer
        *ngFor="let def of _definitions"
        class="form-field-renderer"
        fieldLayout
        [control]="formGroup.controls[def.formKey]"
        [definition]="def"
      ></app-field-renderer>
    </div>
    <div>
      <button
        *ngIf="control.enabled && itemsRemovable"
        mat-icon-button
        color="warn"
        type="button"
        class="trash-icon"
        (click)="removeItem(i)"
        matTooltip="Delete"
      >
        <fa-icon [icon]="trashCircleIcon" class="color-rmt-dark-tint-1"></fa-icon>
      </button>
    </div>
  </div>
</div>
