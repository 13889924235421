import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>
      Glossary <span *ngIf="data.label">for: {{ data.label }}</span>
    </app-dialog-label>
    <app-dialog-content>
      <p *ngFor="let item of data.terms">
        <strong>{{ item.term }}</strong
        >: {{ item.definition }}
      </p>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button mat-dialog-close>Close</button>
    </app-dialog-actions>
  `
})
export class GlossaryDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}
