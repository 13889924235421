import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import {
  ExtractAuditModel,
  ExtractTextModel
} from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { QuoteResponseModel } from '@common/models/quote-response.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ExcelExtractGroupModel } from '@main/org-excel-config/model/org-excel-result.model';

@Injectable({
  providedIn: 'root'
})
export class ExtractTextService {
  constructor(private http: HttpClient) {}

  extractRmtXmlText(
    responseId: number,
    pdfFile: File,
    xmlFile: File,
    supportingFile: File,
    storeFiles: boolean = false
  ): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    formData.append('pdfFile', pdfFile, pdfFile.name);
    formData.append('xmlFile', xmlFile, xmlFile.name);
    if (supportingFile) {
      formData.append('supportingFile', supportingFile, supportingFile.name);
    }
    return this.http
      .post<QuoteResponseModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract-rmt?storeFiles=${storeFiles}`,
        formData
      )
      .pipe(
        map((res) => ({
          fields: null,
          extractedObj: res
        }))
      );
  }

  extractDyXmlText(
    responseId: number,
    pdfFile: File,
    xmlFile: File,
    storeFiles: boolean = true
  ): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    formData.append('pdfFile', pdfFile, pdfFile.name);
    formData.append('xmlFile', xmlFile, xmlFile.name);
    return this.http
      .post<QuoteResponseModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract-dy?storeFiles=${storeFiles}`,
        formData
      )
      .pipe(
        map((res) => ({
          fields: null,
          extractedObj: res
        }))
      );
  }

  extractOcrText(responseId: number, templateId: number, fileId: string, file: File): Observable<ExtractAuditModel> {
    if (fileId) {
      return this.http.get<ExtractAuditModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/field-extract/${templateId}?rmtFileId=${fileId}&async=true`
      );
    }

    const formData: FormData = new FormData();
    formData.append('pdfFile', file, file.name);
    return this.http.post<ExtractAuditModel>(
      `${SERVER_API_URL}/quote-response/${responseId}/field-extract/${templateId}?async=true`,
      formData
    );
  }

  getExtractAudit(extractAuditId: number, responseId: number): Observable<ExtractAuditModel> {
    return this.http.get<ExtractAuditModel>(
      `${SERVER_API_URL}/quote-response/${responseId}/extract-audit/${extractAuditId}`
    );
  }

  getExtractOcrResult(extractAuditId: number, responseId: number, file?: File): Observable<ExtractTextModel> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('pdfFile', file, file.name);
    }

    let call = file
      ? this.http.post<OcrModel[]>(
          `${SERVER_API_URL}/quote-response/${responseId}/field-extract-result/${extractAuditId}`,
          formData
        )
      : this.http.get<OcrModel[]>(
          `${SERVER_API_URL}/quote-response/${responseId}/field-extract-result/${extractAuditId}`
        );

    return call.pipe(
      filter((val) => !!val),
      // convert the OCR field data into the shape of an extracted object, it turns the dot referenced fields into objects or arrays
      map((result) => ({ fields: result, extractedObj: this.extractOcrObj(result) }))
    );
  }

  extractExcel(responseId: number, templateId: number, fileId: string, file: File): Observable<ExtractTextModel> {
    let call: Observable<ExcelExtractGroupModel>;
    if (fileId) {
      call = this.http.get<ExcelExtractGroupModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/excel-field-extract/${templateId}?rmtFileId=${fileId}`
      );
    } else {
      const formData: FormData = new FormData();
      formData.append('excelFile', file, file.name);
      call = this.http.post<ExcelExtractGroupModel>(
        `${SERVER_API_URL}/quote-response/${responseId}/excel-field-extract/${templateId}`,
        formData
      );
    }
    return call.pipe(
      (filter((val) => !!val), map((result) => ({ fields: null, extractedObj: this.extractExcelObj(result) })))
    );
  }

  private extractExcelObj(result: ExcelExtractGroupModel): QuoteResponseModel {
    const importObj: QuoteResponseModel = {};
    result.fields.forEach((field) => {
      this.processField(field, importObj);
    });
    this.fillUndefinedOptions(importObj);
    return importObj;
  }

  private extractOcrObj(result: OcrModel[]): QuoteResponseModel {
    const importObj: QuoteResponseModel = {};
    result
      .flatMap((ocrModel) => ocrModel.fields)
      .forEach((field) => {
        this.processField(field, importObj);
      });
    this.fillUndefinedOptions(importObj);
    return importObj;
  }

  private processField(field: { key: string; value: string }, importObj: QuoteResponseModel) {
    field.key.split('.').reduce((obj, fieldName, idx, origArray) => {
      const arrayFound = fieldName.match('(.*)\\[(\\d+)\\]');
      if (arrayFound) {
        const arrayField = arrayFound[1];
        const arrayIndex = arrayFound[2];
        if (!obj[arrayField]) {
          obj[arrayField] = [];
        }
        if (idx === origArray.length - 1) {
          obj[arrayField][arrayIndex] = field.value;
        } else if (!obj[arrayField][arrayIndex]) {
          obj[arrayField][arrayIndex] = {};
        }
        return obj[arrayField][arrayIndex];
      }

      if (idx === origArray.length - 1) {
        obj[fieldName] = field.value;
      } else if (!obj[fieldName]) {
        obj[fieldName] = {};
      }
      return obj[fieldName];
    }, importObj);
  }

  // check all the indices of the quote response options and fill in undefined options
  // it is possible the extract didn't find any value for sequential option numbers and left some undefined objects in the importObj
  // for example, fields only came back for quoteResponseOption[3] and quoteResponseOption[4] that left quoteResponseOption 0, 1, and 2 undefined
  private fillUndefinedOptions(importObj: QuoteResponseModel) {
    if (importObj.quoteResponseOptions) {
      for (let i = 0; i < importObj.quoteResponseOptions.length; i++) {
        if (importObj.quoteResponseOptions[i] === undefined) {
          importObj.quoteResponseOptions[i] = {};
        }
      }
    }
  }
}
