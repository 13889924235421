import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';
import { arrayInRange } from '@app/tools/array';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { DataDefModel } from '@lib-resource/data-def.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { map, startWith } from 'rxjs/operators';
import { Memoize } from '@app/tools/decorators/memoize.decorator';

@Component({
  selector: 'app-table-array',
  templateUrl: './table-array.component.html',
  styleUrls: ['./table-array.component.scss', '../../form-lib.scss'],
  providers: [
    {
      provide: BaseArrayComponent,
      useExisting: forwardRef(() => TableArrayComponent)
    }
  ]
})
export class TableArrayComponent extends BaseArrayComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() hideHeader = false;
  @Input() componentHeight = '100%';
  @ViewChild('scrollFrame') scrollFrame: ElementRef<HTMLDivElement>;
  @ViewChildren('scrollItem') scrollItems: QueryList<ElementRef<HTMLTableHeaderCellElement>>;
  @ViewChild(MatTable, { static: true }) matTable: MatTable<any>;
  @ViewChild(MatHeaderRowDef, { static: true }) headerRowDef: MatHeaderRowDef;
  @ContentChildren(MatHeaderRowDef) headerDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<any>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  optionsColumnDefs: LabelValue[];
  columnsToDisplay$;
  previousIndex; // For drag n' drop
  dataSource: DataDefModel[];
  copyIcon = faCopy;
  trashIcon = faTrashAlt;

  ngOnInit(): void {
    // Note, this makes it a hard limit of 40 columns in the vertical form array.  After 40, the system will start to error.
    //  Business is ok with this, and rethinking the user interaction with 40 columns to scroll through.
    this.optionsColumnDefs = arrayInRange(40, 1).map((i) => ({
      label: `${this.definition.itemPrefix ? this.definition.itemPrefix : 'Item'} ${i}`,
      value: `option_${i}`
    }));
    this.columnsToDisplay$ = this.control.valueChanges.pipe(
      startWith(''),
      map(() => ['tableArrayLabels', ...this.control.controls.map((_, i) => `option_${i + 1}`), 'filler'])
    );
    this._forceRenderChange();
  }

  ngAfterViewInit(): void {
    let scrollItemCount;
    this.scrollItems.changes.subscribe((items) => {
      if (items.length > scrollItemCount) {
        this.scrollFrame.nativeElement.scrollTo({
          left: 10000,
          behavior: 'smooth'
        });
      }
      scrollItemCount = items.length;
    });
  }

  ngAfterContentInit(): void {
    // Content
    this.columnDefs.forEach((value) => this.matTable.addColumnDef(value));
    this.rowDefs.forEach((value) => this.matTable.addRowDef(value));
    this.headerDefs.forEach((value) => this.matTable.addHeaderRowDef(value));

    // View
    if (!this.hideHeader) this.matTable.addHeaderRowDef(this.headerRowDef);
  }

  dragStarted(_, index: number) {
    this.previousIndex = index;
  }

  _forceRenderChange() {
    this.dataSource = this._definitions.map((def) => ({ ...def }));
  }

  reorderControls(event: CdkDragDrop<any[]>, index: number) {
    const movedControl = this.control.at(this.previousIndex);
    this.control.removeAt(this.previousIndex);
    this.control.insert(index, movedControl);
    this.control.markAsDirty();
    this._forceRenderChange();
  }

  @Memoize()
  isArray(classes: any) {
    return Array.isArray(classes);
  }
}
