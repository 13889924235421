import { Injectable, isDevMode } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';
import {
  AsyncSelectFieldComponent,
  CheckboxFieldComponent,
  ColorPickerFieldComponent,
  CurrencyFieldComponent,
  DateFieldComponent,
  DateRangeFieldComponent,
  DateTimeFieldComponent,
  DerivedValueFieldComponent,
  MultiCurrencyFieldComponent,
  MultiInputFieldComponent,
  MultiNumberFieldComponent,
  MultiSelectFieldComponent,
  MultiSelectFilterFieldComponent,
  NumberFieldComponent,
  NumberRangeFieldComponent,
  SelectFieldComponent,
  TelephoneFieldComponent,
  TextareaFieldComponent,
  TextFieldComponent,
  ToggleFieldComponent
} from '../fields';

const fieldMap = {
  boolean: CheckboxFieldComponent,
  numberRange: NumberRangeFieldComponent,
  number: NumberFieldComponent,
  percentage: NumberFieldComponent,
  telephone: TelephoneFieldComponent,
  multiSelect: MultiSelectFieldComponent,
  text: TextFieldComponent,
  textarea: TextareaFieldComponent,
  select: [SelectFieldComponent, AsyncSelectFieldComponent],
  multiInput: MultiInputFieldComponent,
  multiNumber: MultiNumberFieldComponent,
  datepicker: DateFieldComponent,
  checkbox: CheckboxFieldComponent,
  currency: CurrencyFieldComponent,
  multiCurrency: MultiCurrencyFieldComponent,
  multiSelectSet: MultiSelectFilterFieldComponent,
  enum: MultiSelectFieldComponent,
  date: DateFieldComponent,
  dateTime: DateTimeFieldComponent,
  dateRange: DateRangeFieldComponent,
  derivedValue: DerivedValueFieldComponent,
  toggle: ToggleFieldComponent,
  colorpicker: ColorPickerFieldComponent
};

@Injectable()
export class FieldComponentMapService {
  getComponentFor(key: string, async?: boolean, definition?: DataDefModel) {
    const component = definition?.layout?.toggle ? fieldMap.toggle : fieldMap[key];

    if (!component && isDevMode()) {
      console.error(`No component was found for ${key}.`);
    }
    if (key === 'select') {
      return async ? component[1] : component[0];
    }
    return component;
  }
}
