import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, isDevMode } from '@angular/core';

import { NotificationService } from '@shared/notifications/notification.service';
import { VERSION } from 'environments/version';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const minuteInMilliseconds = 60 * 1000;
@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  notificationOn = false;
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (!isDevMode() && event instanceof HttpResponse) {
          const apiVersionHash = event.headers.get('rmt-version-hash');
          if (!!apiVersionHash && apiVersionHash !== VERSION.hash.substring(0, 9)) {
            // Only use 1st 9 chars of VERSION.hash to match server side
            if (!this.notificationOn) {
              const notificationService = this.injector.get(NotificationService);
              this.notificationOn = true;
              notificationService
                .actionNotification({
                  message: 'A new version of the app is available',
                  action: () => location.reload(),
                  actionText: 'Refresh',
                  duration: minuteInMilliseconds * 1200 // 20 hrs
                })
                .afterDismissed()
                .subscribe(() => {
                  setTimeout(() => (this.notificationOn = false), minuteInMilliseconds * 15);
                });
            }
          }
        }
      })
    );
  }
}
