export class GitInfoModel {
  constructor(
    public commit: {
      time: string;
      id: string;
    },
    public branch: string
  ) {}
}

export class ServerInfoModel {
  constructor(
    public app: {
      maxUploadBytes: number;
    },
    public git: GitInfoModel
  ) {}
}
