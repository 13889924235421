import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceStateModel } from '@app/store-utils/resource-state.model';
import { resourceFailure, resourceGet, resourceSuccess } from '@app/store-utils/state.utils';
import { UserModel } from '@auth/models/user.model';
import { AuthSelectors } from '@auth/store/auth.selectors';
import { ApiConstants } from '@common/models/api-constants.model';
import { User } from '@main/store/user/user.actions';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, tap } from 'rxjs/operators';

export class UserStoreModel {
  userResource?: ResourceStateModel<UserModel>;
}

const userStoreModelDefault: UserStoreModel = {};

@State<UserStoreModel>({ name: 'user', defaults: userStoreModelDefault })
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(private http: HttpClient, private store: Store) {}

  ngxsOnInit(ctx: StateContext<UserStoreModel>) {
    const authToken = this.store.selectSnapshot(AuthSelectors.token);
    if (authToken) ctx.dispatch(new User.Get());
  }

  @Action(User.Get)
  getUser(ctx: StateContext<UserStoreModel>) {
    const state = ctx.getState();
    ctx.patchState({
      userResource: resourceGet<UserModel>(state?.userResource)
    });
    return this.http.get<UserModel>(ApiConstants.UserAccount).pipe(
      map((activeUser) => activeUser),
      tap((val) => ctx.dispatch(new User.GetSuccess(val))),
      catchError((err) => ctx.dispatch(new User.GetFailure(err)))
    );
  }

  @Action(User.GetFailure)
  getUserFailure(ctx: StateContext<UserStoreModel>, action: User.GetFailure) {
    const state = ctx.getState();

    ctx.patchState({
      userResource: resourceFailure<UserModel>(action.error, state.userResource)
    });
  }

  @Action(User.GetSuccess)
  getUserSuccess(ctx: StateContext<UserStoreModel>, action: User.GetSuccess) {
    ctx.patchState({
      userResource: resourceSuccess<UserModel>(action.user)
    });
  }

  @Action(User.Reset)
  resetUser(ctx: StateContext<UserStoreModel>) {
    ctx.setState(userStoreModelDefault);
  }
}
