<div class="mt-2 collapsible-form dense">
  <form *ngIf="!!form" [formGroup]="form">
    <div *ngIf="!uiLasers?.length" class="pl-2">No Lasers.</div>
    <div formArrayName="lasers" class="pb-2">
      <div *ngFor="let laserFormGroup of laserFormGroups; let i = index" class="laser-card mb-4">
        <div class="laser-card__header">
          <div class="laser-card__header-name">Individual Name/ID*</div>
          <app-text-field
            [control]="laserFormGroup.controls['name']"
            [dataDef]="nameDataDef"
            [inlineNoLabel]="true"
            [readOnly]="readOnly"
            [ngClass]="{ 'field-disable': readOnly }"
            style="padding-top: 1.5rem"
          ></app-text-field>
          <div class="laser-card__header-close">
            <button *ngIf="!readOnly" (click)="remove(i)" type="button" mat-icon-button matTooltip="Remove">
              <fa-icon [icon]="removeIcon"></fa-icon>
            </button>
          </div>
          <div class="laser-card__header-name">Relationship</div>
          <app-select-field
            [control]="laserFormGroup.controls['relationship']"
            [dataDef]="relationshipDataDef"
            [inlineNoLabel]="true"
            [readOnly]="readOnly"
            [ngClass]="{ 'field-disable': readOnly }"
            style="padding-top: 1.5rem"
          ></app-select-field>
        </div>
        <div class="p-3">
          <app-laser-array
            [control]="laserFormGroup.controls['options']"
            [definition]="laserUiOptionDataDef"
            [readOnly]="readOnly"
          ></app-laser-array>
          <div class="ml-1 mt-2">
            <app-toggle-field
              [control]="laserFormGroup.controls['conditional']"
              [dataDef]="conditionalDataDef"
              [readOnly]="readOnly"
            >
            </app-toggle-field>
          </div>
          <div class="ml-1 mt-2">
            <app-textarea-field
              [control]="laserFormGroup.controls['condition']"
              [dataDef]="conditionDataDef"
              [readOnly]="readOnly"
            >
            </app-textarea-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
