import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ColumnHeader, ColumnType } from '@common/models/csv-import.model';
import { csv2YearDateStrValid, csv4YearDateStrValid } from '@app/tools/date';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { csvNumber } from '@app/tools/string';
import { Memoize } from '@app/tools/decorators/memoize.decorator';

@Component({
  selector: 'app-csv-column-mapper',
  templateUrl: './csv-column-mapper.component.html',
  styleUrls: ['./csv-column-mapper.component.scss']
})
export class CsvColumnMapperComponent implements OnInit, OnChanges {
  @Input() csvData: string[][];
  @Input() hasHeader: boolean;
  @Input() systemFields: ColumnHeader[];
  @Input() csvColumnValues: string[];

  @Output() columnMapped = new EventEmitter<{
    columnsMapped: string[];
    fieldsValid: boolean;
    twoDigitYearAfter2k: string[];
  }>();

  formArray = new UntypedFormArray([]);
  csvOptions: string[];
  sampleData: string[];
  sampleDataWithoutHeader: string[];
  oneOfRequiredGroupNames: string[];
  oneOfRequiredByName: Map<string, UntypedFormControl[]> = new Map<string, UntypedFormControl[]>();

  onlyRequiredFields: boolean = true;
  otherFieldCount: number = 0;

  toggle2YearDate: Map<string, UntypedFormControl> = new Map<string, UntypedFormControl>();
  twoDigitYearAfter2k = new Set<string>();

  ngOnInit() {
    this.oneOfRequiredGroupNames = [];
    this.systemFields.forEach((val) => {
      if (val.oneOfRequiredGroupName && this.oneOfRequiredGroupNames.indexOf(val.oneOfRequiredGroupName) === -1) {
        this.oneOfRequiredGroupNames.push(val.oneOfRequiredGroupName);
      }
      const validatorFn: ValidatorFn = (control) => {
        if (control.value !== null && control.value !== undefined) {
          if (
            val.type === ColumnType.DATE &&
            this.twoDigitYearAfter2k.has(val.key) &&
            !this.valid2YearDate(control.value)
          ) {
            return { dateformat2year: 'needs 2k year date' };
          }
          if (
            val.type === ColumnType.DATE &&
            !this.twoDigitYearAfter2k.has(val.key) &&
            !this.valid4YearDate(control.value)
          ) {
            return { dateformat4year: 'needs 4k year date' };
          }
          if (val.type === ColumnType.NUMBER && !this.validNumber(control.value, val.minMaxValue)) {
            return { numeric: 'needs numeric' };
          }
          if (val.requireValue && !this.populatedValues(control.value)) {
            return { requirevalue: 'needs value' };
          }
        }
        // if this is a oneOfRequired validated field
        if (val.oneOfRequiredGroupName) {
          // check to see if all fields are null, if so, mark all required
          if (
            this.oneOfRequiredByName
              .get(val.oneOfRequiredGroupName)
              ?.filter((control) => control.value !== null && control.value !== undefined && control.value !== '-1')
              .length === 0
          ) {
            this.oneOfRequiredByName
              .get(val.oneOfRequiredGroupName)
              ?.filter((c) => c !== control)
              .forEach((c) => {
                if (c.errors) {
                  c.setErrors({ ...c.errors, oneOfRequired: 'one of required' });
                } else {
                  c.setErrors({ oneOfRequired: 'one of required' });
                }
                c.markAsTouched();
              });
            return { oneOfRequired: 'one of required' };
          }

          // clear the errors from the others
          this.oneOfRequiredByName.get(val.oneOfRequiredGroupName)?.forEach((c) => {
            if (c.errors?.oneOfRequired) {
              const err = c.errors;
              delete err.oneOfRequired;
              if (Object.keys(err).length > 0) {
                c.setErrors(err);
              } else {
                c.setErrors(null);
              }
            }
          });
        }
        return null;
      };
      const validatorFns = [validatorFn];
      if (!val.required) {
        this.otherFieldCount++;
      }
      if (val.required && !val.oneOfRequiredGroupName) {
        validatorFns.push(Validators.required);
      }
      const formControl = new UntypedFormControl(
        val.required && !val.oneOfRequiredGroupName ? null : '-1',
        validatorFns
      );
      if (val.oneOfRequiredGroupName) {
        // keep track of form controls for group level validation
        if (!this.oneOfRequiredByName.has(val.oneOfRequiredGroupName)) {
          this.oneOfRequiredByName.set(val.oneOfRequiredGroupName, []);
        }
        this.oneOfRequiredByName.get(val.oneOfRequiredGroupName).push(formControl);
      }
      this.formArray.push(formControl);
      if (val.type === ColumnType.DATE) {
        this.toggle2YearDate.set(val.key, new UntypedFormControl());
      }
    });
  }

  private validNumber(value: any, minMaxVal: number): boolean {
    const idx = +value;
    if (idx > -1) {
      for (let x = this.hasHeader ? 1 : 0; x < this.csvData?.length; x++) {
        if (!csvNumber(this.csvData[x][idx], minMaxVal)) {
          return false;
        }
      }
    }
    return true;
  }

  private valid2YearDate(value: any): boolean {
    const idx = +value;
    if (idx > -1) {
      for (let x = this.hasHeader ? 1 : 0; x < this.csvData?.length; x++) {
        if (this.csvData[x][idx] && !csv2YearDateStrValid(this.csvData[x][idx])) {
          return false;
        }
      }
    }
    return true;
  }

  private valid4YearDate(value: any): boolean {
    const idx = +value;
    if (idx > -1) {
      for (let x = this.hasHeader ? 1 : 0; x < this.csvData?.length; x++) {
        if (this.csvData[x][idx] && !csv4YearDateStrValid(this.csvData[x][idx])) {
          return false;
        }
      }
    }
    return true;
  }

  private populatedValues(value: any): boolean {
    const idx = +value;
    if (idx > -1) {
      for (let x = this.hasHeader ? 1 : 0; x < this.csvData?.length; x++) {
        if (this.csvData[x][idx] === '') {
          // all records  must have a value
          return false;
        }
      }
    }
    return true;
  }

  ngOnChanges({ csvData, csvColumnValues, hasHeader }: SimpleChanges): void {
    // the values are changed outside this component -- i.e. loading them from a saved template
    if (csvColumnValues && csvColumnValues.currentValue) {
      csvColumnValues.currentValue.forEach((val, index) => {
        const controlIndex = this.systemFields.findIndex((systemField) => systemField.key === val);
        if (controlIndex > -1) {
          const control = this.formArray.controls[controlIndex];
          control?.setValue(index);
        }
      });
    }
    if (csvData && csvData.currentValue) {
      this.sampleData = [];
      for (let x = 0; x < csvData.currentValue[0]?.length; x++) {
        this.sampleData[x] = csvData.currentValue.map((row) => row[x]).join(', ');
      }
      this.sampleDataWithoutHeader = this.sampleData.map((sample) => sample.substring(sample.indexOf(', ') + 2));
      this.setOptions(hasHeader ? hasHeader.currentValue : this.hasHeader, csvData.currentValue);
      this.formArray.patchValue(this.systemFields.map((f) => (f.required && !f.oneOfRequiredGroupName ? null : '-1'))); // set all non-required to IGNORE
    }

    if (hasHeader) {
      const data = csvData && csvData.currentValue ? csvData.currentValue : this.csvData;
      if (data) {
        this.setOptions(hasHeader ? hasHeader.currentValue : this.hasHeader, data);
        this.formArray.controls.forEach((c) => c.updateValueAndValidity());
        // since the form validity changed, emit the value
        this.columnMapped.emit({
          columnsMapped: this.csvColumnValues,
          fieldsValid: this.formArray.valid,
          twoDigitYearAfter2k: Array.from(this.twoDigitYearAfter2k)
        });
      }
    }
    this.formArray.markAllAsTouched();
  }

  setCsvIndexToSystemField(index: string, column: string) {
    // clear the existing control if set to this value
    if (this.csvColumnValues[+index] !== 'IGNORE') {
      for (let x = 0; x < this.systemFields.length; x++) {
        if (this.systemFields[x].key === this.csvColumnValues[+index]) {
          this.formArray.controls[x].patchValue(
            this.systemFields[x].required && !this.systemFields[x].oneOfRequiredGroupName ? null : '-1'
          );
          this.formArray.controls[x].updateValueAndValidity();
          break;
        }
      }
    }
    this.csvColumnValues.forEach((csvColumnValue, i) => {
      if (csvColumnValue === column) {
        this.csvColumnValues[i] = 'IGNORE';
      }
    });
    if (index !== '-1') {
      this.csvColumnValues[index] = column;
    }
    this.columnMapped.emit({
      columnsMapped: this.csvColumnValues,
      fieldsValid: this.formArray.valid,
      twoDigitYearAfter2k: Array.from(this.twoDigitYearAfter2k)
    });
  }

  private setOptions(firstRowIsHeader: boolean, data: string[][]) {
    this.csvOptions = firstRowIsHeader ? data[0].map((s) => s) : data[0].map((_, index) => `CSV Field ${index + 1}`);
  }

  mapError(errors: ValidationErrors): string {
    if (errors.required) {
      return 'This field is required.';
    }

    if (errors.numeric) {
      return 'Number required.';
    }

    if (errors.dateformat2year) {
      return 'Invalid date format; requires 2 digit year';
    }

    if (errors.dateformat4year) {
      return 'Invalid date format; requires 4 digit year';
    }

    if (errors.oneOfRequired) {
      return 'This field is required.';
    }

    if (errors.requirevalue) {
      return 'Empty values in field not allowed.';
    }
  }

  year2kChange(key: string, control: AbstractControl, event: MatSlideToggleChange) {
    if (event.checked) {
      this.twoDigitYearAfter2k.add(key);
    } else {
      this.twoDigitYearAfter2k.delete(key);
    }
    control.updateValueAndValidity();
    this.columnMapped.emit({
      columnsMapped: this.csvColumnValues,
      fieldsValid: this.formArray.valid,
      twoDigitYearAfter2k: Array.from(this.twoDigitYearAfter2k)
    });
  }

  @Memoize()
  isDateType(type: ColumnType) {
    return type === ColumnType.DATE;
  }
}
