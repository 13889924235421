<div class="table-layout mt-4 mb-4 dense csv-column-mapper" *ngIf="csvData && csvData.length">
  <div class="mat-header-cell">System Field</div>
  <div class="mat-header-cell">File Field Name</div>
  <div class="mat-header-cell pl-3">File Example Data</div>
  <div class="line"></div>
  <ng-container *ngFor="let systemField of systemFields; let i = index">
    <ng-container
      *ngIf="systemField.required && !systemField.oneOfRequiredGroupName && formArray.controls[i] as control"
    >
      <div class="label-text text-no-overflow">
        {{ systemField.displayName }} <span *ngIf="systemField.required">*</span>
      </div>
      <div class="csv-mapper-form-field">
        <mat-form-field class="map-select-field">
          <mat-select
            [formControl]="control"
            (selectionChange)="setCsvIndexToSystemField($event.value, systemField.key)"
            [required]="systemField.required"
          >
            <mat-option *ngFor="let csvOption of csvOptions; let optionIndex = index" [value]="optionIndex">
              {{ csvOption }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="control.errors" truncateText>
            {{ mapError(control.errors) }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="pl-3 example-text">
        {{ hasHeader ? sampleDataWithoutHeader[control.value] : sampleData[control.value] }}
      </div>
      <ng-container *ngIf="isDateType(systemField.type) && toggle2YearDate.get(systemField.key)">
        <div></div>
        <div class="mapping-toggle">
          <mat-slide-toggle
            [formControl]="toggle2YearDate.get(systemField.key)"
            (change)="year2kChange(systemField.key, control, $event)"
            >2 digit year >= 2000</mat-slide-toggle
          >
        </div>
        <div></div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngFor="let oneOfRequiredGroupName of oneOfRequiredGroupNames">
    <div class="instruction-text mt-3 mb-3">At least one of the following fields is required.</div>
    <div class="mat-header-cell">System Field</div>
    <div class="mat-header-cell">File Field Name</div>
    <div class="mat-header-cell pl-3">File Example Data</div>
    <div class="line"></div>
    <ng-container *ngFor="let systemField of systemFields; let i = index">
      <ng-container
        *ngIf="
          systemField.required &&
          systemField.oneOfRequiredGroupName === oneOfRequiredGroupName &&
          formArray.controls[i] as control
        "
      >
        <div class="label-text text-no-overflow">
          {{ systemField.displayName }} <span *ngIf="systemField.required">*</span>
        </div>
        <div class="csv-mapper-form-field">
          <mat-form-field class="map-select-field">
            <mat-select
              [formControl]="control"
              (selectionChange)="setCsvIndexToSystemField($event.value, systemField.key)"
            >
              <mat-option value="-1" selected>-- IGNORE --</mat-option>
              <mat-option *ngFor="let csvOption of csvOptions; let optionIndex = index" [value]="optionIndex">
                {{ csvOption }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="control.errors">
              {{ mapError(control.errors) }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="pl-3 example-text">
          {{ hasHeader ? sampleDataWithoutHeader[control.value] : sampleData[control.value] }}
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="otherFieldCount > 0 && onlyRequiredFields; else allFields">
    <div class="table-span-3">
      <a class="more-less-fields-link" (click)="onlyRequiredFields = false">More fields</a>
    </div>
  </ng-container>
  <ng-template #allFields>
    <ng-container *ngIf="otherFieldCount > 0">
      <div class="instruction-text mt-3 mb-3"></div>
      <div class="mat-header-cell">System Field</div>
      <div class="mat-header-cell">File Field Name</div>
      <div class="mat-header-cell pl-3">File Example Data</div>
      <div class="line"></div>
      <ng-container *ngFor="let systemField of systemFields; let i = index">
        <ng-container *ngIf="!systemField.required && formArray.controls[i] as control">
          <div class="label-text text-no-overflow">
            {{ systemField.displayName }} <span *ngIf="systemField.required">*</span>
          </div>
          <div class="csv-mapper-form-field">
            <mat-form-field class="map-select-field">
              <mat-select
                [formControl]="control"
                (selectionChange)="setCsvIndexToSystemField($event.value, systemField.key)"
                [required]="systemField.required"
              >
                <mat-option *ngIf="!systemField.required" value="-1" selected>-- IGNORE --</mat-option>
                <mat-option *ngFor="let csvOption of csvOptions; let optionIndex = index" [value]="optionIndex">
                  {{ csvOption }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="control.errors">
                {{ mapError(control.errors) }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="pl-3 example-text">
            {{ hasHeader ? sampleDataWithoutHeader[control.value] : sampleData[control.value] }}
          </div>
          <ng-container *ngIf="isDateType(systemField.type) && toggle2YearDate.get(systemField.key)">
            <div></div>
            <div class="mapping-toggle">
              <mat-slide-toggle
                [formControl]="toggle2YearDate.get(systemField.key)"
                (change)="year2kChange(systemField.key, control, $event)"
                >2 digit year >= 2000</mat-slide-toggle
              >
            </div>
            <div></div>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="table-span-3">
        <a class="more-less-fields-link" (click)="onlyRequiredFields = true">Less fields</a>
      </div>
    </ng-container>
  </ng-template>
</div>
