import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * This wraps the Angular currency pipe in order to catch errors and instead return the value provided
 * */

@Pipe({
  name: 'appPercent'
})
export class AppPercentPipe extends PercentPipe implements PipeTransform {
  transform(value: any, digitsInfo?: string, locale?: string) {
    if (value === null || value === undefined) {
      return null;
    }
    try {
      return super.transform(value / 100, digitsInfo, locale);
    } catch (error) {
      return value;
    }
  }
}
