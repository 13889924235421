import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { ImportTemplateModel, ImportType } from '@common/models/csv-import.model';

@Injectable({
  providedIn: 'root'
})
export class ImportMappingTemplateService {
  get baseUrl() {
    return `${SERVER_API_URL}/quote-request`;
  }

  constructor(protected http: HttpClient) {}

  getImportMappingTemplate(importType: ImportType) {
    const params = new HttpParams({
      fromObject: {
        importType: importType
      }
    });

    return this.http.get<ImportTemplateModel[]>(`${SERVER_API_URL}/import-mapping-template`, {
      params
    });
  }

  saveImportMappingTemplate(template: ImportTemplateModel, importType: ImportType) {
    return this.http.post<ImportTemplateModel>(`${SERVER_API_URL}/import-mapping-template`, {
      ...template,
      importType: importType
    });
  }
}
