import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { AggResultGroupModel } from '@common/models/agg.model';
import { FindRequestModel, StatsRequestModel } from '@common/models/find-request.model';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { FilterConfig, HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { WorkStatusPeriod, WorkStatusRequiredDateRange } from '@smart/component/filing/model/work-status.model';
import { SlParticipantComment } from '@smart/component/member/model/sl-participant-comment.model';
import { SLParticipantModel } from '@smart/component/member/model/sl-participant.model';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';

const siteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'accountId'
  },
  {
    siteFilterKey: SiteFilterOptions.POLICY,
    dataKey: 'policyid'
  }
];

@Injectable({
  providedIn: 'root'
})
export class SLParticipantService {
  constructor(
    protected http: HttpClient,
    private urlUtil: UrlUtilService,
    private httpResource: HttpResourceService
  ) {}

  getById(id: number): Observable<SLParticipantModel> {
    return this.httpResource.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.get(`${SERVER_API_URL}/org/${orgIds[0]}/slparticipant/${id}`))
    );
  }

  getFamily(parentId: number): Observable<Page<SLParticipantModel>> {
    return this.httpResource.query<Page<SLParticipantModel>>({
      path: 'slparticipant',
      query: {
        filterString: `parentId = ${parentId}`,
        pageIndex: 0,
        pageSize: 100,
        sort: ['relationship']
      }
    });
  }

  search(
    filterString: string,
    pageSize: number,
    siteFilterConfigOverride?: FilterConfig[],
    pageIndex: number = 0,
    sort: string[] = []
  ): Observable<Page<SLParticipantModel>> {
    return this.httpResource.query({
      path: 'slparticipant',
      query: { filterString, pageSize, pageIndex, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
    });
  }

  searchWithOrg(
    orgId: number,
    filterString: string,
    pageSize: number,
    siteFilterConfigOverride?: FilterConfig[],
    pageIndex: number = 0,
    sort: string[] = []
  ): Observable<Page<SLParticipantModel>> {
    return this.httpResource.queryWithoutOrg({
      path: `org/${orgId}/slparticipant`,
      query: { filterString, pageSize, pageIndex, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
    });
  }

  searchAgg(findReq: FindRequestModel): Observable<Page<SLParticipantModel>> {
    return this.httpResource.queryAgg({ path: 'slparticipant', findReq, siteFilterConfig });
  }

  searchStats(req: StatsRequestModel): Observable<AggResultGroupModel> {
    return combineLatest([this.httpResource.orgMembership$, this.httpResource.policy$]).pipe(
      mergeMap(([orgIds, policy]) => {
        if (policy) {
          req.policyIds = [...req.policyIds, ...policy.map((p) => p.id)];
        }
        return this.http.get(`${SERVER_API_URL}/org/${orgIds[0]}/slparticipant/workstatus/stats-by-time`, {
          params: this.urlUtil.buildStatsRequest(req)
        });
      })
    );
  }

  update(participantId: number, value: SLParticipantModel) {
    return this.httpResource.orgMembership$.pipe(
      take(1),
      switchMap((orgIds) => this.http.put(`${SERVER_API_URL}/org/${orgIds[0]}/slparticipant/${participantId}`, value))
    );
  }

  delete(participantId: number): Observable<any> {
    return this.httpResource.delete(`slparticipant/${participantId}`);
  }

  getWorkStatus(participantId: number) {
    return this.httpResource.get(`slparticipant/${participantId}/workstatusrequest`);
  }

  getWorkStatusRequiredDateRange(participantId: number): Observable<WorkStatusRequiredDateRange> {
    return this.httpResource.get<WorkStatusRequiredDateRange>(
      `slparticipant/${participantId}/workstatusrequest/daterange`
    );
  }

  getWorkStatusPeriods(participantId: number): Observable<WorkStatusPeriod[]> {
    return this.httpResource
      .get<WorkStatusPeriod[]>(`slparticipant/${participantId}/workstatusperiod`)
      .pipe(shareReplay());
  }

  sendWorkStatusRequest(participantId: number, email: string) {
    return this.httpResource.post(`slparticipant/${participantId}/workstatusrequest`, { email });
  }

  resendWorkStatusRequest(participantId: number, workStatusRequestId: number) {
    return this.httpResource.put(`slparticipant/${participantId}/workstatusrequest/${workStatusRequestId}`);
  }

  revokeWorkStatusRequestAccess(participantId: number, workStatusRequestId: number) {
    return this.httpResource.delete(`slparticipant/${participantId}/workstatusrequest/${workStatusRequestId}`);
  }

  downloadWorkStatusPeriodFile(participantId: number, workStatusPeriodId: number, fileId: string, fileName: string) {
    return this.httpResource.downloadFile(
      {
        path: `slparticipant/${participantId}/workstatusperiod/${workStatusPeriodId}/workstatusfile/${fileId}/stream`
      },
      { fileName }
    );
  }

  getComments(participantId: number, commentType?: string): Observable<SlParticipantComment[]> {
    if (commentType) {
      return this.httpResource.get<SlParticipantComment[]>(
        `slparticipant/${participantId}/comment/type/${commentType}`
      );
    }
    return this.httpResource.get<SlParticipantComment[]>(`slparticipant/${participantId}/comment`);
  }

  getCommentsOldestFirst(participantId: number, commentType?: string): Observable<SlParticipantComment[]> {
    return this.getComments(participantId, commentType).pipe(map((comments) => comments.reverse()));
  }

  addComment(participantId: number, comment: SlParticipantComment) {
    return this.httpResource.post(`slparticipant/${participantId}/comment`, comment);
  }

  updateComment(participantId: number, comment: SlParticipantComment) {
    return this.httpResource.put(`slparticipant/${participantId}/comment/${comment.id}`, comment);
  }

  deleteComment(participantId: number, commentId: number) {
    return this.httpResource.delete(`slparticipant/${participantId}/comment/${commentId}`);
  }

  clearEligibilityPendForMember(participantId: number, pendReason) {
    return this.httpResource.put(`slparticipant/${participantId}/clearPend/${pendReason}`);
  }

  addEligibilityPendForMember(participantId: number, pendReason) {
    return this.httpResource.put(`slparticipant/${participantId}/addPend/${pendReason}`);
  }
}
