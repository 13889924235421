import { Injectable } from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { SLParticipantService } from '@smart/component/member/service/sl-participant.service';
import { PolicyTierModel } from '@smart/component/policy/model/policy-tier.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyHttpService } from './policy-http.service';

@Injectable()
export class PolicyTierOptionService extends AsyncOptions<string> {
  key = 'policyTier';
  defaultSort = [];

  constructor(private policyService: PolicyHttpService, private slParticipantService: SLParticipantService) {
    super();
  }

  filter = (control, asyncOptionDeps, filterValue, pageIndex, pageSize, _?, requiredQuery?, asyncExtras?) =>
    this.policyService
      .getPolicyTiers(asyncExtras.policyId)
      .pipe(map((result) => ({ content: this.generateLabelValues(result), total: result.length })));

  valuesFromKeys = (values) => of(!values?.length ? null : this.generateLabelValues(values));

  generateLabelValues(policyTiers: PolicyTierModel[]): LabelValue<string, string>[] {
    if (!policyTiers?.length) return [];
    return policyTiers.map((a) => ({
      label: a.label,
      value: a.ratePlan
    }));
  }
}
