import { Inject, Injectable, Optional } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms';
import { getDateFromString } from '@app/tools/date';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { getValue, replaceInvalidKeyChars } from '@lib-resource/data.utils';
import { AsyncFormValidatorDef, DYNAMIC_VALIDATORS, FormValidatorDef } from '../models/validators.model';
import {
  mapAsyncFormValidators,
  mapAsyncValidators,
  mapFieldValidators,
  mapFormArrayDisablers,
  mapFormValidators
} from '../validators/validators.utils';

@Injectable({
  providedIn: 'root'
})
export class FormGeneratorService {
  constructor(
    private fb: UntypedFormBuilder,
    @Optional() @Inject(DYNAMIC_VALIDATORS) private asyncValidatorFns: AsyncValidatorFn[]
  ) {}

  generateFormGroup(
    dataDefs: DataDefModel[],
    validators?: Array<ValidatorFn | FormValidatorDef>,
    asyncValidators?: Array<AsyncValidatorFn | AsyncFormValidatorDef>,
    data = null
  ): UntypedFormGroup {
    const formControls = {};
    data = data ? data : {};
    const defs = [...dataDefs];
    // pull out 'joined field' definitions so they go through the same process as the rest of the controls
    dataDefs.filter((dd) => !!dd.joinedField).forEach((dd) => defs.push(dd.joinedField));

    defs.forEach((definition) => {
      // forms cannot have dots in the keys, replace with a forward slash.  Do this here as it is the only place needed, forms
      if (!definition.formKey) {
        definition = { ...definition, formKey: replaceInvalidKeyChars(definition.key) };
      }
      const value = getValue(data, definition.key);
      // if this definition defines an optionsKey, that means the list of available options
      // for the select is provided by the model object. The optionsKey specifies the
      // definition of the model object that contains the array of LabelValue[] options.

      if (definition.optionsKey) {
        definition.options = data[definition.optionsKey];
      }

      if (definition.type === DATA_TYPES.formGroupArray) {
        const formGroupArray = [];
        if (value && value.length) {
          const definitions = definition.definitions ? definition.definitions : (definition as any).fields;
          value.forEach((datum) => {
            formGroupArray.push(this.generateFormGroup(definitions, undefined, undefined, datum));
          });
        }
        formControls[definition.formKey] = this.fb.array(formGroupArray, [
          ...mapFieldValidators(definition.validators),
          ...mapFormArrayDisablers(definition.disablers)
        ]);
      } else if (definition.type === DATA_TYPES.date || definition.type === DATA_TYPES.datepicker) {
        formControls[definition.formKey] = this.fb.control(
          getDateFromString(value),
          mapFieldValidators(definition.validators),
          mapAsyncValidators(definition, data, this.asyncValidatorFns)
        );
      } else if (definition.type === DATA_TYPES.dateRange) {
        formControls[`${definition.formKey}/startDate`] = this.fb.control(
          getDateFromString(value?.startDate),
          mapFieldValidators(definition.validators),
          mapAsyncValidators(definition, data, this.asyncValidatorFns)
        );
        formControls[`${definition.formKey}/endDate`] = this.fb.control(
          getDateFromString(value?.endDate),
          mapFieldValidators(definition.validators),
          mapAsyncValidators(definition, data, this.asyncValidatorFns)
        );
      } else {
        formControls[definition.formKey] = this.fb.control(
          value,
          mapFieldValidators(definition.validators),
          mapAsyncValidators(definition, data, this.asyncValidatorFns)
        );
      }

      // Keep control disabled if readOnly is true
      if (definition.readOnly) {
        formControls[definition.formKey].registerOnDisabledChange((isDisabled) => {
          if (!isDisabled) {
            formControls[definition.formKey].disable();
          }
        });
        // Init in disabled state
        formControls[definition.formKey].disable();
      }

      if (definition.notCloneable === true) {
        formControls[definition.formKey].value = null;
      }
    });

    const formGroupOptions: AbstractControlOptions = {};
    if (validators) {
      formGroupOptions.validators = mapFormValidators(validators as any);
    }
    if (asyncValidators) {
      formGroupOptions.asyncValidators = mapAsyncFormValidators(asyncValidators as any);
    }
    return this.fb.group(formControls, formGroupOptions);
  }

  setFormValue(definitions: DataDefModel[], control: AbstractControl, data: object): void {
    const formBuilder = new UntypedFormBuilder();
    definitions.forEach((def) => {
      let value = getValue(data, def.key);
      const currentControl = control instanceof UntypedFormGroup ? control.controls[def.formKey] : control;
      if (def.type === DATA_TYPES.formGroupArray) {
        if (value === undefined || value === null) {
          value = [];
        }
        if (!Array.isArray(value)) {
          console.error(`Value for '${def.key}' is not an array.`);
        } else {
          const formArrayControl = currentControl as UntypedFormArray;
          value.forEach((val, idx) => {
            const childControl = formArrayControl.at(idx);
            const defs = def.definitions ? def.definitions : (def as any).fields;
            if (childControl) {
              this.setFormValue(defs, childControl, val);
            } else {
              const config = defs.reduce((acc, field) => {
                acc[field.key] = formBuilder.control(
                  val[field.key],
                  mapFieldValidators(field.validators),
                  mapAsyncValidators(field, data, this.asyncValidatorFns)
                );
                return acc;
              }, {});
              const group = formBuilder.group(config);
              if (control.disabled) {
                group.disable();
              }
              formArrayControl.insert(idx, group);
            }
          });
          while (formArrayControl.controls.length > value.length) {
            formArrayControl.removeAt(formArrayControl.controls.length - 1);
          }
        }
      } else if (def.type === DATA_TYPES.date || def.type === DATA_TYPES.datepicker) {
        currentControl.setValue(getDateFromString(value));
      } else if (def.toggleOptions) {
        // the toggle needed a little help with being intelligent.  When the reset occurs and the value doesn't need to be changed then don't
        // For whatever reason, regardless of the value set, it would always move the toggle to 'on/true'
        if (currentControl.value !== value) {
          currentControl.setValue(value);
        }
      } else if (def.type === DATA_TYPES.dateRange) {
        // the date range type needs some help when a reset occurs
        // this value should be {startDate: <val>, endDate: <val>}.  A date range type will have two fields in the form formKey/startDate and formKey/endDate
        const startDateControl = (control as UntypedFormGroup).controls[`${def.formKey}/startDate`];
        const endDateControl = (control as UntypedFormGroup).controls[`${def.formKey}/endDate`];
        startDateControl?.setValue(getDateFromString(value?.startDate));
        endDateControl?.setValue(getDateFromString(value?.endDate));
      } else {
        currentControl.setValue(value);
      }
    });
  }
}
