<div class="tags-display-card-row">
  <button
    *ngIf="!!displayTagIcon"
    [disabled]="disableEdit"
    mat-icon-button
    class="tags-button color-rmt-dark-tint-1"
    matTooltip="Apply Tags"
    (click)="openTagSelectorDialog()"
  >
    <fa-icon [icon]="tagsIcon" size="lg"></fa-icon>
  </button>
  <div *ngIf="!displayTagIcon" class="label">Tags:</div>
  <div>
    <div class="tag-chips">
      <app-rmt-chip *ngFor="let tag of tags" [displayLabel]="tag?.name"></app-rmt-chip>
    </div>
  </div>
</div>
