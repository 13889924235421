import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CurrencyFormatterDirective } from '@shared/directives/currency-formatter/currency-formatter.directive';
import { Subscription } from 'rxjs';
import { setAndPreserveErrors } from '@form-lib/validators/form.validators';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-currency-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        *ngIf="inline"
      >
        {{ label }}</span
      >
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <input
          matInput
          #input
          (input)="_valueChanged(input.value)"
          [formControl]="control"
          [required]="required"
          [readonly]="readOnly"
          (focus)="isFocused = true"
          (blur)="isFocused = false"
          onkeydown="return event.keyCode !== 69"
          appCurrencyFormatter
          [dollarsOnly]="dataDef.dollarsOnly"
          [useParenthesis]="useParenthesis"
        />
        <mat-hint>
          <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class CurrencyFieldComponent extends BaseFieldComponent implements OnInit, OnDestroy {
  sub: Subscription;
  isFocused: boolean = false;
  @Input() useParenthesis: boolean = false;
  @ViewChild(CurrencyFormatterDirective) currencyFormatterDirective;

  ngOnInit() {
    super.ngOnInit();
    this.sub = this.control.valueChanges.subscribe((value) => {
      setAndPreserveErrors(
        this.control,
        !(value === '' || value === null || value === undefined || this.currencyFormatterDirective.isValidCurrency),
        'currency',
        this.currencyFormatterDirective.errorMessage
      );
      if (!this.isFocused) {
        this.currencyFormatterDirective.formatToCurrency();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
