import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'collapsible-container',
  exportAs: 'collapsibleContainer',
  template: `
    <button class="panel-collapse-toggler" mat-icon-button (click)="toggleContainer()">
      <fa-icon [icon]="opened ? chevronLeftIcon : chevronRightIcon"></fa-icon>
    </button>
    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }

      .panel-collapse-toggler {
        background: transparent;
        position: absolute;
        right: -2rem;
        top: 1.5rem;
        z-index: 4;
      }
    `
  ]
})
export class CollapsibleContainerComponent {
  @Input() opened = true;
  @Input('class') klasses: string;
  @Output() doToggle = new EventEmitter<boolean>();

  chevronLeftIcon = faChevronLeft;
  chevronRightIcon = faChevronRight;

  toggleContainer() {
    this.opened = !this.opened;
    this.doToggle.emit(this.opened);
  }
}
