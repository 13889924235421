import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { scan } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

export interface FileDownloadProgressDialogData {
  filename: string;
  fileObservable: Observable<HttpEvent<Blob>>;
}

interface DownloadProgress {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
  loadedBytes: number;
  progress: number;
  total: number;
  content: Blob | null;
}

@Component({
  templateUrl: 'file-download-progress.component.html'
})
export class FileDownloadProgressDialog {
  file$: Observable<DownloadProgress> = this.data.fileObservable.pipe(this.download());
  cancel: boolean = false;
  closeTimeout: number = 500;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileDownloadProgressDialogData,
    private dialogRef: MatDialogRef<FileDownloadProgressDialog>
  ) {}

  download(): (source: Observable<HttpEvent<Blob>>) => Observable<DownloadProgress> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (previous: DownloadProgress, event: HttpEvent<Blob>): DownloadProgress => {
            // errors in the download will return ResponseHeader and the event is not ok
            if (this.cancel || (event.type === HttpEventType.ResponseHeader && !event.ok)) {
              this.dialogRef.close(null);
              return {
                loadedBytes: previous.loadedBytes,
                state: 'DONE',
                content: null,
                progress: 100,
                total: previous.total
              };
            }
            if (event.type === HttpEventType.DownloadProgress) {
              return {
                loadedBytes: event.loaded,
                state: 'IN_PROGRESS',
                content: null,
                total: event.total,
                progress: event.total ? Math.round((100 * event.loaded) / event.total) : previous.progress
              };
            }
            if (event.type === HttpEventType.Response) {
              if (event.body) {
                // and save to the file system
                if (this.data.filename) {
                  FileSaver.saveAs(event.body, this.data.filename);
                }
              }
              setTimeout(() => {
                this.dialogRef.close(event.body);
              }, this.closeTimeout);
              return {
                loadedBytes: previous.loadedBytes,
                state: 'DONE',
                content: event.body,
                progress: 100,
                total: previous.total
              };
            }
            return previous;
          },
          { loadedBytes: 0, state: 'PENDING', content: null, progress: 0, total: 0 }
        )
      );
  }
}
