import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SubmitModel } from '@form-lib/models/submit.model';
import { OcrProcessedFieldModel } from '@form-lib/ocr-overlay/ocr.model';
import { DataDefModel } from '@lib-resource/data-def.model';
import { unflattenData } from '@lib-resource/data.utils';

class OcrDialogData {
  ocrData: OcrProcessedFieldModel;
  definition: DataDefModel;
}

@Component({
  templateUrl: './ocr-dialog.component.html',
  styleUrls: ['./ocr-dialog.component.scss']
})
export class OcrDialogComponent implements OnInit, AfterViewInit {
  bgImage;
  highlighterTop = [];
  highlighterLeft = [];
  highlighterWidth = [];
  highlighterHeight = [];
  ocrData: OcrProcessedFieldModel;
  definition: DataDefModel;
  definitions: DataDefModel[];
  formData;
  @ViewChildren('highlighter')
  highlighters: QueryList<ElementRef>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: OcrDialogData,
    private sanitization: DomSanitizer,
    private dialogRef: MatDialogRef<OcrDialogComponent>
  ) {
    this.definition = data.definition;
    this.definitions = [{ ...data.definition, validators: null }];
    this.ocrData = data.ocrData;
    this.ocrData.boundingBoxes = this.ocrData.boundingBoxes.sort((a, b) => a.top - b.top);
    this.formData = unflattenData({
      [data.definition.key]: data.ocrData.value
    });
    this.bgImage = this.sanitization.bypassSecurityTrustUrl(this.ocrData.docData.image) as any;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.setTransformValues();
    this.scroll();
  }

  setTransformValues() {
    let i = 0;
    this.ocrData.boundingBoxes.forEach((boundingBox) => {
      this.highlighterHeight.push(`${boundingBox.height * 100}%`);
      this.highlighterWidth.push(`${boundingBox.width * 100}%`);
      this.highlighterTop.push(`${boundingBox.top * 100}%`);
      this.highlighterLeft.push(`${boundingBox.left * 100}%`);
      i++;
    });
  }

  scroll() {
    let i = 0;
    this.highlighters.toArray().forEach((highlighter) => {
      const index = i;
      setTimeout(() => {
        highlighter.nativeElement.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }, 1000 * (index + 1));
      i++;
    });
  }

  submit(event: SubmitModel) {
    this.dialogRef.close(event.value[this.definition.key]);
  }
}
