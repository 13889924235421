import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getIdValue } from '@form-lib/utils/control.utils';
import { OrgOcrConfigService } from '@main/org-ocr-config/service/org-ocr-config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OrgOcrConfigValidators {
  constructor(private ocrConfigService: OrgOcrConfigService) {}

  ocrTemplateNameUnique() {
    return (control: AbstractControl) => {
      const id = getIdValue(control);
      return this.ocrConfigService
        .ocrTemplateNameExists(id, control.value)
        .pipe(
          map((val) =>
            !!val ? { uniqueOcrTemplateName: 'Ocr template name is already in use, choose another' } : null
          )
        );
    };
  }
}
