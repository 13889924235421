import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { AccountService } from './account.service';

/**
 * This Async Option always uses to sitewide filter for selected orgs when building the query.  Use this async option if you need that automatically
 * bound to the query.
 */
@Injectable()
export class AccountOrgOptionService extends AsyncOptions<string> {
  key = 'accountsWithOrgs';
  defaultSort = ['name'];

  constructor(private accountService: AccountService, private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?) =>
    this.store
      .select(SiteFilterSelectors.selectedOrgIds)
      .pipe(
        switchMap((_) =>
          this.accountService
            .searchUnfiltered(this.generateFilterString(value, requiredFilter), pageIndex, pageSize, this.defaultSort)
            .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })))
        )
      );

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.accountService
      .searchUnfiltered(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  private generateFilterString = (value, requiredQuery?) => {
    let filterString = value ? `(name ~ '${value}')` : '';
    const orgIds = this.store.selectSnapshot(SiteFilterSelectors.selectedOrgIds);
    const orgFilter = orgIds.map((orgId) => `(org.id = ${orgId} OR policy.underwriterorgid = ${orgId})`).join(' OR ');
    filterString = filterString ? `${filterString} AND ${orgFilter}` : orgFilter;
    return this.combineFilters(filterString, requiredQuery);
  };
}
