import { OrgFunction } from '@common/models/org-type.model';
import { PolicyModel } from '@smart/component/policy/model/policy.model';
import { DateRange } from '@common/models/common.model';
import { OrgModel } from '@main/org/models/org.model';

export interface HideSiteFilterModel {
  hide: boolean;
}

export enum SiteFilterOptions {
  ACCOUNT = 'account',
  POLICY = 'policy',
  ORG_MEMBERSHIP = 'orgMembership',
  QUOTE_FUNCTION = 'orgFunction',
  HIDE_SITE_FILTER = 'hideSiteFilter',
  SHOW_EFFECTIVE_DATE_FILTER = 'showEffectiveDateFilter',
  EFFECTIVE_DATE_RANGE = 'effectivedate',
  SHOW_UNDERWRITER_FILTER = 'showUnderwriterFilter',
  UNDERWRITER = 'underwriter',
  SHOW_PBM_FILTER = 'showPbmFilter',
  PBM = 'pbm'
}

export interface SiteFilterStateModel {
  [SiteFilterOptions.ACCOUNT]?: number[];
  [SiteFilterOptions.POLICY]?: PolicyModel[];
  [SiteFilterOptions.ORG_MEMBERSHIP]?: number[];
  [SiteFilterOptions.QUOTE_FUNCTION]?: OrgFunction;
  [SiteFilterOptions.HIDE_SITE_FILTER]?: HideSiteFilterModel;
  [SiteFilterOptions.SHOW_EFFECTIVE_DATE_FILTER]?: HideSiteFilterModel;
  [SiteFilterOptions.EFFECTIVE_DATE_RANGE]?: DateRange;
  [SiteFilterOptions.SHOW_UNDERWRITER_FILTER]?: HideSiteFilterModel;
  [SiteFilterOptions.UNDERWRITER]?: OrgModel[];
  [SiteFilterOptions.SHOW_PBM_FILTER]?: HideSiteFilterModel;
  [SiteFilterOptions.PBM]?: number[];
}
