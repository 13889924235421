import { Directive, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';

/**
 * This directive is a sibling to the app-in-progress-indicator component. Use this directive when the container
 * this directive would be applied to needs the indicator to show in the center - above the container contents.
 * Generally used with form components when loading. This will grey out the hosting container and display a white
 * loading indicator until the input boolean changes to false.
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class InProgressIndicatorBase implements OnChanges {
  @Input('appInProgressIndicator') data: boolean;
  private _renderedEl;
  private _classToApply = 'in-progress-indicator';

  constructor(private renderer2: Renderer2, private viewRef: ViewContainerRef) {}

  ngOnChanges({ data }: SimpleChanges): void {
    if (!data) return;

    if (data.currentValue === true) {
      this.attachComponent();
    } else {
      this.detachComponent();
    }
  }

  attachComponent() {
    this.renderer2.addClass(this.viewRef.element.nativeElement, this._classToApply);
    this._renderedEl = this.renderer2.createElement('div');
    this.renderer2.addClass(this._renderedEl, 'indicator-container');
    this.renderer2.appendChild(this.viewRef.element.nativeElement, this._renderedEl);
  }

  detachComponent() {
    if (!this._renderedEl) return;
    this.renderer2.removeClass(this.viewRef.element.nativeElement, this._classToApply);
    this.renderer2.removeChild(this.viewRef.element.nativeElement, this._renderedEl);
  }
}
