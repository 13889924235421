import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[popoverTrigger]' })
export class PopoverTriggerDirective {
  @Output() popoverTrigger = new EventEmitter<ElementRef>();
  @HostListener('click')
  onClick() {
    this.popoverTrigger.emit(this._el);
  }

  constructor(private _el: ElementRef) {}
}
