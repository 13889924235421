import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { GlobalTpaModel } from '@common/models/global-tpa.model';
import { GlobalTpaService } from '@common/services/global-tpa.service';

@Injectable()
export class GlobalTpaOptionService extends AsyncOptions<GlobalTpaModel> {
  key = 'globalTpaModels';
  defaultSort = ['name'];

  constructor(private globalTpaService: GlobalTpaService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.globalTpaService
      .find(this.generateFilterString(value, requiredFilter), pageIndex, pageSize, this.defaultSort)
      .pipe(
        map(({ content, total }) => ({ content: this.genLabelVals(content, asyncExtras?.includeBusinessType), total }))
      );

  valuesFromKeys = (value: GlobalTpaModel) => {
    if (!value?.id) {
      return of(null);
    }
    return this.globalTpaService
      .find(`id = '${value.id}'`, 0, 100, this.defaultSort)
      .pipe(map((result) => this.genLabelVals(result.content, false)));
  };

  private generateFilterString = (value, requiredQuery?) => {
    let filterString = value ? `name ~ '${value}' and active = true` : 'active = true';
    return this.combineFilters(filterString, requiredQuery);
  };

  genLabelVals(globalTpas: GlobalTpaModel[], includeBusinessType: boolean): { label: string; value: GlobalTpaModel }[] {
    return globalTpas.map((gtpa) => ({
      label: gtpa.name,
      value: gtpa
    }));
  }
}
