import { InjectionToken } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export interface ValidatorFnService {
  checkUnique(id: string, value: any): (control: UntypedFormControl) => {};
}

export const DYNAMIC_VALIDATORS = new InjectionToken<ValidatorFnService>('DYNAMIC_VALIDATORS');

export const FORM_VALIDATOR_KEYS = {
  eitherOr: 'eitherOr',
  required: 'required',
  validDateRange: 'validDateRange',
  mustBeLessThanOrEqualTo: 'mustBeLessThanOrEqualTo',
  mustBeGreaterThan: 'mustBeGreaterThan',
  oneOrBoth: 'oneOrBoth',
  customValidator: 'customValidator',
  oneOrTheOther: 'oneOrTheOther',
  allOrNone: 'allOrNone',
  atLeastOneOf: 'atLeastOneOf'
} as const;

export type FormValidatorKey = typeof FORM_VALIDATOR_KEYS[keyof typeof FORM_VALIDATOR_KEYS];

export const ASYNC_FORM_VALIDATOR_KEYS = {
  customValidator: 'customValidator'
} as const;

export type AsyncFormValidatorKey = typeof ASYNC_FORM_VALIDATOR_KEYS[keyof typeof ASYNC_FORM_VALIDATOR_KEYS];

export type CustomValidatorFn = (controlValues: any[], form: UntypedFormGroup) => boolean;
export type CustomAsyncValidatorFn = (controlValues: any[], control: AbstractControl) => Observable<boolean>;

export class CustomValidatorDef {
  readonly key = FORM_VALIDATOR_KEYS.customValidator;
  args: [string[], CustomValidatorFn, string];
}

export class CustomAsyncValidatorDef {
  readonly key = FORM_VALIDATOR_KEYS.customValidator;
  args: [string[], CustomAsyncValidatorFn, string];
}

export class GenericFormValidator {
  key: FormValidatorKey;
  args: [string, string, any?];
}

export type FormValidatorDef = GenericFormValidator | CustomValidatorDef;

export type AsyncFormValidatorDef = CustomAsyncValidatorDef;

export class DisablerConfig {
  // If defined, this value will be used to compare with the control value to determine if dependents should be disabled
  value?: any;

  key?: string; // Key of disabler to be used
  /** @Deprecated use fieldKey * */
  field?: string;
  fieldKey?: string; // Key of the data def
  // Inverts the results of the disabler
  isNot?: boolean;
  // Clears value on disable if property is true
  clearValue?: boolean;

  validateWhenSiblingIsDisabled?: boolean;
  message?: string;
}

export class FormArrayValidatorConfig {
  key: string;
  value?: string | number;
  message?: string;
}

export class ValidatorsMap {
  required?: boolean;
  requiredTrue?: boolean;
  min?: number;
  max?: number;
  usZip?: boolean;
  url?: boolean;
  usFedId?: boolean;
  email?: boolean;
  eitherOr?: string[];
  onlyOneValueOf?: string[];
  rangeValidator?: number[];
  mustBeLessThanOrEqualTo?: string[];
  maxLength?: number;
  minLength?: number;
  mustBeInteger?: boolean;
  noDuplicates?: true;
  maxSize?: number;
  // date validators, expecting yyyy-mm-dd or a Date object, will only apply to date or datepicker types
  minDate?: string | Date;
  maxDate?: string | Date;

  // Start of Disablers
  valueIsTruthy?: DisablerConfig;
  valueContains?: DisablerConfig;

  // Start of FormArray validators
  thereCanBeOnlyOne?: FormArrayValidatorConfig;
}
