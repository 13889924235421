<div class="filter-row-container  dense-layout">
  <div class="filter-row" *ngFor="let filterBrickRow of filterBrickRows">
    <div class="label pt-2">{{ filterBrickRow.rowName }}</div>
    <div class="filters-container">
      <button
        mat-raised-button
        *ngFor="let filterOption of filterBrickRow.filterOptions"
        (click)="onFilterBrickClick(filterOption)"
        [color]="isSelected(filterBrickRow, filterOption) ? 'primary' : null"
      >
        {{ filterOption.displayName }}
      </button>
    </div>
  </div>
</div>
