import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OcrTestResultModel } from '@main/org-ocr-config/model/org-ocr-result.model';
import { OrgOcrConfigTestService } from '@main/org-ocr-config/service/org-ocr-config-test.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-org-ocr-template-test-dialog',
  templateUrl: 'org-ocr-template-test-dialog.component.html',
  styles: [
    `
      .drop-location-padding {
        padding: 1.5rem;
      }
    `
  ]
})
export class OrgOcrTemplateTestDialogComponent {
  uploading = false;

  constructor(
    public dialogRef: MatDialogRef<OrgOcrTemplateTestDialogComponent, OcrTestResultModel>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      ocrTemplateId: number;
    },
    private orgOcrConfigTestService: OrgOcrConfigTestService
  ) {}

  onSelectFile(file: File) {
    this.uploading = true;

    this.orgOcrConfigTestService
      .testOcrExtraction(file, this.data.ocrTemplateId)
      .pipe(finalize(() => (this.uploading = false)))
      .subscribe((result) => {
        this.dialogRef.close(new OcrTestResultModel(result, file.name));
      });
  }
}
