<div class="info-table-wrapper">
  <div class="info-table-header">{{ headerLabel }}</div>
  <div class="info-table">
    @for (section of rowsGroups; track section) {
      <div class="info-grid">
        @for (rowDef of section; track rowDef) {
          <div class="info-table-label">{{ rowDef.label }}</div>
          <div class="info-table-value">
            {{
              rowDef.type === DATA_TYPES.currency
                ? (getValue(data, rowDef) | accountingCurrency: '1.0-0')
                : (data | dataFormatter: rowDef : 'empty')
            }}
          </div>
        }
      </div>
      <mat-divider></mat-divider>
    }
    <div class="info-grid">
      <div class="info-table-label info-table-total-label">{{ totalRowDef.label }}</div>
      <div class="info-table-value info-table-total-value">
        {{
          totalRowDef.type === DATA_TYPES.currency
            ? (getValue(data, totalRowDef) | accountingCurrency: '1.0-0')
            : (data | dataFormatter: totalRowDef : 'empty')
        }}
      </div>
    </div>
  </div>
</div>
