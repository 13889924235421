<div class="wrapper kpi-border {{ bgColorClass }}">
  <fa-icon
    *ngIf="showInfo"
    [icon]="infoIcon"
    (popoverTrigger)="openInfoPopover($event)"
    class="color-medium-gray clickable splash-kpi-info-icon"
  >
  </fa-icon>
  <a
    matRipple
    (click)="active = !active"
    [appDisableAnchorLink]="!kpiDef?.url"
    [class.active]="active"
    class="kpi-link grid-gap-2"
    [routerLink]="kpiDef?.url"
    [queryParams]="queryData"
  >
    <div class="kpi-value {{ valueColorClass }}">
      <app-in-progress-indicator *ngIf="loading" diameter="1.25rem" indicatorStyle="thin"></app-in-progress-indicator>
      <div *ngIf="!loading" [innerHTML]="valueFn(kpiDef, data)"></div>
    </div>
    <div class="kpi-label {{ labelColorClass }}">
      <span [innerHTML]="labelFn(kpiDef, data)"></span>
      <ng-template [ngIf]="kpiDef?.url">
        &nbsp;<fa-icon [icon]="faAnglesRight" class="color-rmt-dark-tint-1"></fa-icon>
      </ng-template>
    </div>
  </a>
</div>
