import { Injectable } from '@angular/core';
import { QuoteRequestService } from '@common/services/quote-request.service';

import { AsyncOptions } from '@form-lib/options/options.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This Async Option is dependent on 2 parent Async Options ('orgIds' and 'accountIds').
 * Whenever the 'orgIds' select field is modified OR the 'accountIds' selections are modified, that will
 * trigger this Async Option to fetch the RFPs for the selected orgIds and/or accountIds.
 */
@Injectable()
export class RfpAsyncOptionGroupService extends AsyncOptions<string> {
  key = 'rfpAsyncOptionGroup';
  defaultSort = ['name'];

  constructor(private quoteRequestService: QuoteRequestService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?, asyncExtras?) =>
    this.quoteRequestService
      .searchQuoteRequestsWithOutSiteFilter(
        this.combineFilters(
          this.generateFilterString(control, asyncOptionDeps, value, requiredFilter),
          asyncExtras?.filter
        ),
        pageIndex,
        pageSize,
        this.defaultSort
      )
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.quoteRequestService
      .searchQuoteRequestsWithOutSiteFilter(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  private generateFilterString = (control, asyncOptionDeps, value, requiredQuery?) => {
    let filterString = value ? `(name ~ '${value}')` : '';

    const orgIdsFormValue = control.parent.get(asyncOptionDeps[0])?.value || [];
    const orgIds = Array.isArray(orgIdsFormValue) ? orgIdsFormValue : [orgIdsFormValue];
    const orgFilter = orgIds?.map((orgId) => `(quotetype.org.id = ${orgId})`).join(' OR ');

    const accountIdsFormValue = control.parent.get(asyncOptionDeps[1]).value || [];
    const accountIds = Array.isArray(accountIdsFormValue) ? accountIdsFormValue : [accountIdsFormValue];
    const accountFilter = accountIds.map((accountId) => `(account.id = ${accountId})`).join(' OR ');
    if (!!accountFilter) {
      filterString = filterString ? `${filterString} AND (${accountFilter})` : accountFilter;
    } else if (!!orgFilter) {
      filterString = filterString ? `${filterString} AND (${orgFilter})` : orgFilter;
    }
    return this.combineFilters(filterString, requiredQuery);
  };

  generateLabelValues(items: any[]) {
    if (!items?.length) return [];
    return items.map((val) => ({
      label: `${val.name} (ID: ${val.id})`,
      value: val.id
    }));
  }
}
