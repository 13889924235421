import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';
import { PopoverRef } from '@shared/components/popover/popover-ref';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-display-columns-menu',
  templateUrl: 'display-columns-menu.component.html',
  styleUrls: ['display-columns-menu.component.scss']
})
export class DisplayColumnsMenuComponent implements AfterViewInit {
  definitions: DataDefModel[];
  defaultColKeys: Set<string> = new Set<string>();
  columnsUpdated: EventEmitter<any>;
  selectedAll = false;

  @ViewChild('selectAllCheckbox')
  matCheckbox: MatCheckbox;

  constructor(
    private popoverRef: PopoverRef,
    @Inject(POPOVER_DATA) data
  ) {
    this.definitions = data.definitions;
    this.columnsUpdated = data.columnsUpdated;
    this.defaultColKeys = data.defaultColKeys;
  }

  ngAfterViewInit(): void {
    this.checkSelectAll();
  }

  close() {
    this.popoverRef.close();
  }

  toggleColumn(key: string) {
    this.definitions = [
      ...this.definitions.map((col) => {
        if (col.key === key) {
          return { ...col, visible: !col.visible };
        }
        return col;
      })
    ];
    this.columnsUpdated.emit(key);
    this.checkSelectAll();
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;
    this.definitions = [
      ...this.definitions.map((col) => {
        if ((this.selectedAll && !col.visible) || (!this.selectedAll && col.visible)) {
          this.columnsUpdated.emit(col.key);
          return { ...col, visible: !col.visible };
        }
        return col;
      })
    ];

    setTimeout((_) => {
      // set in next event loop to get the proper state of the checkbox, otherwise the checkbox and button are out of sync
      if (this.selectedAll !== this.matCheckbox.checked) {
        this.matCheckbox.checked = this.selectedAll;
      }
    });
  }

  private checkSelectAll() {
    // confirm the selectedAll is still valid
    if (this.definitions.some((col) => !col.visible)) {
      // no selectall
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }
    this.matCheckbox.checked = this.selectedAll;
  }

  resetToDefaults() {
    this.definitions = [
      ...this.definitions.map((col) => {
        if (this.defaultColKeys.has(col.key) && !col.visible) {
          this.columnsUpdated.emit(col.key);
          return { ...col, visible: true };
        }

        if (!this.defaultColKeys.has(col.key) && col.visible) {
          this.columnsUpdated.emit(col.key);
          return { ...col, visible: false };
        }
        return col;
      })
    ];
    this.checkSelectAll();
  }
}
