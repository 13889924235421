<form *ngIf="form" [formGroup]="form" class="mat-typography section-form-container">
  <div *ngFor="let section of sectionConfigs" class="section-container">
    <div class="rmt-subsection-header dense-layout">
      {{ section.label }}
      <div
        class="rmt-subsection-header-actions"
        *ngIf="section.formArrayDef && !form.disabled && formGroupArrayItemAddable"
      >
        <button mat-button type="button" (click)="addItem(section.formArrayDef)">
          <fa-icon [icon]="plusIcon"></fa-icon>
          Add <span class="sr-only">{{ section.formArrayDef.label }}</span>
        </button>
      </div>
    </div>
    <div class="flex-layout pt-2">
      <ng-container *ngFor="let def of definitionsBySection[section.key]" [ngSwitch]="def.type">
        <app-group-array
          [control]="form.controls[def.formKey]"
          [definition]="def"
          (doRemove)="removeItem(def, $event)"
          (doClone)="cloneItem(def, $event)"
          *ngSwitchCase="'formGroupArray'"
          [itemsRemovable]="formGroupArrayItemRemovable"
          [itemsAddable]="formGroupArrayItemAddable"
          class="span-12"
        ></app-group-array>
        <div
          *ngSwitchDefault
          class="ocr-overlay-wrapper"
          [ngClass]="def.layout?.base ? 'span-' + def.layout.base : 'span-3'"
        >
          <app-field-renderer
            class="form-field-renderer"
            fieldLayout
            [control]="form.controls[def.formKey]"
            [definition]="def"
          ></app-field-renderer>
        </div>
      </ng-container>
    </div>
  </div>
</form>
