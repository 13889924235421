import { ValidationErrors } from '@angular/forms';

export class SubmitModel<T = any> {
  value?: T;
  rawValue?: T;
  valid?: boolean;
  invalid?: boolean;
  success?: (continueEditing?: any) => any;
  failure?: (_?: any) => any;
  errors?: ValidationErrors;
}

interface FormSubmitPropertyDescriptor extends PropertyDescriptor {
  value?: (success: SubmitModel, ...args) => void;
}

export function OnlyWhenValid(
  target: any,
  key: string,
  propDesc: FormSubmitPropertyDescriptor
): FormSubmitPropertyDescriptor {
  const originalFunction = propDesc.value;
  propDesc.value = function() {
    const argValue: SubmitModel = arguments[0];
    if (argValue.invalid) {
      argValue.failure();
      return;
    }
    originalFunction.apply(this, arguments);
  };
  return propDesc;
}
