export class CsvImportModel {
  ignoreHeader?: boolean;
  columnMapping?: object;
  columnMappingList?: string[];
  valueMapping?: object;
}

export class ImportTemplateModel extends CsvImportModel {
  id?: number;
  name: string;
  description?: string;
  importType: ImportType;
}

export enum ColumnType {
  DATE = 'DATE',
  NUMBER = 'NUMBER'
}

export class ColumnHeader {
  key: string;
  displayName: string;
  required?: boolean; // required a mapping
  requireValue?: boolean; // the value cannot be empty
  type?: ColumnType;
  oneOfRequiredGroupName?: string; // used to group column headers where at least one of which are required
  minMaxValue?: number; // defines the smallest/largest value allowed for a field
}

export enum ImportType {
  DISCLOSURE = 'DISCLOSURE',
  PARTICIPANT = 'PARTICIPANT',
  RX_AUDIT_CLAIM = 'RX_AUDIT_CLAIM'
}

export enum BooleanType {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export class ColumnMap {
  index: number;
  column: string;
}

export class CsvValueMapping {
  [prop: string]: {
    [prop: string]: string;
  };
}

export class CsvMapping {
  ignoreHeader?: boolean;
  columnMappingList?: string[];
  valueMapping?: CsvValueMapping;
  fieldsValid?: boolean;
  twoDigitYearAfter2k?: string[];
}

export class ScanCsvResult {
  unknownMappings: ValueMap[];
  invalid2yDateColumns: ColumnHeader[];
  invalid2yDateRows: number[];
  invalid4yDateColumns: ColumnHeader[];
  invalid4yDateRows: number[];
  invalidNumberColumns: ColumnHeader[];
  invalidNumberRows: number[];
  invalidRequiredValueColumns: ColumnHeader[];
  invalidRequiredValueRows: number[];
  invalidEncoding: string;
}

export class ValueMap {
  key: string;
  name: string;
  values: any;
  mapping?: string[];
}

export const MAX_INT = 2147483647;
export const MAX_VAL_13_5 = 99999999.99999;
export const MAX_VAL_13_2 = 99999999999.99;
