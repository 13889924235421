import { Component, Input, OnInit } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-info-display-vertical',
  template: `
    <div class="grid grid-rows-{{ rows }} {{ appliedClass }}">
      <ng-container *ngFor="let dataDef of dataDefs">
        <app-info-item-display [dataDef]="dataDef" [data]="data"></app-info-item-display>
      </ng-container>
    </div>
  `,
  styleUrls: ['info-display-vertical.component.scss']
})
export class InfoDisplayVerticalComponent implements OnInit {
  @Input() dataDefs: DataDefModel[];
  @Input() data: any;
  @Input() rows: number;
  @Input() rightJustifyValue: boolean;

  appliedClass: string;

  ngOnInit() {
    this.appliedClass = this.rightJustifyValue ? 'info-display-wrapper-justify-value-right' : 'info-display-wrapper';
  }
}
