import { Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';

/* eslint-disable max-len */
export function selector<T, K1 extends keyof T>(k1: K1): OperatorFunction<T, T[K1]>;
export function selector<T, K1 extends keyof T, K2 extends keyof T[K1]>(k1: K1, k2: K2): OperatorFunction<T, T[K1][K2]>;
export function selector<T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
  k1: K1,
  k2: K2,
  k3: K3
): OperatorFunction<T, T[K1][K2][K3]>;
export function selector<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3]
>(k1: K1, k2: K2, k3: K3, k4: K4): OperatorFunction<T, T[K1][K2][K3][K4]>;
export function selector<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4]
>(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5): OperatorFunction<T, T[K1][K2][K3][K4][K5]>;
export function selector<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5]
>(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5, k6: K6): OperatorFunction<T, T[K1][K2][K3][K4][K5][K6]>;
export function selector<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
  R
>(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5, k6: K6, ...rest: string[]): OperatorFunction<T, R>;
export function selector<T, R = unknown>(...properties: string[]): OperatorFunction<T, R>;
/* eslint-enable max-len */

/**
 * Maps each source value (an object) to its specified nested property and only emits
 * if the property has changed since the last emission.
 *
 * Given a list of strings describing a path to an object property, retrieves
 * the value of a specified nested property from all values in the source
 * Observable. If a property can't be resolved, it will return `undefined` for
 * that value. Only emits values that differ from previous emissions.
 *
 * ## Example
 * Map every value of property that is different from the previous
 * ```ts
 * import { of } from 'rxjs';
 * import { selector } from '@lib-resource/operators/selector';
 *
 *  of([{p: 4}, {p: 3}, {p: 4}, {p: 4}, {p: 4}, {p: 2}])
 *  .pipe(selector('p'))
 *  .subscribe(val => {
 *    console.log('Result: ' + val);
 *  });
 *  // Result: 4
 *  // Result: 3
 *  // Result: 4
 *  // Result: 2
 * ```
 *
 * @param {...string} properties The nested properties to pluck from each source
 * value (an object).
 * @return {Observable} A new Observable of property values from the source values.
 * @method selector
 */
export function selector<T, R>(...properties: string[]): OperatorFunction<T, R> {
  return (source: Observable<T>) =>
    source.pipe(
      filter((val) => !!val),
      pluck<T, R>(...properties),
      distinctUntilChanged<R>()
    ); // distinctUntilChanged<R>()(pluck<T, R>(...properties)(source as any));
}
