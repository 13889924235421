import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { matStepperAnimations } from '@angular/material/stepper';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FormContainerService } from '@form-lib/services/form-container.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Directionality } from '@angular/cdk/bidi';
import { DialogsService } from '@common/dialogs/dialogs.service';

export class StepperNavEvent {
  startIndex: number;
  endIndex: number;
  valid?: boolean;
  start?: CdkStep;
  end?: CdkStep;
  failure?: () => void; // async callback
  success?: () => void; // async callback
}

@Component({
  selector: 'app-vertical-stepper',
  exportAs: 'appVerticalStepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: VerticalStepperComponent }],
  animations: [matStepperAnimations.horizontalStepTransition]
})
export class VerticalStepperComponent extends CdkStepper implements OnInit {
  loading = false;
  @Input() dialogTitle: string;
  @Input() actionButtonText: string = 'Create';
  @Input() actions: string[];
  @Input() asyncSteps: boolean;
  @Input() readOnly: boolean;
  @Input() saving: boolean;
  @Output() navigationStart = new EventEmitter<StepperNavEvent>();
  @Output() onSubmit = new EventEmitter<string>();

  checkIcon = faCircleCheck;
  errorIcon = faExclamationTriangle;

  constructor(
    private formContainerService: FormContainerService,
    private dialogRef: MatDialogRef<VerticalStepperComponent>,
    private dialogsService: DialogsService,
    private directionality: Directionality,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(directionality, changeDetectorRef, elementRef);
  }

  ngOnInit() {
    if (!this.actions || this.actions.length === 0) {
      this.actions = [this.actionButtonText];
    }
  }

  startNavigation(endIndex) {
    const endStep = this.steps.find((_, i) => i === endIndex);
    const sharedNavProperties: StepperNavEvent = {
      startIndex: this.selectedIndex,
      start: this.selected,
      endIndex,
      end: endStep
    };
    sharedNavProperties.valid =
      !this.selected.hasError || (this.selected.optional && !this.selected.stepControl?.invalid);

    if (this.asyncSteps) {
      this.loading = true;
      this.navigationStart.emit({
        ...sharedNavProperties,
        success: () => {
          this.setStep(endIndex);
          this.loading = false;
        },
        failure: () => (this.loading = false)
      });
    } else {
      this.setStep(endIndex);
      this.navigationStart.emit(sharedNavProperties);
    }
  }

  setStep(endIndex: number) {
    if (endIndex > this.selectedIndex) {
      const steps = this.steps.toArray();
      for (let i = this.selectedIndex; i < endIndex; i++) {
        steps[i].interacted = true;
        steps[i].completed = true;
      }
    }
    this.selectedIndex = endIndex;
    if (this.selected === this.steps.last) {
      this.selected.interacted = true;
    }
  }

  anyHasError() {
    return this.steps.find((step) => step.hasError);
  }

  submit(action: string) {
    if (!this.anyHasError()) {
      this.onSubmit.emit(action);
    }
  }

  close() {
    if (
      !this.readOnly &&
      (this.formContainerService.hasUnsavedChanges() || this.steps.find((step) => step.interacted))
    ) {
      this.dialogsService
        .discardChangesConfirmationDialog()
        .afterClosed()
        .subscribe((res) => {
          if (!res) return;
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
