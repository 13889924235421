import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AsyncOptions } from '@form-lib/options/options.model';
import { PolicyHttpService } from '@smart/component/policy/service/policy-http.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This Async Option is dependent on a parent Async Option for 'accountIds'. Whenever the 'accountIds' select field is modified, that will
 * trigger this Async Option to fetch the policies for the selected 'accountIds'.
 */
@Injectable()
export class PolicyAsyncOptionGroupService extends AsyncOptions<string> {
  key = 'policyAsyncOptionGroup';
  defaultSort = ['name'];

  constructor(private policyService: PolicyHttpService) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, sort, requiredFilter?) => {
    const orgId = control.parent.get(asyncOptionDeps[0])?.value || null;
    if (!orgId) {
      // run using the sitewide filter version
      return this.policyService
        .search(
          this.generateFilterString(control, asyncOptionDeps[1], value, requiredFilter),
          pageIndex,
          pageSize,
          this.defaultSort
        )
        .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));
    }

    // run using the org specific version
    return this.policyService
      .searchWithOrg(
        orgId,
        this.generateFilterString(control, asyncOptionDeps[1], value, requiredFilter),
        pageIndex,
        pageSize,
        this.defaultSort
      )
      .pipe(map(({ content, total }) => ({ content: this.generateLabelValues(content), total })));
  };

  valuesFromKeys = (values: string[], multi = true) => {
    if (!values?.length) return of(null);
    const filter = multi ? `id ~ ('${values.join("','")}')` : `id ~ '${values}'`;
    return this.policyService
      .search(filter, 0, 1000, this.defaultSort)
      .pipe(map((result) => this.generateLabelValues(result.content)));
  };

  /*
   * The filter string should include the criteria for the org id or account ids based on what has been selected in the parent dependent control 'accountIds'
   */
  private generateFilterString = (control: AbstractControl, asyncOptionDeps: string, value, requiredQuery?) => {
    let filterString = value ? `(name ~ '${value}')` : '';
    const accountIds = control.parent.get(asyncOptionDeps)?.value;
    if (!!accountIds) {
      let accountFilter = null;
      if (accountIds instanceof Array && accountIds?.length > 0) {
        accountFilter = `accountid ~ (${accountIds.join(',')})`;
      } else if (!(accountIds instanceof Array)) {
        accountFilter = `accountid ~ (${accountIds})`;
      }
      if (filterString && accountFilter) {
        filterString = `${filterString} AND (${accountFilter})`;
      } else if (accountFilter) {
        filterString = accountFilter;
      }
    }

    return this.combineFilters(filterString, requiredQuery);
  };
}
