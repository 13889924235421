import { Component, forwardRef } from '@angular/core';
import { BaseArrayComponent } from '@form-lib/arrays/base-array.component';
import { faBars, faTrashCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-group-array',
  templateUrl: './group-array.component.html',
  styleUrls: ['./group-array.component.scss'],
  providers: [
    {
      provide: BaseArrayComponent,
      useExisting: forwardRef(() => GroupArrayComponent)
    }
  ]
})
export class GroupArrayComponent extends BaseArrayComponent {
  barsIcon = faBars;
  trashCircleIcon = faTrashCircle;
}
