import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { KpiCountDefModel } from '@shared/components/kpis/kpi-count/kpi-count-def.model';
import { FilterModel } from '@data-table-lib/models/data-table.model';

@Component({
  selector: 'app-kpi-count',
  templateUrl: './kpi-count.component.html',
  styleUrls: ['./kpi-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiCountComponent implements OnChanges {
  @Input() def: KpiCountDefModel;
  @Input() value: number;
  @Input() activeFilter: FilterModel[];
  @Output() selected = new EventEmitter<KpiCountDefModel>();

  filtersAsString = '';
  on: boolean = false;

  constructor() {}

  ngOnChanges({ def, value, activeFilter }: SimpleChanges): void {
    if (def && this.def?.filter) {
      this.filtersAsString = JSON.stringify({
        activeFilter: this.def?.filter
          .map((f) => ({ key: f.key, value: f.value, operator: f.operator, type: f.type }))
          .sort((a, b) => a?.key.localeCompare(b?.key))
      });
    }
    if (activeFilter) {
      const activeFilterAsString = JSON.stringify({
        activeFilter: activeFilter.currentValue
          ?.map((f) => ({ key: f.key, value: f.value, operator: f.operator, type: f.type }))
          .sort((a, b) => a?.key.localeCompare(b?.key))
      });
      this.on = activeFilterAsString === this.filtersAsString;
    }
    if (!this.value) {
      this.value = 0;
    }
  }

  toggle() {
    if (!this.def?.filter) {
      return; // click disabled if no filter is present
    }
    this.on = !this.on; // flip the toggle
    if (this.on) {
      this.selected.emit(this.def);
    } else {
      this.selected.emit(null);
    }
  }
}
