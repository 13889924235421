<div class="mt-2 collapsible-form dense">
  <app-form
    #generalInformationForm="appForm"
    id="generalInformationForm"
    [definitions]="defs"
    [data]="localFormData"
    [validators]="formValidators"
    [editing]="!readOnly"
    [alwaysEditing]="!readOnly"
    [importData]="importData"
    [importDataOverwrites]="true"
    [appOcrOverlay]="ocrData"
    (valueChanges)="formChanges($event)"
  >
    <div class="quote-response-base-form-layout" *ngIf="generalInformationForm.form">
      <div class="quote-response-section-label-text">General Information</div>
      <div></div>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer [control]="getFormControl(typeDataDef.formKey)" [definition]="typeDataDef" [inline]="true">
        </app-field-renderer>
      </div>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(proposalDateDataDef.formKey)"
          [definition]="proposalDateDataDef"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(effectiveDateDataDef.formKey)"
          [definition]="effectiveDateDataDef"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(validThroughDateDataDef.formKey)"
          [definition]="validThroughDateDataDef"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(expirationDate.formKey)"
          [definition]="expirationDate"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <app-date-field
        [control]="getFormControl(lastUpdatedDate.formKey)"
        [dataDef]="lastUpdatedDate"
        [inline]="true"
        class="field-disable"
      ></app-date-field>
      <div class="quote-response-section-label-text">Requested Enrollment</div>
      <div class="quote-response-section-label-text">Quoted Enrollment</div>
      <app-select-field
        [control]="getFormControl(requestEnrollmentTier.formKey)"
        [dataDef]="requestEnrollmentTier"
        [inline]="true"
        class="field-disable"
      ></app-select-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(quoteEnrollmentTier.formKey)"
          [definition]="quoteEnrollmentTier"
          [inline]="true"
        ></app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestEmpOnly.formKey)"
        [dataDef]="requestEmpOnly"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(quoteEmpOnly.formKey)"
          [definition]="quoteEmpOnly"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestEmpSpouse.formKey)"
        [dataDef]="requestEmpSpouse"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(quoteEmpSpouse.formKey)"
          [definition]="quoteEmpSpouse"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestEmpChild.formKey)"
        [dataDef]="requestEmpChild"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(quoteEmpChild.formKey)"
          [definition]="quoteEmpChild"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestEmpDep.formKey)"
        [dataDef]="requestEmpDep"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer [control]="getFormControl(quoteEmpDep.formKey)" [definition]="quoteEmpDep" [inline]="true">
        </app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestFamily.formKey)"
        [dataDef]="requestFamily"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer [control]="getFormControl(quoteFamily.formKey)" [definition]="quoteFamily" [inline]="true">
        </app-field-renderer>
      </div>
      <app-number-field
        [control]="getFormControl(requestComposite.formKey)"
        [dataDef]="requestComposite"
        [inline]="true"
        class="field-disable"
      ></app-number-field>
      <div class="ocr-overlay-wrapper">
        <app-field-renderer
          [control]="getFormControl(quoteComposite.formKey)"
          [definition]="quoteComposite"
          [inline]="true"
        >
        </app-field-renderer>
      </div>
      @if (!!getFormControl(requestComposite.formKey)?.value) {
        <app-derived-value-field
          [control]="getFormControl(requestedTotalEnrollmentComposite.formKey)"
          [dataDef]="requestedTotalEnrollmentComposite"
          [inline]="true"
          class="field-disable"
        ></app-derived-value-field>
      } @else {
        <app-derived-value-field
          [control]="getFormControl(requestedTotalEnrollmentNoComposite.formKey)"
          [dataDef]="requestedTotalEnrollmentNoComposite"
          [inline]="true"
          class="field-disable"
        ></app-derived-value-field>
      }
      @if (!!getFormControl(quoteComposite.formKey)?.value) {
        <app-derived-value-field
          [control]="getFormControl(quotedTotalEnrollmentComposite.formKey)"
          [dataDef]="quotedTotalEnrollmentComposite"
          [inline]="true"
          class="field-disable"
        ></app-derived-value-field>
      } @else {
        <app-derived-value-field
          [control]="getFormControl(quotedTotalEnrollmentNoComposite.formKey)"
          [dataDef]="quotedTotalEnrollmentNoComposite"
          [inline]="true"
          class="field-disable"
        ></app-derived-value-field>
      }
    </div>
  </app-form>
</div>
