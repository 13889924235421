import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

export const ndc: DataDefModel = new DataDefModel({
  label: 'NDC Code',
  key: 'ndc',
  type: DATA_TYPES.number
});

export const description: DataDefModel = new DataDefModel({
  label: 'Description',
  key: 'description',
  type: DATA_TYPES.text
});

export const rxDrugDefs: DataDefModel[] = [ndc, description];
