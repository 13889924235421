import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import {
  FileDownloadProgressDialog,
  FileDownloadProgressDialogData
} from '@shared/components/file-download-progress/file-download-progress/file-download-progress.component';
import {
  CsvDownloadProgressDialog,
  CsvDownloadProgressDialogData
} from '@shared/components/file-download-progress/csv-download-progress/csv-download-progress.component';
import { Page } from '@common/models/page.model';

export enum HttpRequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT'
}

export class DownloadRequest {
  fileName: string;
  httpType?: HttpRequestType;
  params?: HttpParams;
  body?: any;
}

@Injectable()
export class FileDownloadService {
  constructor(
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  blobDownloadProgress(
    filename: string,
    fileObservable: Observable<HttpEvent<Blob>>
  ): MatDialogRef<FileDownloadProgressDialog, Blob> {
    const data: FileDownloadProgressDialogData = {
      filename: filename,
      fileObservable: fileObservable
    };
    return this.dialog.open(FileDownloadProgressDialog, { data, width: '600px', disableClose: true });
  }

  csvDownloadProgress(
    filename: string,
    columnNames: string[],
    pagedService: (page: number, sid: string) => Observable<Page<string[]>>
  ): MatDialogRef<CsvDownloadProgressDialog, void> {
    const data: CsvDownloadProgressDialogData = { filename, columnNames, pagedService };
    return this.dialog.open(CsvDownloadProgressDialog, { data, width: '600px', disableClose: true });
  }

  downloadFile(uri: string, downloadRequest: DownloadRequest): Observable<Blob> {
    return this.blobDownloadProgress(downloadRequest.fileName, this.buildObs(uri, downloadRequest)).afterClosed();
  }

  private buildObs(uri: string, downloadRequest?: DownloadRequest): Observable<HttpEvent<Blob>> {
    if (!!downloadRequest) {
      if (HttpRequestType.POST === downloadRequest.httpType) {
        return this.http.post(uri, downloadRequest.body, {
          reportProgress: true,
          observe: 'events',
          params: downloadRequest.params,
          responseType: 'blob'
        });
      }

      if (HttpRequestType.PUT === downloadRequest.httpType) {
        return this.http.put(uri, downloadRequest.body, {
          reportProgress: true,
          observe: 'events',
          params: downloadRequest.params,
          responseType: 'blob'
        });
      }

      return this.http.get(uri, {
        reportProgress: true,
        observe: 'events',
        params: downloadRequest.params,
        responseType: 'blob'
      });
    }
    return this.http.get(uri, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }
}
