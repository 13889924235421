import { Component, Inject, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { QuoteResponseService } from '@common/services/quote-response.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { map, take, tap } from 'rxjs/operators';
import { QuoteLinqVersionModel } from '@common/models/quote-linq-version.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileDocType, FileModel } from '@file-upload-lib/file.model';

export class QuoteSupportingFilePdfDialogReturn {
  fileToUpload?: File;
  supportingFile?: FileModel;
}

@Component({
  templateUrl: './quote-supporting-file-pdf-dialog.component.html',
  styleUrls: ['./quote-supporting-file-pdf-dialog.component.scss']
})
export class QuoteSupportingFilePdfDialogComponent implements OnInit {
  form = new UntypedFormGroup({
    fileId: new UntypedFormControl(null, Validators.required)
  });

  supportFiles$: Observable<FileModel[]>;
  supportFileList = [];
  supportFileEvents = {};
  tmpFileId = 0;

  infoIcon: IconDefinition = faInfoCircle;

  data: { quoteResponseId: number; quoteLinqVersion: QuoteLinqVersionModel };

  constructor(
    private quoteResponseService: QuoteResponseService,
    private dialogRef: MatDialogRef<QuoteSupportingFilePdfDialogComponent, QuoteSupportingFilePdfDialogReturn>,
    @Inject(MAT_DIALOG_DATA) dialogData: { quoteResponseId: number; quoteLinqVersion: QuoteLinqVersionModel }
  ) {
    this.data = dialogData;
  }

  ngOnInit() {
    this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.data.quoteResponseId).pipe(
      take(1),
      map((rmtFiles) =>
        rmtFiles.filter(
          (rmtFile) =>
            !rmtFile.versionId && rmtFile.docType === FileDocType.PROPOSAL && rmtFile.mimeType === 'application/pdf'
        )
      ),
      tap((result) => (this.supportFileList = result))
    );
  }

  onSelectFile(event: any) {
    const id = --this.tmpFileId;
    this.supportFileList.push({ name: event.name, id: id });
    this.supportFileEvents[id] = event;
    this.form.get('fileId').setValue(id);
    this.supportFiles$ = of(this.supportFileList);
  }

  submit() {
    const fileId = this.form.get('fileId').value;
    const result = new QuoteSupportingFilePdfDialogReturn();
    if (fileId < 0) {
      result.fileToUpload = this.supportFileEvents[fileId];
    } else {
      result.supportingFile = this.supportFileList.find((fileModel: FileModel) => fileModel.id === fileId);
    }
    this.dialogRef.close(result);
  }
}
