import { Routes } from '@angular/router';
import { AuthTokenGuard } from '@auth/auth-token.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'external-resource',
    loadChildren: () =>
      import('@app/external/external-client-resource.module').then((m) => m.ExternalClientResourceModule)
  },
  {
    path: '',
    loadChildren: () => import('@main/main.module').then((m) => m.MainModule),
    canActivate: [AuthTokenGuard]
  }
];
