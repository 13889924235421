import { Pipe, PipeTransform } from '@angular/core';
import { ActionDef } from '@data-table-lib/models/data-table.model';

@Pipe({
  name: 'derivedActionLabel'
})
export class DerivedActionLabelPipe implements PipeTransform {
  transform(value: string, action: ActionDef, row: any): any {
    return action.derivedLabel ? action.derivedLabel(row) : value;
  }
}
