<form [ngClass]="{ dense: inlineLabels }" class="simple-form" [formGroup]="form">
  <div *ngIf="!inlineLabels" [class]="grid ? 'grid-container form-grid-layout' : 'form-flex-layout'">
    <app-field-renderer
      *ngFor="let def of _displayedDefinitions"
      fieldLayout
      [control]="form.controls[def.formKey]"
      [definition]="def"
    ></app-field-renderer>
  </div>
  <div *ngIf="inlineLabels">
    <app-field-renderer
      *ngFor="let def of _displayedDefinitions"
      [inline]="inlineLabels"
      [control]="form.controls[def.formKey]"
      [definition]="def"
    ></app-field-renderer>
  </div>
  <ng-content></ng-content>
</form>
