import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ArrayItemFormGroupDirective } from '@form-lib/arrays/array-item-form-group.directive';
import { DataDefModel } from '@lib-resource/data-def.model';
import { replaceInvalidKeyChars } from '@lib-resource/data.utils';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseArrayComponent implements OnChanges {
  @Input() control: UntypedFormArray;
  _definitions: DataDefModel[];
  @Input() definition: DataDefModel;
  @Input() itemsRemovable: boolean = true;
  @Input() itemsAddable: boolean = true;
  @Output() doRemove = new EventEmitter<number>();
  @Output() doClone = new EventEmitter<number>();
  dataSource: DataDefModel[];

  @ViewChildren(ArrayItemFormGroupDirective) arrayItemFormGroupDirectives: QueryList<ArrayItemFormGroupDirective>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.definition) {
      const fields = (this.definition as any).fields;
      this._definitions = (fields ? fields : this.definition.definitions).map((def) => ({
        ...def,
        formKey: replaceInvalidKeyChars(def.key)
      }));
    }
  }

  itemOrderChanged($event: CdkDragDrop<any, any>) {
    const currentGroup = this.control.at($event.previousIndex);
    this.control.removeAt($event.previousIndex);
    this.control.insert($event.currentIndex, currentGroup);
  }

  removeItem(index: number) {
    this.doRemove.emit(index);
  }

  cloneItem(index: number) {
    this.doClone.emit(index);
  }
}
