import { Directive, Host, Input, OnInit, Optional, Self } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  selector: 'mat-select[appIsReadOnly]'
})
export class DisableReadonlySelectDirective implements OnInit {
  @Input('appIsReadOnly') isReadonly;
  constructor(@Host() @Self() @Optional() public hostSel: MatSelect) {}

  ngOnInit() {
    if (this.isReadonly) {
      Object.defineProperty(this.hostSel, 'disabled', {
        value: true,
        writable: false
      });
      this.hostSel.setDisabledState = (_) => {};
    }
  }
}
