import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleRight, faEdit, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { CommentModel } from '@shared/components/comments/comment.model';

@Component({
  selector: 'app-comments',
  templateUrl: 'comments.component.html',
  styleUrls: ['comments.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        animate(
          300,
          keyframes([
            style({ opacity: 0, transform: 'translateY(100%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
          ])
        )
      ]),
      transition('* => void', [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: 'translateY(0)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(30%)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
          ])
        )
      ])
    ])
  ]
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() comments: CommentModel[];
  @Input() noCommentsLabel: string = 'comments';
  @Input() canEditWhen: (comment: CommentModel) => boolean;
  @Input() maxLength: number = 4000;

  @Output() addComment = new EventEmitter<string>();
  @Output() deleteComment = new EventEmitter<CommentModel>();
  @Output() updateComment = new EventEmitter<CommentModel>();

  chevronCircleRightIcon = faChevronCircleRight;
  timesIcon = faTimesCircle;
  editIcon = faEdit;
  trashIcon = faTrashAlt;

  addCommentControl: UntypedFormControl;
  editCommentControl: UntypedFormControl;

  selectedEditComment: CommentModel;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef;

  ngOnInit(): void {
    this.addCommentControl = new UntypedFormControl(null, Validators.maxLength(this.maxLength));
    this.editCommentControl = new UntypedFormControl(null, Validators.maxLength(this.maxLength));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.comments) {
      this.addCommentControl?.reset('');
      this.resetEdit();
      this.scrollToBottom();
    }
  }

  onAddComment() {
    if (!this.addCommentControl.value || this.addCommentControl.errors?.maxlength) {
      return;
    }
    this.addComment.emit(this.addCommentControl.value);
  }

  onDeleteComment(comment: CommentModel) {
    this.deleteComment.emit(comment);
  }

  onUpdateComment(comment: CommentModel) {
    if (this.editCommentControl.errors?.maxlength) {
      return;
    }
    if (this.editCommentControl.value) {
      this.updateComment.emit({
        ...comment,
        comment: this.editCommentControl.value
      });
    } else {
      this.resetEdit();
    }
  }

  editComment(comment: CommentModel) {
    this.selectedEditComment = comment;
    this.editCommentControl.setValue(comment.comment);
  }

  resetEdit() {
    this.editCommentControl?.reset();
    this.selectedEditComment = null;
  }

  trackByFn(index, comment) {
    return comment.id;
  }

  scrollToBottom(): void {
    setTimeout(() => {
      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      }
    });
  }

  @Memoize()
  getUserLabel(comment: CommentModel) {
    return comment.user && comment.user.firstName
      ? `${comment.user.firstName} ${comment.user.lastName}`
      : comment.email;
  }
}
