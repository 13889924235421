import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rmt-chip',
  template: `
    <div class="chip bg-rmt-dark-tint-3">{{ displayLabel }}</div>
  `,
  styleUrls: ['./rmt-chip.component.scss']
})
export class RmtChipComponent {
  @Input() displayLabel: string;
  constructor() {}
}
