import { ValidatorFn } from '@angular/forms';
import { FormModel } from '@form-lib/models/form.model';
import { QuoteRequestModel } from '@common/models/quote-request.model';

export enum RpfConfirmationDialogType {
  CLOSE = 'CLOSE'
}

export interface RfpConfirmationDialogData {
  quoteRequest?: QuoteRequestModel;
  dialogType?: RpfConfirmationDialogType;
  formModel?: FormModel;
  formValidators?: ValidatorFn[];
}
