import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@common/dialogs/dialogs.model';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { ThemePalette } from '@angular/material/core';

@Component({
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>
      {{ label }}
    </app-dialog-label>
    <app-dialog-content style="display: inline-block; white-space: pre-line">
      <span [innerHTML]="message"></span>
      <div *ngIf="!!warnMessage">
        <fa-icon [icon]="warningIcon" class="warning-icon color-intermediary"></fa-icon>
        <span [innerHTML]="warnMessage"></span>
      </div>
    </app-dialog-content>
    <app-dialog-actions>
      <div *ngIf="!!actionWarnMessage" secondaryActions>
        <fa-icon [icon]="warningIcon" class="warning-icon color-intermediary"></fa-icon>
        {{ actionWarnMessage }}
      </div>
      <button mat-raised-button [color]="actionColor" [mat-dialog-close]="true" cdkFocusInitial>
        {{ actionText }}
      </button>
      <button mat-raised-button mat-dialog-close>
        {{ cancelText }}
      </button>
    </app-dialog-actions>
  `,
  styles: [
    `
      .warning-icon {
        padding-right: 0.3rem;
      }
    `
  ]
})
export class ConfirmationDialogComponent {
  label: string;
  actionColor: ThemePalette;
  message: string;
  actionText: string;
  cancelText: string;
  warnMessage: string;
  actionWarnMessage: string; // warn message that will display in the action bar
  warningIcon = faWarning;

  constructor(@Inject(MAT_DIALOG_DATA) data: DialogData) {
    this.label = data.label ? data.label : 'Confirm';
    // default the message is no message and no warn message
    this.message = data.message ? data.message : !data.warnMessage ? 'Are you sure?' : null;
    this.actionColor = data.actionColor ? data.actionColor : 'primary';
    this.actionText = data.actionText ? data.actionText : 'Confirm';
    this.cancelText = data.cancelText ? data.cancelText : 'Cancel';
    this.warnMessage = data.warnMessage;
    this.actionWarnMessage = data.actionWarnMessage;
  }
}
