import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { BulkActionModel, BulkActionResultModel } from '@common/models/bulk-action.model';
import { Page } from '@common/models/page.model';
import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { TagAssignCountsModel } from '@configuration/admin/tag-list/tagAssignCounts.model';
import { HttpResourceService } from '@main/http-resource.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  constructor(private httpResourceService: HttpResourceService, private http: HttpClient) {}

  search(filterString: string, pageIndex: number, pageSize: number, sort: string[]): Observable<Page<TagModel>> {
    return this.httpResourceService.query({
      path: 'tag',
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  searchWithOrg(
    orgId: number,
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<TagModel>> {
    return this.httpResourceService.queryWithoutOrg({
      path: `org/${orgId}/tag`,
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  getById(id: number): Observable<TagModel> {
    return this.httpResourceService.get(`tag/${id}`);
  }

  tagNameExistsInOrg(orgId: number, tagName: string, tagId?: number): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('name', tagName);
    if (tagId) {
      params = params.append('id', `${tagId}`);
    }
    return this.http.get<boolean>(`${SERVER_API_URL}/org/${orgId}/tag/exists`, { params: params });
  }

  create(orgId: number, tagModel: TagModel): Observable<TagModel> {
    return this.http.post(`${SERVER_API_URL}/org/${orgId}/tag`, tagModel);
  }

  update(orgId: number, tagModel: TagModel): Observable<TagModel> {
    return this.http.put(`${SERVER_API_URL}/org/${orgId}/tag/${tagModel.id}`, tagModel);
  }

  delete(orgId: number, tagId: number) {
    return this.http.delete(`${SERVER_API_URL}/org/${orgId}/tag/${tagId}`);
  }

  merge(orgId: number, fromTagId: number, toTagId: number) {
    return this.http.put(`${SERVER_API_URL}/org/${orgId}/tag/${toTagId}/merge/${fromTagId}`, {});
  }

  getCounts(orgId: number, tagId: number): Observable<TagAssignCountsModel> {
    return this.http.get(`${SERVER_API_URL}/org/${orgId}/tag/${tagId}/assigned-counts`);
  }

  bulkUpdateMembersByIds(orgId: number, tagId: number[], memberIds: number[], action: string) {
    return this.http.put(`${SERVER_API_URL}/org/${orgId}/tag/${tagId}/bulk-action/member`, {
      action: action,
      ids: memberIds
    });
  }

  bulkUpdateQuoteRequestsByIds(
    orgId: number,
    tagId: number,
    quoteRequestIds: number[],
    action: string
  ): Observable<BulkActionResultModel> {
    return this.http.put<BulkActionResultModel>(
      `${SERVER_API_URL}/org/${orgId}/tag/${tagId}/bulk-action/quote-request`,
      {
        action: action,
        ids: quoteRequestIds
      }
    );
  }

  bulkUpdateQuoteResponsesByIds(orgId: number, tagId: number[], quoteResponseIds: number[], action: string) {
    return this.http.put(`${SERVER_API_URL}/org/${orgId}/tag/${tagId}/bulk-action/quote-response`, {
      action: action,
      ids: quoteResponseIds
    });
  }

  tagUntagMembersByQuery(
    tag: boolean,
    orgId: number,
    tagId: number,
    query: string = ''
  ): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/member`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', null, query)
    );
  }

  tagUntagMembersByIds(ids: number[], tag: boolean, orgId: number, tagId: number): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/member`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', ids)
    );
  }

  tagUntagRFPsByQuery(
    tag: boolean,
    orgId: number,
    tagId: number,
    query: string = ''
  ): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/quote-request`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', null, query)
    );
  }

  tagUntagRFPsByIds(ids: number[], tag: boolean, orgId: number, tagId: number): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/quote-request`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', ids)
    );
  }

  tagUntagQuotesByQuery(
    tag: boolean,
    orgId: number,
    tagId: number,
    query: string = ''
  ): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/quote-response`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', null, query)
    );
  }

  tagUntagQuotesByIds(ids: number[], tag: boolean, orgId: number, tagId: number): Observable<BulkActionResultModel> {
    return this.httpResourceService.putWithoutOrg<BulkActionResultModel>(
      `org/${orgId}/tag/${tagId}/bulk-action/quote-response`,
      new BulkActionModel(tag ? 'TAG' : 'UNTAG', ids)
    );
  }
}
