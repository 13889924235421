import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterConfig, HttpResourceService } from '@main/http-resource.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '@common/models/page.model';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { RxContractModel } from '@common/models/rx-contract.model';
import { SERVER_API_URL } from '@app/app.constants';

const siteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
    dataKey: 'orgId'
  },
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'accountId'
  }
];

@Injectable({
  providedIn: 'root'
})
export class RxContractService {
  // used to send events between unassociated components, from a router outlet tab to the containing component
  private rxContractUpdatedSource = new BehaviorSubject<number>(0);
  rxContractUpdated: Observable<number> = this.rxContractUpdatedSource.asObservable();

  constructor(protected http: HttpClient, protected httpResource: HttpResourceService) {}

  notifyRxContractUpdated(rxContractId: number) {
    this.rxContractUpdatedSource.next(rxContractId);
  }

  search(
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[],
    siteFilterConfigOverride?: FilterConfig[]
  ): Observable<Page<RxContractModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'rx-contract',
      query: { filterString, pageIndex, pageSize, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
    });
  }

  update(rxContract: RxContractModel): Observable<RxContractModel> {
    return this.http.put<RxContractModel>(
      `${SERVER_API_URL}/org/${rxContract.orgId}/rx-contract/${rxContract.id}`,
      rxContract
    );
  }

  delete(orgId: number, id: number): Observable<void> {
    return this.http.delete<void>(`${SERVER_API_URL}/org/${orgId}/rx-contract/${id}`);
  }

  create(rxContract: RxContractModel): Observable<RxContractModel> {
    return this.http.post<RxContractModel>(`${SERVER_API_URL}/org/${rxContract.orgId}/rx-contract`, rxContract);
  }

  get(orgId: number, id: number): Observable<RxContractModel> {
    return this.http.get<RxContractModel>(`${SERVER_API_URL}/org/${orgId}/rx-contract/${id}`);
  }
}
