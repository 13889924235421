import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions
} from '@angular/material/tooltip';

export const truncateCssClass = 'text-no-overflow';
export const truncateVerticallyCssClass = 'text-no-overflow-with-rows';

@Directive({
  selector: '[truncateText]'
})
export class TruncateTextDirective extends MatTooltip implements OnInit {
  @Input() maxRows: number;

  get message() {
    return this._el.nativeElement.innerText;
  }

  constructor(
    private renderer2: Renderer2,
    _overlay: Overlay,
    private _el: ElementRef<HTMLElement>,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _platform: Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() _dir: Directionality,
    @Optional()
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    _defaultOptions: MatTooltipDefaultOptions,
    @Inject(DOCUMENT)
    _document: any
  ) {
    super(
      _overlay,
      _el,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      scrollStrategy,
      _dir,
      _defaultOptions,
      _document
    );
  }

  ngOnInit() {
    if (this.maxRows) {
      this.tooltipClass = 'text-break-at-new-line';
      this.renderer2.addClass(this._el.nativeElement, truncateVerticallyCssClass);
    } else {
      this.renderer2.addClass(this._el.nativeElement, truncateCssClass);
    }
  }
}
