import { Injectable } from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { EndpointConnectionModel } from '@main/org-endpoint/model/org-endpoint.model';
import { EndpointService } from '@main/org-endpoint/service/org-endpoint.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EndpointConnectionOptionService extends AsyncOptions<EndpointConnectionModel> {
  key = 'endpointConnections';

  constructor(private endpointService: EndpointService) {
    super();
  }

  filter = () =>
    this.endpointService.getEndpointConnections().pipe(
      map((result) => ({
        content: this.generateLabelValues(result),
        total: result.length
      }))
    );

  valuesFromKeys = (values: string[]) => {
    if (!values?.length) return of(null);
    return this.endpointService.getEndpointConnections().pipe(map((result) => this.generateLabelValues(result)));
  };

  generateLabelValues(items: EndpointConnectionModel[]): LabelValue<string, EndpointConnectionModel>[] {
    if (!items?.length) return [];
    return items.map((val) => ({
      label: `${val.userName ? val.userName : '<system hosted>'}${val.url ? '@' + val.url : ''} (id: ${val.id})`,
      value: val
    }));
  }
}
