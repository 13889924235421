import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';
import { QueryDisplayModel } from './query-display.model';

@Component({
  template: `
    <app-dialog-close icon appCloseDialog></app-dialog-close>
    <app-dialog-label>View Query</app-dialog-label>
    <app-dialog-content>
      <div *ngFor="let item of data">
        <pre (click)="selectText($event)">{{ item.query }}</pre>
      </div>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button appCloseDialog>Close</button>
    </app-dialog-actions>
  `,
  styleUrls: ['./query-display.component.scss']
})
export class QueryDisplayComponent {
  data: QueryDisplayModel[];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: QueryDisplayModel[],
    @Optional() @Inject(POPOVER_DATA) popoverData: QueryDisplayModel[]
  ) {
    this.data = !!dialogData ? dialogData : popoverData;
  }

  selectText(event: any): void {
    window.getSelection().selectAllChildren(event.target);
  }
}
