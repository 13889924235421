import { Directive, HostListener, Input, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PopoverRef } from '@shared/components/popover/popover-ref';

@Directive({
  selector: '[appCloseDialog]'
})
export class CloseDialogDirective {
  @Input('appCloseDialog') data;
  ref;
  @HostListener('click', [])
  onClick() {
    this.ref.close(this.data);
  }

  constructor(@Optional() private dialogRef: MatDialogRef<any>, @Optional() private popoverRef: PopoverRef) {
    this.ref = this.dialogRef ? this.dialogRef : this.popoverRef;
    if (!this.ref) {
      console.error('No provider for PopoverRef or MatDialogRef was found.');
    }
  }
}
