<app-dialog-close icon (click)="close()"></app-dialog-close>
<app-dialog-label>{{ dialogTitle }} <ng-content select="[dialogTitleContent]"></ng-content></app-dialog-label>
<app-dialog-sidebar class="p-2" style="overflow-x: hidden">
  <div *ngFor="let step of steps; let i = index" (click)="startNavigation(i)" class="p-1 clickable">
    <div class="step-trigger-content">
      <div class="step-trigger-index" [ngClass]="{ 'active-step': selectedIndex === i }">
        <span class="step-trigger-state">
          <ng-container *ngIf="!loading || i !== selectedIndex">
            <fa-icon *ngIf="step.interacted && step.hasError" [icon]="errorIcon" class="color-warn"></fa-icon>
            <fa-duotone-icon
              *ngIf="step.interacted && step.completed && !step.hasError"
              [icon]="checkIcon"
              primaryColor="white"
              secondaryOpacity="1.0"
              class="color-success bg-color-white"
            ></fa-duotone-icon>
          </ng-container>
          <app-in-progress-indicator *ngIf="loading && i === selectedIndex" diameter="2.25rem" indicatorStyle="thin">
          </app-in-progress-indicator>
        </span>
        Step {{ i + 1 }}
      </div>
      <div class="step-trigger-label">{{ step.label }}</div>
    </div>
  </div>
</app-dialog-sidebar>
<app-dialog-content mat-dialog-content style="padding-bottom: 0" id="step-container">
  <div
    *ngFor="let step of steps; let i = index"
    class="step-container"
    role="tabpanel"
    [ngStyle]="{ position: selectedIndex !== i ? 'absolute' : 'inherit' }"
    [@horizontalStepTransition]="_getAnimationDirection(i)"
    [id]="_getStepContentId(i)"
    [attr.aria-labelledby]="_getStepLabelId(i)"
    [attr.aria-expanded]="selectedIndex === i"
  >
    <ng-container *ngIf="steps" [ngTemplateOutlet]="step.content"></ng-container>
  </div>
</app-dialog-content>
<app-dialog-actions>
  <button secondaryActions mat-raised-button cdkStepperPrevious [disabled]="this.steps.first === selected">Back</button>
  <button
    *ngIf="this.steps.last !== selected"
    mat-raised-button
    color="primary"
    (click)="startNavigation(this.selectedIndex + 1)"
  >
    Next
  </button>

  <ng-container *ngIf="steps.last === selected && !readOnly">
    <ng-container *ngFor="let action of actions; let buttonIndex = index">
      <button
        *ngIf="buttonIndex === 0"
        mat-raised-button
        [disabled]="saving"
        [color]="anyHasError() ? 'warn' : 'primary'"
        (click)="submit(action)"
        [appInProgressIndicator]="loading || saving"
      >
        <fa-icon *ngIf="!loading && anyHasError()" [icon]="errorIcon" class="pr-1" style="font-size: 0.75rem"></fa-icon>
        <span *ngIf="!saving">{{ action }}</span>
      </button>
      <button
        *ngIf="buttonIndex !== 0"
        mat-raised-button
        [disabled]="saving"
        [color]="anyHasError() ? 'warn' : undefined"
        (click)="submit(action)"
        [appInProgressIndicator]="loading || saving"
      >
        <fa-icon *ngIf="!loading && anyHasError()" [icon]="errorIcon" class="pr-1" style="font-size: 0.75rem"></fa-icon>
        <span *ngIf="!saving">{{ action }}</span>
      </button>
    </ng-container>
  </ng-container>
  <button mat-raised-button (click)="close()">{{ readOnly ? 'Close' : 'Cancel' }}</button>
</app-dialog-actions>
