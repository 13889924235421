import { Injectable } from '@angular/core';
import { resourceGet, resourceSuccess } from '@app/store-utils/state.utils';
import { AuthHttpService } from '@auth/store/auth-http.service';
import { AuthStateModel, Login, Logout, NavigateToLogin, SetAuthenticated } from '@auth/store/auth.actions';
import { OrgMemberships } from '@main/store/org-membership/org-membership.actions';
import { SiteFilters } from '@main/store/site-filter/site-filter.actions';
import { User } from '@main/store/user/user.actions';
import { Workspace } from '@main/store/workspace/workspace.actions';
import { Idle } from '@ng-idle/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { NotificationService } from '@shared/notifications/notification.service';

export const AUTH_LOGIN_URL = '/auth/login';
export const AUTHENTICATED = 'authenticated';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    tokenResource: null
  }
})
@Injectable()
export class AuthState {
  constructor(
    private notification: NotificationService,
    private authHttpService: AuthHttpService,
    private idle: Idle
  ) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>) {
    // reset all state to defaults when logging in
    ctx.patchState({
      tokenResource: resourceGet<string>(null)
    });
    this._resetAllState(ctx);

    ctx.patchState({
      tokenResource: resourceSuccess(AUTHENTICATED)
    });
    ctx.dispatch(new User.Get());
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    this.idle.stop();
    ctx.dispatch(new User.Reset());
    ctx.dispatch(new NavigateToLogin()).subscribe((_) => {
      // reset all state to defaults when logging out
      this._resetAllState(ctx);
    });
  }

  @Action(SetAuthenticated)
  setAuthToken(ctx: StateContext<AuthStateModel>, { authenticated, expireDate }: SetAuthenticated) {
    ctx.patchState({
      tokenResource: resourceSuccess(authenticated),
      expireDate: expireDate
    });
  }

  @Action(NavigateToLogin)
  navigateToLogin(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new SetAuthenticated(null));
    return ctx.dispatch(new Navigate([AUTH_LOGIN_URL]));
  }

  private _resetAllState(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Workspace.Reset());
    ctx.dispatch(new SiteFilters.Reset());
    ctx.dispatch(new OrgMemberships.Reset());
  }
}
