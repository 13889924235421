import { Component, forwardRef, Input } from '@angular/core';
import { SectionFormComponent } from '@form-lib/forms/section-form/section-form.component';
import { FormSection } from '@form-lib/models/form.model';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-collapsible-form',
  exportAs: 'appForm',
  templateUrl: './collapsible-form.component.html',
  styleUrls: ['../../form-lib.scss', './collapsible-form.component.scss'],
  providers: [
    {
      provide: BaseFormComponent,
      useExisting: forwardRef(() => CollapsibleFormComponent)
    }
  ]
})
export class CollapsibleFormComponent extends SectionFormComponent {
  @Input() sections!: FormSection[];
  @Input() singleColumn: boolean = false;

  plusIcon = faPlus;
}
