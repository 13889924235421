import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Renderer2
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { noop } from 'rxjs';

@Directive({
  selector: '[appRouterLinkActive]'
})
export class RouterLinkActiveDirective extends RouterLinkActive {
  // @ts-ignore
  @Input('appRouterLinkActive') routerLinkActive: string; // Class name to be applied to element
  @Output() activeAdded = new EventEmitter<null>();
  @Output() activeRemoved = new EventEmitter<null>();
  @Output() activeToggled = new EventEmitter<boolean>();

  constructor(
    router: Router,
    element: ElementRef,
    renderer: Renderer2,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() link?: RouterLink,
    @Optional() linkWithHref?: RouterLink
  ) {
    super(
      router,
      element,
      {
        addClass: (el: any, name: string): void => {
          renderer.addClass(el, name);
          this.linkActivated();
        },
        removeClass: (el: any, name: string): void => {
          renderer.removeClass(el, name);
          this.linkDeactivated();
        },
        // no-op the removeAttribute method to satisfy the method signature of the renderer that RouterLinkActive is expecting
        // to keep errors out of the console.  Unknown if there is any value to implementing this method as we never had it before
        // seems to have changed around angular 15
        removeAttribute: noop
      } as any,
      changeDetectorRef,
      link
    );
  }

  linkActivated() {
    this.activeAdded.emit();
    this.activeToggled.emit(true);
  }

  linkDeactivated() {
    this.activeRemoved.emit();
    this.activeToggled.emit(false);
  }
}
