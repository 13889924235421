import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  additionalFeesUiFormGroupDef,
  additionalFeesUiOptionsDef,
  quoteResponseAdditionalFeesDef
} from '@common/components/quote/quote-response/quote-response-detail/quote-response-view/quote-response-view.definitions';
import { DataDefModel } from '@lib-resource/data-def.model';
import { FormGeneratorService } from '@form-lib/services/form-generator.service';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { faInfoCircle, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AdditionalFeeUiModel } from './additionalFeeUiModel';

@Component({
  selector: 'app-quote-response-additional-fees',
  templateUrl: './additional-fees.component.html',
  styleUrls: ['../quote-response-stepper.component.scss', './additional-fees.component.scss']
})
export class AdditionalFeesComponent implements OnChanges, OnDestroy {
  @Input() uiAdditionalFees: AdditionalFeeUiModel[];
  @Input() touched: boolean;
  @Input() readOnly: boolean;
  @Output() update = new EventEmitter<{
    uiAdditionalFees: AdditionalFeeUiModel[];
    valid: boolean;
  }>();

  form: UntypedFormGroup;
  defs: DataDefModel[] = [additionalFeesUiFormGroupDef];
  additionalFeesUiOptionDataDef: DataDefModel = additionalFeesUiOptionsDef;

  nameDataDef = quoteResponseAdditionalFeesDef.find((def) => def.key === 'name');
  typeDataDef = quoteResponseAdditionalFeesDef.find((def) => def.key === 'type');
  recurrenceDataDef = quoteResponseAdditionalFeesDef.find((def) => def.key === 'recurrence');
  feeFormGroups;

  prev: AdditionalFeeUiModel[];

  removeIcon = faXmark;
  infoIcon = faInfoCircle;

  localUpdatedAlready: boolean = false;

  formValueChanges: Subscription;

  constructor(private formService: FormGeneratorService) {}

  ngOnChanges({ uiAdditionalFees, importData }: SimpleChanges): void {
    if (!this.localUpdatedAlready && uiAdditionalFees?.currentValue) {
      this.removeSub();
      this.form = this.formService.generateFormGroup(this.defs, null, null, {
        additionalFees: uiAdditionalFees.currentValue
      });
      this.formValueChanges = this.form.valueChanges
        .pipe(debounceTime(500))
        .subscribe((formData) => this.formChanges(formData));
      const additionalFeesFormControls = this.form.controls['additionalFees'];
      this.feeFormGroups = (<FormArray>additionalFeesFormControls).controls;
      this.form.markAllAsTouched();
    }
    this.localUpdatedAlready = false;
  }

  ngOnDestroy() {
    this.removeSub();
  }

  formChanges(formData: { additionalFees: AdditionalFeeUiModel[] }) {
    if (!this.prev || (this.prev && JSON.stringify(this.prev) !== JSON.stringify(formData.additionalFees))) {
      this.emitUpdate(formData.additionalFees);
      this.localUpdatedAlready = true;
    }
    this.prev = JSON.parse(JSON.stringify(formData.additionalFees));
  }

  getFormControl(key: string): any {
    return this.form?.controls[key];
  }

  remove(uiAdditionalFeesIndex: number) {
    const additionalFeesFormControls = this.form.controls['additionalFees'];
    (<FormArray>additionalFeesFormControls).removeAt(uiAdditionalFeesIndex);
  }

  private emitUpdate(uiAdditionalFees: AdditionalFeeUiModel[]) {
    this.update.emit({
      uiAdditionalFees: uiAdditionalFees,
      valid: !this.form.invalid
    });
  }

  private removeSub() {
    if (this.formValueChanges) {
      this.formValueChanges.unsubscribe();
    }
  }
}
