import { Pipe, PipeTransform } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';
import { DataFormatter } from '@lib-resource/data.formatter';
import { getValueByDef } from '@lib-resource/data.utils';

function isPrimitive(test) {
  return test !== Object(test) || !!test?.toISOString;
}

@Pipe({
  name: 'dataFormatter'
})
export class DataFormatterPipe implements PipeTransform {
  transform(data: any, dataDef: DataDefModel, nullValueReplacement?: string): any {
    if (!isPrimitive(data)) {
      data = getValueByDef(data, dataDef);
    }
    return DataFormatter.transform(data, dataDef, nullValueReplacement);
  }
}
