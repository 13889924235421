import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileService, FileTypes } from '@file-upload-lib/file.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { faCheckCircle, faClose } from '@fortawesome/pro-solid-svg-icons';
import { NotificationService } from '@shared/notifications/notification.service';
import {
  ColumnHeader,
  ColumnType,
  CsvMapping,
  ImportTemplateModel,
  ImportType,
  ScanCsvResult,
  ValueMap
} from '@common/models/csv-import.model';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, zip } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { FormDialogService } from '@form-lib/services/form-dialog.service';
import { ImportHelpDialogComponent } from '@common/dialogs/import-help-dialog/import-help-dialog.component';
import { csv2YearDateStrValid, csv4YearDateStrValid } from '@app/tools/date';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { ImportMappingTemplateService } from '@common/services/import-mapping-template.service';
import { csvNumber } from '@app/tools/string';
import DetectFileEncodingAndLanguage from 'detect-file-encoding-and-language';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-import-csv-dialog',
  templateUrl: './import-csv-dialog.component.html',
  styleUrls: ['./import-csv-dialog.component.scss']
})
export class ImportCsvDialogComponent implements OnInit, OnDestroy {
  @Input() showHelp = false;
  @Input() showSavedTemplates = false;
  @Input() importTemplateType: ImportType;
  savedTemplates: ImportTemplateModel[] = [];
  @Input() showOverwriteCheckbox = false;
  @Input() overwriteCheckboxLabel = '';
  @Input() instructions = '';
  @Input() uploading = { loading: false };
  @Input() columnsWithMappedValues: ValueMap[] = [];
  @Input() columnHeaders: ColumnHeader[] = [];
  @Input() fileTypes: FileTypes[] = [];

  @Output()
  importReady = new EventEmitter<{ file: File; mappings: CsvMapping; overwrite: boolean }>();

  selectedTabIndex = 0;

  file: File;
  fileSuccessIcon = faCheckCircle;
  closeIcon = faClose;
  private fileSource = new BehaviorSubject<File>(null);
  file$ = this.fileSource.asObservable().pipe(tap((file) => (this.file = file)));
  isCsv$ = this.file$.pipe(mergeMap((file) => this.fileService.isFileType(file, FileTypes.CSV)));
  isEdi834$ = this.file$.pipe(mergeMap((file) => this.fileService.isFileType(file, FileTypes.EDI834)));
  isValidFile$;

  columnsWithDateValues: ColumnHeader[];
  columnsWithNumberValues: ColumnHeader[];
  columnsWithRequiredValues: ColumnHeader[];
  scanResult: ScanCsvResult;
  headerEntries: ColumnHeader[];
  overwrite = false;

  mappings: CsvMapping;
  mappingsSource: BehaviorSubject<CsvMapping>;
  mappings$: Observable<CsvMapping>;
  mappingInvalid$: Observable<boolean>;

  valuesToMapSource: BehaviorSubject<ValueMap[]>;
  valuesToMap$: Observable<ValueMap[]>;
  valueMappingInvalid$: Observable<boolean>;
  allValuesMapped: boolean = false;

  subs = new Subscription();

  constructor(
    public fileService: FileService,
    private dialogRef: MatDialogRef<ImportCsvDialogComponent>,
    private matDialog: MatDialog,
    private formDialog: FormDialogService,
    private importMappingTemplateService: ImportMappingTemplateService,
    private notificationService: NotificationService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.registerColumnHeaders(this.columnHeaders);
    this.registerValidFileTypes(this.fileTypes);

    if (this.showSavedTemplates) {
      this.importMappingTemplateService
        .getImportMappingTemplate(this.importTemplateType)
        .subscribe((result) => (this.savedTemplates = result));
    }

    // column mapping
    this.mappingsSource = new BehaviorSubject<CsvMapping>({});
    this.mappings$ = this.mappingsSource.asObservable().pipe(
      tap((mappings) => {
        this.mappings = mappings;
        this.scanResult = null; // clear any scan results if the mappings change, next import will re-validate them
      })
    );
    this.mappingInvalid$ = this.mappings$.pipe(
      map(
        (mapping) =>
          // must have mappings, the required mappings, and all fields valid
          !mapping.columnMappingList || !mapping.fieldsValid
      )
    );

    // Value mapping
    this.valuesToMapSource = new BehaviorSubject<ValueMap[]>(null);
    this.valuesToMap$ = this.valuesToMapSource.asObservable();
    this.valueMappingInvalid$ = combineLatest([this.valuesToMap$, this.mappings$]).pipe(
      map(([valuesToMap, csvMapping]) => {
        if (!valuesToMap || valuesToMap.length === 0) {
          return false;
        }

        if (!csvMapping?.valueMapping) {
          return true;
        }

        let needsMapping = false;
        valuesToMap.forEach((item) => {
          const valuesMapped = csvMapping.valueMapping[item.key];
          item.mapping.forEach((mapping) => {
            if (!valuesMapped || !valuesMapped[mapping]) {
              needsMapping = true;
            }
          });
        });
        this.allValuesMapped = !needsMapping;
        return needsMapping;
      }),
      startWith(true)
    );
  }

  import() {
    if (this.uploading?.loading) {
      return;
    }
    this.uploading = { loading: true };
    if (this.fileService.isCsv(this.file)) {
      this.subs.add(
        fromPromise(DetectFileEncodingAndLanguage(this.file))
          .pipe(
            filter((res) => {
              if (res.encoding !== 'UTF-8') {
                this.scanResult = new ScanCsvResult();
                this.scanResult.invalidEncoding = res.encoding;
                this.uploading = { loading: false };
                return false;
              }
              return true;
            }),
            switchMap((_) => this.scanCsvForProblems())
          )
          .subscribe((scanResult) => {
            if (this.scanResultHasInvalidProblems(scanResult)) {
              this.scanResult = scanResult;
              this.uploading = { loading: false };
            } else if (scanResult?.unknownMappings?.length > 0 && !this.allValuesMapped) {
              this.scanResult = scanResult;
              this.uploading = { loading: false };
              this.selectedTabIndex = 2;
            } else {
              this.uploading = { loading: true };

              if (this.showSavedTemplates && !this.alreadySavedTemplate()) {
                this.openSaveTemplateDialog().subscribe(() => {
                  this.emitImportReady();
                });
              } else {
                this.emitImportReady();
              }
            }
          })
      );
    } else {
      this.uploading = { loading: true };
      this.emitImportReady();
    }
  }

  close() {
    this.dialogRef.close();
  }

  csvDisplayData$: Observable<string[][]> = this.file$.pipe(
    mergeMap((f) => this.fileService.parseTopRowsFromCsv(f, 5)),
    tap((csvDisplayData) => {
      const newMapping =
        !!csvDisplayData && !!csvDisplayData[0]
          ? { columnMappingList: csvDisplayData[0].map(() => 'IGNORE'), valueMapping: {}, ignoreHeader: true }
          : {};
      this.mappingsSource.next(newMapping);
    })
  );

  registerColumnHeaders(columnHeaders: ColumnHeader[]) {
    this.headerEntries = columnHeaders;
    this.columnsWithDateValues = columnHeaders.filter((c) => !!c.type && c.type === ColumnType.DATE);
    this.columnsWithNumberValues = columnHeaders.filter((c) => !!c.type && c.type === ColumnType.NUMBER);
    this.columnsWithRequiredValues = columnHeaders.filter((c) => c.requireValue);
  }

  registerValidFileTypes(fileTypes: FileTypes[]) {
    const validFileTypePipes = [];
    fileTypes.forEach((fileType) => {
      switch (fileType) {
        case FileTypes.EDI834:
          validFileTypePipes.push(this.isEdi834$);
          break;
        case FileTypes.CSV:
          validFileTypePipes.push(this.isCsv$);
          break;
        default:
          break;
      }
    });
    this.isValidFile$ = zip(...validFileTypePipes).pipe(
      map((results) => results.some((result) => !!result)),
      map((valid) => ({ valid: valid })),
      tap((result) => {
        if (!this.file || result.valid) {
          return;
        }
        this.fileService.showInvalidTypeError(fileTypes);
        this.fileSource.next(null);
      })
    );
  }

  setFile(file: File) {
    if (this.fileService.uploadSizeExceeded(file.size)) {
      this.fileService.showTooLargeError(file.size);
    } else {
      this.fileSource.next(file);
    }
  }

  mapColumn(event: { columnsMapped: string[]; fieldsValid: boolean }) {
    const newMapping = { ...this.mappingsSource.value, ...event };
    this.mappingsSource.next(newMapping);
  }

  openHelpDialog() {
    this.matDialog.open(ImportHelpDialogComponent, { width: '600px' });
  }

  toggleIgnoreHeader(event: { checked: boolean }) {
    this.mappingsSource.next({ ...this.mappingsSource.value, ignoreHeader: event.checked });
  }

  applyMapping(event: CsvMapping) {
    event.fieldsValid = true; // set to valid after import to trigger validation
    if (this.mappings.columnMappingList.length === event.columnMappingList.length) {
      this.mappingsSource.next(event);
    } else {
      this.mappingsSource.next({ ...this.mappings });
      this.notificationService.failedNotification(
        'The number of columns in the selected file do not match the selected template.'
      );
    }
  }

  clearFile() {
    this.fileSource.next(null);
  }

  private getDataForColumn(columnIndex: number, row: string[]): string {
    return columnIndex !== -1 ? row[columnIndex] : null;
  }

  scanCsvForProblems(): Observable<ScanCsvResult> {
    const unknownValues: Map<string, Set<string>> = new Map(
      this.columnsWithMappedValues?.map((col) => [col.key, new Set<string>()])
    );
    const invalid2yDateColumns: Set<ColumnHeader> = new Set();
    const invalid2yDateRows: Set<number> = new Set();
    const invalid4yDateColumns: Set<ColumnHeader> = new Set();
    const invalid4yDateRows: Set<number> = new Set();
    const invalidNumberColumns: Set<ColumnHeader> = new Set();
    const invalidNumberRows: Set<number> = new Set();
    const invalidEmptyColumns: Set<ColumnHeader> = new Set();
    const invalidEmptyRows: Set<number> = new Set();
    const twoDigitYearAfter2k: Set<string> = new Set(this.mappingsSource.value.twoDigitYearAfter2k);

    // a callback function to pass to the streamCsv
    const processRowFn = (row: string[], lineNumber: number) => {
      if (!(this.mappingsSource.value.ignoreHeader && lineNumber === 0)) {
        this.scanCsvForDateFormatProblems(
          lineNumber,
          row,
          twoDigitYearAfter2k,
          invalid2yDateColumns,
          invalid2yDateRows,
          invalid4yDateColumns,
          invalid4yDateRows
        );
        this.scanCsvForNumberFormatProblems(lineNumber, row, invalidNumberColumns, invalidNumberRows);
        this.scanCsvForEmptyValueProblems(lineNumber, row, invalidEmptyColumns, invalidEmptyRows);
        if (
          invalid2yDateColumns.size === 0 &&
          invalid4yDateColumns.size === 0 &&
          invalidNumberColumns.size === 0 &&
          invalidEmptyColumns.size === 0 &&
          !!this.columnsWithMappedValues
        ) {
          // only scan for unknown mappings if everything else checks out
          this.scanCsvForUnknownMappings(row, unknownValues);
        }
      }
    };
    // stream the csv file and analyze each row by utilizing the call back function defined above.
    return this.fileService.streamCsv(this.file, processRowFn).pipe(
      switchMap((_) => {
        if (
          invalid2yDateColumns.size > 0 ||
          invalid4yDateColumns.size > 0 ||
          invalidNumberColumns.size > 0 ||
          invalidEmptyColumns.size > 0
        ) {
          const scanCsvResult: ScanCsvResult = {
            invalid2yDateColumns: invalid2yDateColumns?.size > 0 ? Array.from(invalid2yDateColumns.values()) : null,
            invalid2yDateRows: invalid2yDateRows?.size > 0 ? Array.from(invalid2yDateRows.values()) : null,
            invalid4yDateColumns: invalid4yDateColumns?.size > 0 ? Array.from(invalid4yDateColumns.values()) : null,
            invalid4yDateRows: invalid4yDateRows?.size > 0 ? Array.from(invalid4yDateRows.values()) : null,
            invalidNumberColumns: invalidNumberColumns?.size > 0 ? Array.from(invalidNumberColumns.values()) : null,
            invalidNumberRows: invalidNumberRows?.size > 0 ? Array.from(invalidNumberRows.values()) : null,
            invalidRequiredValueColumns:
              invalidEmptyColumns?.size > 0 ? Array.from(invalidEmptyColumns.values()) : null,
            invalidRequiredValueRows: invalidEmptyRows?.size > 0 ? Array.from(invalidEmptyRows.values()) : null
          } as ScanCsvResult;
          return of(scanCsvResult);
        }
        const unknownMappingValues: ValueMap[] = !this.columnsWithMappedValues
          ? []
          : this.columnsWithMappedValues
              .map((c) => ({ ...c, mapping: Array.from(unknownValues.get(c.key).values()) }))
              .filter((item) => item.mapping.length);
        return of({ unknownMappings: unknownMappingValues } as ScanCsvResult);
      }),
      tap((v) => {
        // for consistency, sort the unknown mappings, and the unknown values
        const unknown = v.unknownMappings
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          .map((m) => ({ ...m, mapping: m.mapping?.sort((a, b) => a?.localeCompare(b)) }));
        this.valuesToMapSource.next(unknown);
      })
    );
  }

  /**
   * Analyze the CSV file to identify any date type columns with unsupported date format
   */
  scanCsvForDateFormatProblems(
    lineNumber: number,
    row: string[],
    twoDigitYearAfter2k: Set<string>,
    invalid2kDateColumns: Set<ColumnHeader>,
    invalid2kDateRows: Set<Number>,
    invalid4kDateColumns: Set<ColumnHeader>,
    invalid4kDateRows: Set<Number>
  ) {
    this.columnsWithDateValues.forEach((dateColumn) => {
      const value = this.getDataForColumn(this.mappingsSource.value.columnMappingList.indexOf(dateColumn.key), row);
      if (!!value) {
        if (twoDigitYearAfter2k.has(dateColumn.key) && !csv2YearDateStrValid(value)) {
          invalid2kDateColumns.add(dateColumn);
          if (invalid2kDateRows.size < 5) {
            invalid2kDateRows.add(lineNumber); // only capture the first 5 errors for brevity in the error message
          }
        }
        if (!twoDigitYearAfter2k.has(dateColumn.key) && !csv4YearDateStrValid(value)) {
          invalid4kDateColumns.add(dateColumn);
          if (invalid4kDateRows.size < 5) {
            invalid4kDateRows.add(lineNumber); // only capture the first 5 errors for brevity in the error message
          }
        }
      }
    });
  }

  scanCsvForNumberFormatProblems(
    lineNumber: number,
    row: string[],
    invalidNumberColumns: Set<ColumnHeader>,
    invalidNumberRows: Set<Number>
  ) {
    this.columnsWithNumberValues.forEach((numColumn) => {
      const value = this.getDataForColumn(this.mappingsSource.value.columnMappingList.indexOf(numColumn.key), row);
      if (!!value && !csvNumber(value, numColumn.minMaxValue)) {
        invalidNumberColumns.add(numColumn);
        if (invalidNumberRows.size < 5) {
          invalidNumberRows.add(lineNumber); // only capture the first 5 errors for brevity in the error message
        }
      }
    });
  }

  scanCsvForEmptyValueProblems(
    lineNumber: number,
    row: string[],
    invalidEmptyValueColumns: Set<ColumnHeader>,
    invalidEmptyValueRows: Set<Number>
  ) {
    this.columnsWithRequiredValues.forEach((numColumn) => {
      const value = this.getDataForColumn(this.mappingsSource.value.columnMappingList.indexOf(numColumn.key), row);
      if (value === '') {
        invalidEmptyValueColumns.add(numColumn);
        if (invalidEmptyValueRows.size < 5) {
          invalidEmptyValueRows.add(lineNumber); // only capture the first 5 errors for brevity in the error message
        }
      }
    });
  }

  /**
   * Analyze the CSV file to identify any values that need to be manually mapped by the user.
   * There are certain columns in the CSV file that are bound by a pre-defined set of values.
   * If the CSV file has data in those columns that do not fit into the pre-defined set, then
   * they need to be identified and presented to the user.
   */
  scanCsvForUnknownMappings(row: string[], unknownValues: Map<string, Set<string>>) {
    this.columnsWithMappedValues.forEach((columnWithMappedValue) => {
      const value = this.getDataForColumn(
        this.mappingsSource.value.columnMappingList.indexOf(columnWithMappedValue.key),
        row
      );
      if (!!value && !Object.values(columnWithMappedValue.values).includes(value)) {
        unknownValues.get(columnWithMappedValue.key).add(value);
      }
    });
  }

  updateValueMap(mappedValue: { value: string; systemValue: string }, item: ValueMap) {
    const newState = { ...this.mappingsSource.value };
    if (!newState.valueMapping) {
      newState.valueMapping = {};
    }
    newState.valueMapping = {
      ...newState.valueMapping,
      [item.key]: {
        ...newState.valueMapping[item.key],
        [mappedValue.value]: mappedValue.systemValue
      }
    };
    this.mappingsSource.next(newState);
  }

  openSaveTemplateDialog(): Observable<any> {
    return this.formDialog
      .open({
        label: 'Save Mapping Template',
        definitions: [
          new DataDefModel({
            key: 'name',
            label: 'Mapping Template Title',
            type: DATA_TYPES.text,
            validators: {
              required: true
            }
          }),
          new DataDefModel({
            key: 'description',
            label: 'Mapping Template Description',
            type: DATA_TYPES.text,
            validators: {
              maxLength: 60
            }
          })
        ],
        actionText: 'Save'
      })
      .afterClosed()
      .pipe(
        map((template: any) => {
          if (!template) return;
          const newTemplate = { ...this.mappings, ...template };
          delete newTemplate.id;
          this.importMappingTemplateService
            .saveImportMappingTemplate(newTemplate, this.importTemplateType)
            .subscribe(() => {
              this.notificationService.successfulNotification(`Saved new template: ${newTemplate.name}`);
            });
        })
      );
  }

  private alreadySavedTemplate() {
    return this.savedTemplates.some(
      (savedTemplate) =>
        savedTemplate.valueMapping === this.mappings.valueMapping &&
        savedTemplate.columnMappingList === this.mappings.columnMappingList
    );
  }

  private emitImportReady() {
    this.importReady.emit({
      file: this.file,
      mappings: this.mappings,
      overwrite: this.overwrite
    });
  }

  clickBack() {
    this.selectedTabIndex--;
    if (this.selectedTabIndex === 1) {
      // clear out any mapping
      this.mappings.valueMapping = {};
      this.mappingsSource.next(this.mappings);
    }
  }

  next() {
    this.selectedTabIndex++;
  }

  scanResultHasInvalidProblems(scanResult: ScanCsvResult): boolean {
    return (
      scanResult?.invalid2yDateColumns?.length > 0 ||
      scanResult?.invalid4yDateColumns?.length > 0 ||
      scanResult?.invalidNumberColumns?.length > 0 ||
      scanResult?.invalidRequiredValueColumns?.length > 0 ||
      !!scanResult?.invalidEncoding
    );
  }
}
