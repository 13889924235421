import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { LabelValue } from '@lib-resource/label-value.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-search-control',
  templateUrl: './select-search-control.component.html'
})
export class SelectSearchControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  options: LabelValue[];

  @Input()
  controlLabel: string;

  @Input()
  searchLabel: string;

  @Input()
  noEntriesFoundLabel: string;

  @Input()
  initSelected: boolean;

  @Output()
  selectionChange = new EventEmitter();

  selectCtrl: UntypedFormControl = new UntypedFormControl();
  selectFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: BehaviorSubject<LabelValue[]> = new BehaviorSubject(null);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();

  ngOnInit() {
    // listen for search field value changes
    this.selectFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOptions();
    });
  }

  ngOnChanges({ options }: SimpleChanges): void {
    if (options.currentValue) {
      this.filteredOptions.next(options.currentValue.slice());
    } else {
      this.filteredOptions.next([]);
    }
    if (!options.previousValue && options.currentValue && options.currentValue.length > 0 && this.initSelected) {
      // first time loading, preselect the first
      this.selectCtrl.setValue(options.currentValue[0]);
      this.selectionChange.emit(options.currentValue[0].value);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterOptions() {
    if (!this.options) {
      return;
    }
    // get the search keyword
    let search = this.selectFilterCtrl.value;
    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    }
    search = search.toLowerCase();
    // filter the option labels in memory
    this.filteredOptions.next(this.options.filter((policy) => policy.label.toLowerCase().indexOf(search) > -1));
  }

  selectionChanged(event): void {
    this.selectionChange.emit(event.value.value);
  }
}
