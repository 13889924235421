import { Component, Inject } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnlyWhenValid, SubmitModel } from '@form-lib/models/submit.model';
import { FormValidatorDef } from '@form-lib/models/validators.model';
import { DataDefModel } from '@lib-resource/data-def.model';

export class FormConfig {
  definitions: DataDefModel[];
  displayItems?: string[];
  data?: any;
  label?: string;
  inlineLabels?: boolean;
  formValidators?: Array<ValidatorFn | FormValidatorDef>;
  readOnly?: boolean;
  actionText?: string;
  grid?: boolean; // Default true
  warnMessage?: string;
}

@Component({
  selector: 'app-form-dialog',
  template: `
    <app-dialog-close icon mat-dialog-close></app-dialog-close>
    <app-dialog-label>
      {{ data.label ? data.label : '' }}
    </app-dialog-label>
    <app-dialog-content>
      <app-alert-info *ngIf="data.warnMessage" [message]="data.warnMessage" alertType="warn"> </app-alert-info>
      <app-simple-form
        #dialogForm="simpleForm"
        [validators]="data.formValidators"
        [data]="data.data"
        [editing]="!data.readOnly"
        [grid]="data.grid"
        [definitions]="definitions"
        [inlineLabels]="data.inlineLabels"
        (doSubmit)="submit($event)"
      ></app-simple-form>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button color="primary" (click)="dialogForm.submit()">
        {{ data.actionText ? data.actionText : 'Submit' }}
      </button>
      <button mat-raised-button mat-dialog-close>Cancel</button>
    </app-dialog-actions>
  `
})
export class FormDialogComponent {
  definitions: DataDefModel[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: FormConfig, private dialogRef: MatDialogRef<FormDialogComponent>) {
    this.definitions = data.displayItems
      ? data.definitions.filter((def) => data.displayItems.some((item) => def.key === item))
      : data.definitions;
  }

  @OnlyWhenValid
  submit(event: SubmitModel) {
    this.dialogRef.close(event.value);
  }
}
