import { Directive, HostBinding, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { InProgressIndicatorBase } from '@shared/directives/in-progress-indicator/in-progress-indicator.base';

@Directive({
  selector: 'button[appInProgressIndicator]'
})
export class InProgressIndicatorButtonDirective extends InProgressIndicatorBase implements OnChanges {
  @HostBinding('disabled')
  @Input('disabled')
  disabled: boolean;

  constructor(renderer2: Renderer2, viewRef: ViewContainerRef) {
    super(renderer2, viewRef);
  }

  ngOnChanges({ data, disabled }: SimpleChanges) {
    super.ngOnChanges({ data });
    if (data) {
      if (data.currentValue) {
        this.disabled = true;
      } else if (data.previousValue) {
        this.disabled = false;
      }
    }
  }
}
