import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormConfig, FormDialogComponent } from '@form-lib/form-dialog/form-dialog.component';

@Injectable()
export class FormDialogService {
  constructor(private dialog: MatDialog) {}

  open(config: FormConfig, dialogConfig: MatDialogConfig = {}) {
    return this.dialog.open(FormDialogComponent, {
      data: config,
      disableClose: dialogConfig?.disableClose,
      width: dialogConfig.width ? dialogConfig.width : '600px'
    });
  }
}
