import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { BulkActionResultModel } from '@common/models/bulk-action.model';
import { Page } from '@common/models/page.model';
import { HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { SiteFilterSelectors } from '@main/store/site-filter/site-filter.selectors';
import { Store } from '@ngxs/store';
import { FilterUtilService } from '@common/services/filter-util.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MessageModel } from './message.model';

const requesterOrgSiteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
    dataKey: 'quoteResponse.quoteRequest.quoteType.org.id'
  },
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'quoteresponse.quoterequest.account.id'
  }
];

const responderOrgSiteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ORG_MEMBERSHIP,
    dataKey: 'quoteResponse.org.id'
  }
];

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageCountUpdatedSource = new BehaviorSubject(null);
  messageCountUpdated$ = this.messageCountUpdatedSource.asObservable();

  constructor(
    protected http: HttpClient,
    private store: Store,
    private httpResource: HttpResourceService,
    private filterUtilService: FilterUtilService
  ) {}

  create(quoteResponseId: number, messageAndFiles: FormData): Observable<MessageModel> {
    return this.http.post<MessageModel>(`${SERVER_API_URL}/quote-response/${quoteResponseId}/message`, messageAndFiles);
  }

  search(query: string, page: number, pageSize: number, sort: string[]): Observable<Page<MessageModel>> {
    return this.store.select(SiteFilterSelectors.isRequestingOrg).pipe(
      map((isReq) => (isReq ? requesterOrgSiteFilterConfig : responderOrgSiteFilterConfig)),
      switchMap((orgConfig) =>
        this.httpResource.queryWithoutOrg({
          path: 'quote-response/message',
          query: {
            filterString: this.filterUtilService.filterByQuoteProduct(
              query,
              'quoteresponse.quoterequest.quotetype.product'
            ),
            pageIndex: page,
            sort,
            pageSize
          },
          siteFilterConfig: orgConfig
        })
      )
    );
  }

  searchWithoutSiteFilter(
    query: string,
    page: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<MessageModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'quote-response/message',
      query: { filterString: query, pageIndex: page, sort, pageSize }
    });
  }

  markStatusByIds(ids: number[], read: boolean): Observable<BulkActionResultModel> {
    return this.http
      .put<BulkActionResultModel>(`${SERVER_API_URL}/quote-response/message/bulk-action`, {
        action: read ? 'READ' : 'UNREAD',
        ids
      })
      .pipe(tap((_) => this.updateMessageCount()));
  }

  markStatusByQuery(read: boolean, query: string = ''): Observable<BulkActionResultModel> {
    return this.http
      .put<BulkActionResultModel>(`${SERVER_API_URL}/quote-response/message/bulk-action`, {
        action: read ? 'READ' : 'UNREAD',
        query
      })
      .pipe(tap((_) => this.updateMessageCount()));
  }

  get(id: number, quoteResponseId: number): Observable<MessageModel> {
    return this.http.get<MessageModel>(`${SERVER_API_URL}/quote-response/${quoteResponseId}/message/${id}`);
  }

  getMessageCount() {
    return this.messageCountUpdated$.pipe(
      switchMap((_) => this.search('read = false', 0, 0, null)),
      map((result) => result.total)
    );
  }

  updateMessageCount(): void {
    this.messageCountUpdatedSource.next(null);
  }
}
