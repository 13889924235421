import { LabelValue } from '@lib-resource/label-value.model';

export function generateFilterString(value) {
  if (!value) {
    return '';
  }
  return `name ~ '${value}' or description ~ '${value}'`;
}

export function icdToLabelValue(icdCodes: any[]): LabelValue<string, string>[] {
  return icdCodes.map((icd) => ({
    label: `${icd.name} - ${icd.description}`,
    value: icd.name
  }));
}
