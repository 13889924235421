export enum OrgProduct {
  QUOTE = 'quoteLinqRole',
  QUOTE_RX = 'quoteLinqRxRole',
  SMART = 'smartLinqRole',
  RINGMASTER = 'ringmasterRole'
}

export type OrgProductType = OrgProduct.QUOTE | OrgProduct.QUOTE_RX | OrgProduct.SMART | OrgProduct.RINGMASTER;

export const OrgMultiSelectProducts = [OrgProduct.QUOTE, OrgProduct.QUOTE_RX];
