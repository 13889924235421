import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-boolean-field',
  template: `
    <div class="checkbox-container">
      <mat-checkbox
        (change)="_valueChanged($event.checked)"
        color="accent"
        [formControl]="control"
        [required]="required"
        truncateText
      >
        <mat-label *ngIf="!inlineNoLabel" class="large-label"> {{ label }} </mat-label>
      </mat-checkbox>
    </div>
  `,
  styleUrls: ['../../form-lib.scss'],
  styles: [
    `
      .checkbox-container {
        padding: 0.25rem 0 1.25rem;
      }
    `
  ]
})
export class CheckboxFieldComponent extends BaseFieldComponent {}
