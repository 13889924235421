import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuoteResponseService } from '@common/services/quote-response.service';
import {
  ExtractFileModel,
  ExtractTextModel,
  ExtractType
} from '@common/components/quote/extract-text-dialog/model/extract-text.model';
import { of, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { FileDocType, FileExtModel, FileModel } from '@file-upload-lib/file.model';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ExtractTextService } from '@common/components/quote/extract-text-dialog/service/extract-text.service';
import { ExcelTemplateModel } from '@main/org-excel-config/model/org-excel-config.model';

@Component({
  selector: 'app-excel-extract',
  template: `
    <div class="rmt-card" *ngIf="templates?.length > 0" [appInProgressIndicator]="processing | async">
      <fa-icon [icon]="infoIcon" class="color-rmt-dark-tint-1"></fa-icon>
      An Excel File of your proposal is required to continue.
      <app-file-upload
        labelText="Add Excel File"
        [multi]="false"
        [extensions]="'xlsx'"
        (selectFile)="onSelectFile($event)"
      ></app-file-upload>
      <div class="pt-3">
        <form [formGroup]="form" class="collapsible-form dense excel-import-form">
          <mat-form-field>
            <mat-label>Select from Supporting Files</mat-label>
            <mat-select formControlName="fileId">
              <mat-option> -- </mat-option>
              <mat-option *ngFor="let item of supportFiles$ | async" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pt-3">
            <mat-label>Select Template</mat-label>
            <mat-select formControlName="templateId">
              <mat-option
                *ngFor="let item of templates"
                [value]="item.id"
                [matTooltip]="itemDisplay(item)"
                matTooltipClass="single-line-tool-tip"
                matTooltipPosition="above"
              >
                {{ itemDisplay(item) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div *ngIf="templates?.length === 0">
      <p class="color-warn">
        <strong>No Templates Configured:</strong>
      </p>
      <p>You organization currently has no Excel templates. Contact support to create them.</p>
    </div>
  `,
  styleUrls: ['./excel-extract.component.scss']
})
export class ExcelExtractComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    fileId: new UntypedFormControl(null, Validators.required),
    templateId: new UntypedFormControl(null, Validators.required)
  });

  supportFiles$;
  supportFileList = [];
  supportFileEvents = {};
  tmpFileId = 0;

  infoIcon: IconDefinition = faInfoCircle;

  subs = new Subscription();

  @Input() responseId: number;
  @Input() templates: ExcelTemplateModel[];
  @Output() valid = new EventEmitter<boolean>();
  @Output() processing = new EventEmitter<boolean>();
  @Output() extractedResponse = new EventEmitter<ExtractFileModel>();

  processingFile: File;

  constructor(
    private quoteResponseService: QuoteResponseService,
    private extractTextService: ExtractTextService
  ) {}

  ngOnInit() {
    this.supportFiles$ = this.quoteResponseService.getSupportFiles(this.responseId).pipe(
      map((rmtFiles) =>
        rmtFiles.filter(
          (rmtFile) =>
            !rmtFile.versionId &&
            rmtFile.docType === FileDocType.PROPOSAL &&
            rmtFile.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      ),
      tap((result) => (this.supportFileList = result))
    );
    this.valid.emit(this.form.valid);
    this.subs.add(this.form.valueChanges.subscribe(() => this.valid.emit(this.form.valid)));
    if (this.templates?.length === 1) {
      this.form.get('templateId').setValue(this.templates[0].id);
    }
  }

  onSelectFile(event) {
    if (event.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const id = --this.tmpFileId;
      this.supportFileList.push({ name: event.name, id: id });
      this.supportFileEvents[id] = event;
      this.form.get('fileId').setValue(id);

      this.supportFiles$ = of(this.supportFileList);
    }
  }

  requestExcel() {
    if (this.form.invalid) {
      this.form.markAsDirty();
      this.form.markAsTouched();
      return;
    }

    this.processing.emit(true);
    const { templateId, fileId } = this.form.value;
    if (fileId < 0) {
      const file = this.supportFileEvents[fileId];
      this.extractTextService
        .extractExcel(this.responseId, templateId, null, file)
        .pipe(finalize(() => this.processing.emit(false)))
        .subscribe((result) => {
          this.processingFile = file;
          this.processResult(result);
        });
    } else {
      this.extractTextService
        .extractExcel(this.responseId, templateId, fileId, null)
        .pipe(finalize(() => this.processing.emit(false)))
        .subscribe((result) => {
          this.processResult(result);
        });
    }
  }

  private processResult(result: ExtractTextModel) {
    const filesToSave: FileExtModel[] = [];
    let supportFileProcessed: FileModel = new FileModel();
    if (this.processingFile) {
      this.createFileExtModel(this.processingFile).then((fileExtModel) => {
        filesToSave.push(fileExtModel);

        this.emitExtractedResponse(result, filesToSave, supportFileProcessed);
      });
    } else {
      const supportFile = this.supportFileList.find((sf) => sf.id === this.form.value.fileId);
      if (supportFile) {
        supportFileProcessed.id = this.form.value.fileId;
        supportFileProcessed.name = supportFile.name;
        supportFileProcessed.mimeType = supportFile.mimeType;
      }

      this.emitExtractedResponse(result, filesToSave, supportFileProcessed);
    }
  }

  private emitExtractedResponse(
    result: ExtractTextModel,
    filesToSave: FileExtModel[],
    supportFileProcessed: FileModel
  ) {
    this.extractedResponse.emit(
      new ExtractFileModel(
        result,
        filesToSave,
        ExtractType.EXCEL,
        supportFileProcessed.name ? supportFileProcessed : null
      )
    );
  }

  // Create a new File using the File read in. This essentially takes a snapshot of the File
  // when it gets read in rather than holding onto a reference to the file system. This is done
  // to avoid errors when a user opens the Excel file locally after selecting it for "extract".
  private createFileExtModel(file: File): Promise<FileExtModel> {
    return new Promise((resolve, reject) => {
      const fileExtModel = new FileExtModel();
      fileExtModel.fileModel = new FileModel();
      fileExtModel.fileModel.docType = FileDocType.PROPOSAL;

      let fileReader = new FileReader();

      fileReader.onload = (event) => {
        let blob = new Blob([new Uint8Array(fileReader.result as ArrayBuffer)]);
        let newFile = new File([blob], file.name, { type: file.type });

        fileExtModel.file = newFile;
        resolve(fileExtModel);
      };

      fileReader.onerror = (event) => {
        console.error('Error reading file: ', event);
        reject(event);
      };

      fileReader.readAsArrayBuffer(file);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  itemDisplay(item: ExcelTemplateModel) {
    return `${item.name} ${!!item.description ? `| ${item.description}` : ''}`;
  }
}
