import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AggResultGroupModel } from '@common/models/agg.model';
import { BenefitPlanModel } from '@common/models/benefit-plan.model';
import { DomesticProviderModel } from '@common/models/domestic-provider.model';
import { FindRequestModel, StatsRequestModel } from '@common/models/find-request.model';
import { Page } from '@common/models/page.model';
import { UrlUtilService } from '@common/services/url-util.service';
import { LabelValue } from '@lib-resource/label-value.model';
import { FilterConfig, HttpResourceService } from '@main/http-resource.service';
import { SiteFilterOptions } from '@main/store/site-filter/site-filter.models';
import { PolicyComment } from '@smart/component/policy/model/policy-note.model';
import { PolicyTierModel } from '@smart/component/policy/model/policy-tier.model';
import { PolicyModel, PolicyStatsByMonthModel } from '@smart/component/policy/model/policy.model';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CopyPolicyModel } from '@smart/component/policy/model/copy-policy.model';
import { ClaimLineMinModel } from '@smart/component/claim/model/claim.model';

const siteFilterConfig = [
  {
    siteFilterKey: SiteFilterOptions.ACCOUNT,
    dataKey: 'accountId'
  },
  {
    siteFilterKey: SiteFilterOptions.POLICY,
    dataKey: 'id'
  }
];

@Injectable({
  providedIn: 'root'
})
export class PolicyHttpService {
  constructor(
    protected http: HttpClient,
    private httpResource: HttpResourceService,
    private urlUtil: UrlUtilService
  ) {}

  getDomesticProviders(id): Observable<DomesticProviderModel[]> {
    return this.httpResource.get<DomesticProviderModel[]>(`policy/${id}/domestic-provider`);
  }

  updateDomesticProviders(id, data) {
    return this.httpResource.put<DomesticProviderModel[]>(`policy/${id}/domestic-provider`, data);
  }

  getBenefitPlans(id): Observable<BenefitPlanModel[]> {
    return this.httpResource.get<BenefitPlanModel[]>(`policy/${id}/benefit-package`);
  }

  updateBenefitPlans(id, data) {
    return this.httpResource.put<BenefitPlanModel[]>(`policy/${id}/benefit-package`, data);
  }

  get(id: number): Observable<PolicyModel> {
    return this.httpResource.get<PolicyModel>(`policy/${id}`);
  }

  update(id: number, value) {
    return this.httpResource.put<PolicyModel>(`policy/${id}`, value);
  }

  delete(policy: PolicyModel): Observable<PolicyModel> {
    return this.httpResource.delete<PolicyModel>(`policy/${policy.id}`, policy.orgId);
  }

  exists(policyId: number, name: string): Observable<boolean> {
    let params = new HttpParams(
      this.urlUtil.buildOption({
        fromObject: {
          name: name
        }
      })
    );

    if (policyId) {
      params = params.set('id', `${policyId}`);
    }
    return this.httpResource.getWithOptions<boolean>('policy/exists', { params: params });
  }

  create(policyModel: PolicyModel): Observable<PolicyModel> {
    return this.httpResource.post<PolicyModel>(`policy`, policyModel);
  }

  save(id: number, policyModel: PolicyModel): Observable<PolicyModel> {
    return this.httpResource.put<PolicyModel>(`policy/${id}`, policyModel);
  }

  search(
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[],
    siteFilterConfigOverride?: FilterConfig[]
  ): Observable<Page<PolicyModel>> {
    return this.httpResource.query({
      path: 'policy',
      query: { filterString, pageIndex, pageSize, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
    });
  }

  searchWithOrg(
    orgId: number,
    filterString: string,
    pageIndex: number,
    pageSize: number,
    sort: string[]
  ): Observable<Page<PolicyModel>> {
    return this.httpResource.queryWithoutOrg({
      path: `org/${orgId}/policy`,
      query: { filterString, pageIndex, pageSize, sort }
    });
  }

  searchAgg(findReq: FindRequestModel): Observable<Page<PolicyModel>> {
    return this.httpResource.queryAgg({
      path: 'policy',
      siteFilterConfig,
      findReq
    });
  }

  searchStats(req: StatsRequestModel): Observable<AggResultGroupModel> {
    return this.httpResource.queryStats<AggResultGroupModel>({
      path: 'policy/stats-by-month',
      statsReq: req,
      siteFilterConfig
    });
  }

  getStatsForPolicy(policyId: number): Observable<AggResultGroupModel> {
    return this.httpResource.get<AggResultGroupModel>(`policy/${policyId}/stats-by-policy`);
  }

  getClaimAppliedDeductibleForPolicy(policyId: number): Observable<AggResultGroupModel> {
    return this.httpResource.get<AggResultGroupModel>(`policy/${policyId}/claims-applied-deductible`);
  }

  /**
   * Returns Observable of LabelValue[] for the policies available to the user to select from.
   * The policies are returned in effectiveDate desc order. Default the selection of a policy
   * to the first one on the list. Load the data table (i.e. agg accommodation report)
   * for the default selected policy.
   */
  public getAvailablePoliciesForSelection(): Observable<LabelValue[]> {
    return combineLatest([this.getPoliciesOrderByEffectiveDateDesc(), this.httpResource.policy$]).pipe(
      map(([allList, siteWideFilteredList]) => {
        const allPoliciesMap: Map<string, LabelValue> = new Map();
        allList.forEach((lv) => allPoliciesMap.set(lv.label, lv));
        let availablePolicies: LabelValue[] = [];
        if (siteWideFilteredList && siteWideFilteredList.length > 0) {
          siteWideFilteredList.forEach((f) => availablePolicies.push(allPoliciesMap.get(f.name)));
        } else {
          availablePolicies = allList;
        }
        return availablePolicies;
      })
    );
  }

  private getPoliciesOrderByEffectiveDateDesc(): Observable<LabelValue[]> {
    return this.search('', 0, 1000, ['-effectivedate']).pipe(
      map(({ content }) => content.map((p) => ({ label: p.name, value: p }) as LabelValue))
    );
  }

  getPolicyStatsByMonth(policyId: number): Observable<PolicyStatsByMonthModel[]> {
    return this.httpResource.get<PolicyStatsByMonthModel[]>(`policy/${policyId}/policy-stats-by-month`);
  }

  updatePolicyStatsByMonth(policyId, data: PolicyStatsByMonthModel): Observable<PolicyStatsByMonthModel> {
    return this.httpResource.put<PolicyStatsByMonthModel>(`policy/${policyId}/policy-stats-by-month`, data);
  }

  getComments(id: number): Observable<PolicyComment[]> {
    return this.httpResource.get<PolicyComment[]>(`policy/${id}/comment`);
  }

  getCommentsOldestFirst(id: number): Observable<PolicyComment[]> {
    return this.getComments(id).pipe(map((comments) => comments.reverse()));
  }

  addComment(id: number, comment: PolicyComment) {
    return this.httpResource.post(`policy/${id}/comment`, comment);
  }

  updateComment(id: number, comment: PolicyComment) {
    return this.httpResource.put(`policy/${id}/comment/${comment.id}`, comment);
  }

  deleteComment(id: number, commentId: number) {
    return this.httpResource.delete(`policy/${id}/comment/${commentId}`);
  }

  getPolicyTiers(id: number) {
    return this.httpResource.get<PolicyTierModel[]>(`policy/${id}/policy-tier`);
  }

  updatePolicyTiers(id: number, policyTiers: PolicyTierModel[]) {
    return this.httpResource.put<PolicyTierModel[]>(`policy/${id}/policy-tier`, policyTiers);
  }

  copyPolicy(id: number, copyPolicy: CopyPolicyModel) {
    return this.httpResource.post<PolicyModel>(`policy/${id}/copy`, copyPolicy);
  }

  findClaimLineQl(
    orgId: number,
    policyId: number,
    filterString: string,
    pageSize: number,
    siteFilterConfigOverride?: FilterConfig[],
    pageIndex: number = 0,
    sort: string[] = []
  ): Observable<Page<ClaimLineMinModel>> {
    return this.httpResource.queryWithoutOrg({
      path: `org/${orgId}/policy/${policyId}/claim/line`,
      query: { filterString, pageSize, pageIndex, sort },
      siteFilterConfig: siteFilterConfigOverride ? siteFilterConfigOverride : siteFilterConfig
    });
  }
}
