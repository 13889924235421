import { OrgMemberModel } from '@common/models/org-mem.model';

export namespace OrgMemberships {
  export class Reset {
    static readonly type = '[Main] Reset OrgMemberships';
  }
  export class Get {
    static readonly type = '[Main] Get OrgMemberships';
  }
  export class GetSuccess {
    static readonly type = '[Main] Get OrgMemberships Success';
    constructor(public orgMemberships: OrgMemberModel[]) {}
  }
}
