@if (userJobs$ | async; as userJobs) {
  @if (userJobs.total > 0) {
    <div class="user-job-progress-banner-container">
      @for (job of userJobs.content; track job) {
        <div class="user-job-progress-banner">
          <div><fa-icon [icon]="infoIcon" class="info-icon"></fa-icon></div>
          <div class="user-job-desc">
            {{ job.description }} (Started {{ job.createdDate | appDateTime }}) - {{ job.message }}
          </div>
          <div class="user-job-status">
            <div>{{ getStatusText(job.status) }}</div>
            @if (job.status === UserJobStatus.IN_PROCESS) {
              <fa-icon style="font-size: 1.5rem" [icon]="loadingIcon" [spin]="true"></fa-icon>
            } @else {
              <fa-icon [icon]="closeIcon" class="info-icon" (click)="clearUserJob(job)"></fa-icon>
            }
          </div>
        </div>
      }
      @if (userJobs.total > 5) {
        <div class="user-job-progress-banner">
          <div><fa-icon [icon]="infoIcon" class="info-icon"></fa-icon></div>
          <div class="user-job-desc">There are {{ userJobs.total - 5 }} additional jobs available</div>
          <div></div>
        </div>
      }
    </div>
  }
}
