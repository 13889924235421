import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileModel } from '@file-upload-lib/file.model';
import { Observable } from 'rxjs';
import {
  FileUploadDialogComponent,
  FileUploadDialogData
} from './components/file-upload-dialog/file-upload-dialog.component';
import {
  FileUploadWithOptionsDialogComponent,
  FileUploadWithOptionsDialogData
} from './components/file-upload-with-options-dialog/file-upload-with-options-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  uploadFile(url: string, file: File, form?): Observable<FileModel> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (form) {
      Object.keys(form).forEach((key) => {
        formData.append(key, `${form[key]}`);
      });
    }
    return this.http.post<FileModel>(url, formData);
  }

  openUploadDialog(data: FileUploadDialogData): MatDialogRef<FileUploadDialogComponent> {
    return this.dialog.open(FileUploadDialogComponent, {
      data,
      width: '500px'
    });
  }

  openUploadWithOptionsDialog(
    data: FileUploadWithOptionsDialogData
  ): MatDialogRef<FileUploadWithOptionsDialogComponent> {
    return this.dialog.open(FileUploadWithOptionsDialogComponent, {
      data,
      width: '500px'
    });
  }
}
