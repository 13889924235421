import { ElementRef, InjectionToken, NgZone, TemplateRef } from '@angular/core';

export type PopoverPositionX = 'before' | 'after';

export type PopoverPositionY = 'above' | 'below';

export type PopoverTriggerEvent = 'click' | 'hover' | 'none';

export type PopoverScrollStrategy = 'noop' | 'close' | 'block' | 'reposition';

export interface PopoverPanel {
  positionX: PopoverPositionX;
  positionY: PopoverPositionY;
  containerPositioning: boolean;
  scrollStrategy: PopoverScrollStrategy;
  targetOffsetX?: number;
  targetOffsetY?: number;
  closeDisabled: boolean;
  templateRef: TemplateRef<any>;
  zone?: NgZone;
  setPositionClasses: (x: PopoverPositionX, y: PopoverPositionY) => void;
}

/**
 * Configuration for opening a popover with the Popover service.
 */
export interface PopoverConfig<T = any> {
  backdropClass?: string;
  data?: T;
  disableClose?: boolean;
  panelClass?: string | string[];
  positionX?: PopoverPositionX;
  positionY?: PopoverPositionY;
  triggerEvent?: PopoverTriggerEvent;
  triggerDelay?: number;
  targetOffsetX?: number;
  targetOffsetY?: number;
  closeOnClick?: boolean;
}

/**
 * Injection token that can be used to access the data that was passed in to a popover.
 * */
export const POPOVER_CONFIG = new InjectionToken('popover.config');
export const POPOVER_DATA = new InjectionToken('popover.data');
