export enum RxDrugListType {
  GLOBAL_BIOSIMILAR = 'GLOBAL_BIOSIMILAR',
  LDD = 'LDD',
  SDL = 'SDL'
}

export class RxDrugModel {
  id?: number;
  orgId?: number;
  quoteRequestId?: number;
  accountId?: number;
  listType?: RxDrugListType;
  description?: string;
}
