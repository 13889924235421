import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Pipe({
  name: 'propertyFilter',
  pure: true
})
export class PropertyFilterPipe implements PipeTransform {
  transform(items: any[], property: string, value: any, inverse = false): any {
    if (!items || !property) {
      return items;
    }

    return items.filter((item) => (get(item, property) === value ? !inverse : inverse));
  }
}
