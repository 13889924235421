import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFileSelect]'
})
export class FileSelectDirective implements OnInit {
  fileInput: HTMLInputElement;
  @Input() multi: boolean;
  @Input() extensions: string;
  @Output() selectFile = new EventEmitter<FileList>();

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    this.fileInput = this.renderer.createElement('input');
    this.renderer.setProperty(this.fileInput, 'type', 'file');
    if (this.multi) {
      this.renderer.setAttribute(this.fileInput, 'multiple', '');
    }
    if (this.extensions) {
      this.renderer.setAttribute(this.fileInput, 'accept', this.extensions);
    }
    this.renderer.setStyle(this.fileInput, 'display', 'none');
    this.renderer.appendChild(this.el.nativeElement, this.fileInput);
    this.fileInput.addEventListener('change', (event) => {
      this.fileSelected(event);
    });
  }

  @HostListener('click')
  onClick() {
    this.fileInput.click();
  }

  private fileSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.selectFile.emit(event.target.files);
    }
    // setting the fileInput element's value to empty after every selection
    // so that the onChange event will fire if the same file is selected
    this.fileInput.value = '';
  }
}
