import { OrgMemberModel } from '@common/models/org-mem.model';
import { OrgFunction } from '@common/models/org-type.model';
import { SiteFilterStateModel } from '@main/store/site-filter/site-filter.models';
import { PolicyModel } from '@smart/component/policy/model/policy.model';
import { DateRange } from '@common/models/common.model';
import { OrgModel } from '@main/org/models/org.model';

export namespace SiteFilters {
  export class Set {
    static readonly type = '[Main] Set Site Filters';

    constructor(public siteFilters: SiteFilterStateModel) {}
  }

  export class Reset {
    static readonly type = '[Main] Reset Site Filters';
  }

  export class SetOrgFunction {
    static readonly type = '[Main] Set Org Function Site Filter';

    constructor(public orgFunction?: OrgFunction) {}
  }

  export class SetOrgMemberships {
    static readonly type = '[Main] Set Org Membership Site Filter';

    constructor(public orgIds: number[], public orgFunction?: OrgFunction) {}
  }

  export class SetQuoteOrgMemberships {
    static readonly type = '[Main] Set Quote-Linq Org Membership Site Filter';

    constructor(public orgMems: OrgMemberModel[]) {}
  }

  export class SetAccounts {
    static readonly type = '[Main] Set Accounts Site Filter';

    constructor(public accountIds: number[]) {}
  }

  export class SetPolicies {
    static readonly type = '[Main] Set Policies Site Filter';

    constructor(public policies: PolicyModel[]) {}
  }

  export class SetEffectiveDateRange {
    static readonly type = '[Main] Set Effective Date Site Filter';

    constructor(public dateRange: DateRange) {}
  }

  export class ShowEffectiveDateSiteFilter {
    static readonly type = '[Main] Show/Hide Effective Date Site Filter';

    constructor(public showEffectiveDateSiteFilter: boolean) {}
  }

  export class SetUnderwriters {
    static readonly type = '[Main] Set Underwriter Site Filter';

    constructor(public underwriters: OrgModel[]) {}
  }

  export class ShowUnderwriterFilter {
    static readonly type = '[Main] Show/Hide Underwriter Site Filter';

    constructor(public showUnderwriterFilter: boolean) {}
  }

  export class SetPbms {
    static readonly type = '[Main] Set PBM Site Filter';

    constructor(public pbmIds: number[]) {}
  }

  export class ShowPbmFilter {
    static readonly type = '[Main] Show/Hide PBM Site Filter';

    constructor(public showPbmFilter: boolean) {}
  }
  export class HideSiteFilter {
    static readonly type = '[Main] Hide Site Filter';

    constructor(public hideSiteFilter: boolean) {}
  }
}
