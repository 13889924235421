import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

@Component({
  selector: 'app-workspace-header-status-link',
  template: `
    <span class="label"> Stage: </span>
    <span class="data" [ngClass]="classes" (click)="emitStatusClick()">
      {{ data | dataFormatter: statusDef }}
    </span>
  `,
  styleUrls: ['workspace-header-status-link.component.scss']
})
export class WorkspaceHeaderStatusLinkComponent implements OnChanges {
  @Input() data;
  @Input() statusDef: DataDefModel = new DataDefModel({
    label: 'Status',
    key: 'status',
    type: DATA_TYPES.select
  });

  @Input() allowUpdateStatus: boolean = true;
  @Output() statusClick = new EventEmitter();

  classes = [];

  ngOnChanges(changes: SimpleChanges) {
    this.setClasses();
  }

  emitStatusClick() {
    if (!this.isReadOnlyStatus(this.data.status)) {
      this.statusClick.emit();
    }
  }

  private setClasses() {
    this.classes.length = 0;
    if (this.allowUpdateStatus && !this.isReadOnlyStatus(this.data.status)) {
      this.classes.push('clickable');
    }

    this.classes.push(this.data.status);
  }

  isReadOnlyStatus(s): boolean {
    return s === 'CLOSED' || s === 'LOST' || s === 'DECLINED';
  }
}
