import { FormModel } from '@form-lib/models/form.model';

export enum OrgFunction {
  BROKER = 'BROKER',
  OFFLINE_RESPOND = 'OFFLINE_RESPOND',
  PBM = 'PBM',
  RESPOND = 'RESPOND',
  TPA = 'TPA'
}

export function isRequestingOrg(func: OrgFunction) {
  return func === OrgFunction.TPA || func === OrgFunction.BROKER;
}

export function isStopLossRespondingOrg(func: OrgFunction) {
  return func === OrgFunction.OFFLINE_RESPOND || func === OrgFunction.RESPOND;
}

export function isRxRespondingOrg(func: OrgFunction) {
  return func === OrgFunction.PBM;
}

export function isRespondingOrg(func: OrgFunction) {
  return isStopLossRespondingOrg(func) || isRxRespondingOrg(func);
}

export function enableQLFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction) || isStopLossRespondingOrg(orgType?.orgFunction);
}

export function enableSLFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction) || isStopLossRespondingOrg(orgType?.orgFunction);
}

export function enableQLRXFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction) || isRxRespondingOrg(orgType?.orgFunction);
}

export function enableIntakeRoleFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction);
}

export function enableLevelFundedFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction) || isStopLossRespondingOrg(orgType?.orgFunction);
}

export function enableDYXMLFeature(orgType: OrgTypeModel) {
  return isRequestingOrg(orgType?.orgFunction);
}

export class OrgTypeModel {
  public id?: any;
  public name?: string;
  public icon?: string;
  public orgFunction?: OrgFunction;
  public editFormLayout?: FormModel;
  public searchResultLayout?: string;

  constructor(
    id?: any,
    name?: string,
    icon?: string,
    orgFunction?: OrgFunction,
    editFormLayout?: any,
    searchResultLayout?: string
  ) {
    this.id = id ? id : null;
    this.name = name ? name : null;
    this.icon = icon ? icon : null;
    this.orgFunction = orgFunction ? orgFunction : null;
    this.editFormLayout = editFormLayout ? editFormLayout : null;
    this.searchResultLayout = searchResultLayout ? searchResultLayout : null;
  }
}
