import { Injectable } from '@angular/core';
import { sortByProperty } from '@app/tools/array';
import { AsyncOptions } from '@form-lib/options/options.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { OrgMembershipSelectors } from '@main/store/org-membership/org-membership.selectors';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserOrgMembershipOptionService extends AsyncOptions<string> {
  key = 'userOrgMembership';

  constructor(private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.store.select(OrgMembershipSelectors.orgMembershipsWithSelectedProduct).pipe(
      map((orgMem) => ({
        content: this.generateLabelValues(orgMem),
        total: !orgMem ? 0 : orgMem.length
      }))
    );

  valuesFromKeys = (values, multi = true) => {
    if (!values?.length) return of(null);
    return of(multi ? this.generateLabelValues(values.sort(sortByProperty('orgName'))) : values.orgName);
  };

  generateLabelValues(items: any[]): LabelValue<string, string>[] {
    if (!items?.length) return [];
    return items.map((orgMember) => ({
      label: orgMember.orgName,
      value: orgMember
    }));
  }
}
