import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-card-label',
  template: `
    <h3 class="mat-h3 section-label label m-0 mr-4" *ngIf="label" [innerHTML]="label">
      <fa-icon *ngIf="icon" class="icon pr-1" [icon]="icon"></fa-icon>
    </h3>
  `,
  styles: [
    `
      .icon {
        font-weight: 500;
      }

      .section-label {
        display: inline-block;
      }
    `
  ]
})
export class CardLabelComponent {
  @Input() label: string;
  @Input() icon: IconDefinition;
}
