import { Component, Input } from '@angular/core';
import { AlertLevelType } from '@common/models/alert-level.model';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-alert-info',
  template: `
    <div class="alert-info-container pb-2">
      <fa-icon [ngClass]="'color-' + alertType" [icon]="infoCircleIcon" size="lg"></fa-icon>
      <strong *ngIf="label"> {{ label }}: </strong>
      <div class="pl-1">
        {{ message }}
      </div>
    </div>
  `,
  styles: [
    `
      .alert-info-container {
        display: grid;
        align-items: center;
        grid-gap: 0.125rem;
        grid-template-columns: auto auto 1fr;
      }
      .alert-info-container i {
        font-size: 1.5rem;
      }
    `
  ]
})
export class AlertInfoComponent {
  @Input() label: string;
  @Input() message: string;
  @Input() alertType: AlertLevelType;

  infoCircleIcon = faInfoCircle;
}
