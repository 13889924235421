import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NavigateToLogin } from '@auth/store/auth.actions';
import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/notifications/notification.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (!(err instanceof HttpErrorResponse)) {
            return;
          }
          const notificationService = this.injector.get(NotificationService);
          switch (err.status) {
            case 302:
              // if a 302 is returned, just ignore it here.  If the api response includes a Location header, the browser will redirect to that URI
              //   For the purposes of this SPA, a 302 is only returned for the authentication endpoint and will contain auth information in the payload.
              //   The purpose is to keep the payload from being cached on the internet (firewalls/proxies will not cache 302s)
              return;
            case 400:
              if (err.error) {
                if (err.error.errors) {
                  // response has a payload to parse
                  this.showFailed(err.error, notificationService);
                } else if (
                  err.error instanceof Blob &&
                  err.headers.get('Content-Type').startsWith('application/json')
                ) {
                  // special case where angular does not honor the content-type in the response payload because the original request was for a blob
                  this.blobToText(err.error).subscribe((errorObj) => {
                    this.showFailed(errorObj, notificationService);
                  });
                } else {
                  notificationService.failedNotification(`Bad request to the resource ${err.error.path}.`, [
                    err.error.message
                  ]);
                }
              } else {
                notificationService.failedNotification('Bad request from the browser.');
              }
              break;
            case 401:
              this.injector.get(Store).dispatch(new NavigateToLogin());
              break;
            case 403:
              if (err.error && err.error.message) {
                notificationService.failedNotification(`Permission Denied: ${err.error.message}`);
              } else {
                notificationService.failedNotification('Permission Denied');
              }
              break;
            case 404:
              if (err.error && err.error.message) {
                notificationService.failedNotification(err.error.message);
              } else {
                notificationService.failedNotification('Not found');
              }
              break;
            default:
              notificationService.failedNotification('Server Error occurred, please check the logs.');
          }
        }
      )
    );
  }

  private showFailed(err: any, notificationService) {
    let errorMessages = err.errors?.map((e) => `Field '${e.field}': '${e.defaultMessage}'`);
    if (!errorMessages) {
      errorMessages = [];
    }
    if (!!err.message) {
      errorMessages.push(err.message);
    }
    const message = `Bad Request to the resource ${err.path}`;
    notificationService.failedNotification(message, errorMessages);
  }

  private blobToText(errBlob: Blob): Observable<any> {
    return new Observable<string>((observer) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const responseText = (<any>e.target).result;
        observer.next(JSON.parse(responseText));
        observer.complete();
      };
      reader.readAsText(errBlob, 'utf-8');
    });
  }
}
