import { sortByProperty } from '@app/tools/array';
import { isRequestingOrg } from '@common/models/org-type.model';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OrgMembershipSelectors } from '@main/store/org-membership/org-membership.selectors';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Returns a list of orgs that are:
 * - ORGFUNCTION = requesting type
 * - User is a sysadmin or user is a member of the org
 */
export class RequestOrgByMembershipOptionService extends AsyncOptions<string> {
  key = 'requestOrgByMembership';
  labelKey = 'orgName';
  valueKey = 'orgId';

  constructor(private store: Store) {
    super();
  }

  filter = (control, asyncOptionDeps, value, pageIndex, pageSize, _?, requiredQuery?) =>
    this.store.select(OrgMembershipSelectors.orgMembershipsWithSelectedProduct).pipe(
      map((orgMems) => orgMems.filter((orgMem) => isRequestingOrg(orgMem.orgFunction))),
      map((orgMems) => ({
        content: this.generateLabelValues(orgMems),
        total: !orgMems ? 0 : orgMems.length
      }))
    );

  valuesFromKeys = (values, multi = true) => {
    if (!values?.length) return of(null);
    return of(multi ? this.generateLabelValues(values.sort(sortByProperty('orgName'))) : values.orgName);
  };
}
