import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileDocType } from '@file-upload-lib/file.model';
import { FileService } from '@file-upload-lib/file.service';
import { faCheckCircle, faExclamationTriangle, faTrashCircle, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/notifications/notification.service';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['multi-file-upload.component.scss']
})
export class MultiFileUploadComponent implements OnInit {
  @Input() uploadUrl: string;
  @Input() showButtons: boolean = true;
  @Input() requireDocType: boolean = false;
  @Output() fileUploaded = new EventEmitter<FileItem>();
  @Output() fileQueueUpdated = new EventEmitter<FileItem[]>();
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean = false;
  form: UntypedFormGroup;

  @Input()
  docTypes: Array<FileDocType> = Object.keys(FileDocType) as any;

  @Input()
  displayedColumns = [
    'file.name',
    'formData.description',
    'formData.docType',
    'file.size',
    'progress',
    'status',
    'actions'
  ];

  datasource = [];
  successIcon = faCheckCircle;
  closeIcon = faXmark;
  warnIcon = faExclamationTriangle;
  deleteIcon = faTrashCircle;

  constructor(
    private notificationService: NotificationService,
    public fileService: FileService,
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.uploader = new FileUploader({
      parametersBeforeFiles: true,
      url: this.uploadUrl
    });
    this.uploader.onBuildItemForm = (fileItem, form) => {
      if (fileItem.formData && fileItem.formData.description) {
        form.append('description', fileItem.formData.description);
      }
      if (fileItem.formData && fileItem.formData.docType) {
        form.append('docType', fileItem.formData.docType);
      }
      return { fileItem, form };
    };
    this.uploader.onErrorItem = (item: FileItem, response: string) => {
      item['errorMessage'] = response;
    };
    this.uploader.onSuccessItem = (item: FileItem) => {
      item.remove();
      this.fileUploaded.emit(item);
      this.fileChange();
    };
  }

  fileChange() {
    this.emitFileQueueChange();
    // add form validator from form builder for docType for each fileItem, if not already done, so each field is individually managed
    this.uploader.queue.forEach((fileItem) => {
      if (!fileItem.formData.fg) {
        const controlConfigs = {
          docType: ['', this.requireDocType ? Validators.required : '']
        };
        const fg = this.fb.group(controlConfigs);
        fg.get('docType').markAsTouched();

        fileItem.formData.fg = fg;
      }
    });
    // ensures material recognizes value changes by reference
    this.datasource = [...this.uploader.queue];
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  showFileItemError(fileItem: FileItem): void {
    try {
      const errorMessage = JSON.parse(fileItem['errorMessage']);
      this.notificationService.failedNotification(`Upload Error: ${errorMessage['message']}`);
    } catch (e) {
      console.error(e);
      console.error(fileItem['errorMessage']);
      this.notificationService.failedNotification(`Upload Error`);
    }
  }

  disableAllUpload(): boolean {
    return (
      this.uploader.getNotUploadedItems().length === 0 ||
      this.uploader.getNotUploadedItems().filter((item) => this.tooLarge(item)).length > 0 ||
      this.uploader.getNotUploadedItems().filter((item) => this.zeroByteFile(item)).length > 0 ||
      this.uploader.getNotUploadedItems().filter((item) => this.missingDocType(item)).length > 0
    );
  }

  tooLarge(item: FileItem): boolean {
    return item.file && this.fileService.getMaxUploadBytes() < item.file.size;
  }

  zeroByteFile(item: FileItem): boolean {
    return item.file && item.file.size === 0;
  }

  missingDocType(item: FileItem): boolean {
    if (this.requireDocType) {
      return !item.formData.docType;
    }
    return false;
  }

  showTooLargeError(fileItem: FileItem): void {
    this.fileService.showTooLargeError(fileItem.file.size);
  }

  showZeroByteFileError(): void {
    this.fileService.zeroByteFileError();
  }

  removeItem(fileItem) {
    fileItem.remove();
    this.fileChange();
  }

  clearAllItems() {
    this.uploader.clearQueue();
    this.fileChange();
  }

  emitFileQueueChange() {
    // emit the file queue but don't add ones that are invalid (too big, zero bytes)
    this.fileQueueUpdated.emit(
      this.uploader.queue.filter((fileItem) => !this.zeroByteFile(fileItem) && !this.tooLarge(fileItem))
    );
  }
}
