import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { faAngleDown, faAngleRight, faGreaterThan, faLessThan, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { DataDefModel } from '@lib-resource/data-def.model';
import { getValue } from '@lib-resource/data.utils';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { stringifyParams } from '@app/tools/url';
import { deriveActionLabel } from '../data-table.utils';
import { ActionDef, TableSelectionType } from '../models/data-table.model';
import { TableDataSource } from './table-data-source';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  getValue = getValue;
  dataInputSource = new BehaviorSubject<any>(null);
  columnInputSource = new BehaviorSubject<DataDefModel[]>(null);
  actionInputSource = new BehaviorSubject<ActionDef[]>(null);
  dataSource: TableDataSource;
  _actions: ActionDef[];
  @Input() selectedRowColor: string = 'bg-accent'; // Default to accent color. Can be overridden if desired.
  @Input() pageSizeOptions = [5, 10, 20, 50, 100, 250];
  @Input() pageSize = 20;
  @Input() paginated = true;
  @Input() minimalPagination = false; // This flag determines if the table will have '<' and '>' pagination buttons only.
  @Input() rowTooltipColumn;
  @Input() loading;
  @Input()
  get pageIndex() {
    return this.paginator ? this.paginator.pageIndex : 0;
  }

  set pageIndex(value) {
    if (!this.paginator) return;
    this.paginator.pageIndex = value;
  }

  @Input() currentSort: string[];
  @Input() withExpansion = false;
  @Input() noSort = false;
  @Input() expansionData;
  @Input() expansionColumns;
  @Input('aria-label') ariaLabel: string;
  @Input() columns: DataDefModel[];
  @Input() displayCols: string[];
  @Input() actionColumnName: string = 'Actions';

  @Input() showSelectAll = true;

  @Input() data;
  @Input() inlineActions = false;
  @Input()
  set actions(value: ActionDef[]) {
    this._actions = value;
    this.actionInputSource.next(value);
  }

  get actions(): ActionDef[] {
    return this._actions;
  }

  @Input() total;

  @Input() selectionType: TableSelectionType = 'none';
  @Input() showRadioForSingleSelect: boolean = false;
  @Input() trackByKey: string = 'id';
  @Input() selectedRow;
  @Input() allRowsSelected = false;
  @Input() selectedRows: any[];
  @Input() expandableWhen: (_: any) => boolean;

  @Output() sortChange = new EventEmitter<string[]>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() itemSelected = new EventEmitter<{ row: any; selected: boolean }>();
  @Output() selectAll = new EventEmitter<MatCheckboxChange>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() highlightWhen = (_) => false;

  expandedIcon = faAngleDown;
  notExpandedIcon = faAngleRight;
  warningIcon = faWarning;
  lessThanIcon = faLessThan;
  greaterThanIcon = faGreaterThan;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.setSort();

    this.sort.sortChange.subscribe((value) => {
      const columnDef: DataDefModel = this.columns.find((col) => col.key === value.active);
      let newValue = value.active;
      if (columnDef && columnDef.queryKey) {
        newValue = columnDef.queryKey;
      }
      let sortValue = [];
      if (value.direction === 'asc') {
        sortValue = [newValue];
      } else if (value.direction === 'desc') {
        sortValue = [`-${newValue}`];
      }
      this.sortChange.emit(sortValue);
    });
  }

  ngAfterViewInit() {
    // Wait till view inits to pass paginator and sort references
    if (this.paginated) {
      // when the page changes, clear the selection as well as emit the selection change
      this.paginator.page
        .pipe(tap((_) => this.selectAll.emit({ checked: false, source: null })))
        .subscribe(this.pageChange);
      this.paginator._formFieldAppearance = 'fill'; // hack to use the fill appearance instead of outline
    }
    setTimeout(() => {
      this.dataSource = new TableDataSource({
        data: this.dataInputSource,
        columns: this.columnInputSource,
        actions: this.actionInputSource
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges({ data, total, columns, pageIndex, displayCols, selectedRows, selectionType }: SimpleChanges): void {
    if (data) {
      if (this.paginator) {
        this.paginator.length = this.total;
        if (total) {
          // Reset page index on detected "total" change, to correct range display.  Only change if the page is outside of the result set size
          if (this.total <= this.paginator.pageIndex * this.paginator.pageSize && this.paginator.pageIndex > 0) {
            const previousPageIndex = this.pageIndex;
            this.pageIndex = Math.max(0, this.paginator.pageSize > 0 ? this.total / this.paginator.pageSize - 1 : 0);
            this.pageChange.emit({
              pageIndex: this.pageIndex,
              previousPageIndex: previousPageIndex,
              pageSize: this.paginator.pageSize,
              length: this.total
            });
          }
        }
      }
      this.dataInputSource.next(data.currentValue);
    }
    if (selectionType) {
      if (this.dataSource && this.dataSource.data) {
        this.dataSource.data.next([...this.dataSource.data.value]);
      }
    }
    if (columns || displayCols || selectionType) {
      this.columnsChanged();
    }
  }

  columnsChanged() {
    let cols = [];
    if (this.withExpansion) {
      cols.push({
        label: '',
        key: 'expandable',
        noQuery: true,
        noSort: true,
        type: 'expandable',
        visible: true
      });
    } else if (this.selectionType === 'multi') {
      cols.push({
        label: '',
        key: 'rowSelect',
        type: 'rowSelect',
        noQuery: true,
        noSort: true,
        visible: true
      });
    } else if (this.selectionType === 'single' && this.showRadioForSingleSelect) {
      cols.push({
        label: '',
        key: 'rowSingleSelect',
        type: 'rowSingleSelect',
        noQuery: true,
        noSort: true,
        visible: true
      });
    }
    if (this.displayCols) {
      const filteredCols = this.columns.filter((col) => this.displayCols.includes(col.key));
      cols.push(...filteredCols);
    } else {
      cols.push(...this.columns);
    }
    if (!this.paginated && !this.columns.some((col) => col.visible)) {
      cols = cols.map((def) => ({ ...def, visible: true }));
    }
    this.columnInputSource.next(cols);
  }

  onSelectionChange(event, row) {
    this.itemSelected.emit({
      row,
      selected: event.checked
    });
  }

  onSelectAll(event) {
    this.selectAll.emit(event);
  }

  setSort() {
    if (!this.currentSort || !this.currentSort.length) return;
    if (this.currentSort[0][0] === '-') {
      this.sort.sort(<MatSortable>{ id: this.currentSort[0].slice(1), start: 'desc' });
    } else {
      this.sort.sort(<MatSortable>{ id: this.currentSort[0], start: 'asc' });
    }
  }

  whenWithSelection = () => this.selectionType === 'single';

  whenWithoutSelection = (i, row) => this.selectionType !== 'single' && !this.whenExpandable(null, row);

  whenExpandable = (i, row) => this.withExpansion && this.expandableWhen(row);

  selectRow(row) {
    this.itemSelected.emit({
      row,
      selected: this.selectedRow ? this.selectedRow[this.trackByKey] !== row[this.trackByKey] : true
    });
  }

  // for multiple (multi) selections
  @Memoize()
  isChecked(row, selectedRows) {
    if (selectedRows && selectedRows.length) {
      return selectedRows.some((item) => item[this.trackByKey] === row[this.trackByKey]);
    }
    return false;
  }

  // for single selections
  isSelected(row) {
    if (this.selectedRow) {
      return this.selectedRow[this.trackByKey] === row[this.trackByKey];
    }
    return false;
  }

  isRowExpanded(row) {
    return row === this.selectedRow;
  }

  getTooltip(row): string {
    if (this.minimalPagination && this.rowTooltipColumn) {
      return getValue(row, this.rowTooltipColumn);
    }
  }

  @Memoize()
  randomWidth(_1, _2) {
    return `${Math.floor(Math.random() * 51) + 50}%`;
  }

  @Memoize()
  stringifyParams(linkParams) {
    return stringifyParams(linkParams);
  }

  calculateRowClasses(i, row) {
    const classes: Array<string> = [];
    if (this.isSelected(row) && !this.showRadioForSingleSelect) {
      classes.push(this.selectedRowColor);
    }
    if (i % 2 === 0) {
      classes.push('odd');
    }
    if (this.highlightWhen(row)) {
      classes.push('highlighted');
    }
    return classes;
  }

  toolTipText(action: ActionDef, row: any) {
    return action.disableIf && action.disableIf(row)
      ? !!action.derivedDisabledMessage
        ? action.derivedDisabledMessage(row)
        : action.disabledMessage
      : deriveActionLabel(action, row, action.label);
  }
}
