import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { closedReasonOptions, FundingTypeLabel } from '@common/models/quote-request.model';
import { QuoteResponseReason, quoteResponseStatusDef } from '@common/models/quote-response.model';
import {
  additionalDetailsDef,
  brokerDueDateDef,
  carrierDef,
  contractExpirationDateDef,
  coverSheetDef,
  createdDateDef,
  daysLeftDef,
  effectiveDateDef,
  firstSubmitDateDef,
  flaggedDrcsDef,
  fundingTypeDef,
  groupDef,
  groupIdDef,
  groupOwnerFirstNameDef,
  groupOwnerLastNameDef,
  idDef,
  isIncumbentDef,
  issuerDef,
  issuerIdDef,
  matchDexDef,
  modifiedDateDef,
  primaryGroupNumberDef,
  quoteDueDateDef,
  receivedDateDef,
  requestIdDef,
  requestNameDef,
  responderIdDef,
  secondaryGroupIdsDef,
  stageChangeDate,
  tagsDef,
  unreadMsgsDef
} from '@common/components/quote-response-list/columns.definitions';
import { LabelValue } from '@lib-resource/label-value.model';
import { FundingTypeModel } from '@common/models/funding-type.model';

export const lostReasonOptions: DataDefOption[] = [
  { value: QuoteResponseReason.LASER_CARVE_OUTS, label: 'Laser carve outs' },
  { value: QuoteResponseReason.MISSED_DEADLINE, label: 'Missed deadline' },
  { value: QuoteResponseReason.PRICING_NOT_COMPETITIVE, label: 'Pricing not competitive' },
  { value: QuoteResponseReason.RENEWED_WITH_INCUMBENT, label: 'Renewed with Incumbent' },
  { value: QuoteResponseReason.UNABLE_TO_FIRM_PROPOSAL, label: 'Unable to firm proposal' },
  { value: QuoteResponseReason.UNSATISFIED_CONTRACT_TYPE, label: 'Unsatisfied contract type' },
  { value: QuoteResponseReason.UNSATISFIED_DEDUCTIBLE, label: 'Unsatisfied deductible' },
  { value: QuoteResponseReason.UNSATISFIED_OTHER_REQUESTED_TERMS, label: 'Unsatisfied other requested terms' }
];

export const declinedReasonOptions: DataDefOption[] = [
  { value: QuoteResponseReason.CARRIER_HISTORY, label: 'Carrier history' },
  { value: QuoteResponseReason.INCOMPLETE_RFP_DATA, label: 'Incomplete request data' },
  { value: QuoteResponseReason.INELIGIBLE_INDUSTRY, label: 'Ineligible industry' },
  { value: QuoteResponseReason.POOR_LOSS_HISTORY, label: 'Poor loss history' },
  { value: QuoteResponseReason.POOR_PARTICIPATION_PERCENT, label: 'Poor participation %' },
  { value: QuoteResponseReason.PRICING_NOT_COMPETITIVE, label: 'Pricing not competitive' },
  { value: QuoteResponseReason.UNEVALUATED_PROVIDER_NETWORK, label: 'Unevaluated provider network' },
  { value: QuoteResponseReason.UNSATISFIED_CARRIER_REQUIREMENTS, label: 'Unsatisfied carrier requirements' }
];

export const fundingTypeOptions: LabelValue[] = [
  {
    label: FundingTypeLabel[FundingTypeModel.FULLY_INSURED],
    value: FundingTypeModel.FULLY_INSURED
  },
  {
    label: FundingTypeLabel[FundingTypeModel.LEVEL_FUNDED],
    value: FundingTypeModel.LEVEL_FUNDED
  },
  {
    label: FundingTypeLabel[FundingTypeModel.SELF_FUNDED],
    value: FundingTypeModel.SELF_FUNDED
  },
  {
    label: FundingTypeLabel[FundingTypeModel.NONE],
    value: FundingTypeModel.NONE
  }
];

export const lostReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: lostReasonOptions
});

export const lostReasonDef: DataDefModel = new DataDefModel({
  ...lostReasonDefWithoutLabel,
  label: 'Reason'
});

export const declinedReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: declinedReasonOptions
});

export const declinedReasonDef: DataDefModel = new DataDefModel({
  ...declinedReasonDefWithoutLabel,
  label: 'Reason'
});

export const closedReasonDefWithoutLabel: DataDefModel = new DataDefModel({
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: closedReasonOptions
});

export const closedReasonDef: DataDefModel = new DataDefModel({
  ...closedReasonDefWithoutLabel,
  label: 'Reason'
});

export const allReasonDef: DataDefModel = new DataDefModel({
  label: 'Reason',
  key: 'reason',
  type: DATA_TYPES.select,
  validators: { required: true },
  options: [...lostReasonOptions, ...declinedReasonOptions, ...closedReasonOptions].sort((a, b) =>
    a.label.localeCompare(b.label)
  )
});

export const reasonTextDef = new DataDefModel({
  label: 'Additional Details',
  key: 'reasonText',
  type: DATA_TYPES.textarea
});

export const reasonFundingTypeDef = new DataDefModel({
  label: 'Funding Type',
  key: 'fundingType',
  type: DATA_TYPES.select,
  options: fundingTypeOptions
});

export const columnDefs: DataDefModel[] = [
  idDef,
  matchDexDef,
  requestIdDef,
  requestNameDef,
  issuerIdDef,
  issuerDef,
  coverSheetDef,
  fundingTypeDef,
  groupIdDef,
  groupDef,
  primaryGroupNumberDef,
  secondaryGroupIdsDef,
  groupOwnerFirstNameDef,
  groupOwnerLastNameDef,
  responderIdDef,
  carrierDef,
  isIncumbentDef,
  flaggedDrcsDef,
  receivedDateDef,
  createdDateDef,
  effectiveDateDef,
  contractExpirationDateDef,
  brokerDueDateDef,
  quoteDueDateDef,
  daysLeftDef,
  modifiedDateDef,
  firstSubmitDateDef,
  stageChangeDate,
  quoteResponseStatusDef,
  allReasonDef,
  additionalDetailsDef,
  tagsDef,
  unreadMsgsDef
];

export const quoteResponseRequestDefaultCols = [
  idDef.key,
  requestNameDef.key,
  groupDef.key,
  carrierDef.key,
  effectiveDateDef.key,
  quoteResponseStatusDef.key,
  quoteDueDateDef.key,
  matchDexDef.key,
  allReasonDef.key,
  isIncumbentDef.key
];

export const quoteResponseDefaultCols = [
  idDef.key,
  issuerDef.key,
  requestNameDef.key,
  groupDef.key,
  quoteResponseStatusDef.key,
  allReasonDef.key,
  effectiveDateDef.key,
  quoteDueDateDef.key,
  matchDexDef.key,
  isIncumbentDef.key
];
