<app-dialog-close icon (click)="closePanel()"></app-dialog-close>
<app-dialog-label label="Apply Filter"></app-dialog-label>
<app-dialog-content class="pv-0">
  <div *ngIf="!definitions.length">
    There are no columns to filter.
  </div>
  <div class="filter-menu-wrapper" *ngIf="definitions.length">
    <div class="pr-4" style="overflow: auto">
      <div role="list" class="dense-layout grid filter-column-selector">
        <button
          mat-button
          class="filter-menu-column-label"
          [ngClass]="{ 'btn-accent': selectedColumn === column }"
          *ngFor="let column of renderedColumns"
          (click)="selectColumn(column)"
        >
          <span truncateText [innerHTML]="column.label"></span>
        </button>
      </div>
    </div>
    <div class="filter-menu-content-container p-4 dense">
      <app-simple-form
        style="padding-bottom: 2rem;"
        #operatorForm
        (valueChanges)="setFieldDef($event)"
        [alwaysEditing]="true"
        [data]="operatorFormData"
        [definitions]="operatorDef"
      ></app-simple-form>
      <app-simple-form
        style="padding-bottom: 2rem;"
        #queryForm
        *ngIf="formDef"
        [alwaysEditing]="true"
        [data]="queryFormData"
        [definitions]="formDef"
        (doSubmit)="applyQuery($event)"
      ></app-simple-form>
    </div>
  </div>
</app-dialog-content>
<app-dialog-actions>
  <button mat-raised-button color="primary" (click)="queryForm.submit()">Apply</button>
  <button mat-raised-button (click)="closePanel()">Cancel</button>
</app-dialog-actions>
