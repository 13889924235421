import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { StatsRequestModel } from '@common/models/find-request.model';

/**
 * Necessary to overcome Angular's odd choice to not url encode "+" signs.
 * They are decoded on the server side as spaces because that is what the
 * spec says it should do.
 *
 * https://github.com/angular/angular/issues/11058
 */
export class RmtUrlQueryEncoder extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    k = super.encodeKey(k);
    return k.replace(/\+/gi, '%2B');
  }

  encodeValue(v: string): string {
    v = super.encodeValue(v);
    return v.replace(/\+/gi, '%2B');
  }
}

@Injectable({
  providedIn: 'root'
})
export class UrlUtilService {
  buildParamsFromObject(obj): HttpParams {
    return new HttpParams({
      encoder: new RmtUrlQueryEncoder(),
      fromObject: obj
    });
  }

  buildOption(opts) {
    opts.encoder = new RmtUrlQueryEncoder();
    return opts;
  }

  buildSearchParams(q: string, page: number, pageSize: number, sort: string[], sid?: string): HttpParams {
    const opts: any = {
      fromObject: {}
    };
    opts.encoder = new RmtUrlQueryEncoder();
    let params = new HttpParams(opts);
    if (page >= 0) {
      params = params.append('page', page);
    }
    if (pageSize >= 0) {
      params = params.append('pageSize', pageSize);
    }
    if (q) {
      params = params.append('q', q);
    }
    if (sort) {
      sort.forEach((s) => {
        params = params.append('sort', s);
      });
    }
    if (sid) {
      params = params.append('sid', sid);
    }
    return params;
  }

  buildStatsRequest(req: StatsRequestModel): HttpParams {
    const opts = {
      encoder: new RmtUrlQueryEncoder()
    };

    let params = new HttpParams(opts);

    Object.keys(req).forEach((reqKey) => {
      if (req[reqKey]) {
        params = params.append(reqKey, req[reqKey]);
      }
    });

    return params;
  }
}
