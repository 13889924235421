import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGeneratorService } from '@form-lib/services/form-generator.service';
import { LabelValue } from '@lib-resource/label-value.model';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OptionsService } from '../../options/options.service';
import { BaseSelectFieldComponent } from '../base-select-field/base-select-field.component';

@Component({
  selector: 'app-single-select-filter-field',
  templateUrl: './single-select-filter-field.component.html',
  styleUrls: ['../../form-lib.scss', './single-select-filter-field.component.scss']
})
export class SingleSelectFilterFieldComponent extends BaseSelectFieldComponent
  implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(MatSelect, { static: true }) select: MatSelect;
  searchTextBoxControl = new UntypedFormControl();
  searchSub: Subscription;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  viewport: CdkVirtualScrollViewport;

  subs = new Subscription();
  selectFieldControl: AbstractControl;

  selectedOption: LabelValue;

  get selectedDisplay(): string {
    if (!!this.selectedOption) {
      return this.selectedOption.label;
    }

    if (!!this.noSelectionLabel) {
      return this.noSelectionLabel;
    }

    return null;
  }

  constructor(optionsService: OptionsService, private formGeneratorService: FormGeneratorService) {
    super(optionsService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.add(
      this.asyncOptionsService.valuesFromKeys(this.control.value, false, this.asyncExtras).subscribe((lv) => {
        if (!lv) {
          this.selectedOption = null;
        } else {
          this.selectedOption = lv[0];
        }
      })
    );
    this.selectFieldControl = this.formGeneratorService.generateFormGroup(
      [this.dataDef],
      undefined,
      undefined,
      this.control.value
    ).controls[this.dataDef.formKey];

    if (this.control.disabled) {
      // since the visual control is difference than the actual control, sync them up to be disabled
      this.selectFieldControl.disable();
    }

    this.searchSub = this.searchTextBoxControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => this._filter(value));

    // Initial retrieval of options data
    this._filter('');

    // watch for changes to the controls values..
    this.subs.add(
      this.control.valueChanges.subscribe((values) => {
        // ..only execute this when the control is disabled - something else has changed the values programmatically -
        // ..and not when the user is directly interacting with the control
        if (this.selectFieldControl.disabled && this.control.enabled) {
          // re-enable the select field control because the other hidden control is not disabled any longer, also clear the values
          this.selectFieldControl.enable();
          this.selectFieldControl.patchValue(null);
        } else if (this.selectFieldControl.enabled && this.control.disabled) {
          // disable the select control
          this.selectFieldControl.disable();
          this.selectFieldControl.patchValue(null);
        }
      })
    );

    this.asyncDependentSelectFieldSetup();
  }

  // This forces select trigger to float
  ngAfterContentInit(): void {
    Object.defineProperty(this.select, 'empty', {
      get: function() {
        return false;
      }
    });

    // override the markAsTouched function from the control so that we can react and touch the selectFieldControl
    // bind the correct 'this' reference to the function as well
    const originalMarkAsTouched = this.control.markAsTouched.bind(this.control);
    this.control.markAsTouched = ({ onlySelf }: { onlySelf?: boolean } = {}): void => {
      originalMarkAsTouched({ onlySelf });

      this.selectFieldControl.markAsTouched({ onlySelf });
    };
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
    this.subs.unsubscribe();
  }

  /**
   * Used to filter data based on search input
   */
  _filter(name: string): void {
    this.pageIndex = 0;
    this.filterValue = name;

    // Set selected values to retain the selected checkbox state
    if (this.asyncOptions) {
      this.getAsyncOptions();
    }
  }

  getNextBatch() {
    const total = this.viewport.getDataLength();
    const end = this.viewport.getRenderedRange().end;
    if (total - end >= 20) return;
    this.pageIndex++;
    this.getAsyncOptions();
  }

  updateOptions(value: LabelValue[]) {
    if (this.pageIndex === 0) {
      this.currentOptionsSource.next(value);
      this.viewport.scrollToIndex(0);
    } else {
      const newOptionsArray = [...this.currentOptionsSource.value, ...value];
      this.currentOptionsSource.next(newOptionsArray);
    }
    this.selectFieldControl.setValue(this.selectedOption?.value);
  }

  selectionChange(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.selectedOption = {
          label: event.source.viewValue,
          value: event.source.value
        };
        this.control.setValue(this.selectedOption.value);
        this._valueChanged(this.selectedOption);
        this.markControlAsDirtyAndClose();
      } else {
        this.selectedOption = null;
        this.control.setValue(null);
        this._valueChanged(null);
        this.markControlAsDirtyAndClose();
      }
    }
  }

  selectOpened() {
    this.selectFieldControl.setValue(this.control.value);
    this.viewport.scrollToOffset(1);
  }

  markControlAsDirtyAndClose() {
    this.pageIndex = 0;
    this.getAsyncOptions();
    this.control.markAsDirty();
    this.select.close();
  }
}
