import { Directive, Renderer2, ViewContainerRef } from '@angular/core';
import { InProgressIndicatorBase } from '@shared/directives/in-progress-indicator/in-progress-indicator.base';

@Directive({
  selector: '[appInProgressIndicator]:not(button)'
})
export class InProgressIndicatorDirective extends InProgressIndicatorBase {
  constructor(renderer2: Renderer2, viewRef: ViewContainerRef) {
    super(renderer2, viewRef);
  }
}
