import { Component } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faChevronsUp } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-file-level-funded-info',
  template: `
    <div class="level-funded-file-card mat-elevation-z2">
      <div class="level-funded-file-card-header">
        <h3 mat-subheader>
          <fa-icon class="info-icon" [icon]="infoIcon"></fa-icon>
          Data Requirements for Level Funded Quoting
        </h3>
      </div>
      <div *ngIf="showDetails" class="level-funded-file-card-body">
        <h4>Current Census (or sub/deb report for age-banded groups)</h4>
        <ul>
          <li>Non-fixed format (i.e. Word or Excel).</li>
          <li>
            Include name, DOB, coverage tier, zip code, plan election (if >1 plan option), and status for medical only.
          </li>
        </ul>

        <h4>In-force Rates</h4>
        <ul>
          <li>Carrier letterhead and corresponding benefit grid(s)/SBC(s).</li>
          <li>Include stop loss contract/proposal AND ASO contract/rates for Self-Funded/Level Funded groups.</li>
        </ul>

        <h4>Full Benefits Grids</h4>
        <ul>
          <li>Corresponding to the rates provided.</li>
        </ul>

        <h4>Renewal Rates</h4>
        <ul>
          <li>Carrier letterhead and corresponding benefit grid(s)/SBC(s).</li>
          <li>Include stop loss contract/proposal AND ASO contract/rates for Self-Funded/Level Funded groups.</li>
        </ul>

        <h4>Disclosure Statement</h4>
        <ul>
          <li>Must be fully completed; blank, undated, or unsigned disclosures will not be accepted.</li>
          <li>
            Include the phrase "No one is currently out on any type of leave and all employees are actively at work" if
            no known issues.
          </li>
        </ul>

        <h4>Multiple Employer Questionnaire (MEQ)</h4>
        <ul>
          <li>Complete and return if there are any affiliated employers.</li>
        </ul>

        <h4>Additional Requirements for Groups with 100+ Enrolled & Level Funded / Self Funded</h4>
        <ul>
          <li>Provide current year (YTD), and 2 prior 12-month periods are preferred.</li>
          <li>Large claim experience: 2 years or more, >$25,000 or 50% specific deductible report.</li>
          <li>Monthly Medical and Rx Claims & Enrollment History: 2 years or more.</li>
        </ul>
      </div>
      <a (click)="toggleDetails()" style="margin-left: 1.75rem" class="clickable details-link">
        {{ showDetails ? 'Hide Details' : 'View Details' }}
        <fa-icon class="chevron-icon" [icon]="chevronsUp" [rotate]="showDetails ? undefined : 180"></fa-icon>
      </a>
    </div>
  `,
  styleUrls: ['./file-level-funded-info.scss']
})
export class FileLevelFundedInfoComponent {
  showDetails = false;

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  protected readonly chevronsUp = faChevronsUp;
  protected readonly infoIcon = faInfoCircle;
}
