import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { RxChannelIndicator, RxClaimType, RxDrugType } from '@common/models/rx-claim.model';
import { FormularyDisruptionTierType } from '@common/models/formulary-disruption.model';

export const id: DataDefModel = new DataDefModel({
  label: 'ID',
  key: 'id',
  type: DATA_TYPES.number
});

export const ndc: DataDefModel = new DataDefModel({
  label: 'NDC Code',
  key: 'ndc',
  type: DATA_TYPES.number
});

export const daySupply: DataDefModel = new DataDefModel({
  label: 'Days Supply',
  key: 'daySupply',
  type: DATA_TYPES.number
});

export const quantityFilled: DataDefModel = new DataDefModel({
  label: 'Quantity Filled',
  key: 'quantityFilled',
  type: DATA_TYPES.number
});

export const fillDate: DataDefModel = new DataDefModel({
  label: 'Fill Date',
  key: 'fillDate',
  type: DATA_TYPES.date
});

export const ingredientCost: DataDefModel = new DataDefModel({
  label: 'Ingredient Cost',
  key: 'ingredientCost',
  type: DATA_TYPES.currency
});

export const memberId: DataDefModel = new DataDefModel({
  label: 'Member ID',
  key: 'memberId',
  type: DATA_TYPES.text
});

export const channelIndicator: DataDefModel = new DataDefModel({
  label: 'Channel Indicator',
  key: 'channelIndicator',
  type: DATA_TYPES.select,
  options: [
    {
      label: 'Mail',
      value: RxChannelIndicator.MAIL
    },
    {
      label: 'Retail',
      value: RxChannelIndicator.RETAIL
    }
  ]
});

export const nabp: DataDefModel = new DataDefModel({
  label: 'NABP Number',
  key: 'nabp',
  type: DATA_TYPES.number
});

export const ncpdp: DataDefModel = new DataDefModel({
  label: 'NCPDP Number',
  key: 'ncpdpId',
  type: DATA_TYPES.number
});

export const npi: DataDefModel = new DataDefModel({
  label: 'NPI Number',
  key: 'npi',
  type: DATA_TYPES.number
});

export const drugType: DataDefModel = new DataDefModel({
  label: 'Brand/Generic Indicator',
  key: 'drugType',
  type: DATA_TYPES.select,
  options: [
    {
      label: 'Brand',
      value: RxDrugType.BRAND
    },
    {
      label: 'Generic',
      value: RxDrugType.GENERIC
    }
  ]
});

export const ucAmount: DataDefModel = new DataDefModel({
  label: 'U&C Amount',
  key: 'ucAmount',
  type: DATA_TYPES.currency
});

export const compoundIndicator: DataDefModel = new DataDefModel({
  label: 'Compound Indicator',
  key: 'compoundIndicator',
  type: DATA_TYPES.boolean
});

export const formularyIndicator: DataDefModel = new DataDefModel({
  label: 'Formulary Indicator',
  key: 'formularyIndicator',
  type: DATA_TYPES.boolean
});

export const formularyTier: DataDefModel = new DataDefModel({
  label: 'Formulary Tier',
  key: 'formularyTier',
  type: DATA_TYPES.enum,
  options: [
    { value: FormularyDisruptionTierType.EXCLUDED, label: 'Excluded' },
    { value: FormularyDisruptionTierType.TIER_1, label: 'Tier 1' },
    { value: FormularyDisruptionTierType.TIER_2, label: 'Tier 2' },
    { value: FormularyDisruptionTierType.TIER_3, label: 'Tier 3' },
    { value: FormularyDisruptionTierType.TIER_4, label: 'Tier 4' }
  ]
});

export const inhouseIndicator: DataDefModel = new DataDefModel({
  label: 'In-house Pharmacy Claim Indicator',
  key: 'inhouseIndicator',
  type: DATA_TYPES.boolean
});

export const dispensingFee: DataDefModel = new DataDefModel({
  label: 'Dispensing Fee',
  key: 'dispensingFee',
  type: DATA_TYPES.currency
});

export const indicator340b: DataDefModel = new DataDefModel({
  label: '340b Indicator',
  key: 'indicator340b',
  type: DATA_TYPES.boolean
});

export const cobIndicator: DataDefModel = new DataDefModel({
  label: 'COB Indicator',
  key: 'cobIndicator',
  type: DATA_TYPES.boolean
});

export const dmrIndicator: DataDefModel = new DataDefModel({
  label: 'DMR Indicator',
  key: 'dmrIndicator',
  type: DATA_TYPES.boolean
});

export const daw: DataDefModel = new DataDefModel({
  label: 'DAW Code',
  key: 'daw',
  type: DATA_TYPES.number
});

export const ucIndicator: DataDefModel = new DataDefModel({
  label: 'UC Indicator',
  key: 'ucIndicator',
  type: DATA_TYPES.boolean
});

export const memberCost: DataDefModel = new DataDefModel({
  label: 'Member Cost',
  key: 'memberCost',
  type: DATA_TYPES.currency
});

export const medispanAwp: DataDefModel = new DataDefModel({
  label: 'Medispan AWP',
  key: 'medispanAwp',
  type: DATA_TYPES.currency
});

export const nadacAwp: DataDefModel = new DataDefModel({
  label: 'Nadac AWP',
  key: 'nadacAwp',
  type: DATA_TYPES.currency
});

export const otc: DataDefModel = new DataDefModel({
  label: 'OTC',
  key: 'otc',
  type: DATA_TYPES.text
});

export const multiSourceCode: DataDefModel = new DataDefModel({
  label: 'Multi-Source Code',
  key: 'multiSourceCode',
  type: DATA_TYPES.text
});

export const nameTypeCode: DataDefModel = new DataDefModel({
  label: 'Name Type Code',
  key: 'nameTypeCode',
  type: DATA_TYPES.text
});

export const tcgpiId: DataDefModel = new DataDefModel({
  label: 'TCGP Id',
  key: 'tcgpiId',
  type: DATA_TYPES.text
});

export const drugName: DataDefModel = new DataDefModel({
  label: 'Drug Name',
  key: 'drugName',
  type: DATA_TYPES.text
});

export const pharmacyName: DataDefModel = new DataDefModel({
  label: 'Pharmacy Name',
  key: 'pharmacyName',
  type: DATA_TYPES.text
});

export const gpi: DataDefModel = new DataDefModel({
  label: 'GPI',
  key: 'gpi',
  type: DATA_TYPES.text
});

export const rmtTCName: DataDefModel = new DataDefModel({
  label: 'RMT Therapeutic Class',
  key: 'rmtTCName',
  type: DATA_TYPES.text
});

export const reversal: DataDefModel = new DataDefModel({
  label: 'Reversal',
  key: 'reversal',
  type: DATA_TYPES.boolean
});

export const invalidPriceHistory: DataDefModel = new DataDefModel({
  label: 'Unknown AWP',
  key: 'invalidPriceHistory',
  type: DATA_TYPES.boolean
});

export const vaccine: DataDefModel = new DataDefModel({
  label: 'Vaccine',
  key: 'vaccine',
  type: DATA_TYPES.boolean
});

export const syringe: DataDefModel = new DataDefModel({
  label: 'Syringe',
  key: 'syringe',
  type: DATA_TYPES.boolean
});

export const teststrip: DataDefModel = new DataDefModel({
  label: 'Test Strip',
  key: 'teststrip',
  type: DATA_TYPES.boolean
});

export const hiv: DataDefModel = new DataDefModel({
  label: 'HIV',
  key: 'hiv',
  type: DATA_TYPES.boolean
});

export const otcInsulin: DataDefModel = new DataDefModel({
  label: 'OTC Insulin',
  key: 'otcInsulin',
  type: DATA_TYPES.boolean
});

export const otcNonDiabetic: DataDefModel = new DataDefModel({
  label: 'OTC Non-Diabetic',
  key: 'otcNonDiabetic',
  type: DATA_TYPES.boolean
});

export const brand: DataDefModel = new DataDefModel({
  label: 'Brand',
  key: 'brand',
  type: DATA_TYPES.boolean
});

export const multiSourceBrand: DataDefModel = new DataDefModel({
  label: 'Multi-Source Brand',
  key: 'multiSourceBrand',
  type: DATA_TYPES.boolean
});

export const discounted: DataDefModel = new DataDefModel({
  label: 'Discounted',
  key: 'discounted',
  type: DATA_TYPES.boolean
});

export const rxClaimType: DataDefModel = new DataDefModel({
  label: 'Type',
  key: 'type',
  type: DATA_TYPES.enum,
  options: [
    { label: 'Limited Distribution Brand', value: RxClaimType.LIMITED_DIST_BRAND },
    { label: 'Limited Distribution Generic', value: RxClaimType.LIMITED_DIST_GENERIC },
    { label: 'Mail Brand', value: RxClaimType.MAIL_BRAND },
    { label: 'Mail Generic', value: RxClaimType.MAIL_GENERIC },
    { label: 'Retail 30 Brand', value: RxClaimType.RETAIL30_BRAND },
    { label: 'Retail 30 Generic', value: RxClaimType.RETAIL30_GENERIC },
    { label: 'Retail 90 Brand', value: RxClaimType.RETAIL90_BRAND },
    { label: 'Retail 90 Generic', value: RxClaimType.RETAIL90_GENERIC },
    { label: 'Specialty Brand', value: RxClaimType.SPECIALTY_BRAND },
    { label: 'Specialty Generic', value: RxClaimType.SPECIALTY_GENERIC }
  ]
});

export const longTermCare: DataDefModel = new DataDefModel({
  label: 'Long-term Care',
  key: 'longTermCare',
  type: DATA_TYPES.boolean
});

export const va: DataDefModel = new DataDefModel({
  label: 'VA',
  key: 'va',
  type: DATA_TYPES.boolean
});

export const military: DataDefModel = new DataDefModel({
  label: 'Military',
  key: 'military',
  type: DATA_TYPES.boolean
});

export const homeInfusion: DataDefModel = new DataDefModel({
  label: 'Home Infusion',
  key: 'homeInfusion',
  type: DATA_TYPES.boolean
});

export const ihs: DataDefModel = new DataDefModel({
  label: 'IHS',
  key: 'ihs',
  type: DATA_TYPES.boolean
});

export const covidAntiviral: DataDefModel = new DataDefModel({
  label: 'Covid Antiviral',
  key: 'covidAntiviral',
  type: DATA_TYPES.boolean
});

export const covidTest: DataDefModel = new DataDefModel({
  label: 'Covid Test',
  key: 'covidTest',
  type: DATA_TYPES.boolean
});

export const pcsk9: DataDefModel = new DataDefModel({ label: 'PCSK9', key: 'pcsk9', type: DATA_TYPES.boolean });

export const rxClaimDefs: DataDefModel[] = [
  id,
  ndc,
  daySupply,
  quantityFilled,
  fillDate,
  ingredientCost,
  memberId,
  channelIndicator,
  nabp,
  ncpdp,
  npi,
  pharmacyName,
  dispensingFee,
  compoundIndicator,
  indicator340b,
  cobIndicator,
  dmrIndicator,
  inhouseIndicator,
  drugType,
  formularyTier,
  daw,
  ucIndicator,
  memberCost,
  ucAmount,
  formularyIndicator,
  drugName,
  gpi,
  rmtTCName,
  medispanAwp,
  nadacAwp,
  otc,
  multiSourceCode,
  nameTypeCode,
  tcgpiId,
  reversal,
  invalidPriceHistory,
  vaccine,
  syringe,
  teststrip,
  hiv,
  otcInsulin,
  otcNonDiabetic,
  brand,
  multiSourceBrand,
  discounted,
  rxClaimType,
  longTermCare,
  va,
  military,
  homeInfusion,
  ihs,
  pcsk9,
  covidAntiviral,
  covidTest
];

export const rxClaimResponderViewDefs: DataDefModel[] = [
  id,
  ndc,
  daySupply,
  quantityFilled,
  fillDate,
  memberId,
  channelIndicator,
  nabp,
  ncpdp,
  npi
];

export const displayItems: string[] = [
  ndc.key,
  daySupply.key,
  quantityFilled.key,
  fillDate.key,
  ingredientCost.key,
  memberId.key,
  channelIndicator.key,
  nabp.key,
  ncpdp.key,
  npi.key,
  pharmacyName.key,
  rmtTCName.key
];

export const displayItemsResponderView: string[] = [
  ndc.key,
  daySupply.key,
  quantityFilled.key,
  fillDate.key,
  memberId.key,
  channelIndicator.key,
  nabp.key,
  ncpdp.key,
  npi.key
];

export const quoteResponseRxClaimManifestColumns: DataDefModel[] = [
  id,
  ndc,
  daySupply,
  quantityFilled,
  fillDate,
  channelIndicator,
  nabp,
  ncpdp,
  npi
];
