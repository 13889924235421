import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <div [ngStyle]="loaderStyles" class="loader"></div>
  `,
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() Cwidth: number;
  @Input() Cheight = 16;
  @Input() CminWidth: number;
  @Input() circle: boolean;

  loaderStyles;

  ngOnInit() {
    let width;
    if (this.CminWidth) {
      const twentyPercent = this.CminWidth * 0.2; // vary width within 20% of minWidth
      width = +this.CminWidth + Math.random() * twentyPercent;
    }
    if (this.Cwidth) {
      width = this.Cwidth;
    }
    this.loaderStyles = {
      'width.px': width,
      'height.px': this.Cheight ? this.Cheight : '',
      'border-radius': this.circle ? '50%' : '.25rem'
    };
  }
}
