import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { AsyncOptions } from '@form-lib/options/options.model';
import { OptionsService } from '@form-lib/options/options.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-query-brick',
  templateUrl: 'query-brick.component.html',
  styleUrls: ['query-brick.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBrickComponent implements OnChanges {
  asyncOptionsService: AsyncOptions;
  getValueConfig;
  displayValue$: Observable<string>;

  @Input() query: any;

  @Output() remove = new EventEmitter<number>();
  @Output() selected = new EventEmitter<ElementRef>();

  timesIcon = faTimes;

  constructor(private optionsService: OptionsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.query) {
      this.getValueConfig = { ...this.query, key: 'value' };
      // Remove the calculated value fn to format the value correctly. The calculatedValueFn is provided to format the field in the table. Don't use it here.
      delete this.getValueConfig.calculatedValueFn;
      if (this.query.asyncOptions) {
        this.asyncOptionsService = this.optionsService.getAsyncOptionsService(this.query.asyncOptions);
        this.displayValue$ = this.asyncOptionsService.valuesFromKeys(this.query.value).pipe(
          take(1),
          map((labelValues) => labelValues.map(({ label }) => label).join(', '))
        );
      } else {
        this.asyncOptionsService = null;
      }
    }
  }

  removeQuery($event: Event) {
    $event.stopPropagation();
    this.remove.emit(this.query);
  }
}
