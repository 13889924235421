import { Component } from '@angular/core';
import { faPoop } from '@fortawesome/pro-solid-svg-icons';
import { MainService } from '@main/main.service';
import { ProductSelectors } from '@main/store/product/product.selectors';
import { Store } from '@ngxs/store';
import { map, switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  // Determine the product url link that the user will be sent to when they
  // click on the 'home page' link in the 404 error page.
  // This product url link is determined based on what product the user is
  // currently in. (i.e. quote-linq, smart-linq, configuration).
  // If the user received a 404 error immediately after authenticating (i.e. they
  // have not selected a product), then they will be redirected to the first
  // product available to them.
  selectedProductLink$ = this.store.select(ProductSelectors.selectedProduct).pipe(
    switchMap((currentProduct) =>
      this.layoutService.productsAvailable$.pipe(
        map((availableProducts) => {
          if (!currentProduct) {
            return availableProducts.length > 0 ? availableProducts[0].link : '/';
          }
          return availableProducts.find((availableProduct) => availableProduct.key === currentProduct).link;
        })
      )
    )
  );

  poopIcon = faPoop;

  constructor(private layoutService: MainService, private store: Store) {}
}
