<app-dialog-close icon [closeOnClick]="true"></app-dialog-close>
<app-dialog-label style="min-width: 180px">Display Columns</app-dialog-label>
<app-dialog-content class="p-0 dialog-content">
  <button mat-menu-item (click)="selectAll()">
    <mat-checkbox #selectAllCheckbox>
      <span [innerHTML]="'Select all'"></span>
    </mat-checkbox>
  </button>
  <div class="line"></div>
  <div class="column-content">
    <button
      mat-menu-item
      *ngFor="let col of definitions | propertyFilter: 'key':'icon':true | sort: 'label'"
      (click)="toggleColumn(col.key); $event.stopPropagation()"
    >
      <mat-checkbox [checked]="col.visible">
        <span [innerHTML]="col.label"></span>
      </mat-checkbox>
    </button>
  </div>
</app-dialog-content>
<app-dialog-actions>
  <a secondaryActions class="reset-link" (click)="resetToDefaults()">
    Reset defaults
  </a>
  <button mat-raised-button (click)="close()">Close</button>
</app-dialog-actions>
