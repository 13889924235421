import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormArrayValidatorConfig } from '@form-lib/models/validators.model';

export class FormArrayValidators {
  static thereCanBeOnlyOne(config: FormArrayValidatorConfig) {
    const message = config.message ? config.message : 'There can be only one item with this value.';
    return (formArray: UntypedFormArray): ValidationErrors | null => {
      const ctrlsAtKey = (formArray.controls as UntypedFormGroup[]).map((fg) => fg.controls[config.key]);
      let ctrlsWithError: any[];
      if (!config.value) {
        const ctrlValues = ctrlsAtKey.map((ctrl) => ctrl.value);
        ctrlsWithError = ctrlsAtKey.filter(
          (ctrl) => ctrlValues.indexOf(ctrl.value) !== ctrlValues.lastIndexOf(ctrl.value)
        );
      } else {
        ctrlsWithError = ctrlsAtKey.filter((ctrl) => ctrl.value === config.value);
      }
      if (ctrlsWithError.length <= 1) {
        ctrlsAtKey.forEach((ctrl) => {
          const errors =
            ctrl.errors && Object.keys(ctrl.errors).some((key) => key !== 'thereCanBeOnlyOne')
              ? { ...ctrl.errors, thereCanBeOnlyOne: null }
              : null;
          ctrl.setErrors(errors);
        });
        return null;
      }

      ctrlsWithError.forEach((ctrl) => {
        ctrl.setErrors({ ...ctrl.errors, thereCanBeOnlyOne: message });
      });
      return { thereCanBeOnlyOne: true };
    };
  }
}
