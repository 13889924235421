import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Directive, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { LabelValue } from '@lib-resource/label-value.model';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseMultiFieldComponent extends BaseFieldComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  hintText = `Hit [enter] or [comma] to add item`;
  isMulti = true;

  ngOnInit() {
    super.ngOnInit();
    const value = this.control.value;

    // Attempt to coerce the value to array if not already an array.
    if (value && !Array.isArray(value)) {
      this.control.setValue([value]);
    }
  }

  valueFormatter(val) {
    return val.trim();
  }

  add(event: MatChipInputEvent, numeric: boolean = false): void {
    const input = event.chipInput.inputElement;
    const value = event.value;
    let ctrlValue = this.control.value;

    if ((value || '').trim()) {
      if (!Array.isArray(ctrlValue)) {
        ctrlValue = [];
      }
      this.updateCtrlValue([...ctrlValue, numeric ? +this.valueFormatter(value) : this.valueFormatter(value)]);
      this.control.markAsTouched();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item): void {
    const values = [...this.control.value];
    const idx = values.findIndex((value) => item === value);
    values.splice(idx, 1);
    this.updateCtrlValue([...values]);
    this.control.markAsTouched();
  }

  @Memoize()
  getDisplayValue(currentOptions: LabelValue[], controlVal: any[]): string {
    if (!controlVal?.length) {
      if (!!this.noSelectionLabel) {
        return this.noSelectionLabel;
      }
      return null;
    }
    return controlVal
      .map((val) => currentOptions.find((option) => this.compareFn(option.value, val)))
      .filter((val) => !!val)
      .map(({ label }) => label)
      .join(', ');
  }
}
