import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OcrProcessedFieldModel } from '@form-lib/ocr-overlay/ocr.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { DataDefModel } from '@lib-resource/data-def.model';
import { PopoverService } from '@shared/components/popover/popover.service';
import { POPOVER_DATA } from '@shared/components/popover/popover.model';

export interface OcrDialogData {
  ocrData: OcrProcessedFieldModel;
  field: DataDefModel;
}

@Component({
  template: `
    <app-dialog-close icon appCloseDialog></app-dialog-close>
    <app-dialog-label><span>OCR Result</span></app-dialog-label>
    <app-dialog-content>
      <div class="popover-container mat-typography" *ngIf="data.ocrData; else noData">
        <div class="img-container mat-elevation-z2" *ngFor="let bgTransform of bgTransforms">
          <img class="ocr-img" [src]="bgImage" [style.transform]="bgTransform" alt="Image of extracted text" />
        </div>
        <div class="popover-info">
          <p><b>Field:</b> {{ data.field.label }}</p>
          <p>
            <small><b>Confidence:</b> {{ data.ocrData.confidence }}</small>
          </p>
        </div>
      </div>
      <ng-template #noData>
        <div class="popover-container">
          <p>No data was provided.</p>
        </div>
      </ng-template>
    </app-dialog-content>
    <app-dialog-actions>
      <button mat-raised-button color="primary" appCloseDialog="true">Expand</button>
      <button mat-raised-button appCloseDialog>Cancel</button>
    </app-dialog-actions>
  `,
  styleUrls: ['ocr-popover-trigger.component.scss']
})
export class OcrSnippetMessageDialogComponent implements OnInit {
  bgImage;
  bgTransforms = [];

  constructor(@Inject(POPOVER_DATA) public data: OcrDialogData, private sanitization: DomSanitizer) {}

  ngOnInit() {
    this.setTransformValues();
    this.bgImage = this.sanitization.bypassSecurityTrustUrl(this.data.ocrData.docData.image) as any;
  }

  setTransformValues() {
    this.data.ocrData.boundingBoxes.forEach((boundingBox) => {
      let scale = 1 / boundingBox.width;
      if (scale > 10) {
        scale = 10;
      }
      const xTranslate = scale * boundingBox.left * 100;
      const yTranslate = scale * boundingBox.top * 100;

      this.bgTransforms.push(
        this.sanitization.bypassSecurityTrustStyle(`translate(-${xTranslate}%, -${yTranslate}%) scale(${scale})`)
      );
    });
  }
}

@Component({
  selector: 'app-ocr-popover',
  template: `
    <button
      type="button"
      class="trigger dense-layout"
      mat-icon-button
      [matTooltip]="'View source for ' + field.label"
      (popoverTrigger)="openInfoPopover($event)"
    >
      <fa-icon *ngIf="icon" [icon]="icon" [ngClass]="colorClass"></fa-icon>
    </button>
  `,
  styleUrls: ['ocr-popover-trigger.component.scss']
})
export class OcrPopoverTriggerComponent implements OnInit {
  @Input() ocrData: OcrProcessedFieldModel;
  @Input() field: DataDefModel;
  @Output() openDialog = new EventEmitter();

  icon: IconDefinition;
  colorClass: string;

  constructor(private sanitization: DomSanitizer, private popoverService: PopoverService) {}

  ngOnInit() {
    this.icon = faWarning;
    this.colorClass = 'color-status-orange-dark';
  }

  openInfoPopover(target: ElementRef) {
    this.popoverService
      .openDialogComponent(OcrSnippetMessageDialogComponent, target, { ocrData: this.ocrData, field: this.field })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.openDialog.emit();
        }
      });
  }
}
