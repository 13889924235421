import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { asapScheduler, BehaviorSubject, Subject } from 'rxjs';
import { filter, map, observeOn } from 'rxjs/operators';

export class FormToggleEditNotification {
  form: UntypedFormGroup;
  editing?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FormContainerService {
  private _formRegistry: {
    [prop: string]: BaseFormComponent;
  } = {};

  formRegistrySource = new BehaviorSubject(this._formRegistry);
  yourForm$ = this.formRegistrySource.asObservable();

  private formToggleEdit = new Subject<FormToggleEditNotification>();
  formToggleEdit$ = this.formToggleEdit.asObservable();

  constructor() {}

  hasUnsavedChanges(): boolean {
    return !!Object.keys(this._formRegistry).filter(
      (key) => this._formRegistry[key].editing && this._formRegistry[key].form.dirty
    ).length;
  }

  getForm(id) {
    return this.yourForm$.pipe(
      observeOn(asapScheduler),
      map((registry) => registry[id]),
      filter((formContainer) => !!formContainer)
    );
  }

  registerForm(form: BaseFormComponent) {
    if (!form.id) throw Error('The form provided has no id');
    this._formRegistry[form.id] = form;
    this.formRegistrySource.next(this._formRegistry);
  }

  notifyFormToggleEdit(notification: FormToggleEditNotification) {
    this.formToggleEdit.next(notification);
  }

  unregisterForm(formId: string) {
    delete this._formRegistry[formId];
  }
}
