import { Component, Input, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { PopoverRef } from '@shared/components/popover/popover-ref';

@Component({
  selector: 'app-dialog-close [icon]',
  template: `
    <button matTooltip="Close" class="dialog-close-icon-btn" mat-icon-button (click)="closeDialog()">
      <fa-icon [icon]="timesIcon"></fa-icon>
    </button>
  `,
  styles: [
    `
      .dialog-close-icon-btn {
        position: absolute;
        top: 0.5rem;
        right: 10px;
        z-index: 3;
      }
    `
  ]
})
export class DialogCloseButtonComponent {
  @Input() closeOnClick = false;

  timesIcon = faTimes;

  overlayRef: PopoverRef | MatDialogRef<any>;
  constructor(@Optional() dialogRef: MatDialogRef<any>, @Optional() popoverRef: PopoverRef) {
    this.overlayRef = dialogRef ? dialogRef : popoverRef;
  }

  closeDialog() {
    if (this.closeOnClick) this.overlayRef.close();
  }
}
