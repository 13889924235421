import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { scan, switchMap, tap } from 'rxjs/operators';
import { Page } from '@common/models/page.model';
import { unparse } from 'papaparse';
import { SERVER_URL } from '@app/app.constants';
// @ts-ignore
import streamSaver from 'streamsaver'; // produces a warning without the ignore due to no default class exposed

export interface CsvDownloadProgressDialogData {
  filename: string;
  columnNames: string[];
  pagedService: (page: number, sid: string) => Observable<Page<string[]>>;
}

interface DownloadProgress {
  downloaded: number;
  total: number;
  progress: number;
  currentPage: number;
  sid: string;
}

@Component({
  templateUrl: 'csv-download-progress.component.html'
})
export class CsvDownloadProgressDialog {
  writer: WritableStreamDefaultWriter;
  encoder = new TextEncoder();
  cancel: boolean = false;
  closeTimeout: number = 500;

  pageBehaviorSubject = new BehaviorSubject<{ pageNumber: number; sid: string }>({ pageNumber: 0, sid: null });
  itemStream$: Observable<DownloadProgress> = this.pageBehaviorSubject.asObservable().pipe(
    switchMap((page) => this.data.pagedService(page.pageNumber, page.sid)),
    scan(
      (previous: DownloadProgress, page: Page<string[]>): DownloadProgress => {
        if (page.page === 0) {
          this.writer.write(this.encoder.encode(unparse([this.data.columnNames])));
          this.writer.write(this.encoder.encode('\r\n'));
        }
        this.writer.write(this.encoder.encode(unparse(page.content)));
        this.writer.write(this.encoder.encode('\r\n'));
        return {
          downloaded: previous.downloaded + page.content.length,
          total: page.total,
          progress: page.total === 0 ? 100 : ((previous.downloaded + page.content.length) / page.total) * 100,
          currentPage: page.page,
          sid: page.sid
        };
      },
      { downloaded: 0, total: 0, progress: 0, currentPage: 0, sid: null }
    ),
    tap((downloadProgress) => {
      // if there are more pages, pump through the next page number
      if (!this.cancel && downloadProgress.downloaded < downloadProgress.total) {
        this.pageBehaviorSubject.next({ pageNumber: downloadProgress.currentPage + 1, sid: downloadProgress.sid });
      } else {
        // paging is complete or the download has been canceled, close the stream, complete is true
        this.writer.close();
        setTimeout(
          () => {
            this.dialogRef.close();
          },
          this.cancel ? 0 : this.closeTimeout
        );
      }
    })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CsvDownloadProgressDialogData,
    private dialogRef: MatDialogRef<CsvDownloadProgressDialog>
  ) {
    streamSaver.mitm = `${SERVER_URL}/assets/file-stream/file-stream-mitm.html`;
    this.writer = streamSaver.createWriteStream(this.data.filename).getWriter();
  }
}
