import { UntypedFormControl } from '@angular/forms';
import { ActionDef, DerivedIcon } from '@data-table-lib/models/data-table.model';
import { DisablerConfig, FormValidatorDef, ValidatorsMap } from '@form-lib/models/validators.model';
import { LabelValue } from '@lib-resource/label-value.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { replaceInvalidKeyChars } from '@lib-resource/data.utils';

export class HtmlAttributes {
  autocomplete: string;
}

class Layout {
  base?: number; // ColumnRange;
  toggle?: boolean;

  constructor({ base }: Layout = {}) {
    this.base = base || 3;
  }
}

type BooleanFunction = (value, def) => boolean;

export interface DataDefOption<L = string, V = any> extends LabelValue<L, V> {
  disable?: boolean;
  disableIf?: BooleanFunction;
}

export class DataDefTerms {
  term: string;
  definition: string;
}

export class DataDefGlossary {
  label?: string;
  terms?: DataDefTerms[];
  information?: string;
}

export class DerivedValueDef {
  operation: string;
  fields?: string[];
  numeratorFields?: string[];
  denominatorFields?: string[];
  subtractFrom?: number;
}

export const DATA_TYPES = {
  numberRange: 'numberRange',
  number: 'number',
  telephone: 'telephone',
  multiSelect: 'multiSelect',
  text: 'text',
  textarea: 'textarea',
  select: 'select',
  multiInput: 'multiInput',
  datepicker: 'datepicker',
  fileSize: 'fileSize',
  formGroup: 'formGroup',
  formGroupArray: 'formGroupArray',
  currency: 'currency',
  multiCurrency: 'multiCurrency',
  action: 'action',
  boolean: 'boolean',
  date: 'date',
  dateTime: 'dateTime',
  dateRange: 'dateRange',
  enum: 'enum',
  icon: 'icon',
  percentage: 'percentage',
  multiSelectSet: 'multiSelectSet',
  colorPicker: 'colorpicker',
  spacer: 'spacer', // used for form group arrays right now to display a row without a label while still supporting derived methods to display icons/values
  labelOnly: 'labelOnly' // used for form group arrays right now to display 'section type' headers and sub headers
} as const;

export type DataTypes = (typeof DATA_TYPES)[keyof typeof DATA_TYPES];

export class DataDefModel<T = any> {
  // core
  key: string;
  // angular forms do not allow '.', provide a different key
  formKey: string;
  label?: string;
  type?: DataTypes;
  options?: DataDefOption[];
  toggleOptions?: DataDefOption[];
  asyncOptions?: string; // asyncOptions can take 0-3 params: value, page, pageSize
  asyncImmediate?: boolean; // controls if the async option will include a 500ms debounce time, useful if the async calls an api
  asyncExtras?: any; // object that holds any information to help the async control be dynamic
  derivedAsyncExtras?: (row, column?: DataDefModel) => any; // just like asyncExtras but able to be even more dynamic per row
  optionsKey?: string; // The field name in the data model that contains the array of options.
  asyncOptionsDeps?: string[]; // The list of selectors that this control depends on to fetch its list of options.

  // field specific
  fields?: DataDefModel[]; // For arrayGroups
  definitions?: DataDefModel[]; // For arrayGroups
  formGroupArrayInitModel?: () => Object; // For arrayGroups to provide the initial data model on Add and Clone operations
  joinedField?: DataDefModel; // for an extra field that is included in this fields label
  validators?: ValidatorsMap | FormValidatorDef[];
  disablers?: DisablerConfig[];
  asyncValidators?: any;
  readOnly?: boolean;
  hint?: string;
  noSelectionLabel?: string; // for selects, the label to display if there is no selected option. (i.e. 'All')
  notOrderable?: boolean; // Whether a array of definitions can be ordered
  dollarsOnly?: boolean; // for currency fields, if only dollars
  itemPrefix?: string; // Label to prefix items in FormArray
  onChangeFn?: (control: UntypedFormControl, valueChange: any) => void;
  icon?: IconDefinition; // Font-awesome icon class definition
  notCloneable?: boolean; // in form group array, some fields should not be cloned when copying. For example 'id' values.
  hiddenRow?: boolean; // in form group array, ability to specify some rows to be hidden in the table array view.

  attrs?: HtmlAttributes; // Add things like autocomplete key so the browser knows what to insert

  // Column specific
  queryKey?: string;
  noSort?: boolean;
  noQuery?: boolean;
  noView?: boolean;
  actions?: ActionDef[];

  // Derived
  calculatedValueFn?: (row, column?: DataDefModel) => any;
  derivedIcon?: (row, column?: DataDefModel) => DerivedIcon[] | null;
  derivedValue?: DerivedValueDef;
  // Only use w/ non-paginated data; unless you're sure of what you are doing
  rollupFn?: (values?, column?) => any;

  // =============
  // Bad things START HERE
  // =============
  visible?: boolean; // this implies mutation of the def instance; NO!
  // Is this needed? I could make this def generic and have the passed in type be the config,
  // which could be used to handle very specific needs
  config?: T; // Let's try it out and see where this goes.

  // layout
  // Sections can be handled in the LayoutDef once complete and will be a separate entity
  layout?: Layout;
  calculatedLayout?: any; // Same as 'visible' prop
  sectionKey?: string | number;
  subSectionKey?: string | number;

  // styling
  // Should this ever be defined on a DataDef... 'Signs point to NO'
  // There are other ways to style specific columns
  class?: string;
  labelClasses?: string;
  fieldRenderClass?: string;

  // copy constructor, for the primary purpose of copying the data coming in and creating the formKey from the key
  constructor(data: Partial<DataDefModel>, formKey?: string) {
    this.key = data.key;
    this.formKey = formKey ? formKey : replaceInvalidKeyChars(this.key);
    this.label = data.label;
    this.type = data.type;
    this.options = data.options;
    this.toggleOptions = data.toggleOptions;
    this.asyncOptions = data.asyncOptions;
    this.asyncImmediate = data.asyncImmediate;
    this.asyncExtras = data.asyncExtras;
    this.derivedAsyncExtras = data.derivedAsyncExtras;
    this.optionsKey = data.optionsKey;
    this.asyncOptionsDeps = data.asyncOptionsDeps;
    this.fields = data.fields;
    this.definitions = data.definitions;
    this.formGroupArrayInitModel = data.formGroupArrayInitModel;
    this.joinedField = data.joinedField;
    this.validators = data.validators;
    this.disablers = data.disablers;
    this.asyncValidators = data.asyncValidators;
    this.readOnly = data.readOnly;
    this.hint = data.hint;
    this.noSelectionLabel = data.noSelectionLabel;
    this.notOrderable = data.notOrderable;
    this.dollarsOnly = data.dollarsOnly;
    this.itemPrefix = data.itemPrefix;
    this.onChangeFn = data.onChangeFn;
    this.icon = data.icon;
    this.notCloneable = data.notCloneable;
    this.hiddenRow = data.hiddenRow;
    this.attrs = data.attrs;
    this.queryKey = data.queryKey;
    this.noSort = data.noSort;
    this.noQuery = data.noQuery;
    this.noView = data.noView;
    this.actions = data.actions;
    this.calculatedValueFn = data.calculatedValueFn;
    this.derivedIcon = data.derivedIcon;
    this.derivedValue = data.derivedValue;
    this.rollupFn = data.rollupFn;
    this.visible = data.visible;
    this.config = data.config;
    this.layout = data.layout;
    this.calculatedLayout = data.calculatedLayout;
    this.sectionKey = data.sectionKey;
    this.subSectionKey = data.subSectionKey;
    this.class = data.class;
    this.labelClasses = data.labelClasses;
    this.fieldRenderClass = data.fieldRenderClass;
  }
}
