import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataDefModel } from '@lib-resource/data-def.model';
import { getValueByDef } from '@lib-resource/data.utils';
import { BadgeDef } from '@info-lib/info-badge/badge-def.model';

@Component({
  selector: 'app-info-item-display',
  template: `
    <span class="label" *ngIf="!!dataDef.label"> {{ dataDef.label }}: </span>
    <span
      (click)="onClick()"
      class="data"
      [class]="{ 'empty-label': hasNoValue(dataDef), 'clickable-link': clickable, 'bold-value': boldValue }"
    >
      {{ data | dataFormatter: dataDef : emptyPlaceholder }}
      @for (badge of badges; track badge) {
        <app-info-badge class="info-badge" [badgeDef]="badge" [data]="data"></app-info-badge>
      }
    </span>
  `,
  styleUrls: ['./info-display-item.component.scss']
})
export class InfoDisplayItemComponent {
  @Input() dataDef: DataDefModel;
  @Input() data: any;
  @Input() clickable: boolean = false;
  @Input() badges: BadgeDef[] = [];
  @Input() emptyPlaceholder: string = 'empty';
  @Input() boldValue: boolean = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  hasNoValue(dataDef: DataDefModel): boolean {
    const value = getValueByDef(this.data, dataDef);
    return value === undefined || value === null || value === '';
  }

  onClick() {
    this.clicked.emit(true);
  }
}
