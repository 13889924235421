import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { FiltersModel } from '@main/store/workspace/workspace.model';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * The KPI Component to use in Workspaces.
 *
 * Currently, there are multiple implementations of KPI component. The goal is to ultimately
 * reduce all KPIs in the app to a single type of KPI Component.
 *
 * This KPI Component is a simple KPI component that takes in a label, value, and query model
 * as @Input.
 * When the KPI is clicked, this component will push the query model to the URL.
 * The KPI Component will listen for changes to the URL, and if the filter parameter
 * matches the query model definition then the KPI will be highlighted in the UI.
 */
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiComponent implements OnChanges {
  @Input() label: string;
  @Input() labelIcon: IconDefinition;
  @Input() value: any;
  @Input() filters: FiltersModel;
  @Input() selectedFilters: FiltersModel;
  @Input() searchValue: string;
  @Input() updateRoute: boolean = true;
  @Output() selected = new EventEmitter();
  colorClass = 'bg-white';

  filtersAsString = '';
  on: boolean = false;

  constructor(private router: Router) {}

  ngOnChanges({ filters, searchValue }: SimpleChanges): void {
    if (filters || searchValue) {
      this.filtersAsString = JSON.stringify({
        filters: filters?.currentValue || this.filters,
        searchValue: searchValue?.currentValue || this.searchValue
      });
    }
  }

  isSelected(): boolean {
    this.on = this.checkSelected();
    return this.on;
  }

  private checkSelected(): boolean {
    if (!this.selectedFilters) return false;

    const cleanedSelectedFilters = {};

    Object.keys(this.selectedFilters).forEach((key) => {
      if (this.selectedFilters[key] && this.selectedFilters[key].length) {
        cleanedSelectedFilters[key] = this.selectedFilters[key];
      }
    });

    const selectedFilterKeys = Object.keys(cleanedSelectedFilters);
    const filterKeys = Object.keys(this.filters);

    // Are they the same length?
    if (!selectedFilterKeys.length || selectedFilterKeys.length !== filterKeys.length) return false;
    // Do they contain the same keys?
    if (!checker(selectedFilterKeys, filterKeys)) return false;

    // Do the filters in each row match?
    return !selectedFilterKeys.some((key) => !checker(this.filters[key], cleanedSelectedFilters[key]));
  }

  toggle() {
    this.on = !this.on; // flip the toggle

    if (this.on) {
      this.selected.emit(this.filters);
    } else {
      this.selected.emit(null);
    }

    if (this.updateRoute) {
      const queryParams = this.on ? this.filtersAsString : this.searchValue ? JSON.stringify({ searchValue: this.searchValue }) : '';
      this.router.navigate([], {
        queryParams: { workspace: queryParams },
        queryParamsHandling: 'merge'
      });
    }
  }
}

function checker(arr, target) {
  arr = arr.sort();
  target = target.sort();
  return target.every((val, idx) => val === arr[idx]);
}
