<div class="comments-wrapper dense">
  <div #scrollContainer class="comment-list">
    <div class="comment-item" *ngIf="!comments || comments.length === 0">No {{ noCommentsLabel }} to display.</div>
    <div class="comment-item" *ngFor="let comment of comments; trackBy: trackByFn" [@flyInOut]="'in'">
      <div>
        <div class="comment-meta-data">
          <strong class="pr-4">{{ getUserLabel(comment) }}</strong>
          <span class="comment-date">{{ comment.createdDate | date: 'short' }}</span>
        </div>
        <div>
          <span class="comment-text" *ngIf="selectedEditComment !== comment">{{ comment.comment }}</span>
          <form *ngIf="selectedEditComment === comment" class="comment-form" (submit)="onUpdateComment(comment)">
            <mat-form-field appearance="outline">
              <label class="sr-only" for="comments-edit-field">Edit Comment</label>
              <textarea
                autofocus
                matTextareaAutosize
                matAutosizeMaxRows="4"
                [formControl]="editCommentControl"
                id="comments-edit-field"
                data-spec="comments-edit-field"
                matInput
                (keydown.enter)="$event.preventDefault(); onUpdateComment(comment)"
              ></textarea>
              <button
                data-spec="comments-update-button"
                class="comment-button"
                matTooltip="Update comment"
                [disabled]="
                  !this.editCommentControl.value || this.editCommentControl?.errors?.maxlength?.requiredLength
                "
                (click)="onUpdateComment(comment)"
                type="submit"
                mat-icon-button
                matSuffix
              >
                <fa-icon [icon]="chevronCircleRightIcon"></fa-icon>
              </button>
              <mat-hint>Max {{ maxLength | number }} chars</mat-hint>
              <mat-error *ngIf="editCommentControl.errors?.maxlength"
                >Max {{ editCommentControl.errors.maxlength.requiredLength | number }} chars</mat-error
              >
            </mat-form-field>

            <button
              data-spec="comments-cancel-edit-button"
              color="warn"
              matTooltip="Cancel edit"
              type="button"
              (click)="resetEdit()"
              mat-icon-button
              class="ml-2 comment-button"
            >
              <fa-icon style="vertical-align: middle" [icon]="timesIcon"></fa-icon>
            </button>
          </form>
        </div>
      </div>
      <ng-container *ngIf="!!canEditWhen && canEditWhen(comment)">
        <div class="comment-edit-delete" *ngIf="selectedEditComment !== comment">
          <button data-spec="comments-edit-button" mat-icon-button (click)="editComment(comment)">
            <fa-icon [icon]="editIcon"></fa-icon>
          </button>
          <button data-spec="comments-delete-button" color="warn" mat-icon-button (click)="onDeleteComment(comment)">
            <fa-icon [icon]="trashIcon"></fa-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="bottom-pane">
    <form class="comment-form" (submit)="onAddComment()">
      <mat-form-field appearance="outline">
        <label class="sr-only" for="comments-add-field">Add Comment</label>
        <textarea
          matTextareaAutosize
          matAutosizeMaxRows="4"
          [formControl]="addCommentControl"
          id="comments-add-field"
          class="comment-textarea"
          data-spec="comments-add-field"
          matInput
          (keydown.enter)="$event.preventDefault(); onAddComment()"
        ></textarea>
        <button
          matSuffix
          [disabled]="!this.addCommentControl.value || this.addCommentControl?.errors?.maxlength?.requiredLength"
          class="comment-button"
          matTooltip="Add comment"
          mat-icon-button
          data-spec="comments-add-button"
        >
          <fa-icon [icon]="chevronCircleRightIcon"></fa-icon>
        </button>
        <mat-hint>Max {{ maxLength | number }} chars</mat-hint>
        <mat-error *ngIf="addCommentControl.errors?.maxlength"
          >Max {{ addCommentControl.errors.maxlength.requiredLength | number }} chars</mat-error
        >
      </mat-form-field>
    </form>
  </div>
</div>
