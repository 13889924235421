import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './pdf-viewer-dialog.component.html',
  styles: [
    `
      .pdf-viewer {
        height: 100%;
      }
    `
  ]
})
export class PdfViewerDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { blob: Blob; title: string }) {}
}
