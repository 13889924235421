import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';

export const columnDefs: DataDefModel[] = [
  { label: 'ID', key: 'id', type: DATA_TYPES.number },
  { label: 'Member Name', key: 'participantName', type: DATA_TYPES.text },
  { label: 'Date of Birth', key: 'dateOfBirth', type: DATA_TYPES.date },
  { label: 'Sex', key: 'sex', type: DATA_TYPES.text },
  { label: 'Member Type', key: 'participantType', type: DATA_TYPES.text },
  { label: 'Enrollment Status', key: 'enrollmentStatus', type: DATA_TYPES.text },
  { label: 'Dx/Rx Code', key: 'diagnosis', type: DATA_TYPES.text },
  { label: 'Dx/Rx Description', key: 'prognosis', type: DATA_TYPES.text },
  { label: 'Latest Date of Service', key: 'latestDateOfService', type: DATA_TYPES.date },
  { label: 'Paid Claims Amt', key: 'paidClaimsAmt', type: DATA_TYPES.currency },
  { label: 'Pending/Denied Claims Amt', key: 'pendingOrDeniedClaimsAmt', type: DATA_TYPES.currency }
].map((def) => new DataDefModel({ ...def, layout: { base: 6 } }));

export const defaultCols = [
  'id',
  'participantName',
  'dateOfBirth',
  'sex',
  'latestDateOfService',
  'diagnosis',
  'prognosis'
];
