import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from '@app/app.constants';
import { Observable, of } from 'rxjs';
import { QuoteResponseRxModel } from '@common/models/quote-response-rx.model';
import { HttpResourceService } from '@main/http-resource.service';
import { RxResponseManifestEntry } from '@common/dialogs/manifest-dialogs/quote-response-rx-manifest-dialog.component';
import { UrlUtilService } from '@common/services/url-util.service';
import { QueryModel } from '@data-table-lib/data-table-config-source';
import { Page } from '@common/models/page.model';
import { QuoteResponseOptionRxModel } from '@common/models/quote-response-option-rx.model';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { UserJobService } from '@shared/components/user-job-progress/user-job.service';
import {
  UserJobProgressDialog,
  UserJobProgressDialogData
} from '@shared/components/user-job-progress/user-job-progress-dialog.component';
import { UserJobModel, UserJobStatus } from '@common/models/user-job.model';

const baseUrl = `${SERVER_API_URL}/quote-response-rx`;

@Injectable({
  providedIn: 'root'
})
export class QuoteResponseRxService {
  constructor(
    protected http: HttpClient,
    protected httpResource: HttpResourceService,
    protected urlUtil: UrlUtilService,
    private dialog: MatDialog,
    private userJobService: UserJobService
  ) {}

  getById(id: number): Observable<QuoteResponseRxModel> {
    return this.http.get(`${baseUrl}/${id}`);
  }

  getQuoteResponseRxManifestOptions(
    quoteResponseRxId: number,
    isRequestingSide: boolean = false
  ): Observable<RxResponseManifestEntry[]> {
    const params = this.urlUtil.buildParamsFromObject({ requestingSide: isRequestingSide });
    return this.http.get<RxResponseManifestEntry[]>(`${baseUrl}/${quoteResponseRxId}/manifest`, { params });
  }

  downloadQuoteResponseRxManifest(quoteResponseRxId: number, qrPackage, fileName: string): Observable<Blob> {
    return this.httpResource.postWithoutOrg(`quote-response-rx/${quoteResponseRxId}/manifest/download`, qrPackage).pipe(
      switchMap((job) => {
        const data: UserJobProgressDialogData = {
          userJob: job,
          title: 'Preparing Quote File',
          fileName: fileName
        };
        return this.dialog
          .open<
            UserJobProgressDialog,
            UserJobProgressDialogData,
            UserJobModel
          >(UserJobProgressDialog, { data, width: '600px', disableClose: true })
          .afterClosed();
      }),
      switchMap((userJob) => {
        // process is complete, if id is returned, then delete it, no need to keep it around
        if (userJob?.status === UserJobStatus.COMPLETE) {
          return this.userJobService.delete(userJob.id);
        }
        return of(null);
      })
    );
  }

  downloadRxProposalDoc(quoteResponseRxId: number, quoteResponseRxOptionId: number, fileName: string) {
    let params = new HttpParams();
    if (!!quoteResponseRxOptionId) {
      params = params.append('quoteResponseOptionId', quoteResponseRxOptionId);
    }
    return this.httpResource.downloadFileWithoutOrg(
      { path: `quote-response-rx/${quoteResponseRxId}/rx-proposal/doc` },
      { fileName: fileName, params: params }
    );
  }

  searchRxProposalOptions(query: QueryModel): Observable<Page<QuoteResponseOptionRxModel>> {
    return this.httpResource.queryWithoutOrg({
      path: 'quote-response-rx/option',
      query
    });
  }
}
